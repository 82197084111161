import React, { FC } from "react";
import { PopupModal } from "react-calendly";

interface Props {
  open: boolean;
  onClose: () => void;
}

const CalendlyPopUp: FC<Props> = ({ open, onClose }) => {
  return (
    <PopupModal
      url="https://calendly.com/seamlessvisa/request-demo"
      // pageSettings={this.props.pageSettings}
      // utm={this.props.utm}
      // prefill={this.props.prefill}
      onModalClose={onClose}
      open={open}
      /*
       * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
       * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
       */
      rootElement={document.getElementById("root") as HTMLElement}
    />
  );
};

export default CalendlyPopUp;
