import React, { FC } from "react";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { N600PhysicalPresencePayload } from "../../../../../../api/n600/types";
import { FormN600Progress } from "../../types";

interface Props {
  title: string;
  data?: N600PhysicalPresencePayload;
}

const ReviewPhysicalPresence: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      label: "Physical Presence Period",
      value: `From: ${data?.fromDate} To: ${data?.toDate}`,
    },
  ];

  return (
    <ReviewBox
      title={title}
      data={section}
      editUrl={FormN600Progress.physicalPresenceFromBirth}
    />
  );
};

export default ReviewPhysicalPresence;
