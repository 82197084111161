import MiniLists from "../MiniLists";
import { DocChecklistProps } from "./types";

export enum n600DocChecklistNames {}

export const n600DocChecklist: DocChecklistProps[] = [
  {
    name: "n600_money_paid_to_DHS",
    label: `Check or money order made payable to "U.S. Department of Homeland Security" for the appropriate amount`,
    isNotFile: true,
    info: (
      <MiniLists>
        <li>USCIS fee is $1,170.</li>
        <li>
          There is no fee for members and veterans of the U.S. Armed Forces
          submitting Form N-600 on their own behalf, but you will need to attach
          proof of your military service.
        </li>
        <li>
          The children of military service members and veterans must still pay
          the applicable fee.
        </li>
      </MiniLists>
    ),
  },
  {
    name: "n600_copy_of_birth_cert",
    label:
      "Copy of your birth certificate or registration, certified by the appropriate official in your country of birth.",
  },
  {
    name: "n600_copy_of_parent_birth_cert_or_registration",
    label:
      "Copy of your U.S. citizen parent's (or parents') birth certificate or registration",
    info: "if they are applying for you.",
    optional: true,
  },
  {
    name: "n600_copy_of_marriage_cert",
    label:
      "Copy of the marriage certificate for your U.S. citizen parent's current marriage and/or a copy of your parents' marriage certificate.", //
  },
  {
    name: "n600_copy_of_marriage_termination_doc",
    label: "Copy of marriage termination documents",
    optional: true,
  },
  {
    name: "n600_proof_of_parent_US_status",
    label: "Proof of your parents' U.S. citizenship or status as U.S. national",
  },
  {
    name: "n600_other_proof_of_US_citizenship",
    label:
      "If you have some other proof of your U.S. citizenship, such as a passport, a copy of that.",
    optional: true,
  },
  {
    name: "n600_front_copy_of_US_permanent_resident_card",
    label: "A copy of your front your U.S. permanent resident card",
    info: (
      <MiniLists>
        <li>if you are claiming citizenship after birth.</li>
      </MiniLists>
    ),
    optional: true,
  },
  {
    name: "n600_back_copy_of_US_permanent_resident_card",
    label: "A copy of your back your U.S. permanent resident card",
    info: (
      <MiniLists>
        <li>if you are claiming citizenship after birth.</li>
      </MiniLists>
    ),
    optional: true,
  },
  {
    name: "n600_copy_of_full_final_adoption_decree",
    label: "Copy of your full, final adoption decree",
    optional: true,
  },
  {
    name: "n600_evidence_of_full_final_foreign_adoption",
    label:
      "Evidence of a full and final foreign adoption recognized by the appropriate authority in your current location of residence.",
    optional: true,
  },
  {
    name: "n600_evidence_all_legal_name_change_by_parents",
    label: "Evidence of all legal name changes by you or your parents",
    optional: true,
  },
  {
    name: "n600_evidence_of_parent_residence",
    label:
      "Provide evidence proving your parent's residence or physical presence in the U.S. for the required period.", //
    info: (
      <MiniLists>
        <li>
          Additional requirements if you have only one U.S. citizen parent
        </li>
        <li>
          Examples include school, employment, or military records, deeds,
          mortgages, utility bills, leases, tax transcripts, or letters from
          officials of relevant organizations.
        </li>
      </MiniLists>
    ),
    optional: true, //
  },
  {
    name: "n600_proof_of_parent_custody_on_date_of_citizenship_derivation",
    label:
      "Proof that the parent had physical and legal custody on the date of citizenship derivation.", //
    info: (
      <MiniLists>
        <li>If you derived citizenship from one U.S. parent</li>
        <li>
          Examples include court records, custody orders, and proof of shared
          residence.
        </li>
      </MiniLists>
    ),
    optional: true, //
  },
  {
    name: "n600_proof_of_legitimation",
    label:
      "Submit proof of legitimation according to the laws of the country of residence",
    info: (
      <MiniLists>
        <li>
          If born out of wedlock and basing claim on U.S. citizen father's
          status
        </li>
        <li>
          Such as birth certificate, DNA test results, evidence of financial
          support, or proof of shared residence and paternal recognition.
        </li>
      </MiniLists>
    ),
    optional: true, //
  },
];
