import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { parseError } from "../../utils";
import { StaffQueryKeys } from "./types";
import {
  createStaff,
  deleteStaff,
  getAllStaff,
  getSingleStaff,
  updateStaff,
} from "../../api/business/staff";

export const useGetAllStaff = () => {
  return useQuery({
    queryKey: [StaffQueryKeys.getAllStaff],
    queryFn: getAllStaff,
    select: (data) => data.data.data,
  });
};

export const useGetSingleStaff = (staffId: string) => {
  return useQuery({
    enabled: !!staffId,
    queryKey: [StaffQueryKeys.getSingleStaff, staffId],
    queryFn: () => getSingleStaff(staffId),
    select: (data) => data.data.data,
  });
};

export const useCreateStaff = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createStaff,
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [StaffQueryKeys.getAllStaff, StaffQueryKeys.getSingleStaff],
        })
        .then((r) => console.log(r));
    },
    onError: (error) => parseError(error),
  });
};

export const useUpdateStaff = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateStaff,
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [StaffQueryKeys.getAllStaff, StaffQueryKeys.getSingleStaff],
        })
        .then((r) => console.log(r));
    },
    onError: (error) => parseError(error),
  });
};

export const useDeleteStaff = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteStaff,
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [StaffQueryKeys.getAllStaff, StaffQueryKeys.getSingleStaff],
        })
        .then((r) => console.log(r));
    },
    onError: (error) => parseError(error),
  });
};
