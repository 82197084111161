import React, { useEffect } from "react";
import Hero from "./components/Hero";
import FiBiChoices from "./components/FiBiChoices";
import Features from "./components/Features";
import FeaturedVideo from "./components/FeaturedVideo";

const FiBi = () => {
  useEffect(() => {
    document.title = `Meet FiBi AI | Your Personal Interview Trainer`;

    return () => {
      document.title =
        "SeamlessVisa | The Trusted Partner in Your Immigration Journey";
    };
  }, []);
  return (
    <div>
      <Hero />
      <FiBiChoices />
      <Features />
      <FeaturedVideo />
    </div>
  );
};

export default FiBi;
