export enum n565QueryKeys {
  getN565InfoFromCert = "getN565InfoFromCert",
  getN565ApplicantInfo = "getN565ApplicantInfo",
  getN565ApplicationType = "getN565ApplicationType",
  getN565Form = "getN565Form",
  getN565PreparerInterpreter = "getN565PreparerInterpreter",
  getN565Info = "get-N565-Info",
  getN565PaySuccess = "get-N565-Pay-Success",
  getN565PayFail = "get-N565-Pay-Fail",
  getN565Eligibility = "get-N565-eligibility",
  getN565Files = "get-N565-files",
  getN565Trigger = "get-N565-trigger",
}
