import dayjs, { Dayjs } from "dayjs";
import { N400EligibilityPayload } from "../../../../api/n400/types";
import { SingleOption } from "../../../../types/types";
import {
  GenericEligibilityYesNoValues,
  N400AreYouMarriedToAUSCitizenLabels,
  N400DidYouServeHonorablyInActiveDutyLabels,
  N400HaveYouBeenMarriedForThreeMoreLabels,
  N400HaveYouLeftTheUSInThePastFiveYearsLabels,
  N400HaveYouLeftTheUSInThePastThreeYearsLabels,
  N400HowLongHaveYouHadYourGreenCardLabels,
  N400HowOldAreYouLabels,
} from "../../checkEligibilty/components/types";
import {
  GenericYesNoOptionsValue,
  MaritalStatusValues,
} from "../../components/data";
import { N400FormTimeOffData } from "./TimeOutsideUS";
import { FormN400Progress } from "./types";
import { MaritalHistoryData } from "./MaritalHistory";
import { FormikProps } from "formik";
import { TooltipType } from "../../components/Tooltip";
import { N400ApplicantInfo1Data } from "./ApplicantInfo/ApplicantInfo1";
import { N400ApplicantInfo2Data } from "./ApplicantInfo/ApplicantInfo2";

export const n400LeftSideBarData = [
  {
    label: "Applicant's Information",
    children: [
      {
        name: "Information About You",
        formProgress: FormN400Progress.applicantInfo1,
      },
      {
        name: "Additional Information",
        formProgress: FormN400Progress.applicantInfo2,
      },
    ],
  },
  {
    label: "Biographic Information",
    formProgress: FormN400Progress.bioInfo,
  },
  {
    label: "Information About Your Residence",
    formProgress: FormN400Progress.addressHistory,
  },
  {
    label: "Information About Your Marital History",
    formProgress: FormN400Progress.maritalHistory,
  },
  {
    label: "Information About Your Children",
    formProgress: FormN400Progress.childrenInfo,
  },
  {
    label: "Information About Your Employment And Schools You Attended",
    formProgress: FormN400Progress.employmentAndSchoolHistory,
  },
  {
    label: "Time Outside The U.S",
    formProgress: FormN400Progress.timeoff,
  },
  {
    label: "Additional Information About You",
    children: [
      {
        name: "Part 1",
        formProgress: FormN400Progress.checkPart1,
      },
      {
        name: "Part 2",
        formProgress: FormN400Progress.checkPart2,
      },
      {
        name: "Part 3",
        formProgress: FormN400Progress.checkPart3,
      },
      {
        name: "Part 4",
        formProgress: FormN400Progress.checkPart4,
      },
    ],
  },
  {
    label: "Request For Fee Reduction",
    formProgress: FormN400Progress.feeDeduct,
  },
  {
    label: "Preparer",
    formProgress: FormN400Progress.PI,
  },
  {
    label: "Review and Pay",
    formProgress: FormN400Progress.review,
  },
];

export const GenericNumDropDownValues = {
  zero: 0,
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
  six: 6,
};

export const GenericNumDropDown: SingleOption<number, string>[] = [
  // { value: GenericNumDropDownValues.zero, label: "Never married" },
  { value: GenericNumDropDownValues.one, label: "1" },
  { value: GenericNumDropDownValues.two, label: "2" },
  { value: GenericNumDropDownValues.three, label: "3" },
  { value: GenericNumDropDownValues.four, label: "4" },
  { value: GenericNumDropDownValues.five, label: "5" },
  // { value: GenericNumDropDownValues.six, label: "6" },
];

export const GenericNumDropDownChildren: SingleOption<number, string>[] = [
  { value: GenericNumDropDownValues.zero, label: "None" },
  { value: GenericNumDropDownValues.one, label: "1" },
  { value: GenericNumDropDownValues.two, label: "2" },
  { value: GenericNumDropDownValues.three, label: "3" },
  { value: GenericNumDropDownValues.four, label: "4" },
  { value: GenericNumDropDownValues.five, label: "5" },
  { value: GenericNumDropDownValues.six, label: "6" },
];

export const childRelationshipData: SingleOption[] = [
  {
    value: "biological child",
    label: "Biological child",
  },
  { value: "stepchild", label: "Step-child" },
  {
    value: "llegally adopted child",
    label: "Legally adopted child",
  },
];

export enum childResidenceValues {
  "resides with me" = "resides with me",
  "does not reside with me" = "does not reside with me",
  "unknown/missing" = "unknown/missing",
}

export const childResidenceData: SingleOption[] = [
  {
    label: "Resides with me",
    value: "resides with me",
  },
  { label: "Does not reside with me", value: "does not reside with me" },
  {
    label: "Unknown/Missing",
    value: "unknown/missing",
  },
];

export const immigrationStatusData: SingleOption[] = [
  { label: "U.S Citizen", value: "U.S Citizen" },
  {
    label: "Permanent Residence",
    value: "Permanent Residence",
  },
  { label: "Unknown", value: "Unknown" },
];

export const howMarriageEndedData: SingleOption[] = [
  { label: "Annulled", value: "Annulled" },
  {
    label: "Divorced",
    value: "Divorced",
  },
  { label: "Death of Spouse", value: "Death of Spouse" },
];

export const TOUSLeftUsCondition1 = (
  data: N400FormTimeOffData,
  formEligibility: N400EligibilityPayload
) => {
  const scenerio1 =
    data.have_you_left_us_in_the_past_five_years ===
      GenericYesNoOptionsValue.yes &&
    (formEligibility?.have_you_left_us_in_the_last_five_years ===
      N400HaveYouLeftTheUSInThePastFiveYearsLabels.No ||
      formEligibility?.have_you_left_us_in_the_past_three_years ===
        N400HaveYouLeftTheUSInThePastThreeYearsLabels.No);

  const scenerio2 =
    data.have_you_left_us_in_the_past_five_years ===
      GenericYesNoOptionsValue.no &&
    (formEligibility?.have_you_left_us_in_the_last_five_years ===
      N400HaveYouLeftTheUSInThePastFiveYearsLabels.Yes ||
      formEligibility?.have_you_left_us_in_the_past_three_years ===
        N400HaveYouLeftTheUSInThePastThreeYearsLabels.Yes);

  return scenerio1 || scenerio2;
};

export const TOUSMonthRangeCondition = (param: {
  data?: N400FormTimeOffData;
  index?: number;
  leftDate?: Dayjs | null;
  returnedDate?: Dayjs | null;
}) => {
  const { data, index, leftDate, returnedDate } = param;

  const dateYouReturned =
    index && data
      ? data.timeOutsideUSArray[index - 1].dateYouReturned
      : returnedDate;

  const dateYouLeft =
    index && data ? data.timeOutsideUSArray[index - 1].dateYouLeft : leftDate;

  const scenerio =
    (dateYouReturned ? dayjs(dateYouReturned) : dayjs())?.diff(
      dayjs(dateYouLeft),
      "month"
    ) >= 6;

  return scenerio;
};

// export const AHSameStateCondition = (data: AddressHistoryData) => {
//   const scenerio =
//     data.livedSameStateForThreeMonths === GenericYesNoOptionsValue.no;

//   return scenerio;
// };

// export const MHTimesMarriedCondition = (
//   data: MaritalHistory,
//   formEligibility: N400EligibilityPayload
// ) => {
//   const scenerio =
//     data.numOfTimesMarried?.value === GenericNumDropDownValues.zero &&
//     formEligibility.are_you_married_to_us_citizen ===
//       N400AreYouMarriedToAUSCitizenLabels.Yes;

//   return scenerio;
// };

export const MHMarriageStatusValCondition = (
  data: MaritalHistoryData,
  formEligibility: N400EligibilityPayload
) => {
  const scenerio =
    data.currentMaritalStatus?.value ===
      MaritalStatusValues["single & never married"] &&
    formEligibility.are_you_married_to_us_citizen ===
      N400AreYouMarriedToAUSCitizenLabels.Yes;

  return scenerio;
};

// export const MHSpouseUSCondition = (
//   data: MaritalHistoryData,
//   formEligibility: N400EligibilityPayload
// ) => {
//   const scenerio1 =
//     data.isSpouseUSCitizen === GenericYesNoOptionsValue.yes &&
//     formEligibility.are_you_married_to_us_citizen ===
//       N400AreYouMarriedToAUSCitizenLabels.No;

//   const scenerio2 =
//     data.isSpouseUSCitizen === GenericYesNoOptionsValue.no &&
//     formEligibility.are_you_married_to_us_citizen ===
//       N400AreYouMarriedToAUSCitizenLabels.Yes;

//   return scenerio1 || scenerio2;
// };

export const MHDateSpouseUSCitizen = (
  data: MaritalHistoryData,
  formEligibility: N400EligibilityPayload
) => {
  const year = dayjs()?.diff(dayjs(data.dateSpouseBecameUsCitizen), "year");

  const scenerio1 =
    formEligibility?.has_your_spouse_been_us_citizen_for_three_years_or_longer ===
      GenericEligibilityYesNoValues.Yes &&
    data.dateSpouseBecameUsCitizen &&
    year < 3;

  const scenerio2 =
    formEligibility?.has_your_spouse_been_us_citizen_for_three_years_or_longer ===
      GenericEligibilityYesNoValues.No &&
    data.dateSpouseBecameUsCitizen &&
    year >= 3;

  return scenerio1 || scenerio2;
};

export const MHDateMarriage = (
  data: MaritalHistoryData,
  formEligibility: N400EligibilityPayload
) => {
  const year = dayjs()?.diff(dayjs(data.dateOfMarriage), "year");

  const scenerio1 =
    formEligibility?.have_you_been_married_for_three_years_or_longer ===
      N400HaveYouBeenMarriedForThreeMoreLabels.Yes &&
    data.dateOfMarriage &&
    year < 3;

  const scenerio2 =
    formEligibility?.have_you_been_married_for_three_years_or_longer ===
      N400HaveYouBeenMarriedForThreeMoreLabels.No &&
    data.dateOfMarriage &&
    year >= 3;

  return scenerio1 || scenerio2;
};

export const getCheckListFieldValidation = (
  formik: FormikProps<any>,
  name: string,
  isNo?: boolean
) => {
  let temp: any;
  const object = formik.values;
  Object.keys(object).forEach((key) => {
    const value = (object as any)[key];

    if (
      name === key &&
      (isNo
        ? value === GenericYesNoOptionsValue.no
        : value === GenericYesNoOptionsValue.yes)
    ) {
      temp = {
        isReversed: true,
        type: TooltipType.risky,
        body: "Due to your selected option, it is advisable you connect with an attorney.",
        checkLabel:
          "I acknowledge that I have consulted with an attorney and/or want to continue anyway",
      };
      return;
    }
  });

  return temp;
};

// export const ApplicantInfoDateLPRCondition = (
//   data: N400ApplicantInfo1Data,
//   formEligibility: N400EligibilityPayload
// ) => {
//   if (!data.dateLPR) return;
//   const scenerio1 =
//     dayjs()?.diff(dayjs(data.dateLPR), "year") <= 5 &&
//     formEligibility.how_long_have_you_had_your_green_card ===
//       N400HowLongHaveYouHadYourGreenCardLabels.over5years;

//   const scenerio2 =
//     (dayjs()?.diff(dayjs(data.dateLPR), "year") < 4 ||
//       dayjs()?.diff(dayjs(data.dateLPR), "year") > 5) &&
//     formEligibility.how_long_have_you_had_your_green_card ===
//       N400HowLongHaveYouHadYourGreenCardLabels.fourToFiveYears;

//   const scenerio3 =
//     (dayjs()?.diff(dayjs(data.dateLPR), "year") < 3 ||
//       dayjs()?.diff(dayjs(data.dateLPR), "year") > 4) &&
//     formEligibility.how_long_have_you_had_your_green_card ===
//       N400HowLongHaveYouHadYourGreenCardLabels.threeToFourYears;

//   const scenerio4 =
//     (dayjs()?.diff(dayjs(data.dateLPR), "year") < 2 ||
//       dayjs()?.diff(dayjs(data.dateLPR), "year") > 3) &&
//     formEligibility.how_long_have_you_had_your_green_card ===
//       N400HowLongHaveYouHadYourGreenCardLabels.twoToThreeYears;

//   return scenerio1 || scenerio2 || scenerio3 || scenerio4;
// };

export const ApplicantInfoIsLprCondition = (
  data: N400ApplicantInfo1Data,
  formEligibility: N400EligibilityPayload
) => {
  const scenerio1 =
    data.isLPR === GenericYesNoOptionsValue.yes &&
    formEligibility.are_you_a_lawful_permanent_resident ===
      GenericEligibilityYesNoValues.No;

  const scenerio2 =
    data.isLPR === GenericYesNoOptionsValue.no &&
    formEligibility.are_you_a_lawful_permanent_resident ===
      GenericEligibilityYesNoValues.Yes;

  return scenerio1 || scenerio2;
};

export const ApplicantInfoDateLPRCondition = (
  data: N400ApplicantInfo1Data,
  formEligibility: N400EligibilityPayload
) => {
  if (!data.dateLPR) return;
  const scenerio1 =
    dayjs()?.diff(dayjs(data.dateLPR), "year") < 5 &&
    formEligibility.how_long_have_you_had_your_green_card ===
      N400HowLongHaveYouHadYourGreenCardLabels.least5years;

  const scenerio2 =
    (dayjs()?.diff(dayjs(data.dateLPR), "year") < 3 ||
      dayjs()?.diff(dayjs(data.dateLPR), "year") > 4) &&
    formEligibility.how_long_have_you_had_your_green_card ===
      N400HowLongHaveYouHadYourGreenCardLabels.threeToFourYears;

  return scenerio1 || scenerio2;
};

export const ApplicantInfoDateOfBirthCondition = (
  value: Dayjs | null,
  formEligibility: N400EligibilityPayload
) => {
  const dobValue = dayjs(value);
  if (!dobValue) return;
  const year = dayjs()?.diff(dayjs(dobValue), "year");
  const scenerio1 =
    formEligibility?.how_old_are_you ===
      N400HowOldAreYouLabels["18 or older"] &&
    dobValue &&
    year < 18;

  const scenerio2 =
    formEligibility?.how_old_are_you === N400HowOldAreYouLabels["Under 18"] &&
    dobValue &&
    year >= 18;

  return scenerio1 || scenerio2;
};

export const ApplicantInfoGender = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Another Gender Identity", value: "another gender identity" },
];

export const AIParentUSValCondition = (data: N400ApplicantInfo2Data) => {
  const scenerio =
    data.was_one_of_parent_us_citizen === GenericYesNoOptionsValue.yes;

  return scenerio;
};

export const BioInfoEthnicity = [
  { label: "Hispanic or Latino", value: "HispanicOrLatino" },
  { label: "Not Hispanic or Latino", value: "NotHispanicOrLatino" },
];

export const BioInfoEyeColor = [
  { label: "Black", value: "black" },
  { label: "Blue", value: "blue" },
  { label: "Brown", value: "brown" },
  { label: "Gray", value: "gray" },
  { label: "Green", value: "green" },
  { label: "Hazel", value: "hazel" },
  { label: "Maroon", value: "maroon" },
  { label: "Pink", value: "pink" },
  { label: "Unknown/Other", value: "unknown" },
];

export const BioInfoHairColor = [
  { label: "Bald (No hair)", value: "bald" },
  { label: "Black", value: "black" },
  { label: "Blond", value: "blond" },
  { label: "Brown", value: "brown" },
  { label: "Gray", value: "gray" },
  { label: "Red", value: "red" },
  { label: "Sandy", value: "sandy" },
  { label: "White", value: "white" },
  { label: "Unknown/Other", value: "unknown" },
];

export enum WhenCurrentSpouseUSCitizenValues {
  birth = "By Birth in the United States",
  other = "Other",
}

export const WhenCurrentSpouseUSCitizen = [
  {
    label: "By Birth in the United States",
    value: WhenCurrentSpouseUSCitizenValues.birth,
  },
  { label: "Other", value: WhenCurrentSpouseUSCitizenValues.other },
];

export enum N400ReasonForFiling {
  general = "General Provision",
  spouseUSCitizen = "Spouse of U.S. Citizen",
  vawa = "VAWA",
  employmentSpouseUSCitizen = "Spouse of U.S. Citizen in Qualified Employment Outside the United States.",
  hostilitiesMilitary = "Military Service During Period of Hostilities.",
  leastOneYearMilitary = "At Least One Year of Honorable Military Service at Any Time.",
  others = "Other Reason for Filing Not Listed Above",
}

export const GetN400ReasonForFiling = (data: N400EligibilityPayload) => {
  if (
    data.how_long_have_you_had_your_green_card ===
    N400HowLongHaveYouHadYourGreenCardLabels.least5years
  ) {
    return N400ReasonForFiling.general;
  }

  if (
    data.how_long_have_you_had_your_green_card ===
    N400HowLongHaveYouHadYourGreenCardLabels.threeToFourYears
  ) {
    return data.choose_between_options;
  }

  if (
    data.did_you_serve_honorably_in_active_duty ===
    N400DidYouServeHonorablyInActiveDutyLabels.yesPeriodOfConflict
  ) {
    return N400ReasonForFiling.hostilitiesMilitary;
  }

  if (
    data.did_you_serve_honorably_in_active_duty ===
    N400DidYouServeHonorablyInActiveDutyLabels.yesYearOrMore
  ) {
    return N400ReasonForFiling.leastOneYearMilitary;
  }

  return N400ReasonForFiling.others;
};

export const IsDependingOnCategory = (
  formEligibility: N400EligibilityPayload
) => {
  return (
    GetN400ReasonForFiling(formEligibility) ===
      N400ReasonForFiling.employmentSpouseUSCitizen ||
    GetN400ReasonForFiling(formEligibility) ===
      N400ReasonForFiling.spouseUSCitizen
  );
};

export const IsDependingOnSpousePresentEmployer = (
  formEligibility: N400EligibilityPayload
) => {
  return (
    GetN400ReasonForFiling(formEligibility) ===
    N400ReasonForFiling.employmentSpouseUSCitizen
  );
};
