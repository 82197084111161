import React from "react";
import Colors from "../../../../styles/Colors";
import styled from "styled-components";

import RadioField from "../../components/formik fields/RadioField";
import { mediaObj } from "../../../../styles/Media";
import { ReceiveInterpreterHelpValues } from "../../components/type";

const InterpreterSection = () => {
  return (
    <>
      <RadioWrapper>
        <RadioLabel>
          Did you receive help from an interpreter to translate this
          application?
        </RadioLabel>
        <RadioFlex>
          <RadioField
            name="receiveInterpreterHelp"
            value={ReceiveInterpreterHelpValues.yes}
            label="Yes"
          />
          <RadioField
            name="receiveInterpreterHelp"
            value={ReceiveInterpreterHelpValues.no}
            label="No"
          />
        </RadioFlex>
      </RadioWrapper>
    </>
  );
};

export default InterpreterSection;

const RadioLabel = styled.div`
  margin-bottom: 12px;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: ${Colors.Black31};
  font-weight: 400;

  &.required:after {
    content: "*";
    font-size: 24px;
    line-height: 0;
    vertical-align: middle;
    color: #d90b2c;
  }

  ${mediaObj.mobile} {
    font-size: 0.875rem;
  }
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding-bottom: 14px;

  input {
    accent-color: red;
  }
`;

const RadioFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
