import React from "react";
import styled from "styled-components";
import Constaint from "../../layout/components/Constaint";
import Colors from "../../../../styles/Colors";
import { ProcessData } from "./data";
import ProcessBg from "../../../../images/web/process-img.png";
import { motion } from "framer-motion";
import {
  TitleVariant,
  listItemContainerVariant,
  listItemVariant,
} from "./variants";
import { useNavigate } from "react-router-dom";
import { WebPaths } from "../../../../routes/data";

const Process = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Constaint>
        <Container>
          <H2
            variants={TitleVariant}
            whileInView={"animate"}
            initial={"initial"}
            viewport={{ once: true }}
          >
            Our Process
          </H2>
          <Section>
            <LeftSection>
              <LeftContent>
                <H3>How it Works</H3>
                <Para>
                  We believe that the key to a successful immigration case is a
                  well-planned strategy. That's why we take a personalized
                  approach to every case, tailoring our services to meet your
                  individual needs.
                </Para>
                <CardBtn onClick={() => navigate(WebPaths.Services)}>
                  GET started
                </CardBtn>
              </LeftContent>
            </LeftSection>
            <RightSection>
              <CardFlex
                variants={listItemContainerVariant}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true }}
              >
                {ProcessData.map((ev, i) => (
                  <Card key={i} variants={listItemVariant}>
                    <CardName>{ev.name}</CardName>
                    {ev.img}
                    <CardPara>{ev.subtext}</CardPara>
                  </Card>
                ))}
              </CardFlex>
            </RightSection>
          </Section>
        </Container>
      </Constaint>
    </Wrapper>
  );
};

export default Process;

const Wrapper = styled.div`
  background-color: #f9fcff;
`;

const Container = styled.div`
  padding: 100px 0;
`;

const H2 = styled(motion.h2)`
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.375rem;
  color: #31393c;
  margin-bottom: 59px;
  text-align: center;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const LeftSection = styled.div`
  padding: 3rem 24px 3rem 24px;
  background: url(${ProcessBg});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    width: 650px;
    padding: 3rem 84px 3rem 84px;
  }
`;

const LeftContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  text-align: center;

  @media only screen and (min-width: 768px) {
    text-align: left;
  }
`;

const H3 = styled.h3`
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 2.6875rem;
  color: #ffffff;
`;

const Para = styled.p`
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #dee1e5;
  margin-top: 33px;
  margin-bottom: 40px;
`;

const CardBtn = styled.button`
  width: 243px;
  height: 56px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.1875rem;
  text-transform: uppercase;
  color: #f9fcff;
  background: ${Colors.Blue00};

  margin-inline: auto;

  &:hover {
    background: #022e58;
    color: #f9fcff;
    transition: 0.3s ease-in-out;
  }
`;

const RightSection = styled.div``;

const CardFlex = styled(motion.div)`
  display: grid;
  place-content: center;
  gap: 20px;
  grid-template-columns: max-content;

  @media only screen and (min-width: 768px) {
    grid-template-columns: max-content max-content;
  }
`;

const Card = styled(motion.div)`
  width: 350px;
  height: 313px;
  background: rgba(255, 255, 255, 0.61);
  box-shadow: 0px 6px 6px -2px #f5f5f5;
  border-radius: 12px;
  padding: 38px 28px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 25px;

  @media screen and (min-width: 768px) {
    width: 315px;
  }
`;

// const CardImage = styled.img`
//   width: 82px;
//   height: 82px;
// `;

const CardName = styled.h5`
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.8125rem;
  color: #31393c;
`;

const CardPara = styled.p`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: #31393c;
`;
