import React, { useState } from "react";
import {
  TBODY,
  TD,
  TH,
  THEAD,
  TR,
  Table,
  TableWrapper,
} from "../../../../components/styles";
import Colors from "../../../../../../styles/Colors";
import styled from "styled-components";
import { useSnapshot } from "valtio";
import { userDataStore } from "../../../../../../store/userData";
import { getFormatedFormName } from "../../../../components/data";
import StatusTag from "./StatusTag";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { WebPaths } from "../../../../../../routes/data";
import useOnContinueNav from "../../../../../../hooks/useOnContinueNav";
import DownloadForms from "../../../../components/DownloadForms";
import { CaseHistoryObj } from "../../../../../../types/types";
import useBasedOnFormPaid from "../../../../../../hooks/useBasedOnFormPaid";

const CaseTable = () => {
  const navigate = useNavigate();
  const { getBasedOnFormPaid } = useBasedOnFormPaid();
  const { onContinueNav } = useOnContinueNav();
  const { userData } = useSnapshot(userDataStore);
  const tableData = userData?.caseHistory.filter((ev) => ev.caseId !== "");
  const [onDownload, setOnDownload] = useState<CaseHistoryObj>();

  const onOpenDownload = (params: CaseHistoryObj) => {
    setOnDownload(params);
  };

  const onCloseDownload = () => {
    setOnDownload(undefined);
  };
  const onView = (caseType: string) => {
    getBasedOnFormPaid(caseType)
      ? navigate(`${WebPaths.FormCategory}/${caseType}?type=${caseType}-review`)
      : onContinueNav();
  };
  return (
    <TableWrapper>
      <Table>
        <THEAD>
          <TR>
            <TH>Case ID</TH>
            <TH>Case Type</TH>
            <TH>Date Opened</TH>
            <TH>Date Submitted</TH>
            <TH>Status</TH>
            <TH>Payment</TH>
            <TH></TH>
          </TR>
        </THEAD>

        <TBODY>
          {tableData?.length === 0 ? (
            <TR>
              <TD colSpan={6}>
                <NoData>You don't have any case history yet.</NoData>
              </TD>
            </TR>
          ) : (
            tableData?.map((ev, i) => {
              return (
                <TR key={i}>
                  <TD>#{ev.caseId}</TD>
                  <TD>
                    <Texter>{getFormatedFormName(ev.caseType) || "--"}</Texter>
                  </TD>
                  <TD>
                    {ev.dateOpened
                      ? dayjs(ev.dateOpened).format("DD/MM/YY")
                      : "--"}
                  </TD>
                  <TD>
                    {ev.dateSubmitted
                      ? dayjs(ev.dateSubmitted).format("DD/MM/YY")
                      : "--"}
                  </TD>
                  <TD>
                    <StatusTag status={ev.Status} />
                  </TD>
                  <TD>{ev.Payment ? "Paid" : "N/A"}</TD>
                  <TD>
                    <BtnWrapper>
                      <ViewBtn onClick={() => onView(ev.caseType)}>
                        View
                      </ViewBtn>
                      {ev.Payment && (
                        <DownloadBtn
                          onClick={() => {
                            onOpenDownload(ev);
                          }}
                        >
                          Download Form
                        </DownloadBtn>
                      )}
                    </BtnWrapper>
                  </TD>
                </TR>
              );
            })
          )}
        </TBODY>
      </Table>
      {onDownload && (
        <DownloadForms
          onClose={onCloseDownload}
          caseType={onDownload.caseType}
          filingURL={onDownload.filingURL}
          formDownloadUrl={onDownload.formDownloadUrl}
          g1145URL={onDownload.g1145URL || ""}
        />
      )}
    </TableWrapper>
  );
};

export default CaseTable;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 13px;
`;

const Btn = styled.button`
  padding: 4px 11px;
  border: 1px solid;
  font-size: 0.75rem;
  border-radius: 4px;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const ViewBtn = styled(Btn)`
  border-color: ${Colors.Blue00};
  color: ${Colors.Blue00};
`;

const DownloadBtn = styled(Btn)`
  border-color: ${Colors.Black31};
  color: ${Colors.Black31};
  display: flex;
  align-items: center;
  gap: 2px;
`;

const Texter = styled.span`
  text-transform: uppercase;
`;

const NoData = styled.div`
  text-transform: capitalize;
  padding: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
