export enum FormDS160Progress {
  // getStarted = "ds160 Get Started",
  personalInfoP1 = "ds-160 personal information personal 1",
  personalInfoP2 = "ds-160 personal information personal 2",
  travel = "ds-160 travel information",
  travelCompanions = "ds-160 travel companions information",
  previousUSTravel = "ds-160 previous US travel",
  addressAndPhone = "ds-160 address and phone",
  passport = "ds-160 passport",
  usContact = "ds-160 Us Contact",
  familyRelatives = "ds-160 family",
  maritalStatus = "ds-160 marital status",
  WETPresent = "ds-160 present work/education/training",
  WETPrevious = "ds-160 previous work/education/training",
  WETAdditional = "ds-160 additional work/education/training",
  securityBackgroundP1 = "ds-160 security/background part 1",
  securityBackgroundP2 = "ds-160 security/background part 2",
  securityBackgroundP3 = "ds-160 security/background part 3",
  securityBackgroundP4 = "ds-160 security/background part 4",
  securityBackgroundP5 = "ds-160 security/background part 5",
  studentExchangeVisaAdditional = "ds-160 student exchange visa additional contact",
  studentExchangeVisaSEVIS = "ds-160 student exchange visa sevis",
  review = "ds160-review",
}
