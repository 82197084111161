import { useRef, useState } from "react";

const useActivate = (isActive?: boolean) => {
  const contentEl = useRef<HTMLDivElement>(null);
  const [active, setActive] = useState(isActive ? isActive : false);
  const toggle = () => setActive((prev) => !prev);

  return { contentEl, active, toggle };
};

export default useActivate;
