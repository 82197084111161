import React from "react";
import { userDataStore } from "../../../../../store/userData";
import { useSnapshot } from "valtio";
import ContinueApplication from "./components/ContinueApplication";
import ActiveApplication from "./components/ActiveApplication";
import { BoxWrapper, DashboardTitle, DashboardWrapper } from "../styles";
import styled from "styled-components";
import { FaInfoCircle } from "react-icons/fa";
import Colors from "../../../../../styles/Colors";

const Applications = () => {
  const { userData } = useSnapshot(userDataStore);

  return (
    <DashboardWrapper>
      <DashboardTitle>Active Application</DashboardTitle>
      <Wrapper>
        <>
          {userData &&
          userData.currentFormInfo.formCategory &&
          userData.currentFormInfo.formProgress ? (
            <ContinueApplication
              formProgress={userData.currentFormInfo.formProgress}
              formCategory={userData.currentFormInfo.formCategory}
              isFormCompleted={userData.currentFormInfo.isFormCompleted}
            />
          ) : (
            <ActiveApplication />
          )}
        </>
        <Info>
          <FaInfoCircle />
          Only one application is allowed at a time; complete any active
          application before starting a new one.
        </Info>
      </Wrapper>
    </DashboardWrapper>
  );
};

export default Applications;

const Wrapper = styled(BoxWrapper)`
  flex-direction: column;
  justify-content: unset;
  /* align-items: unset; */
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.875rem;
  margin-top: 0.5rem;

  & > svg {
    color: ${Colors.RedDA};
    flex-shrink: 0;
    width: 16px;
    height: 16px;
  }
`;
