import { userDataStore } from "../store/userData";
import { useSnapshot } from "valtio";
import { FormCategories } from "../routes/type";

const useIsEligibilityFormFilled = () => {
  const { userData } = useSnapshot(userDataStore);

  const getIsEligibilityFormFilled = (formType: FormCategories) => {
    const data = userData?.eligibilityHistory;
    if (!data) return false;
    const newArray = data
      .filter((obj) => obj.isFilled && obj.isEligible)
      .map((obj) => obj.formCategory);

    return newArray.includes(formType);
  };

  return { getIsEligibilityFormFilled };
};

export default useIsEligibilityFormFilled;
