import Header from "./Header";
import styled from "styled-components";
import { IoFolderOutline } from "react-icons/io5";
import { FaRegCircle } from "react-icons/fa";
import { MdAccessTime } from "react-icons/md";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import OverviewCard from "./OverviewCard";
import Colors from "../../../../../../../styles/Colors";
import { mediaObj } from "../../../../../../../styles/Media";
import {
  useGetTotalCases,
  useGetTotalCompletedCases,
  useGetTotalPaidCases,
  useGetTotalPendingCases,
} from "../../../../../../../hooks/business/useBusinessApplications";

const Stats = () => {
  const { data: casesData, isLoading: casesLoading } = useGetTotalCases();
  const { data: completedData, isLoading: completedLoading } =
    useGetTotalCompletedCases();
  const { data: paidData, isLoading: paidLoading } = useGetTotalPaidCases();
  const { data: pendingData, isLoading: pendingLoading } =
    useGetTotalPendingCases();

  return (
    <>
      <Header name="Overview" />
      <TopWrapper>
        <OverviewCard
          name="Total Cases"
          number={casesData}
          icon={<IoFolderOutline />}
          iconBg={"#519AE16E"}
          iconColor={Colors.White}
          isLoading={casesLoading}
          cardBg={Colors.Blue00}
        />
        <OverviewCard
          name="Paid Cases"
          number={paidData}
          icon={<FaRegCircle />}
          iconBg={"#CFE6FC"}
          iconColor={Colors.Blue00}
          isLoading={paidLoading}
        />
        <OverviewCard
          name="Pending Cases"
          number={pendingData}
          icon={<MdAccessTime />}
          iconBg={"#FFE4D4"}
          iconColor={Colors.RedDA}
          isLoading={pendingLoading}
        />
        <OverviewCard
          name="Completed Cases"
          number={completedData}
          icon={<IoMdCheckmarkCircleOutline />}
          iconBg={"#CBF6F0"}
          iconColor={"#00C1A3"}
          isLoading={completedLoading}
        />
      </TopWrapper>
    </>
  );
};

export default Stats;

const TopWrapper = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(4, 1fr);

  ${mediaObj.smallDesktop} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${mediaObj.bigMobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;
