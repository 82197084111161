import React, { FC } from "react";
import {
  saveDS160SecurityBackgroundP1Obj,
  saveDS160SecurityBackgroundP2Obj,
  saveDS160SecurityBackgroundP3Obj,
  saveDS160SecurityBackgroundP4Obj,
  saveDS160SecurityBackgroundP5Obj,
} from "../../../../../../api/ds160/types";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { FormDS160Progress } from "../../types";
import { GenericDs160ReviewProps } from "./DownloadPage";
import PdfReviewBox from "../../../../components/Review/Pdf Review/PdfReviewBox";
import {
  ReviewSecuritySectionA,
  ReviewSecuritySectionB,
  ReviewSecuritySectionC,
  ReviewSecuritySectionD,
  ReviewSecuritySectionE,
} from "./data";

interface Props extends GenericDs160ReviewProps {
  data?: {
    partOne: saveDS160SecurityBackgroundP1Obj;
    partTwo: saveDS160SecurityBackgroundP2Obj;
    partThree: saveDS160SecurityBackgroundP3Obj;
    partFour: saveDS160SecurityBackgroundP4Obj;
    partFive: saveDS160SecurityBackgroundP5Obj;
  };
}

const ReviewSecurity: FC<Props> = ({ title, data, isDownload }) => {
  const sectionA = data ? ReviewSecuritySectionA(data.partOne) : [];

  const sectionB = data ? ReviewSecuritySectionB(data.partTwo) : [];

  const sectionC = data ? ReviewSecuritySectionC(data.partThree) : [];

  const sectionD = data ? ReviewSecuritySectionD(data.partFour) : [];

  const sectionE = data ? ReviewSecuritySectionE(data.partFive) : [];

  const section: ReviewData[] = [
    {
      section: {
        title: "Part 1",
        children: sectionA,
      },
    },
    {
      section: {
        title: "Part 2",
        children: sectionB,
      },
    },
    {
      section: {
        title: "Part 3",
        children: sectionC,
      },
    },
    {
      section: {
        title: "Part 4",
        children: sectionD,
      },
    },
    {
      section: {
        title: "Part 5",
        children: sectionE,
      },
    },
  ];

  const compProps = {
    title: title,
    data: section,
    editUrl: FormDS160Progress.securityBackgroundP1,
  };

  return isDownload ? (
    <PdfReviewBox {...compProps} />
  ) : (
    <ReviewBox {...compProps} />
  );
};

export default ReviewSecurity;
