import { FC } from "react";
import styled from "styled-components";
import { getFormatedFormName } from "../../../../../components/data";
import Colors from "../../../../../../../styles/Colors";
import { GetBusinessApplicationsObj } from "../../../../../../../api/business/applications/types";
import StatusTag from "../../dashboard/components/StatusTag";
import { BusinessBoxWrapper, SingleCaseBoxHeader } from "./styles";

interface Props {
  data: GetBusinessApplicationsObj;
}

const CaseHeader: FC<Props> = ({ data }) => {
  const { applicationInfo } = data;
  const sect = [
    {
      label: "Case No.:",
      value: applicationInfo?.CaseNumber || "--", //
    },
    {
      label: "Type:",
      value: getFormatedFormName(data.applicationType).toUpperCase(),
    },
    {
      label: "Payment:",
      value: applicationInfo?.isPaid ? "Paid" : "N/A",
    },
    {
      label: "Status:",
      value: <StatusTag status={applicationInfo?.isFormCompleted || false} />,
    },
    {
      label: "Progress:",
      value: (
        <div className="capitalize ">
          {applicationInfo?.formProgress || "Eligibility"}
        </div>
      ),
    },
  ];

  return (
    <Wrapper>
      <SingleCaseBoxHeader>Case Information</SingleCaseBoxHeader>
      <MiniWrap>
        {sect.map((ev, index) => (
          <Entry className={`${(index + 1) % 2 === 0 ? "ml-auto" : ""}`}>
            <EntryLabel>{ev.label}</EntryLabel>
            <EntryValue>{ev.value}</EntryValue>
          </Entry>
        ))}
      </MiniWrap>
    </Wrapper>
  );
};

export default CaseHeader;

const Wrapper = styled(BusinessBoxWrapper)``;

const MiniWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
`;

const Entry = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
  flex-wrap: wrap;
`;

const EntryLabel = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  /* color: ${Colors.Black31}; */
  color: ${Colors.Blue00};
  white-space: nowrap;
`;

interface EntryValueProps {
  $color?: string;
}

const EntryValue = styled.div<EntryValueProps>`
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  /* color: ${({ $color }) => ($color ? $color : Colors.Black31)}; */
`;
