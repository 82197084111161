export const faqData = [
  {
    label: "How does SeamlessVisa ensure the security of my data?",
    value:
      "We adhere to all applicable data protection legislation and prioritize the security and confidentiality of user data.",
  },
  {
    label: "How does the platform guide users through the application process?",
    value:
      "Our attorney-reviewed platform simplifies complex forms, guides you step-by-step, and does rigorous error-checking to ensure your application is flawless and our dedicated support team is just a click away, to answer your questions and alleviate your anxieties.",
  },
  {
    label: "Can I receive real-time updates on the progress of my application?",
    value:
      "Yes, SeamlessVisa provides real-time updates, eliminating the need for constant checking.",
  },
  {
    label:
      "How long does it take to have the application finalized and ready to send to USCIS?",
    value:
      "The timeframe depends on how fast you work through your application, provide your document and submit for review. On average it takes approximately 1 to 2 weeks.",
  },
  {
    label:
      "How can I know if I am eligible to apply for any immigration service?",
    value:
      "We understand that determining whether you are eligible or not to begin an immigration process might be difficult. We created an eligibility quiz and by answering a few questions, our software can help figure out if you are qualified for a particular visa.",
  },
];
