import React, { FC } from "react";
import { useGetN600PaySuccess } from "../../../../hooks/n600/useN600";
import { useSearchParams } from "react-router-dom";
import PaySuccess from "../../components/PaySuccess";

interface Props {
  formType: string;
}

const N600PaySuccess: FC<Props> = ({ formType }) => {
  const [searchParams] = useSearchParams();
  const payId = searchParams.get("payId") as string;
  const { isLoading, isError } = useGetN600PaySuccess(payId);

  return (
    <PaySuccess isLoading={isLoading} isError={isError} formType={formType} />
  );
};

export default N600PaySuccess;
