import React from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { IoMdArrowBack } from "react-icons/io";
import Colors from "../../../../../../styles/Colors";
import { getFormatedFormName } from "../../../../components/data";
import N400DocChecklist from "./forms checklist/N400DocChecklist";
import N565DocChecklist from "./forms checklist/N565DocChecklist";
import { FormCategories } from "../../../../../../routes/type";
import N600DocChecklist from "./forms checklist/N600DocChecklist";
import DS160DocChecklist from "./forms checklist/DS160DocChecklist";

const DocumentChecklist = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const getType = searchParams.get("form");

  const onBack = () => {
    searchParams.delete("form");
    setSearchParams(searchParams);
  };

  return (
    <Wrapper>
      <Back onClick={onBack}>
        <IoMdArrowBack />
        Back
      </Back>
      <Header>
        <HeaderTitle>
          {getFormatedFormName(getType || "").toUpperCase()} Document Checklist
        </HeaderTitle>
        <HeaderText>
          Documents to be submitted with your{" "}
          <HeaderTextColored>
            {getFormatedFormName(getType || "")}
          </HeaderTextColored>{" "}
          form.
        </HeaderText>
      </Header>
      <MiniFlex>
        {getType === FormCategories.n565 && <N565DocChecklist />}
        {getType === FormCategories.n400 && <N400DocChecklist />}
        {getType === FormCategories.n600 && <N600DocChecklist />}
        {getType === FormCategories.ds160 && <DS160DocChecklist />}
      </MiniFlex>
    </Wrapper>
  );
};

export default DocumentChecklist;

const Wrapper = styled.div``;

const Back = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: max-content;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin: 1.5rem 0;
  border-bottom: 1px solid ${Colors.GreyA7};
  padding-bottom: 10px;
`;

const HeaderTitle = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
`;

const HeaderText = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
`;

const HeaderTextColored = styled.span`
  font-weight: 700;
  color: ${Colors.Blue00};
  text-transform: uppercase;
`;

const MiniFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
