import React, { useState } from "react";
import {
  n400CommonDocChecklist,
  n400MarraigeNoDocChecklist,
  n400MarraigeYesDocChecklist,
} from "../data/n400Data";
import DocChecklistItem from "../DocChecklistItem";
import {
  useGetN400Eligibility,
  useGetN400Files,
  useUpdateN400Files,
  useUploadN400Files,
} from "../../../../../../../hooks/n400/useN400";
import { toast } from "react-toastify";
import RequestWrapper from "../../../../../components/RequestWrapper";
import { UpdateN400FilesPayload } from "../../../../../../../api/n400/types";
import { useSearchParams } from "react-router-dom";
import { N400AreYouMarriedToAUSCitizenLabels } from "../../../../../checkEligibilty/components/types";
import AdditionalDoc from "../AdditionalDoc";
import AdditionalDocItem from "./AdditionalDocItem";

const N400DocChecklist = () => {
  const [searchParams] = useSearchParams();
  const [loadingField, setLoading] = useState("");
  const getType = searchParams.get("form");
  const {
    data: eligibilityData,
    isLoading: eligibilityLoading,
    isError: eligibilityError,
  } = useGetN400Eligibility();
  const { data, isLoading: isGetting, isError } = useGetN400Files();
  const { mutate, isLoading } = useUploadN400Files();
  const { mutate: updateMutate, isLoading: isUpdating } = useUpdateN400Files();

  const getDocData = () => {
    if (
      eligibilityData?.are_you_married_to_us_citizen ===
      N400AreYouMarriedToAUSCitizenLabels.Yes
    ) {
      return n400CommonDocChecklist.concat(n400MarraigeYesDocChecklist);
    } else {
      return n400CommonDocChecklist.concat(n400MarraigeNoDocChecklist);
    }
  };

  const docData = getDocData();

  const onSubmit = (
    params: File,
    name: string,
    fileId?: string,
    onSuccessful?: () => void
  ) => {
    const payload = new FormData();
    payload.append("file", params);

    if (fileId && getType) {
      const temp: UpdateN400FilesPayload = {
        id: fileId,
        formCategory: getType,
        data: payload,
      };

      updateMutate(temp, {
        onSuccess: () => toast.success("Update Successful"),
      });
    } else {
      payload.append("name", name);

      mutate(payload, {
        onSuccess: () => {
          onSuccessful && onSuccessful();
          toast.success("Upload Successful");
        },
      });
    }

    setLoading(name);
  };

  return (
    <RequestWrapper
      isLoading={isGetting || eligibilityLoading}
      isError={isError || eligibilityError}
      data={data || eligibilityData?.are_you_married_to_us_citizen}
    >
      <>
        {docData &&
          docData.map((ev, i) => (
            <DocChecklistItem
              key={i}
              data={ev}
              onSubmit={onSubmit}
              isLoading={loadingField === ev.name && (isLoading || isUpdating)}
              savedData={
                data
                  ? [...data?.filedetail]
                      .reverse()
                      .find((item) => item.name === ev.name)
                  : undefined
              }
            />
          ))}

        {data && (
          <AdditionalDocItem
            filedetail={data.filedetail}
            onSubmit={onSubmit}
            loadingField={loadingField}
            loadingState={isLoading || isUpdating}
          />
        )}
        <AdditionalDoc
          onSubmit={onSubmit}
          loadingField={loadingField}
          loadingState={isLoading || isUpdating}
        />
      </>
    </RequestWrapper>
  );
};

export default N400DocChecklist;
