import { useGetN400Trigger } from "./n400/useN400";
// import { useGetDS160Trigger } from "./ds160/usDS160";
import { FormCategories } from "../routes/type";
import { useGetN600Trigger } from "./n600/useN600";
import { useGetN565Trigger } from "./n565/useN565";

const useDownloadTrigger = (formType: string) => {
  const { isFetching: n400TriggerFetching, isError: n400TriggerError } =
    useGetN400Trigger(formType === FormCategories.n400);
  // const { isFetching: ds160TriggerFetching, isError: ds160TriggerError } =
  //   useGetDS160Trigger(formType === FormCategories.ds160);
  const { isFetching: n600TriggerFetching, isError: n600TriggerError } =
    useGetN600Trigger(formType === FormCategories.n600);
  const { isFetching: n565TriggerFetching, isError: n565TriggerError } =
    useGetN565Trigger(formType === FormCategories.n565);

  const getQueryBooleans = () => {
    if (formType === FormCategories.n400) {
      return {
        triggerFetching: n400TriggerFetching,
        triggerError: n400TriggerError,
      };
    }

    if (formType === FormCategories.ds160) {
      return {
        triggerFetching: false,
        triggerError: false,
      };
      // return {
      //   triggerFetching: ds160TriggerFetching,
      //   triggerError: ds160TriggerError,
      // };
    }

    if (formType === FormCategories.n600) {
      return {
        triggerFetching: n600TriggerFetching,
        triggerError: n600TriggerError,
      };
    }

    if (formType === FormCategories.n565) {
      return {
        triggerFetching: n565TriggerFetching,
        triggerError: n565TriggerError,
      };
    }
  };

  const triggerFetching = getQueryBooleans()?.triggerFetching;
  const triggerError = getQueryBooleans()?.triggerError;

  return { triggerFetching, triggerError };
};

export default useDownloadTrigger;
