import React, { FC } from "react";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { N600InfoAboutYouPart3Payload } from "../../../../../../api/n600/types";
import { FormN600Progress } from "../../types";

interface Props {
  title: string;
  data?: N600InfoAboutYouPart3Payload;
}

const ReviewInfoAboutYouPart3: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      label: "City or Town (Port-of-Entry)",
      value: data?.cityOrTown,
    },
    {
      label: "State (Port-of-Entry)",
      value: data?.state,
    },
    {
      label: "Date of Entry",
      value: data?.dateOfEntry,
    },
    {
      label: "Family Name (Last Name) at Time of Entry",
      value: data?.familyName,
    },
    {
      label: "Given Name (First Name) at Time of Entry",
      value: data?.givenName,
    },
    {
      label: "Middle Name at Time of Entry",
      value: data?.middleName,
    },
    {
      label: "Travel Document Used",
      value: data?.travelDocumentUsed,
    },
    {
      label: "Travel Document Number",
      value: data?.travelDocumentNumber,
    },
    {
      label: "Country of Issuance for Travel Document",
      value: data?.travelDocumentCountryOfIssuance,
    },
    {
      label: "Date Travel Document Issued",
      value: data?.travelDocumentDateIssued,
    },
    {
      label: "Immigration Status",
      value: data?.immigrationStatus,
    },
    {
      label: "Explain Immigration Status",
      value: data?.immigrationStatusExplain,
    },
    {
      label: "Date Became LPR",
      value: data?.dateBecameLPR,
    },
    {
      label: "USCIS Office Granted LPR",
      value: data?.uscisOfficeGrantedLPR,
    },
    {
      label: "Previously Applied for Citizenship or Passport",
      value: data?.previouslyAppliedForCitizenshipOrPassport,
    },
    {
      label: "Explanation if Previously Applied for Citizenship or Passport",
      value: data?.previouslyAppliedForCitizenshipOrPassportExplanation,
    },
    {
      label: "Abandoned or Lost LPR Status",
      value: data?.abandonedOrLostLPRStatus,
    },
    {
      label: "Explanation if Abandoned or Lost LPR Status",
      value: data?.abandonedOrLostLPRStatusExplanation,
    },
    {
      label: "Were you adopted?",
      value: data?.adopted,
    },
    {
      label: "City or Town of Adoption",
      value: data?.cityOrTownOfAdoption,
    },
    {
      label: "State of Adoption",
      value: data?.stateOfAdoption,
    },
    {
      label: "Country of Adoption",
      value: data?.countryOfAdoption,
    },
    {
      label: "Date of Adoption",
      value: data?.dateOfAdoption,
    },
    {
      label: "Date Legal Custody Began",
      value: data?.dateLegalCustodyBegan,
    },
    {
      label: "Date Physical Custody Began",
      value: data?.datePhysicalCustodyBegan,
    },
    {
      label: "Did you have to be re-adopted in the United States?",
      value: data?.reAdoptedInUS,
    },
    {
      label: "City or Town of Re-adoption",
      value: data?.cityOrTownOfReAdoption,
    },
    {
      label: "State of Re-adoption",
      value: data?.stateOfReAdoption,
    },
    {
      label: "Country of Re-adoption",
      value: data?.countryOfReAdoption,
    },
    {
      label: "Date of Re-adoption",
      value: data?.dateOfReAdoption,
    },
    {
      label: "Date Re-adoption Legal Custody Began",
      value: data?.dateReAdoptionLegalCustodyBegan,
    },
    {
      label: "Date Re-adoption Physical Custody Began",
      value: data?.dateReAdoptionPhysicalCustodyBegan,
    },
  ];

  return (
    <ReviewBox
      title={title}
      data={section}
      editUrl={FormN600Progress.infoAboutYouP3}
    />
  );
};

export default ReviewInfoAboutYouPart3;
