import React, { FC } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import Colors from "../../../../../styles/Colors";

export const PdfReviewGenericstyles = StyleSheet.create({
  row: {
    padding: "24px 16px 12px 16px",
    borderBottom: "1px solid #eeeeee",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "24px",
    justifyContent: "space-between",
    color: Colors.Black31,
  },
  rowLabel: {
    maxWidth: 400,
    fontSize: "14px",
    fontWeight: 400,
    // lineHeight: "17px",
    letterSpacing: "0",
    flex: 1,
  },
  rowValue: {
    flex: 1,
    textAlign: "right",
    fontSize: "16px",
    fontWeight: 600,
    // lineHeight: "19px",
    letterSpacing: "0",
    "&::first-letter": { textTransform: "capitalize" },
  },
});

interface Props {
  label?: string;
  value?: string;
}

const PdfReviewGeneric: FC<Props> = ({ label, value }) => {
  return value ? (
    <View style={PdfReviewGenericstyles.row} wrap={false}>
      <Text style={PdfReviewGenericstyles.rowLabel}>{label}</Text>
      <Text style={PdfReviewGenericstyles.rowValue}>{value}</Text>
    </View>
  ) : (
    <></>
  );
};

export default PdfReviewGeneric;
