import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { userDataStore } from "../store/userData";
import { useSnapshot } from "valtio";

const useIsCompleted = () => {
  const { formCategory: urlFormCategory } = useParams();
  const { userData } = useSnapshot(userDataStore);
  const [isFormCompleted, setIsFormCompleted] = useState(false);

  const getIsCompleted = useCallback(
    (formType?: string) => {
      if (!userData) return false;
      const result = userData.caseHistory.find(
        (ev) => (formType || urlFormCategory) === ev.caseType && ev.Status
      );
      return result ? true : false;
    },
    [urlFormCategory, userData]
  );

  useEffect(() => {
    const result = getIsCompleted();
    setIsFormCompleted(result);
  }, [getIsCompleted]);

  return { isFormCompleted, getIsCompleted };
};

export default useIsCompleted;
