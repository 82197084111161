import React, { useEffect } from "react";
import styled from "styled-components";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { Form, Formik } from "formik";
import InputField from "../../../components/formik fields/InputField";
import { FormWrapper } from "../../../components/styles";
import DatePickerField from "../../../components/formik fields/DatePickerField";
import {
  ApartmentSuiteFloorData,
  GenericYesNo,
  GenericYesNoOptionsValue,
  MaritalStatusData,
  MaritalStatusValues,
  ToSaveDate,
  initDate,
  initInput,
  initSelect,
  //   resetDynamicFields,
} from "../../../components/data";
import SelectField from "../../../components/formik fields/SelectField";
import useCountries from "../../../components/useCountries";
import RadioComp from "../../../components/RadioComp";
import useStatesByCountry from "../../../components/useStatesByCountry";
import { Modify, SingleOption } from "../../../../../types/types";
import { Dayjs } from "dayjs";
import { Info } from "../../../form ds 160/components/styles";
import FormBtn from "../../../components/FormBtn";
import { N600InfoAboutUSCitizenFatherPart2Payload } from "../../../../../api/n600/types";
import useToNextSection from "../../../../../hooks/useToNextSection";
import {
  useGetN600Eligibility,
  useGetN600InfoAboutUSCitizenFatherPart2,
  useSaveN600InfoAboutUSCitizenFatherPart2,
} from "../../../../../hooks/n600/useN600";
import { USCitizenFatherP2Validation } from "../validation";
import {
  SpouseImmigrationStatusData,
  SpouseImmigrationStatusValues,
} from "../data";

export interface USCitizenFatherP2FormData
  extends Modify<
    N600InfoAboutUSCitizenFatherPart2Payload,
    {
      fatherCurrentMaritalStatus: SingleOption | null;
      fatherSpouseDateOfBirth: Dayjs | null;

      fatherSpouseCountryOfBirth: SingleOption | null;
      fatherSpouseCountryOfCitizenship: SingleOption | null;
      fatherSpousePhysicalAddressAptSteFlr: SingleOption | null;
      fatherSpousePhysicalAddressState: SingleOption | null;
      fatherSpousePhysicalAddressCountry: SingleOption | null;
      fatherDateOfMarriage: Dayjs | null;
      fatherPlaceOfMarriageState: SingleOption | null;
      fatherPlaceOfMarriageCountry: SingleOption | null;
      fatherSpouseImmigrationStatus: SingleOption | null;
    }
  > {}

const USCitizenMotherP2 = () => {
  const { data, isFetching, isError } =
    useGetN600InfoAboutUSCitizenFatherPart2();
  const { mutate, isLoading: isSaving } =
    useSaveN600InfoAboutUSCitizenFatherPart2();
  const { data: eligibilityData } = useGetN600Eligibility();

  const { toNextSection } = useToNextSection();

  const isFatherCitizen =
    eligibilityData?.which_parent_is_a_us_citizen ===
    "My father is a U.S citizen";

  //skip this section if not to study
  useEffect(() => {
    if (!isFatherCitizen) {
      toNextSection();
    }
  }, [isFatherCitizen, toNextSection]);

  const {
    formattedCountryData: fatherSpouseCountryOfBirthData,
    countryLoading: fatherSpouseCountryOfBirthLoading,
  } = useCountries();

  const {
    formattedCountryData: fatherSpouseCountryOfCitizenshipData,
    countryLoading: fatherSpouseCountryOfCitizenshipLoading,
  } = useCountries();

  const {
    formattedCountryData: fatherPlaceOfMarriageCountryData,
    countryLoading: fatherPlaceOfMarriageCountryLoading,
  } = useCountries();

  const {
    formattedStateData: fatherPlaceOfMarriageStateData,
    stateLoading: fatherPlaceOfMarriageStateLoading,
    setCountry: fatherPlaceOfMarriageSetCountry,
    selectedCountry: fatherPlaceOfMarriageStateSelectedCountry,
  } = useStatesByCountry(data?.fatherPlaceOfMarriageCountry);

  const {
    formattedCountryData: fatherSpousePhysicalAddressCountryData,
    countryLoading: fatherSpousePhysicalAddressCountryLoading,
  } = useCountries();

  const {
    formattedStateData: fatherSpousePhysicalAddressStateData,
    stateLoading: fatherSpousePhysicalAddressStateLoading,
    setCountry: setFatherSpousePhysicalAddressSetCountry,
    selectedCountry: fatherSpousePhysicalAddressSelectedCountry,
  } = useStatesByCountry(
    data?.fatherSpousePhysicalAddressCountry || "United States"
  );

  const onSubmit = (values: USCitizenFatherP2FormData) => {
    const {
      fatherCurrentMaritalStatus,
      fatherSpouseDateOfBirth,
      fatherSpouseCountryOfBirth,
      fatherSpouseCountryOfCitizenship,
      fatherSpousePhysicalAddressAptSteFlr,
      fatherSpousePhysicalAddressState,
      fatherSpousePhysicalAddressCountry,
      fatherDateOfMarriage,
      fatherPlaceOfMarriageState,
      fatherPlaceOfMarriageCountry,
      fatherSpouseImmigrationStatus,
      ...rest
    } = values;

    const payload = {
      ...rest,
      fatherCurrentMaritalStatus: fatherCurrentMaritalStatus?.value || "",
      fatherSpouseDateOfBirth: ToSaveDate(fatherSpouseDateOfBirth),
      fatherSpouseCountryOfBirth: fatherSpouseCountryOfBirth?.value || "",
      fatherSpouseCountryOfCitizenship:
        fatherSpouseCountryOfCitizenship?.value || "",
      fatherSpousePhysicalAddressAptSteFlr:
        fatherSpousePhysicalAddressAptSteFlr?.value || "",
      fatherSpousePhysicalAddressState:
        fatherSpousePhysicalAddressState?.value || "",
      fatherSpousePhysicalAddressCountry:
        fatherSpousePhysicalAddressCountry?.value || "",
      fatherDateOfMarriage: ToSaveDate(fatherDateOfMarriage),
      fatherPlaceOfMarriageState: fatherPlaceOfMarriageState?.value || "",
      fatherPlaceOfMarriageCountry: fatherPlaceOfMarriageCountry?.value || "",
      fatherSpouseImmigrationStatus: fatherSpouseImmigrationStatus?.value || "",
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValues: USCitizenFatherP2FormData = {
    fatherLostCitizenship: initInput(data?.fatherLostCitizenship), // Has your father ever lost U.S. citizenship or taken any action that would cause loss of U.S. citizenship?
    fatherLostCitizenshipExplanation: initInput(
      data?.fatherLostCitizenshipExplanation
    ), // Explanation if father lost U.S. citizenship or took any action that would cause loss of U.S. citizenship

    fatherNumberOfMarriages: initInput(data?.fatherNumberOfMarriages), // How many times has your U.S. citizen father been married (including annulled marriages and marriages to the same person)?
    fatherCurrentMaritalStatus: initSelect(
      MaritalStatusData,
      data?.fatherCurrentMaritalStatus
    ), // What is your U.S. citizen father's current marital status?
    fatherCurrentMaritalStatusExplanation: initInput(
      data?.fatherCurrentMaritalStatusExplanation
    ), // Explanation if father's current marital status requires it

    // Information About U.S. Citizen Father's Current Spouse:
    fatherSpouseFamilyName: initInput(data?.fatherSpouseFamilyName), // Family Name (Last Name)
    fatherSpouseGivenName: initInput(data?.fatherSpouseGivenName), // Given Name (First Name)
    fatherSpouseMiddleName: initInput(data?.fatherSpouseMiddleName), // Middle Name
    fatherSpouseDateOfBirth: initDate(data?.fatherSpouseDateOfBirth), // Date of Birth (mm/dd/yyyy)
    fatherSpouseCountryOfBirth: initSelect(
      fatherSpouseCountryOfBirthData,
      data?.fatherSpouseCountryOfBirth
    ), // Country of Birth
    fatherSpouseCountryOfCitizenship: initSelect(
      fatherSpouseCountryOfCitizenshipData,
      data?.fatherSpouseCountryOfCitizenship
    ), // Country of Citizenship or Nationality

    // Spouse's Physical Address:
    fatherSpousePhysicalAddressStreetNumberAndName: initInput(
      data?.fatherSpousePhysicalAddressStreetNumberAndName
    ), // Street Number and Name
    fatherSpousePhysicalAddressAptSteFlr: initSelect(
      ApartmentSuiteFloorData,
      data?.fatherSpousePhysicalAddressAptSteFlr
    ), // Apt. Ste. Flr.
    fatherSpousePhysicalAddressAptSteFlrNumber: initInput(
      data?.fatherSpousePhysicalAddressAptSteFlrNumber
    ), // Apt. Ste. Flr. Number
    fatherSpousePhysicalAddressCityOrTown: initInput(
      data?.fatherSpousePhysicalAddressCityOrTown
    ), // City or Town
    fatherSpousePhysicalAddressState: initSelect(
      fatherSpousePhysicalAddressStateData,
      data?.fatherSpousePhysicalAddressState
    ), // State
    fatherSpousePhysicalAddressZipCode: initInput(
      data?.fatherSpousePhysicalAddressZipCode
    ), // ZIP Code
    fatherSpousePhysicalAddressProvince: initInput(
      data?.fatherSpousePhysicalAddressProvince
    ), // Province (foreign address only)
    fatherSpousePhysicalAddressPostalCode: initInput(
      data?.fatherSpousePhysicalAddressPostalCode
    ), // Postal Code (foreign address only)
    fatherSpousePhysicalAddressCountry: initSelect(
      fatherSpousePhysicalAddressCountryData,
      data?.fatherSpousePhysicalAddressCountry
    ), // Country (foreign address only)

    fatherDateOfMarriage: initDate(data?.fatherDateOfMarriage), // Date of Marriage (mm/dd/yyyy)

    // Place of Marriage:
    fatherPlaceOfMarriageCityOrTown: initInput(
      data?.fatherPlaceOfMarriageCityOrTown
    ), // City or Town
    fatherPlaceOfMarriageState: initSelect(
      fatherPlaceOfMarriageStateData,
      data?.fatherPlaceOfMarriageState
    ), // State
    fatherPlaceOfMarriageCountry: initSelect(
      fatherPlaceOfMarriageCountryData,
      data?.fatherPlaceOfMarriageCountry
    ), // Country

    fatherSpouseImmigrationStatus: initSelect(
      SpouseImmigrationStatusData,
      data?.fatherSpouseImmigrationStatus
    ), // Spouse's Immigration Status
    fatherSpouseImmigrationStatusExplanation: initInput(
      data?.fatherSpouseImmigrationStatusExplanation
    ), // Explanation if spouse's immigration status requires it

    isFatherCurrentSpouseBiologicalOrAdoptiveMother: initInput(
      data?.isFatherCurrentSpouseBiologicalOrAdoptiveMother
    ), // Is your U.S. citizen father's current spouse also your biological (or adopted) mother?
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={USCitizenFatherP2Validation}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <RadioComp
                  title="Has your father ever lost U.S. citizenship or taken any action that would cause loss of U.S. citizenship?"
                  name="fatherLostCitizenship"
                  options={GenericYesNo}
                />
              </FormWrapper>

              {formik.values.fatherLostCitizenship ===
                GenericYesNoOptionsValue.yes && (
                <FormWrapper>
                  <InputField
                    label="Explain"
                    name="fatherLostCitizenshipExplanation"
                    placeholder=""
                  />
                  <div></div>
                </FormWrapper>
              )}

              <Info>Marital History</Info>

              <FormWrapper>
                <InputField
                  label="How many times has your U.S. citizen father been married?"
                  coloredLabel="(including annulled marriages and marriages to the same person)"
                  name="fatherNumberOfMarriages"
                  placeholder=""
                />
                <div></div>
              </FormWrapper>

              <FormWrapper>
                <SelectField
                  label="What is your U.S. citizen father's current marital status?"
                  name="fatherCurrentMaritalStatus"
                  options={MaritalStatusData}
                  onChange={(value) => {
                    // console.log(value);
                    // console.log(
                    //   formik.values.fatherCurrentMaritalStatus?.value
                    // );
                    formik.setFieldValue(
                      "fatherCurrentMaritalStatusExplanation",
                      ""
                    );
                    formik.setFieldValue("fatherSpouseFamilyName", "");
                    formik.setFieldValue("fatherSpouseGivenName", "");
                    formik.setFieldValue("fatherSpouseMiddleName", "");
                    formik.setFieldValue(
                      "fatherSpousePhysicalAddressStreetNumberAndName",
                      ""
                    );
                    formik.setFieldValue(
                      "fatherSpousePhysicalAddressAptSteFlr",
                      ""
                    );
                    formik.setFieldValue(
                      "fatherSpousePhysicalAddressAptSteFlrNumber",
                      ""
                    );
                    formik.setFieldValue(
                      "fatherSpousePhysicalAddressCityOrTown",
                      ""
                    );
                    formik.setFieldValue(
                      "fatherSpousePhysicalAddressZipCode",
                      ""
                    );
                    formik.setFieldValue(
                      "fatherSpousePhysicalAddressProvince",
                      ""
                    );
                    formik.setFieldValue(
                      "fatherSpousePhysicalAddressPostalCode",
                      ""
                    );
                    formik.setFieldValue("fatherPlaceOfMarriageCityOrTown", "");
                    formik.setFieldValue("fatherSpouseDateOfBirth", null);
                    formik.setFieldValue("fatherSpouseCountryOfBirth", null);
                    formik.setFieldValue(
                      "fatherSpouseCountryOfCitizenship",
                      null
                    );
                    formik.setFieldValue(
                      "fatherSpousePhysicalAddressState",
                      null
                    );
                    formik.setFieldValue(
                      "fatherSpousePhysicalAddressCountry",
                      null
                    );
                    formik.setFieldValue("fatherDateOfMarriage", null);
                    formik.setFieldValue("fatherPlaceOfMarriageState", null);
                    formik.setFieldValue("fatherPlaceOfMarriageCountry", null);
                  }}
                />
                <div></div>
              </FormWrapper>

              {formik.values.fatherCurrentMaritalStatus?.value ===
                MaritalStatusValues.other && (
                <FormWrapper>
                  <InputField
                    label="Explain"
                    name="fatherCurrentMaritalStatusExplanation"
                    placeholder=""
                  />
                  <div></div>
                </FormWrapper>
              )}

              {formik.values.fatherCurrentMaritalStatus?.value ===
                MaritalStatusValues.married && (
                <>
                  <Info>
                    Information About U.S. Citizen Father's Current Spouse:
                  </Info>
                  <FormWrapper>
                    <InputField
                      label="Family Name (Last Name)"
                      name="fatherSpouseFamilyName"
                      placeholder=""
                    />

                    <InputField
                      label="Given Name (First Name)"
                      name="fatherSpouseGivenName"
                      placeholder=""
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <InputField
                      label="Middle Name"
                      name="fatherSpouseMiddleName"
                      placeholder=""
                    />
                    <div></div>
                  </FormWrapper>

                  <FormWrapper>
                    <DatePickerField
                      label="Date of Birth (mm/dd/yyyy)"
                      name="fatherSpouseDateOfBirth"
                    />

                    <SelectField
                      label="Country of Birth"
                      name="fatherSpouseCountryOfBirth"
                      placeholder="-Select-"
                      options={fatherSpouseCountryOfBirthData}
                      isDisabled={fatherSpouseCountryOfBirthLoading}
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <SelectField
                      label="Country of Citizenship or Nationality"
                      name="fatherSpouseCountryOfCitizenship"
                      placeholder="-Select-"
                      options={fatherSpouseCountryOfCitizenshipData}
                      isDisabled={fatherSpouseCountryOfCitizenshipLoading}
                    />

                    <div></div>
                  </FormWrapper>

                  <Info>Spouse's Physical Address:</Info>

                  <FormWrapper>
                    <InputField
                      label="Street Number and Name"
                      name="fatherSpousePhysicalAddressStreetNumberAndName"
                      placeholder=""
                    />

                    <div></div>
                  </FormWrapper>

                  <FormWrapper>
                    <SelectField
                      label="Apartment Suite or Floor"
                      name="fatherSpousePhysicalAddressAptSteFlr"
                      placeholder="-Select-"
                      options={ApartmentSuiteFloorData}
                    />

                    <div>
                      {formik.values.fatherSpousePhysicalAddressAptSteFlr
                        ?.value && (
                        <InputField
                          label={`${formik.values.fatherSpousePhysicalAddressAptSteFlr?.value} Number`}
                          name="fatherSpousePhysicalAddressAptSteFlrNumber"
                          placeholder=""
                        />
                      )}
                    </div>
                  </FormWrapper>

                  <FormWrapper>
                    <InputField
                      label="City or Town"
                      name="fatherSpousePhysicalAddressCityOrTown"
                      placeholder=""
                    />

                    <SelectField
                      label="State"
                      name="fatherSpousePhysicalAddressState"
                      placeholder="-Select-"
                      options={fatherSpousePhysicalAddressStateData}
                      isLoading={fatherSpousePhysicalAddressStateLoading}
                      isDisabled={!fatherSpousePhysicalAddressSelectedCountry}
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <InputField
                      label="ZIP Code"
                      name="fatherSpousePhysicalAddressZipCode"
                      placeholder=""
                    />

                    <InputField
                      label="Province"
                      coloredLabel="(foreign address only)"
                      name="fatherSpousePhysicalAddressProvince"
                      placeholder=""
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <InputField
                      label="Postal Code"
                      coloredLabel="(foreign address only)"
                      name="fatherSpousePhysicalAddressPostalCode"
                      placeholder=""
                    />

                    <SelectField
                      name="fatherSpousePhysicalAddressCountry"
                      label="Country"
                      coloredLabel="(foreign address only)"
                      placeholder="-Select-"
                      options={fatherSpousePhysicalAddressCountryData}
                      isLoading={fatherSpousePhysicalAddressCountryLoading}
                      onChange={(value) => {
                        // console.log(value);
                        // console.log(
                        //   formik.values.fatherSpousePhysicalAddressCountry
                        //     ?.value
                        // );
                        setFatherSpousePhysicalAddressSetCountry(value.label);
                        formik.setFieldValue(
                          "fatherSpousePhysicalAddressState",
                          null
                        );
                      }}
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <DatePickerField
                      label="Date of Marriage (mm/dd/yyyy)"
                      name="fatherDateOfMarriage"
                    />

                    <div></div>
                  </FormWrapper>

                  <Info>Place of Marriage:</Info>

                  <FormWrapper>
                    <InputField
                      label="City or Town"
                      name="fatherPlaceOfMarriageCityOrTown"
                      placeholder=""
                    />

                    <SelectField
                      label="State"
                      name="fatherPlaceOfMarriageState"
                      placeholder="-Select-"
                      options={fatherPlaceOfMarriageStateData}
                      isLoading={fatherPlaceOfMarriageStateLoading}
                      isDisabled={!fatherPlaceOfMarriageStateSelectedCountry}
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <SelectField
                      label="Country"
                      name="fatherPlaceOfMarriageCountry"
                      placeholder="-Select-"
                      options={fatherPlaceOfMarriageCountryData}
                      isLoading={fatherPlaceOfMarriageCountryLoading}
                      onChange={(value) => {
                        // console.log(value);
                        // console.log(
                        //   formik.values.fatherPlaceOfMarriageCountry?.value
                        // );
                        fatherPlaceOfMarriageSetCountry(value.label);
                        formik.setFieldValue(
                          "fatherPlaceOfMarriageState",
                          null
                        );
                      }}
                    />

                    <div></div>
                  </FormWrapper>

                  <FormWrapper>
                    <SelectField
                      label="Spouse's Immigration Status"
                      name="fatherSpouseImmigrationStatus"
                      placeholder="-Select-"
                      options={SpouseImmigrationStatusData}
                      onChange={(value) => {
                        formik.setFieldValue(
                          "fatherSpouseImmigrationStatusExplanation",
                          ""
                        );
                      }}
                    />

                    <div>
                      {formik.values.fatherSpouseImmigrationStatus?.value ===
                        SpouseImmigrationStatusValues.other && (
                        <InputField
                          label="Explain"
                          name="fatherSpouseImmigrationStatusExplanation"
                          placeholder=""
                        />
                      )}
                    </div>
                  </FormWrapper>

                  <FormWrapper>
                    <RadioComp
                      title="Is your U.S. citizen father's current spouse also your biological (or adopted) mother?"
                      name="isFatherCurrentSpouseBiologicalOrAdoptiveMother"
                      options={GenericYesNo}
                    />
                  </FormWrapper>
                </>
              )}

              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default USCitizenMotherP2;

const Wrapper = styled.div``;
