import { useSnapshot } from "valtio";
import { userDataStore } from "../store/userData";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { isBusinessDataStore } from "../store/isBusinessData";
import { applicationDataStore } from "../store/applicationData";

const useIsPaid = () => {
  const { formCategory: urlFormCategory } = useParams();
  const { userData } = useSnapshot(userDataStore);
  const { isBusinessData } = useSnapshot(isBusinessDataStore);
  const { applicationData } = useSnapshot(applicationDataStore);
  const [isFormPaid, setIsFormPaid] = useState(false);

  const getIsPaid = useCallback(
    (formType?: string) => {
      if (isBusinessData) {
        return applicationData?.applicationInfo?.isPaid || false;
      } else {
        if (!userData) return false;
        const result = userData.caseHistory.find(
          (ev) => (formType || urlFormCategory) === ev.caseType && ev.Payment
        );
        return result ? true : false;
      }
    },
    [
      urlFormCategory,
      userData,
      isBusinessData,
      applicationData?.applicationInfo?.isPaid,
    ]
  );

  useEffect(() => {
    const result = getIsPaid();
    setIsFormPaid(result);
  }, [getIsPaid]);

  return { isFormPaid, getIsPaid };
};

export default useIsPaid;
