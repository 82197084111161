import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import styled from "styled-components";
import { useField } from "formik";
import { Dayjs } from "dayjs";
import Colors from "../../../../styles/Colors";
import { mediaObj } from "../../../../styles/Media";
import Tooltip, { TooltipProps } from "../Tooltip";
import { DateView } from "@mui/x-date-pickers";
import { ExplicitNGDateFormat, USDatePlaceholderFormat } from "../data";

interface Props {
  name: string;
  label: string;
  placeholder?: string;
  value?: Dayjs | null;
  onChange?: (ev: Dayjs | null) => void;
  isRequired?: boolean;
  isDisabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  toolTipProps?: TooltipProps;
  bgColor?: string;
  views?: DateView[];
  format?: string;
}

const DatePickerField: React.FC<Props> = ({
  label,
  placeholder = USDatePlaceholderFormat,
  value,
  isRequired,
  onChange,
  isDisabled,
  disableFuture,
  disablePast,
  toolTipProps,
  bgColor,
  views,
  format,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const isError = !!(meta.touched && meta.error);
  const errorClass = `${meta.touched && meta.error && "error"} ${
    isRequired ? "required" : ""
  }`;

  return (
    <Container isError={isError} bgColor={bgColor} isDisabled={isDisabled}>
      <Label htmlFor={props.name} className={errorClass}>
        <div>{label}</div>
        {toolTipProps && (
          <Tooltip toolTipProps={toolTipProps} parentName={props.name} />
        )}
      </Label>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          {...field}
          {...meta}
          className="date-picker"
          // value={value}
          disabled={isDisabled}
          disableFuture={disableFuture}
          disablePast={disablePast}
          onChange={(newValue) => {
            helpers.setValue(newValue);
            // onChange && onChange(newValue);
          }}
          format={
            format
              ? format
              : placeholder === ExplicitNGDateFormat
              ? ExplicitNGDateFormat
              : undefined
          }
          views={views}
          sx={{
            "& .MuiInputBase-root": {
              height: "40px",
              fontFamily: "Lato, sans-serif",
              fontWeight: 450,
              fontSize: "1rem",
            },
          }}
          slotProps={{
            textField: {
              inputProps: { readOnly: true },
              placeholder: placeholder,
            },
            popper: {
              disablePortal: true,
              className: "date-popper",
            },
          }}
        />
      </LocalizationProvider>
      {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
    </Container>
  );
};

export default DatePickerField;

interface ContainerProps {
  isError: boolean;
  isDisabled?: boolean;
  bgColor?: string;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * {
    font-family: "Lato", sans-serif;
    font-weight: 450;
    font-size: 16px;
    color: #000000;
  }

  & > div:nth-child(1) {
    width: 100%;
  }

  .date-picker {
    border: ${({ isError }) =>
      isError ? `1px solid ${Colors.RedDA}` : `1px solid ${Colors.GreyA7}`};
    border-radius: 4px;
    height: 40px;
    background-color: ${({ bgColor, isDisabled }) =>
      isDisabled ? "rgba(27, 9, 44, 0.05)" : bgColor ? bgColor : `white`};
  }

  .MuiOutlinedInput-root {
    & > fieldset {
      border: none;
    }
  }

  .MuiOutlinedInput-root:hover {
    & > fieldset {
      border: none;
    }
  }

  .MuiPaper-root * {
    font-family: "Lato", sans-serif;
    font-weight: 450;
  }

  .date-popper .MuiPickersDay-root:focus {
    background-color: ${Colors.White};
  }

  .date-popper .Mui-selected,
  .date-popper .Mui-selected:hover,
  .date-popper .Mui-selected:focus {
    background-color: ${Colors.Blue00};
  }
`;

const Label = styled.label`
  margin-bottom: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: ${Colors.Black31};
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.required:after {
    content: "*";
    font-size: 24px;
    line-height: 0;
    vertical-align: middle;
    color: #d90b2c;
  }

  ${mediaObj.mobile} {
    font-size: 14px;
  }
`;

const Error = styled.div`
  color: red;
  font-size: 0.875rem;
`;
