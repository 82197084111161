import styled from "styled-components";
import { FieldArray, Form, Formik } from "formik";
import {
  n400CheckPart2Res,
  N400EligibilityPayload,
} from "../../../../../api/n400/types";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { FormWrapper } from "../../../components/styles";
import RadioComp from "../../../components/RadioComp";
import {
  GenericYesNo,
  GenericYesNoOptionsValue,
  initDate,
  initInput,
  SpecialSeparator,
  ToSaveDate,
} from "../../../components/data";
import { getCheckListFieldValidation } from "../data";
import { Title } from "../styles";
import FormBtn from "../../../components/FormBtn";
// import useToNextSection from "../../../../../hooks/useToNextSection";
import {
  useGetN400CheckPart2,
  useSaveN400CheckPart2,
} from "../../../../../hooks/n400/useN400";
import { Modify } from "../../../../../types/types";
import InputField from "../../../components/formik fields/InputField";
import DatePickerField from "../../../components/formik fields/DatePickerField";
import GroupedSection from "../../../components/GroupedSection";
import AddAnotherBtn from "../../../components/AddAnotherBtn";
import Colors from "../../../../../styles/Colors";
import { CheckPart2Validation } from "../validation";
import { Dayjs } from "dayjs";
import useToNextSection from "../../../../../hooks/useToNextSection";

const initialCrimeRecordsValues = {
  crime_or_offense: "",
  date_of_crime: null,
  date_of_conviction: null,
  place_of_crime: "",
  disposition_of_arrest_citation_charge: "",
  what_was_your_sentence: "",
};

export interface crimeRecordPropsPayload {
  crime_or_offense: string;
  date_of_crime: Dayjs | null;
  date_of_conviction: Dayjs | null;
  place_of_crime: string;
  disposition_of_arrest_citation_charge: string;
  what_was_your_sentence: string;
}
export interface CheckFormData2
  extends Modify<
    Omit<
      n400CheckPart2Res,
      | "crime_or_offense"
      | "date_of_crime"
      | "date_of_conviction"
      | "place_of_crime"
      | "disposition_of_arrest_citation_charge"
      | "what_was_your_sentence"
    >,
    {
      crimeRecordsArray: crimeRecordPropsPayload[];
      ever_committed_or_attempted_to_commit_a_crime_risky_checkbox: boolean;
      ever_been_arrested_by_law_enforcement_officer_risky_checkbox: boolean;
      ever_received_a_suspended_sentence_risky_checkbox: boolean;
      ever_been_a_prostitute_risky_checkbox: boolean;
      ever_sold_or_smuggled_narcotics_risky_checkbox: boolean;
      ever_been_married_to_one_person_at_a_time_risky_checkbox: boolean;
      ever_been_married_to_obtain_immigration_benefit_risky_checkbox: boolean;
      ever_helped_anyone_enter_illegally_risky_checkbox: boolean;
      ever_gambled_illegally_risky_checkbox: boolean;
      ever_failed_to_support_dependents_risky_checkbox: boolean;
      ever_given_any_official_false_documentation_risky_checkbox: boolean;
      ever_lied_to_any_official_to_gain_entry_risky_checkbox: boolean;
    }
  > {}

interface Props {
  formEligibility: N400EligibilityPayload;
}

const CheckPart2: React.FC<Props> = ({ formEligibility }) => {
  const { toNextSection } = useToNextSection();
  const { data, isFetching, isError } = useGetN400CheckPart2();
  const { mutate, isLoading: isSaving } = useSaveN400CheckPart2();

  const savedCrimeRecords = () => {
    if (
      data?.crime_or_offense ||
      data?.date_of_crime ||
      data?.date_of_conviction ||
      data?.place_of_crime ||
      data?.disposition_of_arrest_citation_charge ||
      data?.what_was_your_sentence
    ) {
      const crime_or_offense_data = data?.crime_or_offense
        .split(SpecialSeparator)
        .map((ev: any) => ({ crime_or_offense: ev }));
      const date_of_crime_data = data?.date_of_crime
        .split(SpecialSeparator)
        .map((ev: any) => ({ date_of_crime: initDate(ev) }));
      const date_of_conviction_data = data?.date_of_conviction
        .split(",")
        .map((ev: any) => ({ date_of_conviction: initDate(ev) }));

      // permanent patch for now
      const place_of_crime_data = data?.place_of_crime
        .split(",")
        .map((ev: any) => {
          return {
            place_of_crime: ev,
          };
        });
      const disposition_of_arrest_citation_charge_data =
        data?.disposition_of_arrest_citation_charge
          .split(",")
          .map((ev: any) => ({ disposition_of_arrest_citation_charge: ev }));

      const what_was_your_sentence_data = data?.what_was_your_sentence
        ?.split(",")
        .map((ev: any) => ({ what_was_your_sentence: ev }));

      return crime_or_offense_data.map((ev: any, i: any) => ({
        ...ev,
        ...date_of_crime_data[i],
        ...date_of_conviction_data[i],
        ...place_of_crime_data[i],
        ...disposition_of_arrest_citation_charge_data[i],
        ...what_was_your_sentence_data[i],
      }));
    } else return [initialCrimeRecordsValues];
  };

  const onSubmit = (values: CheckFormData2) => {
    const {
      ever_committed_or_attempted_to_commit_a_crime_risky_checkbox,
      ever_been_arrested_by_law_enforcement_officer_risky_checkbox,
      ever_received_a_suspended_sentence_risky_checkbox,
      ever_been_a_prostitute_risky_checkbox,
      ever_sold_or_smuggled_narcotics_risky_checkbox,
      ever_been_married_to_one_person_at_a_time_risky_checkbox,
      ever_been_married_to_obtain_immigration_benefit_risky_checkbox,
      ever_helped_anyone_enter_illegally_risky_checkbox,
      ever_gambled_illegally_risky_checkbox,
      ever_failed_to_support_dependents_risky_checkbox,
      ever_given_any_official_false_documentation_risky_checkbox,
      ever_lied_to_any_official_to_gain_entry_risky_checkbox,
      crimeRecordsArray,
      ...rest
    } = values;

    const payload = {
      ...rest,
      crime_or_offense: crimeRecordsArray
        .map((ev) => ev.crime_or_offense)
        .join(","),
      date_of_conviction: crimeRecordsArray
        .map((ev) => ToSaveDate(ev.date_of_conviction))
        .join(","),
      date_of_crime: crimeRecordsArray
        .map((ev) => ToSaveDate(ev.date_of_crime))
        .join(","),
      disposition_of_arrest_citation_charge: crimeRecordsArray
        .map((ev) => ev.disposition_of_arrest_citation_charge)
        .join(","),
      place_of_crime: crimeRecordsArray
        .map((ev) => ev.place_of_crime)
        .join(","),
      what_was_your_sentence: crimeRecordsArray
        .map((ev) => ev.what_was_your_sentence)
        .join(","),
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValue: CheckFormData2 = {
    ever_committed_or_attempted_to_commit_a_crime: initInput(
      data?.ever_committed_or_attempted_to_commit_a_crime
    ),
    ever_been_arrested_by_law_enforcement_officer: initInput(
      data?.ever_been_arrested_by_law_enforcement_officer
    ),

    crimeRecordsArray: savedCrimeRecords(),

    ever_received_a_suspended_sentence: initInput(
      data?.ever_received_a_suspended_sentence
    ),
    ever_been_a_prostitute: initInput(data?.ever_been_a_prostitute),
    ever_been_a_prostitute_exp: initInput(data?.ever_been_a_prostitute_exp),
    ever_sold_or_smuggled_narcotics: initInput(
      data?.ever_sold_or_smuggled_narcotics
    ),

    ever_sold_or_smuggled_narcotics_exp: initInput(
      data?.ever_sold_or_smuggled_narcotics_exp
    ),

    ever_been_married_to_one_person_at_a_time: initInput(
      data?.ever_been_married_to_one_person_at_a_time
    ),

    ever_been_married_to_one_person_at_a_time_exp: initInput(
      data?.ever_been_married_to_one_person_at_a_time_exp
    ),

    ever_been_married_to_obtain_immigration_benefit: initInput(
      data?.ever_been_married_to_obtain_immigration_benefit
    ),

    ever_been_married_to_obtain_immigration_benefit_exp: initInput(
      data?.ever_been_married_to_obtain_immigration_benefit_exp
    ),

    ever_helped_anyone_enter_illegally: initInput(
      data?.ever_helped_anyone_enter_illegally
    ),

    ever_helped_anyone_enter_illegally_exp: initInput(
      data?.ever_helped_anyone_enter_illegally_exp
    ),

    ever_gambled_illegally: initInput(data?.ever_gambled_illegally),

    ever_gambled_illegally_exp: initInput(data?.ever_gambled_illegally_exp),

    ever_failed_to_support_dependents: initInput(
      data?.ever_failed_to_support_dependents
    ),

    ever_failed_to_support_dependents_exp: initInput(
      data?.ever_failed_to_support_dependents_exp
    ),

    ever_given_any_official_false_documentation: initInput(
      data?.ever_given_any_official_false_documentation
    ),

    ever_given_any_official_false_documentation_exp: initInput(
      data?.ever_given_any_official_false_documentation_exp
    ),

    ever_made_any_misrepresentation_to_obtain_any_public_benefit: initInput(
      data?.ever_made_any_misrepresentation_to_obtain_any_public_benefit
    ),

    ever_made_any_misrepresentation_to_obtain_any_public_benefit_exp: initInput(
      data?.ever_made_any_misrepresentation_to_obtain_any_public_benefit_exp
    ),

    ever_lied_to_any_official_to_gain_entry: initInput(
      data?.ever_lied_to_any_official_to_gain_entry
    ),

    ever_lied_to_any_official_to_gain_entry_exp: initInput(
      data?.ever_lied_to_any_official_to_gain_entry_exp
    ),

    ever_committed_or_attempted_to_commit_a_crime_risky_checkbox: false,
    ever_been_arrested_by_law_enforcement_officer_risky_checkbox: false,
    ever_received_a_suspended_sentence_risky_checkbox: false,
    ever_been_a_prostitute_risky_checkbox: false,
    ever_sold_or_smuggled_narcotics_risky_checkbox: false,
    ever_been_married_to_one_person_at_a_time_risky_checkbox: false,
    ever_been_married_to_obtain_immigration_benefit_risky_checkbox: false,
    ever_helped_anyone_enter_illegally_risky_checkbox: false,
    ever_gambled_illegally_risky_checkbox: false,
    ever_failed_to_support_dependents_risky_checkbox: false,
    ever_given_any_official_false_documentation_risky_checkbox: false,
    ever_lied_to_any_official_to_gain_entry_risky_checkbox: false,
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValue}
          onSubmit={onSubmit}
          validationSchema={CheckPart2Validation}
        >
          {(formik) => (
            <Form>
              <Title>
                If you answer “Yes” to any part of Item Number 15. below,
                complete the table below with each crime or offense even if your
                records have been sealed, expunged, or otherwise cleared. You
                must disclose this information even if someone, including a
                judge, law enforcement officer, or attorney, told you that it is
                no longer on your record, or told you that you do not have to
                disclose the information.
              </Title>

              <FormWrapper>
                <RadioComp
                  name="ever_committed_or_attempted_to_commit_a_crime"
                  title="15.a. Have you EVER committed, agreed to commit, asked someone else to commit, helped commit, or tried to commit a crime or offense for which you were NOT arrested?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_committed_or_attempted_to_commit_a_crime"
                  )}
                />
              </FormWrapper>

              <FormWrapper>
                <RadioComp
                  name="ever_been_arrested_by_law_enforcement_officer"
                  title={`15.b. Have you EVER been arrested, cited, detained or confined by any law enforcement officer, military official (in the U.S. or elsewhere), or immigration official for any reason, or been charged with a crime or offense?`}
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_been_arrested_by_law_enforcement_officer"
                  )}
                />
              </FormWrapper>

              {(formik.values.ever_committed_or_attempted_to_commit_a_crime ===
                GenericYesNoOptionsValue.yes ||
                formik.values.ever_been_arrested_by_law_enforcement_officer ===
                  GenericYesNoOptionsValue.yes) && (
                <FieldArray
                  name="crimeRecordsArray"
                  render={(arrayHelpers) => (
                    <div className="px-2 py-3 my-8 border rounded">
                      {formik.values.crimeRecordsArray.map((_, index) => (
                        <GroupedSection
                          key={index}
                          index={index}
                          onCancel={() => arrayHelpers.remove(index)}
                        >
                          <div className={`text-[${Colors.Blue00}] mt-4`}>
                            {index === 0 ? "Entry 1:" : `Entry ${index + 1}:`}
                          </div>

                          <FormWrapper>
                            <div>
                              <InputField
                                name={`crimeRecordsArray[${index}].crime_or_offense`}
                                label="What was the crime or offense?"
                                placeholder=""
                              />
                            </div>
                            <div>
                              <DatePickerField
                                name={`crimeRecordsArray[${index}].date_of_crime`}
                                label="Date of the Crime or Offense"
                                placeholder=""
                              />
                            </div>
                          </FormWrapper>

                          <FormWrapper>
                            <div>
                              <DatePickerField
                                name={`crimeRecordsArray[${index}].date_of_conviction`}
                                label="Date of your conviction or guilty plea"
                                placeholder=""
                              />
                            </div>
                            <div>
                              <InputField
                                name={`crimeRecordsArray[${index}].place_of_crime`}
                                label="Place of Crime or Offense"
                                placeholder=""
                              />
                            </div>
                          </FormWrapper>

                          <FormWrapper>
                            <div>
                              <InputField
                                name={`crimeRecordsArray[${index}].disposition_of_arrest_citation_charge`}
                                label="What was the result or disposition of the arrest, citation, or charge?"
                                placeholder=""
                              />
                            </div>
                            <div>
                              <InputField
                                name={`crimeRecordsArray[${index}].what_was_your_sentence`}
                                label="What was your sentence"
                                placeholder=""
                              />
                            </div>
                          </FormWrapper>
                        </GroupedSection>
                      ))}
                      <AddAnotherBtn
                        onClick={() =>
                          arrayHelpers.push(initialCrimeRecordsValues)
                        }
                      />
                    </div>
                  )}
                />
              )}

              <FormWrapper>
                <RadioComp
                  name="ever_received_a_suspended_sentence"
                  title="16. If you received a suspended sentence, were placed on probation, or were paroled, have you completed your suspended sentence, probation, or parole?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_received_a_suspended_sentence",
                    true
                  )}
                />
              </FormWrapper>

              {/* <Sub>
                If you answer “Yes” to any of the questions in Item Numbers
                17.a. - 19., provide an explanation in the space provided in
                Part 14. Additional Information. Submit evidence to support your
                answers.
              </Sub> */}

              <FormWrapper>
                <RadioComp
                  name="ever_been_a_prostitute"
                  title="17.a. Engaged in prostitution, attempted to procure or import prostitutes or persons for the purpose of prostitution, or received any proceeds or money from prostitution?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_been_a_prostitute"
                  )}
                />
              </FormWrapper>

              {formik.values.ever_been_a_prostitute ===
                GenericYesNoOptionsValue.yes && (
                <FormWrapper>
                  <div>
                    <InputField
                      name="ever_been_a_prostitute_exp"
                      label="Explanation"
                      inputType="text-area"
                      placeholder=""
                    />
                  </div>

                  <div></div>
                </FormWrapper>
              )}

              <FormWrapper>
                <RadioComp
                  name="ever_sold_or_smuggled_narcotics"
                  title="17.b. Manufactured, cultivated, produced, distributed, dispensed, sold, or smuggled (trafficked) any controlled substances, illegal drugs, narcotics, or drug paraphernalia in violation of any law or regulation of a U.S. state, the United States, or a foreign country?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_sold_or_smuggled_narcotics"
                  )}
                />
              </FormWrapper>

              {formik.values.ever_sold_or_smuggled_narcotics ===
                GenericYesNoOptionsValue.yes && (
                <FormWrapper>
                  <div>
                    <InputField
                      name="ever_sold_or_smuggled_narcotics_exp"
                      label="Explanation"
                      inputType="text-area"
                      placeholder=""
                    />
                  </div>

                  <div></div>
                </FormWrapper>
              )}

              <FormWrapper>
                <RadioComp
                  name="ever_been_married_to_one_person_at_a_time"
                  title="17.c. Been married to more than one person at the same time?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_been_married_to_one_person_at_a_time"
                  )}
                />
              </FormWrapper>

              {formik.values.ever_been_married_to_one_person_at_a_time ===
                GenericYesNoOptionsValue.yes && (
                <FormWrapper>
                  <div>
                    <InputField
                      name="ever_been_married_to_one_person_at_a_time_exp"
                      label="Explanation"
                      inputType="text-area"
                      placeholder=""
                    />
                  </div>

                  <div></div>
                </FormWrapper>
              )}

              <FormWrapper>
                <RadioComp
                  name="ever_been_married_to_obtain_immigration_benefit"
                  title="17.d. Married someone in order to obtain an immigration benefit?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_been_married_to_obtain_immigration_benefit"
                  )}
                />
              </FormWrapper>

              {formik.values.ever_been_married_to_obtain_immigration_benefit ===
                GenericYesNoOptionsValue.yes && (
                <FormWrapper>
                  <div>
                    <InputField
                      name="ever_been_married_to_obtain_immigration_benefit_exp"
                      label="Explanation"
                      inputType="text-area"
                      placeholder=""
                    />
                  </div>

                  <div></div>
                </FormWrapper>
              )}

              <FormWrapper>
                <RadioComp
                  name="ever_helped_anyone_enter_illegally"
                  title="17.e. Helped anyone to enter, or try to enter, the United States illegally?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_helped_anyone_enter_illegally"
                  )}
                />
              </FormWrapper>

              {formik.values.ever_helped_anyone_enter_illegally ===
                GenericYesNoOptionsValue.yes && (
                <FormWrapper>
                  <div>
                    <InputField
                      name="ever_helped_anyone_enter_illegally_exp"
                      label="Explanation"
                      inputType="text-area"
                      placeholder=""
                    />
                  </div>

                  <div></div>
                </FormWrapper>
              )}

              <FormWrapper>
                <RadioComp
                  name="ever_gambled_illegally"
                  title="17.f. Gambled illegally or received income from illegal gambling?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_gambled_illegally"
                  )}
                />
              </FormWrapper>

              {formik.values.ever_gambled_illegally ===
                GenericYesNoOptionsValue.yes && (
                <FormWrapper>
                  <div>
                    <InputField
                      name="ever_gambled_illegally_exp"
                      label="Explanation"
                      inputType="text-area"
                      placeholder=""
                    />
                  </div>

                  <div></div>
                </FormWrapper>
              )}

              <FormWrapper>
                <RadioComp
                  name="ever_failed_to_support_dependents"
                  title="17.g. Failed to support your dependents (pay child support) or to pay alimony (court-ordered financial support after divorce or separation)?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_failed_to_support_dependents"
                  )}
                />
              </FormWrapper>

              {formik.values.ever_failed_to_support_dependents ===
                GenericYesNoOptionsValue.yes && (
                <FormWrapper>
                  <div>
                    <InputField
                      name="ever_failed_to_support_dependents_exp"
                      label="Explanation"
                      inputType="text-area"
                      placeholder=""
                    />
                  </div>

                  <div></div>
                </FormWrapper>
              )}

              <FormWrapper>
                <RadioComp
                  name="ever_made_any_misrepresentation_to_obtain_any_public_benefit"
                  title="17.h. Made any misrepresentation to obtain any public benefit in the United States?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_made_any_misrepresentation_to_obtain_any_public_benefit"
                  )}
                />
              </FormWrapper>

              {formik.values
                .ever_made_any_misrepresentation_to_obtain_any_public_benefit ===
                GenericYesNoOptionsValue.yes && (
                <FormWrapper>
                  <div>
                    <InputField
                      name="ever_made_any_misrepresentation_to_obtain_any_public_benefit_exp"
                      label="Explanation"
                      inputType="text-area"
                      placeholder=""
                    />
                  </div>

                  <div></div>
                </FormWrapper>
              )}

              <FormWrapper>
                <RadioComp
                  name="ever_given_any_official_false_documentation"
                  title="18. Have you EVER given any U.S. Government officials any information or documentation that was false, fraudulent, or misleading?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_given_any_official_false_documentation"
                  )}
                />
              </FormWrapper>

              {formik.values.ever_given_any_official_false_documentation ===
                GenericYesNoOptionsValue.yes && (
                <FormWrapper>
                  <div>
                    <InputField
                      name="ever_given_any_official_false_documentation_exp"
                      label="Explanation"
                      inputType="text-area"
                      placeholder=""
                    />
                  </div>

                  <div></div>
                </FormWrapper>
              )}

              <FormWrapper>
                <RadioComp
                  name="ever_lied_to_any_official_to_gain_entry"
                  title="19. Have you EVER lied to any U.S. Government officials to gain entry or admission into the United States or to gain immigration benefits while in the United States?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_lied_to_any_official_to_gain_entry"
                  )}
                />
              </FormWrapper>

              {formik.values.ever_lied_to_any_official_to_gain_entry ===
                GenericYesNoOptionsValue.yes && (
                <FormWrapper>
                  <div>
                    <InputField
                      name="ever_lied_to_any_official_to_gain_entry_exp"
                      label="Explanation"
                      inputType="text-area"
                      placeholder=""
                    />
                  </div>

                  <div></div>
                </FormWrapper>
              )}

              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default CheckPart2;

const Wrapper = styled.div``;
