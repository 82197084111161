import React, { FC } from "react";
import styled from "styled-components";
import Colors from "../../../../../../styles/Colors";
import { Form, Formik } from "formik";
import UploadBox from "./upload/UploadBox";
import Spinner from "../../../../../../utils/Spinner";
import DocStatus from "./DocStatus";
import { GetFileDetailObj } from "../../../../../../api/n400/types";
import FileDownload from "./FileDownload";
import { DocChecklistProps } from "./data/types";

interface Props {
  savedData?: GetFileDetailObj;
  data: DocChecklistProps;
  onSubmit: (file: File, name: string, fileId?: string) => void;
  isLoading: boolean;
  isAdditional?: boolean;
}

const DocChecklistItem: FC<Props> = ({
  data,
  onSubmit,
  isLoading,
  savedData,
  isAdditional,
}) => {
  return (
    <Container>
      <Circle />
      <Wrapper>
        <MiniFlex>
          <MiniFlexTitle>
            <span className="capitalize ">{data.label}</span>
            {isAdditional && <OpText> {"(additional)"}</OpText>}
            {data.optional && (
              <OpText> {data.optionalText || "(if applicable)"}</OpText>
            )}
            {savedData && <DocStatus data={savedData} />}
          </MiniFlexTitle>

          <MiniFlexText>{data.info}</MiniFlexText>
          {savedData && savedData.note && savedData.not_recommended && (
            <InfoWrapper>
              <span>Admin Review:</span> <span>{savedData.note}</span>
            </InfoWrapper>
          )}
        </MiniFlex>
        {savedData && savedData?.recommended && (
          <FileDownload data={savedData} />
        )}
        {(!savedData || savedData?.not_recommended) && !data.isNotFile && (
          <Formik
            initialValues={{
              file: null,
            }}
            onSubmit={(values) => {
              if (!values.file) return;
              onSubmit(values.file, data.name, savedData?.id);
            }}
          >
            {(formik) => (
              <Form>
                <BtnWrapper>
                  <UploadBox
                    name={"file"}
                    formik={formik}
                    acceptedFormat={"image/*,.pdf"}
                    // maxFileSizeInBytes={OpinionMaxFileSize}
                    btnText="Upload"
                  />

                  {formik.values.file && (
                    <Btn type="submit">
                      {isLoading && <Spinner color={Colors.White} size={14} />}
                      Save
                    </Btn>
                  )}
                </BtnWrapper>
              </Form>
            )}
          </Formik>
        )}
      </Wrapper>
    </Container>
  );
};

export default DocChecklistItem;

const Container = styled.div`
  display: flex;
  gap: 12px;
`;

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: flex-start;
  gap: 100px;
`;

const Circle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: ${Colors.Blue00};
  flex-shrink: 0;
  margin-top: 4px;
`;

const MiniFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const MiniFlexTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
`;

const MiniFlexText = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: ${Colors.Grey8B};
`;

const OpText = styled.span`
  color: ${Colors.RedC3};
`;

const Btn = styled.button`
  text-transform: uppercase;
  padding: 7px 20px;
  border-radius: 6px;
  font-size: 0.75rem;
  color: ${Colors.White};
  background-color: ${Colors.Blue00};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const InfoWrapper = styled.div`
  font-size: 0.85rem;

  & > span:nth-child(1) {
    color: ${Colors.AmberFE};
    font-weight: bold;
  }
`;
