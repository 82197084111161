import React, { FC } from "react";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { N400ChildrenInfoObj } from "../../../../../../api/n400/types";
import { FormN400Progress } from "../../types";

interface Props {
  title: string;
  data?: N400ChildrenInfoObj;
}

const ReviewChildrenInfo: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      section: {
        title: "Information About Children",
        children: [
          {
            label: "How many children do you have?",
            value: data?.numOfChildren,
          },
          {
            label: "Name",
            value: data?.name?.replace(/,/g, ", "),
          },
          {
            label: "Date of Birth (yyyy-mm-dd)",
            value: data?.dateOfBirth,
          },
          {
            label: "Residence",
            value: data?.residence,
          },
          {
            label: "Relationship",
            value: data?.childRelationshipToYou,
          },
          {
            label: "Are you providing support for this child?h",
            value: data?.areYouProvidingSupport,
          },
          {
            section: {
              title: "Child's Current Address",
              children: [
                {
                  label: "In Care of Name (If any)",
                  value: data?.inCareOfName,
                },
                {
                  label: "Street number and name",
                  value: data?.streetNumberAndName?.replace(/,/g, ", "),
                },
                {
                  label: "Is there an apartment, suite or floor?",
                  value: data?.apartmentSuiteOrFloor?.replace(/,/g, ", "),
                },
                {
                  label: "City or Town",
                  value: data?.cityOrTown?.replace(/,/g, ", "),
                },
                {
                  label: "Country",
                  value: data?.country?.replace(/,/g, ", "),
                },
                {
                  label: "State or Territory",
                  value: data?.stateOrTerritory?.replace(/,/g, ", "),
                },
                {
                  label: "Zip Code",
                  value: data?.zipCode?.replace(/,/g, ", "),
                },
                {
                  label: "Province",
                  value: data?.province?.replace(/,/g, ", "),
                },
                {
                  label: "Postal Code",
                  value: data?.postalCode?.replace(/,/g, ", "),
                },
              ],
            },
          },
        ],
      },
    },
  ];

  return (
    <ReviewBox
      title={title}
      data={section}
      editUrl={FormN400Progress.childrenInfo}
    />
  );
};

export default ReviewChildrenInfo;
