import { FC } from "react";
import styled from "styled-components";
import Colors from "../../../../../../../styles/Colors";

interface Props {
  name: string;
  onViewAll?: () => void;
}

const Header: FC<Props> = ({ name, onViewAll }) => {
  return (
    <Wrapper>
      <ModuleTitle>{name}</ModuleTitle>
      {onViewAll && <Btn onClick={onViewAll}>View all</Btn>}
    </Wrapper>
  );
};

export default Header;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const Btn = styled.button`
  color: ${Colors.Blue00};
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 16px;
  text-decoration: underline;
`;

const ModuleTitle = styled.h5`
  font-size: 1rem;
  font-weight: 600;
  line-height: 19px;
`;
