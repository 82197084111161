import React from "react";
import { BoxWrapper, DashboardWrapper } from "../../styles";
import DocumentsTable from "./DocumentsTable";

const DocumentsHistory = () => {
  return (
    <DashboardWrapper>
      {/* <DashboardTitle>Documents History</DashboardTitle> */}
      <BoxWrapper>
        <DocumentsTable />
      </BoxWrapper>
    </DashboardWrapper>
  );
};

export default DocumentsHistory;
