import { IoRocket } from "react-icons/io5";
import { PiSealQuestionFill } from "react-icons/pi";
import { PiWechatLogoFill } from "react-icons/pi";

const features = [
  {
    name: "Realistic Interview Questions",
    description:
      "Get access to actual visa interview questions tailored to your visa type",
    // href: "#",
    icon: PiSealQuestionFill,
  },
  {
    name: "Instant Feedback",
    description:
      "Receive detailed feedback on your responses, helping you identify areas for improvement.",
    // href: "#",
    icon: PiWechatLogoFill,
  },
  {
    name: "Boost Confidence",
    description:
      "Reduce anxiety by familiarizing yourself with the interview process. ",
    // href: "#",
    icon: IoRocket,
  },
];

const Features = () => {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          {/* <h2 className="text-base/7 font-semibold text-blue-600">
            Deploy faster
          </h2> */}
          <p className="mt-2 text-pretty text-4xl font-bold font-spartan tracking-tight text-gray-900 sm:text-5xl lg:text-balance">
            Here’s why thousands of applicants trust FiBi AI for their U.S. visa
            interviews
          </p>
          {/* <p className="mt-6 text-lg/8 text-gray-600"></p> */}
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="flex items-center gap-x-3 text-base/7 font-semibold text-gray-900">
                  <feature.icon
                    aria-hidden="true"
                    className="h-5 w-5 flex-none text-blue-600"
                  />
                  {feature.name}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base/7 text-gray-600">
                  <p className="flex-auto">{feature.description}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Features;
