import { useSnapshot } from "valtio";
import { userDataStore } from "../store/userData";
import { useParams } from "react-router-dom";
import { useCallback } from "react";

const useIsCurrentOrFreshForm = () => {
  const { formCategory: urlFormCategory } = useParams();
  const { userData } = useSnapshot(userDataStore);

  const getIsCurrentForm = useCallback(
    (formType?: string) => {
      if (userData) {
        const formCategory = userData.currentFormInfo.formCategory;
        const result =
          formType === formCategory ||
          formCategory === "" ||
          urlFormCategory === formCategory;
        return result;
      } else return true;
    },
    [urlFormCategory, userData]
  );

  return { getIsCurrentForm };
};

export default useIsCurrentOrFreshForm;
