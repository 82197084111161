import React, { FC } from "react";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { N600MilitaryServicePayload } from "../../../../../../api/n600/types";
import { FormN600Progress } from "../../types";

interface Props {
  title: string;
  data?: N600MilitaryServicePayload;
}

const ReviewParentMilitaryService: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      label: "Has your U.S. citizen parent served in the U.S. Armed Forces?",
      value: data?.parentServedInUSArmedForces,
    },
    {
      label: "Which parent served in the U.S. Armed Forces?",
      value: data?.parentWhoServed,
    },
    {
      label: "From Date",
      value: data?.fromDate,
    },
    {
      label: "To Date",
      value: data?.toDate,
    },
    {
      label: "Type of Discharge",
      value: data?.typeOfDischarge,
    },
  ];

  return (
    <ReviewBox
      title={title}
      data={section}
      editUrl={FormN600Progress.infoAboutParentsMilitary}
    />
  );
};

export default ReviewParentMilitaryService;
