import {
  useGetDS160AIData,
  useSaveDs160Assessment,
} from "../../../../hooks/ds160/usDS160";
import { AssessmentDs160DataPayload } from "../../../../api/ai/types";
import { useSearchParams } from "react-router-dom";
import { BusinessQueryKey } from "../../../../api/utils/types";

const useAiReview = () => {
  const [searchParams] = useSearchParams();
  const getApplicationId = searchParams.get(BusinessQueryKey.appId) || "";
  const { mutate, isLoading: riskSaving } = useSaveDs160Assessment();

  const {
    data: riskData,
    isLoading: riskLoading,
    isError: riskError,
  } = useGetDS160AIData(getApplicationId);

  const postAiReview = ({
    riskPayload,
    onSave,
  }: {
    riskPayload: Partial<AssessmentDs160DataPayload>;
    onSave: () => void;
  }) => {
    const payload = { ...riskPayload, applicationId: getApplicationId };
    mutate(payload, {
      onSuccess: onSave,
    });
  };

  return { postAiReview, riskData, riskError, riskLoading, riskSaving };
};

export default useAiReview;
