import * as Yup from "yup";
import {
  ApplicationPreparerValues,
  ReceiveInterpreterHelpValues,
  SchemaObject,
} from "../../components/type";
import { digitsOnly } from "../../../../utils";

export const PreparerInterpreterValidation = Yup.object().shape<
  SchemaObject<any>
>({
  applicationPreparer: Yup.string().required("Required").trim(),
  receiveInterpreterHelp: Yup.string().when(
    "applicationPreparer",
    ([applicationPreparer], schema) => {
      if (applicationPreparer === ApplicationPreparerValues.iAmTheApplicant)
        return Yup.string().required("Required");
      return schema;
    }
  ),
  language: Yup.string().when(
    "receiveInterpreterHelp",
    ([receiveInterpreterHelp], schema) => {
      if (receiveInterpreterHelp === ReceiveInterpreterHelpValues.yes)
        return Yup.string().required("Required");
      return schema;
    }
  ),
  interpreterFirstName: Yup.string().when(
    "receiveInterpreterHelp",
    ([receiveInterpreterHelp], schema) => {
      if (receiveInterpreterHelp === ReceiveInterpreterHelpValues.yes)
        return Yup.string().required("Required");
      return schema;
    }
  ),
  interpreterLastName: Yup.string().when(
    "receiveInterpreterHelp",
    ([receiveInterpreterHelp], schema) => {
      if (receiveInterpreterHelp === ReceiveInterpreterHelpValues.yes)
        return Yup.string().required("Required");
      return schema;
    }
  ),
  interpreterFirm: Yup.string().when(
    "receiveInterpreterHelp",
    ([receiveInterpreterHelp], schema) => {
      if (receiveInterpreterHelp === ReceiveInterpreterHelpValues.yes)
        return Yup.string().required("Required");
      return schema;
    }
  ),
  interpreterAddress: Yup.string().when(
    "receiveInterpreterHelp",
    ([receiveInterpreterHelp], schema) => {
      if (receiveInterpreterHelp === ReceiveInterpreterHelpValues.yes)
        return Yup.string().required("Required");
      return schema;
    }
  ),
  interpreterStreetNumAndName: Yup.string().when(
    "receiveInterpreterHelp",
    ([receiveInterpreterHelp], schema) => {
      if (receiveInterpreterHelp === ReceiveInterpreterHelpValues.yes)
        return Yup.string()
          .max(34, "Maximum of 34 character allowed")
          .required("Required")
          .trim();
      return schema;
    }
  ),
  interpreterApartmentSuiteOrFloor: Yup.object()
    .nullable()
    .when("receiveInterpreterHelp", ([receiveInterpreterHelp], schema) => {
      if (receiveInterpreterHelp === ReceiveInterpreterHelpValues.yes)
        return Yup.object().nullable().required("Required");
      return schema;
    }),
  interpreterAddressNum: Yup.string().when(
    "interpreterApartmentSuiteOrFloor",
    ([interpreterApartmentSuiteOrFloor], schema) => {
      if (interpreterApartmentSuiteOrFloor?.value)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  interpreterCityOrTown: Yup.string().when(
    "receiveInterpreterHelp",
    ([receiveInterpreterHelp], schema) => {
      if (receiveInterpreterHelp === ReceiveInterpreterHelpValues.yes)
        return Yup.string().required("Required");
      return schema;
    }
  ),
  interpreterCountry: Yup.object()
    .nullable()
    .when("receiveInterpreterHelp", ([receiveInterpreterHelp], schema) => {
      if (receiveInterpreterHelp === ReceiveInterpreterHelpValues.yes)
        return Yup.object().nullable().required("Required");
      return schema;
    }),
  interpreterDayTimePhoneNumber: Yup.string().when(
    "receiveInterpreterHelp",
    ([receiveInterpreterHelp], schema) => {
      if (receiveInterpreterHelp === ReceiveInterpreterHelpValues.yes)
        return Yup.string()
          .required("Required")
          .label("Daytime Phone Number")
          .length(10)
          .test("Digits only", "Must be a number", digitsOnly);
      return schema;
    }
  ),
  interpreterMobilePhoneNumber: Yup.string().when(
    "receiveInterpreterHelp",
    ([receiveInterpreterHelp], schema) => {
      if (receiveInterpreterHelp === ReceiveInterpreterHelpValues.yes)
        return Yup.string();
      return schema;
    }
  ),
  interpreterStateOrTerritory: Yup.object()
    .nullable()
    .when("receiveInterpreterHelp", ([receiveInterpreterHelp], schema) => {
      if (receiveInterpreterHelp === ReceiveInterpreterHelpValues.yes)
        return Yup.object().nullable().required("Required");
      return schema;
    }),
  interpreterZipCode: Yup.string().when(
    "receiveInterpreterHelp",
    ([receiveInterpreterHelp], schema) => {
      if (receiveInterpreterHelp === ReceiveInterpreterHelpValues.yes)
        return Yup.string()
          .length(5, "Maximum of 5 characters allowed")
          .required("Required")
          .trim()
          .test("Digits only", "Must be a number", digitsOnly);
      return schema;
    }
  ),
  interpreterEmail: Yup.string()
    .label("Email")
    .email()
    .when("receiveInterpreterHelp", ([receiveInterpreterHelp], schema) => {
      if (receiveInterpreterHelp === ReceiveInterpreterHelpValues.yes)
        return Yup.string().label("Email").email().required("Required");
      return schema;
    }),
  interpreterAcknowledgement: Yup.bool().when(
    "receiveInterpreterHelp",
    ([receiveInterpreterHelp], schema) => {
      if (receiveInterpreterHelp === ReceiveInterpreterHelpValues.yes)
        return Yup.bool().oneOf([true]).required("Required");
      return schema;
    }
  ),
  englishConfirmation: Yup.bool().when(
    "receiveInterpreterHelp",
    ([receiveInterpreterHelp], schema) => {
      if (receiveInterpreterHelp === ReceiveInterpreterHelpValues.no)
        return Yup.bool().oneOf([true]).required("Required");
      return schema;
    }
  ),

  // //preparer
  preparerFirstName: Yup.string().when(
    "applicationPreparer",
    ([applicationPreparer], schema) => {
      if (
        applicationPreparer === ApplicationPreparerValues.iAmApreparer ||
        applicationPreparer === ApplicationPreparerValues.iAmTheAttorney
      )
        return Yup.string().required("Required");
      return schema;
    }
  ),
  preparerLastName: Yup.string().when(
    "applicationPreparer",
    ([applicationPreparer], schema) => {
      if (
        applicationPreparer === ApplicationPreparerValues.iAmApreparer ||
        applicationPreparer === ApplicationPreparerValues.iAmTheAttorney
      )
        return Yup.string().required("Required");
      return schema;
    }
  ),
  preparerFirm: Yup.string().when(
    "applicationPreparer",
    ([applicationPreparer], schema) => {
      if (
        applicationPreparer === ApplicationPreparerValues.iAmApreparer ||
        applicationPreparer === ApplicationPreparerValues.iAmTheAttorney
      )
        return Yup.string().required("Required");
      return schema;
    }
  ),
  preparerAddress: Yup.string().when(
    "applicationPreparer",
    ([applicationPreparer], schema) => {
      if (applicationPreparer === ApplicationPreparerValues.iAmApreparer)
        return Yup.string().required("Required");
      return schema;
    }
  ),
  preparerStreetNumAndName: Yup.string().when(
    "applicationPreparer",
    ([applicationPreparer], schema) => {
      if (
        applicationPreparer === ApplicationPreparerValues.iAmApreparer ||
        applicationPreparer === ApplicationPreparerValues.iAmTheAttorney
      )
        return Yup.string()
          .max(34, "Maximum of 34 character allowed")
          .required("Required")
          .trim();
      return schema;
    }
  ),
  preparerApartmentSuiteOrFloor: Yup.object()
    .nullable()
    .when("applicationPreparer", ([applicationPreparer], schema) => {
      if (
        applicationPreparer === ApplicationPreparerValues.iAmApreparer ||
        applicationPreparer === ApplicationPreparerValues.iAmTheAttorney
      )
        return Yup.object().nullable().required("Required");
      return schema;
    }),
  preparerAddressNum: Yup.string().when(
    "preparerApartmentSuiteOrFloor",
    ([preparerApartmentSuiteOrFloor], schema) => {
      if (preparerApartmentSuiteOrFloor?.value)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  preparerCityOrTown: Yup.string().when(
    "applicationPreparer",
    ([applicationPreparer], schema) => {
      if (
        applicationPreparer === ApplicationPreparerValues.iAmApreparer ||
        applicationPreparer === ApplicationPreparerValues.iAmTheAttorney
      )
        return Yup.string().required("Required");
      return schema;
    }
  ),
  preparerCountry: Yup.object()
    .nullable()
    .when("applicationPreparer", ([applicationPreparer], schema) => {
      if (
        applicationPreparer === ApplicationPreparerValues.iAmApreparer ||
        applicationPreparer === ApplicationPreparerValues.iAmTheAttorney
      )
        return Yup.object().nullable().required("Required");
      return schema;
    }),
  preparerDayTimePhoneNumber: Yup.string().when(
    "applicationPreparer",
    ([applicationPreparer], schema) => {
      if (
        applicationPreparer === ApplicationPreparerValues.iAmApreparer ||
        applicationPreparer === ApplicationPreparerValues.iAmTheAttorney
      )
        return Yup.string()
          .required("Required")
          .label("Daytime Phone Number")
          .length(10)
          .test("Digits only", "Must be a number", digitsOnly);
      return schema;
    }
  ),
  preparerMobilePhoneNumber: Yup.string().when(
    "applicationPreparer",
    ([applicationPreparer], schema) => {
      if (
        applicationPreparer === ApplicationPreparerValues.iAmApreparer ||
        applicationPreparer === ApplicationPreparerValues.iAmTheAttorney
      )
        return Yup.string();
      return schema;
    }
  ),
  preparerStateOrTerritory: Yup.object()
    .nullable()
    .when("applicationPreparer", ([applicationPreparer], schema) => {
      if (
        applicationPreparer === ApplicationPreparerValues.iAmApreparer ||
        applicationPreparer === ApplicationPreparerValues.iAmTheAttorney
      )
        return Yup.object().nullable().required("Required");
      return schema;
    }),
  preparerZipCode: Yup.string().when(
    "applicationPreparer",
    ([applicationPreparer], schema) => {
      if (
        applicationPreparer === ApplicationPreparerValues.iAmApreparer ||
        applicationPreparer === ApplicationPreparerValues.iAmTheAttorney
      )
        return Yup.string()
          .length(5, "Maximum of 5 characters allowed")
          .required("Required")
          .trim()
          .test("Digits only", "Must be a number", digitsOnly);
      return schema;
    }
  ),
  preparerEmail: Yup.string()
    .email()
    .label("Email")
    .when("applicationPreparer", ([applicationPreparer], schema) => {
      if (
        applicationPreparer === ApplicationPreparerValues.iAmApreparer ||
        applicationPreparer === ApplicationPreparerValues.iAmTheAttorney
      )
        return Yup.string().email().label("Email").required("Required");
      return schema;
    }),
  representationOfApplicant: Yup.string().when(
    "applicationPreparer",
    ([applicationPreparer], schema) => {
      if (applicationPreparer === ApplicationPreparerValues.iAmTheAttorney)
        return Yup.string().required("Required");
      return schema;
    }
  ),
  stateBarNum: Yup.string().when(
    "applicationPreparer",
    ([applicationPreparer], schema) => {
      if (applicationPreparer === ApplicationPreparerValues.iAmTheAttorney)
        return Yup.string().required("Required");
      return schema;
    }
  ),
  uscisOnlineAccountNumber: Yup.string().when(
    "applicationPreparer",
    ([applicationPreparer], schema) => {
      if (applicationPreparer === ApplicationPreparerValues.iAmTheAttorney)
        return Yup.string().required("Required");
      return schema;
    }
  ),
});
