import axios, { AxiosRequestHeaders } from "axios";
import { snapshot } from "valtio";
import {
  accessTokenStore,
  seamlessAccessToken,
  seamlessRefreshToken,
} from "../store/accessToken";
import storage from "../utils/storage";
import { ApiResponse, RefreshTokenRes } from "../types/types";

const devText = "development";

const isDev =
  process.env.NODE_ENV === devText || process.env.REACT_APP_ENV === devText;

const ProdUrl = "https://svbackend-production.up.railway.app/";
const DevUrl = "https://svbackend-production-45f5.up.railway.app/";

export const BaseUrl = !isDev ? ProdUrl : DevUrl;

const request = axios.create({
  baseURL: BaseUrl,
});

request.interceptors.request.use(
  (config) => {
    const accessToken = snapshot(accessTokenStore).accessToken;

    const newConfig = { ...config };

    if (accessToken) {
      newConfig.headers = {
        Authorization: `Bearer ${accessToken}`,
        ...newConfig.headers,
      } as AxiosRequestHeaders;
    }

    return newConfig;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
request.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response?.status === 400) {
      return Promise.reject(error.response?.data);
    }

    if (error.response?.status === 403) {
      return Promise.reject(
        "Sorry! You're not authorized to perform this action"
      );
    }

    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // logout
      // loginFailed();
      // storage.clear();
      storage.remove(seamlessAccessToken);
      storage.remove(seamlessRefreshToken);

      try {
        const refreshToken = snapshot(accessTokenStore).refreshToken;
        if (!refreshToken) return;
        const response = await request.post<ApiResponse<RefreshTokenRes>>(
          "/user/refresh-token",
          {
            grant_type: "refresh_token",
            refresh_token: refreshToken,
          }
        );
        const { access_token, refresh_token } = response.data.data;
        // Update the access token and refresh token in localStorage
        storage.set(seamlessAccessToken, access_token);
        storage.set(seamlessRefreshToken, refresh_token);
        accessTokenStore.accessToken = access_token;
        accessTokenStore.refreshToken = refresh_token;

        // Retry the original request with the new token
        originalRequest.headers.Authorization = `Bearer ${access_token}`;
        return request(originalRequest);
      } catch (error) {
        // Handle refresh token error or redirect to login
        console.log(error, "error");
      }
    }
    return Promise.reject(error);
  }
);

export default request;
