import { ApiResponse } from "../../types/types";
import request from "../request";
import { ArticleRes } from "./types";

export const createArticle = async (payload: FormData) => {
  return await request.post<ApiResponse<string>>(`/create-article`, payload);
};

export const updateArticle = async (payload: {
  articleId: string;
  data: FormData;
}) => {
  return await request.post<ApiResponse<string>>(
    `/update-article/${payload.articleId}`,
    payload.data
  );
};

export const getSingleArticle = async (articleId: string) => {
  return await request.get<ApiResponse<ArticleRes>>(
    `/user/get-article/${articleId}`
  );
};

export const getAllArticle = async () => {
  return await request.get<ApiResponse<ArticleRes[]>>(`/user/get-all-articles`);
};

export const deleteArticle = async (articleId: string) => {
  return await request.delete<ApiResponse<string>>(
    `/delete-article/${articleId}`
  );
};
