import { motion } from "framer-motion";
import React, { FC } from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import Overlay from "../../../../../checkEligibilty/components/modals/Overlay";
import { ContainerVariants } from "../../../../../checkEligibilty/components/modals/variant";
import { mediaObj } from "../../../../../../../styles/Media";
import Colors from "../../../../../../../styles/Colors";
import { Form, Formik } from "formik";
import InputField from "../../../../../components/formik fields/InputField";
import PhoneFormatter from "../../../../../components/formik fields/PhoneFormatter";
import Spinner from "../../../../../../../utils/Spinner";
import {
  useCreateBusinessUser,
  useEditBusinessUser,
} from "../../../../../../../hooks/business/useBusinessUsers";
import { toast } from "react-toastify";
import {
  CreateBusinessUserPayload,
  GetBusinessUsersObj,
} from "../../../../../../../api/business/users/types";
import { BusinessCreateUserValidation } from "./validation";

interface Props {
  onClose: () => void;
  data?: Omit<GetBusinessUsersObj, "createdAt">; //types not uniform
}

const CreateUser: FC<Props> = ({ onClose, data }) => {
  const { mutate, isLoading } = useCreateBusinessUser();
  const { mutate: editMutate, isLoading: isEditing } = useEditBusinessUser();

  const masterLoading = isLoading || isEditing;
  const miniText = data ? "Edit" : "Create";

  return createPortal(
    <Overlay onClose={onClose}>
      <Wrapper
        onClick={(e) => e.stopPropagation()}
        variants={ContainerVariants}
      >
        <Header>{miniText} User</Header>

        <Formik
          initialValues={
            {
              name: data?.name || "",
              email: data?.email || "",
              phoneNumber: data?.phoneNumber || null,
            } as CreateBusinessUserPayload
          }
          validationSchema={BusinessCreateUserValidation}
          enableReinitialize
          onSubmit={(values) => {
            const payload = {
              ...values,
            };

            const onSuccess = () => {
              toast.success(`User ${data ? "Edited" : "Created"} Sucessfully`);
              onClose();
            };

            if (!!data) {
              editMutate(
                { ...payload, userId: data.id },
                {
                  onSuccess: onSuccess,
                }
              );
            } else {
              mutate(payload, {
                onSuccess: onSuccess,
              });
            }
          }}
        >
          <Form className="flex flex-col gap-4 mt-5">
            <InputField name="name" label="Name" />
            <InputField name="email" label="Email" />
            <PhoneFormatter name="phoneNumber" label="Phone Number" />
            <BtnWrapper>
              <CancelBtn
                onClick={onClose}
                disabled={masterLoading}
                type="button"
              >
                Cancel
              </CancelBtn>
              <ConfirmBtn
                disabled={masterLoading}
                type="submit"
                className="mx-auto"
              >
                {masterLoading ? <Spinner color={Colors.White} /> : miniText}
              </ConfirmBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      </Wrapper>
    </Overlay>,
    document.body
  );
};

export default CreateUser;

const Wrapper = styled(motion.div)`
  width: 437px;
  height: max-content;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 12px;
  padding: 40px 32px;

  ${mediaObj.smallDesktop} {
    margin: auto;
    width: 60vw;
    padding-left: 5%;
    padding-right: 5%;
  }

  ${mediaObj.tablet} {
    width: 80vw;
  }

  ${mediaObj.bigMobile} {
    width: 90vw;
  }
`;

const Header = styled.h2`
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.625rem;
  text-align: center;
  color: #1b092c;
`;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 8px;

  & > button {
    flex: 1;
  }

  ${mediaObj.bigMobile} {
    flex-wrap: wrap-reverse;
    gap: 10px;
  }
`;

const Btn = styled.button`
  border: none;
  cursor: pointer;
  height: 50px;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  padding: 13px 45px;
  border-radius: 30px;
  white-space: nowrap;
  min-width: 160px;

  :disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const CancelBtn = styled(Btn)`
  background: #e6e6e6;
  color: #1b092c99;
`;

const ConfirmBtn = styled(Btn)`
  width: 100%;
  background: ${Colors.Blue00};
  color: #ffffff;
  transition: all 0.3s ease;
  border: 1px solid ${Colors.Blue00};

  &:hover:not(:disabled) {
    color: ${Colors.Blue00};
    background: #ffffff;
  }
`;
