import * as Yup from "yup";
import { SchemaObject } from "../../../../../components/type";
import { BusinessCreateApplicationFormData } from "./CreateApplication";

export const DocReviewModalValidation = Yup.object().shape({
  note: Yup.string().required("Required"),
});

export const BusinessStartApplicationValidation = Yup.object().shape<
  SchemaObject<BusinessCreateApplicationFormData>
>({
  applicationType: Yup.object().nullable().required("Required"),
  user: Yup.object().nullable().required("Required"),
});
