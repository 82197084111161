import { useField } from "formik";
import React, { forwardRef, useEffect } from "react";
import styled from "styled-components";

interface Props {
  ref: React.ForwardedRef<HTMLInputElement>;
  handleFile: (files: FileList) => void;
  accept?: string;
  name: string;
  onError: (errorState: boolean) => void;
  onErrorMsg: (errorMsg?: string) => void;
}

const FileInput = forwardRef<HTMLInputElement, Props>(
  ({ handleFile, accept, onError, onErrorMsg, ...props }, ref) => {
    const [, meta] = useField(props);
    const isError = !!(meta.touched && meta.error);

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.currentTarget.files) return;
      const fileUploaded = event.currentTarget.files;
      handleFile(fileUploaded);
    };

    useEffect(() => {
      onError(isError);
      onErrorMsg(meta?.error);
    }, [isError, onError, onErrorMsg, meta.error]);

    return (
      <InputFile
        type="file"
        ref={ref}
        onChange={(e) => {
          onFileChange(e);
          e.target.value = "";
        }}
        accept={accept}
      />
    );
  }
);

export default FileInput;

const InputFile = styled.input`
  display: none;
`;
