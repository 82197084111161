import React, { useEffect } from "react";
import styled from "styled-components";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { Form, Formik } from "formik";
import InputField from "../../../components/formik fields/InputField";
import { FormWrapper } from "../../../components/styles";
import DatePickerField from "../../../components/formik fields/DatePickerField";
import {
  ApartmentSuiteFloorData,
  GenericYesNo,
  GenericYesNoOptionsValue,
  ToSaveDate,
  USDatePlaceholderFormat,
  initDate,
  initInput,
  initSelect,
  resetDynamicFields,
} from "../../../components/data";
import SelectField from "../../../components/formik fields/SelectField";
import useCountries from "../../../components/useCountries";
import RadioComp from "../../../components/RadioComp";
import useStatesByCountry from "../../../components/useStatesByCountry";
import { FatherCitizenByData, FatherCitizenByDataValues } from "../data";
import { Modify, SingleOption } from "../../../../../types/types";
import { Dayjs } from "dayjs";
import { Info, InfoBold } from "../../../form ds 160/components/styles";
import FormBtn from "../../../components/FormBtn";
import { N600InfoAboutUSCitizenMotherPart1Payload } from "../../../../../api/n600/types";
import useToNextSection from "../../../../../hooks/useToNextSection";
import {
  useGetN600Eligibility,
  useGetN600InfoAboutUSCitizenMotherPart1,
  useSaveN600InfoAboutUSCitizenMotherPart1,
} from "../../../../../hooks/n600/useN600";
import { USCitizenMOtherP1Validation } from "../validation";

export interface USCitizenMotherP1FormData
  extends Modify<
    N600InfoAboutUSCitizenMotherPart1Payload,
    {
      motherDateOfBirth: Dayjs | null;
      motherCountryOfBirth: SingleOption | null;
      motherCountryOfCitizenship: SingleOption | null;

      motherDateOfDeath: Dayjs | null;

      motherPhysicalAddressAptSteFlr: SingleOption | null;
      motherPhysicalAddressState: SingleOption | null;
      motherPhysicalAddressCountry: SingleOption | null;

      motherCitizenshipStatus: SingleOption | null;
      motherPlaceOfNaturalizationState: SingleOption | null;
      motherDateOfNaturalization: Dayjs | null;
    }
  > {}

const USCitizenMotherP1 = () => {
  const { data, isFetching, isError } =
    useGetN600InfoAboutUSCitizenMotherPart1();
  const { mutate, isLoading: isSaving } =
    useSaveN600InfoAboutUSCitizenMotherPart1();
  const { data: eligibilityData } = useGetN600Eligibility();

  const { toNextSection } = useToNextSection();

  const isMotherCitizen =
    eligibilityData?.which_parent_is_a_us_citizen ===
    "My mother is a U.S citizen";

  //skip this section if not to study
  useEffect(() => {
    if (!isMotherCitizen) {
      toNextSection();
    }
  }, [isMotherCitizen, toNextSection]);

  const { formattedCountryData, countryLoading } = useCountries();
  const {
    formattedCountryData: nationCountryData,
    countryLoading: nationCountryLoading,
  } = useCountries();

  const {
    formattedCountryData: addressCountryData,
    countryLoading: addressCountryLoading,
  } = useCountries();
  const { formattedStateData, stateLoading, setCountry, selectedCountry } =
    useStatesByCountry(data?.motherPhysicalAddressCountry || "United States");

  const {
    formattedStateData: naturalizationStateData,
    stateLoading: naturalizationStateLoading,
    // setCountry: naturalizationSetCountry,
    selectedCountry: naturalizationSelectedCountry,
  } = useStatesByCountry("United States");

  const onSubmit = (values: USCitizenMotherP1FormData) => {
    const {
      motherDateOfBirth,
      motherCountryOfBirth,
      motherCountryOfCitizenship,
      motherDateOfDeath,
      motherPhysicalAddressCountry,
      motherPhysicalAddressState,
      motherPhysicalAddressAptSteFlr,
      motherPlaceOfNaturalizationState,
      motherCitizenshipStatus,
      motherDateOfNaturalization,
      ...rest
    } = values;

    const payload = {
      ...rest,
      motherDateOfBirth: ToSaveDate(motherDateOfBirth),
      motherCountryOfBirth: motherCountryOfBirth?.value || "",
      motherCountryOfCitizenship: motherCountryOfCitizenship?.value || "",
      motherDateOfDeath: ToSaveDate(motherDateOfDeath),
      motherPhysicalAddressCountry: motherPhysicalAddressCountry?.value || "",
      motherPhysicalAddressState: motherPhysicalAddressState?.value || "",
      motherPhysicalAddressAptSteFlr:
        motherPhysicalAddressAptSteFlr?.value || "",
      motherPlaceOfNaturalizationState:
        motherPlaceOfNaturalizationState?.value || "",
      motherCitizenshipStatus: motherCitizenshipStatus?.value || "",
      motherDateOfNaturalization: ToSaveDate(motherDateOfNaturalization),
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValues: USCitizenMotherP1FormData = {
    motherCurrentLegalFamilyName: initInput(data?.motherCurrentLegalFamilyName),
    motherCurrentLegalGivenName: initInput(data?.motherCurrentLegalGivenName),
    motherCurrentLegalMiddleName: initInput(data?.motherCurrentLegalMiddleName),

    motherDateOfBirth: initDate(data?.motherDateOfBirth),
    motherCountryOfBirth: initSelect(
      formattedCountryData,
      data?.motherCountryOfBirth
    ),
    motherCountryOfCitizenship: initSelect(
      nationCountryData,
      data?.motherCountryOfCitizenship
    ),

    isMotherAlive: initInput(data?.isMotherAlive),
    motherDateOfDeath: initDate(data?.motherDateOfDeath),

    motherPhysicalAddressStreetNumberAndName: initInput(
      data?.motherPhysicalAddressStreetNumberAndName
    ),
    motherPhysicalAddressAptSteFlr: initSelect(
      ApartmentSuiteFloorData,
      data?.motherPhysicalAddressAptSteFlr
    ),
    motherPhysicalAddressAptSteFlrNumber: initInput(
      data?.motherPhysicalAddressAptSteFlrNumber
    ),
    motherPhysicalAddressCityOrTown: initInput(
      data?.motherPhysicalAddressCityOrTown
    ),

    motherPhysicalAddressCountry: initSelect(
      addressCountryData,
      data?.motherPhysicalAddressCountry
    ),
    motherPhysicalAddressState: initSelect(
      formattedStateData,
      data?.motherPhysicalAddressState
    ),
    motherPhysicalAddressZipCode: initInput(data?.motherPhysicalAddressZipCode),
    motherPhysicalAddressProvince: initInput(
      data?.motherPhysicalAddressProvince
    ),
    motherPhysicalAddressPostalCode: initInput(
      data?.motherPhysicalAddressPostalCode
    ),

    motherCitizenshipStatus: initSelect(
      FatherCitizenByData,
      data?.motherCitizenshipStatus
    ),
    motherCertificateOfCitizenshipNumber: initInput(
      data?.motherCertificateOfCitizenshipNumber
    ),
    motherAlienRegistrationNumber: initInput(
      data?.motherAlienRegistrationNumber
    ),
    motherPlaceOfNaturalizationCourtOrUSCISOffice: initInput(
      data?.motherPlaceOfNaturalizationCourtOrUSCISOffice
    ),
    motherCertificateOfNaturalizationNumber: initInput(
      data?.motherCertificateOfNaturalizationNumber
    ),
    motherPlaceOfNaturalizationCityOrTown: initInput(
      data?.motherPlaceOfNaturalizationCityOrTown
    ),
    motherPlaceOfNaturalizationState: initSelect(
      naturalizationStateData,
      data?.motherPlaceOfNaturalizationState
    ),
    motherNaturalizationANumber: initInput(data?.motherNaturalizationANumber),
    motherDateOfNaturalization: initDate(data?.motherDateOfNaturalization),
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={USCitizenMOtherP1Validation}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <Info>
                <InfoBold>Current Legal Name of U.S. Citizen Mother</InfoBold>
              </Info>
              <FormWrapper>
                <InputField
                  name="motherCurrentLegalFamilyName"
                  label="Family Name"
                  coloredLabel="(Last Name)"
                  placeholder=""
                />
                <InputField
                  name="motherCurrentLegalGivenName"
                  label="Given Name"
                  coloredLabel="(First Name)"
                  placeholder=""
                />
              </FormWrapper>

              <FormWrapper>
                <InputField
                  name="motherCurrentLegalMiddleName"
                  label="Middle Name"
                  placeholder=""
                />
                <div></div>
              </FormWrapper>

              <FormWrapper>
                <DatePickerField
                  name="motherDateOfBirth"
                  label="Date of Birth"
                  placeholder={USDatePlaceholderFormat}
                  disableFuture={true}
                />
                <SelectField
                  name="motherCountryOfBirth"
                  label="Country of Birth"
                  placeholder="-Select-"
                  options={formattedCountryData}
                  isLoading={countryLoading}
                />
              </FormWrapper>

              <FormWrapper>
                <SelectField
                  name="motherCountryOfCitizenship"
                  label="Country of Citizenship/Nationality"
                  placeholder="-Select-"
                  options={nationCountryData}
                  isLoading={nationCountryLoading}
                />
                <div></div>
              </FormWrapper>

              <FormWrapper>
                <RadioComp
                  title="Is your mother presently alive?"
                  name="isMotherAlive"
                  options={GenericYesNo}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "motherDateOfDeath", value: null },
                      {
                        name: "motherPhysicalAddressStreetNumberAndName",
                        value: "",
                      },
                      { name: "motherPhysicalAddressCityOrTown", value: "" },
                      { name: "motherPhysicalAddressState", value: null },
                      { name: "motherPhysicalAddressCountry", value: null },
                      { name: "motherPhysicalAddressAptSteFlr", value: null },
                      {
                        name: "motherPhysicalAddressAptSteFlrNumber",
                        value: "",
                      },
                      { name: "motherPhysicalAddressZipCode", value: "" },
                    ]);
                  }}
                />
                <div></div>
              </FormWrapper>

              {formik.values.isMotherAlive === GenericYesNoOptionsValue.no && (
                <FormWrapper>
                  <DatePickerField
                    name="motherDateOfDeath"
                    label="Date of Death"
                    placeholder={USDatePlaceholderFormat}
                    disableFuture={true}
                  />

                  <div></div>
                </FormWrapper>
              )}

              {formik.values.isMotherAlive === GenericYesNoOptionsValue.yes && (
                <>
                  <FormWrapper>
                    <InputField
                      label="Street number and name"
                      placeholder=""
                      name={`motherPhysicalAddressStreetNumberAndName`}
                    />

                    <InputField
                      label="City or Town"
                      placeholder=""
                      name={`motherPhysicalAddressCityOrTown`}
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <SelectField
                      label="Is there an apartment, suite or floor?"
                      name={`motherPhysicalAddressAptSteFlr`}
                      options={ApartmentSuiteFloorData}
                    />

                    <div>
                      {formik.values.motherPhysicalAddressAptSteFlr?.value && (
                        <InputField
                          label={`${formik.values.motherPhysicalAddressAptSteFlr?.value} number`}
                          placeholder=""
                          name={`motherPhysicalAddressAptSteFlrNumber`}
                        />
                      )}
                    </div>
                  </FormWrapper>

                  <FormWrapper>
                    <SelectField
                      name={`motherPhysicalAddressCountry`}
                      label="Country"
                      coloredLabel="(foreign address only)"
                      placeholder="-Select-"
                      options={addressCountryData}
                      isLoading={addressCountryLoading}
                      onChange={(value) => {
                        setCountry(value.label);
                        formik.setFieldValue(
                          `motherPhysicalAddressState`,
                          null
                        );
                      }}
                    />
                    <SelectField
                      name={`motherPhysicalAddressState`}
                      label="State or Territory"
                      placeholder="-Select-"
                      options={formattedStateData}
                      isLoading={stateLoading}
                      isDisabled={!selectedCountry}
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <div>
                      <InputField
                        label="ZIP Code"
                        placeholder=""
                        name={`motherPhysicalAddressZipCode`}
                      />
                    </div>

                    <div>
                      <InputField
                        label="Province"
                        coloredLabel="(foreign address only)"
                        placeholder=""
                        name={`motherPhysicalAddressProvince`}
                      />
                    </div>
                  </FormWrapper>
                  <FormWrapper>
                    <div>
                      <InputField
                        label="Postal Code"
                        coloredLabel="(foreign address only)"
                        placeholder=""
                        name={`motherPhysicalAddressPostalCode`}
                      />
                    </div>

                    <div></div>
                  </FormWrapper>
                </>
              )}

              <FormWrapper>
                <SelectField
                  label="My mother is a U.S. citizen by:"
                  name="motherCitizenshipStatus"
                  options={FatherCitizenByData}
                />
                <div></div>
              </FormWrapper>

              {formik.values.motherCitizenshipStatus?.value ===
                FatherCitizenByDataValues.birthAbroad && (
                <FormWrapper>
                  <InputField
                    name="motherCertificateOfCitizenshipNumber"
                    label="Certificate of Citizenship Number"
                    placeholder=""
                  />

                  <InputField
                    label="Alien Registration Number (A-Number)"
                    coloredLabel="(optional)"
                    placeholder=""
                    name="motherAlienRegistrationNumber"
                  />
                </FormWrapper>
              )}

              {formik.values.motherCitizenshipStatus?.value ===
                FatherCitizenByDataValues.naturalization && (
                <>
                  <FormWrapper>
                    <InputField
                      name="motherPlaceOfNaturalizationCourtOrUSCISOffice"
                      label="Place of Naturalization"
                      coloredLabel="(Name of Court or USCIS Office Location)"
                      placeholder=""
                    />

                    <InputField
                      label="City or Town"
                      placeholder=""
                      name={`motherPlaceOfNaturalizationCityOrTown`}
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <SelectField
                      name={`motherPlaceOfNaturalizationState`}
                      label="State"
                      placeholder="-Select-"
                      options={naturalizationStateData}
                      isLoading={naturalizationStateLoading}
                      isDisabled={!naturalizationSelectedCountry}
                    />

                    <InputField
                      name="motherCertificateOfNaturalizationNumber"
                      label="Certificate of Naturalization Number"
                      placeholder=""
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <InputField
                      label="Alien Registration Number (A-Number)"
                      coloredLabel="(optional)"
                      placeholder=""
                      name="motherNaturalizationANumber"
                    />

                    <DatePickerField
                      name="motherDateOfNaturalization"
                      label="Date of Naturalization"
                      placeholder={USDatePlaceholderFormat}
                      disableFuture={true}
                    />
                  </FormWrapper>
                </>
              )}

              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default USCitizenMotherP1;

const Wrapper = styled.div``;
