import React, { useState } from "react";
import styled from "styled-components";
import Constaint from "../../layout/components/Constaint";
import ContactBg from "../../../../images/web/contact-bg.png";
import Colors from "../../../../styles/Colors";
import { Form, Formik } from "formik";
// import { BsChatRightTextFill } from "react-icons/bs";
import InputField from "../../components/formik fields/InputField";

import emailjs from "@emailjs/browser";
import FormBtn from "../../components/FormBtn";

interface ContactProps {
  name: string;
  email: string;
  subject: string;
  message: string;
}

type SetSubmitting = (isSubmitting: boolean) => void;
type ResetForm = () => void;
interface EmailJSResponse {
  status: number;
  text: string;
  // Add other properties specific to EmailJS response if needed
}

const Contact = () => {
  const style = {
    height: "70px",
    borderRadius: "8px",
  };

  const initialValues: ContactProps = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };

  // const onStartChat = (url: string) => {
  //   window.open(
  //     url,
  //     "window",
  //     "width=500, height=750, toolbar=no, top=100, left=100, menubar=no, resizable=yes"
  //   );
  // };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = (
    values: ContactProps,
    {
      setSubmitting,
      resetForm,
    }: { setSubmitting: SetSubmitting; resetForm: ResetForm }
  ) => {
    setIsLoading(true);
    emailjs
      .send(
        "service_7qce2wf", // Replace with your Service ID
        "template_wsyswwb", // Replace with your Template ID
        { ...values },
        "Y9QzImH-5ZAvhtbdu" // Replace with your Public Key
      )
      .then((response: EmailJSResponse) => {
        // console.log("SUCCESS!", response.status, response.text);
        setSubmitting(false);
        setIsLoading(false);
        resetForm();
      })
      .catch((err: Error) => {
        console.error("FAILED...", err);
        setSubmitting(false);
        setIsLoading(false);
      });
  };

  return (
    <Wrapper>
      <Constaint>
        <Container>
          <H2>
            Get In <Colored>Touch</Colored> With Us
          </H2>
          {/* <ChatWithUs
            onClick={() =>
              onStartChat(
                "https://messenger.yablo.io/seamlessvisa.com/gubijgvxcptlfje"
              )
            }
          >
            <BsChatRightTextFill />
            <p>Chat with our Support team</p>
          </ChatWithUs> */}
          <Formik
            initialValues={initialValues}
            // validationSchema={AddCategoryValidation}
            onSubmit={onSubmit}
            // enableReinitialize
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <InputsWrapper>
                  <Flexxer>
                    <InputField
                      label=""
                      placeholder="Name"
                      name="name"
                      {...style}
                    />
                  </Flexxer>
                  <Flexxer>
                    <InputField
                      label=""
                      placeholder="Email"
                      name="email"
                      {...style}
                    />
                  </Flexxer>

                  <Flexxer>
                    <InputField
                      label=""
                      placeholder="Subject"
                      name="subject"
                      {...style}
                    />
                  </Flexxer>
                  <InputField
                    label=""
                    placeholder="Message"
                    name="message"
                    inputType="text-area"
                    height={"175px"}
                    borderRadius={"8px"}
                  />

                  {/* <CTA disabled={isLoading}>
                    {isLoading && <Spinner />} Send Message
                  </CTA> */}
                  <FormBtn
                    isLoading={isLoading}
                    text="Send Message"
                    isError={false}
                  />
                </InputsWrapper>
              </Form>
            )}
          </Formik>
        </Container>
      </Constaint>
    </Wrapper>
  );
};

export default Contact;

const Wrapper = styled.div`
  background: linear-gradient(
      0deg,
      rgba(0, 52, 102, 0.38),
      rgba(0, 52, 102, 0.38)
    ),
    url(${ContactBg});
  background-size: cover;
  margin-bottom: 110px;
`;

const Container = styled.div`
  color: ${Colors.White};
  padding-top: 170px;
  padding-bottom: 180px;
  margin: 0 auto;
  width: 90%;

  @media screen and (min-width: 768px) {
    /* font-size: 2.5rem; */
    width: 70%;
  }
`;

const H2 = styled.h2`
  font-size: 2rem;
  color: ${Colors.White};
  text-align: center;
  margin-bottom: 32px;

  @media screen and (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Colored = styled.span`
  color: #cfa202;
`;

// const ChatWithUs = styled.button`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   gap: 8px;

//   background-color: ${Colors.Blue00};
//   padding: 1rem 2rem;
//   border-radius: 8px;

//   margin: 0 auto;

//   @media screen and (min-width: 768px) {
//     padding: 1rem 5rem;
//   }

//   & > svg {
//     width: 24px;
//     height: 24px;
//   }

//   & > p {
//     font-weight: 600;
//   }
// `;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  color: ${Colors.Black31} !important;
`;

const Flexxer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

// const CTA = styled.button`
//   width: 100%;
//   height: 56px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 18px 59px;
//   font-size: 1rem;
//   font-weight: 600;
//   line-height: 1.1875rem;
//   letter-spacing: 0em;
//   background: #0076e9;
//   border-radius: 8px;
//   color: #f9fcff;
//   white-space: nowrap;
//   align-self: flex-end;
//   text-transform: uppercase;

//   @media screen and (min-width: 768px) {
//     width: 222px;
//   }

//   &:hover {
//     background: #022e58;
//     color: #f9fcff;
//     transition: 0.3s ease-in-out;
//   }

//   &:disabled {
//   }
// `;
