import React, { FC } from "react";
import {
  saveDS160PersonalInfo2Obj,
  saveDS160PersonalInfoObj,
} from "../../../../../../api/ds160/types";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { FormDS160Progress } from "../../types";
import { GenericDs160ReviewProps } from "./DownloadPage";
import PdfReviewBox from "../../../../components/Review/Pdf Review/PdfReviewBox";
import { ReviewPersonalSectionA, ReviewPersonalSectionB } from "./data";

interface Props extends GenericDs160ReviewProps {
  data?: {
    personalOne: saveDS160PersonalInfoObj;
    personalTwo: saveDS160PersonalInfo2Obj;
  };
}

const ReviewPersonal: FC<Props> = ({ title, data, isDownload }) => {
  const sectionA = data?.personalOne
    ? ReviewPersonalSectionA(data?.personalOne)
    : [];

  const sectionB = data?.personalTwo
    ? ReviewPersonalSectionB(data?.personalTwo)
    : [];

  const section: ReviewData[] = [
    {
      section: {
        title: "Personal 1",
        children: sectionA,
      },
    },
    {
      section: {
        title: "Personal 2",
        children: sectionB,
      },
    },
  ];

  const compProps = {
    title: title,
    data: section,
    editUrl: FormDS160Progress.personalInfoP1,
    isActive: true,
  };

  return isDownload ? (
    <PdfReviewBox {...compProps} />
  ) : (
    <ReviewBox {...compProps} />
  );
};

export default ReviewPersonal;
