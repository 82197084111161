import React, { useState } from "react";
import styled from "styled-components";
import CalendlyPopUp from "./CalendlyPopUp";

const RequestButton = () => {
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CTA onClick={() => setOpen(true)}>Request a free demo</CTA>
      <CalendlyPopUp open={open} onClose={onClose} />
    </>
  );
};

export default RequestButton;

const CTA = styled.button`
  width: 222px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.1875rem;
  letter-spacing: 0em;
  background: #0076e9;
  border-radius: 8px;
  color: #f9fcff;

  &:hover {
    background: #022e58;
    color: #f9fcff;
    transition: 0.3s ease-in-out;
  }

  margin: auto;

  @media only screen and (min-width: 768px) {
    margin: 40px 0;
  }
`;
