import { statesObj } from "../../../../api/utils/types";
import { useGetMultiStates } from "../../../../hooks/utils/useUtils";
import { SingleOption } from "../../../../types/types";
import { FormatingData } from "../../components/data";

const useMultiStatesByCountry = (passedCountry?: string) => {
  const multiStateData = useGetMultiStates(passedCountry);
  let statesArray: SingleOption[][] = [];

  multiStateData.forEach((ev) => {
    const formattedStateData =
      FormatingData<statesObj>("state_code", "name", ev?.states) || [];
    statesArray.push(formattedStateData);
  });

  return {
    statesArray,
  };
};

export default useMultiStatesByCountry;
