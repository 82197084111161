import React, { FC } from "react";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { N600InfoAboutUSCitizenMotherPart1Payload } from "../../../../../../api/n600/types";
import { FormN600Progress } from "../../types";

interface Props {
  title: string;
  data?: N600InfoAboutUSCitizenMotherPart1Payload;
}

const ReviewUSMotherInfoPart1: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      label: "Family Name (Last Name)",
      value: data?.motherCurrentLegalFamilyName,
    },
    {
      label: "Given Name (First Name)",
      value: data?.motherCurrentLegalGivenName,
    },
    {
      label: "Middle Name",
      value: data?.motherCurrentLegalMiddleName,
    },
    {
      label: "Date of Birth",
      value: data?.motherDateOfBirth,
    },
    {
      label: "Country of Birth",
      value: data?.motherCountryOfBirth,
    },
    {
      label: "Country of Citizenship",
      value: data?.motherCountryOfCitizenship,
    },
    {
      label: "Is your mother presently alive?",
      value: data?.isMotherAlive,
    },
    {
      label: "Date of Death",
      value: data?.motherDateOfDeath,
    },
    {
      label: "Street Number and Name",
      value: data?.motherPhysicalAddressStreetNumberAndName,
    },
    {
      label: "Apt. Ste. Flr",
      value: data?.motherPhysicalAddressAptSteFlr,
    },
    {
      label: "Apt. Ste. Flr Number",
      value: data?.motherPhysicalAddressAptSteFlrNumber,
    },
    {
      label: "Apt. Ste. Flr Number",
      value: data?.motherPhysicalAddressAptSteFlrNumber,
    },
    {
      label: "City or Town",
      value: data?.motherPhysicalAddressCityOrTown,
    },
    {
      label: "State",
      value: data?.motherPhysicalAddressState,
    },
    {
      label: "ZIP Code",
      value: data?.motherPhysicalAddressZipCode,
    },
    {
      label: "Province (foreign address only)",
      value: data?.motherPhysicalAddressProvince,
    },
    {
      label: "Postal Code (foreign address only)",
      value: data?.motherPhysicalAddressPostalCode,
    },
    {
      label: "Country (foreign address only)",
      value: data?.motherPhysicalAddressCountry,
    },
    {
      label: "My mother is a U.S. citizen by",
      value: data?.motherCitizenshipStatus,
    },
    {
      label: "Certificate of Citizenship Number",
      value: data?.motherCertificateOfCitizenshipNumber,
    },
    {
      label: "Alien Registration Number (A-Number)",
      value: data?.motherAlienRegistrationNumber,
    },
    {
      label: "Place of Naturalization (Name of Court or USCIS Office Location)",
      value: data?.motherPlaceOfNaturalizationCourtOrUSCISOffice,
    },
    {
      label: "Place of Naturalization City or Town",
      value: data?.motherPlaceOfNaturalizationCityOrTown,
    },
    {
      label: "Place of Naturalization State",
      value: data?.motherPlaceOfNaturalizationState,
    },
    {
      label: "Certificate of Naturalization Number",
      value: data?.motherCertificateOfNaturalizationNumber,
    },
    {
      label: "A-Number (if any)",
      value: data?.motherNaturalizationANumber,
    },
    {
      label: "Date of Naturalization",
      value: data?.motherDateOfNaturalization,
    },
  ];

  return (
    <ReviewBox
      title={title}
      data={section}
      editUrl={FormN600Progress.infoAboutUSCitizenMotherP1}
    />
  );
};

export default ReviewUSMotherInfoPart1;
