import { FC } from "react";
import styled from "styled-components";
import Spinner from "../../../../../../../utils/Spinner";
import Colors from "../../../../../../../styles/Colors";

interface Props {
  name: string;
  number?: number;
  icon: JSX.Element;
  iconBg: string;
  iconColor: string;
  isLoading: boolean;
  cardBg?: string;
}

const OverviewCard: FC<Props> = ({
  name,
  number,
  icon,
  iconBg,
  iconColor,
  isLoading,
  cardBg,
}) => {
  return (
    <Wrapper $cardBg={cardBg}>
      <Top>
        {name}

        <IconWrapper $iconBg={iconBg} $iconColor={iconColor}>
          {icon}
        </IconWrapper>
      </Top>
      <Bottom>
        <NumberText>
          {isLoading ? <Spinner size={14} color={Colors.Blue00} /> : number}
        </NumberText>
        <CircleCode $colorCode={iconColor} />
      </Bottom>
    </Wrapper>
  );
};

export default OverviewCard;

interface WrapperProps {
  $cardBg?: string;
}

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  padding: 21px 12px;
  border-radius: 12px;
  background-color: ${({ $cardBg }) => ($cardBg ? $cardBg : Colors.White)};
  color: ${({ $cardBg }) => ($cardBg ? Colors.White : Colors.Black31)};
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 13px;
  border-bottom: 1px solid ${Colors.Blue00};
`;

interface IconWrapperProps {
  $iconBg: string;
  $iconColor: string;
}

const IconWrapper = styled.div<IconWrapperProps>`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: 7px;
  background-color: ${({ $iconBg }) => $iconBg};
  color: ${({ $iconColor }) => $iconColor};
`;

const Bottom = styled.div`
  padding-top: 15px;
  display: flex;
  align-items: center;
  gap: 18px;
`;

interface CircleCodeProps {
  $colorCode: string;
}

const CircleCode = styled.div<CircleCodeProps>`
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: ${({ $colorCode }) => $colorCode};
`;

const NumberText = styled.div`
  font-weight: 600;
  font-size: 1.25rem;
`;
