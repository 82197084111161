import { FC } from "react";
import ReviewBox, { ReviewData } from "./ReviewBox";
import { BusinessBoxWrapper } from "./styles";
import { DS160EligibilityPayload } from "../../../../../../../api/ds160/types";
import styled from "styled-components";

interface Props {
  title: string;
  data: DS160EligibilityPayload;
}

const Ds160Eligibility: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      label: "What is your primary reason for visiting the U.S.?",
      value: data?.primary_reason_for_visiting_us,
    },
    {
      label: "Where are you applying from?",
      value: data?.where_are_applying_from,
    },
    {
      label: "Select the country code that matches the one on your passport.",
      value: data?.select_the_country_code,
    },
    {
      label: `Are you applying from ${data.where_are_applying_from}`,
      value: data?.are_you_applying_from_country,
    },
    {
      label: "Where will you apply for your visa?",
      value: data?.where_will_you_apply_for_your_visa,
    },
    {
      label: `Do you either have a job, a spouse or own a property in ${data.where_are_applying_from}`,
      value: data?.have_a_job_spouse_or_property_in_country,
    },
    {
      label: `In the past have you traveled internationally and returned to ${data.where_are_applying_from}`,
      value:
        data?.have_you_travelled_internationally_in_the_past_and_returned_to_country,
    },
    {
      label: "Are you visiting friends or relatives in the U.S?",
      value: data?.are_you_visiting_friends_or_relatives_in_the_us,
    },
    {
      label:
        "Do you have any immediate family members (parents, siblings, children) who are US citizens or permanent residents?",
      value: data?.are_you_visiting_friends_or_relatives_in_the_us,
    },
    {
      label: "Are you applying for the same type of visa?",
      value:
        data?.have_any_immediate_family_members_who_are_us_citizens_or_permanent_residents,
    },
    {
      label: `Have you traveled to any other countries in the past 5 years, aside from ${data.where_are_applying_from}`,
      value:
        data?.have_you_traveled_to_any_other_countries_in_the_past_five_years_aside_country,
    },
    {
      label: "Do you have U.S or Canada or U.K Visa?",
      value: data?.do_you_have_us_or_canada_uk_visa,
    },
    {
      label: "Have you been denied a travel visa to any country before?",
      value: data?.have_you_been_denied_a_travel_visa_to_any_country,
    },
    {
      label:
        "Have you been denied a travel visa to the U.S in the last 90 days or 6 months?",
      value:
        data?.have_you_been_denied_a_travel_visa_to_the_us_in_the_last_six_months,
    },
    {
      label:
        "Do you have a valid passport with a validity date extending at least 6 months beyond your intended stay, unless exempted",
      value:
        data?.do_you_have_valid_passport_with_validity_date_extending_at_least_six_months_beyond_intended_stay,
    },
    {
      label:
        "Do you have enough funds to cover your travel, accommodation, and other expenses during your stay in the US?",
      value:
        data?.do_you_have_enough_funds_to_cover_your_travel_accommodation_and_educational_expenses_in_us,
    },
    {
      label:
        "Are you traveling for a scientific, educational, professional or business convention, conference or meeting on specific dates?",
      value:
        data?.are_you_travelling_for_a_scientific_educational_professional_or_business_convention,
    },
    {
      label:
        "Are you planning to attend a U.S.-based academic institution, including language training programs?",
      value:
        data?.are_you_planning_to_attend_us_based_academic_institution_including_language_training_programs,
    },
    {
      label: "Have you been accepted into an SEVP-approved school in the U.S.?",
      value: data?.have_you_been_accepted_into_sevp_approved_school_in_us,
    },
    {
      label:
        "Have you been issued an I-20 document by an SEVP-approved school in the U.S?",
      value:
        data?.have_you_been_issued_a_document_by_a_sevp_approved_school_in_us,
    },
  ];

  return (
    <Wrapper>
      <ReviewBox title={title} data={section} />
    </Wrapper>
  );
};

export default Ds160Eligibility;

const Wrapper = styled(BusinessBoxWrapper)`
  & > div {
    margin-top: 0;
  }
`;
