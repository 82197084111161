import React, { useState } from "react";
import DocChecklistItem from "../DocChecklistItem";
import { toast } from "react-toastify";
import RequestWrapper from "../../../../../components/RequestWrapper";
import { UpdateN400FilesPayload } from "../../../../../../../api/n400/types";
import { useSearchParams } from "react-router-dom";
import {
  useGetN565Files,
  useGetN565Info,
  useUpdateN565Files,
  useUploadN565Files,
} from "../../../../../../../hooks/n565/useN565";
import { n565DocChecklist } from "../data/n565Data";
import AdditionalDocItem from "./AdditionalDocItem";
import AdditionalDoc from "../AdditionalDoc";
import { N565NameChangedThroughDataValue } from "../../../../../form 565/components/data";
import { DocChecklistProps } from "../data/types";

const N565DocChecklist = () => {
  const [searchParams] = useSearchParams();
  const [loadingField, setLoading] = useState("");
  const getType = searchParams.get("form");
  const { data, isLoading: isGetting, isError } = useGetN565Files();
  const {
    data: infoData,
    isLoading: infoGetting,
    isError: infoIsError,
  } = useGetN565Info();
  const { mutate, isLoading } = useUploadN565Files();
  const { mutate: updateMutate, isLoading: isUpdating } = useUpdateN565Files();

  const onSubmit = (
    params: File,
    name: string,
    fileId?: string,
    onSuccessful?: () => void
  ) => {
    const payload = new FormData();
    payload.append("file", params);

    if (fileId && getType) {
      const temp: UpdateN400FilesPayload = {
        id: fileId,
        formCategory: getType,
        data: payload,
      };

      updateMutate(temp, {
        onSuccess: () => toast.success("Update Successful"),
      });
    } else {
      payload.append("name", name);
      mutate(payload, {
        onSuccess: () => {
          onSuccessful && onSuccessful();
          toast.success("Upload Successful");
        },
      });
    }

    setLoading(name); //
  };

  const getN565DocChecklist = () => {
    const temp: DocChecklistProps[] = [];

    //basis of application
    if (
      infoData?.applicationType.basisForApplicationArray[0]
        .lostStolenOrDestroyed
    ) {
      temp.push({
        name: "n565_certificate_or_declaration_was_lost_stolen_or_destroyed_docs",
        label:
          "Attach a copy of the certificate or declaration (if available), police report, and/or sworn statement.",
        info: "Basis for Application: My certificate or declaration was lost, stolen, or destroyed.",
      });
    }

    if (
      infoData?.applicationType.basisForApplicationArray[0].mutilated ||
      infoData?.applicationType.basisForApplicationArray[0].typoError
    ) {
      temp.push({
        name: "n565_certificate_or_declaration_is_mutilated_docs",
        label: "Attach the original certificate or declaration",
        info: "Basis for Application: My certificate or declaration is mutilated or My certificate or declaration is incorrect due to a typographical or clerical error by USCIS.",
      });
    }

    if (
      infoData?.applicationType.basisForApplicationArray[0].nameLegallyChanged
    ) {
      temp.push({
        name: "n565_name_has_legally_changed_docs",
        label:
          "Attach the original certificate or declaration and evidence of the name change",
        info: "Basis for Application: My name has legally changed.",
      });
    }

    if (
      infoData?.applicationType.basisForApplicationArray[0]
        .dateOfBirthLegallyChanged
    ) {
      temp.push({
        name: "n565_date_of_birth_has_legally_changed_docs",
        label:
          "Attach the original certificate or declaration and evidence of the date of birth change",
        info: "Basis for Application: My date of birth has legally changed through a court order or U.S. Government-issued document, and I am applying for a replacement Certificate of Citizenship.",
      });
    }

    if (infoData?.applicationType.basisForApplicationArray[0].changeGender) {
      temp.push({
        name: "n565_gender_changed_docs",
        label:
          "Attach the original certificate or declaration and evidence of the gender change",
        info: "Basis for Application: I am seeking to change the gender listed on my document",
      });
    }

    if (infoData?.applicationType.basisForApplicationArray[0].reasonNotListed) {
      temp.push({
        name: "n565_reason_for_applyingnot_listed_docs",
        label:
          "Attach the original certificate or declaration and any evidence documents",
        info: "Basis for Application: My reason for applying for a new document is not listed above.",
      });
    }

    // part 5
    if (
      infoData?.applicationType.nameChangedThrough ===
      N565NameChangedThroughDataValue.marriageDivorceOrAnnulment
    ) {
      temp.push({
        name: "n565_name_changed_through_marriage_divorce_or_annulment_docs",
        label:
          "Attach a copy of your marriage certificate, annulment decree, or divorce decree.",
        info: "For applying for a new document because of a name change through marriage, divorce, or annulment",
      });
    }

    if (
      infoData?.applicationType.nameChangedThrough ===
      N565NameChangedThroughDataValue.courtOrder
    ) {
      temp.push({
        name: "n565_name_changed_through_court_order_docs",
        label:
          "Attach a copy of either the original or certified court document",
        info: "For applying for a new document because of a name change through court order",
      });
    }

    //part 6
    if (
      infoData?.applicationType.dateOfBirthChangedThroughArray[0].courtOrder
    ) {
      temp.push({
        name: "n565_date_of_birth_changed_through_court_order_docs",
        label:
          "Attach a copy of either the original or certified court document",
        info: "For applying for a new Certificate of citizenship because of an official date of birth change through court order",
      });
    }

    if (
      infoData?.applicationType.dateOfBirthChangedThroughArray[0].courtOrder
    ) {
      temp.push({
        name: "n565_date_of_birth_changed_through_U.S_government_issued_document_docs",
        label:
          "Attach a copy of the U.S. government-issued document that reflects the changed date of birth.",
        info: "for example, birth certificate, certificate recognizing the foreign birth, certificate of birth abroad, or other similar vital records issued by the U.S. state where the child resided when the document was issued.",
      });
    }

    //part 7
    if (infoData?.applicationType.genderChangedThroughArray[0].courtOrder) {
      temp.push({
        name: "n565_gender_changed_through_court_order_docs",
        label:
          "Attach a copy of either the original or certified court document",
        info: "For applying for a new document because of an official change in gender through court order",
      });
    }

    if (infoData?.applicationType.genderChangedThroughArray[0].govIssuedDoc) {
      temp.push({
        name: "n565_gender_changed_through_government_issued_docs",
        label:
          "Attach a copy of the Government-Issued Document Reflecting the Gender Change",
        info: "For applying for a new document because of an official change in gender through Government-Issued Document",
      });
    }

    if (
      infoData?.applicationType.genderChangedThroughArray[0]
        .licensedHealthCareCertification
    ) {
      temp.push({
        name: "n565_gender_changed_through_licensed_health_docs",
        label: "Attach the certification letter",
        info: "For applying for a new document because of an official change in gender through Licensed Health Care Professional's Certification",
      });
    }

    return temp.concat(n565DocChecklist);
  };

  return (
    <RequestWrapper
      isLoading={isGetting || infoGetting}
      isError={isError || infoIsError}
      data={data && infoData && data?.length > 0 ? data : true}
    >
      <>
        {getN565DocChecklist().map((ev, i) => (
          <DocChecklistItem
            key={i}
            data={ev}
            onSubmit={onSubmit}
            isLoading={loadingField === ev.name && (isLoading || isUpdating)}
            savedData={
              data
                ? [...data].reverse().find((item) => item.name === ev.name)
                : undefined
            }
          />
        ))}
        {data && (
          <AdditionalDocItem
            filedetail={data}
            onSubmit={onSubmit}
            loadingField={loadingField}
            loadingState={isLoading || isUpdating}
          />
        )}

        <AdditionalDoc
          onSubmit={onSubmit}
          loadingField={loadingField}
          loadingState={isLoading || isUpdating}
        />
      </>
    </RequestWrapper>
  );
};

export default N565DocChecklist;
