import Marquee from "react-fast-marquee";
import Constaint from "../../../layout/components/Constaint";
import { TestimonialData } from "../../../landing page/components/data";
import styled from "styled-components";
import { listItemVariant } from "../../../landing page/components/variants";
import { motion } from "framer-motion";
import { ReactComponent as QuoteImg } from "../../../../../images/icons/quote.svg";
import Colors from "../../../../../styles/Colors";

const Testimonials = () => {
  const half = Math.ceil(TestimonialData.length / 2);
  const firstHalf = TestimonialData.slice(0, half);
  const secondHalf = TestimonialData.slice(half);

  return (
    <div>
      <Constaint>
        <div>
          <h2 className="text-center text-base/7 font-semibold text-Blue00">
            What our Customers say about us
          </h2>
          <p className="text-center mt-2 text-pretty text-4xl font-bold font-spartan tracking-tight text-gray-900 sm:text-5xl lg:text-balance">
            Testimonials
          </p>
          <Marquee gradient={true} speed={50} pauseOnHover>
            <div className="flex space-x-4 my-10">
              {firstHalf.map((testimony, i) => (
                <Card key={i} index={i} variants={listItemVariant}>
                  <Quote index={i}>
                    <QuoteImg />
                  </Quote>
                  <Para index={i}>{testimony.text}</Para>
                  <Avatar>
                    {/* <AvatarImage src={ev.img} /> */}
                    <AvatarContent>
                      <AvatarHeading index={i}>{testimony.name}</AvatarHeading>
                      {/* <AvatarFooter>{ev.location}</AvatarFooter> */}
                    </AvatarContent>
                  </Avatar>
                </Card>
              ))}
            </div>
          </Marquee>
          <Marquee gradient={true} speed={50} pauseOnHover direction="right">
            <div className="flex space-x-4 my-10">
              {secondHalf.map((testimony, i) => (
                <Card key={i} index={i} variants={listItemVariant}>
                  <Quote index={i}>
                    <QuoteImg />
                  </Quote>
                  <Para index={i}>{testimony.text}</Para>
                  <Avatar>
                    {/* <AvatarImage src={ev.img} /> */}
                    <AvatarContent>
                      <AvatarHeading index={i}>{testimony.name}</AvatarHeading>
                      {/* <AvatarFooter>{ev.location}</AvatarFooter> */}
                    </AvatarContent>
                  </Avatar>
                </Card>
              ))}
            </div>
          </Marquee>
        </div>
      </Constaint>
    </div>
  );
};

export default Testimonials;

interface CardProps {
  index: number;
}

const Card = styled(motion.div)<CardProps>`
  position: relative;
  width: 100%;
  /* height: 324px; */
  /* background: ${({ index }) => (index % 2 !== 0 ? "#435D76" : "#ffffff")}; */
  background-color: ${Colors.BlueEA};
  border-radius: 12px;
  padding: 100px 34px 31px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 23px;
  overflow-y: hidden;
  margin-inline: 14px;
`;

interface QuoteProps {
  index: number;
}

const Quote = styled.div<QuoteProps>`
  position: absolute;
  top: 20px;
  left: 34px;

  & > svg {
    /* fill: ${({ index }) =>
      index % 2 !== 0 ? "#A5B8CB" : "rgba(67, 93, 118, 0.51)"}; */
    fill: #0076e9;
    opacity: 0.5;
  }
`;

interface ParaProps {
  index: number;
}

const Para = styled.p<ParaProps>`
  width: 400px;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-style: italic;
  font-weight: 500;
  /* color: ${({ index }) => (index % 2 !== 0 ? "#F9FCFF" : "#31393c")}; */
  color: #31393c;
`;

const Avatar = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

// const AvatarImage = styled.img`
//   width: 50px;
//   height: 50px;
//   border-radius: 100%;
// `;

const AvatarContent = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

interface AvatarHeadingProps {
  index: number;
}

const AvatarHeading = styled.div<AvatarHeadingProps>`
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
  /* color: ${({ index }) => (index % 2 !== 0 ? "#F9FCFF" : "#31393c")}; */
  color: #31393c;
`;
// const AvatarFooter = styled.div`
//   font-weight: 400;
//   font-size: 0.625rem;
//   line-height: 0.75rem;
//   color: #a7aabd;
// `;

// const H2 = styled.h2`
//   font-style: italic;
//   font-weight: 600;
//   font-size: 2.25rem;
//   line-height: 2.6875rem;
//   text-align: center;
//   color: #31393c;
//   margin-top: 100px;
//   margin-bottom: 40px;
//   width: 100%;

//   @media screen and (min-width: 768px) {
//     width: 60%;
//   }
// `;

// const CardBtn = styled.button`
//   width: 243px;
//   height: 56px;
//   border-radius: 8px;
//   font-weight: 600;
//   font-size: 1rem;
//   line-height: 1.1875rem;
//   text-transform: uppercase;
//   color: #f9fcff;
//   background: ${Colors.Blue00};

//   &:hover {
//     background: #022e58;
//     color: #f9fcff;
//     transition: 0.3s ease-in-out;
//   }
// `;
