import { ApiResponse } from "../../types/types";
import request from "../request";
import { userDataResponse, countriesObj } from "./types";

export const getCountries = async () => {
  return await request.get<ApiResponse<countriesObj[]>>(
    `/user/get-country-and-states`
  );
};

export const getStates = async (country?: string) => {
  return await request.get<ApiResponse<countriesObj>>(
    `/user/single-country-and-states/${country}`
  );
};

export const getUserData = async () => {
  return await request.get<userDataResponse>(`/user/get-user-data`);
};

export const deleteForm = async (formCategory: string) => {
  return await request.delete<ApiResponse<string>>(
    `user/delete/${formCategory}`
  );
};

export const deleteAccount = async () => {
  return await request.delete<ApiResponse<string>>(`user/delete-account`);
};
