import React, { FC } from "react";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { n400FeeReductionObj } from "../../../../../../api/n400/types";
import { FormN400Progress } from "../../types";

interface Props {
  title: string;
  data?: n400FeeReductionObj;
}

const ReviewFeeDeduction: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      label:
        "My household income is less than or equal to 400% of the Federal Poverty Guidelines (see Instructions for required documentation).",
      value: data?.household_income,
    },
    {
      label: "Total household income:",
      value: data?.total_household_income,
    },
    {
      label: "My household size is:",
      value: data?.household_size,
    },
    {
      label:
        "Total number of household members earning income including yourself:",
      value: data?.total_number_of_household_members,
    },
    {
      label: "I am the head of household.",
      value: data?.head_of_household,
    },
    {
      label:
        "Total number of household members earning income including yourself:",
      value: data?.name_of_head_of_household,
    },
  ];

  return (
    <ReviewBox
      title={title}
      data={section}
      editUrl={FormN400Progress.checkPart4}
    />
  );
};

export default ReviewFeeDeduction;
