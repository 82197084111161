import { useNavigate } from "react-router-dom";
import { GetBusinessApplicationsObj } from "../api/business/applications/types";
import { WebPaths } from "../routes/data";
import { BusinessQueryKey } from "../api/utils/types";

const useBusinessContinueToForm = () => {
  const navigate = useNavigate();
  // const [searchParams] = useSearchParams();
  // const getApplicationId = searchParams.get(BusinessQueryKey.appId) || "";

  const businessContinueToForm = (data: GetBusinessApplicationsObj) => {
    // if (!data.applicationInfo || !data.eligibilityInfo) return;
    const formCategory = data.applicationType;
    const hasEligibility =
      data.eligibilityInfo?.ds160.isEligible &&
      data.eligibilityInfo?.ds160.isFilled;
    const isFormCompleted = data.applicationInfo?.isFormCompleted;
    const formProgress = data.applicationInfo?.formProgress;

    if (!hasEligibility) {
      navigate(
        `${WebPaths.checkEligibility}/${formCategory}?${BusinessQueryKey.appId}=${data.applicationId}`
      );
    } else if (isFormCompleted) {
      navigate(
        `${WebPaths.FormCategory}/${formCategory}?${BusinessQueryKey.appId}=${data.applicationId}&type=${formCategory}-review`
      );
    } else
      navigate(
        `${WebPaths.FormCategory}/${formCategory}?${BusinessQueryKey.appId}=${data.applicationId}&type=${formProgress}`
      );
  };

  return { businessContinueToForm };
};

export default useBusinessContinueToForm;
