import { FormCategories } from "../../../../../../../routes/type";

export const CaseTypeOptions = [
  {
    label: "All",
    value: "",
  },
  {
    label: "N-400",
    value: FormCategories.n400,
  },
  {
    label: "N-600",
    value: FormCategories.n600,
  },
  {
    label: "N-565",
    value: FormCategories.n565,
  },
  {
    label: "DS-160",
    value: FormCategories.ds160,
  },
];

export const StatusOptions = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Completed",
    value: "Completed",
  },
  {
    label: "Pending",
    value: "Pending",
  },
];

export const DoesNotApplyText = "Does not apply";
export const DoNotKnowText = "Do not know";

export const reviewDoesNotApply = (value?: string, condition?: boolean) => {
  return condition ? DoesNotApplyText : value;
};

export const reviewDoNotKnow = (value?: string, condition?: boolean) => {
  return condition ? DoNotKnowText : value;
};
