import React, { useEffect } from "react";
import Hero from "./components/Hero";
import Offers from "./components/Offers";
import HowItWorks from "./components/HowItWorks";
import Testimonials from "./components/Testimonials";
// import PricingTab from "../components/PricingTab";

const ForIndividuals = () => {
  useEffect(() => {
    document.title = `For Individuals | Empower Your Dreams, Get Your Visa Approved`;

    return () => {
      document.title =
        "SeamlessVisa | The Trusted Partner in Your Immigration Journey";
    };
  }, []);
  return (
    <div>
      <Hero />
      <Offers />
      <HowItWorks />
      {/* <PricingTab /> */}
      <Testimonials />
      {/* <About /> */}
      {/* <WhySeamless /> */}
      {/* <Mission /> */}
    </div>
  );
};

export default ForIndividuals;
