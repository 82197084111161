import {
  saveDS160AddressAndPhoneObj,
  saveDS160FamilyObj,
  saveDS160PassportObj,
  saveDS160PersonalInfo2Obj,
  saveDS160PersonalInfoObj,
  saveDS160PreviousUSTravelObj,
  saveDS160SecurityBackgroundP1Obj,
  saveDS160SecurityBackgroundP2Obj,
  saveDS160SecurityBackgroundP3Obj,
  saveDS160SecurityBackgroundP4Obj,
  saveDS160SecurityBackgroundP5Obj,
  saveDS160SEVAdditionalContactObj,
  saveDS160SivesObj,
  saveDS160TravelCompanionsObj,
  saveDS160TravelObj,
  saveDS160USContactObj,
  saveDS160WETAdditionalObj,
  saveDS160WETPresentObj,
  saveDS160WETPreviousObj,
} from "../../../../../../api/ds160/types";
import {
  reviewDoesNotApply,
  reviewDoNotKnow,
} from "../../../../components/data";
import { ReviewSectionChildrenData } from "../../../../components/Review/ReviewBox";
import { saveDS160MaritalStatusObj } from "../../FamilyRelatives/MaritalStatus";

export const ReviewPersonalSectionA = (
  personalOne: saveDS160PersonalInfoObj
): ReviewSectionChildrenData[] => [
  {
    label: "Family Name (Last Name)",
    value: personalOne?.lastName,
  },
  {
    label: "Given Names",
    value: personalOne?.givenNames,
  },
  {
    label: "Full Name in Native Alphabet",
    value: reviewDoesNotApply(personalOne?.fullName, personalOne.doesNotApply),
  },
  {
    label: "Have you used any other names since birth?",
    value: personalOne?.anyOtherNames,
  },
  {
    label: "Any Other Given Name",
    value: personalOne?.otherFirstName,
  },
  {
    label: "Any Other Middle Name",
    value: personalOne?.otherMiddleName,
  },
  {
    label: "Any Other Family Name",
    value: personalOne?.otherLastName,
  },
  {
    label: "Do you have a telecode that represents your name?",
    value: personalOne?.teleCode,
  },
  {
    label: "Telecode Surname",
    value: personalOne?.teleCodeSurname,
  },
  {
    label: "Telecode Given Names",
    value: personalOne?.teleCodeGivenNames,
  },
  {
    label: "Sex",
    value: personalOne?.sex,
  },
  // {
  //   label: "Marital status",
  //   value: personalOne?.maritalStatus,
  // },
  {
    section: {
      title: "Birth Information",
      children: [
        {
          label: "State and Province",
          value: personalOne?.stateAndProvince,
        },
        {
          label: "Date of birth",
          value: personalOne?.dateOfBirth,
        },
        {
          label: "City",
          value: personalOne?.city,
        },
        {
          label: "Country/Region",
          value: personalOne?.countryOrRegion,
        },
      ],
    },
  },
];

export const ReviewPersonalSectionB = (
  personalTwo: saveDS160PersonalInfo2Obj
): ReviewSectionChildrenData[] => [
  {
    label: "Country/Region of Origin (Nationality)",
    value: personalTwo?.countryOrRegion,
  },
  {
    label:
      "Do you hold or have you held another nationality other than the one indicated above on nationality?",
    value: personalTwo?.heldAnotherNationality,
  },
  {
    label:
      "Are you a permanent resident of a country/region other than your country/region of origin (nationality) indicated above?",
    value: personalTwo?.prOfOtherCountry,
  },
  {
    label: "Other Country/Region of Origin (Nationality)",
    value: personalTwo?.otherCountryOrRegion,
  },
  {
    label: "National Identification Number",
    value: reviewDoesNotApply(personalTwo?.nin, personalTwo.doesNotApplyNin),
  },
  {
    label: "U.S Social Security Number",
    value: reviewDoesNotApply(personalTwo?.SSN, personalTwo.doesNotApplySSN),
  },
  {
    label: "U.S Taxpayer ID Number",
    value: reviewDoesNotApply(
      personalTwo?.idNumber,
      personalTwo.doesNotApplyIdNumber
    ),
  },
];

export const ReviewTravelSection = (
  data: saveDS160TravelObj
): ReviewSectionChildrenData[] => [
  {
    label: "Purpose of Trip to the U.S",
    value: data?.purposeOfTrip,
  },
  {
    label: "Specific",
    value: data?.specific,
  },
  {
    label: "Have you made specific travel plans?",
    value: data?.haveYouMadeSpecificTravelPlans,
  },
  {
    label: "Date of Arrival in U.S",
    value: data?.dateOfArrival,
  },
  {
    label: "Arrival Flight (If known)",
    value: data?.arrivalFlight,
  },
  {
    label: "Arrival City",
    value: data?.arrivalCity,
  },
  {
    label: "Date of Departure from U.S",
    value: data?.dateOfDeparture,
  },
  {
    label: "Departure Flight (If known)",
    value: data?.departureFlight,
  },
  {
    label: "Departure City",
    value: data?.departureCity,
  },
  {
    label: "Location",
    value: data?.location,
  },
  {
    label: "Intended Date of Arrival",
    value: data?.intendedDateOfArrival,
  },
  {
    label: "Intended Length of Stay in U.S",
    value: `${data?.intendedLengthOfStayDuration} ${data?.intendedLengthOfStayDropDown}`,
  },
  {
    section: {
      title: "Address where you will stay in the U.S",
      children: [
        {
          label: "Street Address (Line 1)",
          value: data?.streetAddressLine1,
        },
        {
          label: "Street Address (Line 2)",
          value: data?.streetAddressLine2,
        },
        {
          label: "City",
          value: data?.city,
        },
        {
          label: "State",
          value: data?.state,
        },
        {
          label: "Zip Code (If known)",
          value: data?.zipCode,
        },
        {
          label: "Person Or Entity Paying For Your Trip",
          value: data?.personPaying,
        },
      ],
    },
  },
  {
    section: {
      title: "Person Paying information",
      children: [
        {
          label: "Surnames of Person Paying for Trip",
          value: data?.personPayingSurnames,
        },
        {
          label: "Given Names of Person Paying for Trip",
          value: data?.personPayingGivenNames,
        },
        {
          label: "Email Address",
          value: data?.personPayingEmail,
        },
        {
          label: "Relationship to You",
          value: data?.personPayingRelationship,
        },
        {
          label:
            "Is the address of the party paying for your trip the same as your Home or Mailing Address?",
          value: data?.personPayingIsSameAddress,
        },
        {
          label: "Street Address (Line 1)",
          value: data?.personPayingStreetAddressLine1,
        },
        {
          label: "Street Address (Line 2)",
          value: data?.personPayingStreetAddressLine2,
        },
        {
          label: "City",
          value: data?.personPayingCity,
        },
        {
          label: "Country",
          value: data?.personPayingCountry,
        },
        {
          label: "State",
          value: data?.personPayingState,
        },
        {
          label: "Postal Zone/ZIP Code",
          value: data?.personPayingZipCode,
        },
      ],
    },
  },

  {
    section: {
      title: "Other Company/Organization Paying information",
      children: [
        {
          label: "Name of Company/Organization Paying for Trip",
          value: data?.companyPayingName,
        },
        {
          label: "Telephone Number",
          value: data?.companyPayingTelephone,
        },
        {
          label: "Relationship to You",
          value: data?.companyPayingRelationship,
        },
        {
          label: "Street Address (Line 1)",
          value: data?.companyPayingStreetAddressLine1,
        },
        {
          label: "Street Address (Line 2)",
          value: data?.companyPayingStreetAddressLine2,
        },
        {
          label: "City",
          value: data?.companyPayingCity,
        },
        {
          label: "Country",
          value: data?.companyPayingCountry,
        },
        {
          label: "State",
          value: data?.companyPayingState,
        },
        {
          label: "Postal Zone/ZIP Code",
          value: data?.companyPayingZipCode,
        },
      ],
    },
  },
];

export const ReviewTravelCompSection = (
  data: saveDS160TravelCompanionsObj
): ReviewSectionChildrenData[] => [
  {
    section: {
      title: "Person Traveling with You",
      children: [
        {
          label: "Are there other persons traveling with you?",
          value: data?.otherPersonsTravelling,
        },
        {
          label: "Are you traveling as part of a group or organization?",
          value: data?.travellingAsPartOfGroup,
        },
        {
          label: "Surname of person traveling with you",
          value: data?.surname,
        },
        {
          label: "Given name of person traveling with you",
          value: data?.givenName,
        },
        {
          label: "Relationship with person",
          value: data?.relationshipwithPerson,
        },
        {
          label: "Enter group name",
          value: data?.groupName,
        },
      ],
    },
  },
];

export const ReviewPreviousUSSection = (
  data: saveDS160PreviousUSTravelObj
): ReviewSectionChildrenData[] => [
  {
    label: "Have you ever been to the US?",
    value: data?.everBeenToTheUs,
  },
  {
    label: "Date Arrived",
    value: data?.dateArrived,
  },
  {
    label: "Length of Stay",
    value: `${data?.lengthOfStayDuration} ${data?.lengthOfStayDropdown}`,
  },
  {
    label: "Do you or did you have a driver’s license?",
    value: data?.haveDriverLicense,
  },
  {
    label: "Driver’s License Number",
    value: data?.driverLicenseNumber,
  },
  {
    label: "State of Driver’s License",
    value: data?.stateOfDriverLicense,
  },
  {
    label: "Have you ever been issued a US visa?",
    value: data?.everIssuedUsVisa,
  },
  {
    label: "Date last visa was issued",
    value: data?.lastVisaDate,
  },
  {
    label: "Visa Number",
    value: reviewDoNotKnow(data?.visaNumber, data?.doNotKnowVisaNumber),
  },
  {
    label: "Are you applying for the same type of visa?",
    value: data?.applyingForSameTypeOfVisa,
  },
  {
    label:
      "Are you applying in the same country or location where the visa above was issued, and is this country or location your place of principal residence?",
    value: data?.applyingInSameLocation,
  },
  {
    label: "Have you been ten-printed?",
    value: data?.beenTenPrinted,
  },
  {
    label: "Has your US visa ever been lost or stolen?",
    value: data?.visaEverStolen,
  },
  {
    label: "Year",
    value: data?.visaStolenYear,
  },
  {
    label: "Explanation",
    value: data?.visaStolenExplanation,
  },
  {
    label: "Has your US visa ever been cancelled or revoked?",
    value: data?.visaEverRevoked,
  },
  {
    label: "Year",
    value: data?.visaRevokeYear,
  },
  {
    label: "Explanation",
    value: data?.visaRevokeExplanation,
  },
  {
    label:
      "Have you ever been refused a US visa or been refused admission to United States or withdrawn your application for admission at the port of entry?",
    value: data?.everBeenRefusedUsVisa,
  },
  {
    label: "Explanation",
    value: data?.everBeenRefusedUsVisaExplanation,
  },
  {
    label: "Are you or have you ever been a U.S. legal permanent resident?",
    value: data?.beenLPR,
  },
  {
    label: "Explanation",
    value: data?.beenLPRExplanation,
  },
  {
    label:
      "Has anyone ever filed an immigration petition on your behalf with the United States Citizenship and Immigration Services?",
    value: data?.anyoneFiledPetition,
  },
  {
    label: "Explanation",
    value: data?.anyoneFiledPetitionExplanation,
  },
];

export const ReviewAddressAndPhoneSection = (
  data: saveDS160AddressAndPhoneObj
): ReviewSectionChildrenData[] => [
  {
    label: "Home Address",
    value: data?.homeAddress,
  },
  {
    label: "Street Address Line 1",
    value: data?.streetAddressLine1,
  },
  {
    label: "Street Address Line 2 (Optional)",
    value: data?.streetAddressLine2,
  },
  {
    label: "City",
    value: data?.city,
  },
  {
    label: "Sate/Province",
    value: data?.stateOrProvince,
  },
  {
    label: "Country/Region",
    value: data?.countryOrRegion,
  },
  {
    label: "Postal Code/Zip Code",
    value: data?.zipCode,
  },
  {
    section: {
      title: "MAILING ADDRESS",
      children: [
        {
          label: "Is your mailing address the same as your home address?",
          value: data?.mailAddressSameAsHomeAddress,
        },
        {
          label: "Home Address",
          value: data?.mailHomeAddress,
        },
        {
          label: "Street Address Line 1",
          value: data?.mailStreetAddressLine1,
        },
        {
          label: "Street Address Line 2 (Optional)",
          value: data?.mailStreetAddressLine2,
        },
        {
          label: "City",
          value: data?.mailCity,
        },
        {
          label: "Sate/Province",
          value: data?.mailStateOrProvince,
        },
        {
          label: "Country Region",
          value: data?.mailCountryOrRegion,
        },
        {
          label: "Postal Code/Zip Code",
          value: data?.mailZipCode,
        },
        {
          label: "Primary Phone Number",
          value: data?.primaryPhoneNumber,
        },
        {
          label: "Secondary Phone Number",
          value: reviewDoesNotApply(
            data?.secondaryPhoneNumber,
            data?.doesNotApplySecPN
          ),
        },
        {
          label: "Work Phone Number",
          value: reviewDoesNotApply(
            data?.workPhoneNumber,
            data?.doesNotApplyWorkPN
          ),
        },
      ],
    },
  },
  {
    label: "Have you used any other phone number in the last five (5) years?",
    value: data?.anyOtherPhoneNumber,
  },
  {
    label: "Additional Phone Number",
    value: data?.additionalPhoneNumber,
  },
  {
    label: "Email Address",
    value: data?.email,
  },
  {
    label: "Have you used any other email address in the last five (5) years?",
    value: data?.anyOtherEmail,
  },
  {
    label: `Additional Email Address`,
    value: data?.additionalEmail,
  },
  {
    label: "Social Media",
    value: data?.socialMedia,
  },
  {
    label: "Social Media Identifier",
    value: data?.socialMediaIdentifier,
  },
  {
    label:
      "Do you wish to provide information about your presence and any other websites or applications you have used within the last 5 years to create or share content (photos videos updates)",
    value: data?.presenceOnWebOrApp,
  },
  {
    label: "Additional social media platform",
    value: data?.additionalSocialMedia,
  },
  {
    label: "Additional social media handle",
    value: data?.additionalSocialMediaHandle,
  },
];

export const ReviewFamilySectionA = (
  data: saveDS160FamilyObj
): ReviewSectionChildrenData[] => [
  {
    label: " ",
    value: " ",
  },
  {
    section: {
      title: "Father's Full name and Date of birth",
      children: [
        {
          label: "Surname",
          value: data?.fatherSurname,
        },
        {
          label: "Given Name",
          value: data?.fatherGivenNames,
        },
        {
          label: "Date of Birth",
          value: data?.fatherDateOfBirth,
        },
        {
          label: "Is your father in the US?",
          value: data?.isFatherInTheUs,
        },
      ],
    },
  },
  {
    section: {
      title: "Mother's Full name and Date of birth",
      children: [
        {
          label: "Surname",
          value: reviewDoNotKnow(data?.motherSurname, data?.doNotKnowSurname),
        },
        {
          label: "Given Names",
          value: reviewDoNotKnow(
            data?.motherGivenNames,
            data?.doNotKnowGivenNames
          ),
        },
        {
          label: "Date of Birth",
          value: data?.motherDateOfBirth,
        },
        {
          label: "Is your mother in the US?",
          value: data?.isMotherInTheUs,
        },
        {
          label: "Mother's Status",
          value: data?.motherStatus,
        },
        {
          label:
            "Do you have any immediate relatives, not including parents, in the united states?",
          value: data?.immediateRelativesInTheUs,
        },
        {
          label: "Do you have any other relatives in the united states?",
          value: data?.anyOtherRelatives,
        },
        {
          section: {
            title: "Provide the following details",
            children: [
              {
                label: "Surname",
                value: data?.surname,
              },
              {
                label: "Given Names",
                value: data?.givenNames,
              },
              {
                label: "Relative's Status",
                value: data?.relativeStatus,
              },
            ],
          },
        },
      ],
    },
  },
];

export const ReviewFamilySectionB = (
  maritalStatusData: saveDS160MaritalStatusObj
): ReviewSectionChildrenData[] => [
  {
    label: "Marital status",
    value: maritalStatusData?.maritalStatus,
  },
  {
    label: "Surname",
    value: maritalStatusData?.personSurname,
  },
  {
    label: "Given Name",
    value: maritalStatusData?.personGivenName,
  },
  {
    label: "Date of birth",
    value: maritalStatusData?.personDateOfBirth,
  },
  {
    label: "Country/Region",
    value: maritalStatusData?.personCountry,
  },
  {
    label: "City",
    value: maritalStatusData?.personCity,
  },
  {
    label: "Birth Country",
    value: maritalStatusData?.personBirthCountry,
  },
  {
    label: "Address",
    value: maritalStatusData?.personAddress,
  },
  {
    label: "Street Address (Line 1)",
    value: maritalStatusData?.personAddressStreetAddressLine1,
  },
  {
    label: "Street Address (Line 2)",
    value: maritalStatusData?.personAddressStreetAddressLine2,
  },
  {
    label: "City",
    value: maritalStatusData?.personAddressCity,
  },
  {
    label: "Country",
    value: maritalStatusData?.personAddressCountry,
  },
  {
    label: "State",
    value: maritalStatusData?.personAddressState,
  },
  {
    label: "Postal Zone/ZIP Code",
    value: maritalStatusData?.personAddressZipCode,
  },
];

export const ReviewPassportSection = (
  data: saveDS160PassportObj
): ReviewSectionChildrenData[] => [
  {
    label: "Passport/Travel Document Type",
    value: data?.passportType,
  },
  {
    label: "Passport/Travel Document Number",
    value: reviewDoesNotApply(data?.passportNumber, data?.doesNotApply),
  },
  {
    label: "Passport Book Number",
    value: data?.passportBookNumber,
  },
  {
    label: "Country",
    value: data?.country,
  },
  {
    section: {
      title: "Where was the passport/travel document issued?",
      children: [
        {
          label: "City",
          value: data?.city,
        },
        {
          label: "State province (if shown on passport)",
          value: data?.stateProvince,
        },
        {
          label: "Country or Region",
          value: data?.countryOrRegion,
        },
        {
          label: "Issuance Date",
          value: data?.issuanceDate,
        },
        {
          label: "Expiration Date",
          value: data?.expirationDate,
        },
        {
          label: "Have you ever lost a passport or had one stolen?",
          value: data?.everLostPassport,
        },
        {
          label: "Passport/Travel Document Number",
          value: data?.passportLostPassportNumber,
        },
        {
          label: "Country or Authority that issued passport/travel document",
          value: data?.passportLostCountry,
        },
        {
          label: "Explain",
          value: data?.passportLostExplain,
        },
      ],
    },
  },
];

export const ReviewSecuritySectionA = (
  partOne: saveDS160SecurityBackgroundP1Obj
): ReviewSectionChildrenData[] => [
  {
    label:
      "Do you have a communicable disease of public health significance communicable diseases of public significance include chancroid, gonorrhea, granuloma inguinale, infectious leprosy, lymphogranuloma venereum, infectious stage syphilis, active tuberculosis, and other diseases as determined by the  Department of health and human services?",
    value: partOne?.partOneI,
  },
  {
    label: "Other Status? explain.",
    value: partOne?.explanationPartOneI,
  },
  {
    label:
      "Do you have a mental or physical disorder that processes or is likely to pose a threat to the safety or welfare of yourself or others?",
    value: partOne?.partOneII,
  },
  {
    label: "Other Status? explain.",
    value: partOne?.explanationPartOneII,
  },
  {
    label: "Are you or have you ever been a drug abuser or addict?",
    value: partOne?.partOneIII,
  },
  {
    label: "Other Status? explain.",
    value: partOne?.explanationPartOneIII,
  },
];

export const ReviewSecuritySectionB = (
  partTwo: saveDS160SecurityBackgroundP2Obj
): ReviewSectionChildrenData[] => [
  {
    label:
      "Have you ever been arrested or convicted for any offence or crime even though subject of a pardon amnesty or other similar actions?",
    value: partTwo?.partTwoI,
  },
  {
    label: "Other Status? explain.",
    value: partTwo?.explanationPartTwoI,
  },
  {
    label:
      "Have you ever been related or engaged in a conspiracy to violate any law relating to controlled substances?",
    value: partTwo?.partTwoII,
  },
  {
    label: "Other Status? explain.",
    value: partTwo?.explanationPartTwoII,
  },
  {
    label:
      "Are you coming to the United States to engage in prostitution or unlawful commercialized vice or have you been engaged in  prostitution or procuring prostitutes within the past 10 years?",
    value: partTwo?.partTwoIII,
  },
  {
    label: "Other Status? explain.",
    value: partTwo?.explanationPartTwoIII,
  },
  {
    label:
      "Have you ever been involved in or do you seek to engage in money laundering?",
    value: partTwo?.partTwoIV,
  },
  {
    label: "Other Status? explain.",
    value: partTwo?.explanationPartTwoIV,
  },
  {
    label:
      "Have you ever committed or conspire to commit a human trafficking offence in the United States or outside United States?",
    value: partTwo?.partTwoV,
  },
  {
    label: "Other Status? explain.",
    value: partTwo?.explanationPartTwoV,
  },
  {
    label:
      "Have you ever loo in the aided abetted, assisted or colluded with an individual who has committed or conspire to commit a severe human trafficking offence in the United States or outside the United States?",
    value: partTwo?.partTwoVI,
  },
  {
    label: "Other Status? explain.",
    value: partTwo?.explanationPartTwoVI,
  },
  {
    label:
      "Are you the spouse son or daughter of an individual who has committed of conspired to commit human trafficking offence and United States or after United States and have you wouldn't last 5 years mainly benefited from the trafficking activities?",
    value: partTwo?.partTwoVII,
  },
  {
    label: "Other Status? explain.",
    value: partTwo?.explanationPartTwoVII,
  },
];

export const ReviewSecuritySectionC = (
  partThree: saveDS160SecurityBackgroundP3Obj
): ReviewSectionChildrenData[] => [
  {
    label:
      "Do you seek to engage in espionage sabotage, exports control violations or any other illegal activity while in the United States?",
    value: partThree?.partThreeI,
  },
  {
    label: "Other Status? explain.",
    value: partThree?.explanationPartThreeI,
  },
  {
    label:
      "Do you seek to engage in terrorist activities while in the United States or have you ever engage in terrorist activities?",
    value: partThree?.partThreeII,
  },
  {
    label: "Other Status? explain.",
    value: partThree?.explanationPartThreeII,
  },
  {
    label:
      "Have you ever or do you intend to provide financial assistance or other support to terrorists or terrorist organisations?",
    value: partThree?.partThreeIII,
  },
  {
    label: "Other Status? explain.",
    value: partThree?.explanationPartThreeIII,
  },
  {
    label: "Are you a member or representative of a terrorist organization?",
    value: partThree?.partThreeIV,
  },
  {
    label: "Other Status? explain.",
    value: partThree?.explanationPartThreeIV,
  },
  {
    label:
      "Are you the spouse son or daughter of an individual who has engaged in terrorist activity including providing financial assistance all the support 2 terrorist or terrorist organizations, in the last 5 years?",
    value: partThree?.partThreeV,
  },
  {
    label: "Other Status? explain.",
    value: partThree?.explanationPartThreeV,
  },
  {
    label:
      "Have you ever ordered incited committed assisted or otherwise participated in genocide?",
    value: partThree?.partThreeVI,
  },
  {
    label: "Other Status? explain.",
    value: partThree?.explanationPartThreeVI,
  },
  {
    label:
      "Have you ever committed, ordered, incited, assisted, or otherwise participated in torture?",
    value: partThree?.partThreeVII,
  },
  {
    label: "Other Status? explain.",
    value: partThree?.explanationPartThreeVII,
  },
  {
    label:
      "Have you ever committed, ordered, incited, assisted, or otherwise participated in extrajudicial killings, political killings, or other acts of violence?",
    value: partThree?.partThreeVIII,
  },
  {
    label: "Other Status? explain.",
    value: partThree?.explanationPartThreeVIII,
  },
  {
    label:
      "Have you ever engaged in the recruitment or the use of child soldiers?",
    value: partThree?.partThreeIX,
  },
  {
    label: "Other Status? explain.",
    value: partThree?.explanationPartThreeIX,
  },
  {
    label:
      "Have you ever been directly involved in the establishment or enforcement of population controls forcing a woman to undergo an abortion against her free choice or a man or a woman to undergo sterilization against her free will?",
    value: partThree?.partThreeX,
  },
  {
    label: "Other Status? explain.",
    value: partThree?.explanationPartThreeX,
  },
  {
    label:
      "Have you ever been directly involved in the coercive transplantation of human organs or bodily tissue?",
    value: partThree?.partThreeXI,
  },
  {
    label: "Other Status? explain.",
    value: partThree?.explanationPartThreeXI,
  },
];

export const ReviewSecuritySectionD = (
  partFour: saveDS160SecurityBackgroundP4Obj
): ReviewSectionChildrenData[] => [
  {
    label:
      "Have you ever sought to obtain or assist others to obtain a visa, entry, into the United States, or any other united states immigration benefit by fraud or willful misrepresentation or other unlawful means?",
    value: partFour?.partFourI,
  },
  {
    label: "Other Status? explain.",
    value: partFour?.explanationPartFourI,
  },
  {
    label: "Have you ever been removed or deported from any country?",
    value: partFour?.partFourII,
  },
  {
    label: "Other Status? explain.",
    value: partFour?.explanationPartFourII,
  },
];

export const ReviewSecuritySectionE = (
  partFive: saveDS160SecurityBackgroundP5Obj
): ReviewSectionChildrenData[] => [
  {
    label:
      "Have you ever withheld custody of a US citizen child outside the United States from a person granted legal custody by a U.S court?",
    value: partFive?.partFiveI,
  },
  {
    label: "Other Status? explain.",
    value: partFive?.explanationPartFiveI,
  },
  {
    label:
      "Have you voted in the united states in violation of any law or regulation?",
    value: partFive?.partFiveII,
  },
  {
    label: "Other Status? explain.",
    value: partFive?.explanationPartFiveII,
  },
  {
    label:
      "Have you ever renounced United States citizenship for the purposes of avoiding taxation?",
    value: partFive?.partFiveIII,
  },
  {
    label: "Other Status? explain.",
    value: partFive?.explanationPartFiveIII,
  },
];

export const ReviewSEVSectionA = (
  additionalContact: saveDS160SEVAdditionalContactObj
): ReviewSectionChildrenData[] => [
  {
    label: "Surname",
    value: additionalContact?.surname,
  },
  {
    label: "Given Names",
    value: additionalContact?.givenNames,
  },
  {
    label: "Street Address Line 1",
    value: additionalContact?.streetAddressLine1,
  },
  {
    label: "Street Address Line 2 (Optional)",
    value: additionalContact?.streetAddressLine2,
  },
  {
    label: "City",
    value: additionalContact?.city,
  },
  {
    label: "State/Province",
    value: additionalContact?.stateOrProvince,
  },
  {
    label: "Postal Code/Zip Code",
    value: additionalContact?.zipCode,
  },
  {
    label: "Country/Region",
    value: additionalContact?.countryOrRegion,
  },
  {
    label: "Phone Number",
    value: additionalContact?.telephoneNumber,
  },
  {
    label: "Email Address",
    value: additionalContact?.emailAddress,
  },
];

export const ReviewSEVSectionB = (
  sevis: saveDS160SivesObj
): ReviewSectionChildrenData[] => [
  {
    label: "SEVIS ID",
    value: sevis?.sevisID,
  },
  {
    label: "Name of School",
    value: sevis?.nameOfSchool,
  },
  {
    label: "Course Of Study",
    value: sevis?.courseOfStudy,
  },
  {
    label: "Street Address Line 1",
    value: sevis?.streetAddressLine1,
  },
  {
    label: "Street Address Line 2 (Optional)",
    value: sevis?.streetAddressLine2,
  },
  {
    label: "City",
    value: sevis?.city,
  },
  {
    label: "State/Province",
    value: sevis?.stateOrProvince,
  },
  {
    label: "Postal Code/Zip Code",
    value: sevis?.zipCode,
  },
];

export const ReviewUSContactSection = (
  data: saveDS160USContactObj
): ReviewSectionChildrenData[] => [
  {
    section: {
      title: "Contact person or organization in the united states",

      children: [
        {
          label: "Surname",
          value: data?.surname,
        },
        {
          label: "Given Name",
          value: reviewDoesNotApply(data?.givenNames, data?.doesNotApply),
        },
        {
          label: "Organization Name",
          value: data?.organizationName,
        },
        {
          label: "Relationship to you",
          value: data?.relationshipToYou,
        },
      ],
    },
  },

  {
    section: {
      title: "Address and phone number of the point of contact",
      children: [
        {
          label: "U.S Street Address (Line 1)",
          value: data?.usStreetAddressLine1,
        },
        {
          label: "U.S Street Address Line 2 (optional)",
          value: data?.usStreetAddressLine2,
        },
        {
          label: "City",
          value: data?.city,
        },
        {
          label: "State",
          value: data?.state,
        },
        {
          label: "Zip Code",
          value: data?.zipCode,
        },
        {
          label: "Phone Number",
          value: data?.phoneNumber,
        },
        {
          label: "Email Address",
          value: reviewDoesNotApply(
            data?.emailAddress,
            data?.emailDoesNotApply
          ),
        },
      ],
    },
  },
];

export const ReviewWETAdditionalSectionA = (
  present: saveDS160WETPresentObj
): ReviewSectionChildrenData[] => [
  {
    label: "Primary Occupation",
    value: present?.primaryOccupation,
  },
  {
    label: "Present employer or school name",
    value: present?.presentEmployerOrSchoolName,
  },
  {
    label: "Street Address Line 1",
    value: present?.streetAddressLine1,
  },
  {
    label: "Street Address Line 2 (Optional)",
    value: present?.streetAddressLine2,
  },
  {
    label: "City",
    value: present?.city,
  },
  {
    label: "State/Province",
    value: present?.stateOrProvince,
  },
  {
    label: "Postal Code/Zip Code",
    value: present?.zipCode,
  },
  {
    label: "Phone Number",
    value: present?.phoneNumber,
  },
  {
    label: "Country/Region",
    value: present?.countryOrRegion,
  },
  {
    label: "Start Date",
    value: present?.startDate,
  },
  {
    label: "Monthly income in local currency",
    value: reviewDoesNotApply(present?.monthlyIncome, present.doesNotApply),
  },
  {
    label: "Briefly describe your duties",
    value: present?.dutiesDescription,
  },
];

export const ReviewWETAdditionalSectionB = (
  previous: saveDS160WETPreviousObj
): ReviewSectionChildrenData[] => [
  {
    label: "Were you previously employed?",
    value: previous?.wereYouPreviouslyEmployed,
  },
  {
    label: "Employer Name",
    value: previous?.employerName,
  },
  {
    label: "Street Address Line 1",
    value: previous?.employerStreetAddressLine1,
  },
  {
    label: "Street Address Line 2 (Optional)",
    value: previous?.employerStreetAddressLine2,
  },
  {
    label: "City",
    value: previous?.city,
  },
  {
    label: "State or Province",
    value: previous?.stateOrProvince,
  },
  {
    label: "Postal Code/Zip Code",
    value: previous?.zipCode,
  },
  {
    label: "Phone number",
    value: previous?.phoneNumber,
  },
  {
    label: "Country/Region",
    value: previous?.countryOrRegion,
  },
  {
    label: "Job title",
    value: previous?.jobTitle,
  },
  {
    label: "Supervisor's Surname",
    value: previous?.supervisorSurname,
  },
  {
    label: "Supervisor's Given Name",
    value: previous?.supervisorGivenNames,
  },
  {
    label: "Employment Date from",
    value: previous?.employmentDateFrom,
  },
  {
    label: "Employment Date to",
    value: previous?.employmentDateTo,
  },
  {
    label: "Briefly Describe your duties",
    value: previous?.dutiesDescription,
  },
];

export const ReviewWETAdditionalSectionC = (
  additional: saveDS160WETAdditionalObj
): ReviewSectionChildrenData[] => [
  {
    label: "Do you belong to a Clan or Tribe?",
    value: additional?.belongToClanOrTribe,
  },
  {
    label: "Clan or Tribe Name",
    value: additional?.tribeName,
  },
  {
    label: "Provide a list of the Languages you speak`",
    value: additional?.languages,
  },
  {
    label: "Have you traveled to any countries/region within the last 5years?",
    value: additional?.travelToCountriesOrRegions,
  },
  {
    label: "Country/Region",
    value: additional?.countryOrRegion,
  },
  {
    label:
      "Have you ever belonged to, contributed to, or worked for any professional, social or charitable organization?",
    value: additional?.belongToCharitableOrg,
  },
  {
    label: "Organisation Name",
    value: additional?.organizationName,
  },
  {
    label:
      "Do you have any specialised skills or training such as firearms explosives no clear biological or chemical experience?",
    value: additional?.anySpecialisedSkills,
  },
  {
    label: "Other Status? explain",
    value: additional?.skillExplanation,
  },
  {
    label: "Have you ever served in the military?",
    value: additional?.everServedInTheMilitary,
  },
  {
    label: "Name of Country/Region",
    value: additional?.militaryCountryOrRegion,
  },
  {
    label: "Branch Of Service",
    value: additional?.branchOfService,
  },
  {
    label: "Rank/Position",
    value: additional?.rank,
  },
  {
    label: "Military Specialty",
    value: additional?.militarySpecialty,
  },
  {
    label: "Date of Service From",
    value: additional?.dateOfServiceFrom,
  },
  {
    label: "Date of Service to",
    value: additional?.dateOfServiceTo,
  },
];
