import FormSectionWrapper from "../../../components/FormSectionWrapper";
import {
  ArrayWrapper,
  Info,
  InfoBold,
  SubTitle,
  VerticalFormWrapper,
  Wrapper,
} from "../styles";
import { FieldArray, Form, Formik } from "formik";
import SelectField from "../../../components/formik fields/SelectField";
import FormBtn from "../../../components/FormBtn";
import styled from "styled-components";
import InputField from "../../../components/formik fields/InputField";
import CheckBoxField from "../../../components/formik fields/CheckBoxField";
import {
  GenericYesNo,
  GenericYesNoOptionsValue,
  ExplicitNGDateFormat,
  ToSaveDate,
  getSelectedOption,
  initCheck,
  initDate,
  initInput,
  initSelect,
} from "../../../components/data";
import { ImmigrationStatus, processReviewData, RelativeStatus } from "../data";
import DatePickerField from "../../../components/formik fields/DatePickerField";
import RadioComp from "../../../components/RadioComp";
import { Modify, SingleOption } from "../../../../../types/types";
import { saveDS160FamilyObj } from "../../../../../api/ds160/types";
import { Dayjs } from "dayjs";
import {
  useGetDS160Family,
  useSaveDS160Family,
} from "../../../../../hooks/ds160/usDS160";
import GroupedSection from "../GroupedSection";
import AddAnotherBtn from "../AddAnotherBtn";
import { FamilyRelativeValidation } from "../validation";
import { resetDynamicFields } from "../../../components/data";
import useToNextSection from "../../../../../hooks/useToNextSection";
import useAiReview from "../useAiReview";
import { ReviewFamilySectionA } from "../Review/components/data";
import { useSearchParams } from "react-router-dom";
import { BusinessQueryKey } from "../../../../../api/utils/types";

const initialRelativesArray = {
  surname: "",
  givenNames: "",
  relativeStatus: null,
};

interface relativesArrayPropsPayload {
  surname: string;
  givenNames: string;
  relativeStatus: SingleOption | null;
}

export interface FamilyData
  extends Modify<
    Omit<saveDS160FamilyObj, "surname" | "givenNames" | "relativeStatus">,
    {
      fatherStatus: SingleOption | null;
      fatherDateOfBirth: Dayjs | null;
      motherDateOfBirth: Dayjs | null;
      motherStatus: SingleOption | null;
      relativesArray: relativesArrayPropsPayload[];
    }
  > {}

const FamilyRelative = () => {
  const { toNextSection } = useToNextSection();
  const [searchParams] = useSearchParams();
  const getApplicationId = searchParams.get(BusinessQueryKey.appId) || "";
  const { data, isFetching, isError } = useGetDS160Family(getApplicationId);
  const { mutate, isLoading: isSaving } = useSaveDS160Family();
  const { postAiReview, riskData, riskError, riskLoading, riskSaving } =
    useAiReview();

  const savedRelativesArray = () => {
    if (data?.surname || data?.givenNames || data?.relativeStatus) {
      const surnameData = data?.surname
        .split(",")
        .map((ev) => ({ surname: ev }));
      const givenNameData = data?.givenNames
        .split(",")
        .map((ev) => ({ givenNames: ev }));
      const relativeStatusData = data?.relativeStatus.split(",").map((ev) => ({
        relativeStatus: getSelectedOption(RelativeStatus, ev),
      }));

      return surnameData.map((ev, i) => ({
        ...ev,
        ...givenNameData[i],
        ...relativeStatusData[i],
      }));
    } else return [initialRelativesArray];
  };

  const onSubmit = (values: FamilyData) => {
    const {
      relativesArray,
      fatherDateOfBirth,
      motherDateOfBirth,
      motherStatus,
      fatherStatus,
      ...rest
    } = values;

    const payload = {
      ...rest,
      fatherDateOfBirth: ToSaveDate(fatherDateOfBirth, ExplicitNGDateFormat),
      motherDateOfBirth: ToSaveDate(motherDateOfBirth, ExplicitNGDateFormat),
      fatherStatus: fatherStatus?.value || "",
      motherStatus: motherStatus?.value || "",
      surname: relativesArray.map((ev) => ev.surname).join(","),
      givenNames: relativesArray.map((ev) => ev.givenNames).join(","),
      relativeStatus: relativesArray
        .map((ev) => ev.relativeStatus?.value || "")
        .join(","),
    };

    const section = ReviewFamilySectionA(payload);
    const result = processReviewData(section);

    const riskPayload = {
      ...riskData,
      family: {
        ...riskData?.family,
        ...result,
      },
    };

    const onSave = () => {
      mutate(
        { ...payload, applicationId: getApplicationId },
        {
          onSuccess: toNextSection,
        }
      );
    };

    postAiReview({
      riskPayload: riskPayload,
      onSave,
    });
  };

  const initialValues: FamilyData = {
    fatherSurname: initInput(data?.fatherSurname),
    fatherGivenNames: initInput(data?.fatherGivenNames),
    fatherDateOfBirth: initDate(data?.fatherDateOfBirth, ExplicitNGDateFormat),
    isFatherInTheUs: initInput(data?.isFatherInTheUs),
    fatherStatus: initSelect(ImmigrationStatus, data?.fatherStatus),
    motherSurname: initInput(data?.motherSurname),
    doNotKnowSurname: initCheck(data?.doNotKnowSurname),
    motherGivenNames: initInput(data?.motherGivenNames),
    doNotKnowGivenNames: initCheck(data?.doNotKnowGivenNames),
    motherDateOfBirth: initDate(data?.motherDateOfBirth, ExplicitNGDateFormat),
    isMotherInTheUs: initInput(data?.isMotherInTheUs),
    motherStatus: initSelect(ImmigrationStatus, data?.motherStatus),
    immediateRelativesInTheUs: initInput(data?.immediateRelativesInTheUs),
    relativesArray: savedRelativesArray(),
    anyOtherRelatives: initInput(data?.anyOtherRelatives),
  };

  return (
    <FormSectionWrapper
      isLoading={isFetching || riskLoading}
      isError={isError || riskError}
    >
      <Wrapper>
        <Info>
          <InfoBold>
            NOTE: Provide the following information concerning your Family.
          </InfoBold>
        </Info>
        <Formik
          initialValues={initialValues}
          validationSchema={FamilyRelativeValidation}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <Sub>Father's Full name and Date of birth</Sub>

                <InputField
                  label="Surname"
                  placeholder=""
                  name="fatherSurname"
                />

                <InputField
                  label="Given Name"
                  placeholder=""
                  name="fatherGivenNames"
                />

                <DatePickerField
                  name="fatherDateOfBirth"
                  label="Date of Birth"
                  placeholder={ExplicitNGDateFormat}
                  disableFuture={true}
                />

                <RadioComp
                  title="Is your father in the US?"
                  name="isFatherInTheUs"
                  options={GenericYesNo}
                />

                {/* if yes ask for status */}
                {formik.values.isFatherInTheUs ===
                  GenericYesNoOptionsValue.yes && (
                  <div className="px-2 py-3 border rounded">
                    <SelectField
                      name="fatherStatus"
                      label="Father's Status"
                      options={ImmigrationStatus}
                    />
                  </div>
                )}

                <Sub>Mother's Full name and Date of birth</Sub>

                <div className="flex flex-col gap-2">
                  <InputField
                    label="Surname"
                    placeholder=""
                    name="motherSurname"
                    isDisabled={!!formik.values.doNotKnowSurname}
                  />
                  <CheckBoxField
                    name="doNotKnowSurname"
                    label="I don't know"
                    onChange={() => {
                      resetDynamicFields(formik, [
                        { name: "motherSurname", value: "" },
                      ]);
                    }}
                  />
                </div>

                <div className="flex flex-col gap-2">
                  <InputField
                    label="Given Names"
                    placeholder=""
                    name="motherGivenNames"
                    isDisabled={!!formik.values.doNotKnowGivenNames}
                  />
                  <CheckBoxField
                    name="doNotKnowGivenNames"
                    label="I don't know"
                    onChange={() => {
                      resetDynamicFields(formik, [
                        { name: "motherGivenNames", value: "" },
                      ]);
                    }}
                  />
                </div>

                <DatePickerField
                  name="motherDateOfBirth"
                  label="Date of Birth"
                  placeholder={ExplicitNGDateFormat}
                  disableFuture={true}
                />

                {/* DYNAMIC QUESTION */}
                <RadioComp
                  title="Is your mother in the US?"
                  name="isMotherInTheUs"
                  options={GenericYesNo}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      {
                        name: "motherStatus",
                        value: null,
                      },
                    ]);
                  }}
                />

                {/* if yes ask for status */}
                {formik.values.isMotherInTheUs ===
                  GenericYesNoOptionsValue.yes && (
                  <div className="px-2 py-3 border rounded">
                    <SelectField
                      name="motherStatus"
                      label="Mother's Status"
                      options={ImmigrationStatus}
                    />
                  </div>
                )}

                {/* if no do nothing */}
                {/* END MOTHER IN US */}

                {/* DYNAMIC QUESTION */}
                <RadioComp
                  title="Do you have any immediate relatives, not including parents, in the united states?"
                  name="immediateRelativesInTheUs"
                  options={GenericYesNo}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      {
                        name: "relativesArray",
                        value: [initialRelativesArray],
                      },
                      {
                        name: "anyOtherRelatives",
                        value: "",
                      },
                    ]);
                  }}
                />

                {/* if yes ask for name and related */}
                {formik.values.immediateRelativesInTheUs ===
                  GenericYesNoOptionsValue.yes && (
                  <div className="px-2 py-3 border rounded">
                    <span className="block mb-5 font-bold">
                      Provide the following details:
                    </span>
                    <FieldArray
                      name="relativesArray"
                      render={(arrayHelpers) => (
                        <ArrayWrapper>
                          {formik.values.relativesArray.map((_, index) => (
                            <GroupedSection
                              key={index}
                              index={index}
                              onCancel={() => arrayHelpers.remove(index)}
                            >
                              <div className="mb-4">
                                <InputField
                                  label="Surname"
                                  placeholder=""
                                  name={`relativesArray[${index}].surname`}
                                />
                              </div>

                              <div className="mb-4">
                                <InputField
                                  label="Given Names"
                                  placeholder=""
                                  name={`relativesArray[${index}].givenNames`}
                                />
                              </div>

                              <div className="mb-">
                                <SelectField
                                  name={`relativesArray[${index}].relativeStatus`}
                                  label="Relative's Status"
                                  options={RelativeStatus}
                                />
                              </div>
                            </GroupedSection>
                          ))}
                          <AddAnotherBtn
                            onClick={() =>
                              arrayHelpers.push(initialRelativesArray)
                            }
                          />
                        </ArrayWrapper>
                      )}
                    />
                  </div>
                )}

                {formik.values.immediateRelativesInTheUs ===
                  GenericYesNoOptionsValue.no && (
                  <RadioComp
                    title="Do you have any other relatives in the united states?"
                    name="anyOtherRelatives"
                    options={GenericYesNo}
                  />
                )}

                {/* end both */}
              </FormWrapper>
              <FormBtn
                isLoading={isSaving || riskSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default FamilyRelative;

const FormWrapper = styled(VerticalFormWrapper)`
  & > div {
    /* width: 50%; */
  }
`;

export const Sub = styled(SubTitle)`
  margin-bottom: 0;
`;
