import { motion } from "framer-motion";
import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { ContainerVariants } from "./variant";
import Overlay from "./Overlay";
import { createPortal } from "react-dom";
import { BsArrowRightShort } from "react-icons/bs";
import { ImCancelCircle } from "react-icons/im";
import CongratsImage from "../../../../../images/web/congrats.svg";
import QuestionImage from "../../../../../images/web/question-mark-circle-outline.svg";
import { mediaObj } from "../../../../../styles/Media";
import Colors from "../../../../../styles/Colors";
import { useNavigate, useSearchParams } from "react-router-dom";
import { WebPaths } from "../../../../../routes/data";
import useIsLoggedIn from "../../../../../hooks/useIsLoggedIn";
import ParamFormName from "../ParamFormName";
import {
  eligibilityDataStore,
  seamlessEligibilityData,
} from "../../../../../store/eligibilityData";
import { useSnapshot } from "valtio";
import storage from "../../../../../utils/storage";
import useFormsEligibility from "../../../../../hooks/useFormsEligibility";
import useIsCurrentOrFreshForm from "../../../../../hooks/useIsCurrentOrFreshForm";
import { clearEligibilityStoreAndLocalStorage } from "../data";
import useIsEligibilityFormFilled from "../../../../../hooks/useHasFilledCurrentEligibility";
import { FormCategories } from "../../../../../routes/type";
import { BusinessQueryKey } from "../../../../../api/utils/types";

export enum ECongratulationType {
  eligible = "eligible",
  notEligible = "not-eligible",
  reStart = "restart quiz",
  info = "info",
}

interface Props {
  onClose: () => void;
  type?: ECongratulationType;
  formName?: string;
  formToRetake?: string;
  secondBtnText?: string;
  onRestart: () => void;
  infoContent?: JSX.Element | string;
}

const EligibilityModal: FC<Props> = ({
  onClose,
  type,
  formName,
  formToRetake,
  onRestart,
  secondBtnText,
  infoContent,
}) => {
  const { eligibilityData } = useSnapshot(eligibilityDataStore);
  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();
  const { onSaveFormEligibility, formEligiblityLoading, formEligiblityError } =
    useFormsEligibility();
  const { getIsCurrentForm } = useIsCurrentOrFreshForm();
  const { getIsEligibilityFormFilled } = useIsEligibilityFormFilled();
  const [searchParams] = useSearchParams();
  const getApplicationId = searchParams.get(BusinessQueryKey.appId);

  useEffect(() => {
    if (isLoggedIn) {
      onSaveFormEligibility(formName);
      // clearEligibilityStoreAndLocalStorage();
    } else {
      storage.set(seamlessEligibilityData, eligibilityData);
    }
  }, [formName, eligibilityData, isLoggedIn, onSaveFormEligibility]);

  // useEffect(() => {
  //   if (formName && getIsEligibilityFormFilled(formName as FormCategories)) {
  //     clearEligibilityStoreAndLocalStorage();
  //   }
  // }, [getIsEligibilityFormFilled, formName]);

  if (formEligiblityLoading)
    return createPortal(
      <Overlay onClose={() => {}}>
        <Wrapper
          onClick={(e) => e.stopPropagation()}
          variants={ContainerVariants}
        >
          <ContentWrapper>
            <TextBold>Getting your eligibility status...</TextBold>
          </ContentWrapper>
        </Wrapper>
      </Overlay>,
      document.body
    );

  if (formEligiblityError)
    return createPortal(
      <Overlay onClose={onClose}>
        <Wrapper
          onClick={(e) => e.stopPropagation()}
          variants={ContainerVariants}
        >
          <Cancel onClick={onClose}>
            <ImCancelCircle />
          </Cancel>
          <MainIcon>
            <img src={CongratsImage} alt="" />{" "}
          </MainIcon>
          <ContentWrapper>
            <TextBold className="text-[#ff0000]">
              ⚠️ Oops! we encountered an error. Please try agin later.
            </TextBold>
          </ContentWrapper>
        </Wrapper>
      </Overlay>,
      document.body
    );

  const getTitle = () => {
    if (type === ECongratulationType.notEligible)
      return (
        <>
          Not Eligible for <ParamFormName />
        </>
      );
  };

  const getSubTitle = () => {
    if (type === ECongratulationType.eligible)
      return (
        <>
          <ParaBold>Congratulations!!!</ParaBold> You are eligible to apply for
          the <ParaBold>{<ParamFormName />}</ParaBold>
        </>
      );
    if (type === ECongratulationType.notEligible)
      return (
        <p>
          Please take the{" "}
          <span className="font-bold uppercase">{formToRetake}</span>{" "}
          Eligibility Quiz.{" "}
        </p>
      );

    if (type === ECongratulationType.reStart)
      return `Based on your answers so far, you are currently not eligible to use our software with the form you selected.`;

    if (type === ECongratulationType.info) return infoContent;
  };

  const getSubText = () => {
    if (type === ECongratulationType.eligible)
      if (!isLoggedIn)
        return `Create an account and start your application, please click the button below.`;
      else
        return (
          <>
            {`Please click the button below to start your `} <br />{" "}
            <ParamFormName /> {`application.`}{" "}
          </>
        );
  };

  const getIcon = () => {
    if (type === ECongratulationType.eligible)
      return <img src={CongratsImage} alt="" />;
    if (type === ECongratulationType.notEligible) return <ImCancelCircle />;
    if (type === ECongratulationType.reStart) return <ImCancelCircle />;
    if (type === ECongratulationType.info)
      return <img src={QuestionImage} alt="" />;
  };

  const getBtnText = () => {
    if (type === ECongratulationType.eligible) {
      if (!isLoggedIn) return `Create Account`;
      return "Start Application";
    }
    if (type === ECongratulationType.notEligible)
      return `${formToRetake} Eligibility Check`;
    if (
      type === ECongratulationType.reStart ||
      type === ECongratulationType.info
    )
      return `Restart quiz`;
  };

  const getBtnNav = () => {
    if (type === ECongratulationType.eligible) {
      if (!isLoggedIn) return navigate(WebPaths.Join);
      else if (getIsCurrentForm(formName)) {
        navigate(
          `${WebPaths.FormCategory}/${formName}${
            getApplicationId
              ? `?${BusinessQueryKey.appId}=${getApplicationId}`
              : ""
          }`
        );
      } else {
        navigate(WebPaths.Profile);
      }
      // else if (formName) return onSaveFormEligibility(formName);
      // return navigate(`${WebPaths.FormCategory}/${formName}`);
      // else return navigate(WebPaths.Profile);
    }

    if (type === ECongratulationType.notEligible) {
      onClose();
      onRestart();
      navigate(`${WebPaths.checkEligibility}/${formToRetake}`);

      return;
    }
    if (type === ECongratulationType.reStart) {
      onClose();
      onRestart();
      clearEligibilityStoreAndLocalStorage();
      return;
    }
    if (type === ECongratulationType.info) {
      onClose();
      onRestart();
      clearEligibilityStoreAndLocalStorage();
      return;
    }
  };

  const getSecondBtnNav = () => {
    if (type === ECongratulationType.info) {
      onClose();
      return;
    }
  };

  const Title = getTitle();
  const SubTitle = getSubTitle();
  const subText = getSubText();
  const Icon = getIcon();
  const BtnText = getBtnText();

  return createPortal(
    <Overlay onClose={onClose}>
      <Wrapper
        onClick={(e) => e.stopPropagation()}
        variants={ContainerVariants}
      >
        <Cancel onClick={onClose}>
          <ImCancelCircle />
        </Cancel>

        <>
          {Icon && <MainIcon>{Icon}</MainIcon>}

          {Title && <Header>{Title}</Header>}
          {SubTitle && <Para>{SubTitle}</Para>}
          {subText && <SubText>{subText}</SubText>}

          {BtnText && (
            <BtnWrapper>
              <CTA onClick={getBtnNav}>
                {BtnText}
                <BsArrowRightShort />
              </CTA>
              {secondBtnText && (
                <ContinueBtn onClick={getSecondBtnNav}>
                  {secondBtnText}
                </ContinueBtn>
              )}
            </BtnWrapper>
          )}
        </>
      </Wrapper>
    </Overlay>,
    document.body
  );
};

export default EligibilityModal;

const Wrapper = styled(motion.div)`
  width: 802px;
  max-height: 80vh;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 12px;
  padding: 76px 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${mediaObj.smallDesktop} {
    margin: auto;
    width: 70vw;
    padding-left: 5%;
    padding-right: 5%;
  }

  ${mediaObj.tablet} {
    width: 80vw;
  }

  ${mediaObj.bigMobile} {
    width: 90vw;
  }
`;

const Cancel = styled.div`
  position: absolute;
  right: 40px;
  top: 48px;
  width: 24px;
  height: 24px;
  color: ${Colors.Black};
  cursor: pointer;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const MainIcon = styled.div`
  width: 108px;
  height: 108px;
  margin-bottom: 16px;

  & > svg,
  & > img {
    width: 100%;
    height: 100%;
    fill: ${Colors.RedC3};
  }
`;

const Header = styled.div`
  font-weight: 700;
  font-size: 1.5rem;
  color: ${Colors.Black31};
  text-align: center;
`;

const Para = styled.p`
  font-size: 1rem;
  color: ${Colors.Black31};
  margin-top: 16px;
  margin-bottom: 40px;
  text-align: center;
  font-weight: 400;
`;

const SubText = styled(Para)`
  margin-bottom: 14px;
  margin-top: 0;
  width: 80%;

  ${mediaObj.tablet} {
    width: 100%;
  }
`;

const ParaBold = styled.span`
  font-weight: bold;
`;

const BtnWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
`;

const Btn = styled.button`
  width: max-content;
  height: 46px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 24px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
  white-space: nowrap;
  text-transform: uppercase;
  margin: auto;

  & > svg {
    width: 20px;
    height: 20px;
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const CTA = styled(Btn)`
  background: ${Colors.Blue00};
  color: ${Colors.WhiteF9};

  &:hover {
    background: #022e58;
    color: ${Colors.WhiteF9};
    transition: 0.3s ease-in-out;
  }
`;

const ContinueBtn = styled(Btn)`
  color: ${Colors.Blue00};
  border: 1px solid ${Colors.Blue00};
`;

const ContentWrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Text = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
`;

const TextBold = styled(Text)`
  font-weight: bold;
`;
