import { useGetCountries } from "../../../hooks/utils/useUtils";
import { FormatingData } from "./data";
import { countriesObj } from "../../../api/utils/types";

const useCountries = () => {
  const { data: countryData, isLoading: countryLoading } = useGetCountries();

  const formattedCountryData =
    FormatingData<countriesObj>("name", "name", countryData) || [];
  return { formattedCountryData, countryLoading };
};

export default useCountries;
