import { ApiResponse } from "../../types/types";
import { GetFileDetailObj, N400InfoObj } from "../n400/types";
import request from "../request";
import { PreparerInterpreterObj } from "../utils/types";
import {
  N600BiographicInfoPayload,
  N600EligibilityInfoPayload,
  N600EligibilityPayload,
  N600InfoAboutUSCitizenFatherPart1Payload,
  N600InfoAboutUSCitizenFatherPart2Payload,
  N600InfoAboutUSCitizenMotherPart1Payload,
  N600InfoAboutUSCitizenMotherPart2Payload,
  N600InfoAboutYouPart1Payload,
  N600InfoAboutYouPart2Payload,
  N600InfoAboutYouPart3Payload,
  N600InfoAboutYouPart4Payload,
  N600InfoData,
  N600MilitaryServicePayload,
  N600PhysicalPresencePayload,
  payN600Data,
  UpdateN600FilesPayload,
} from "./types";

export const saveN600Eligibility = async (payload: N600EligibilityPayload) => {
  return await request.post<ApiResponse>(`/user/n600-eligibility`, payload);
};

export const getN600Eligibility = async () => {
  return await request.get<ApiResponse<N600EligibilityPayload>>(
    `/user/get-n600-eligibility`
  );
};

export const uploadN600Files = async (payload: FormData) => {
  return await request.post<ApiResponse>(`/user/upload-n600-files`, payload);
};

export const getN600Files = async () => {
  return await request.get<ApiResponse<GetFileDetailObj[]>>(
    `/user/get-upload-n600-files`
  );
};

export const updateN600Files = async (payload: UpdateN600FilesPayload) => {
  return await request.post<ApiResponse>(
    `/user/update-n600-files/${payload.id}/${payload.formCategory}`,
    payload.data
  );
};

export const saveN600InfoAboutEligibility = async (
  payload: N600EligibilityInfoPayload
) => {
  return await request.post<ApiResponse>(
    `/user/n600-info-about-eligibility`,
    payload
  );
};

export const getN600InfoAboutEligibility = async () => {
  return await request.get<ApiResponse<N600EligibilityInfoPayload>>(
    `/user/get-n600-info-about-eligibility`
  );
};

export const saveN600InfoAboutYouPart1 = async (
  payload: N600InfoAboutYouPart1Payload
) => {
  return await request.post<ApiResponse>(
    `/user/n600-applicant-info-one`,
    payload
  );
};

export const getN600InfoAboutYouPart1 = async () => {
  return await request.get<ApiResponse<N600InfoAboutYouPart1Payload>>(
    `/user/get-n600-applicant-info-one`
  );
};

export const saveN600InfoAboutYouPart2 = async (
  payload: N600InfoAboutYouPart2Payload
) => {
  return await request.post<ApiResponse>(
    `/user/n600-applicant-info-two`,
    payload
  );
};

export const getN600InfoAboutYouPart2 = async () => {
  return await request.get<ApiResponse<N600InfoAboutYouPart2Payload>>(
    `/user/get-n600-applicant-info-two`
  );
};

export const saveN600InfoAboutYouPart3 = async (
  payload: N600InfoAboutYouPart3Payload
) => {
  return await request.post<ApiResponse>(
    `/user/n600-applicant-info-three`,
    payload
  );
};

export const getN600InfoAboutYouPart3 = async () => {
  return await request.get<ApiResponse<N600InfoAboutYouPart3Payload>>(
    `/user/get-n600-applicant-info-three`
  );
};

export const saveN600InfoAboutYouPart4 = async (
  payload: N600InfoAboutYouPart4Payload
) => {
  return await request.post<ApiResponse>(
    `/user/n600-applicant-info-four`,
    payload
  );
};

export const getN600InfoAboutYouPart4 = async () => {
  return await request.get<ApiResponse<N600InfoAboutYouPart4Payload>>(
    `/user/get-n600-applicant-info-four`
  );
};

export const saveN600BiographicInfo = async (
  payload: N600BiographicInfoPayload
) => {
  return await request.post<ApiResponse>(
    `/user/n600-applicant-bio-info`,
    payload
  );
};

export const getN600BiographicInfo = async () => {
  return await request.get<ApiResponse<N600BiographicInfoPayload>>(
    `/user/get-n600-applicant-bio-info`
  );
};

export const saveN600InfoAboutUSCitizenFatherPart1 = async (
  payload: N600InfoAboutUSCitizenFatherPart1Payload
) => {
  return await request.post<ApiResponse>(
    `/user/n600-applicant-father-one`,
    payload
  );
};

export const getN600InfoAboutUSCitizenFatherPart1 = async () => {
  return await request.get<
    ApiResponse<N600InfoAboutUSCitizenFatherPart1Payload>
  >(`/user/get-n600-applicant-father-one`);
};

export const saveN600InfoAboutUSCitizenFatherPart2 = async (
  payload: N600InfoAboutUSCitizenFatherPart2Payload
) => {
  return await request.post<ApiResponse>(
    `/user/n600-applicant-father-two`,
    payload
  );
};

export const getN600InfoAboutUSCitizenFatherPart2 = async () => {
  return await request.get<
    ApiResponse<N600InfoAboutUSCitizenFatherPart2Payload>
  >(`/user/get-n600-applicant-father-two`);
};

export const saveN600InfoAboutUSCitizenMotherPart1 = async (
  payload: N600InfoAboutUSCitizenMotherPart1Payload
) => {
  return await request.post<ApiResponse>(
    `/user/n600-applicant-mother-one`,
    payload
  );
};

export const getN600InfoAboutUSCitizenMotherPart1 = async () => {
  return await request.get<
    ApiResponse<N600InfoAboutUSCitizenMotherPart1Payload>
  >(`/user/get-n600-applicant-mother-one`);
};

export const saveN600InfoAboutUSCitizenMotherPart2 = async (
  payload: N600InfoAboutUSCitizenMotherPart2Payload
) => {
  return await request.post<ApiResponse>(
    `/user/n600-applicant-mother-two`,
    payload
  );
};

export const getN600InfoAboutUSCitizenMotherPart2 = async () => {
  return await request.get<
    ApiResponse<N600InfoAboutUSCitizenMotherPart2Payload>
  >(`/user/get-n600-applicant-mother-two`);
};

export const saveN600PhysicalPresence = async (
  payload: N600PhysicalPresencePayload
) => {
  return await request.post<ApiResponse>(
    `/user/n600-applicant-physical-presence`,
    payload
  );
};

export const getN600PhysicalPresence = async () => {
  return await request.get<ApiResponse<N600PhysicalPresencePayload>>(
    `/user/get-n600-applicant-physical-presence`
  );
};

export const saveN600MilitaryService = async (
  payload: N600MilitaryServicePayload
) => {
  return await request.post<ApiResponse>(
    `/user/n600-military-service`,
    payload
  );
};

export const getN600MilitaryService = async () => {
  return await request.get<ApiResponse<N600MilitaryServicePayload>>(
    `/user/get-n600-military-service`
  );
};

export const saveN600Preparer = async (payload: PreparerInterpreterObj) => {
  return await request.post<ApiResponse>(
    `/user/n600-preparer-and-interpreter`,
    payload
  );
};

export const getN600Preparer = async () => {
  return await request.get<ApiResponse<PreparerInterpreterObj>>(
    `/user/get-n600-preparer-and-interpreter`
  );
};

export const payN600 = async (payload: payN600Data) => {
  return await request.post<ApiResponse>(`user/n600-payment`, payload);
};

export const getN600PaySuccess = async (payId: string) => {
  return await request.get<ApiResponse>(`/user/n600-payment-success/${payId}`);
};

export const getN600PayFail = async () => {
  return await request.get<ApiResponse<N400InfoObj>>(
    `/user/n600-payment-cancel`
  );
};

export const getN600Info = async () => {
  return await request.get<ApiResponse<N600InfoData>>(`user/get-n600-info`);
};

export const getN600Trigger = async () => {
  return await request.get(`/user/download-n600-application`);
};
