import styled from "styled-components";
import { Form, Formik } from "formik";
import {
  n400CheckPart4Res,
  N400EligibilityPayload,
} from "../../../../../api/n400/types";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { FormWrapper } from "../../../components/styles";
import RadioComp from "../../../components/RadioComp";
import {
  GenericYesNo,
  GenericYesNoOptionsValue,
  initInput,
} from "../../../components/data";
import { getCheckListFieldValidation } from "../data";
import FormBtn from "../../../components/FormBtn";
import {
  useGetN400CheckPart4,
  useSaveN400CheckPart4,
} from "../../../../../hooks/n400/useN400";
import { Modify } from "../../../../../types/types";
import { CheckPart4Validation } from "../validation";
import InputField from "../../../components/formik fields/InputField";
import useToNextSection from "../../../../../hooks/useToNextSection";

export interface CheckFormData4
  extends Modify<
    n400CheckPart4Res,
    {
      do_you_have_hereditary_title_risky_checkbox: boolean;
      are_you_willing_to_give_up_inherited_titles_risky_checkbox: boolean;
      do_you_support_the_constitution_risky_checkbox: boolean;
      do_you_understand_the_oath_of_allegiance_risky_checkbox: boolean;
      are_you_unable_to_take_oath_of_allegiance_risky_checkbox: boolean;
      are_you_willing_to_take_oath_of_allegiance_risky_checkbox: boolean;
      are_you_willing_to_bear_arms_risky_checkbox: boolean;
      are_you_willing_to_perform_noncombatant_services_risky_checkbox: boolean;
      are_you_willing_to_perform_work_of_national_importance_risky_checkbox: boolean;
    }
  > {}

interface Props {
  formEligibility: N400EligibilityPayload;
}

const CheckPart4: React.FC<Props> = ({ formEligibility }) => {
  const { toNextSection } = useToNextSection();
  const { data, isFetching, isError } = useGetN400CheckPart4();
  const { mutate, isLoading: isSaving } = useSaveN400CheckPart4();

  const onSubmit = (values: CheckFormData4) => {
    const {
      do_you_have_hereditary_title_risky_checkbox,
      are_you_willing_to_give_up_inherited_titles_risky_checkbox,
      do_you_support_the_constitution_risky_checkbox,
      do_you_understand_the_oath_of_allegiance_risky_checkbox,
      are_you_unable_to_take_oath_of_allegiance_risky_checkbox,
      are_you_willing_to_take_oath_of_allegiance_risky_checkbox,
      are_you_willing_to_bear_arms_risky_checkbox,
      are_you_willing_to_perform_noncombatant_services_risky_checkbox,
      are_you_willing_to_perform_work_of_national_importance_risky_checkbox,
      ...rest
    } = values;

    const payload = {
      ...rest,
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValue: CheckFormData4 = {
    do_you_have_hereditary_title: initInput(data?.do_you_have_hereditary_title),

    do_you_have_hereditary_title_exp: initInput(
      data?.do_you_have_hereditary_title_exp
    ),

    are_you_willing_to_give_up_inherited_titles: initInput(
      data?.are_you_willing_to_give_up_inherited_titles
    ),

    list_of_titles: initInput(data?.list_of_titles),
    do_you_support_the_constitution: initInput(
      data?.do_you_support_the_constitution
    ),

    do_you_understand_the_oath_of_allegiance: initInput(
      data?.do_you_understand_the_oath_of_allegiance
    ),

    are_you_unable_to_take_oath_of_allegiance: initInput(
      data?.are_you_unable_to_take_oath_of_allegiance
    ),

    are_you_willing_to_take_oath_of_allegiance: initInput(
      data?.are_you_willing_to_take_oath_of_allegiance
    ),

    are_you_willing_to_bear_arms: initInput(data?.are_you_willing_to_bear_arms),

    are_you_willing_to_perform_noncombatant_services: initInput(
      data?.are_you_willing_to_perform_noncombatant_services
    ),

    are_you_willing_to_perform_work_of_national_importance: initInput(
      data?.are_you_willing_to_perform_work_of_national_importance
    ),

    do_you_have_hereditary_title_risky_checkbox: false,
    are_you_willing_to_give_up_inherited_titles_risky_checkbox: false,
    // list_of_titles_risky_checkbox: false,
    do_you_support_the_constitution_risky_checkbox: false,
    do_you_understand_the_oath_of_allegiance_risky_checkbox: false,
    are_you_unable_to_take_oath_of_allegiance_risky_checkbox: false,
    are_you_willing_to_take_oath_of_allegiance_risky_checkbox: false,
    are_you_willing_to_bear_arms_risky_checkbox: false,
    are_you_willing_to_perform_noncombatant_services_risky_checkbox: false,
    are_you_willing_to_perform_work_of_national_importance_risky_checkbox:
      false,
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValue}
          onSubmit={onSubmit}
          validationSchema={CheckPart4Validation}
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <RadioComp
                  name="do_you_have_hereditary_title"
                  title="30.a. Do you now have, or did you EVER have, a hereditary title or an order of nobility in any foreign country?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "do_you_have_hereditary_title"
                  )}
                />
              </FormWrapper>

              {formik.values.do_you_have_hereditary_title ===
                GenericYesNoOptionsValue.yes && (
                <>
                  <FormWrapper>
                    <InputField
                      name="do_you_have_hereditary_title_exp"
                      label="Explanation"
                      inputType="text-area"
                      placeholder=""
                    />

                    <div></div>
                  </FormWrapper>

                  <FormWrapper>
                    <RadioComp
                      name="are_you_willing_to_give_up_inherited_titles"
                      title={`30.b. Are you willing to give up any inherited titles or orders`}
                      options={GenericYesNo}
                      toolTipProps={getCheckListFieldValidation(
                        formik,
                        "are_you_willing_to_give_up_inherited_titles",
                        true
                      )}
                    />
                  </FormWrapper>
                </>
              )}

              <FormWrapper>
                <RadioComp
                  name="do_you_support_the_constitution"
                  title="31. Do you support the Constitution and form of Government of the United States?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "do_you_support_the_constitution",
                    true
                  )}
                />
              </FormWrapper>

              <FormWrapper>
                <RadioComp
                  name="do_you_understand_the_oath_of_allegiance"
                  title="32. Do you understand the full Oath of Allegiance to the United States?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "do_you_understand_the_oath_of_allegiance",
                    true
                  )}
                />
              </FormWrapper>

              <FormWrapper>
                <RadioComp
                  name="are_you_unable_to_take_oath_of_allegiance"
                  title="33. Are you unable to take the Oath of Allegiance because of a physical or developmental disability or mental impairment?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "are_you_unable_to_take_oath_of_allegiance"
                  )}
                />
              </FormWrapper>

              {formik.values.are_you_unable_to_take_oath_of_allegiance ===
                GenericYesNoOptionsValue.no && (
                <>
                  <FormWrapper>
                    <RadioComp
                      name="are_you_willing_to_take_oath_of_allegiance"
                      title="34. Are you willing to take the full Oath of Allegiance to the United States?"
                      options={GenericYesNo}
                      toolTipProps={getCheckListFieldValidation(
                        formik,
                        "are_you_willing_to_take_oath_of_allegiance",
                        true
                      )}
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <RadioComp
                      name="are_you_willing_to_bear_arms"
                      title="35. If the law requires it, are you willing to bear arms (carry weapons) on behalf of the United States?"
                      options={GenericYesNo}
                      toolTipProps={getCheckListFieldValidation(
                        formik,
                        "are_you_willing_to_bear_arms",
                        true
                      )}
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <RadioComp
                      name="are_you_willing_to_perform_noncombatant_services"
                      title="36. If the law requires it, are you willing to perform noncombatant services (do something that does not include fighting in a war) in the U.S. armed forces?"
                      options={GenericYesNo}
                      toolTipProps={getCheckListFieldValidation(
                        formik,
                        "are_you_willing_to_perform_noncombatant_services",
                        true
                      )}
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <RadioComp
                      name="are_you_willing_to_perform_work_of_national_importance"
                      title="37. If the law requires it, are you willing to perform work of national importance under civilian direction (do non-military work that the U.S. Government says is important to the country)?"
                      options={GenericYesNo}
                      toolTipProps={getCheckListFieldValidation(
                        formik,
                        "are_you_willing_to_perform_work_of_national_importance",
                        true
                      )}
                    />
                  </FormWrapper>
                </>
              )}

              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default CheckPart4;

const Wrapper = styled.div``;
