import { SettingsTitle, SettingsWrapper } from "../../styles";
import { Form, Formik } from "formik";
import InputField from "../../../../components/formik fields/InputField";
import styled from "styled-components";
import Colors from "../../../../../../styles/Colors";
import { ProfilePasswordValidation } from "../../validation";
import { useChangePassword } from "../../../../../../hooks/auth/useAuth";
import { useNavigate } from "react-router-dom";
import { WebPaths } from "../../../../../../routes/data";
import Spinner from "../../../../../../utils/Spinner";
import { loginFailed } from "../../../../../../utils";
import { useState } from "react";
import PasswordCode from "./PasswordCode";
import { toast } from "react-toastify";
import { useBusinessChangePassword } from "../../../../../../hooks/business/useAuth";
import { SignUpType } from "../../../../auth/components/SignUp";

interface FormData {
  newPassword: string;
  confirmNewPassword: string;
  code: string;
}

const Password = () => {
  const navigate = useNavigate();
  const { mutate, isLoading } = useChangePassword();
  const { mutate: businessMutate, isLoading: businessLoading } =
    useBusinessChangePassword();

  const [getCode, setGetCode] = useState<boolean>(false);

  const isBusinessType = true; //get from user date

  const onSubmit = (values: FormData) => {
    const payload = {
      newPassword: values.confirmNewPassword,
      code: values.code,
    };

    const onSuccess = () => {
      loginFailed();
      navigate(
        `${WebPaths.Auth}${
          isBusinessType ? `?type=${SignUpType.business}` : ""
        }`
      );
      toast.success("Password changes, Login with new password");
    };

    isBusinessType
      ? businessMutate(payload, {
          onSuccess: onSuccess,
        })
      : mutate(payload, {
          onSuccess: onSuccess,
        });
  };

  const onGetCode = () => setGetCode(true);
  const masterLoading = isLoading || businessLoading;

  return (
    <SettingsWrapper>
      <SettingsTitle>Password</SettingsTitle>
      <Formik
        initialValues={
          {
            newPassword: "",
            confirmNewPassword: "",
            code: "",
          } as FormData
        }
        onSubmit={onSubmit}
        validationSchema={ProfilePasswordValidation}
      >
        <Former>
          <InputField
            name="newPassword"
            label="New Password"
            placeholder=""
            inputType="password"
          />
          <InputField
            name="confirmNewPassword"
            label="Confirm Password"
            placeholder=""
            inputType="password"
          />
          <FormWrapper>
            <UpdateBtn type="button" onClick={onGetCode} disabled={getCode}>
              {false && <Spinner color={Colors.White} size={14} />}
              Get code
            </UpdateBtn>
          </FormWrapper>
          {getCode && <PasswordCode />}
          {getCode && (
            <FormWrapper>
              <UpdateBtn type="submit" disabled={masterLoading}>
                {masterLoading && <Spinner color={Colors.White} size={14} />}
                Change password
              </UpdateBtn>
            </FormWrapper>
          )}
        </Former>
      </Formik>
    </SettingsWrapper>
  );
};

export default Password;

const FormWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Former = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Btn = styled.button`
  padding: 10px 29px;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 8px;
  display: flex;
  gap: 8px;
  align-items: center;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const UpdateBtn = styled(Btn)`
  background-color: ${Colors.Blue00};
  color: ${Colors.White};
`;
