import useIsPaid from "./useIsPaid";
import useIsCompleted from "./useIsCompleted";
import { useParams } from "react-router-dom";
import { FormCategories } from "../routes/type";

const useBasedOnFormPaid = () => {
  const { getIsCompleted } = useIsCompleted();
  const { formCategory: urlFormCategory } = useParams();
  const { getIsPaid } = useIsPaid();
  const isFormPaid = getIsPaid();
  const isFormCompleted = getIsCompleted();

  const getBasedOnFormPaid = (params: string) => {
    const paidTemp = getIsPaid(params);
    const completedTemp = getIsCompleted(params);
    return params === FormCategories.ds160 ? completedTemp : paidTemp;
  };

  return {
    getBasedOnFormPaid,
    basedOnFormPaid:
      urlFormCategory === FormCategories.ds160 ? isFormCompleted : isFormPaid,
  };
};

export default useBasedOnFormPaid;
