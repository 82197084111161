import { ApiResponse } from "../../../types/types";
import {
  changePasswordPayload,
  forgotPasswordPayload,
  signinPayload,
  SignInRes,
} from "../../auth/types";
import request from "../../request";
import {
  BusinessEditProfile,
  BusinessSignInRes,
  BusinessSignupPayload,
} from "./types";

export const businessChangePassword = async (
  payload: changePasswordPayload
) => {
  return await request.post<ApiResponse>(`/business/change-password`, payload);
};

export const businessSignup = async (payload: BusinessSignupPayload) => {
  return await request.post<ApiResponse>(`/business/signup`, payload);
};

export const businessSignin = async (payload: signinPayload) => {
  return await request.post<BusinessSignInRes>(`/business/signin`, payload);
};

export const businessForgotPassword = async (
  payload: forgotPasswordPayload
) => {
  return await request.post<ApiResponse>(`/business/forgot-password`, payload);
};

export const businessGetChangePasswordCode = async () => {
  return await request.get<ApiResponse>(`/business/get-change-password-code`);
};

export const getBusinessProfile = async () => {
  return await request.get<BusinessSignInRes>(`/business/get-profile`);
};

export const businessEditProfile = async (payload: BusinessEditProfile) => {
  return await request.post<SignInRes>(`/business/edit-profile`, payload);
};

export const deleteBusinessAccount = async () => {
  return await request.delete<ApiResponse<string>>(`business/delete-account`);
};
