import React, { FC } from "react";
import { ErrorText, SpinnerWrapper } from "./styles";
import Spinner from "../../../utils/Spinner";

interface Props {
  isLoading: boolean;
  isError: boolean;
  children: JSX.Element;
}

const FormSectionWrapper: FC<Props> = ({ isLoading, isError, children }) => {
  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner size={35} />
      </SpinnerWrapper>
    );

  if (isError)
    return (
      <SpinnerWrapper>
        <ErrorText>An error occurred. Please try again later.</ErrorText>
      </SpinnerWrapper>
    );

  return children;
};

export default FormSectionWrapper;
