import React, { FC } from "react";
import styled from "styled-components";
import InputField from "../../components/formik fields/InputField";
import CheckBoxField from "../../components/formik fields/CheckBoxField";
import SelectField from "../../components/formik fields/SelectField";
import { FormData } from "..";
import { ApartmentSuiteFloorData } from "../../components/data";
import { SingleOption } from "../../../../types/types";
import { FormikProps } from "formik";

interface Props {
  formik: FormikProps<FormData>;
  interpreterCountryData: SingleOption[];
  interpreterCountryLoading: boolean;
  interpreterStateData: SingleOption[];
  interpreterStateLoading: boolean;
  interpreterSetCountry: React.Dispatch<React.SetStateAction<string>>;
  interpreterSelectedCountry: string;
}

const InterpreterInfoSection: FC<Props> = ({
  formik,
  interpreterCountryData,
  interpreterCountryLoading,
  interpreterStateData,
  interpreterStateLoading,
  interpreterSetCountry,
  interpreterSelectedCountry,
}) => {
  return (
    <>
      <FormWrapper>
        <Flexxer>
          <InputField
            label="Which language was used to translate into English?"
            name="language"
            placeholder=""
          />
          <div></div>
        </Flexxer>
        <Flexxer>
          <InputField
            label="Interpreter’s Given Name"
            coloredLabel="(First Name)"
            placeholder=""
            name="interpreterFirstName"
          />
          <InputField
            label="Interpreter’s Family Name"
            coloredLabel="(Last Name)"
            placeholder=""
            name="interpreterLastName"
          />
        </Flexxer>
        <Flexxer>
          <InputField
            label="Interpreter’s Firm or Organization Name "
            placeholder=""
            name="interpreterFirm"
          />
          <InputField
            label="Interpreter’s Address"
            placeholder=""
            name="interpreterAddress"
          />
        </Flexxer>
        <Flexxer>
          <MiniFlexxer>
            <InputField
              label="Street Number and Name "
              placeholder=""
              name="interpreterStreetNumAndName"
            />
          </MiniFlexxer>
          <InputField label="Email" placeholder="" name="interpreterEmail" />
        </Flexxer>

        <Flexxer>
          <SelectField
            label="Is there an apartment, suite or floor?"
            name="interpreterApartmentSuiteOrFloor"
            placeholder="-Select-"
            options={ApartmentSuiteFloorData}
          />
          <div>
            {formik.values.interpreterApartmentSuiteOrFloor?.value && (
              <InputField
                label={`${formik.values.interpreterApartmentSuiteOrFloor.value} Address number`}
                placeholder=""
                name={`interpreterAddressNum`}
              />
            )}
          </div>
        </Flexxer>
        <Flexxer>
          <SelectField
            label="Country"
            placeholder=""
            name="interpreterCountry"
            options={interpreterCountryData}
            isLoading={interpreterCountryLoading}
            onChange={(value) => {
              interpreterSetCountry(value.label);
              formik.setFieldValue(`interpreterStateOrTerritory`, null);
            }}
          />

          <SelectField
            label="State"
            placeholder=""
            name="interpreterStateOrTerritory"
            options={interpreterStateData}
            isLoading={interpreterStateLoading}
            isDisabled={!interpreterSelectedCountry}
          />
        </Flexxer>
        <Flexxer>
          <InputField
            label="Daytime Phone Number"
            placeholder=""
            name="interpreterDayTimePhoneNumber"
          />
          <InputField
            label="Mobile Phone Number"
            coloredLabel="(if any)"
            placeholder=""
            name="interpreterMobilePhoneNumber"
          />
        </Flexxer>
        <Flexxer>
          <InputField
            label="City or Town"
            placeholder=""
            name="interpreterCityOrTown"
          />

          <InputField
            label="ZIP Code"
            placeholder=""
            name="interpreterZipCode"
          />
        </Flexxer>
        <CheckWrapper>
          <CheckBoxField
            label="I acknowledge that each and every question and instruction on this form, as well as my answer to each question, has been read to me by the person named above in a language in which I am fluent. I have provided true and correct responses in the language indicated above."
            name="interpreterAcknowledgement"
          />
        </CheckWrapper>
      </FormWrapper>
    </>
  );
};

export default InterpreterInfoSection;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Flexxer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 70px;
  & > div {
    flex: 1;
  }
`;

const MiniFlexxer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 40px;
`;

const CheckWrapper = styled.div`
  margin-top: 11px;
`;
