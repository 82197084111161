import { BiSolidUpArrow, BiSolidDownArrow } from "react-icons/bi";
import { FC } from "react";
import styled from "styled-components";

interface Props {
  active: boolean;
  toggle: () => void;
}

const ActivateIcon: FC<Props> = ({ active, toggle }) => {
  return (
    <Wrapper>
      {active ? (
        <BiSolidUpArrow onClick={toggle} />
      ) : (
        <BiSolidDownArrow onClick={toggle} />
      )}
    </Wrapper>
  );
};

export default ActivateIcon;

const Wrapper = styled.div`
  cursor: pointer;
`;
