import { Field, useField } from "formik";
import React, { ChangeEvent, useState } from "react";
import styled from "styled-components";
import { mediaObj } from "../../../../styles/Media";
import Colors from "../../../../styles/Colors";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import Tooltip, { TooltipProps } from "../Tooltip";

interface Props {
  label: string;
  coloredLabel?: string;
  placeholder?: string;
  inputType?: "text" | "password" | "text-area";
  name: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  onChange?: (value: string, name: string) => void;
  numberInput?: "number";
  min?: number;
  height?: string;
  borderRadius?: string;
  validate?: (value: string) => string | undefined;
  toolTipProps?: TooltipProps;
}

const InputField: React.FC<Props> = ({
  label,
  coloredLabel,
  inputType = "text",
  placeholder,
  isRequired,
  isDisabled,
  onChange,
  numberInput,
  min,
  height,
  borderRadius,
  toolTipProps,
  ...props
}) => {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [field, meta, helpers] = useField(props);
  const errorClass = `${meta.touched && meta.error && "error"} ${
    isRequired ? "required" : ""
  }`;

  return (
    <InputContainer height={height} borderRadius={borderRadius}>
      {label && (
        <Label htmlFor={props.name} className={errorClass}>
          <div>
            {label} <Colored>{coloredLabel && coloredLabel}</Colored>
          </div>
          {toolTipProps && (
            <Tooltip toolTipProps={toolTipProps} parentName={props.name} />
          )}
        </Label>
      )}

      {inputType === "text" && (
        <Field
          {...field}
          {...props}
          type={numberInput || "text"}
          id={props.name}
          className={errorClass}
          placeholder={placeholder}
          disabled={isDisabled}
          min={min}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            const name = e.target.name;
            onChange && onChange(value, name);
            helpers.setValue(value);
          }}
        />
      )}

      {inputType === "text-area" && (
        <textarea
          {...field}
          {...props}
          id={props.name}
          className={errorClass}
          placeholder={placeholder}
        />
      )}

      {inputType === "password" && (
        <IconInput className={errorClass}>
          <Field
            type={show ? "text" : "password"}
            id={props.name}
            {...field}
            {...props}
            placeholder={placeholder}
          />
          {show ? (
            <FaEye onClick={handleClick} className="clickable" />
          ) : (
            <FaEyeSlash onClick={handleClick} className="clickable" />
          )}
        </IconInput>
      )}

      {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
    </InputContainer>
  );
};

export default InputField;

interface InputContainerProps {
  height?: string;
  borderRadius?: string;
}

const InputContainer = styled.div<InputContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > input {
    background: #ffffff;
    border: 1px solid ${Colors.GreyA7};
    border-radius: ${({ borderRadius }) =>
      borderRadius ? borderRadius : "4px"};
    height: ${({ height }) => (height ? height : "40px")};
    padding: 1rem;

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      transition: background-color 0s 600000s, color 0s 600000s;
    }

    &:focus-visible {
      outline: none;
    }

    &:disabled {
      background: rgba(27, 9, 44, 0.05);

      &:-webkit-autofill,
      &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 50px rgba(27, 9, 44, 0.05) inset;
      }
    }

    &.error {
      border: 1px solid red;
    }
  }

  & > textarea {
    border: 1px solid ${Colors.GreyA7};
    border-radius: ${({ borderRadius }) =>
      borderRadius ? borderRadius : "8px"};
    padding: 16px 17px;
    height: ${({ height }) => (height ? height : "110px")};
    resize: none;

    &:focus-visible {
      outline: none;
    }

    &.error {
      border: 1px solid red;
    }
  }
`;

export const Label = styled.label`
  margin-bottom: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: ${Colors.Black31};
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &.required:after {
    content: "*";
    font-size: 24px;
    line-height: 0;
    vertical-align: middle;
    color: #d90b2c;
  }

  ${mediaObj.mobile} {
    font-size: 14px;
  }
`;

const Error = styled.div`
  color: red;
  font-size: 0.875rem;
`;

const Colored = styled.span`
  color: ${Colors.RedDA};
`;

interface IconInputProps {
  height?: string;
  borderRadius?: string;
}

const IconInput = styled.label<IconInputProps>`
  width: 100%;
  background: #ffffff;
  border: 1px solid ${Colors.GreyA7};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "4px")};
  height: ${({ height }) => (height ? height : "40px")};
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  &.error {
    border: 1px solid red;
  }

  input {
    width: 100%;
    border: none;
    outline: none;

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      transition: background-color 0s 600000s, color 0s 600000s;
    }
  }

  .clickable {
    cursor: pointer;
  }
`;
