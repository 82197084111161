import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import { useSearchParams } from "react-router-dom";
import { leftSideBarDataProps } from "./type";
import { getEnumIndex } from "./data";
import { userDataStore } from "../../../store/userData";
import { useSnapshot } from "valtio";
import { IoChevronForward, IoClose } from "react-icons/io5";
import useBasedOnFormPaid from "../../../hooks/useBasedOnFormPaid";
import { mediaObj } from "../../../styles/Media";

interface Props {
  title: string;
  data: leftSideBarDataProps[];
  storeProgress?: number;
  progressEnum: any;
  isDefaultHidden?: boolean;
}

const LeftSideBar: FC<Props> = ({
  title,
  data,
  storeProgress,
  progressEnum,
  isDefaultHidden,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const getType = searchParams.get("type");
  const { basedOnFormPaid } = useBasedOnFormPaid();
  const { userData } = useSnapshot(userDataStore);
  const isFormCompleted = userData?.currentFormInfo.isFormCompleted;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(false);
  }, [searchParams]);

  useEffect(() => {
    if (!getType) {
      const firstUrl =
        data[0].formProgress ||
        (data[0].children && data[0].children[0]?.formProgress) ||
        "";
      searchParams.set("type", firstUrl);
      setSearchParams(searchParams);
    }
  }, [searchParams, getType, data, setSearchParams]);

  return (
    <Container isOpen={isOpen} isDefaultHidden={isDefaultHidden}>
      {/* <div className="sticky top-0 left-0"> */}
      <Toggle isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <IoClose /> : <IoChevronForward />}
      </Toggle>
      <Top>{title}</Top>
      <Bottom>
        {data.map((ev, i) => {
          const parentActive =
            ev.formProgress === getType ||
            (ev.children
              ? ev.children.some((evc) => evc.formProgress === getType)
              : false);

          const getIsClickable = () => {
            if (isFormCompleted) return true;
            if (basedOnFormPaid) return true;

            if (ev.children) {
              if (!storeProgress) return false;
              return ev.children.some(
                (x) =>
                  getEnumIndex(progressEnum, x.formProgress) <= storeProgress
              );
            } else {
              if (!ev.formProgress || !storeProgress) return false;

              return (
                getEnumIndex(progressEnum, ev.formProgress) <= storeProgress
              );
            }
          };

          const isClickable = getIsClickable();

          return (
            <React.Fragment key={i}>
              <Nav
                key={i}
                $isClickable={isClickable}
                $isActive={parentActive}
                //   onMouseEnter={() => ev.children && setNo(i)}
                // isActive={i === no || getType === ev.url?.split("=")[1]}
                onClick={() => {
                  // setNo(i);
                  if (isClickable) {
                    if (ev.children) {
                      searchParams.set("type", ev.children[0].formProgress);
                      setSearchParams(searchParams);
                    } else {
                      if (!ev.formProgress) return;
                      searchParams.set("type", ev.formProgress);
                      setSearchParams(searchParams);
                    }
                  }
                }}
              >
                <Circle isActive={parentActive} isClickable={isClickable}>
                  {i + 1}
                </Circle>
                {ev.label}
              </Nav>
              <Main>
                {parentActive && ev.children && (
                  <SubSection>
                    {ev.children?.map((ev, i) => {
                      const isChildActive = getType === ev.formProgress;

                      const isChildClickable =
                        ev.formProgress &&
                        storeProgress &&
                        getEnumIndex(progressEnum, ev.formProgress) <=
                          storeProgress;

                      return (
                        <ChildrenBox
                          key={i}
                          isClickable={!!isChildClickable}
                          onClick={() => {
                            if (isChildClickable) {
                              searchParams.set("type", ev.formProgress);
                              setSearchParams(searchParams);
                            }
                          }}
                          isActive={isChildActive}
                        >
                          <Dot />
                          <ChildrenBoxName isActive={isChildActive}>
                            {ev.name}
                          </ChildrenBoxName>
                        </ChildrenBox>
                      );
                    })}
                  </SubSection>
                )}
              </Main>
            </React.Fragment>
          );
        })}
      </Bottom>
      {/* </div> */}
    </Container>
  );
};

export default LeftSideBar;
interface ContainerProps {
  isOpen: boolean;
  isDefaultHidden?: boolean;
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  /* background-color: #f9fcff; */
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  /* border: 1px solid grey; */

  ${({ isDefaultHidden, isOpen }) =>
    isDefaultHidden
      ? `
      @media screen and (min-width: 0px) {
          position: fixed;
          left: 0;
          width: max-content;
          height: calc(100vh - 100px);
          padding-left: 24px;
          transition: 300ms all ease;
          transform: 
            ${isOpen ? "translateX(-20px)" : "translateX(-100%)"}};
          z-index: 1000;

            ${mediaObj.bigMobile} {
                width: 85vw;
             }
    
        }
      `
      : `

           @media screen and (max-width: 767px) {
          position: fixed;
          left: 0;
          width: max-content;
          height: calc(100vh - 100px);
          padding-left: 24px;
          transition: 300ms all ease;
          transform: 
            ${isOpen ? "translateX(-20px)" : "translateX(-100%)"}};
          z-index: 1000;

            ${mediaObj.bigMobile} {
                width: 85vw;
             }
    
        }
             
        // @media screen and (max-width: 767px) {
        //   position: absolute;
        //   left: 0;
        //   width: 80%;
        //   min-height: 100vh;
        //   padding-left: 24px;
        //   transition: 300ms all ease;
        //   transform: 
        //    ${isOpen ? "translateX(-20px)" : "translateX(-100%)"}};
        //   z-index: 999;
        // }
      `}
`;

const Toggle = styled.button<{ isOpen: boolean }>`
  width: 64px;
  border-radius: 0 8px 8px 0;

  padding: 0.6rem;

  background-color: ${Colors.Blue00};

  display: grid;
  place-content: center;

  position: fixed !important;
  left: 100%;
  top: 24px;

  opacity: ${({ isOpen }) => (isOpen ? "1" : "0.5")};

  transition: 300ms all ease;

  &:hover {
    opacity: 1;
  }

  & > svg {
    height: 32px;
    width: 32px;

    transition: 300ms all ease;

    color: white;
  }
`;

const Top = styled.div`
  padding: 29px 11px;
  background-color: #0076e9;
  margin-bottom: 43px;
  border-radius: 12px 12px 0 0;
  color: ${Colors.White};
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 38px;
  padding: 0 11px;
  overflow-y: auto;
  height: 100%;
`;

const Nav = styled.div<{
  $isClickable: boolean;
  $isActive: boolean;
}>`
  display: flex;
  align-items: flex-start;
  gap: 14px;
  cursor: ${({ $isClickable }) => ($isClickable ? "pointer" : "default")};
  color: ${({ $isClickable, $isActive }) =>
    $isActive ? Colors.Blue00 : $isClickable ? Colors.Black31 : Colors.GreyA7};
`;

interface CircleProps {
  isActive: boolean;
  isClickable: boolean;
}

const Circle = styled.div<CircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 28px;
  height: 28px;
  color: white;
  background-color: ${({ isActive, isClickable }) =>
    isActive || isClickable ? Colors.Blue00 : Colors.GreyA7};
  flex-shrink: 0;

  &:hover {
    transition: 0.2s ease-out;
  }
`;

const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: ${Colors.Black31};
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-left: 38px;
  margin-top: -22px;
`;

interface ChildrenBoxProps {
  isActive: boolean;
  isClickable?: boolean;
}

const ChildrenBox = styled.div<ChildrenBoxProps>`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  color: ${({ isActive }) => (isActive ? Colors.Blue00 : "#6b6d70")};
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "default")};
`;

const ChildrenBoxName = styled.span<ChildrenBoxProps>`
  &:hover {
    color: ${({ isActive }) => (isActive ? Colors.Blue00 : "#000000")};
    transition: 0.2s ease-out;
  }
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  background: ${Colors.Black31};
  border-radius: 100%;
`;
