import React from "react";
import styled from "styled-components";
import { leftSideBarData } from "./data";
import Colors from "../../../../styles/Colors";
import { useNavigate, useSearchParams } from "react-router-dom";
import { userDataStore } from "../../../../store/userData";
import { useSnapshot } from "valtio";
import { isBusinessDataStore } from "../../../../store/isBusinessData";
import { businessUserDataStore } from "../../../../store/businessUserData";

const LeftSideBar = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const getType = searchParams.get("active");
  const { isBusinessData } = useSnapshot(isBusinessDataStore);
  const { businessUserData } = useSnapshot(businessUserDataStore);
  const { userData } = useSnapshot(userDataStore);

  return (
    <Container>
      <Top>
        Hello,{" "}
        {isBusinessData
          ? businessUserData?.businessInfo.name
          : userData?.userInfo?.name.split(" ")[0]}
        .
      </Top>
      <Bottom>
        {leftSideBarData(isBusinessData).map((ev, i) => {
          const isActive = getType === ev.url.split("=")[1];

          return (
            <React.Fragment key={i}>
              <Nav
                key={i}
                onClick={() => {
                  ev.url && navigate(ev.url);
                }}
                isActive={isActive}
              >
                <Circle isActive={isActive}>{ev.icon}</Circle>
                <Label isActive={isActive}>{ev.label}</Label>
              </Nav>
            </React.Fragment>
          );
        })}
      </Bottom>
    </Container>
  );
};

export default LeftSideBar;

const Container = styled.div`
  width: 100%;
  background-color: #f9fcff;
`;

const Top = styled.div`
  padding: 29px 11px;
  background-color: #0076e9;
  margin-bottom: 24px;
  border-radius: 12px 12px 0 0;
  color: ${Colors.White};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  text-transform: capitalize;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding-bottom: 2rem;
`;

interface NavProps {
  isActive: boolean;
}

const Nav = styled.div<NavProps>`
  display: flex;
  align-items: center;
  gap: 14px;
  cursor: pointer;
  background-color: ${({ isActive }) =>
    isActive ? "#0076E914" : "transparent"};
  border-left: ${({ isActive }) =>
    isActive ? `4px solid ${Colors.Blue00} ` : ""};
  padding: 11px 17px;
`;

interface CircleProps {
  isActive: boolean;
}

const Circle = styled.div<CircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 20%;
  width: 20px;
  height: 20px;
  color: ${({ isActive }) => (isActive ? Colors.Blue00 : Colors.GreyA7)};

  &:hover {
    transition: 0.2s ease-out;
  }
`;

interface LabelProps {
  isActive: boolean;
}

const Label = styled.div<LabelProps>`
  color: ${({ isActive }) => (isActive ? Colors.Blue00 : Colors.GreyA7)};
`;
