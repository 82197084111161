import {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
} from "react";

// Define the shape of your context data
interface AuthData {
  accessToken: string | null;
  refreshToken: string | null;
  signInTime: string | null;
  storeToken: (identifier: string, tokenData: string, duration: number) => void; // TODO: function to store token in browser cookies
  getCookie: (name: string) => string | null; // TODO: function to get token from browser cookies
  logOut: () => void; // TODO: function to log out
}

// Create the context itself
export const AuthContext = createContext<AuthData | undefined>(undefined);

// Create a custom hook for using the context
export function useAuthContext() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useMyContext must be used within a MyContextProvider");
  }
  return context;
}

// Create a provider component to wrap your app with
export function AuthProvider({ children }: { children: ReactNode }) {
  // Define your context state and methods here
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const [signInTime, setSignInTime] = useState<string | null>(null);

  // ! THE STORE TOKEN FUNCTION
  const storeToken = (
    identifier: string,
    tokenData: string,
    duration: number
  ) => {
    const expiration = new Date();
    expiration.setTime(expiration.getTime() + duration * 60 * 60 * 1000);
    document.cookie = `${identifier}=${tokenData};expires=${expiration.toUTCString()};path=/`;
  };

  // ! THE GET COOKIE FUNCTION
  const getCookie = (name: string): string | null => {
    const cookies = document.cookie.split(";");
    for (const cookie of cookies) {
      const [cookieName, cookieData] = cookie.trim().split("=");
      if (cookieName === name) {
        return decodeURIComponent(cookieData);
      }
    }
    return null;
  };

  const logOut = () => {
    document.cookie = `accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    document.cookie = `refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    document.cookie = `signInTime=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

    setAccessToken(null);
    setRefreshToken(null);
    setSignInTime(null);

    // navigate(WebPaths.Auth);
  };

  // Provide the context value to the children
  const contextValue: AuthData = {
    // !: THE VARIABLES
    accessToken,
    refreshToken,
    signInTime,

    // !: THE FUNCTIONS
    storeToken,
    getCookie,
    logOut,
  };

  useEffect(() => {
    getCookie("accessToken");
    getCookie("refreshToken");
    getCookie("signInTime");
  }, []);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
}
