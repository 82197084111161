import React, { FC } from "react";
import { saveDS160PassportObj } from "../../../../../../api/ds160/types";
import ReviewBox from "../../../../components/Review/ReviewBox";
import { FormDS160Progress } from "../../types";
import { GenericDs160ReviewProps } from "./DownloadPage";
import PdfReviewBox from "../../../../components/Review/Pdf Review/PdfReviewBox";
import { ReviewPassportSection } from "./data";

interface Props extends GenericDs160ReviewProps {
  data?: saveDS160PassportObj;
}

const ReviewPassport: FC<Props> = ({ title, data, isDownload }) => {
  const section = data ? ReviewPassportSection(data) : [];

  const compProps = {
    title: title,
    data: section,
    editUrl: FormDS160Progress.passport,
  };

  return isDownload ? (
    <PdfReviewBox {...compProps} />
  ) : (
    <ReviewBox {...compProps} />
  );
};

export default ReviewPassport;
