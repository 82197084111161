import React, { FC } from "react";
import styled from "styled-components";
import ReviewGeneric from "./ReviewGeneric";
import ActivateIcon from "./ActivateIcon";
import useActivate from "../../../../../components/useActivate";
import Colors from "../../../../../../../styles/Colors";

export interface ReviewSectionChildrenData extends ReviewData {}

export interface ReviewSectionData {
  title: string;
  children: ReviewSectionChildrenData[];
}

export interface ReviewData {
  label?: string;
  value?: string;
  section?: ReviewSectionData;
}

interface Props {
  title: string;
  data?: ReviewData[];
  isActive?: boolean;
  extraComp?: JSX.Element;
}

const ReviewBox: FC<Props> = ({ title, data, isActive, extraComp }) => {
  const { active, toggle, contentEl } = useActivate(isActive);

  return (
    <Wrapper>
      <Header>
        <Title>{title}</Title>
        <MiniFlex>
          <div></div>
          <ActivateIcon active={active} toggle={toggle} />
        </MiniFlex>
      </Header>
      <MiniWrapper ref={contentEl} contentEl={contentEl} isActive={active}>
        {extraComp
          ? extraComp
          : data &&
            data.map((ev) => {
              return <ReviewGeneric value={ev.value} label={ev.label} />;
            })}
      </MiniWrapper>
    </Wrapper>
  );
};

export default ReviewBox;

const Wrapper = styled.div`
  margin-top: 33px;
`;

interface MiniWrapperProps {
  isActive: boolean;
  contentEl: React.RefObject<HTMLDivElement>;
}

const MiniWrapper = styled.div<MiniWrapperProps>`
  height: ${({ isActive, contentEl }) =>
    !isActive ? "0" : `${contentEl.current?.scrollHeight}px`};
  overflow: hidden;
  transition: height ease 0.3s;
`;

const Header = styled.div`
  padding: 13px 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${Colors.BlueEA};
  font-size: 1rem;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;

  & > svg {
    cursor: pointer;
  }
`;

const Title = styled.div`
  color: ${Colors.Blue00};
`;

const Btn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 6px 12px;
  border-radius: 8px;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

export const EditBtn = styled(Btn)`
  border: 1px solid ${Colors.Blue00};
  color: ${Colors.Blue00};
`;

const MiniFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;
