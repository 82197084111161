import React from "react";
import styled from "styled-components";
import Colors from "../../../../styles/Colors";
import { Form, Formik } from "formik";
import InputField from "../../components/formik fields/InputField";
import {
  useGetN565InfoFromCert,
  useSaveN565InfoFromCert,
} from "../../../../hooks/n565/useN565";
import { InfoFromDocValidation } from "./validation";
import FormBtn from "../../components/FormBtn";
import FormSectionWrapper from "../../components/FormSectionWrapper";
import useToNextSection from "../../../../hooks/useToNextSection";
import { FormWrapper } from "../../components/styles";
import DatePickerField from "../../components/formik fields/DatePickerField";
import SelectField from "../../components/formik fields/SelectField";
import {
  ToSaveDate,
  USDateFormat,
  USDatePlaceholderFormat,
  initDate,
  initInput,
  initSelect,
} from "../../components/data";
import useCountries from "../../components/useCountries";
import { n565DocInfoObj } from "../../../../api/n565/types";
import { Modify, SingleOption } from "../../../../types/types";
import { Dayjs } from "dayjs";

export interface N565DocInfoFormData
  extends Modify<
    n565DocInfoObj,
    {
      certificateDateOfBirth: Dayjs | null;
      certificateCountryOfBirth: SingleOption | null;
      certificateDateOfIssuance: Dayjs | null;
    }
  > {}

const InfoFromDoc = () => {
  const { data, isFetching, isError } = useGetN565InfoFromCert();
  const { mutate, isLoading: isSaving } = useSaveN565InfoFromCert();
  const { toNextSection } = useToNextSection();
  const { formattedCountryData, countryLoading } = useCountries();

  const onSubmit = (values: N565DocInfoFormData) => {
    const {
      certificateDateOfIssuance,
      certificateCountryOfBirth,
      certificateDateOfBirth,
      ...rest
    } = values;
    const payload = {
      ...rest,
      certificateDateOfIssuance: ToSaveDate(
        certificateDateOfIssuance,
        USDateFormat
      ),
      certificateCountryOfBirth: certificateCountryOfBirth?.value || "",
      certificateDateOfBirth: ToSaveDate(certificateDateOfBirth, USDateFormat),
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValues: N565DocInfoFormData = {
    certificateFirstName: initInput(data?.certificateFirstName),
    certificateMiddleName: initInput(data?.certificateMiddleName),
    certificateLastName: initInput(data?.certificateLastName),
    certificateDateOfBirth: initDate(data?.certificateDateOfBirth),
    certificateCountryOfBirth: initSelect(
      formattedCountryData,
      data?.certificateCountryOfBirth
    ),
    certificateNumber: initInput(data?.certificateNumber),
    certificateANumber: initInput(data?.certificateANumber),
    whoIssuedCertificate: initInput(data?.whoIssuedCertificate),
    certificateDateOfIssuance: initDate(data?.certificateDateOfIssuance),
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Title>Your Full Name</Title>
        <Sub>
          Provide your full name exactly as it is printed on the certificate or
          declaration.
        </Sub>
        <Formik
          initialValues={initialValues}
          validationSchema={InfoFromDocValidation}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <InputField
                  label="Family Name"
                  coloredLabel="(Last Name)"
                  placeholder=""
                  name="certificateLastName"
                />
                <InputField
                  label="Given Name"
                  coloredLabel="(First Name)"
                  placeholder=""
                  name="certificateFirstName"
                />
              </FormWrapper>

              <FormWrapper>
                <InputField
                  label="Middle Name(s)"
                  coloredLabel="(optional)"
                  placeholder=""
                  name="certificateMiddleName"
                />
                <div></div>
              </FormWrapper>

              

              <FormWrapper>
                <DatePickerField
                  name="certificateDateOfBirth"
                  label="Date of Birth on Certificate or Declaration"
                  placeholder={USDatePlaceholderFormat}
                  disableFuture={true}
                />
                <SelectField
                  name="certificateCountryOfBirth"
                  label="Country of Former Citizenship or Nationality"
                  placeholder="-Select-"
                  options={formattedCountryData}
                  isLoading={countryLoading}
                />
              </FormWrapper>

              <FormWrapper>
                <div>
                  <InputField
                    label="Certificate or Declaration Number"
                    placeholder=""
                    name="certificateNumber"
                  />
                  <Note>
                    (The certificate number is generally a red 6 to 8-digit
                    alpha numeric number.)
                  </Note>
                </div>

                <InputField
                  label="Alien Registration Number"
                  coloredLabel="(A-Number)"
                  placeholder=""
                  name="certificateANumber"
                />
              </FormWrapper>

              <Title>Certificate or Declaration Issuance</Title>
              <Sub>
                Provide information about who issued your last certificate or
                declaration along with the date it was issued.
              </Sub>

              <FormWrapper>
                <InputField
                  label="U.S. Citizenship and Immigration Services (USCIS) Office or Name of Court"
                  placeholder=""
                  name="whoIssuedCertificate"
                />

                <div></div>
              </FormWrapper>

              <FormWrapper>
                <DatePickerField
                  name="certificateDateOfIssuance"
                  label="Date of Issuance"
                  placeholder={USDatePlaceholderFormat}
                  disableFuture
                />
                <div></div>
              </FormWrapper>

              <FormBtn
                isLoading={isSaving}
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default InfoFromDoc;

const Wrapper = styled.div``;

const Title = styled.div`
  color: ${Colors.Black31};
  font-weight: bold;
`;

const Sub = styled.div`
  color: ${Colors.Black31};
  margin-top: 19px;
  margin-bottom: 30px;
  font-weight: 300;
  font-size: 0.875rem;
`;

const Note = styled.div`
  color: ${Colors.Black31};
  font-weight: 300;
  margin-bottom: 30px;
  font-size: 0.875rem;
`;
