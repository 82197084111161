import React, { FC } from "react";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { N600InfoAboutYouPart4Payload } from "../../../../../../api/n600/types";
import { FormN600Progress } from "../../types";

interface Props {
  title: string;
  data?: N600InfoAboutYouPart4Payload;
}

const ReviewInfoAboutYouPart4: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      label:
        "Were your parents married to each other when you were born (or adopted)?",
      value: data?.parentsMarriedAtBirth,
    },
    {
      label: "Did your parents marry after you were born?",
      value: data?.parentsMarriedAfterBirth,
    },
    {
      label:
        "Do you regularly reside in the United States in the legal and physical custody of your U.S. citizen parents?",
      value: data?.resideInUSWithCitizenParents,
    },
    {
      label:
        "Have you been absent from the United States since you first arrived?",
      value: data?.absentFromUS,
    },
    {
      label: "Date You Left the United States (First Departure)",
      value: data?.firstDepartureDate,
    },
    {
      label: "Date You Returned to the United States (First Return)",
      value: data?.firstReturnDate,
    },
    {
      label:
        "Place of Entry Upon Return to the United States City or Town (First Return)",
      value: data?.firstPlaceOfEntryCity,
    },
    {
      label:
        "Place of Entry Upon Return to the United States State (First Return)",
      value: data?.firstPlaceOfEntryState,
    },
    {
      label: "Date You Left the United States (Second Departure)",
      value: data?.secondDepartureDate,
    },
    {
      label: "Date You Returned to the United States (Second Return)",
      value: data?.secondReturnDate,
    },
    {
      label:
        "Place of Entry Upon Return to the United States City or Town (Second Return)",
      value: data?.secondPlaceOfEntryCity,
    },
    {
      label:
        "Place of Entry Upon Return to the United States State (Second Return)",
      value: data?.secondPlaceOfEntryState,
    },
  ];

  return (
    <ReviewBox
      title={title}
      data={section}
      editUrl={FormN600Progress.infoAboutYouP4}
    />
  );
};

export default ReviewInfoAboutYouPart4;
