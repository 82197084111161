import React, { FC } from "react";
import {
  FormWrapper,
  RadioLabel,
  RadioWrapper,
} from "../../../components/styles";
import { FieldArray, FormikProps } from "formik";
import { Colored } from "../../../form 400/components/styles";
import {
  CheckWrap,
  Title,
  TypeOfApplicationFormData,
} from "../TypeOfApplication";
import CheckBoxField from "../../../components/formik fields/CheckBoxField";
import InputField from "../../../components/formik fields/InputField";

interface Props {
  formik: FormikProps<TypeOfApplicationFormData>;
}

const Part4: FC<Props> = ({ formik }) => {
  return (
    <>
      {formik.values.basisForApplicationArray[0].typoError && (
        <div className="flex flex-col gap-4 ">
          <Title>
            Complete If Applying to Correct Your Document Due to a USCIS
            Typographical or Clerical Error
          </Title>
          <RadioWrapper>
            <RadioLabel>
              What was the typographical or clerical error in your document that
              needs to be corrected?{" "}
              <Colored>(select all applicable boxes) </Colored>
            </RadioLabel>
            <FieldArray
              name="typeOfTypoErrorArray"
              render={(arrayHelpers) => (
                <>
                  {formik.values.typeOfTypoErrorArray.map((_, index) => (
                    <CheckWrap key={index}>
                      <CheckBoxField
                        value={"Name"}
                        name={`typeOfTypoErrorArray[${index}].name`}
                        label={"Name"}
                      />
                      <CheckBoxField
                        value={"Date of Birth"}
                        name={`typeOfTypoErrorArray[${index}].dateOfBirth`}
                        label={"Date of Birth"}
                      />
                      <CheckBoxField
                        value={"Gender"}
                        name={`typeOfTypoErrorArray[${index}].gender`}
                        label={"Gender"}
                      />

                      <CheckBoxField
                        value={"Other"}
                        name={`typeOfTypoErrorArray[${index}].other`}
                        label={"Other"}
                      />
                    </CheckWrap>
                  ))}
                </>
              )}
            />
          </RadioWrapper>

          <FormWrapper>
            <InputField
              name="explanationOfTypoError"
              label="Provide an explanation of what is incorrect on your current certificate or declaration"
              placeholder=""
              inputType="text-area"
            />

            <div></div>
          </FormWrapper>
        </div>
      )}
    </>
  );
};

export default Part4;
