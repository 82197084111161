const Mission = () => {
  return (
    <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-5xl font-bold font-spartan tracking-tight text-gray-900 sm:text-7xl">
          Our mission
        </h2>
        <p className="mt-8 text-lg font-medium text-gray-500 sm:text-xl">
          To become the leading U.S. immigration platform, empowering
          individuals and businesses to achieve success through efficient,
          affordable, and top-tier services.
        </p>
      </div>
    </div>
  );
};

export default Mission;
