import React, { FC } from "react";
import styled from "styled-components";
import CheckBoxField from "../../components/formik fields/CheckBoxField";
import InputField from "../../components/formik fields/InputField";
import SelectField from "../../components/formik fields/SelectField";
import Colors from "../../../../styles/Colors";
import { FormData } from "..";
import { ApartmentSuiteFloorData } from "../../components/data";
import { FormikProps } from "formik";
import { SingleOption } from "../../../../types/types";

interface Props {
  formik: FormikProps<FormData>;
  preparerCountryData: SingleOption[];
  preparerCountryLoading: boolean;
  preparerStateData: SingleOption[];
  preparerStateLoading: boolean;
  preparerSetCountry: React.Dispatch<React.SetStateAction<string>>;
  preparerSelectedCountry: string;
}

const PreparerInfoSection: FC<Props> = ({
  formik,
  preparerCountryData,
  preparerCountryLoading,
  preparerStateData,
  preparerStateLoading,
  preparerSetCountry,
  preparerSelectedCountry,
}) => {
  return (
    <>
      <Header>Preparer’s Information</Header>
      <FormWrapper>
        <Flexxer>
          <InputField
            label="Preparer’s Given Name"
            coloredLabel="(First Name)"
            placeholder=""
            name="preparerFirstName"
          />
          <InputField
            label="Preparer’s Family Name"
            coloredLabel="(Last Name)"
            placeholder=""
            name="preparerLastName"
          />
        </Flexxer>
        <Flexxer>
          <InputField
            label="Preparer’s Firm or Organization Name"
            placeholder=""
            name="preparerFirm"
          />
          <InputField
            label="Preparer’s Address"
            placeholder=""
            name="preparerAddress"
          />
        </Flexxer>
        <Flexxer>
          <InputField
            label="Street Number and Name"
            placeholder=""
            name="preparerStreetNumAndName"
          />
          <InputField label="Email" placeholder="" name="preparerEmail" />
        </Flexxer>
        <Flexxer>
          <MiniFlexxer>
            <InputField
              label="City or Town"
              placeholder=""
              name="preparerCityOrTown"
            />
            <InputField
              label="ZIP Code"
              placeholder=""
              name="preparerZipCode"
            />
          </MiniFlexxer>
          <MiniFlexxer>
            <InputField
              label="Daytime Phone Number"
              placeholder=""
              name="preparerDayTimePhoneNumber"
            />
            <InputField
              label="Mobile Phone Number"
              coloredLabel="(if any)"
              placeholder=""
              name="preparerMobilePhoneNumber"
            />
          </MiniFlexxer>
        </Flexxer>
        <Flexxer>
          <SelectField
            label="Country"
            placeholder=""
            name="preparerCountry"
            options={preparerCountryData}
            isLoading={preparerCountryLoading}
            onChange={(value) => {
              preparerSetCountry(value.label);
              formik.setFieldValue(`preparerStateOrTerritory`, null);
            }}
          />

          <SelectField
            label="State"
            placeholder=""
            name="preparerStateOrTerritory"
            options={preparerStateData}
            isLoading={preparerStateLoading}
            isDisabled={!preparerSelectedCountry}
          />
        </Flexxer>

        <Flexxer>
          <SelectField
            name="preparerApartmentSuiteOrFloor"
            label="In there an apartment, suite or floor"
            placeholder="-Select-"
            options={ApartmentSuiteFloorData}
          />
          <div>
            {formik.values.preparerApartmentSuiteOrFloor?.value && (
              <InputField
                label={`${formik.values.preparerApartmentSuiteOrFloor.value} Address number`}
                placeholder=""
                name={`preparerAddressNum`}
              />
            )}
          </div>
        </Flexxer>
        <CheckWrapper>
          <CheckBoxField
            label="I acknowledge that each and every question and instruction on this form, as well as my answer to each question, has been read to me by the person named above in a language in which I am fluent. I have provided true and correct responses in the language indicated above."
            name="preparerAcknowledgement"
          />
        </CheckWrapper>
      </FormWrapper>
    </>
  );
};

export default PreparerInfoSection;

const Header = styled.div`
  font-size: 1rem;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  padding-bottom: 6px;
  border-bottom: 1px solid ${Colors.GreyA7};
  margin-bottom: 30px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Flexxer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 70px;

  & > * {
    width: 50%;
  }
`;

const MiniFlexxer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 40px;
`;

const CheckWrapper = styled.div`
  margin-top: 11px;
`;
