import styled from "styled-components";

import { IoArrowBackOutline } from "react-icons/io5";
// import dayjs from "dayjs";

import parse from "html-react-parser";
import { useNavigate, useParams } from "react-router-dom";

import RequestWrapper from "../../components/RequestWrapper";
// import { useState } from "react";
// import { toast } from "react-toastify";
// import TerminateModal from "../../components/modal/TerminateModal";
import { WebPaths } from "../../../../routes/data";
import Colors from "../../../../styles/Colors";
import { replaceEmptyPTagWithBrTag } from "../../../../utils";
import {
  // useDeleteArticle,
  useGetSingleArticle,
} from "../../../../hooks/blogs/useArticles";
import Constaint from "../../layout/components/Constaint";
import { useEffect } from "react";

const SingleNews = () => {
  const navigate = useNavigate();
  const { newsId } = useParams();
  const { data, isLoading, isError } = useGetSingleArticle(newsId || "");
  // const { mutate, isLoading: isDeleting } = useDeleteArticle();
  // const [open, setOpen] = useState(false);

  // const onOpenDelete = () => {
  //   setOpen(true);
  // };

  // const onCloseDelete = () => {
  //   setOpen(false);
  // };

  // const onDelete = () => {
  //   if (!data) return;
  //   mutate(data.article_id, {
  //     onSuccess: () => {
  //       toast.success(`Article, ${data.title} deleted successfully!`);
  //       onCloseDelete();
  //       navigate(WebPaths.Blogs);
  //     },
  //   });
  // };

  const capitalizeFirstLetter = (str: string | undefined) => {
    if (str === undefined) return;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    document.title = `SeamlessVisa - Blogs  | ${
      capitalizeFirstLetter(data?.title) || "Loading..."
    }`;

    return () => {
      document.title =
        "SeamlessVisa | The Trusted Partner in Your Immigration Journey";
    };
  }, [data]);

  return (
    <Wrapper>
      <Constaint>
        <>
          <Header>
            <MiniFlex onClick={() => navigate(WebPaths.Blogs)}>
              <IoArrowBackOutline />
              Back
            </MiniFlex>

            {data && (
              <MiniFlex>
                {/* <EditBtn
                onClick={() =>
                  navigate(
                    `${WebPaths.NewArticle}?${newTitleQuery}=${data.title}`
                  )
                }
              >
                Edit
              </EditBtn>
              <DeleteBtn onClick={onOpenDelete}>Delete</DeleteBtn> */}
              </MiniFlex>
            )}
          </Header>
          <RequestWrapper isError={isError} isLoading={isLoading} data={data}>
            <>
              {data && (
                <Content>
                  <ContentH1>{data.title}</ContentH1>
                  <ContentSpan>
                    {/* <span>Written by: {data.author}</span> */}
                    {/* <span>
                      Date published:{" "}
                      {dayjs(data.publication_date).format("DD-MM-YYYY")}
                    </span> */}
                  </ContentSpan>
                  <ContentImage src={data.image} />

                  <ContentBody>
                    {parse(replaceEmptyPTagWithBrTag(data.content || ""))}
                  </ContentBody>
                </Content>
              )}
            </>
          </RequestWrapper>
        </>
      </Constaint>
    </Wrapper>
  );
};

export default SingleNews;

const Wrapper = styled.div`
  font-family: "Lato", sans-serif !important;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  margin-bottom: 24px;
  height: 40px;

  & > div {
    cursor: pointer;
  }
`;

const MiniFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

// const Btn = styled.button`
//   height: 30px;
//   padding: 4px 12px;
//   border-radius: 3px;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   font-weight: 500;
//   line-height: 12px;
//   letter-spacing: 0em;
// `;

// const EditBtn = styled(Btn)`
//   border: 1px solid ${Colors.Black31};
// `;

// const DeleteBtn = styled(Btn)`
//   background-color: ${Colors.RedC3};
//   color: white;
// `;

const Content = styled.div`
  background-color: white;
  padding: 24px;
`;

const ContentH1 = styled.h1`
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: capitalize;
`;

const ContentSpan = styled.span`
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 22px;
  margin-bottom: 40px;

  & > span:nth-child(1) {
    color: ${Colors.Blue00};
  }

  & > span:nth-child(2) {
    color: ${Colors.GreyA7};
  }
`;

const ContentImage = styled.img`
  height: 400px;
  object-fit: cover;
  width: 100%;
`;

const ContentBody = styled.div`
  margin: 60px 0;

  p {
    margin: 0.75rem 0 !important;
  }

  span {
    font-family: "Lato", sans-serif !important;
  }

  ul {
    list-style: disc;
    margin: 1rem 1.25rem;
  }

  strong {
    display: block;
    margin: 1.25rem 0 0;
  }

  a {
    color: ${Colors.Blue00} !important;
    text-decoration: underline !important;

    span {
      color: ${Colors.Blue00} !important;
    }

    &:hover {
      color: ${Colors.Blue02} !important;
    }
  }
`;
