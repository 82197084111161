import React from "react";
import styled from "styled-components";
import Colors from "../../../../../../styles/Colors";
import { settingsTab } from "../../data";
import { useSearchParams } from "react-router-dom";
import { IoMdArrowDropright } from "react-icons/io";

export const tabText = "tab";

const LeftSide = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const getType = searchParams.get(tabText);

  return (
    <Container>
      {settingsTab.map((ev) => {
        const isActive = getType === ev.url;

        return (
          <NavItem>
            <NavIcon>
              {isActive && <IoMdArrowDropright color={Colors.Blue00} />}
            </NavIcon>
            <NavText
              isActive={isActive}
              onClick={() => {
                searchParams.set(tabText, ev.url);
                setSearchParams(searchParams);
              }}
            >
              {ev.title}
            </NavText>
          </NavItem>
        );
      })}
    </Container>
  );
};

export default LeftSide;

const Container = styled.div`
  flex: 0.2;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const NavItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 500;
`;

interface NavTextProps {
  isActive: boolean;
}

const NavText = styled.div<NavTextProps>`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.1875rem;
  color: ${({ isActive }) => (isActive ? Colors.Black31 : Colors.GreyA7)};
  cursor: pointer;
`;

const NavIcon = styled.div`
  width: 20px;
  height: 20px;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;
