import React, { FC } from "react";
import { AddressHistoryData } from "./AddressHistory";
import { FormikProps } from "formik";
import { FormWrapper } from "../../components/styles";
import InputField from "../../components/formik fields/InputField";
import SelectField from "../../components/formik fields/SelectField";
import {
  ApartmentSuiteFloorData,
  USDatePlaceholderFormat,
  isZipCode,
} from "../../components/data";
import DatePickerField from "../../components/formik fields/DatePickerField";
import { CheckWrapper } from "../../form ds 160/components/styles";
import CheckBoxField from "../../components/formik fields/CheckBoxField";
import useCountries from "../../components/useCountries";
import useStatesByCountry from "../../components/useStatesByCountry";
import styled from "styled-components";
import { N400AddressHistoryObj } from "../../../../api/n400/types";

interface Props {
  formik: FormikProps<AddressHistoryData>;
  data?: N400AddressHistoryObj;
  index: number;
}

const AddressHistoryPhy: FC<Props> = ({ formik, data, index }) => {
  const savedCountry = data?.country.split(",")[index] || "";
  const { formattedCountryData, countryLoading } = useCountries();
  const { formattedStateData, stateLoading, setCountry, selectedCountry } =
    useStatesByCountry(savedCountry);

  return (
    <>
      <div className="mb-8">
        <FormWrapper>
          {index === 0 && (
            <InputField
              label="In Care Of Name"
              coloredLabel=" (if any)"
              placeholder=""
              name={`physicalAddressArray[${index}].inCareOfName`}
            />
          )}

          <InputField
            label="Street Number and Name"
            placeholder=""
            name={`physicalAddressArray[${index}].streetNumberAndName`}
          />
          {index !== 0 && <div></div>}
        </FormWrapper>

        <FormWrapper>
          <div>
            <SelectField
              name={`physicalAddressArray[${index}].country`}
              label="Country"
              placeholder="-Select-"
              options={formattedCountryData}
              isLoading={countryLoading}
              onChange={(value) => {
                setCountry(value.label);
                formik.setFieldValue(
                  `physicalAddressArray[${index}].stateOrTerritory`,
                  null
                );
                formik.setFieldValue(
                  `physicalAddressArray[${index}].addressCode`,
                  ""
                );
              }}
            />
          </div>
          <div>
            <SelectField
              name={`physicalAddressArray[${index}].stateOrTerritory`}
              label="State"
              placeholder="-Select-"
              options={formattedStateData}
              isLoading={stateLoading}
              isDisabled={!selectedCountry}
            />
          </div>
        </FormWrapper>

        <FormWrapper>
          <InputField
            label="City or Town"
            placeholder=""
            name={`physicalAddressArray[${index}].cityOrTown`}
          />
          <InputField
            label={`${
              isZipCode(
                formik.values.physicalAddressArray[index].country?.value
              )
                ? "Zip"
                : "Postal"
            } Code`}
            placeholder=""
            name={`physicalAddressArray[${index}].addressCode`}
          />
        </FormWrapper>

        {index === 0 && (
          <FormWrapper>
            <div>
              <SelectField
                label="Is there an apartment, suite or floor?"
                name={`physicalAddressArray[${index}].apartmentSuiteOrFloor`}
                options={ApartmentSuiteFloorData}
              />
            </div>

            <div>
              {formik.values.physicalAddressArray[index].apartmentSuiteOrFloor
                ?.value && (
                <InputField
                  label={`${formik.values.physicalAddressArray[index].apartmentSuiteOrFloor?.value} Address number`}
                  placeholder=""
                  name={`physicalAddressArray[${index}].addressNumber`}
                />
              )}
            </div>
          </FormWrapper>
        )}
        {index === 0 && (
          <FormWrapper>
            <InputField
              label="Province"
              placeholder=""
              name={`physicalAddressArray[${index}].province`}
            />

            <div></div>
          </FormWrapper>
        )}

        <FormWrapper>
          <div>
            <DatePickerField
              name={`physicalAddressArray[${index}].dateYouBeganLivingAtAddress`}
              label="Date you began living at the address above"
              placeholder={USDatePlaceholderFormat}
              disableFuture={true}
            />
          </div>

          {index === 0 ? (
            <div></div>
          ) : (
            <LastWrapper>
              <DatePickerField
                name={`physicalAddressArray[${index}].dateYouLeftAddress`}
                label="Date you left the address above"
                placeholder={USDatePlaceholderFormat}
                disableFuture={true}
                isDisabled={index === 0}
              />

              {index === 0 && (
                <CheckWrapper>
                  <CheckBoxField
                    label={`Still lives in there?`}
                    name={`physicalAddressArray[${index}].yetToReturn`}
                    onChange={() => {
                      formik.setFieldTouched(
                        `physicalAddressArray[${index}].dateYouLeftAddress`,
                        false
                      );
                      formik.setFieldValue(
                        `physicalAddressArray[${index}].dateYouLeftAddress`,
                        null
                      );
                    }}
                  />
                </CheckWrapper>
              )}
            </LastWrapper>
          )}
        </FormWrapper>
      </div>
    </>
  );
};

export default AddressHistoryPhy;

const LastWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
