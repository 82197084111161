import { FC } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import styled from "styled-components";

interface Props {
  onBack: () => void;
}

const BackButton: FC<Props> = ({ onBack }) => {
  return (
    <MiniFlex onClick={onBack}>
      <IoArrowBackOutline />
      Back
    </MiniFlex>
  );
};

export default BackButton;

const MiniFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;
  cursor: pointer;
`;
