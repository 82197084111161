import { FC } from "react";
import styled from "styled-components";
import Colors from "../../../../../../../styles/Colors";

interface Props {
  label?: string;
  value?: string;
}

const ReviewGeneric: FC<Props> = ({ label, value }) => {
  return value ? (
    <Row>
      <RowLabel>{label}</RowLabel>
      <RowValue>{value}</RowValue>
    </Row>
  ) : (
    <></>
  );
};

export default ReviewGeneric;

export const Row = styled.div`
  padding: 24px 16px 12px 16px;
  border-bottom: 1px solid #d3d4dc75;
  color: ${Colors.Black31};
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: space-between;
`;

const RowLabel = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
`;

const RowValue = styled.div`
  font-size: 1rem;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;

  &::first-letter {
    text-transform: capitalize;
  }
`;
