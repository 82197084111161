import React from "react";
import styled from "styled-components";
import Colors from "../../../../styles/Colors";

const PhotoRequirement = () => {
  return (
    <Info>
      <InfoBold>Important:</InfoBold> Kindly ensure your photo meets the U.S
      Department of State requirements by using the official government photo
      tool.{" "}
      <Anchor
        href="https://tsg.phototool.state.gov/photo"
        rel="noreferrer"
        target="_blank"
      >
        click here
      </Anchor>{" "}
    </Info>
  );
};

export default PhotoRequirement;

const Info = styled.div`
  color: ${Colors.Black31};
  font-size: 0.875rem;
`;

const InfoBold = styled.span`
  font-weight: bold;
`;

const Anchor = styled.a`
  text-decoration: underline;
`;
