export const NeedsImprovementsData = [
  {
    label: "Step-by-step help with explanations",
    value: "Step-by-step help with explanations",
  },
  { label: "Speed of the website", value: "Speed of the website" },
  { label: "Price", value: "Price" },
  { label: "Customer support", value: "Customer support" },
  {
    label: "Account management (passwords, dashboard, etc.)",
    value: "Account management (passwords, dashboard, etc.)",
  },
  { label: "None (it’s all great)", value: "None (it’s all great)" },
  { label: "Other", value: "Other" },
];

export const Q3Data = [
  {label:"Completely trustworthy", value:"Completely trustworthy"},
  {label:"Mostly trustworthy", value:"Mostly trustworthy"},
  {label:"Somewhat trustworthy", value:"Somewhat trustworthy"},
  {label:"Not trustworthy", value:"Not trustworthy"},
  {label:"Can’t determine yet", value:"Can’t determine yet"},
]

export const Q6Data = [
  {label:"Highly Likely",value:"Highly Likely"},
  {label:"Likely",value:"Likely"},
  {label:"Undecided",value:"Undecided"},
  {label:"Unlikely",value:"Unlikely"},
  {label:"Highly Unlikely",value:"Highly Unlikely"},
]
