import React, { FC } from "react";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { FormN400Progress } from "../../types";
import { N400MaritalHistoryObj } from "../../../../../../api/n400/types";

interface Props {
  title: string;
  data?: N400MaritalHistoryObj;
}

const ReviewMaritalHistory: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      section: {
        title: "Marital History",
        children: [
          {
            label: "What is your current marital status?",
            value: data?.currentMaritalStatus,
          },
          {
            label: "Is your spouse a member of the U.S. Armed Forces?",
            value: data?.isSpouseMemberOfArmedForces,
          },
          {
            label: "How many times have you been married?",
            value: data?.numOfTimesMarried,
          },
          {
            section: {
              title: "Your Current Spouse Legal Name",
              children: [
                {
                  label: "Family Name (Last Name)",
                  value: data?.spouseLastName,
                },
                {
                  label: "Given Name (First Name)",
                  value: data?.spouseFirstName,
                },
                {
                  label: "Middle Name (if applicable)",
                  value: data?.spouseMiddleName,
                },
              ],
            },
          },
          {
            section: {
              title: "Other Information About Your Current Spouse",
              children: [
                {
                  label: "Spouse's Date of Birth",
                  value: data?.spouseDateOfBirth,
                },
                {
                  label: "Date of Marriage",
                  value: data?.dateOfMarriage,
                },
                {
                  label: "Spouse's Present Employer",
                  value: data?.spousePresentEmployer,
                },
              ],
            },
          },
          {
            section: {
              title: "Current Spouse’s Home Address",
              children: [
                {
                  label:
                    "Is your current spouse's present physical address the same as your physical address?",
                  value: data?.isSpouseAtYourAddress,
                  section: {
                    title: "Current Spouse Physical Address",
                    children: [
                      {
                        label: "In Care of Name (if any)",
                        value: data?.inCareOfName.replace(/,/g, ", "),
                      },
                      {
                        label: "Street Number and Name",
                        value: data?.streetNumberAndName.replace(/,/g, ", "),
                      },
                      {
                        label: "Is there an apartment, suite or floor?",
                        value: data?.apartmentSuiteOrFloor.replace(/,/g, ", "),
                      },
                      {
                        label: "Address number",
                        value: data?.addressNumber,
                      },
                      {
                        label: "City or Town",
                        value: data?.cityOrTown.replace(/,/g, ", "),
                      },
                      {
                        label: "Country",
                        value: data?.country.replace(/,/g, ", "),
                      },
                      {
                        label: "State or Territory",
                        value: data?.stateOrTerritory.replace(/,/g, ", "),
                      },
                      {
                        label: "Province",
                        value: data?.province,
                      },
                      {
                        label: "Zip Code",
                        value: data?.zipCode,
                      },
                      {
                        label: "Postal Code",
                        value: data?.postalCode,
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            section: {
              title:
                "Information about your current spouse's previous marriage",
              children: [
                {
                  label: "How many times has your current spouse been married?",
                  value: data?.howManyTimeSpouseMarried,
                },
              ],
            },
          },
          {
            label: "How did your current spouse become a U.S. citizen?",
            value: data?.whenSpouseBecameUsCitizen,
          },
          {
            label: "Date Your Current Spouse Became a U.S. Citizen",
            value: data?.dateSpouseBecameUsCitizen,
          },
          {
            label:
              "Current Spouse's Alien Registration Number (A-Number) (if any)",
            value: data?.spouseAnumber,
          },
        ],
      },
    },
  ];

  return (
    <ReviewBox
      title={title}
      data={section}
      editUrl={FormN400Progress.maritalHistory}
    />
  );
};

export default ReviewMaritalHistory;
