import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  saveN600Eligibility,
  getN600Eligibility,
  uploadN600Files,
  getN600Files,
  updateN600Files,
  getN600InfoAboutEligibility,
  saveN600InfoAboutEligibility,
  getN600InfoAboutYouPart1,
  saveN600InfoAboutYouPart1,
  saveN600InfoAboutYouPart2,
  getN600InfoAboutYouPart2,
  getN600BiographicInfo,
  saveN600BiographicInfo,
  getN600InfoAboutUSCitizenFatherPart1,
  saveN600InfoAboutUSCitizenFatherPart1,
  getN600InfoAboutUSCitizenFatherPart2,
  saveN600InfoAboutUSCitizenFatherPart2,
  saveN600InfoAboutUSCitizenMotherPart2,
  getN600InfoAboutUSCitizenMotherPart2,
  saveN600InfoAboutUSCitizenMotherPart1,
  getN600InfoAboutUSCitizenMotherPart1,
  getN600PhysicalPresence,
  saveN600PhysicalPresence,
  getN600MilitaryService,
  saveN600MilitaryService,
  getN600InfoAboutYouPart3,
  saveN600InfoAboutYouPart3,
  getN600InfoAboutYouPart4,
  saveN600InfoAboutYouPart4,
  saveN600Preparer,
  getN600Preparer,
  payN600,
  getN600PaySuccess,
  getN600PayFail,
  getN600Info,
  getN600Trigger,
} from "../../api/n600";
import { parseError } from "../../utils";
import { n600QueryKeys } from "./types";
import { utilsQueryKeys } from "../utils/types";

export const useSaveN600Eligibility = () => {
  const queryClient = useQueryClient();

  return useMutation(saveN600Eligibility, {
    onError: (error) => parseError(error),
    onSuccess: () =>
      queryClient.invalidateQueries([utilsQueryKeys.getUserData]),
  });
};

export const useGetN600Eligibility = () => {
  return useQuery([n600QueryKeys.getN600Eligibility], getN600Eligibility, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useUploadN600Files = () => {
  const queryClient = useQueryClient();

  return useMutation(uploadN600Files, {
    onError: (error) => parseError(error),
    onSuccess: () =>
      queryClient.invalidateQueries([n600QueryKeys.getN600Files]),
  });
};

export const useGetN600Files = () => {
  return useQuery([n600QueryKeys.getN600Files], getN600Files, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    // refetchOnWindowFocus: false,
  });
};

export const useUpdateN600Files = () => {
  const queryClient = useQueryClient();

  return useMutation(updateN600Files, {
    onError: (error) => parseError(error),
    onSuccess: () =>
      queryClient.invalidateQueries([n600QueryKeys.getN600Files]),
  });
};

export const useGetN600InfoAboutEligibility = () => {
  return useQuery(
    [n600QueryKeys.getN600InfoAboutEligibility],
    getN600InfoAboutEligibility,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveN600InfoAboutEligibility = () => {
  return useMutation(saveN600InfoAboutEligibility, {
    onError: (error) => parseError(error),
  });
};

export const useGetN600InfoAboutYouPart1 = () => {
  return useQuery(
    [n600QueryKeys.getN600InfoAboutYouPart1],
    getN600InfoAboutYouPart1,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveN600InfoAboutYouPart1 = () => {
  return useMutation(saveN600InfoAboutYouPart1, {
    onError: (error) => parseError(error),
  });
};

export const useGetN600InfoAboutYouPart2 = () => {
  return useQuery(
    [n600QueryKeys.getN600InfoAboutYouPart2],
    getN600InfoAboutYouPart2,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveN600InfoAboutYouPart2 = () => {
  return useMutation(saveN600InfoAboutYouPart2, {
    onError: (error) => parseError(error),
  });
};

export const useGetN600InfoAboutYouPart3 = () => {
  return useQuery(
    [n600QueryKeys.getN600InfoAboutYouPart3],
    getN600InfoAboutYouPart3,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveN600InfoAboutYouPart3 = () => {
  return useMutation(saveN600InfoAboutYouPart3, {
    onError: (error) => parseError(error),
  });
};

export const useGetN600InfoAboutYouPart4 = () => {
  return useQuery(
    [n600QueryKeys.getN600InfoAboutYouPart4],
    getN600InfoAboutYouPart4,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveN600InfoAboutYouPart4 = () => {
  return useMutation(saveN600InfoAboutYouPart4, {
    onError: (error) => parseError(error),
  });
};

export const useGetN600BiographicInfo = () => {
  return useQuery(
    [n600QueryKeys.getN600BiographicInfo],
    getN600BiographicInfo,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveN600BiographicInfo = () => {
  return useMutation(saveN600BiographicInfo, {
    onError: (error) => parseError(error),
  });
};

export const useGetN600InfoAboutUSCitizenFatherPart1 = () => {
  return useQuery(
    [n600QueryKeys.getN600InfoAboutUSCitizenFatherPart1],
    getN600InfoAboutUSCitizenFatherPart1,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveN600InfoAboutUSCitizenFatherPart1 = () => {
  return useMutation(saveN600InfoAboutUSCitizenFatherPart1, {
    onError: (error) => parseError(error),
  });
};

export const useGetN600InfoAboutUSCitizenFatherPart2 = () => {
  return useQuery(
    [n600QueryKeys.getN600InfoAboutUSCitizenFatherPart2],
    getN600InfoAboutUSCitizenFatherPart2,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveN600InfoAboutUSCitizenFatherPart2 = () => {
  return useMutation(saveN600InfoAboutUSCitizenFatherPart2, {
    onError: (error) => parseError(error),
  });
};

export const useGetN600InfoAboutUSCitizenMotherPart1 = () => {
  return useQuery(
    [n600QueryKeys.getN600InfoAboutUSCitizenMotherPart1],
    getN600InfoAboutUSCitizenMotherPart1,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveN600InfoAboutUSCitizenMotherPart1 = () => {
  return useMutation(saveN600InfoAboutUSCitizenMotherPart1, {
    onError: (error) => parseError(error),
  });
};

export const useGetN600InfoAboutUSCitizenMotherPart2 = () => {
  return useQuery(
    [n600QueryKeys.getN600InfoAboutUSCitizenMotherPart2],
    getN600InfoAboutUSCitizenMotherPart2,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveN600InfoAboutUSCitizenMotherPart2 = () => {
  return useMutation(saveN600InfoAboutUSCitizenMotherPart2, {
    onError: (error) => parseError(error),
  });
};

export const useGetN600PhysicalPresence = () => {
  return useQuery(
    [n600QueryKeys.getN600PhysicalPresence],
    getN600PhysicalPresence,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveN600PhysicalPresence = () => {
  return useMutation(saveN600PhysicalPresence, {
    onError: (error) => parseError(error),
  });
};

export const useGetN600MilitaryService = () => {
  return useQuery(
    [n600QueryKeys.getN600MilitaryService],
    getN600MilitaryService,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveN600MilitaryService = () => {
  return useMutation(saveN600MilitaryService, {
    onError: (error) => parseError(error),
  });
};

export const useSaveN600Preparer = () => {
  return useMutation(saveN600Preparer, {
    onError: (error) => parseError(error),
  });
};

export const useGetN600Preparer = () => {
  return useQuery([n600QueryKeys.getN600Preparer], getN600Preparer, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const usePayN600 = () => {
  return useMutation(payN600, {
    onError: (error) => parseError(error),
  });
};

export const useGetN600PaySuccess = (payId: string) => {
  const queryClient = useQueryClient();

  return useQuery(
    [n600QueryKeys.getN600PaySuccess, payId],
    () => getN600PaySuccess(payId),
    {
      enabled: !!payId,
      onSuccess: () =>
        queryClient.invalidateQueries([utilsQueryKeys.getUserData]),
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetN600PayFail = () => {
  const queryClient = useQueryClient();

  return useQuery([n600QueryKeys.getN600PayFail], getN600PayFail, {
    onSuccess: () =>
      queryClient.invalidateQueries([utilsQueryKeys.getUserData]),
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useGetN600Info = () => {
  return useQuery([n600QueryKeys.getN600Info], getN600Info, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useGetN600Trigger = (isEnabled: boolean) => {
  return useQuery([n600QueryKeys.getN600Trigger], getN600Trigger, {
    enabled: isEnabled,
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};
