import React, { FC } from "react";
import {
  saveDS160WETAdditionalObj,
  saveDS160WETPresentObj,
  saveDS160WETPreviousObj,
} from "../../../../../../api/ds160/types";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { FormDS160Progress } from "../../types";
import { GenericDs160ReviewProps } from "./DownloadPage";
import PdfReviewBox from "../../../../components/Review/Pdf Review/PdfReviewBox";
import {
  ReviewWETAdditionalSectionA,
  ReviewWETAdditionalSectionB,
  ReviewWETAdditionalSectionC,
} from "./data";

interface Props extends GenericDs160ReviewProps {
  data?: {
    present: saveDS160WETPresentObj;
    previous: saveDS160WETPreviousObj;
    additional: saveDS160WETAdditionalObj;
  };
}

const ReviewWETAdditional: FC<Props> = ({ title, data, isDownload }) => {
  const sectionA = data ? ReviewWETAdditionalSectionA(data.present) : [];

  const sectionB = data ? ReviewWETAdditionalSectionB(data.previous) : [];

  const sectionC = data ? ReviewWETAdditionalSectionC(data.additional) : [];

  const section: ReviewData[] = [
    {
      section: {
        title: "Present",
        children: sectionA,
      },
    },
    {
      section: {
        title: "Previous",
        children: sectionB,
      },
    },
    {
      section: {
        title: "Additional",
        children: sectionC,
      },
    },
  ];

  const compProps = {
    title: title,
    data: section,
    editUrl: FormDS160Progress.WETPresent,
  };

  return isDownload ? (
    <PdfReviewBox {...compProps} />
  ) : (
    <ReviewBox {...compProps} />
  );
};

export default ReviewWETAdditional;
