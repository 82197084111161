import { BiSolidDollarCircle } from "react-icons/bi";
import { FaLink } from "react-icons/fa6";
import { HiMiniSparkles } from "react-icons/hi2";
import { IoChatbubbles, IoTimer } from "react-icons/io5";
import { RiDashboardFill } from "react-icons/ri";

export interface CardData {
  title: string;
  description: string;
  icon: JSX.Element;
  color: string;
}

export const cardData: CardData[] = [
  {
    title: "AI Risk Checker",
    description:
      "Proactively identify and mitigate potential risks with our advanced AI risk assessment technology. Protect your business, safeguard your reputation, and ensure compliance with industry regulations.",
    icon: <HiMiniSparkles />, // use an icon component or unicode
    color: "#0076E9", // Light blue for the icon background
  },
  {
    title: "Time-Saving Technology",
    description:
      "Streamline your workflows, automate repetitive tasks, and free up valuable time for strategic initiatives. Our time-saving technology empowers you to focus on what truly matters and drive your business forward.",
    icon: <IoTimer />,
    color: "#FF9900", // Light yellow for the icon background
  },
  {
    title: "AI Interview Trainer",
    description:
      "Master the art of immigration interviews with our AI-powered training platform. Practice with realistic scenarios tailored to your specific situation, receive expert feedback on your responses, and develop the confidence you need to impress immigration officers.",
    icon: <IoChatbubbles />,
    color: "#0DA700", // Light green for the icon background
  },
  {
    title: "Customized Dashboard",
    description:
      "Stay informed and in control with our real-time immigration case management dashboard. Track the progress of your cases, receive instant updates on key milestones, and make data-driven decisions to optimize your immigration strategy.",
    icon: <RiDashboardFill />,
    color: "#FF00C7", // Light pink for the icon background
  },
  {
    title: "Flexible Pricing",
    description:
      "Choose the perfect plan for your immigration practice. Our flexible options offer a range of features to suit your business needs and budget. From essential tools to advanced capabilities, we have the right plan for you.",
    icon: <BiSolidDollarCircle />,
    color: "#9300C7", // Light purple for the icon background
  },
  {
    title: "API Integration",
    description:
      "Seamlessly integrate our platform with your existing systems and applications using our robust API. Enhance your capabilities, streamline workflows, and unlock new opportunities for growth and innovation.",
    icon: <FaLink />,
    color: "#FF4E4E", // Light red for the icon background
  },
];

export const pricingPlans = [
  {
    title: "Standard",
    features: ["API Integration", "AI Risk Checker", "AI Interview Trainer"],
    buttonText: "Book a free demo",
    buttonColor: "#00bfa6", // Green color for the button
    borderColor: "#00e4db", // Teal border for the card
  },
  {
    title: "Premium",
    features: [
      "API Integration",
      "AI Risk Checker",
      "AI Interview Trainer",
      "Case Evaluation",
      "Client Collaboration",
      "Customized Dashboard",
    ],
    buttonText: "Book a free demo",
    buttonColor: "#ffffff", // White button for premium
    borderColor: "#0073ff", // Blue background for the card
    isHighlighted: true, // Highlight the premium plan
  },
  {
    title: "Enterprise",
    features: ["Get tailored solutions for your business needs"],
    buttonText: "Book a free demo",
    buttonColor: "#9c6fff", // Purple button for enterprise
    borderColor: "#bb7cff", // Light purple border for the card
  },
];
