import React, { FC } from "react";
import { saveDS160FamilyObj } from "../../../../../../api/ds160/types";
import ReviewBox from "../../../../components/Review/ReviewBox";
import { FormDS160Progress } from "../../types";
import { saveDS160MaritalStatusObj } from "../../FamilyRelatives/MaritalStatus";
import { GenericDs160ReviewProps } from "./DownloadPage";
import PdfReviewBox from "../../../../components/Review/Pdf Review/PdfReviewBox";
import { ReviewFamilySectionA, ReviewFamilySectionB } from "./data";

interface Props extends GenericDs160ReviewProps {
  data?: saveDS160FamilyObj;
  maritalStatusData?: saveDS160MaritalStatusObj;
}

const ReviewFamily: FC<Props> = ({
  title,
  data,
  maritalStatusData,
  isDownload,
}) => {
  const sectionA = data ? ReviewFamilySectionA(data) : [];

  const sectionB = maritalStatusData
    ? ReviewFamilySectionB(maritalStatusData)
    : [];

  const section = [
    {
      section: {
        title: "Relatives",
        children: sectionA,
      },
    },
    {
      section: {
        title: "Marital Status",
        children: sectionB,
      },
    },
  ];

  const compProps = {
    title: title,
    data: section,
    editUrl: FormDS160Progress.familyRelatives,
  };

  return isDownload ? (
    <PdfReviewBox {...compProps} />
  ) : (
    <ReviewBox {...compProps} />
  );
};

export default ReviewFamily;
