import React, { FC } from "react";
import { RadioFlex, RadioLabel, RadioWrapper } from "./styles";
import RadioField from "./formik fields/RadioField";
import Tooltip, { TooltipProps } from "./Tooltip";
import styled from "styled-components";
import Colors from "../../../styles/Colors";

interface Props {
  title: string;
  name: string;
  options: { label: string; value: string }[];
  onChange?: (value: string, name: string) => void;
  toolTipProps?: TooltipProps;
  coloredLabel?: string;
  column?: boolean;
}

const RadioComp: FC<Props> = ({
  title,
  name,
  options,
  onChange,
  toolTipProps,
  coloredLabel,
  column,
}) => {
  return (
    <RadioWrapper>
      <RadioLabel>
        <div>
          {title} <Colored>{coloredLabel && coloredLabel}</Colored>
        </div>
      </RadioLabel>
      <RadioFlex column={column}>
        {options.map((ev, i) => (
          <RadioField
            key={i}
            name={name}
            value={ev.value}
            label={ev.label}
            onChange={onChange}
          />
        ))}
        <Mini>
          {toolTipProps && (
            <Tooltip toolTipProps={toolTipProps} parentName={name} />
          )}
        </Mini>
      </RadioFlex>
    </RadioWrapper>
  );
};

export default RadioComp;

const Mini = styled.div`
  margin-top: -2rem;
`;

const Colored = styled.span`
  color: ${Colors.RedDA};
`;
