import styled from "styled-components";
import { FieldArray, Form, Formik } from "formik";
import { Sub, Title } from "./styles";
import { Modify, SingleOption } from "../../../../types/types";
import DatePickerField from "../../components/formik fields/DatePickerField";
import {
  childRelationshipData,
  childResidenceData,
  childResidenceValues,
} from "./data";
import { N400ChildrenInfoObj } from "../../../../api/n400/types";
import { Dayjs } from "dayjs";
import {
  useGetN400ChildrenInfo,
  useSaveN400ChildrenInfo,
} from "../../../../hooks/n400/useN400";
import FormBtn from "../../components/FormBtn";
import FormSectionWrapper from "../../components/FormSectionWrapper";
import { ChildrenInfoValidation } from "./validation";
import {
  ApartmentSuiteFloorData,
  GenericYesNo,
  SpecialSeparator,
  ToSaveDate,
  USDateFormat,
  USDatePlaceholderFormat,
  formatMultiStates,
  getSelectedOption,
  initDate,
  isZipCode,
} from "../../components/data";
import GroupedSection from "../../components/GroupedSection";
import useToNextSection from "../../../../hooks/useToNextSection";
import InputField from "../../components/formik fields/InputField";
import SelectField from "../../components/formik fields/SelectField";
import RadioComp from "../../components/RadioComp";
import ChildCurrentAddress from "./ChildCurrentAddress";
import { FormWrapper } from "../../components/styles";
import useMultiStatesByCountry from "./useMultiStatesByCountry";

const initialChildrenInfoArray = {
  firstName: "",
  lastName: "",
  dateOfBirth: null,
  residence: null,
  childRelationshipToYou: null,
  areYouProvidingSupport: "",
  inCareOfName: "",
  apartmentSuiteOrFloor: null,
  country: null,
  province: "",
  addressCode: "",
  streetNumberAndName: "",
  addressNumber: "",
  cityOrTown: "",
  stateOrTerritory: null,
};

interface childrenInfoArrayPropsPayLoad {
  firstName: string;
  lastName: string;
  dateOfBirth: Dayjs | null;
  residence: SingleOption | null;
  childRelationshipToYou: SingleOption | null;
  areYouProvidingSupport: string;
  inCareOfName: string;
  apartmentSuiteOrFloor: SingleOption | null;
  country: SingleOption | null;
  province: string;
  addressCode: string;
  streetNumberAndName: string;
  addressNumber: string;
  cityOrTown: string;
  stateOrTerritory: SingleOption | null;
}

export interface ChildrenInfoData
  extends Modify<
    Omit<
      N400ChildrenInfoObj,
      | "name"
      | "dateOfBirth"
      | "residence"
      | "childRelationshipToYou"
      | "areYouProvidingSupport"
      | "inCareOfName"
      | "apartmentSuiteOrFloor"
      | "country"
      | "postalCode"
      | "province"
      | "zipCode"
      | "streetNumberAndName"
      | "addressNumber"
      | "cityOrTown"
      | "stateOrTerritory"
    >,
    {
      childrenInforArray: childrenInfoArrayPropsPayLoad[];
    }
  > {}

const ChildrenInfomation = () => {
  const { toNextSection } = useToNextSection();
  const { data, isFetching, isError } = useGetN400ChildrenInfo();
  const { mutate, isLoading: isSaving } = useSaveN400ChildrenInfo();
  const { statesArray } = useMultiStatesByCountry(data?.country);

  const savedChildrenInfoArray = () => {
    if (data?.name) {
      const firstNameData = data?.name
        .split(",")
        .map((ev) => ({ firstName: ev?.split(" ")[0] }));

      const lastNameData = data?.name
        .split(",")
        .map((ev) => ({ lastName: ev?.split(" ")[1] }));

      const dateOfBirthData = data?.dateOfBirth
        .split(",")
        .map((ev) => ({ dateOfBirth: initDate(ev) }));

      const residenceData = data?.residence.split(",").map((ev) => ({
        residence: getSelectedOption(childResidenceData, ev),
      }));

      const childRelationshipToYouData = data?.childRelationshipToYou
        .split(",")
        .map((ev) => ({
          childRelationshipToYou: getSelectedOption(childRelationshipData, ev),
        }));

      const areYouProvidingSupportData = data?.areYouProvidingSupport
        .split(",")
        .map((ev) => ({ areYouProvidingSupport: ev }));
      //
      const inCareOfNameData = data?.inCareOfName
        ?.split(SpecialSeparator)
        .map((ev) => ({ inCareOfName: ev }));

      const apartmentSuiteOrFloorData = data?.apartmentSuiteOrFloor
        ?.split(",")
        .map((ev) => ({
          apartmentSuiteOrFloor: getSelectedOption(ApartmentSuiteFloorData, ev),
        }));

      const countryArray = data?.country?.split(",");
      // permanent patch for now
      const countryArrayData = countryArray?.map((ev) => ({
        country: { value: ev, label: ev },
      }));
      //p

      const provinceData = data?.province
        ?.split(",")
        .map((ev) => ({ province: ev }));
      //z
      const streetNumberAndNameData = data?.streetNumberAndName
        ?.split(SpecialSeparator)
        .map((ev) => ({ streetNumberAndName: ev }));

      const addressNumberData = data?.addressNumber
        ?.split(",")
        .map((ev) => ({ addressNumber: ev }));

      const cityOrTownData = data?.cityOrTown
        ?.split(",")
        .map((ev) => ({ cityOrTown: ev }));

      // permanent patch for now
      const stateOrTerritoryData = formatMultiStates({
        dataKey: data?.stateOrTerritory,
        fieldKey: "stateOrTerritory",
        dataArray: statesArray,
      }) as { stateOrTerritory: SingleOption }[];

      const addressCodeData = data?.zipCode?.split(",").map((ev, index) => ({
        addressCode: isZipCode(countryArray[index])
          ? ev
          : data?.postalCode?.split(",")[index],
      }));

      return firstNameData.map((ev, i) => ({
        ...ev,
        ...lastNameData[i],
        ...dateOfBirthData[i],
        ...residenceData[i],
        ...areYouProvidingSupportData[i],
        ...streetNumberAndNameData[i],
        ...cityOrTownData[i],
        ...apartmentSuiteOrFloorData[i],
        ...countryArrayData[i],
        ...inCareOfNameData[i],
        ...stateOrTerritoryData[i],
        ...provinceData[i],
        ...childRelationshipToYouData[i],
        ...addressNumberData[i],
        ...addressCodeData[i],
      }));
    } else return [initialChildrenInfoArray];
  };

  const onSubmit = (values: ChildrenInfoData) => {
    const { childrenInforArray, numOfChildren, ...rest } = values;

    // if (!countryOfBirth || !childRelationshipToYou) return;

    const payload = {
      ...rest,
      numOfChildren,
      name: childrenInforArray
        .map((ev) => ev.firstName + " " + ev.lastName)
        .join(","),
      dateOfBirth: childrenInforArray
        .map((ev) => ToSaveDate(ev.dateOfBirth, USDateFormat))
        .join(","),
      residence: childrenInforArray
        .map((ev) => ev.residence?.value || "")
        .join(","),
      childRelationshipToYou: childrenInforArray
        .map((ev) => ev.childRelationshipToYou?.value || "")
        .join(","),
      areYouProvidingSupport: childrenInforArray
        .map((ev) => ev.areYouProvidingSupport)
        .join(","),
      inCareOfName: childrenInforArray
        .map((ev) => ev.inCareOfName)
        .join(SpecialSeparator),
      streetNumberAndName: childrenInforArray
        .map((ev) => ev.streetNumberAndName)
        .join(SpecialSeparator),
      cityOrTown: childrenInforArray.map((ev) => ev.cityOrTown).join(","),
      apartmentSuiteOrFloor: childrenInforArray
        .map((ev) => ev.apartmentSuiteOrFloor?.value || "")
        .join(","),
      country: childrenInforArray
        .map((ev) => ev.country?.value || "")
        .join(","),
      province: childrenInforArray.map((ev) => ev.province).join(","),
      stateOrTerritory: childrenInforArray
        .map((ev) => ev.stateOrTerritory?.value || "")
        .join(","),
      zipCode: childrenInforArray
        .map((ev) => (isZipCode(ev.country?.value) ? ev.addressCode : ""))
        .join(","),
      postalCode: childrenInforArray
        .map((ev) => (!isZipCode(ev.country?.value) ? ev.addressCode : ""))
        .join(","),
      addressNumber: childrenInforArray.map((ev) => ev.addressNumber).join(","),
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValue: ChildrenInfoData = {
    numOfChildren: data?.numOfChildren || "",
    childrenInforArray: savedChildrenInfoArray(),
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValue}
          onSubmit={onSubmit}
          validationSchema={ChildrenInfoValidation}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <div>
                  <InputField
                    name="numOfChildren"
                    label="How many children do you have?"
                    placeholder=""
                    onChange={(value) => {
                      const init = [];
                      for (let index = 0; index < Number(value); index++) {
                        init.push(initialChildrenInfoArray);
                      }
                      formik.setFieldValue("childrenInforArray", [...init]);
                    }}
                  />
                </div>

                <div></div>
              </FormWrapper>

              {Number(formik.values.numOfChildren) >= 1 && (
                <>
                  <div>
                    <Sub>Provide information about each of your children</Sub>

                    <FieldArray
                      name="childrenInforArray"
                      render={(arrayHelpers) => (
                        <div className="px-2 py-3 border rounded">
                          {formik.values.childrenInforArray.map((_, index) => (
                            <GroupedSection
                              index={index}
                              key={index}
                              onCancel={() => arrayHelpers.remove(index)}
                              removeCancel
                            >
                              <Title>
                                Child{" "}
                                {formik.values.childrenInforArray.length === 1
                                  ? "1"
                                  : index + 1}{" "}
                                Information{" "}
                              </Title>

                              <FormWrapper>
                                <InputField
                                  label="Given Name"
                                  coloredLabel="(First Name)"
                                  placeholder=""
                                  name={`childrenInforArray[${index}].firstName`}
                                />

                                <InputField
                                  label="Family Name"
                                  coloredLabel="(Last Name)"
                                  placeholder=""
                                  name={`childrenInforArray[${index}].lastName`}
                                />
                              </FormWrapper>

                              <FormWrapper>
                                <DatePickerField
                                  name={`childrenInforArray[${index}].dateOfBirth`}
                                  label="Date of Birth"
                                  placeholder={USDatePlaceholderFormat}
                                  disableFuture={true}
                                />

                                <SelectField
                                  name={`childrenInforArray[${index}].residence`}
                                  label="Residence"
                                  placeholder="-Select-"
                                  options={childResidenceData}
                                />
                              </FormWrapper>

                              {formik.values.childrenInforArray[index].residence
                                ?.value ===
                                childResidenceValues[
                                  "does not reside with me"
                                ] && (
                                <>
                                  <ChildCurrentAddress
                                    data={data}
                                    formik={formik}
                                    index={index}
                                  />
                                </>
                              )}

                              <FormWrapper>
                                <SelectField
                                  name={`childrenInforArray[${index}].childRelationshipToYou`}
                                  label="What is your child's relationship to you?"
                                  placeholder="-Select-"
                                  options={childRelationshipData}
                                />

                                <div>
                                  <RadioComp
                                    title="Are you providing support for this child?"
                                    name={`childrenInforArray[${index}].areYouProvidingSupport`}
                                    options={GenericYesNo}
                                  />
                                </div>
                              </FormWrapper>
                            </GroupedSection>
                          ))}
                        </div>
                      )}
                    />
                  </div>
                </>
              )}

              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default ChildrenInfomation;

const Wrapper = styled.div``;
