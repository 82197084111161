import { FC } from "react";
import ItemAction from "../../../../../components/table/ItemAction";
import useOpener from "../../../../../../../hooks/useOpener";
import { GetBusinessUsersObj } from "../../../../../../../api/business/users/types";
import CreateUser from "./CreateUser";
import { useSearchParams } from "react-router-dom";

interface Props {
  data: GetBusinessUsersObj;
}

const UsersAction: FC<Props> = ({ data }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  // const {
  //   open: detailsOpen,
  //   onClose: onDetailsClose,
  //   onOpen: onDetailsOpen,
  // } = useOpener();
  const {
    open: editOpen,
    onClose: onEditClose,
    onOpen: onEditOpen,
  } = useOpener();

  const actionOptions = () => [
    {
      name: "View",
      onClick: () => {
        searchParams.set("userId", data.id);
        setSearchParams(searchParams);
      },
    },
    {
      name: "Edit",
      onClick: onEditOpen,
    },
  ];

  return (
    <>
      <ItemAction options={actionOptions()} />

      {/* {detailsOpen && (
        <TableDetails
          onClose={onDetailsClose}
          tableData={UsersTableData}
          mainText={"User"}
          dataItems={data}
        />
      )} */}

      {editOpen && <CreateUser onClose={onEditClose} data={data} />}
    </>
  );
};

export default UsersAction;
