import React, { FC } from "react";
import { saveDS160TravelCompanionsObj } from "../../../../../../api/ds160/types";
import ReviewBox from "../../../../components/Review/ReviewBox";
import { FormDS160Progress } from "../../types";
import { GenericDs160ReviewProps } from "./DownloadPage";
import PdfReviewBox from "../../../../components/Review/Pdf Review/PdfReviewBox";
import { ReviewTravelCompSection } from "./data";

interface Props extends GenericDs160ReviewProps {
  data?: saveDS160TravelCompanionsObj;
}

const ReviewTravelComp: FC<Props> = ({ title, data, isDownload }) => {
  const section = data ? ReviewTravelCompSection(data) : [];

  const compProps = {
    title: title,
    data: section,
    editUrl: FormDS160Progress.travelCompanions,
  };

  return isDownload ? (
    <PdfReviewBox {...compProps} />
  ) : (
    <ReviewBox {...compProps} />
  );
};

export default ReviewTravelComp;
