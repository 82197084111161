import { Form, Formik } from "formik";
import styled from "styled-components";
import {
  ApartmentSuiteFloorData,
  GenericYesNo,
  initInput,
  initSelect,
  MaritalStatusData,
} from "../../../components/data";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { FormWrapper, ModuleTitle } from "../../../components/styles";
import RadioComp from "../../../components/RadioComp";
// import { N600EligibilityBasis } from "../data";
import FormBtn from "../../../components/FormBtn";
import InputField from "../../../components/formik fields/InputField";
import SelectField from "../../../components/formik fields/SelectField";
import { N600InfoAboutYouPart2Payload } from "../../../../../api/n600/types";
import { Modify, SingleOption } from "../../../../../types/types";
import useCountries from "../../../components/useCountries";
import useStatesByCountry from "../../../components/useStatesByCountry";
import {
  useGetN600InfoAboutYouPart2,
  useSaveN600InfoAboutYouPart2,
} from "../../../../../hooks/n600/useN600";
import useToNextSection from "../../../../../hooks/useToNextSection";
import { InfoAboutYouPart2Validation } from "../validation";

export interface N600InfoAboutYouPart2Data
  extends Modify<
    N600InfoAboutYouPart2Payload,
    {
      mailingAptSteFlr: SingleOption | null;
      physicalAptSteFlr: SingleOption | null;

      mailingCountry: SingleOption | null;
      physicalCountry: SingleOption | null;
      mailingState: SingleOption | null;
      physicalState: SingleOption | null;

      maritalStatus: SingleOption | null;
    }
  > {}

const InfoAboutYou2 = () => {
  const { data, isFetching, isError } = useGetN600InfoAboutYouPart2();
  const { mutate, isLoading: isSaving } = useSaveN600InfoAboutYouPart2();
  const { toNextSection } = useToNextSection();

  const {
    formattedCountryData: mailingCountryData,
    countryLoading: mailingCountryLoading,
  } = useCountries();

  const {
    formattedCountryData: physicalCountryData,
    countryLoading: physicalCountryLoading,
  } = useCountries();

  const {
    formattedStateData: mailingStateData,
    stateLoading: mailingStateLoading,
    setCountry: mailingSetCountry,
    selectedCountry: mailingSelectedCountry,
  } = useStatesByCountry(data?.mailingCountry || "United States");

  const {
    formattedStateData: physicalStateData,
    stateLoading: physicalStateLoading,
    setCountry: physicalSetCountry,
    selectedCountry: physicalSelectedCountry,
  } = useStatesByCountry(data?.mailingCountry || "United States");

  const onSubmit = (values: N600InfoAboutYouPart2Data) => {
    const {
      mailingAptSteFlr,
      physicalAptSteFlr,
      mailingCountry,
      physicalCountry,
      mailingState,
      physicalState,
      maritalStatus,
      ...rest
    } = values;

    const payload = {
      ...rest,
      mailingAptSteFlr: mailingAptSteFlr?.value || "",
      physicalAptSteFlr: physicalAptSteFlr?.value || "",
      mailingCountry: mailingCountry?.value || "",
      physicalCountry: physicalCountry?.value || "",
      mailingState: mailingState?.value || "",
      physicalState: physicalState?.value || "",
      maritalStatus: maritalStatus?.value || "",
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValues: N600InfoAboutYouPart2Data = {
    mailingInCareOfName: initInput(data?.mailingInCareOfName), // In Care Of Name (if any)
    mailingStreetNumberName: initInput(data?.mailingStreetNumberName), // Street Number and Name
    mailingAptSteFlr: initSelect(
      ApartmentSuiteFloorData,
      data?.mailingAptSteFlr
    ), // Apt. Ste. Flr.
    mailingAptSteFlrNumber: initInput(data?.mailingAptSteFlrNumber), // Apt. Ste. Flr. Number
    mailingCityOrTown: initInput(data?.mailingCityOrTown), // City or Town
    mailingState: initSelect(mailingStateData, data?.mailingState), // State
    mailingZipCode: initInput(data?.mailingZipCode), // ZIP Code
    mailingProvince: initInput(data?.mailingProvince), // Province (foreign address only)
    mailingPostalCode: initInput(data?.mailingPostalCode), // Postal Code (foreign address only)
    mailingCountry: initSelect(mailingCountryData, data?.mailingCountry), // Country (foreign address only)

    physicalStreetNumberName: initInput(data?.physicalStreetNumberName), // Street Number and Name
    physicalAptSteFlr: initSelect(
      ApartmentSuiteFloorData,
      data?.physicalAptSteFlr
    ), // Apt. Ste. Flr.
    physicalAptSteFlrNumber: initInput(data?.physicalAptSteFlrNumber), // Apt. Ste. Flr. Number
    physicalCityOrTown: initInput(data?.physicalCityOrTown), // City or Town
    physicalState: initSelect(physicalStateData, data?.physicalState), // State
    physicalZipCode: initInput(data?.physicalZipCode), // ZIP Code
    physicalProvince: initInput(data?.physicalProvince), // Province (foreign address only)
    physicalPostalCode: initInput(data?.physicalPostalCode), // Postal Code (foreign address only)
    physicalCountry: initSelect(physicalCountryData, data?.physicalCountry), // Country (foreign address only)

    maritalStatus: initSelect(MaritalStatusData, data?.maritalStatus),
    maritalStatusExp: initInput(data?.maritalStatusExp),
    usArmedForces: initInput(data?.usArmedForces),

    applicantsDaytimeTelephoneNumber: initInput(
      data?.applicantsDaytimeTelephoneNumber
    ),
    applicantsMobileTelephoneNumber: initInput(
      data?.applicantsMobileTelephoneNumber
    ),
    applicantsEmailAddress: initInput(data?.applicantsEmailAddress),
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={InfoAboutYouPart2Validation}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <ModuleTitle>Mailing Address</ModuleTitle>

              <FormWrapper>
                <div>
                  <InputField
                    label="In Care of Name"
                    coloredLabel=" (if any)"
                    placeholder=""
                    name={`mailingInCareOfName`}
                  />
                </div>
                <div>
                  <InputField
                    label="Street Number and Name"
                    placeholder=""
                    name={`mailingStreetNumberName`}
                  />
                </div>
              </FormWrapper>

              <FormWrapper>
                <div>
                  <SelectField
                    label="Apartment Suite or Floor"
                    coloredLabel=" (if any)"
                    options={ApartmentSuiteFloorData}
                    placeholder=""
                    name={`mailingAptSteFlr`}
                  />
                </div>

                <div>
                  {formik.values.mailingAptSteFlr?.value && (
                    <InputField
                      label="Apartment Suite or Floor Number"
                      placeholder=""
                      name={`mailingAptSteFlrNumber`}
                    />
                  )}
                </div>
              </FormWrapper>

              <FormWrapper>
                <div>
                  <InputField
                    label="City or Town"
                    placeholder=""
                    name={`mailingCityOrTown`}
                  />
                </div>
                <div>
                  <InputField
                    label="ZIP Code"
                    placeholder=""
                    name={`mailingZipCode`}
                  />
                </div>
              </FormWrapper>

              <FormWrapper>
                <div>
                  <SelectField
                    name="mailingCountry"
                    label="Country"
                    coloredLabel="(foreign address only)"
                    placeholder="-Select-"
                    options={mailingCountryData}
                    isLoading={mailingCountryLoading}
                    onChange={(value) => {
                      // console.log(value);
                      // console.log(formik.values.mailingCountry?.value);
                      mailingSetCountry(value.label);
                      formik.setFieldValue("mailingState", null);
                      formik.setFieldValue(`mailingPostalCode`, "");
                      formik.setFieldValue(`mailingProvince`, "");
                    }}
                  />
                </div>

                <div>
                  <SelectField
                    name={`mailingState`}
                    label="State"
                    placeholder="-Select-"
                    options={mailingStateData}
                    isLoading={mailingStateLoading}
                    isDisabled={!mailingSelectedCountry}
                  />
                </div>
              </FormWrapper>

              <FormWrapper>
                <div>
                  <InputField
                    label="Province"
                    coloredLabel="(foreign address only)"
                    placeholder=""
                    name={`mailingProvince`}
                    isDisabled={
                      !formik.values.mailingCountry?.value ||
                      formik.values.mailingCountry?.value === "United States"
                    }
                  />
                </div>

                <div>
                  <InputField
                    label={`Postal Code`}
                    placeholder=""
                    coloredLabel="(foreign address only)"
                    name={`mailingPostalCode`}
                    isDisabled={
                      !formik.values.mailingCountry?.value ||
                      formik.values.mailingCountry?.value === "United States"
                    }
                  />
                </div>
              </FormWrapper>

              <ModuleTitle>Physical Address</ModuleTitle>

              <FormWrapper>
                <div>
                  <InputField
                    label="Street Number and Name"
                    placeholder=""
                    name={`physicalStreetNumberName`}
                  />
                </div>
                <div></div>
              </FormWrapper>

              <FormWrapper>
                <div>
                  <SelectField
                    label="Apartment Suite or Floor"
                    coloredLabel=" (if any)"
                    options={ApartmentSuiteFloorData}
                    placeholder=""
                    name={`physicalAptSteFlr`}
                  />
                </div>

                <div>
                  {formik.values.physicalAptSteFlr?.value && (
                    <InputField
                      label="Apartment Suite or Floor Number"
                      placeholder=""
                      name={`physicalAptSteFlrNumber`}
                    />
                  )}
                </div>
              </FormWrapper>

              <FormWrapper>
                <div>
                  <InputField
                    label="City or Town"
                    placeholder=""
                    name={`physicalCityOrTown`}
                  />
                </div>
                <div>
                  <InputField
                    label="ZIP Code"
                    placeholder=""
                    name={`physicalZipCode`}
                  />
                </div>
              </FormWrapper>

              <FormWrapper>
                <div>
                  <SelectField
                    name="physicalCountry"
                    label="Country"
                    coloredLabel="(foreign address only)"
                    placeholder="-Select-"
                    options={physicalCountryData}
                    isLoading={physicalCountryLoading}
                    onChange={(value) => {
                      // console.log(value);
                      // console.log(formik.values.physicalCountry?.value);
                      physicalSetCountry(value.label);
                      formik.setFieldValue("physicalState", null);
                      formik.setFieldValue(`physicalPostalCode`, "");
                    }}
                  />
                </div>

                <div>
                  <SelectField
                    name={`physicalState`}
                    label="State"
                    placeholder="-Select-"
                    options={physicalStateData}
                    isLoading={physicalStateLoading}
                    isDisabled={!physicalSelectedCountry}
                  />
                </div>
              </FormWrapper>

              <FormWrapper>
                <div>
                  <InputField
                    label="Province"
                    coloredLabel="(foreign address only)"
                    placeholder=""
                    name={`physicalProvince`}
                    isDisabled={
                      !formik.values.physicalCountry?.value ||
                      formik.values.physicalCountry?.value === "United States"
                    }
                  />
                </div>

                <div>
                  <InputField
                    label={`Postal Code`}
                    placeholder=""
                    coloredLabel="(foreign address only)"
                    name={`physicalPostalCode`}
                    isDisabled={
                      !formik.values.physicalCountry?.value ||
                      formik.values.physicalCountry?.value === "United States"
                    }
                  />
                </div>
              </FormWrapper>

              <FormWrapper>
                <SelectField
                  label="Current Marital Status"
                  options={MaritalStatusData}
                  name={`maritalStatus`}
                />

                <div>
                  {formik.values.maritalStatus?.value === "Other" && (
                    <InputField
                      label={`Explain`}
                      placeholder=""
                      name={`maritalStatusExp`}
                    />
                  )}
                </div>
              </FormWrapper>

              <FormWrapper>
                <RadioComp
                  title="Are you a member or veteran of any branch of the U.S. Armed Forces?"
                  name={`usArmedForces`}
                  options={GenericYesNo}
                />

                <div></div>
              </FormWrapper>

              <ModuleTitle>Applicant Contact Info</ModuleTitle>

              <FormWrapper>
                <InputField
                  name="applicantsDaytimeTelephoneNumber"
                  label="Applicant's Daytime Telephone Number"
                  placeholder=""
                />
                <InputField
                  name="applicantsMobileTelephoneNumber"
                  label="Applicant's Mobile Telephone Number"
                  coloredLabel="(if any)"
                  placeholder=""
                />
              </FormWrapper>

              <FormWrapper>
                <InputField
                  name="applicantsEmailAddress"
                  label="Applicant's Email Address"
                  coloredLabel="(if any)"
                  placeholder=""
                />
                <div></div>
              </FormWrapper>

              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default InfoAboutYou2;

const Wrapper = styled.div``;
