import { Form, Formik } from "formik";
import React, { useState } from "react";
import styled from "styled-components";
import Colors from "../../../../styles/Colors";
import InputField from "../../components/formik fields/InputField";
import { useSaveDS160VistorEmail } from "../../../../hooks/ds160/usDS160";
import { toast } from "react-toastify";
import { Ds160InfoContentValidation } from "./validation";
import Spinner from "../../../../utils/Spinner";
import { mediaObj } from "../../../../styles/Media";

const Ds160InfoContent = () => {
  const [hide, setHide] = useState(false);
  const { mutate, isLoading } = useSaveDS160VistorEmail();

  return (
    <>
      {/* <a
        href="https://messenger.yablo.io/seamlessvisa.com/gubijgvxcptlfje"
        target="_blank"
        rel="noreferrer"
        style={{
          textDecoration: "underline",
        }}
      >
        Click here
      </a>{" "}
      for a personalized consultation with our visa experts who can help you
      explore all your options. */}
      We understand navigating visa options can be confusing. Let's work
      together to find the right visa for you! <br />
      <br /> Kindly provide your email below for a personalized consultation
      with our visa experts who can help you explore all your options
      <Formik
        initialValues={{ email: "" }}
        validationSchema={Ds160InfoContentValidation}
        onSubmit={(values, { resetForm }) => {
          const payload = {
            email: values.email,
            phone: "",
            eligibilityStatus: "",
          };

          mutate(payload, {
            onSuccess: () => {
              toast.success("Email saved successfully!");
              setHide(true);
              resetForm();
            },
          });
        }}
      >
        <Form>
          {!hide && (
            <FormWrapper>
              <InputField
                name="email"
                label=""
                placeholder="Enter your email here"
              />
              <ContinueBtn>
                {isLoading && <Spinner color={Colors.White} size={14} />}
                Submit
              </ContinueBtn>
            </FormWrapper>
          )}
        </Form>
      </Formik>
    </>
  );
};

export default Ds160InfoContent;

const FormWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-top: 16px;

  ${mediaObj.mobile} {
    flex-direction: column;
  }
`;

const Btn = styled.button`
  padding: 10px 46px;
  text-transform: uppercase;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  ${mediaObj.mobile} {
    width: 100%;
  }
`;

const ContinueBtn = styled(Btn)`
  border-radius: 8px;
  background-color: ${Colors.Blue00};
  color: white;
`;
