import React, { FC } from "react";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import styled from "styled-components";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import { FormWrapper } from "../../../components/styles";
import SelectField from "../../../components/formik fields/SelectField";
import {
  ApplicantInfoDateLPRCondition,
  ApplicantInfoDateOfBirthCondition,
  ApplicantInfoGender,
  ApplicantInfoIsLprCondition,
} from "../data";
import Colors from "../../../../../styles/Colors";
import { Title } from "../styles";
import { ApplicantInfoValidation } from "../validation";
import InputField from "../../../components/formik fields/InputField";
import RadioComp from "../../../components/RadioComp";
import {
  GenericYesNo,
  GenericYesNoOptionsValue,
  ToSaveDate,
  USDatePlaceholderFormat,
  getSelectedOption,
  initDate,
  initInput,
  initSelect,
} from "../../../components/data";
import FormBtn from "../../../components/FormBtn";
import DatePickerField from "../../../components/formik fields/DatePickerField";
import useCountries from "../../../components/useCountries";
import { Modify, SingleOption } from "../../../../../types/types";
import { Dayjs } from "dayjs";
import {
  N400ApplicantInfo1Obj,
  N400EligibilityPayload,
} from "../../../../../api/n400/types";
import { TooltipProps, TooltipType } from "../../../components/Tooltip";
import GroupedSection from "../../../components/GroupedSection";
import AddAnotherBtn from "../../../components/AddAnotherBtn";
import { toast } from "react-toastify";
import {
  useGetN400ApplicantInfo1,
  useSaveN400ApplicantInfo1,
} from "../../../../../hooks/n400/useN400";
import useToNextSection from "../../../../../hooks/useToNextSection";

export interface otherNamesArrayPropsPayload {
  otherNamesFirstName: string;
  otherNamesMiddleName: string;
  otherNamesLastName: string;
}

export interface dateOfBirthArrayPropsPayload {
  dateOfBirth: Dayjs | null;
}

export interface countryOfCitizenshipArrayPropsPayload {
  countryOfCitizenship: SingleOption | null;
}

const initialOtherNamesArray: otherNamesArrayPropsPayload = {
  otherNamesFirstName: "",
  otherNamesMiddleName: "",
  otherNamesLastName: "",
};

const initialDateOfBirthArray: dateOfBirthArrayPropsPayload = {
  dateOfBirth: null,
};

const initialCountryOfCitizenshipArray: countryOfCitizenshipArrayPropsPayload =
  {
    countryOfCitizenship: null,
  };

export interface N400ApplicantInfo1Data
  extends Modify<
    Omit<
      N400ApplicantInfo1Obj,
      | "otherNamesFirstName"
      | "otherNamesMiddleName"
      | "otherNamesLastName"
      | "dateOfBirth"
      | "countryOfCitizenship"
    >,
    {
      dateLPR: Dayjs | null;
      dateLPR_risky_checkbox: boolean;
      isLPR_risky_checkbox: boolean;
      countryOfBirth: SingleOption | null;
      otherNamesArray: otherNamesArrayPropsPayload[];
      dateOfBirthArray: dateOfBirthArrayPropsPayload[];
      countryOfCitizenshipArray: countryOfCitizenshipArrayPropsPayload[];
    }
  > {}

interface Props {
  formEligibility: N400EligibilityPayload;
}

const ApplicantInfo1: FC<Props> = ({ formEligibility }) => {
  const { toNextSection } = useToNextSection();
  const { data, isFetching, isError } = useGetN400ApplicantInfo1();
  const { mutate, isLoading: isSaving } = useSaveN400ApplicantInfo1();

  const { formattedCountryData, countryLoading } = useCountries();
  const {
    formattedCountryData: nationCountryData,
    countryLoading: nationCountryLoading,
  } = useCountries();

  const savedOtherNamesArray = () => {
    if (data?.otherNamesFirstName) {
      const otherNamesFirstNameData = data?.otherNamesFirstName
        .split(",")
        .map((ev: any) => ({ otherNamesFirstName: ev }));
      const otherNamesMiddleNameData = data?.otherNamesMiddleName
        .split(",")
        .map((ev: any) => ({ otherNamesMiddleName: ev }));

      const otherNamesLastNameData = data?.otherNamesLastName
        .split(",")
        .map((ev: any) => ({ otherNamesLastName: ev }));

      return otherNamesFirstNameData.map((ev: any, i: any) => ({
        ...ev,
        ...otherNamesMiddleNameData[i],
        ...otherNamesLastNameData[i],
      }));
    } else return [initialOtherNamesArray];
  };

  const savedDateOfBirthArray = () => {
    if (data?.dateOfBirth) {
      const dateOfBirthData = data?.dateOfBirth
        .split(",")
        .map((ev: any) => ({ dateOfBirth: initDate(ev) }));

      return dateOfBirthData.map((ev: any, i: any) => ({
        ...ev,
      }));
    } else return [initialDateOfBirthArray];
  };

  const savedCountryOfCitizenshipArray = () => {
    if (data?.countryOfCitizenship) {
      const countryOfCitizenshipData = data?.countryOfCitizenship
        .split(",")
        .map((ev: any) => ({
          countryOfCitizenship: getSelectedOption(nationCountryData, ev),
        }));

      return countryOfCitizenshipData.map((ev: any, i: any) => ({
        ...ev,
      }));
    } else return [initialCountryOfCitizenshipArray];
  };

  const initialValues: N400ApplicantInfo1Data = {
    firstName: initInput(data?.firstName),
    middleName: initInput(data?.middleName),
    lastName: initInput(data?.lastName),
    otherNames: initInput(data?.otherNames),
    anyNewNameFirstName: initInput(data?.otherNamesFirstName),
    anyNewNameMiddleName: initInput(data?.otherNamesMiddleName),
    anyNewNameLastName: initInput(data?.otherNamesLastName),
    anyNewName: initInput(data?.anyNewName),
    otherNamesArray: savedOtherNamesArray(),
    uscisAcctNum: initInput(data?.uscisAcctNum),
    gender: initInput(data?.gender),
    isLPR: initInput(data?.isLPR),
    dateLPR: initDate(data?.dateLPR),
    dateLPR_risky_checkbox: false,
    isLPR_risky_checkbox: false,
    countryOfBirth: initSelect(formattedCountryData, data?.countryOfBirth),
    dateOfBirthArray: savedDateOfBirthArray(),
    countryOfCitizenshipArray: savedCountryOfCitizenshipArray(),
  };

  const onSubmit = (values: N400ApplicantInfo1Data) => {
    const {
      dateLPR,
      dateOfBirthArray,
      countryOfBirth,
      countryOfCitizenshipArray,
      otherNamesArray,
      dateLPR_risky_checkbox,
      isLPR_risky_checkbox,
      ...rest
    } = values;

    if (!countryOfBirth) return;

    const payload = {
      ...rest,
      dateLPR: ToSaveDate(dateLPR),
      otherNamesFirstName: otherNamesArray
        .map((ev) => ev.otherNamesFirstName)
        .join(","),
      otherNamesMiddleName: otherNamesArray
        .map((ev) => ev.otherNamesMiddleName)
        .join(","),
      otherNamesLastName: otherNamesArray
        .map((ev) => ev.otherNamesLastName)
        .join(","),
      dateOfBirth: dateOfBirthArray
        .map((ev) => ToSaveDate(ev.dateOfBirth))
        .join(","),
      countryOfBirth: countryOfBirth.value,
      countryOfCitizenship: countryOfCitizenshipArray
        .map((ev) => ev.countryOfCitizenship?.value || "")
        .join(","),
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const getEligibilityValidation = (
    formik: FormikProps<N400ApplicantInfo1Data>,
    name: string
  ): TooltipProps | undefined => {
    if (name === "isLPR") {
      if (ApplicantInfoIsLprCondition(formik.values, formEligibility))
        return {
          isReversed: true,
          type: TooltipType.risky,
        };
    }

    if (name === "dateLPR") {
      if (ApplicantInfoDateLPRCondition(formik.values, formEligibility))
        return {
          isReversed: true,
          type: TooltipType.risky,
        };
    }
    if (name === `dateOfBirthArray[${0}].dateOfBirth`) {
      const dobValue = formik.values.dateOfBirthArray[0].dateOfBirth;
      if (!dobValue) return;
      if (ApplicantInfoDateOfBirthCondition(dobValue, formEligibility))
        return {
          body: "Your selected date of birth does not match your eligibility data",
          type: TooltipType.deadly,
        };
    }
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Title>Your Current Legal Name (do not provide a nickname)</Title>
        <Formik
          initialValues={initialValues}
          validationSchema={ApplicantInfoValidation(formEligibility)}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <InputField
                  label="Family Name"
                  coloredLabel="(Last Name)"
                  placeholder=""
                  name="lastName"
                />

                <InputField
                  label="Given Name"
                  coloredLabel="(First Name)"
                  placeholder=""
                  name="firstName"
                />
              </FormWrapper>

              <FormWrapper>
                <InputField
                  label="Middle Name"
                  coloredLabel="(if applicable)"
                  placeholder=""
                  name="middleName"
                />
                <div></div>
              </FormWrapper>

              <FormWrapper>
                <RadioComp
                  title="Have you used any other names since birth?"
                  name="otherNames"
                  options={GenericYesNo}
                  onChange={() => {
                    formik.setFieldTouched("otherNamesFirstName", false);
                    formik.setFieldTouched("otherNamesMiddleName", false);
                    formik.setFieldTouched("otherNamesLastName", false);
                  }}
                />
                <div></div>
              </FormWrapper>

              {formik.values.otherNames === GenericYesNoOptionsValue.yes && (
                <>
                  <Note>Other Names You Have Used Since Birth</Note>

                  <FieldArray
                    name="otherNamesArray"
                    render={(arrayHelpers) => (
                      <div className="px-2 py-3 mb-8 border rounded">
                        {formik.values.otherNamesArray.map((_, index) => (
                          <GroupedSection
                            key={index}
                            index={index}
                            onCancel={() => arrayHelpers.remove(index)}
                          >
                            <div className={`text-[${Colors.Blue00}]`}>
                              Entry {index + 1}:
                            </div>

                            <div className="mb-8">
                              <FormWrapper>
                                <InputField
                                  label="Family Name"
                                  coloredLabel="(Last Name)"
                                  placeholder=""
                                  name={`otherNamesArray[${index}].otherNamesLastName`}
                                />
                                <InputField
                                  label="Given Name"
                                  coloredLabel="(First Name)"
                                  placeholder=""
                                  name={`otherNamesArray[${index}].otherNamesFirstName`}
                                />
                              </FormWrapper>

                              <FormWrapper>
                                <InputField
                                  label="Middle Name"
                                  coloredLabel="(if applicable)"
                                  placeholder=""
                                  name={`otherNamesArray[${index}].otherNamesMiddleName`}
                                />
                                <div></div>
                              </FormWrapper>
                            </div>
                          </GroupedSection>
                        ))}
                        <AddAnotherBtn
                          onClick={() => {
                            if (formik.values.otherNamesArray.length === 8) {
                              toast.error("Limit Reached");
                              return;
                            }

                            arrayHelpers.push(initialOtherNamesArray);
                          }}
                        />
                      </div>
                    )}
                  />
                </>
              )}

              <FormWrapper>
                <RadioComp
                  title="Would you like to legally change your name?"
                  name="anyNewName"
                  options={GenericYesNo}
                  onChange={() => {
                    formik.setFieldTouched("anyNewNameFirstName", false);
                    formik.setFieldTouched("anyNewNameMiddleName", false);
                    formik.setFieldTouched("anyNewNameLastName", false);
                  }}
                />
                <div></div>
              </FormWrapper>

              {formik.values.anyNewName === GenericYesNoOptionsValue.yes && (
                <div className="px-2 py-3 mb-8 border rounded">
                  <Note>Provide the new names you would like to use.</Note>
                  <FormWrapper>
                    <InputField
                      label="Family Name"
                      coloredLabel="(Last Name)"
                      placeholder=""
                      name="anyNewNameLastName"
                    />
                    <InputField
                      label="Given Name"
                      coloredLabel="(First Name)"
                      placeholder=""
                      name="anyNewNameFirstName"
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <InputField
                      label="Middle Name"
                      coloredLabel="(if applicable)"
                      placeholder=""
                      name="anyNewNameMiddleName"
                    />
                    <div></div>
                  </FormWrapper>
                </div>
              )}

              <FormWrapper>
                <InputField
                  label="USCIS Online Account Number"
                  coloredLabel="(if any)"
                  placeholder=""
                  name="uscisAcctNum"
                />
                <RadioComp
                  title="Gender"
                  name="gender"
                  options={ApplicantInfoGender}
                />
              </FormWrapper>

              <FormWrapper>
                <div>
                  <Title>Dates of Birth</Title>
                  <TinyNote>
                    In addition to your actual date of birth, include any other
                    dates of birth you have ever used, including dates used in
                    connection with any legal names or non-legal names.
                  </TinyNote>

                  <FieldArray
                    name="dateOfBirthArray"
                    render={(arrayHelpers) => (
                      <div className="px-2 py-3 mb-8 border rounded">
                        {formik.values.dateOfBirthArray.map((_, index) => (
                          <GroupedSection
                            key={index}
                            index={index}
                            onCancel={() => arrayHelpers.remove(index)}
                          >
                            <div className={`text-[${Colors.Blue00}]`}>
                              Entry {index + 1}:
                            </div>

                            <div className="mb-8">
                              <DatePickerField
                                name={`dateOfBirthArray[${index}].dateOfBirth`}
                                label={`Date of birth ${
                                  index + 1 === 1
                                    ? "(Your actual date of birth)"
                                    : ""
                                }`}
                                placeholder={USDatePlaceholderFormat}
                                disableFuture={true}
                                toolTipProps={getEligibilityValidation(
                                  formik,
                                  `dateOfBirthArray[${index}].dateOfBirth`
                                )}
                              />
                            </div>
                          </GroupedSection>
                        ))}
                        <AddAnotherBtn
                          onClick={() => {
                            if (formik.values.dateOfBirthArray.length === 8) {
                              toast.error("Limit Reached");
                              return;
                            }

                            arrayHelpers.push(initialDateOfBirthArray);
                          }}
                        />
                      </div>
                    )}
                  />
                </div>

                <div></div>
              </FormWrapper>

              <FormWrapper>
                <RadioComp
                  name="isLPR"
                  title="Are you a lawful permanent resident?"
                  options={GenericYesNo}
                  toolTipProps={getEligibilityValidation(formik, "isLPR")}
                />

                <div></div>
              </FormWrapper>

              {formik.values.isLPR === GenericYesNoOptionsValue.yes && (
                <FormWrapper>
                  <DatePickerField
                    name="dateLPR"
                    label="Date You Became a Lawful Permanent Resident"
                    placeholder={USDatePlaceholderFormat}
                    disableFuture={true}
                    toolTipProps={getEligibilityValidation(formik, "dateLPR")}
                  />
                  <div></div>
                </FormWrapper>
              )}

              <FormWrapper>
                <SelectField
                  name="countryOfBirth"
                  label="Country of Birth"
                  placeholder="-Select-"
                  options={formattedCountryData}
                  isLoading={countryLoading}
                />

                <div></div>
              </FormWrapper>

              <FormWrapper>
                <div>
                  <Title>Countries of Citizenship or Nationality</Title>
                  <TinyNote>
                    If you are a citizen or national of more than one country,
                    list additional countries of nationality.
                  </TinyNote>

                  <FieldArray
                    name="countryOfCitizenshipArray"
                    render={(arrayHelpers) => (
                      <div className="px-2 py-3 mb-8 border rounded">
                        {formik.values.countryOfCitizenshipArray.map(
                          (_, index) => (
                            <GroupedSection
                              key={index}
                              index={index}
                              onCancel={() => arrayHelpers.remove(index)}
                            >
                              <div className={`text-[${Colors.Blue00}]`}>
                                Entry {index + 1}:
                              </div>

                              <div className="mb-8">
                                <SelectField
                                  name={`countryOfCitizenshipArray[${index}].countryOfCitizenship`}
                                  label="Country of Nationality/Citizenship"
                                  placeholder="-Select-"
                                  options={nationCountryData}
                                  isLoading={nationCountryLoading}
                                />
                              </div>
                            </GroupedSection>
                          )
                        )}
                        <AddAnotherBtn
                          onClick={() => {
                            if (
                              formik.values.countryOfCitizenshipArray.length ===
                              8
                            ) {
                              toast.error("Limit Reached");
                              return;
                            }

                            arrayHelpers.push(initialCountryOfCitizenshipArray);
                          }}
                        />
                      </div>
                    )}
                  />
                </div>

                <div></div>
              </FormWrapper>

              <FormBtn
                isLoading={isSaving}
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default ApplicantInfo1;

const Wrapper = styled.div``;

const Note = styled.div`
  color: ${Colors.Black31};
  font-weight: 600;
  margin-bottom: 30px;
  font-size: 0.875rem;
`;

const TinyNote = styled.div`
  color: ${Colors.Black31};
  font-weight: 300;
  margin-bottom: 8px;
  font-size: 0.875rem;
`;
