import React, { FC } from "react";
import { N565GenderChangedThroughData } from "../data";
import {
  FormWrapper,
  RadioLabel,
  RadioWrapper,
} from "../../../components/styles";
import { Colored } from "../../../form 400/components/styles";
import { FieldArray, FormikProps } from "formik";
import {
  CheckWrap,
  Title,
  TypeOfApplicationFormData,
} from "../TypeOfApplication";
import CheckBoxField from "../../../components/formik fields/CheckBoxField";
import RadioComp from "../../../components/RadioComp";

interface Props {
  formik: FormikProps<TypeOfApplicationFormData>;
}

const Part7: FC<Props> = ({ formik }) => {
  return (
    <div>
      {formik.values.basisForApplicationArray[0].changeGender && (
        <div className="flex flex-col gap-4 ">
          <Title>
            Complete If Applying for a New Document Because of an Official
            Change in Gender
          </Title>
          <RadioWrapper>
            <RadioLabel>
              My gender officially changed through{" "}
              <Colored>(select all applicable boxes)</Colored>
            </RadioLabel>
            <FieldArray
              name="genderChangedThroughArray"
              render={(arrayHelpers) => (
                <>
                  {formik.values.genderChangedThroughArray.map((_, index) => (
                    <CheckWrap key={index}>
                      <CheckBoxField
                        value={"Court Order"}
                        name={`genderChangedThroughArray[${index}].courtOrder`}
                        label={"Court Order"}
                      />
                      <CheckBoxField
                        value={
                          "Government-Issued Document Reflecting the Gender Change"
                        }
                        name={`genderChangedThroughArray[${index}].govIssuedDoc`}
                        label={
                          "Government-Issued Document Reflecting the Gender Change"
                        }
                      />
                      <CheckBoxField
                        value={
                          "Licensed Health Care Professional's Certification of Gender"
                        }
                        name={`genderChangedThroughArray[${index}].licensedHealthCareCertification`}
                        label={
                          "Licensed Health Care Professional's Certification of Gender"
                        }
                      />
                    </CheckWrap>
                  ))}
                </>
              )}
            />
          </RadioWrapper>

          <FormWrapper>
            <RadioComp
              title="My current gender designation is"
              name="genderChangedThroughCurrentGender"
              options={N565GenderChangedThroughData}
            />

            <div></div>
          </FormWrapper>
        </div>
      )}
    </div>
  );
};

export default Part7;
