import { ApiResponse } from "../../types/types";
import { GetFileDetailObj } from "../n400/types";
import request from "../request";
import { PreparerInterpreterObj } from "../utils/types";
import {
  n565TypeOfApplicationObj,
  n565InfoRes,
  payN565Obj,
  N565EligibilityPayload,
  UpdateN565FilesPayload,
  n565DocInfoObj,
  n565CurrentInfoAboutYouObj,
} from "./types";

export const saveN565InfoFromCert = async (payload: n565DocInfoObj) => {
  return await request.post<ApiResponse>(
    `/user/n565-info-from-certificate`,
    payload
  );
};

export const getN565InfoFromCert = async () => {
  return await request.get<ApiResponse<n565DocInfoObj>>(
    `/user/get-n565-info-from-certificate`
  );
};

export const saveN565ApplicantInfo = async (
  payload: n565CurrentInfoAboutYouObj
) => {
  return await request.post<ApiResponse>(
    `/user/n565-info-about-applicant`,
    payload
  );
};

export const getN565ApplicantInfo = async () => {
  return await request.get<ApiResponse<n565CurrentInfoAboutYouObj>>(
    `/user/get-n565-info-about-applicant`
  );
};

export const saveN565ApplicationType = async (
  payload: n565TypeOfApplicationObj
) => {
  return await request.post<ApiResponse>(
    `/user/n565-application-type`,
    payload
  );
};

export const getN565ApplicationType = async () => {
  return await request.get<ApiResponse<n565TypeOfApplicationObj>>(
    `/user/get-n565-application-type`
  );
};

export const getN565Form = async () => {
  return await request.get<ApiResponse>(`/user/get-n565-application`, {
    // responseType: "blob",
  });
};

export const getN565PreparerInterpreter = async () => {
  return await request.get<ApiResponse<PreparerInterpreterObj>>(
    `/user/get-n565-preparer-and-interpreter`
  );
};

export const saveN565PreparerInterpreter = async (
  payload: Partial<PreparerInterpreterObj>
) => {
  return await request.post<ApiResponse>(
    `/user/n565-preparer-and-interpreter`,
    payload
  );
};

export const getN565Info = async () => {
  return await request.get<ApiResponse<n565InfoRes>>(`/user/get-n565-info`);
};

export const payN565 = async (payload: payN565Obj) => {
  return await request.post<ApiResponse>(`user/n565-payment`, payload);
};

export const getN565PaySuccess = async (payId: string) => {
  return await request.get<ApiResponse>(`/user/n565-payment-success/${payId}`);
};

export const getN565PayFail = async () => {
  return await request.get<ApiResponse<n565InfoRes>>(
    `/user/n565-payment-cancel`
  );
};

export const saveN565Eligibility = async (payload: N565EligibilityPayload) => {
  return await request.post<ApiResponse>(`/user/n565-eligibility`, payload);
};

export const getN565Eligibility = async () => {
  return await request.get<ApiResponse<N565EligibilityPayload>>(
    `/user/get-n565-eligibility`
  );
};

export const uploadN565Files = async (payload: FormData) => {
  return await request.post<ApiResponse>(`/user/upload-n565-files`, payload);
};

export const getN565Files = async () => {
  return await request.get<ApiResponse<GetFileDetailObj[]>>(
    `/user/get-upload-n565-files`
  );
};

export const updateN565Files = async (payload: UpdateN565FilesPayload) => {
  return await request.post<ApiResponse>(
    `/user/update-n565-files/${payload.id}/${payload.formCategory}`,
    payload.data
  );
};

export const getN565Trigger = async () => {
  return await request.get(`/user/download-n565-application`);
};
