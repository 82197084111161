import styled from "styled-components";
import { Form, Formik } from "formik";
import {
  n400CheckPart3Res,
  N400EligibilityPayload,
} from "../../../../../api/n400/types";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { FormWrapper } from "../../../components/styles";
import RadioComp from "../../../components/RadioComp";
import {
  GenericYesNo,
  GenericYesNoOptionsValue,
  ToSaveDate,
  initDate,
  initInput,
} from "../../../components/data";
import { getCheckListFieldValidation } from "../data";
import { Title } from "../styles";
import FormBtn from "../../../components/FormBtn";
import {
  useGetN400CheckPart3,
  useSaveN400CheckPart3,
} from "../../../../../hooks/n400/useN400";
import { Modify } from "../../../../../types/types";
import { Sub } from "../../../profile/components/styles";
import DatePickerField from "../../../components/formik fields/DatePickerField";
import InputField from "../../../components/formik fields/InputField";
import { CheckPart3Validation } from "../validation";
import useToNextSection from "../../../../../hooks/useToNextSection";
import Colors from "../../../../../styles/Colors";
import { Dayjs } from "dayjs";

export interface CheckFormData3
  extends Modify<
    n400CheckPart3Res,
    {
      date_registered_for_selective_service: Dayjs | null;
      ever_been_placed_in_deportation_proceedings_risky_checkbox: boolean;
      ever_been_removed_or_deported_risky_checkbox: boolean;
      are_you_a_male_in_the_us_between_18th_26th_risky_checkbox: boolean;
      did_you_register_for_selective_service_risky_checkbox: boolean;
      date_registered_for_selective_service_risky_checkbox: boolean;
      selective_service_number_risky_checkbox: boolean;
      ever_left_the_united_states_to_avoid_being_drafted_risky_checkbox: boolean;
      ever_applied_for_any_kind_of_exemption_from_military_service_risky_checkbox: boolean;
      ever_served_in_armed_forces_risky_checkbox: boolean;
      are_you_currently_a_member_of_armed_forces_risky_checkbox: boolean;
      are_you_scheduled_to_deploy_overseas_risky_checkbox: boolean;
      are_you_currently_stationed_overseas_risky_checkbox: boolean;
      are_you_a_former_military_member_overseas_risky_checkbox: boolean;
      ever_been_court_martialed_risky_checkbox: boolean;
      ever_been_discharged_because_you_were_a_alien_risky_checkbox: boolean;
      ever_deserted_the_armed_forces_risky_checkbox: boolean;
    }
  > {}

interface Props {
  formEligibility: N400EligibilityPayload;
}

const CheckPart3: React.FC<Props> = ({ formEligibility }) => {
  const { toNextSection } = useToNextSection();
  const { data, isFetching, isError } = useGetN400CheckPart3();
  const { mutate, isLoading: isSaving } = useSaveN400CheckPart3();

  const onSubmit = (values: CheckFormData3) => {
    const {
      ever_been_placed_in_deportation_proceedings_risky_checkbox,
      ever_been_removed_or_deported_risky_checkbox,
      are_you_a_male_in_the_us_between_18th_26th_risky_checkbox,
      did_you_register_for_selective_service_risky_checkbox,
      date_registered_for_selective_service_risky_checkbox,
      selective_service_number_risky_checkbox,
      ever_left_the_united_states_to_avoid_being_drafted_risky_checkbox,
      ever_applied_for_any_kind_of_exemption_from_military_service_risky_checkbox,
      ever_served_in_armed_forces_risky_checkbox,
      are_you_currently_a_member_of_armed_forces_risky_checkbox,
      are_you_scheduled_to_deploy_overseas_risky_checkbox,
      are_you_currently_stationed_overseas_risky_checkbox,
      are_you_a_former_military_member_overseas_risky_checkbox,
      ever_been_court_martialed_risky_checkbox,
      ever_been_discharged_because_you_were_a_alien_risky_checkbox,
      ever_deserted_the_armed_forces_risky_checkbox,
      date_registered_for_selective_service,
      ...rest
    } = values;

    const payload = {
      ...rest,
      date_registered_for_selective_service: ToSaveDate(
        date_registered_for_selective_service
      ),
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValue: CheckFormData3 = {
    ever_been_placed_in_deportation_proceedings: initInput(
      data?.ever_been_placed_in_deportation_proceedings
    ),

    ever_been_placed_in_deportation_proceedings_exp: initInput(
      data?.ever_been_placed_in_deportation_proceedings_exp
    ),

    ever_been_removed_or_deported: initInput(
      data?.ever_been_removed_or_deported
    ),

    ever_been_removed_or_deported_exp: initInput(
      data?.ever_been_removed_or_deported_exp
    ),

    are_you_a_male_in_the_us_between_18th_26th: initInput(
      data?.are_you_a_male_in_the_us_between_18th_26th
    ),

    did_you_register_for_selective_service: initInput(
      data?.did_you_register_for_selective_service
    ),

    date_registered_for_selective_service: initDate(
      data?.date_registered_for_selective_service
    ),

    selective_service_number: initInput(data?.selective_service_number),

    ever_left_the_united_states_to_avoid_being_drafted: initInput(
      data?.ever_left_the_united_states_to_avoid_being_drafted
    ),

    ever_left_the_united_states_to_avoid_being_drafted_exp: initInput(
      data?.ever_left_the_united_states_to_avoid_being_drafted_exp
    ),

    ever_applied_for_any_kind_of_exemption_from_military_service: initInput(
      data?.ever_applied_for_any_kind_of_exemption_from_military_service
    ),

    ever_applied_for_any_kind_of_exemption_from_military_service_exp: initInput(
      data?.ever_applied_for_any_kind_of_exemption_from_military_service_exp
    ),

    ever_served_in_armed_forces: initInput(data?.ever_served_in_armed_forces),
    are_you_currently_a_member_of_armed_forces: initInput(
      data?.are_you_currently_a_member_of_armed_forces
    ),

    are_you_scheduled_to_deploy_overseas: initInput(
      data?.are_you_scheduled_to_deploy_overseas
    ),

    are_you_currently_stationed_overseas: initInput(
      data?.are_you_currently_stationed_overseas
    ),

    are_you_a_former_military_member_overseas: initInput(
      data?.are_you_a_former_military_member_overseas
    ),

    ever_been_court_martialed: initInput(data?.ever_been_court_martialed),

    ever_been_court_martialed_exp: initInput(
      data?.ever_been_court_martialed_exp
    ),

    ever_been_discharged_because_you_were_a_alien: initInput(
      data?.ever_been_discharged_because_you_were_a_alien
    ),

    ever_been_discharged_because_you_were_a_alien_exp: initInput(
      data?.ever_been_discharged_because_you_were_a_alien_exp
    ),

    ever_deserted_the_armed_forces: initInput(
      data?.ever_deserted_the_armed_forces
    ),

    ever_deserted_the_armed_forces_exp: initInput(
      data?.ever_deserted_the_armed_forces_exp
    ),

    ever_been_placed_in_deportation_proceedings_risky_checkbox: false,
    ever_been_removed_or_deported_risky_checkbox: false,
    are_you_a_male_in_the_us_between_18th_26th_risky_checkbox: false,
    did_you_register_for_selective_service_risky_checkbox: false,
    date_registered_for_selective_service_risky_checkbox: false,
    selective_service_number_risky_checkbox: false,
    ever_left_the_united_states_to_avoid_being_drafted_risky_checkbox: false,
    ever_applied_for_any_kind_of_exemption_from_military_service_risky_checkbox:
      false,
    ever_served_in_armed_forces_risky_checkbox: false,
    are_you_currently_a_member_of_armed_forces_risky_checkbox: false,
    are_you_scheduled_to_deploy_overseas_risky_checkbox: false,
    are_you_currently_stationed_overseas_risky_checkbox: false,
    are_you_a_former_military_member_overseas_risky_checkbox: false,
    ever_been_court_martialed_risky_checkbox: false,
    ever_been_discharged_because_you_were_a_alien_risky_checkbox: false,
    ever_deserted_the_armed_forces_risky_checkbox: false,
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValue}
          onSubmit={onSubmit}
          validationSchema={CheckPart3Validation}
        >
          {(formik) => (
            <Form>
              {/* <Title>
                See the Specific Instructions by Item Number, Part 9. Additional
                Information About You of the Instructions for more information.
              </Title> */}

              <FormWrapper>
                <RadioComp
                  name="ever_been_placed_in_deportation_proceedings"
                  title="20. Have you EVER been placed in removal, rescission, or deportation proceedings?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_been_placed_in_deportation_proceedings"
                  )}
                />
              </FormWrapper>

              {formik.values.ever_been_placed_in_deportation_proceedings ===
                GenericYesNoOptionsValue.yes && (
                <FormWrapper>
                  <div>
                    <InputField
                      name="ever_been_placed_in_deportation_proceedings_exp"
                      label="Explanation"
                      inputType="text-area"
                      placeholder=""
                    />
                  </div>

                  <div></div>
                </FormWrapper>
              )}

              <FormWrapper>
                <div>
                  <RadioComp
                    name="ever_been_removed_or_deported"
                    title={`21. Have you EVER been removed or deported from the United States?`}
                    options={GenericYesNo}
                    toolTipProps={getCheckListFieldValidation(
                      formik,
                      "ever_been_removed_or_deported"
                    )}
                  />
                </div>
              </FormWrapper>

              {formik.values.ever_been_removed_or_deported ===
                GenericYesNoOptionsValue.yes && (
                <FormWrapper>
                  <div>
                    <InputField
                      name="ever_been_removed_or_deported_exp"
                      label="Explanation"
                      inputType="text-area"
                      placeholder=""
                    />
                  </div>

                  <div></div>
                </FormWrapper>
              )}

              <div className={`font-normal ${Colors.Black31} my-4 text-sm`}>
                Federal Law requires nearly all people born as male who are
                either U.S. citizens or immigrants, 18 through 25 years of age,
                to register with Selective Service. See{" "}
                <a
                  href="https://www.sss.gov/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-primaryColor"
                >
                  www.sss.gov
                </a>{" "}
              </div>

              <FormWrapper>
                <RadioComp
                  name="are_you_a_male_in_the_us_between_18th_26th"
                  title="22.a. Are you a person born as a male who lived in the United States at any time between your 18th and 26th birthdays?"
                  coloredLabel="(Do not select “Yes” if you were a lawful nonimmigrant for all of that time period.)"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "are_you_a_male_in_the_us_between_18th_26th"
                  )}
                />
              </FormWrapper>

              {formik.values.are_you_a_male_in_the_us_between_18th_26th ===
                GenericYesNoOptionsValue.yes && (
                <FormWrapper>
                  <RadioComp
                    name="did_you_register_for_selective_service"
                    title="22.b. Did you register for the Selective Service?"
                    options={GenericYesNo}
                    toolTipProps={getCheckListFieldValidation(
                      formik,
                      "did_you_register_for_selective_service"
                    )}
                  />
                </FormWrapper>
              )}

              {formik.values.did_you_register_for_selective_service ===
                GenericYesNoOptionsValue.yes && (
                <>
                  <Title>
                    22.c. Provide information about your registration.
                  </Title>

                  <FormWrapper>
                    <div>
                      <DatePickerField
                        name="date_registered_for_selective_service"
                        label="Date Registered (mm/dd/yyyy)"
                      />
                    </div>
                    <div>
                      <InputField
                        name="selective_service_number"
                        label="Selective Service Number"
                        placeholder=""
                      />
                    </div>
                  </FormWrapper>
                </>
              )}

              <FormWrapper>
                <RadioComp
                  name="ever_left_the_united_states_to_avoid_being_drafted"
                  title="23. Have you EVER left the United States to avoid being drafted in the U.S. armed forces?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_left_the_united_states_to_avoid_being_drafted"
                  )}
                />
              </FormWrapper>

              {formik.values
                .ever_left_the_united_states_to_avoid_being_drafted ===
                GenericYesNoOptionsValue.yes && (
                <FormWrapper>
                  <div>
                    <InputField
                      name="ever_left_the_united_states_to_avoid_being_drafted_exp"
                      label="Explanation"
                      inputType="text-area"
                      placeholder=""
                    />
                  </div>

                  <div></div>
                </FormWrapper>
              )}

              <FormWrapper>
                <RadioComp
                  name="ever_applied_for_any_kind_of_exemption_from_military_service"
                  title="24. Have you EVER applied for any kind of exemption from military service in the U.S. armed forces?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_applied_for_any_kind_of_exemption_from_military_service"
                  )}
                />
              </FormWrapper>

              {formik.values
                .ever_applied_for_any_kind_of_exemption_from_military_service ===
                GenericYesNoOptionsValue.yes && (
                <FormWrapper>
                  <div>
                    <InputField
                      name="ever_applied_for_any_kind_of_exemption_from_military_service_exp"
                      label="Explanation"
                      inputType="text-area"
                      placeholder=""
                    />
                  </div>

                  <div></div>
                </FormWrapper>
              )}

              <FormWrapper>
                <RadioComp
                  name="ever_served_in_armed_forces"
                  title="25. Have you EVER served in the U.S. armed forces?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_served_in_armed_forces"
                  )}
                />
              </FormWrapper>

              {/* <Sub>
                If you answered “No” to Item Number 25., go to Item Number 30.a.
              </Sub> */}

              {formik.values.ever_served_in_armed_forces ===
                GenericYesNoOptionsValue.yes && (
                <>
                  <FormWrapper>
                    <RadioComp
                      name="are_you_currently_a_member_of_armed_forces"
                      title="26.a. Are you currently a member of the U.S. armed forces?"
                      options={GenericYesNo}
                      toolTipProps={getCheckListFieldValidation(
                        formik,
                        "are_you_currently_a_member_of_armed_forces"
                      )}
                    />
                  </FormWrapper>

                  {formik.values.are_you_currently_a_member_of_armed_forces ===
                    GenericYesNoOptionsValue.yes && (
                    <>
                      <FormWrapper>
                        <div>
                          <RadioComp
                            name="are_you_scheduled_to_deploy_overseas"
                            title="26.b. Are you scheduled to deploy outside the United States, including to a vessel, within the next 3 months?"
                            options={GenericYesNo}
                            toolTipProps={getCheckListFieldValidation(
                              formik,
                              "are_you_scheduled_to_deploy_overseas"
                            )}
                          />
                          <Sub>
                            Call the Military Help Line at{" "}
                            <a
                              href="tel:877-247-4645"
                              className=" text-primaryColor"
                            >
                              877-247-4645
                            </a>{" "}
                            if you transfer to a new duty station after you file
                            your Form N-400, including if you are deployed
                            outside the United States or to a vessel.
                          </Sub>
                        </div>
                      </FormWrapper>

                      <FormWrapper>
                        <RadioComp
                          name="are_you_currently_stationed_overseas"
                          title="26.c. Are you currently stationed outside the United States?"
                          options={GenericYesNo}
                          toolTipProps={getCheckListFieldValidation(
                            formik,
                            "are_you_currently_stationed_overseas"
                          )}
                        />
                      </FormWrapper>
                    </>
                  )}

                  {formik.values.are_you_currently_a_member_of_armed_forces ===
                    GenericYesNoOptionsValue.no && (
                    <FormWrapper>
                      <RadioComp
                        name="are_you_a_former_military_member_overseas"
                        title="26.d. Are you a former U.S. military service member who is currently residing outside of the U.S.?"
                        options={GenericYesNo}
                        toolTipProps={getCheckListFieldValidation(
                          formik,
                          "are_you_a_former_military_member_overseas"
                        )}
                      />
                    </FormWrapper>
                  )}

                  {/* <Sub>
                    If you answer “Yes” to Item Numbers 27. - 29., provide an
                    explanation in the space provided in Part 14. Additional
                    Information.
                  </Sub> */}

                  <FormWrapper>
                    <RadioComp
                      name="ever_been_court_martialed"
                      title="27. Have you EVER been court-martialed or have you received a discharge characterized as other than honorable, bad conduct, or dishonorable, while in the U.S. armed forces?"
                      options={GenericYesNo}
                      toolTipProps={getCheckListFieldValidation(
                        formik,
                        "ever_been_court_martialed"
                      )}
                    />
                  </FormWrapper>

                  {formik.values.ever_been_court_martialed ===
                    GenericYesNoOptionsValue.yes && (
                    <FormWrapper>
                      <div>
                        <InputField
                          name="ever_been_court_martialed_exp"
                          label="Explanation"
                          inputType="text-area"
                          placeholder=""
                        />
                      </div>

                      <div></div>
                    </FormWrapper>
                  )}

                  <FormWrapper>
                    <RadioComp
                      name="ever_been_discharged_because_you_were_a_alien"
                      title="28. Have you EVER been discharged from training or service in the U.S. armed forces because you were an alien?"
                      options={GenericYesNo}
                      toolTipProps={getCheckListFieldValidation(
                        formik,
                        "ever_been_discharged_because_you_were_a_alien"
                      )}
                    />
                  </FormWrapper>

                  {formik.values
                    .ever_been_discharged_because_you_were_a_alien ===
                    GenericYesNoOptionsValue.yes && (
                    <FormWrapper>
                      <div>
                        <InputField
                          name="ever_been_discharged_because_you_were_a_alien_exp"
                          label="Explanation"
                          inputType="text-area"
                          placeholder=""
                        />
                      </div>

                      <div></div>
                    </FormWrapper>
                  )}

                  <FormWrapper>
                    <RadioComp
                      name="ever_deserted_the_armed_forces"
                      title="29. Have you EVER deserted from the U.S. armed forces?"
                      options={GenericYesNo}
                      toolTipProps={getCheckListFieldValidation(
                        formik,
                        "ever_deserted_the_armed_forces"
                      )}
                    />
                  </FormWrapper>

                  {formik.values.ever_deserted_the_armed_forces ===
                    GenericYesNoOptionsValue.yes && (
                    <FormWrapper>
                      <div>
                        <InputField
                          name="ever_deserted_the_armed_forces_exp"
                          label="Explanation"
                          inputType="text-area"
                          placeholder=""
                        />
                      </div>

                      <div></div>
                    </FormWrapper>
                  )}
                </>
              )}
              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default CheckPart3;

const Wrapper = styled.div``;
