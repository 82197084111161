import React, { FC } from "react";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { N400ApplicantInfo1Obj } from "../../../../../../api/n400/types";
import { FormN400Progress } from "../../types";

interface Props {
  title: string;
  data?: N400ApplicantInfo1Obj;
}

const ReviewApplicantInfo1: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      section: {
        title: "Applicant’s Legal Name",
        children: [
          {
            label: "Family Name",
            value: data?.lastName,
          },
          {
            label: "Given Name",
            value: data?.firstName,
          },
          {
            label: "Middle Name(s",
            value: data?.middleName,
          },
        ],
      },
    },
    {
      section: {
        title: "Other Names",
        children: [
          {
            label: "Have you used any other names since birth?",
            value: data?.otherNames,
          },
          {
            label: "Family Name",
            value: data?.otherNamesLastName,
          },
          {
            label: "Given Name",
            value: data?.otherNamesFirstName,
          },
          {
            label: "Middle Name(s",
            value: data?.otherNamesMiddleName,
          },
        ],
      },
    },
    {
      section: {
        title: "Any New Names",
        children: [
          {
            label: "Would you like to legally change your name?",
            value: data?.anyNewName,
          },
          {
            section: {
              title: "New names you would like to use.",
              children: [
                {
                  label: "Given Name",
                  value: data?.anyNewNameFirstName,
                },
                {
                  label: "Middle Name(s",
                  value: data?.anyNewNameMiddleName,
                },
                {
                  label: "Family Name",
                  value: data?.anyNewNameLastName,
                },
              ],
            },
          },
        ],
      },
    },
    {
      label: "USCIS Online Account Number (if any)",
      value: data?.uscisAcctNum,
    },
    {
      label: "Gender",
      value: data?.gender,
    },
    {
      label: "Date of Birth",
      value: data?.dateOfBirth,
    },
    {
      label: "Are you a lawful permanent resident?",
      value: data?.isLPR,
    },
    {
      label: "Date You Became a Lawful Permanent Resident",
      value: data?.dateLPR,
    },
    {
      label: "Countries of Citizenship or Nationality",
      value: data?.countryOfCitizenship,
    },
  ];

  return (
    <ReviewBox
      title={title}
      data={section}
      editUrl={FormN400Progress.applicantInfo1}
      isActive
    />
  );
};

export default ReviewApplicantInfo1;
