import { FieldArray, Form, Formik } from "formik";
import { Note, Title, Wrapper } from "./styles";
import { Modify, SingleOption } from "../../../../types/types";
import {
  N400AddressHistoryObj,
  N400EligibilityPayload,
} from "../../../../api/n400/types";
import { Dayjs } from "dayjs";
import {
  useGetN400AddressHistory,
  useSaveN400AddressHistory,
} from "../../../../hooks/n400/useN400";
import FormBtn from "../../components/FormBtn";
import FormSectionWrapper from "../../components/FormSectionWrapper";
import { AddressHistoryValidation } from "./validation";
import RadioComp from "../../components/RadioComp";
import {
  ApartmentSuiteFloorData,
  GenericYesNo,
  GenericYesNoOptionsValue,
  SpecialSeparator,
  ToSaveDate,
  USDateFormat,
  formatMultiStates,
  getSelectedOption,
  initDate,
  initInput,
  initSelect,
  isZipCode,
  saveDynamicCheck,
} from "../../components/data";
import GroupedSection from "../../components/GroupedSection";
import AddAnotherBtn from "../../components/AddAnotherBtn";
import useToNextSection from "../../../../hooks/useToNextSection";
import { FC } from "react";
import AddressHistoryMail from "./AddressHistoryMail";
import { FormWrapper } from "../../components/styles";
import AddressHistoryPhy from "./AddressHistoryPhy";
import useCountries from "../../components/useCountries";
import useStatesByCountry from "../../components/useStatesByCountry";
import useMultiStatesByCountry from "./useMultiStatesByCountry";
import Colors from "../../../../styles/Colors";
import { GetN400ReasonForFiling, N400ReasonForFiling } from "./data";
import { toast } from "react-toastify";

const initialPhysicalAddressArray = {
  inCareOfName: "",
  streetNumberAndName: "",
  cityOrTown: "",
  apartmentSuiteOrFloor: null,
  country: null,
  province: "",
  stateOrTerritory: null,
  yetToReturn: false,
  addressNumber: "",
  dateYouBeganLivingAtAddress: null,
  dateYouLeftAddress: null,
  zipCode: "",
};

export interface physicalAddressArrayPropsPayload {
  inCareOfName: string;
  streetNumberAndName: string;
  apartmentSuiteOrFloor: SingleOption | null;
  cityOrTown: string;
  country: SingleOption | null;
  province: string;
  stateOrTerritory: SingleOption | null;
  yetToReturn: boolean;
  addressNumber: string;
  dateYouBeganLivingAtAddress: Dayjs | null;
  dateYouLeftAddress: Dayjs | null;
  addressCode: string;
}

export interface AddressHistoryData
  extends Modify<
    Omit<
      N400AddressHistoryObj,
      | "inCareOfName"
      | "streetNumberAndName"
      | "cityOrTown"
      | "apartmentSuiteOrFloor"
      | "country"
      | "province"
      | "stateOrTerritory"
      | "zipCode"
      | "yetToReturn"
      | "addressNumber"
      | "dateYouLeftAddress"
      | "dateYouBeganLivingAtAddress"
      | "zipCodeMail"
      | "postalCodeMail"
      | "zipCode"
      | "postalCode"
    >,
    {
      addressCodeMail: string;
      physicalAddressArray: physicalAddressArrayPropsPayload[];
      apartmentSuiteOrFloorMail: SingleOption | null;
      countryMail: SingleOption | null;
      // livedSameStateForThreeMonths_risky_checkbox: boolean;
      stateOrTerritoryMail: SingleOption | null;
    }
  > {}

interface Props {
  formEligibility: N400EligibilityPayload;
}

const AddressHistory: FC<Props> = ({ formEligibility }) => {
  const { toNextSection } = useToNextSection();
  const { data, isFetching, isError } = useGetN400AddressHistory();
  const { mutate, isLoading: isSaving } = useSaveN400AddressHistory();
  const { formattedCountryData: mailCountryData } = useCountries();
  const { formattedStateData: mailStateData } = useStatesByCountry(
    data?.countryMail
  );
  const { statesArray } = useMultiStatesByCountry(data?.country);

  const savedPhysicalAddressArray = () => {
    if (
      data?.streetNumberAndName ||
      data?.cityOrTown ||
      data?.apartmentSuiteOrFloor ||
      data?.country ||
      data?.province ||
      data?.stateOrTerritory
    ) {
      const phyInCareOfNameMailData = data?.inCareOfName
        .split(SpecialSeparator)
        .map((ev: any) => ({ inCareOfName: ev }));
      const strNumNameData = data?.streetNumberAndName
        .split(SpecialSeparator)
        .map((ev: any) => ({ streetNumberAndName: ev }));
      const cityOrTownData = data?.cityOrTown
        .split(",")
        .map((ev: any) => ({ cityOrTown: ev }));

      const countryArray = data?.country.split(",");

      // permanent patch for now
      const countryData = countryArray.map((ev: any) => {
        return {
          country: { value: ev, label: ev },
        };
      });
      const countyData = data?.province
        .split(",")
        .map((ev: any) => ({ province: ev }));

      // permanent patch for now
      const stateOrTerritoryData = formatMultiStates({
        dataKey: data?.stateOrTerritory,
        fieldKey: "stateOrTerritory",
        dataArray: statesArray,
      }) as { stateOrTerritory: SingleOption }[];

      const apartmentSuiteOrFloorData = data?.apartmentSuiteOrFloor
        .split(",")
        .map((ev: any) => ({
          apartmentSuiteOrFloor: getSelectedOption(ApartmentSuiteFloorData, ev),
        }));

      const dateYouBeganLivingAtAddressData = data?.dateYouBeganLivingAtAddress
        .split(",")
        .map((ev: any) => ({ dateYouBeganLivingAtAddress: initDate(ev) }));

      const dateYouLeftAddressData = data?.dateYouLeftAddress
        ?.split(",")
        .map((ev: any) => ({ dateYouLeftAddress: initDate(ev) }));

      const addressNumberData = data?.addressNumber
        ?.split(",")
        .map((ev: any) => ({ addressNumber: ev }));

      const addressCodeData = data?.zipCode?.split(",").map((ev, index) => ({
        addressCode: isZipCode(countryArray[index])
          ? ev
          : data?.postalCode?.split(",")[index],
      }));

      const yetToReturnData = data?.yetToReturn?.split(",").map((ev: any) => ({
        yetToReturn: saveDynamicCheck(ev),
      }));

      return strNumNameData.map((ev: any, i: any) => ({
        ...ev,
        ...phyInCareOfNameMailData[i],
        ...cityOrTownData[i],
        ...countryData[i],
        ...countyData[i],
        ...stateOrTerritoryData[i],
        ...apartmentSuiteOrFloorData[i],
        ...yetToReturnData[i],
        ...dateYouBeganLivingAtAddressData[i],
        ...dateYouLeftAddressData[i],
        ...addressNumberData[i],
        ...addressCodeData[i],
      }));
    } else return [initialPhysicalAddressArray];
  };

  const onSubmit = (values: AddressHistoryData) => {
    const {
      physicalAddressArray,
      apartmentSuiteOrFloorMail,
      countryMail,
      stateOrTerritoryMail,
      addressCodeMail,
      ...rest
    } = values;

    const payload = {
      ...rest,
      inCareOfName: physicalAddressArray
        .map((ev) => ev.inCareOfName)
        .join(SpecialSeparator),
      streetNumberAndName: physicalAddressArray
        .map((ev) => ev.streetNumberAndName)
        .join(SpecialSeparator),
      apartmentSuiteOrFloor: physicalAddressArray
        .map((ev) => ev.apartmentSuiteOrFloor?.value)
        .join(","),
      country: physicalAddressArray
        .map((ev) => ev.country?.value || "")
        .join(","),
      province: physicalAddressArray.map((ev) => ev.province).join(","),
      cityOrTown: physicalAddressArray.map((ev) => ev.cityOrTown).join(","),
      stateOrTerritory: physicalAddressArray
        .map((ev) => ev.stateOrTerritory?.value || "")
        .join(","),
      dateYouBeganLivingAtAddress: physicalAddressArray
        .map((ev) => ToSaveDate(ev.dateYouBeganLivingAtAddress, USDateFormat))
        .join(","),
      dateYouLeftAddress: physicalAddressArray
        .map((ev) => ToSaveDate(ev.dateYouLeftAddress, USDateFormat))
        .join(","),
      addressNumber: physicalAddressArray
        .map((ev) => ev.addressNumber)
        .join(","),
      zipCode: physicalAddressArray
        .map((ev) => (isZipCode(ev.country?.value) ? ev.addressCode : ""))
        .join(","),
      postalCode: physicalAddressArray
        .map((ev) => (!isZipCode(ev.country?.value) ? ev.addressCode : ""))
        .join(","),
      yetToReturn: physicalAddressArray
        .map((ev) => ev.yetToReturn.toString())
        .join(","),
      apartmentSuiteOrFloorMail: apartmentSuiteOrFloorMail?.value || "",
      countryMail: countryMail?.value || "",
      stateOrTerritoryMail: stateOrTerritoryMail?.value || "",
      postalCodeMail: !isZipCode(countryMail?.value) ? addressCodeMail : "",
      zipCodeMail: isZipCode(countryMail?.value) ? addressCodeMail : "",
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValues: AddressHistoryData = {
    physicalAddressArray: savedPhysicalAddressArray(),
    mailAddressSameAsPhysicalAddress: initInput(
      data?.mailAddressSameAsPhysicalAddress
    ),
    inCareOfNameMail: initInput(data?.inCareOfNameMail),
    apartmentSuiteOrFloorMail: initSelect(
      ApartmentSuiteFloorData,
      data?.apartmentSuiteOrFloorMail
    ),
    streetNumberAndNameMail: initInput(data?.streetNumberAndNameMail),
    addressNumberMail: initInput(data?.addressNumberMail),
    cityOrTownMail: initInput(data?.cityOrTownMail),
    provinceMail: initInput(data?.provinceMail),
    countryMail: initSelect(mailCountryData, data?.countryMail),
    stateOrTerritoryMail: initSelect(mailStateData, data?.stateOrTerritoryMail),
    addressCodeMail: isZipCode(data?.countryMail)
      ? initInput(data?.zipCodeMail)
      : initInput(data?.postalCodeMail),
    // livedSameStateForThreeMonths: data?.livedSameStateForThreeMonths || "",
    // livedSameStateForThreeMonths_risky_checkbox: false,
  };

  // const getEligibilityValidation = (
  //   formik: FormikProps<AddressHistoryData>,
  //   name: string
  // ): TooltipProps | undefined => {
  //   if (name === "livedSameStateForThreeMonths") {
  //     if (AHSameStateCondition(formik.values))
  //       return {
  //         isReversed: true,
  //         type: TooltipType.risky,
  //         body: "You must live in the state or USCIS district where you claim residence for at least 3 months prior to filing. You may  complete this application, but do not mail it to USCIS until you meet the 3 month requirement.",
  //       };
  //   }
  // };

  const getInfo = () => {
    if (
      GetN400ReasonForFiling(formEligibility) ===
        N400ReasonForFiling.spouseUSCitizen ||
      GetN400ReasonForFiling(formEligibility) === N400ReasonForFiling.vawa
    )
      return "List every location where you have lived during the last 3 years";
    else if (
      GetN400ReasonForFiling(formEligibility) ===
        N400ReasonForFiling.employmentSpouseUSCitizen ||
      GetN400ReasonForFiling(formEligibility) ===
        N400ReasonForFiling.hostilitiesMilitary
    )
      return "Only list the location where you are currently living if you are applying";

    return "List every location where you have lived during the last 5 years.";
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={AddressHistoryValidation()}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <Title>Physical Addresses</Title>
              <Note>
                {getInfo()} Provide information for the complete time period.
              </Note>

              <FieldArray
                name="physicalAddressArray"
                render={(arrayHelpers) => (
                  <div className="px-2 py-3 mb-8 border rounded">
                    {formik.values.physicalAddressArray.map((_, index) => (
                      <GroupedSection
                        key={index}
                        index={index}
                        onCancel={() => arrayHelpers.remove(index)}
                      >
                        <div className={`text-[${Colors.Blue00}]`}>
                          {index === 0
                            ? "Current Physical Address"
                            : `Entry${index + 1}:`}
                        </div>
                        <AddressHistoryPhy
                          data={data}
                          formik={formik}
                          index={index}
                        />
                      </GroupedSection>
                    ))}
                    <AddAnotherBtn
                      onClick={() => {
                        if (formik.values.physicalAddressArray.length === 8) {
                          toast.error("Limit Reached");
                          return;
                        }
                        arrayHelpers.push(initialPhysicalAddressArray);
                      }}
                    />
                  </div>
                )}
              />

              <FormWrapper>
                <div>
                  <RadioComp
                    title="Is your current physical address (should be the first one listed above) the same as your mailing address?"
                    name="mailAddressSameAsPhysicalAddress"
                    options={GenericYesNo}
                  />
                </div>
                <div></div>
              </FormWrapper>

              {formik.values.mailAddressSameAsPhysicalAddress ===
                GenericYesNoOptionsValue.no && (
                <AddressHistoryMail formik={formik} data={data} />
              )}

              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default AddressHistory;
