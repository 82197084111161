import React, { FC } from "react";
import { ReviewData } from "./ReviewBox";
import styled from "styled-components";
import Colors from "../../../../styles/Colors";
import ReviewGeneric, { Row } from "./ReviewGeneric";

interface Props {
  title: string;
  data: ReviewData[];
}

const ReviewOutput: FC<Props> = ({ data, title }) => {
  let number = 0;

  const childrenJsx = data.map((child) => {
    if (child.section) {
      return (
        <ReviewOutput
          data={child.section.children}
          title={child.section.title}
        />
      );
    } else {
      if (child.value) {
        number += 1;
      }

      return <ReviewGeneric value={child.value} label={child.label} />;
    }
  });

  return number > 0 ? (
    <>
      <Row>
        <SubHead>{title}</SubHead>
      </Row>
      {childrenJsx}
    </>
  ) : (
    <></>
  );
};

export default ReviewOutput;

const SubHead = styled.div`
  justify-self: flex-start;
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: ${Colors.Blue00};
`;
