import React, { FC } from "react";
import { ClipLoader } from "react-spinners";
import Colors from "../styles/Colors";

interface Props {
  color?: string;
  size?: number;
}

const Spinner: FC<Props> = ({ color, size }) => {
  return <ClipLoader color={color || Colors.Blue00} size={size || 25} />;
};

export default Spinner;
