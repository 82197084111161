import React, { FC, useState } from "react";
import styled from "styled-components";
import Colors from "../../../../../../styles/Colors";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDeleteForm } from "../../../../../../hooks/utils/useUtils";
import { toast } from "react-toastify";
import TerminateModal from "../../../../components/TerminateModal";
import useOnContinueNav from "../../../../../../hooks/useOnContinueNav";
import useIsEligibilityFormFilled from "../../../../../../hooks/useHasFilledCurrentEligibility";
import { FormCategories } from "../../../../../../routes/type";

interface Props {
  formProgress: string;
  formCategory: string;
  isFormCompleted: boolean;
}

const ContinueApplication: FC<Props> = ({
  formProgress,
  formCategory,
  isFormCompleted,
}) => {
  const { mutate, isLoading: isDeleting } = useDeleteForm();
  const [open, setOpen] = useState(false);
  const { getIsEligibilityFormFilled } = useIsEligibilityFormFilled();
  const hasEligibility = getIsEligibilityFormFilled(
    formCategory as FormCategories
  );
  const { onContinueNav } = useOnContinueNav();

  const onOpenDelete = () => {
    setOpen(true);
  };

  const onCloseDelete = () => {
    setOpen(false);
  };

  const onDelete = () => {
    mutate(formCategory, {
      onSuccess: () => {
        toast.success(`Form deleted successfully!`);
        onCloseDelete();
      },
    });
  };

  const onContinue = () => {
    onContinueNav();
  };

  return (
    <>
      <Wrapper>
        <Label>
          <Dot />
          Continue{" "}
          {hasEligibility
            ? `${isFormCompleted ? `${formCategory} Review` : formProgress}`
            : `${formCategory} Eligibility`}
        </Label>
        <BtnWrapper>
          <ContinueBtn onClick={onContinue}>Continue</ContinueBtn>
          <DeleteBtn onClick={onOpenDelete}>
            <RiDeleteBin6Line />
            DELETE
          </DeleteBtn>
        </BtnWrapper>
      </Wrapper>
      {open && (
        <TerminateModal
          title={`Are you sure you want to delete this application?`}
          subText={`The application for Form ${formCategory.toUpperCase()} will be deleted permanently, and any progress made on the form will be lost.`}
          onClose={onCloseDelete}
          onConfirm={onDelete}
          isLoading={isDeleting}
        />
      )}
    </>
  );
};

export default ContinueApplication;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 29px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Dot = styled.div`
  width: 6px;
  height: 6px;
  background-color: black;
  border-radius: 100%;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  text-transform: capitalize;
`;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Btn = styled.button`
  padding: 10px 46px;
  text-transform: uppercase;
  font-size: 0.75rem;
`;

const ContinueBtn = styled(Btn)`
  border-radius: 8px;
  background-color: ${Colors.Blue00};
  color: white;
`;

const DeleteBtn = styled.button`
  padding: 10px 24px;
  display: flex;
  align-items: center;
  gap: 13px;
  color: ${Colors.RedC3};
`;
