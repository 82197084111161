import { SingleOption } from "../../../../types/types";
import { leftSideBarDataProps } from "../../components/type";
import { FormN565Progress } from "./types";

export const n565leftSideBarData: leftSideBarDataProps[] = [
  {
    label: "Information From Current Certificate or Declaration",
    formProgress: FormN565Progress.infoFromCurrentDoc,
  },
  {
    label: "Current Information About You",
    formProgress: FormN565Progress.currentInfoAboutYou,
  },
  {
    label: "Type of Application",
    formProgress: FormN565Progress.typeOfApplication,
  },
  {
    label: "Preparer",
    formProgress: FormN565Progress.PI,
  },
  {
    label: "Review and Pay",
    formProgress: FormN565Progress.review,
  },
];

export const CountryData: SingleOption[] = [
  { value: "nigeria", label: "Nigeria" },
];

export const StateOrTerritoryData: SingleOption[] = [
  { value: "lagos", label: "Lagos" },
];

export const InterpreterLanguageOptions = [
  {
    value: "spanish",
    label: "Spanish",
  },
  {
    value: "french",
    label: "French",
  },
];

export enum N565MaritalStatusValues {
  single = "single",
  married = "married",
  divorced = "divorced",
  widowed = "widowed",
  "marriage annulled" = "marriage annulled",
}

export const N565MaritalStatusData: SingleOption[] = [
  {
    label: "Single",
    value: N565MaritalStatusValues.single,
  },
  { value: N565MaritalStatusValues.married, label: "Married" },
  { value: N565MaritalStatusValues.divorced, label: "Divorced" },
  { value: N565MaritalStatusValues.widowed, label: "Widowed" },
  {
    value: N565MaritalStatusValues["marriage annulled"],
    label: "Marriage Annulled",
  },
];

export enum N565TypeOfApplicationDataValue {
  citizenship = "Citizenship",
  naturalization = "Naturalization",
  repatriation = "Repatriation",
  intention = "Intention",
  recognition = "Recognition",
}

export const N565TypeOfApplicationData = [
  {
    label: "New Certificate of Citizenship",
    value: N565TypeOfApplicationDataValue.citizenship,
  },
  {
    label: "New Certificate of Naturalization",
    value: N565TypeOfApplicationDataValue.naturalization,
  },
  {
    label: "New Certificate of Repatriation",
    value: N565TypeOfApplicationDataValue.repatriation,
  },
  {
    label: "New Declaration of Intention",
    value: N565TypeOfApplicationDataValue.intention,
  },
  {
    label:
      "Special Certificate of Naturalization to Obtain Recognition of My U.S. Citizenship by a Foreign Country",
    value: N565TypeOfApplicationDataValue.recognition,
  },
];

// export enum N565BasisOfApplicationDataValue {
//   lostStolenOrDestroyed = "My certificate or declaration was lost, stolen, or destroyed.",
//   mutilated = "My certificate or declaration is mutilated.",
//   typoError = "My certificate or declaration is incorrect due to a typographical or clerical error by USCIS.",
//   nameLegallyChanged = "My name has legally changed",
//   dateOfBirthLegallyChanged = "My date of birth has legally changed through a court order or U.S. Government-issued document, and I am applying for a replacement Certificate of Citizenship.",
//   changeGender = "I am seeking to change the gender listed on my document.",
//   reasonNotListed = "My reason for applying for a new document is not listed above.",
// }

// export const N565BasisOfApplicationData = [
//   {
//     label: "My certificate or declaration was lost, stolen, or destroyed.",
//     value: N565BasisOfApplicationDataValue.lostStolenOrDestroyed,
//   },
//   {
//     label: "My certificate or declaration is mutilated.",
//     value: N565BasisOfApplicationDataValue.mutilated,
//   },
//   {
//     label:
//       "My certificate or declaration is incorrect due to a typographical or clerical error by USCIS.",
//     value: N565BasisOfApplicationDataValue.typoError,
//   },
//   {
//     label: "My name has legally changed.",
//     value: N565BasisOfApplicationDataValue.nameLegallyChanged,
//   },
//   {
//     label:
//       "My date of birth has legally changed through a court order or U.S. Government-issued document, and I am applying for a replacement Certificate of Citizenship.",
//     value: N565BasisOfApplicationDataValue.dateOfBirthLegallyChanged,
//   },
//   {
//     label: "I am seeking to change the gender listed on my document.",
//     value: N565BasisOfApplicationDataValue.changeGender,
//   },
//   {
//     label: "My reason for applying for a new document is not listed above.",
//     value: N565BasisOfApplicationDataValue.reasonNotListed,
//   },
// ];

export enum N565NameChangedThroughDataValue {
  marriageDivorceOrAnnulment = "Marriage, Divorce, or Annulment",
  courtOrder = "Court Order",
}

export const N565NameChangedThroughData = [
  {
    label: "Marriage, Divorce, or Annulment",
    value: N565NameChangedThroughDataValue.marriageDivorceOrAnnulment,
  },
  {
    label: "Court Order",
    value: N565NameChangedThroughDataValue.courtOrder,
  },
];

export enum N565GenderChangedThroughDataValue {
  male = "Male",
  female = "Female",
}

export const N565GenderChangedThroughData = [
  {
    label: "Male",
    value: N565GenderChangedThroughDataValue.male,
  },
  {
    label: "Female",
    value: N565GenderChangedThroughDataValue.female,
  },
];

export const SectionRiskAssessmentSystemInstructions = `You are an AI assistant trained to analyze user input from the SeamlessVisa platform for potential risks and inconsistencies in immigration form submissions. Your primary function is to assess information provided in each section of the form based on your knowledge of U.S. immigration laws and SeamlessVisa services.

Responsibilities:

Risk Assessment: Scrutinize each section of the filled form for potential red flags that may indicate fraud, errors, or inconsistencies. This includes evaluating:
Personal Information: Unusual names, addresses, or birthdates.
Immigration History: Inconsistencies in visa status, travel history, or previous applications.
Supporting Documents: Missing or incomplete documents, questionable authenticity.
Flag Discrepancies: Identify and clearly highlight any discrepancies or concerns you find in the data.
Provide Recommendations: Offer actionable recommendations for addressing the identified risks, such as requesting additional documentation or clarification from the user.
Avoid Legal Advice: Refrain from providing legal interpretations or advice. Instead, focus on identifying potential issues and suggesting next steps for SeamlessVisa's legal team or the user.
Encourage SeamlessVisa Contact: When appropriate, recommend the user contact SeamlessVisa directly for further assistance or clarification.
Additional Considerations:

Prioritize user privacy and data protection.
Maintain a professional and objective tone in your assessments.
Keep your responses concise and easy to understand.
Refer to the latest U.S. immigration laws and regulations.
Your role is crucial in helping SeamlessVisa streamline its processes, improve user experience, and mitigate risks associated with fraudulent or inaccurate information.`;
