import Colors from "../../../../../../../styles/Colors";
import MiniLists from "../MiniLists";
import { DocChecklistProps } from "./types";

export enum n400DocChecklistNames {}

export const n400CommonDocChecklist: DocChecklistProps[] = [
  {
    name: "n400_front_green_card",
    label: "A copy of your front Green Card (Permanent Resident Card).",
    info: (
      <MiniLists>
        <li>It should be a valid unexpired green card.</li>
        {/* <li>
          If you are requesting an exemption from the citizenship test due to a
          qualifying medical condition, you must include{" "}
          <span className={`text-[${Colors.Blue00}]`}>
            Form N-648, which is the Medical Certification for Disability
            Exceptions, with your application.
          </span>
        </li> */}
      </MiniLists>
    ),
  },
  {
    name: "n400_back_green_card",
    label: "A copy of your back Green Card (Permanent Resident Card).",
    info: (
      <MiniLists>
        <li>It should be a valid unexpired green card.</li>
        {/* <li>
          If you are requesting an exemption from the citizenship test due to a
          qualifying medical condition, you must include{" "}
          <span className={`text-[${Colors.Blue00}]`}>
            Form N-648, which is the Medical Certification for Disability
            Exceptions, with your application.
          </span>
        </li> */}
      </MiniLists>
    ),
  },
  {
    name: "n400_app_and_bio_fee",
    label:
      "A check or money order for the application fee and the biometric services fee.",
    isNotFile: true,
    info: (
      <MiniLists>
        <li>
          Write your A-Number (Alien Registration Number) on the back of the
          check or money order. You may also pay using a credit card, by using
          the
          <span className={`text-[${Colors.Blue00}]`}>
            G-1450, Authorization for Credit Card Transaction.
          </span>
        </li>
        <li>The filing fee is $760.</li>
      </MiniLists>
    ),
  },
  {
    name: "n400_medical_exceptions",
    label:
      "Form N-648, which is the Medical Certification for Disability Exceptions",
    info: "If you are requesting an exemption from the citizenship test due to a qualifying medical condition",
    optional: true,
  },
];

export const n400MarraigeNoDocChecklist: DocChecklistProps[] = [
  {
    name: "n400_marriage_cert",
    label: "A copy of your marriage certificate",
    info: "If currently or previously married, provide Marriage certificate(s), Divorce or annulment decree, Death certificate(s).",
    optional: true,
  },
  {
    name: "n400_two_US_passport",
    label: "Two U.S. passport-style photos.",
    info: (
      <MiniLists>
        <li>If you are applying from a foreign country.</li>
        <li>The photos should measure 2 inches by 2 inches.</li>
      </MiniLists>
    ),
    optional: true,
  },
];

export const n400MarraigeYesDocChecklist: DocChecklistProps[] = [
  {
    name: "n400_spouse_US_proof",
    label:
      "Proof that your spouse has been a U.S. citizen for at least 3 years, send one of these documents:",
    info: (
      <MiniLists>
        <li>Birth certificate, or</li>
        <li>Certificate of Naturalization, or</li>
        <li>Certificate of Citizenship, or</li>
        <li>
          The inside of the front cover and signature page of your spouse’s
          current U.S. passport, or
        </li>
        <li>
          Form FS-240, Report of Birth Abroad of a Citizen of the United States
          of America.
        </li>
      </MiniLists>
    ),
  },
  {
    name: "n400_martial_proof",
    label: "Proof of your current marital status. Provide Marriage Certificate",
  },
  {
    name: "n400_marriage_termination_proof",
    label: "Proof of termination of all your prior marriage(s)",
    info: (
      <MiniLists>
        <li>Divorce papers, Annulment(s), or Death certificate(s)</li>
      </MiniLists>
    ),
    optional: true,
  },
  {
    name: "n400_you_and_spouse_bank",
    label: "Bank accounts, tax returns, leases, mortgages",
    info: "Documents and Financial records of you and your spouse",
  },
  {
    name: "n400_you_and_spouse_child_cert",
    label: "Birth certificates of children born into the marriage",
    info: "Documents and Financial records of you and your spouse",
  },
  {
    name: "n400_you_and_spouse_tax",
    label: "Tax form",
    info: (
      <MiniLists>
        <li>
          An IRS tax return transcript for the last 3 year record of you and
          your spouse, <span className={`text-[${Colors.Blue00}]`}>or</span>
        </li>
        <li>
          Internal Revenue Service (IRS) certified copies of income tax forms
          filed by you and your spouse for the past 3 years
        </li>
      </MiniLists>
    ),
  },
];
