import { FormCategories } from "../../../../routes/type";
import { ECongratulationType } from "./modals/EligibilityModal";

export interface terminateInfo {
  formToRetake?: FormCategories;
  type: ECongratulationType;
  secondBtnText?: string;
  infoContent?: JSX.Element | string;
}

export interface answerLead {
  good?: string;
  unsure?: string;
  bad?: string;
}

export type EligibilityFormData = { [x: string]: string };

export interface commonOption {
  nextStep?: number;
  terminate?: terminateInfo;
  note?: JSX.Element | string;
  lead?: "good" | "unsure" | "bad";
  dynamicNextStep?: (formData?: any) => number | undefined;
}

export interface OptionObj extends commonOption {
  label: string;
}

export interface SingleOptionObj extends commonOption {
  renderAltComp: (data: RenderAltCompProps) => JSX.Element;
}

export interface checkQuestionsProps<T> {
  id: number;
  title: string;
  getTitle?: (formData?: any) => string;
  subTitle?: string;
  options?: OptionObj[];
  singleOption?: SingleOptionObj;
  storeField: T;
}

export enum N400HowOldAreYouLabels {
  "18 or older" = "18 or older",
  "Under 18" = "Under 18",
}

export enum N400HaveYouLeftTheUSInThePastFiveYearsLabels {
  Yes = "Yes",
  No = "No",
}

export enum N400HaveYouLeftTheUSInThePastThreeYearsLabels {
  Yes = "Yes",
  No = "No",
}

export enum N400AreYouMarriedToAUSCitizenLabels {
  Yes = "Yes",
  No = "No",
}

export enum N400HaveYouBeenMarriedForThreeMoreLabels {
  Yes = "Yes",
  No = "No",
}

export enum N400HaveYouMovedInThePastThreeMonthsLabels {
  Yes = "Yes",
  No = "No",
}

export interface OnFieldChangeProps {
  selectedValue: string;
  nextStep?: number;
  terminate?: terminateInfo;
  dynamicNextStep?: (formData: any) => number | undefined;
}

export interface RenderAltCompProps {
  formData?: { [x: string]: string };
  onChange: (data: OnFieldChangeProps) => void;
  result: checkQuestionsProps<any>;
}

export enum GenericEligibilityYesNoValues {
  Yes = "Yes",
  No = "No",
}

export enum N400HowLongHaveYouHadYourGreenCardLabels {
  least5years = "I have had my green card for at least 5 years",
  threeToFourYears = "I have had my green card for 3-4 years",
  doesNotApply = "None of these apply to me",
}

export enum N400DidYouServeHonorablyInActiveDutyLabels {
  yesPeriodOfConflict = "Yes, during a designated period of conflict",
  yesYearOrMore = "Yes, for a year or more",
  neither = "Neither of these apply to me",
}

export enum DS160PrimaryReasonForVisitingLabels {
  "Vacation/Tourism" = "Vacation/Tourism",
  "Business trip" = "Business trip",
  "To study" = "To study",
  "None" = "None",
}
