import { ApiResponse } from "../../../types/types";
import request from "../../request";
import {
  CreateBusinessApplicationPayload,
  CreateBusinessApplicationRes,
  GetBusinessApplicationsQueries,
  GetBusinessApplicationsRes,
  GetSingleBusinessApplicationRes,
} from "./types";

export const createBusinessApplication = async (
  payload: CreateBusinessApplicationPayload
) => {
  const { applicationType, userId } = payload;
  return await request.post<CreateBusinessApplicationRes>(
    `business/create-application${userId ? `/${userId}` : ""}`,
    {
      applicationType,
    }
  );
};

export const getBusinessApplications = async (
  queries: GetBusinessApplicationsQueries
) => {
  const { casetype, status, search, page, limit, userId } = queries;
  let payload = {};

  if (limit) {
    payload = {
      ...payload,
      limit,
    };
  }

  if (page) {
    payload = {
      ...payload,
      page,
    };
  }

  if (search) {
    payload = {
      ...payload,
      search,
    };
  }

  if (casetype) {
    payload = {
      ...payload,
      casetype,
    };
  }

  if (status) {
    payload = {
      ...payload,
      status,
    };
  }

  if (userId) {
    payload = {
      ...payload,
      userId,
    };
  }

  return await request.get<GetBusinessApplicationsRes>(
    `/business/get-applications`,
    {
      params: {
        ...payload,
      },
    }
  );
};

export const getSingleBusinessApplication = async (applicationId: string) => {
  return await request.get<GetSingleBusinessApplicationRes>(
    `/business/get-application/${applicationId}`
  );
};

export const getTotalCases = async () => {
  return await request.get<ApiResponse>(`/business/get-total-cases`);
};

export const getTotalPaidCases = async () => {
  return await request.get<ApiResponse>(`business/get-total-paid-cases`);
};

export const getTotalPendingCases = async () => {
  return await request.get<ApiResponse>(`/business/get-total-pending-cases`);
};

export const getTotalCompletedCases = async () => {
  return await request.get<ApiResponse>(`business/get-completed-cases`);
};
