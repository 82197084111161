import styled from "styled-components";
import { FC } from "react";
import Colors from "../../../styles/Colors";
import FormSectionWrapper from "../components/FormSectionWrapper";
import { Form, Formik } from "formik";
import { RadioLabel, RadioWrapper } from "../components/styles";
import RadioField from "../components/formik fields/RadioField";
import {
  ApplicationPreparerValues,
  ReceiveInterpreterHelpValues,
} from "../components/type";
import { PreparerInterpreterValidation } from "./components/Validation";
import { PreparerInterpreterObj } from "../../../api/utils/types";
import CheckBoxField from "../components/formik fields/CheckBoxField";
import InterpreterSection from "./components/InterpreterSection";
import InterpreterInfoSection from "./components/InterpreterInfoSection";
import FormBtn from "../components/FormBtn";
import PreparerInfoSection from "./components/PreparerInfoSection";
import AccreditedRepSection from "./components/AccreditedRepSection";
import { Modify, SingleOption } from "../../../types/types";
import { ApartmentSuiteFloorData, initSelect } from "../components/data";
import useCountries from "../components/useCountries";
import useStatesByCountry from "../components/useStatesByCountry";

export interface FormData
  extends Modify<
    PreparerInterpreterObj,
    {
      interpreterApartmentSuiteOrFloor: SingleOption | null;
      preparerApartmentSuiteOrFloor: SingleOption | null;
      interpreterCountry: SingleOption | null;
      interpreterStateOrTerritory: SingleOption | null;
      preparerCountry: SingleOption | null;
      preparerStateOrTerritory: SingleOption | null;
    }
  > {}

interface Props {
  ptData?: PreparerInterpreterObj;
  onMutate: (values: PreparerInterpreterObj) => void;
  isSaving: boolean;
  isFetching: boolean;
  isError: boolean;
}

const PreparerInterpreter: FC<Props> = ({
  ptData,
  onMutate,
  isSaving,
  isFetching,
  isError,
}) => {
  const {
    formattedCountryData: interpreterCountryData,
    countryLoading: interpreterCountryLoading,
  } = useCountries();
  const {
    formattedStateData: interpreterStateData,
    stateLoading: interpreterStateLoading,
    setCountry: interpreterSetCountry,
    selectedCountry: interpreterSelectedCountry,
  } = useStatesByCountry(ptData?.interpreterCountry);

  const {
    formattedCountryData: preparerCountryData,
    countryLoading: preparerCountryLoading,
  } = useCountries();
  const {
    formattedStateData: preparerStateData,
    stateLoading: preparerStateLoading,
    setCountry: preparerSetCountry,
    selectedCountry: preparerSelectedCountry,
  } = useStatesByCountry(ptData?.preparerCountry);

  const onSubmit = (values: FormData) => {
    const temp = {
      applicationPreparer: values.applicationPreparer,
      representationOfApplicant: values.representationOfApplicant,
      preparerFirstName: values.preparerFirstName,
      preparerLastName: values.preparerLastName,
      preparerFirm: values.preparerFirm,
      stateBarNum: values.stateBarNum,
      uscisOnlineAccountNumber: values.uscisOnlineAccountNumber,
      preparerAddress: values.preparerAddress,
      preparerStreetNumAndName: values.preparerStreetNumAndName,
      preparerApartmentSuiteOrFloor:
        values.preparerApartmentSuiteOrFloor?.value || "",
      preparerAddressNum: values.preparerAddressNum,
      preparerCityOrTown: values.preparerCityOrTown,
      preparerCountry: values.preparerCountry?.value || "",
      preparerStateOrTerritory: values.preparerStateOrTerritory?.value || "",
      preparerZipCode: values.preparerZipCode,
      preparerDayTimePhoneNumber: values.preparerDayTimePhoneNumber,
      preparerMobilePhoneNumber: values.preparerMobilePhoneNumber,
      preparerEmail: values.preparerEmail,
      receiveInterpreterHelp: values.receiveInterpreterHelp,
      englishConfirmation: values.englishConfirmation,
      language: values.language,
      interpreterFirstName: values.interpreterFirstName,
      interpreterLastName: values.interpreterLastName,
      interpreterFirm: values.interpreterFirm,
      interpreterAddress: values.interpreterAddress,
      interpreterStreetNumAndName: values.interpreterStreetNumAndName,
      interpreterApartmentSuiteOrFloor:
        values.interpreterApartmentSuiteOrFloor?.value || "",
      interpreterAddressNum: values.interpreterAddressNum,
      interpreterCityOrTown: values.interpreterCityOrTown,
      interpreterCountry: values.interpreterCountry?.value || "",
      interpreterStateOrTerritory:
        values.interpreterStateOrTerritory?.value || "",
      interpreterZipCode: values.interpreterZipCode,
      interpreterDayTimePhoneNumber: values.interpreterDayTimePhoneNumber,
      interpreterMobilePhoneNumber: values.interpreterMobilePhoneNumber,
      interpreterEmail: values.interpreterEmail,
      interpreterAcknowledgement: values.interpreterAcknowledgement,
      preparerAcknowledgement: values.preparerAcknowledgement,
    };
    onMutate(temp);
  };

  const initialValues: FormData = {
    applicationPreparer: ptData?.applicationPreparer || "",
    receiveInterpreterHelp: ptData?.receiveInterpreterHelp || "",
    language: ptData?.language || "",
    interpreterFirstName: ptData?.interpreterFirstName || "",
    interpreterLastName: ptData?.interpreterLastName || "",
    interpreterFirm: ptData?.interpreterFirm || "",
    interpreterAddress: ptData?.interpreterAddress || "",
    interpreterStreetNumAndName: ptData?.interpreterStreetNumAndName || "",
    interpreterApartmentSuiteOrFloor: initSelect(
      ApartmentSuiteFloorData,
      ptData?.interpreterApartmentSuiteOrFloor
    ),
    interpreterAddressNum: ptData?.interpreterAddressNum || "",
    interpreterCityOrTown: ptData?.interpreterCityOrTown || "",
    interpreterCountry: initSelect(
      interpreterCountryData,
      ptData?.interpreterCountry
    ),
    interpreterDayTimePhoneNumber: ptData?.interpreterDayTimePhoneNumber || "",
    interpreterMobilePhoneNumber: ptData?.interpreterMobilePhoneNumber || "",
    interpreterStateOrTerritory: initSelect(
      interpreterStateData,
      ptData?.interpreterStateOrTerritory
    ),
    interpreterZipCode: ptData?.interpreterZipCode || "",
    interpreterEmail: ptData?.interpreterEmail || "",
    preparerFirstName: ptData?.preparerFirstName || "",
    preparerLastName: ptData?.preparerLastName || "",
    preparerFirm: ptData?.preparerFirm || "",
    preparerAddress: ptData?.preparerAddress || "",
    preparerStreetNumAndName: ptData?.preparerStreetNumAndName || "",
    preparerApartmentSuiteOrFloor: initSelect(
      ApartmentSuiteFloorData,
      ptData?.preparerApartmentSuiteOrFloor
    ),
    preparerAddressNum: ptData?.preparerAddressNum || "",
    preparerCityOrTown: ptData?.preparerCityOrTown || "",
    preparerCountry: initSelect(preparerCountryData, ptData?.preparerCountry),
    preparerDayTimePhoneNumber: ptData?.preparerDayTimePhoneNumber || "",
    preparerMobilePhoneNumber: ptData?.preparerMobilePhoneNumber || "",
    preparerStateOrTerritory: initSelect(
      preparerStateData,
      ptData?.preparerStateOrTerritory
    ),
    preparerZipCode: ptData?.preparerZipCode || "",
    preparerEmail: ptData?.preparerEmail || "",

    englishConfirmation: ptData?.englishConfirmation || false,
    interpreterAcknowledgement: ptData?.interpreterAcknowledgement || false,
    preparerAcknowledgement: ptData?.preparerAcknowledgement || false,
    representationOfApplicant: ptData?.representationOfApplicant || "",
    stateBarNum: ptData?.stateBarNum || "",
    uscisOnlineAccountNumber: ptData?.uscisOnlineAccountNumber || "",
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={PreparerInterpreterValidation}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <div>
                  <SubTitle>Preparer</SubTitle>
                  <RadioWrapper>
                    <RadioLabel>Who prepared this document?</RadioLabel>
                    <AppFlex>
                      <RadioField
                        name="applicationPreparer"
                        value={ApplicationPreparerValues.iAmTheApplicant}
                        label="I am the applicant, and I prepared this application"
                      />
                      <RadioField
                        name="applicationPreparer"
                        value={ApplicationPreparerValues.iAmApreparer}
                        label="I am NOT an attorney or accredited representative but have prepared this application on behalf of the applicant and with the applicant's consent."
                      />
                      <RadioField
                        name="applicationPreparer"
                        value={ApplicationPreparerValues.iAmTheAttorney}
                        label="I am an attorney or accredited representative of the applicant that prepared this application."
                      />
                    </AppFlex>
                  </RadioWrapper>
                </div>
                <div></div>
              </FormWrapper>

              {formik.values.applicationPreparer ===
                ApplicationPreparerValues.iAmTheApplicant && (
                <>
                  <ExtraWrapper>
                    <InterpreterSection />
                  </ExtraWrapper>

                  {formik.values.receiveInterpreterHelp ===
                    ReceiveInterpreterHelpValues.yes && (
                    <ExtraWrapper>
                      <InterpreterInfoSection
                        formik={formik}
                        interpreterCountryData={interpreterCountryData}
                        interpreterCountryLoading={interpreterCountryLoading}
                        interpreterStateData={interpreterStateData}
                        interpreterSetCountry={interpreterSetCountry}
                        interpreterStateLoading={interpreterStateLoading}
                        interpreterSelectedCountry={interpreterSelectedCountry}
                      />
                    </ExtraWrapper>
                  )}

                  {formik.values.receiveInterpreterHelp ===
                    ReceiveInterpreterHelpValues.no && (
                    <Footer>
                      <TermsWrapper>
                        <CheckBoxField
                          label={`I can read and understand English, and have 
        read and understand every question and instruction on this application, as well as my answer to every question.`}
                          name="englishConfirmation"
                        />
                      </TermsWrapper>
                    </Footer>
                  )}
                </>
              )}

              {formik.values.applicationPreparer ===
                ApplicationPreparerValues.iAmApreparer && (
                <ExtraWrapper>
                  <PreparerInfoSection
                    formik={formik}
                    preparerCountryData={preparerCountryData}
                    preparerCountryLoading={preparerCountryLoading}
                    preparerStateData={preparerStateData}
                    preparerSetCountry={preparerSetCountry}
                    preparerStateLoading={preparerStateLoading}
                    preparerSelectedCountry={preparerSelectedCountry}
                  />
                </ExtraWrapper>
              )}

              {formik.values.applicationPreparer ===
                ApplicationPreparerValues.iAmTheAttorney && (
                <ExtraWrapper>
                  <AccreditedRepSection
                    formik={formik}
                    preparerCountryData={preparerCountryData}
                    preparerCountryLoading={preparerCountryLoading}
                    preparerStateData={preparerStateData}
                    preparerSetCountry={preparerSetCountry}
                    preparerStateLoading={preparerStateLoading}
                    preparerSelectedCountry={preparerSelectedCountry}
                  />
                </ExtraWrapper>
              )}

              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default PreparerInterpreter;
const Wrapper = styled.div``;

const FormWrapper = styled.div`
  margin-top: 40px;
`;

const SubTitle = styled.div`
  padding-bottom: 6px;
  border-bottom: 1px solid ${Colors.GreyA7};
  margin-bottom: 18px;
`;

const ExtraWrapper = styled.div`
  margin-top: 24px;
`;

const Footer = styled.div`
  border-top: 1px solid ${Colors.GreyA7};
  padding-top: 33px;
`;

const TermsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: ${Colors.Dark6B};

  & > input {
    width: 24px;
    height: 24px;
  }
`;

const AppFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
