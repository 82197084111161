import React from "react";
import { useParams } from "react-router-dom";
import { FormCategories } from "../../../routes/type";
import F565 from "../form 565";
import F400 from "../form 400";
import DS160 from "../form ds 160";
import F600 from "../form 600";

const FormCategoryPage = () => {
  const { formCategory } = useParams();
  return (
    <>
      {formCategory === FormCategories.n565 && <F565 />}
      {formCategory === FormCategories.n400 && <F400 />}
      {formCategory === FormCategories.n600 && <F600 />}
      {formCategory === FormCategories.ds160 && <DS160 />}
    </>
  );
};

export default FormCategoryPage;
