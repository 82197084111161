import React, { FC } from "react";
import styled from "styled-components";
import Colors from "../../../../../../styles/Colors";

interface Props {
  status: boolean;
}

const StatusTag: FC<Props> = ({ status }) => {
  const text = status ? "completed" : "pending";
  return <Status status={text}>{text}</Status>;
};

export default StatusTag;

interface StatusProps {
  status: "completed" | "pending";
}

const Status = styled.span<StatusProps>`
  font-size: 14px;
  color: ${({ status }) => (status === "completed" ? "#019132" : Colors.RedDA)};
  text-transform: capitalize;
  font-weight: 600;
`;
