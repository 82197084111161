import React, { useEffect } from "react";
import styled from "styled-components";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { Form, Formik } from "formik";
import InputField from "../../../components/formik fields/InputField";
import { FormWrapper } from "../../../components/styles";
import DatePickerField from "../../../components/formik fields/DatePickerField";
import {
  ApartmentSuiteFloorData,
  GenericYesNo,
  GenericYesNoOptionsValue,
  MaritalStatusData,
  MaritalStatusValues,
  ToSaveDate,
  initDate,
  initInput,
  initSelect,
  //   resetDynamicFields,
} from "../../../components/data";
import SelectField from "../../../components/formik fields/SelectField";
import useCountries from "../../../components/useCountries";
import RadioComp from "../../../components/RadioComp";
import useStatesByCountry from "../../../components/useStatesByCountry";
import { Modify, SingleOption } from "../../../../../types/types";
import { Dayjs } from "dayjs";
import { Info } from "../../../form ds 160/components/styles";
import FormBtn from "../../../components/FormBtn";
import { N600InfoAboutUSCitizenMotherPart2Payload } from "../../../../../api/n600/types";
import useToNextSection from "../../../../../hooks/useToNextSection";
import {
  useGetN600Eligibility,
  useGetN600InfoAboutUSCitizenMotherPart2,
  useSaveN600InfoAboutUSCitizenMotherPart2,
} from "../../../../../hooks/n600/useN600";
import { USCitizenMotherP2Validation } from "../validation";
import {
  SpouseImmigrationStatusData,
  SpouseImmigrationStatusValues,
} from "../data";

export interface USCitizenMotherP2FormData
  extends Modify<
    N600InfoAboutUSCitizenMotherPart2Payload,
    {
      motherCurrentMaritalStatus: SingleOption | null;
      motherSpouseDateOfBirth: Dayjs | null;

      motherSpouseCountryOfBirth: SingleOption | null;
      motherSpouseCountryOfCitizenship: SingleOption | null;
      motherSpousePhysicalAddressAptSteFlr: SingleOption | null;
      motherSpousePhysicalAddressState: SingleOption | null;
      motherSpousePhysicalAddressCountry: SingleOption | null;
      motherDateOfMarriage: Dayjs | null;
      motherPlaceOfMarriageState: SingleOption | null;
      motherPlaceOfMarriageCountry: SingleOption | null;
      motherSpouseImmigrationStatus: SingleOption | null;
    }
  > {}

const USCitizenMotherP2 = () => {
  const { data, isFetching, isError } =
    useGetN600InfoAboutUSCitizenMotherPart2();
  const { mutate, isLoading: isSaving } =
    useSaveN600InfoAboutUSCitizenMotherPart2();

  const { data: eligibilityData } = useGetN600Eligibility();

  const { toNextSection } = useToNextSection();

  const isMotherCitizen =
    eligibilityData?.which_parent_is_a_us_citizen ===
    "My mother is a U.S citizen";

  //skip this section if not to study
  useEffect(() => {
    if (!isMotherCitizen) {
      toNextSection();
    }
  }, [isMotherCitizen, toNextSection]);

  const {
    formattedCountryData: motherSpouseCountryOfBirthData,
    countryLoading: motherSpouseCountryOfBirthLoading,
  } = useCountries();

  const {
    formattedCountryData: motherSpouseCountryOfCitizenshipData,
    countryLoading: motherSpouseCountryOfCitizenshipLoading,
  } = useCountries();

  const {
    formattedCountryData: motherPlaceOfMarriageCountryData,
    countryLoading: motherPlaceOfMarriageCountryLoading,
  } = useCountries();

  const {
    formattedStateData: motherPlaceOfMarriageStateData,
    stateLoading: motherPlaceOfMarriageStateLoading,
    setCountry: motherPlaceOfMarriageSetCountry,
    selectedCountry: motherPlaceOfMarriageStateSelectedCountry,
  } = useStatesByCountry(data?.motherPlaceOfMarriageCountry);

  const {
    formattedCountryData: motherSpousePhysicalAddressCountryData,
    countryLoading: motherSpousePhysicalAddressCountryLoading,
  } = useCountries();

  const {
    formattedStateData: motherSpousePhysicalAddressStateData,
    stateLoading: motherSpousePhysicalAddressStateLoading,
    setCountry: setMotherSpousePhysicalAddressSetCountry,
    selectedCountry: motherSpousePhysicalAddressSelectedCountry,
  } = useStatesByCountry(
    data?.motherSpousePhysicalAddressCountry || "United States"
  );

  const onSubmit = (values: USCitizenMotherP2FormData) => {
    const {
      motherCurrentMaritalStatus,
      motherSpouseDateOfBirth,
      motherSpouseCountryOfBirth,
      motherSpouseCountryOfCitizenship,
      motherSpousePhysicalAddressAptSteFlr,
      motherSpousePhysicalAddressState,
      motherSpousePhysicalAddressCountry,
      motherDateOfMarriage,
      motherPlaceOfMarriageState,
      motherPlaceOfMarriageCountry,
      motherSpouseImmigrationStatus,
      ...rest
    } = values;

    const payload = {
      ...rest,
      motherCurrentMaritalStatus: motherCurrentMaritalStatus?.value || "",
      motherSpouseDateOfBirth: ToSaveDate(motherSpouseDateOfBirth),
      motherSpouseCountryOfBirth: motherSpouseCountryOfBirth?.value || "",
      motherSpouseCountryOfCitizenship:
        motherSpouseCountryOfCitizenship?.value || "",
      motherSpousePhysicalAddressAptSteFlr:
        motherSpousePhysicalAddressAptSteFlr?.value || "",
      motherSpousePhysicalAddressState:
        motherSpousePhysicalAddressState?.value || "",
      motherSpousePhysicalAddressCountry:
        motherSpousePhysicalAddressCountry?.value || "",
      motherDateOfMarriage: ToSaveDate(motherDateOfMarriage),
      motherPlaceOfMarriageState: motherPlaceOfMarriageState?.value || "",
      motherPlaceOfMarriageCountry: motherPlaceOfMarriageCountry?.value || "",
      motherSpouseImmigrationStatus: motherSpouseImmigrationStatus?.value || "",
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValues: USCitizenMotherP2FormData = {
    motherLostCitizenship: initInput(data?.motherLostCitizenship), // Has your mother ever lost U.S. citizenship or taken any action that would cause loss of U.S. citizenship?
    motherLostCitizenshipExplanation: initInput(
      data?.motherLostCitizenshipExplanation
    ), // Explanation if mother lost U.S. citizenship or took any action that would cause loss of U.S. citizenship

    motherNumberOfMarriages: initInput(data?.motherNumberOfMarriages), // How many times has your U.S. citizen mother been married (including annulled marriages and marriages to the same person)?
    motherCurrentMaritalStatus: initSelect(
      MaritalStatusData,
      data?.motherCurrentMaritalStatus
    ), // What is your U.S. citizen mother's current marital status?
    motherCurrentMaritalStatusExplanation: initInput(
      data?.motherCurrentMaritalStatusExplanation
    ), // Explanation if mother's current marital status requires it

    // Information About U.S. Citizen Mother's Current Spouse:
    motherSpouseFamilyName: initInput(data?.motherSpouseFamilyName), // Family Name (Last Name)
    motherSpouseGivenName: initInput(data?.motherSpouseGivenName), // Given Name (First Name)
    motherSpouseMiddleName: initInput(data?.motherSpouseMiddleName), // Middle Name
    motherSpouseDateOfBirth: initDate(data?.motherSpouseDateOfBirth), // Date of Birth (mm/dd/yyyy)
    motherSpouseCountryOfBirth: initSelect(
      motherSpouseCountryOfBirthData,
      data?.motherSpouseCountryOfBirth
    ), // Country of Birth
    motherSpouseCountryOfCitizenship: initSelect(
      motherSpouseCountryOfCitizenshipData,
      data?.motherSpouseCountryOfCitizenship
    ), // Country of Citizenship or Nationality

    // Spouse's Physical Address:
    motherSpousePhysicalAddressStreetNumberAndName: initInput(
      data?.motherSpousePhysicalAddressStreetNumberAndName
    ), // Street Number and Name
    motherSpousePhysicalAddressAptSteFlr: initSelect(
      ApartmentSuiteFloorData,
      data?.motherSpousePhysicalAddressAptSteFlr
    ), // Apt. Ste. Flr.
    motherSpousePhysicalAddressAptSteFlrNumber: initInput(
      data?.motherSpousePhysicalAddressAptSteFlrNumber
    ), // Apt. Ste. Flr. Number
    motherSpousePhysicalAddressCityOrTown: initInput(
      data?.motherSpousePhysicalAddressCityOrTown
    ), // City or Town
    motherSpousePhysicalAddressState: initSelect(
      motherSpousePhysicalAddressStateData,
      data?.motherSpousePhysicalAddressState
    ), // State
    motherSpousePhysicalAddressZipCode: initInput(
      data?.motherSpousePhysicalAddressZipCode
    ), // ZIP Code
    motherSpousePhysicalAddressProvince: initInput(
      data?.motherSpousePhysicalAddressProvince
    ), // Province (foreign address only)
    motherSpousePhysicalAddressPostalCode: initInput(
      data?.motherSpousePhysicalAddressPostalCode
    ), // Postal Code (foreign address only)
    motherSpousePhysicalAddressCountry: initSelect(
      motherSpousePhysicalAddressCountryData,
      data?.motherSpousePhysicalAddressCountry
    ), // Country (foreign address only)

    motherDateOfMarriage: initDate(data?.motherDateOfMarriage), // Date of Marriage (mm/dd/yyyy)

    // Place of Marriage:
    motherPlaceOfMarriageCityOrTown: initInput(
      data?.motherPlaceOfMarriageCityOrTown
    ), // City or Town
    motherPlaceOfMarriageState: initSelect(
      motherPlaceOfMarriageStateData,
      data?.motherPlaceOfMarriageState
    ), // State
    motherPlaceOfMarriageCountry: initSelect(
      motherPlaceOfMarriageCountryData,
      data?.motherPlaceOfMarriageCountry
    ), // Country

    motherSpouseImmigrationStatus: initSelect(
      SpouseImmigrationStatusData,
      data?.motherSpouseImmigrationStatus
    ), // Spouse's Immigration Status
    motherSpouseImmigrationStatusExplanation: initInput(
      data?.motherSpouseImmigrationStatusExplanation
    ), // Explanation if spouse's immigration status requires it

    isMotherCurrentSpouseBiologicalOrAdoptiveFather: initInput(
      data?.isMotherCurrentSpouseBiologicalOrAdoptiveFather
    ), // Is your U.S. citizen mother's current spouse also your biological (or adopted) mother?
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={USCitizenMotherP2Validation}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <RadioComp
                  title="Has your mother ever lost U.S. citizenship or taken any action that would cause loss of U.S. citizenship?"
                  name="motherLostCitizenship"
                  options={GenericYesNo}
                />
              </FormWrapper>

              {formik.values.motherLostCitizenship ===
                GenericYesNoOptionsValue.yes && (
                <FormWrapper>
                  <InputField
                    label="Explain"
                    name="motherLostCitizenshipExplanation"
                    placeholder=""
                  />
                  <div></div>
                </FormWrapper>
              )}

              <Info>Marital History</Info>

              <FormWrapper>
                <InputField
                  label="How many times has your U.S. citizen mother been married?"
                  coloredLabel="(including annulled marriages and marriages to the same person)"
                  name="motherNumberOfMarriages"
                  placeholder=""
                />
                <div></div>
              </FormWrapper>

              <FormWrapper>
                <SelectField
                  label="What is your U.S. citizen mother's current marital status?"
                  name="motherCurrentMaritalStatus"
                  options={MaritalStatusData}
                  onChange={(value) => {
                    // console.log(value);
                    // console.log(
                    //   formik.values.motherCurrentMaritalStatus?.value
                    // );
                    formik.setFieldValue(
                      "motherCurrentMaritalStatusExplanation",
                      ""
                    );
                    formik.setFieldValue("motherSpouseFamilyName", "");
                    formik.setFieldValue("motherSpouseGivenName", "");
                    formik.setFieldValue("motherSpouseMiddleName", "");
                    formik.setFieldValue(
                      "motherSpousePhysicalAddressStreetNumberAndName",
                      ""
                    );
                    formik.setFieldValue(
                      "motherSpousePhysicalAddressAptSteFlr",
                      ""
                    );
                    formik.setFieldValue(
                      "motherSpousePhysicalAddressAptSteFlrNumber",
                      ""
                    );
                    formik.setFieldValue(
                      "motherSpousePhysicalAddressCityOrTown",
                      ""
                    );
                    formik.setFieldValue(
                      "motherSpousePhysicalAddressZipCode",
                      ""
                    );
                    formik.setFieldValue(
                      "motherSpousePhysicalAddressProvince",
                      ""
                    );
                    formik.setFieldValue(
                      "motherSpousePhysicalAddressPostalCode",
                      ""
                    );
                    formik.setFieldValue("motherPlaceOfMarriageCityOrTown", "");
                    formik.setFieldValue("motherSpouseDateOfBirth", null);
                    formik.setFieldValue("motherSpouseCountryOfBirth", null);
                    formik.setFieldValue(
                      "motherSpouseCountryOfCitizenship",
                      null
                    );
                    formik.setFieldValue(
                      "motherSpousePhysicalAddressState",
                      null
                    );
                    formik.setFieldValue(
                      "motherSpousePhysicalAddressCountry",
                      null
                    );
                    formik.setFieldValue("motherDateOfMarriage", null);
                    formik.setFieldValue("motherPlaceOfMarriageState", null);
                    formik.setFieldValue("motherPlaceOfMarriageCountry", null);
                  }}
                />
                <div></div>
              </FormWrapper>

              {formik.values.motherCurrentMaritalStatus?.value ===
                MaritalStatusValues.other && (
                <FormWrapper>
                  <InputField
                    label="Explain"
                    name="motherCurrentMaritalStatusExplanation"
                    placeholder=""
                  />
                  <div></div>
                </FormWrapper>
              )}

              {formik.values.motherCurrentMaritalStatus?.value ===
                MaritalStatusValues.married && (
                <>
                  <Info>
                    Information About U.S. Citizen Mother's Current Spouse:
                  </Info>
                  <FormWrapper>
                    <InputField
                      label="Family Name (Last Name)"
                      name="motherSpouseFamilyName"
                      placeholder=""
                    />

                    <InputField
                      label="Given Name (First Name)"
                      name="motherSpouseGivenName"
                      placeholder=""
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <InputField
                      label="Middle Name"
                      name="motherSpouseMiddleName"
                      placeholder=""
                    />
                    <div></div>
                  </FormWrapper>

                  <FormWrapper>
                    <DatePickerField
                      label="Date of Birth (mm/dd/yyyy)"
                      name="motherSpouseDateOfBirth"
                    />

                    <SelectField
                      label="Country of Birth"
                      name="motherSpouseCountryOfBirth"
                      placeholder="-Select-"
                      options={motherSpouseCountryOfBirthData}
                      isDisabled={motherSpouseCountryOfBirthLoading}
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <SelectField
                      label="Country of Citizenship or Nationality"
                      name="motherSpouseCountryOfCitizenship"
                      placeholder="-Select-"
                      options={motherSpouseCountryOfCitizenshipData}
                      isDisabled={motherSpouseCountryOfCitizenshipLoading}
                    />

                    <div></div>
                  </FormWrapper>

                  <Info>Spouse's Physical Address:</Info>

                  <FormWrapper>
                    <InputField
                      label="Street Number and Name"
                      name="motherSpousePhysicalAddressStreetNumberAndName"
                      placeholder=""
                    />

                    <div></div>
                  </FormWrapper>

                  <FormWrapper>
                    <SelectField
                      label="Apartment Suite or Floor"
                      name="motherSpousePhysicalAddressAptSteFlr"
                      placeholder="-Select-"
                      options={ApartmentSuiteFloorData}
                    />

                    <div>
                      {formik.values.motherSpousePhysicalAddressAptSteFlr
                        ?.value && (
                        <InputField
                          label={`${formik.values.motherSpousePhysicalAddressAptSteFlr?.value} Number`}
                          name="motherSpousePhysicalAddressAptSteFlrNumber"
                          placeholder=""
                        />
                      )}
                    </div>
                  </FormWrapper>

                  <FormWrapper>
                    <InputField
                      label="City or Town"
                      name="motherSpousePhysicalAddressCityOrTown"
                      placeholder=""
                    />

                    <SelectField
                      label="State"
                      name="motherSpousePhysicalAddressState"
                      placeholder="-Select-"
                      options={motherSpousePhysicalAddressStateData}
                      isLoading={motherSpousePhysicalAddressStateLoading}
                      isDisabled={!motherSpousePhysicalAddressSelectedCountry}
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <InputField
                      label="ZIP Code"
                      name="motherSpousePhysicalAddressZipCode"
                      placeholder=""
                    />

                    <InputField
                      label="Province"
                      coloredLabel="(foreign address only)"
                      name="motherSpousePhysicalAddressProvince"
                      placeholder=""
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <InputField
                      label="Postal Code"
                      coloredLabel="(foreign address only)"
                      name="motherSpousePhysicalAddressPostalCode"
                      placeholder=""
                    />

                    <SelectField
                      name="motherSpousePhysicalAddressCountry"
                      label="Country"
                      coloredLabel="(foreign address only)"
                      placeholder="-Select-"
                      options={motherSpousePhysicalAddressCountryData}
                      isLoading={motherSpousePhysicalAddressCountryLoading}
                      onChange={(value) => {
                        // console.log(value);
                        // console.log(
                        //   formik.values.motherSpousePhysicalAddressCountry
                        //     ?.value
                        // );
                        setMotherSpousePhysicalAddressSetCountry(value.label);
                        formik.setFieldValue(
                          "motherSpousePhysicalAddressState",
                          null
                        );
                      }}
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <DatePickerField
                      label="Date of Marriage (mm/dd/yyyy)"
                      name="motherDateOfMarriage"
                    />

                    <div></div>
                  </FormWrapper>

                  <Info>Place of Marriage:</Info>

                  <FormWrapper>
                    <InputField
                      label="City or Town"
                      name="motherPlaceOfMarriageCityOrTown"
                      placeholder=""
                    />

                    <SelectField
                      label="State"
                      name="motherPlaceOfMarriageState"
                      placeholder="-Select-"
                      options={motherPlaceOfMarriageStateData}
                      isLoading={motherPlaceOfMarriageStateLoading}
                      isDisabled={!motherPlaceOfMarriageStateSelectedCountry}
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <SelectField
                      label="Country"
                      name="motherPlaceOfMarriageCountry"
                      placeholder="-Select-"
                      options={motherPlaceOfMarriageCountryData}
                      isLoading={motherPlaceOfMarriageCountryLoading}
                      onChange={(value) => {
                        // console.log(value);
                        // console.log(
                        //   formik.values.motherPlaceOfMarriageCountry?.value
                        // );
                        motherPlaceOfMarriageSetCountry(value.label);
                        formik.setFieldValue(
                          "motherPlaceOfMarriageState",
                          null
                        );
                      }}
                    />

                    <div></div>
                  </FormWrapper>

                  <FormWrapper>
                    <SelectField
                      label="Spouse's Immigration Status"
                      name="motherSpouseImmigrationStatus"
                      placeholder="-Select-"
                      options={SpouseImmigrationStatusData}
                      onChange={(value) => {
                        formik.setFieldValue(
                          "motherSpouseImmigrationStatusExplanation",
                          ""
                        );
                      }}
                    />

                    <div>
                      {formik.values.motherSpouseImmigrationStatus?.value ===
                        SpouseImmigrationStatusValues.other && (
                        <InputField
                          label="Explain"
                          name="motherSpouseImmigrationStatusExplanation"
                          placeholder=""
                        />
                      )}
                    </div>
                  </FormWrapper>

                  <FormWrapper>
                    <RadioComp
                      title="Is your U.S. citizen mother's current spouse also your biological (or adopted) father?"
                      name="isMotherCurrentSpouseBiologicalOrAdoptiveFather"
                      options={GenericYesNo}
                    />
                  </FormWrapper>
                </>
              )}

              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default USCitizenMotherP2;

const Wrapper = styled.div``;
