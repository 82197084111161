import React from "react";
import Constaint from "../../../layout/components/Constaint";
import styled from "styled-components";
import RequestButton from "./RequestButton";
import Colors from "../../../../../styles/Colors";
import businessImageBanner from "../../../../../images/web/business-banner-img.jpg";

const Banner: React.FC = () => {
  return (
    <Wrapper>
      <Constaint>
        <Content>
          <LeftContent>
            <h1>Maximize Visa Approval with AI Risk Checker</h1>
            <p>And Manage All Visa Applications from One Dashboard</p>
            <RequestButton />
          </LeftContent>
          <RightContent>
            <ArtifactOne />
            <ArtifactTwo />
            <img src={businessImageBanner} alt="sv-for-business-banner" />
          </RightContent>
        </Content>
      </Constaint>
    </Wrapper>
  );
};

export default Banner;

const Wrapper = styled.div``;

const Content = styled.div`
  padding: 4rem 0;
  min-height: 75dvh;
  margin: 0 auto;
  gap: 24px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    padding-top: 6rem;
    gap: 48px;
    flex-direction: row;
  }
`;

const LeftContent = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  flex: 1;

  & > h1 {
    font-size: 48px;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 24px;
    color: ${Colors.Blue00};
  }

  & > p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 48px;
    color: ${Colors.Black1A};
  }
`;

const RightContent = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: space-between; */

  position: relative;
  flex: 1;

  & > img {
    height: 500px;
    width: 100%;

    object-fit: cover;
  }
`;

const ArtifactOne = styled.div`
  position: absolute;
  top: -50px;
  left: 100px;
  width: 300px;
  height: 150px;
  background-color: #e5f1fd;
  z-index: -10;
  /* background-image: url("https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80");
  background-size: cover;
  background-position: center; */
`;

const ArtifactTwo = styled.div`
  position: absolute;
  top: -80px;
  right: -70px;
  width: 200px;
  height: 350px;
  background-color: #aad5ff;
  z-index: -10;
  /* background-image: url("https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80");
  background-size: cover;
  background-position: center; */
`;
