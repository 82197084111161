import { FC } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import ReactPaginate from "react-paginate";

interface Props {
  handlePageClick: (params: { selected: number }) => void;
  forcePage: number;
  dataLength: number;
  totalPages: number;
  pageLimit: number;
}

const TablePagination: FC<Props> = ({
  handlePageClick,
  forcePage,
  dataLength,
  totalPages,
  pageLimit,
}) => {
  const pageCount = totalPages > pageLimit ? totalPages / pageLimit : 0;
  const hasNextPage =
    forcePage * pageLimit + dataLength < totalPages ? true : false;

  return (
    <div
      className={`flex items-center justify-center ${
        hasNextPage ? "mt-6" : ""
      }`}
    >
      <ReactPaginate
        breakLabel="..."
        nextLabel={<IoIosArrowForward />}
        forcePage={forcePage}
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel={<IoIosArrowBack />}
        renderOnZeroPageCount={null}
        containerClassName={"flex items-center gap-2"}
        pageClassName="pagination-block"
        activeClassName="!bg-black !text-white"
        previousClassName="pagination-block"
        nextClassName={`pagination-block ${hasNextPage ? "" : "hidden"}`}
        breakClassName="pagination-block"
        disabledClassName="hidden"
      />
    </div>
  );
};

export default TablePagination;
