import React, { FC } from "react";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { n400EmploymentHistoryRes } from "../../../../../../api/n400/types";
import { FormN400Progress } from "../../types";

interface Props {
  title: string;
  data?: n400EmploymentHistoryRes;
}

const ReviewEmploymentHistory: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      section: {
        title: "Employment and School History",
        children: [
          {
            label: "Employer of School Name",
            value: data?.employerOrSchoolName.replace(/,/g, ", "),
          },
          {
            label: "City or Town",
            value: data?.cityOrTown.replace(/,/g, ", "),
          },
          {
            label: "State or Territory",
            value: data?.stateOrProvince.replace(/,/g, ", "),
          },
          {
            label: "Country",
            value: data?.country.replace(/,/g, ", "),
          },
          {
            label: "Zip Code",
            value: data?.zipCode.replace(/,/g, ", "),
          },
          {
            label: "Postal Code",
            value: data?.postalCode.replace(/,/g, ", "),
          },
          {
            label: "Your Occupation",
            value: data?.occupationOrFieldOfStudy.replace(/,/g, ", "),
          },
          {
            label: "Date from",
            value: data?.dateFrom.replace(/,/g, ", "),
          },
          {
            label: "Date to",
            value: data?.dateTo.replace(/,/g, ", "),
          },
        ],
      },
    },
  ];

  return (
    <ReviewBox
      title={title}
      data={section}
      editUrl={FormN400Progress.employmentAndSchoolHistory}
    />
  );
};

export default ReviewEmploymentHistory;
