import React from "react";
import styled from "styled-components";
import Constaint from "../../layout/components/Constaint";
import { TestimonialData } from "./data";
import { ReactComponent as QuoteImg } from "../../../../images/icons/quote.svg";
import Colors from "../../../../styles/Colors";
import { motion } from "framer-motion";
import {
  listItemContainerVariant,
  listItemVariant,
  TitleVariant,
} from "./variants";
import { useNavigate } from "react-router-dom";
import { WebPaths } from "../../../../routes/data";

const Testmonial = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Constaint>
        <Container>
          <Label>What our clients say about us.</Label>
          <Heading
            variants={TitleVariant}
            whileInView={"animate"}
            initial={"initial"}
            viewport={{ once: true }}
          >
            Testimonials
          </Heading>
          <CardFlex
            variants={listItemContainerVariant}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true }}
          >
            {TestimonialData.map((ev, i) => (
              <Card key={i} index={i} variants={listItemVariant}>
                <Quote index={i}>
                  <QuoteImg />
                </Quote>
                <Para index={i}>{ev.text}</Para>
                <Avatar>
                  {/* <AvatarImage src={ev.img} /> */}
                  <AvatarContent>
                    <AvatarHeading index={i}>{ev.name}</AvatarHeading>
                    {/* <AvatarFooter>{ev.location}</AvatarFooter> */}
                  </AvatarContent>
                </Avatar>
              </Card>
            ))}
          </CardFlex>

          <H2>Ready to take the first step? Click here to get started.</H2>
          <CardBtn onClick={() => navigate(WebPaths.Services)}>
            GET started
          </CardBtn>
        </Container>
      </Constaint>
    </Wrapper>
  );
};

export default Testmonial;

const Wrapper = styled.div`
  background-color: #f9fcff;
`;

const Container = styled.div`
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Label = styled.div`
  font-size: 1rem;
  line-height: 1.1875rem;
  color: #31393c;
  text-align: center;
  text-transform: uppercase;
`;

const Heading = styled(motion.h1)`
  margin-top: 16px;
  margin-bottom: 60px;
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 2.6875rem;
  text-align: center;
  color: #31393c;
`;

const CardFlex = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;

  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

interface CardProps {
  index: number;
}

const Card = styled(motion.div)<CardProps>`
  position: relative;
  width: 100%;
  /* height: 324px; */
  /* background: ${({ index }) => (index % 2 !== 0 ? "#435D76" : "#ffffff")}; */
  background-color: #ffffff;
  border-radius: 12px;
  padding: 100px 34px 31px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 23px;
  overflow-y: hidden;
`;

interface QuoteProps {
  index: number;
}

const Quote = styled.div<QuoteProps>`
  position: absolute;
  top: 20px;
  left: 34px;

  & > svg {
    /* fill: ${({ index }) =>
      index % 2 !== 0 ? "#A5B8CB" : "rgba(67, 93, 118, 0.51)"}; */
    fill: #0076e9;
    opacity: 0.5;
  }
`;

interface ParaProps {
  index: number;
}

const Para = styled.p<ParaProps>`
  font-style: italic;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
  /* color: ${({ index }) => (index % 2 !== 0 ? "#F9FCFF" : "#31393c")}; */
  color: #31393c;
`;

const Avatar = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

// const AvatarImage = styled.img`
//   width: 50px;
//   height: 50px;
//   border-radius: 100%;
// `;

const AvatarContent = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

interface AvatarHeadingProps {
  index: number;
}

const AvatarHeading = styled.div<AvatarHeadingProps>`
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
  /* color: ${({ index }) => (index % 2 !== 0 ? "#F9FCFF" : "#31393c")}; */
  color: #31393c;
`;
// const AvatarFooter = styled.div`
//   font-weight: 400;
//   font-size: 0.625rem;
//   line-height: 0.75rem;
//   color: #a7aabd;
// `;

const H2 = styled.h2`
  font-style: italic;
  font-weight: 600;
  font-size: 2.25rem;
  line-height: 2.6875rem;
  text-align: center;
  color: #31393c;
  margin-top: 100px;
  margin-bottom: 40px;
  width: 100%;

  @media screen and (min-width: 768px) {
    width: 60%;
  }
`;

const CardBtn = styled.button`
  width: 243px;
  height: 56px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.1875rem;
  text-transform: uppercase;
  color: #f9fcff;
  background: ${Colors.Blue00};

  &:hover {
    background: #022e58;
    color: #f9fcff;
    transition: 0.3s ease-in-out;
  }
`;
