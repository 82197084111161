import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import { FC, FormEvent, useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import Colors from "../../../../styles/Colors";
import { mediaObj } from "../../../../styles/Media";
import { QueriesKey } from "../type";

interface Props {
  placeholder: string;
  reverse?: boolean;
  onParamsChange?: (passedParams: URLSearchParams) => void;
}

const TableSearch: FC<Props> = ({ placeholder, reverse, onParamsChange }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchValueParams = searchParams.get(QueriesKey.search);
  const [search, setSearch] = useState("");

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (search.trim() === "") {
      searchParams.delete(QueriesKey.search);
      setSearchParams(searchParams);
      return;
    }
    onParamsChange && onParamsChange(searchParams);
    searchParams.set(QueriesKey.search, search.trim());
    setSearchParams(searchParams);
  };

  useEffect(() => {
    setSearch(searchValueParams || "");
  }, [searchValueParams]);

  return (
    <SearchWrapper onSubmit={onSubmit} $reverse={reverse}>
      <SearchField
        type="text"
        name="search"
        placeholder={placeholder}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <FiSearch onClick={onSubmit} />
    </SearchWrapper>
  );
};

export default TableSearch;

interface SearchWrapperProps {
  $reverse?: boolean;
}

const SearchWrapper = styled.form<SearchWrapperProps>`
  width: 276px;
  min-width: 200px;
  height: 40px;
  padding: 12px 16px 12px 16px;
  background-color: transparent;
  display: flex;
  align-items: center;
  flex-direction: ${({ $reverse }) => ($reverse ? "row-reverse" : "row")};
  gap: 8px;
  border: 1px solid ${Colors.GreyA7};
  border-radius: 4px;

  & > svg {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    color: ${Colors.GreyA7};
    cursor: pointer;
  }

  ${mediaObj.mobile} {
    width: 100%;
  }
`;

const SearchField = styled.input`
  width: 100%;
  border: none;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  background-color: transparent;
  color: ${Colors.Black31};

  &:focus-within {
    outline: none;
  }
`;
