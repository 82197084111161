export enum n400QueryKeys {
  getN400TimeOutsideUs = "getN400TimeOutsideUs",
  getN400ApplicantInfo1 = "getN400ApplicantInfo1",
  getN400ApplicantInfo2 = "getN400ApplicantInfo2",
  getN400BioInfo = "getN400BioInfo",
  getN400Accommodation = "getN400Accommodation",
  getN400CivicTestExemption = "getN400CivicTestExemption",
  getN400EnglishTestExemption = "getN400EnglishTestExemption",
  getN400AdditionalInfo = "getN400AdditionalInfo",
  getN400ContactInfo = "getN400ContactInfo",
  getN400AddressHistory = "getN400AddressHistory",
  getN400EmploymentHistory = "getN400EmploymentHistory",
  getN400MaritalHistory = "getN400MaritalHistory",
  getN400ChildrenInfo = "getN400ChildrenInfo",
  getN400CheckPart1 = "getN400CheckPart1",
  getN400CheckPart2 = "getN400CheckPart2",
  getN400CheckPart3 = "getN400CheckPart3",
  getN400CheckPart4 = "getN400CheckPart4",
  getN400CheckPart5 = "getN400CheckPart5",
  getN400CheckPart6 = "getN400CheckPart6",
  getN400Preparer = "getN400Preparer",
  getN400Form = "getN400Form",
  getN400Info = "get-N400-Info",
  getN400PaySuccess = "get-N400-pay-success",
  getN400PayFail = "get-N400-pay-fail",
  getN400Eligibility = "get-N400-eligibility",
  getN400Files = "get-N400-files",
  getN400Trigger = "get-N400-trigger",
  getN400FeeReduction = "get-N400-fee-reduction",
}
