const DS160DocTypeValues = [
  "Sponsor's Bank Statement",
  "Tax Returns Document",
  "Proof of Employment",
  "Proof of Business Ownership",
  "Property Ownership Documents",
  "Investment Statements",
  "Marriage Certificate",
  "Travel Insurance",
  "Letter of Sponsorship",
  "Scholarship Letter",
  "Invitation Letter",
  "Previous Travel History",
  "Flight Reservation",
  "Hotel Reservation",
  "Tour Itinerary",
  "Medical Records",
  "Police Clearance Certificate",
];

export enum DocTypeOthersValues {
  Others = "Others",
}

const temp = DS160DocTypeValues.map((ev) => ({
  label: ev,
  value: ev,
}));

temp.push({
  label: DocTypeOthersValues.Others,
  value: DocTypeOthersValues.Others,
});

export const DS160DocTypeOptions = temp;
