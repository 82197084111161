import { FC } from "react";
import styled from "styled-components";
import Colors from "../../../../../../../styles/Colors";

interface Props {
  status: boolean;
  trueText?: string;
  falseText?: string;
}

const StatusTag: FC<Props> = ({ status, trueText, falseText }) => {
  const text = status ? trueText || "completed" : falseText || "pending";
  return <Status status={status}>{text}</Status>;
};

export default StatusTag;

interface StatusProps {
  status: boolean;
}

const Status = styled.span<StatusProps>`
  font-size: 14px;
  color: ${({ status }) => (status ? "#019132" : Colors.RedDA)};
  text-transform: capitalize;
  font-weight: 600;
`;
