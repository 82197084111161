import { Form, Formik } from "formik";
import styled from "styled-components";
import {
  DocumentData,
  GenericYesNo,
  GenericYesNoOptionsValue,
  ImmigrationStatus,
  ImmigrationStatusValues,
  initDate,
  initInput,
  initSelect,
  ToSaveDate,
  USDateFormat,
} from "../../../components/data";
import FormBtn from "../../../components/FormBtn";
import InputField from "../../../components/formik fields/InputField";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import RadioComp from "../../../components/RadioComp";
import { FormWrapper, ModuleTitle } from "../../../components/styles";

import { N600InfoAboutYouPart3Payload } from "../../../../../api/n600/types";
import { Modify, SingleOption } from "../../../../../types/types";
import DatePickerField from "../../../components/formik fields/DatePickerField";
import SelectField from "../../../components/formik fields/SelectField";
import useStatesByCountry from "../../../components/useStatesByCountry";
import { Dayjs } from "dayjs";
import useCountries from "../../../components/useCountries";
import {
  useGetN600InfoAboutYouPart3,
  useSaveN600InfoAboutYouPart3,
} from "../../../../../hooks/n600/useN600";
import useToNextSection from "../../../../../hooks/useToNextSection";
import { InfoAboutYouPart3Validation } from "../validation";

export interface N600InfoAboutYouPart3Data
  extends Modify<
    N600InfoAboutYouPart3Payload,
    {
      // Port-of-Entry
      state: SingleOption | null;
      dateOfEntry: Dayjs | null;

      travelDocumentUsed: SingleOption | null;

      immigrationStatus: SingleOption | null;

      travelDocumentCountryOfIssuance: SingleOption | null;
      travelDocumentDateIssued: Dayjs | null;

      dateBecameLPR: Dayjs | null;

      stateOfAdoption: SingleOption | null;
      countryOfAdoption: SingleOption | null;
      dateOfAdoption: Dayjs | null;
      dateLegalCustodyBegan: Dayjs | null;
      datePhysicalCustodyBegan: Dayjs | null;

      stateOfReAdoption: SingleOption | null;
      countryOfReAdoption: SingleOption | null;
      dateOfReAdoption: Dayjs | null;
      dateReAdoptionLegalCustodyBegan: Dayjs | null;
      dateReAdoptionPhysicalCustodyBegan: Dayjs | null;
    }
  > {}

const InfoAboutYou3 = () => {
  const { data, isFetching, isError } = useGetN600InfoAboutYouPart3();
  const { mutate, isLoading: isSaving } = useSaveN600InfoAboutYouPart3();
  const { toNextSection } = useToNextSection();

  const {
    formattedStateData: statesData,
    stateLoading: statesLoading,
    selectedCountry,
  } = useStatesByCountry("United States");

  const {
    formattedCountryData: travelDocCountryData,
    countryLoading: travelDocCountryLoading,
  } = useCountries();

  const {
    formattedCountryData: adoptedCountryData,
    countryLoading: adoptedCountryLoading,
  } = useCountries();

  const {
    formattedStateData: adoptedStateData,
    stateLoading: adoptedStateLoading,
    setCountry: adoptedSetCountry,
    selectedCountry: adoptedSelectedCountry,
  } = useStatesByCountry(data?.countryOfAdoption);

  const {
    formattedCountryData: reAdoptedCountryData,
    countryLoading: reAdoptedCountryLoading,
  } = useCountries();

  const {
    formattedStateData: reAdoptedStateData,
    stateLoading: reAdoptedStateLoading,
    setCountry: reAdoptedSetCountry,
    selectedCountry: reAdoptedSelectedCountry,
  } = useStatesByCountry(data?.countryOfAdoption);

  const onSubmit = (values: N600InfoAboutYouPart3Data) => {
    const {
      state,
      dateOfEntry,
      travelDocumentUsed,
      immigrationStatus,
      travelDocumentCountryOfIssuance,
      travelDocumentDateIssued,
      dateBecameLPR,
      stateOfAdoption,
      countryOfAdoption,
      dateOfAdoption,
      dateLegalCustodyBegan,
      datePhysicalCustodyBegan,
      stateOfReAdoption,
      countryOfReAdoption,
      dateOfReAdoption,
      dateReAdoptionLegalCustodyBegan,
      dateReAdoptionPhysicalCustodyBegan,
      ...rest
    } = values;

    const payload = {
      state: state?.value || "",
      dateOfEntry: ToSaveDate(dateOfEntry),
      travelDocumentUsed: travelDocumentUsed?.value || "",
      immigrationStatus: immigrationStatus?.value || "",
      travelDocumentCountryOfIssuance:
        travelDocumentCountryOfIssuance?.value || "",
      travelDocumentDateIssued: ToSaveDate(travelDocumentDateIssued),
      dateBecameLPR: ToSaveDate(dateBecameLPR),
      stateOfAdoption: stateOfAdoption?.value || "",
      countryOfAdoption: countryOfAdoption?.value || "",
      dateOfAdoption: ToSaveDate(dateOfAdoption),
      dateLegalCustodyBegan: ToSaveDate(dateLegalCustodyBegan),
      datePhysicalCustodyBegan: ToSaveDate(datePhysicalCustodyBegan),
      stateOfReAdoption: stateOfReAdoption?.value || "",
      countryOfReAdoption: countryOfReAdoption?.value || "",
      dateOfReAdoption: ToSaveDate(dateOfReAdoption),
      dateReAdoptionLegalCustodyBegan: ToSaveDate(
        dateReAdoptionLegalCustodyBegan
      ),
      dateReAdoptionPhysicalCustodyBegan: ToSaveDate(
        dateReAdoptionPhysicalCustodyBegan
      ),
      ...rest,
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValues: N600InfoAboutYouPart3Data = {
    // I arrived in the following manner
    // Port-of-Entry
    cityOrTown: initInput(data?.cityOrTown), // City or Town
    state: initSelect(statesData, data?.state), // State
    dateOfEntry: initDate(data?.dateOfEntry), // Date of Entry (mm/dd/yyyy)

    // Exact Name Used at Time of Entry
    familyName: initInput(data?.familyName), // Family Name (Last Name)
    givenName: initInput(data?.givenName), // Given Name (First Name)
    middleName: initInput(data?.middleName), // Middle Name

    // I used the following travel document to be admitted to the United States
    travelDocumentUsed: initSelect(DocumentData, data?.travelDocumentUsed), // I used the following travel document to be admitted to the United States
    travelDocumentNumber: initInput(data?.travelDocumentNumber), // Travel Document Number
    travelDocumentCountryOfIssuance: initSelect(
      travelDocCountryData,
      data?.travelDocumentCountryOfIssuance
    ), // Country of Issuance for Travel Document
    travelDocumentDateIssued: initDate(data?.travelDocumentDateIssued), // Date Travel Document Issued (mm/dd/yyyy)

    // I am
    immigrationStatus: initSelect(ImmigrationStatus, data?.immigrationStatus), // Immigration Status
    immigrationStatusExplain: initInput(data?.immigrationStatusExplain), // Explain if other
    dateBecameLPR: initDate(data?.dateBecameLPR), // Date I became a LPR (mm/dd/yyyy)
    uscisOfficeGrantedLPR: initInput(data?.uscisOfficeGrantedLPR), // USCIS Office That Granted My LPR Status or Location Where I Was Admitted

    previouslyAppliedForCitizenshipOrPassport: initInput(
      data?.previouslyAppliedForCitizenshipOrPassport
    ), // Have you previously applied for a Certificate of Citizenship or U.S. Passport?
    previouslyAppliedForCitizenshipOrPassportExplanation: initInput(
      data?.previouslyAppliedForCitizenshipOrPassportExplanation
    ), // Explanation if previously applied for Certificate of Citizenship or U.S. Passport

    abandonedOrLostLPRStatus: initInput(data?.abandonedOrLostLPRStatus), // Have you ever abandoned or lost your LPR status?
    abandonedOrLostLPRStatusExplanation: initInput(
      data?.abandonedOrLostLPRStatusExplanation
    ), // Explanation if you have ever abandoned or lost your LPR status?

    // Were you adopted?
    adopted: initInput(data?.adopted), // Were you adopted?
    // If (Were you adopted === yes)
    cityOrTownOfAdoption: initInput(data?.cityOrTownOfAdoption), // City or Town of Adoption
    stateOfAdoption: initSelect(adoptedStateData, data?.stateOfAdoption), // State of Adoption
    countryOfAdoption: initSelect(adoptedCountryData, data?.countryOfAdoption), // Country of Adoption
    dateOfAdoption: initDate(data?.dateOfAdoption), // Date of Adoption (mm/dd/yyyy)
    dateLegalCustodyBegan: initDate(data?.cityOrTown), // Date Legal Custody Began (mm/dd/yyyy)
    datePhysicalCustodyBegan: initDate(data?.datePhysicalCustodyBegan), // Date Physical Custody Began (mm/dd/yyyy)

    reAdoptedInUS: initInput(data?.reAdoptedInUS), // Did you have to be re-adopted in the United States?
    // if (Did you have to be re-adopted in the United States? === yes)
    cityOrTownOfReAdoption: initInput(data?.cityOrTownOfReAdoption), // City or Town of Re-adoption
    stateOfReAdoption: initSelect(reAdoptedStateData, data?.stateOfReAdoption), // State of Re-adoption
    countryOfReAdoption: initSelect(
      reAdoptedCountryData,
      data?.countryOfReAdoption
    ), // Country of Re-adoption
    dateOfReAdoption: initDate(data?.dateOfReAdoption), // Date of Re-Adoption (mm/dd/yyyy)
    dateReAdoptionLegalCustodyBegan: initDate(
      data?.dateReAdoptionLegalCustodyBegan
    ), // Date Re-adoption Legal Custody Began (mm/dd/yyyy)
    dateReAdoptionPhysicalCustodyBegan: initDate(
      data?.dateReAdoptionPhysicalCustodyBegan
    ), // Date Re-adoption Physical Custody Began (mm/dd/yyyy)
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={InfoAboutYouPart3Validation}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <ModuleTitle>
                Information About Your Admission into the United States and
                Current Immigration Status
              </ModuleTitle>
              <ModuleTitle>
                I arrived in the following manner: Port of Entry
              </ModuleTitle>
              <FormWrapper>
                <div>
                  <InputField
                    name="cityOrTown"
                    label="City or Town"
                    placeholder=""
                  />
                </div>

                <div>
                  <SelectField
                    name={`state`}
                    label="State"
                    placeholder="-Select-"
                    options={statesData}
                    isLoading={statesLoading}
                    isDisabled={!selectedCountry}
                  />
                </div>
              </FormWrapper>
              <FormWrapper>
                <div>
                  <DatePickerField
                    name="dateOfEntry"
                    label="Date of Entry (mm/dd/yyyy)"
                    placeholder=""
                  />
                </div>

                <div></div>
              </FormWrapper>
              <ModuleTitle>Exact Name Used at Time of Entry</ModuleTitle>
              <FormWrapper>
                <div>
                  <InputField
                    name="familyName"
                    label="Family Name"
                    coloredLabel="(Last Name)"
                    placeholder=""
                  />
                </div>

                <div>
                  <InputField
                    name="givenName"
                    label="Given Name"
                    coloredLabel="(First Name)"
                    placeholder=""
                  />
                </div>
              </FormWrapper>
              <FormWrapper>
                <div>
                  <InputField
                    name="middleName"
                    label="Middle Name"
                    placeholder=""
                  />
                </div>

                <div></div>
              </FormWrapper>
              <ModuleTitle>
                I used the following travel document to be admitted to the
                United States
              </ModuleTitle>
              <FormWrapper>
                <div>
                  <SelectField
                    name="travelDocumentUsed"
                    label="Travel document used"
                    options={DocumentData}
                    placeholder=""
                    onChange={(value) => {
                      // console.log(value);
                      // console.log(formik.values.countryOfAdoption?.value);
                      adoptedSetCountry(value.label);
                      formik.setFieldValue("travelDocumentNumber", "");
                      formik.setFieldValue(`travelDocumentDateIssued`, null);
                      formik.setFieldValue(
                        `travelDocumentCountryOfIssuance`,
                        null
                      );
                    }}
                  />
                </div>

                <div>
                  <InputField
                    name="travelDocumentNumber"
                    label={`${
                      formik.values.travelDocumentUsed?.value
                        ? formik.values.travelDocumentUsed?.value
                        : "Document"
                    } Number`}
                    placeholder=""
                    isDisabled={!formik.values.travelDocumentUsed?.value}
                  />
                </div>
              </FormWrapper>

              <FormWrapper>
                <div>
                  <SelectField
                    name="travelDocumentCountryOfIssuance"
                    label={`Country of Issuance for ${
                      formik.values.travelDocumentUsed?.value
                        ? formik.values.travelDocumentUsed?.value
                        : "Document"
                    }`}
                    placeholder="-Select-"
                    options={travelDocCountryData}
                    isLoading={travelDocCountryLoading}
                    onChange={(value) => {
                      // console.log(value);
                      // console.log(
                      //   formik.values.travelDocumentCountryOfIssuance?.value
                      // );
                    }}
                  />
                </div>

                <div>
                  <DatePickerField
                    name="travelDocumentDateIssued"
                    label={`${
                      formik.values.travelDocumentUsed?.value
                        ? formik.values.travelDocumentUsed?.value
                        : "Document"
                    } Issued (mm/dd/yyyy)`}
                    disableFuture
                  />
                </div>
              </FormWrapper>

              <ModuleTitle>Current Immigration Status</ModuleTitle>

              <FormWrapper>
                <div>
                  <SelectField
                    name="immigrationStatus"
                    label="I am"
                    options={ImmigrationStatus}
                    placeholder="-Select-"
                    onChange={() => {
                      formik.setFieldValue("immigrationStatusExplain", "");
                      formik.setFieldValue("dateBecameLPR", null);
                      formik.setFieldValue("uscisOfficeGrantedLPR", "");
                    }}
                  />
                </div>

                <div>
                  {formik.values.immigrationStatus?.value ===
                    ImmigrationStatusValues.other && (
                    <InputField
                      name="immigrationStatusExplain"
                      label="Explain"
                      placeholder=""
                    />
                  )}
                </div>
              </FormWrapper>
              {formik.values.immigrationStatus?.value ===
                ImmigrationStatusValues.lpr && (
                <>
                  <ModuleTitle>
                    I obtained LPR status through adjustment of status in the
                    United States or admission as a LPR.
                  </ModuleTitle>

                  <FormWrapper>
                    <div>
                      <DatePickerField
                        name="dateBecameLPR"
                        label="Date I became a LPR (mm/dd/yyyy)"
                        placeholder={USDateFormat}
                      />
                    </div>

                    <div>
                      <InputField
                        name="uscisOfficeGrantedLPR"
                        label="U.S. Citizenship and Immigration Services (USCIS) Office That Granted My LPR Status or Location Where I Was Admitted"
                        placeholder=""
                      />
                    </div>
                  </FormWrapper>
                </>
              )}
              <FormWrapper>
                <div>
                  <RadioComp
                    name="previouslyAppliedForCitizenshipOrPassport"
                    title="Have you previously applied for a Certificate of Citizenship or U.S. Passport?"
                    options={GenericYesNo}
                    onChange={() => {
                      formik.setFieldValue(
                        "previouslyAppliedForCitizenshipOrPassportExplanation",
                        ""
                      );
                    }}
                  />
                </div>

                <div>
                  {formik.values.previouslyAppliedForCitizenshipOrPassport ===
                    GenericYesNoOptionsValue.yes && (
                    <InputField
                      name="previouslyAppliedForCitizenshipOrPassportExplanation"
                      label="Explanation if previously applied for Certificate of Citizenship or U.S. Passport"
                      placeholder=""
                    />
                  )}
                </div>
              </FormWrapper>

              <FormWrapper>
                <div>
                  <RadioComp
                    name="abandonedOrLostLPRStatus"
                    title="Have you ever abandoned or lost your LPR status?"
                    options={GenericYesNo}
                    onChange={() => {
                      formik.setFieldValue(
                        "abandonedOrLostLPRStatusExplanation",
                        ""
                      );
                    }}
                  />
                </div>

                <div>
                  {formik.values.abandonedOrLostLPRStatus ===
                    GenericYesNoOptionsValue.yes && (
                    <InputField
                      name="abandonedOrLostLPRStatusExplanation"
                      label="Explanation if you have ever abandoned or lost your LPR status?"
                      placeholder=""
                    />
                  )}
                </div>
              </FormWrapper>

              <FormWrapper>
                <div>
                  <RadioComp
                    name="adopted"
                    title="Were you adopted?"
                    options={GenericYesNo}
                    onChange={() => {
                      formik.setFieldValue("reAdoptedInUS", "");
                      formik.setFieldValue("cityOrTownOfAdoption", "");
                      formik.setFieldValue("countryOfAdoption", null);
                      formik.setFieldValue("stateOfAdoption", null);
                      formik.setFieldValue("dateOfAdoption", null);
                      formik.setFieldValue("dateLegalCustodyBegan", null);
                      formik.setFieldValue("datePhysicalCustodyBegan", null);
                    }}
                  />
                </div>

                <div></div>
              </FormWrapper>

              {formik.values.adopted === GenericYesNoOptionsValue.yes && (
                <>
                  <ModuleTitle>Place of Final Adoption</ModuleTitle>

                  <FormWrapper>
                    <div>
                      <InputField
                        name="cityOrTownOfAdoption"
                        label="City or Town"
                        placeholder=""
                      />
                    </div>

                    <div>
                      <SelectField
                        name="countryOfAdoption"
                        label="Country"
                        placeholder="-Select-"
                        options={adoptedCountryData}
                        isLoading={adoptedCountryLoading}
                        onChange={(value) => {
                          // console.log(value);
                          // console.log(formik.values.countryOfAdoption?.value);
                          adoptedSetCountry(value.label);
                          formik.setFieldValue("stateOfAdoption", null);
                        }}
                      />
                    </div>
                  </FormWrapper>

                  <FormWrapper>
                    <div>
                      <SelectField
                        name={`stateOfAdoption`}
                        label="State"
                        placeholder="-Select-"
                        options={adoptedStateData}
                        isLoading={adoptedStateLoading}
                        isDisabled={!adoptedSelectedCountry}
                      />
                    </div>

                    <div>
                      <DatePickerField
                        name="dateOfAdoption"
                        label="Date of Adoption (mm/dd/yyyy)"
                        disableFuture
                      />
                    </div>
                  </FormWrapper>

                  <FormWrapper>
                    <div>
                      <DatePickerField
                        name="dateLegalCustodyBegan"
                        label="Date Legal Custody Began (mm/dd/yyyy)"
                        disableFuture
                      />
                    </div>

                    <div>
                      <DatePickerField
                        name="datePhysicalCustodyBegan"
                        label="Date Physical Custody Began (mm/dd/yyyy)"
                        disableFuture
                      />
                    </div>
                  </FormWrapper>
                </>
              )}

              {formik.values.adopted === GenericYesNoOptionsValue.yes && (
                <FormWrapper>
                  <div>
                    <RadioComp
                      name="reAdoptedInUS"
                      title="Did you have to be re-adopted in the United States?"
                      options={GenericYesNo}
                      onChange={() => {
                        formik.setFieldValue("cityOrTownOfReAdoption", "");
                        formik.setFieldValue("countryOfReAdoption", null);
                        formik.setFieldValue("stateOfReAdoption", null);
                        formik.setFieldValue("dateOfReAdoption", null);
                        formik.setFieldValue(
                          "dateReAdoptionLegalCustodyBegan",
                          null
                        );
                        formik.setFieldValue(
                          "dateReAdoptionPhysicalCustodyBegan",
                          null
                        );
                      }}
                    />
                  </div>

                  <div></div>
                </FormWrapper>
              )}

              {formik.values.reAdoptedInUS === GenericYesNoOptionsValue.yes && (
                <>
                  <ModuleTitle>Place of Final Adoption</ModuleTitle>

                  <FormWrapper>
                    <div>
                      <InputField
                        name="cityOrTownOfReAdoption"
                        label="City or Town"
                        placeholder=""
                      />
                    </div>

                    <div>
                      <SelectField
                        name="countryOfReAdoption"
                        label="Country"
                        placeholder="-Select-"
                        options={reAdoptedCountryData}
                        isLoading={reAdoptedCountryLoading}
                        onChange={(value) => {
                          // console.log(value);
                          // console.log(formik.values.countryOfReAdoption?.value);
                          reAdoptedSetCountry(value.label);
                          formik.setFieldValue("stateOfReAdoption", null);
                        }}
                      />
                    </div>
                  </FormWrapper>

                  <FormWrapper>
                    <div>
                      <SelectField
                        name={`stateOfReAdoption`}
                        label="State"
                        placeholder="-Select-"
                        options={reAdoptedStateData}
                        isLoading={reAdoptedStateLoading}
                        isDisabled={!reAdoptedSelectedCountry}
                      />
                    </div>

                    <div>
                      <DatePickerField
                        name="dateOfReAdoption"
                        label="Date of Re-Adoption (mm/dd/yyyy)"
                        disableFuture
                      />
                    </div>
                  </FormWrapper>

                  <FormWrapper>
                    <div>
                      <DatePickerField
                        name="dateReAdoptionLegalCustodyBegan"
                        label="Date Re-adoption Legal Custody Began (mm/dd/yyyy)"
                        disableFuture
                      />
                    </div>

                    <div>
                      <DatePickerField
                        name="dateReAdoptionPhysicalCustodyBegan"
                        label="Date Re-adoption Physical Custody Began (mm/dd/yyyy)"
                        disableFuture
                      />
                    </div>
                  </FormWrapper>
                </>
              )}

              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default InfoAboutYou3;

const Wrapper = styled.div``;
