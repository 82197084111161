import PreparerInterpreter from "../../PI";
import { PreparerInterpreterObj } from "../../../../api/utils/types";
import {
  useGetN565PreparerInterpreter,
  useSaveN565PreparerInterpreter,
} from "../../../../hooks/n565/useN565";
import useToNextSection from "../../../../hooks/useToNextSection";

const N565Preparer = () => {
  const { toNextSection } = useToNextSection();
  const { data, isFetching, isError } = useGetN565PreparerInterpreter();
  const { mutate: saveMutate, isLoading: isSaving } =
    useSaveN565PreparerInterpreter();

  const onMutate = (payload: PreparerInterpreterObj) => {
    saveMutate(payload, {
      onSuccess: toNextSection,
    });
  };

  return (
    <PreparerInterpreter
      onMutate={onMutate}
      ptData={data}
      isFetching={isFetching}
      isError={isError}
      isSaving={isSaving}
    />
  );
};

export default N565Preparer;
