import React, { FC } from "react";
import { FaPlus } from "react-icons/fa";
import styled from "styled-components";
import Colors from "../../../styles/Colors";

interface Props {
  onClick: () => void;
}

const AddAnotherBtn: FC<Props> = ({ onClick }) => {
  return (
    <div>
      <Wrapper type="button" onClick={onClick}>
        <FaPlus />
        Add Another
      </Wrapper>
    </div>
  );
};

export default AddAnotherBtn;

const Wrapper = styled.button`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  padding: 10px 24px;
  border: 1px solid ${Colors.Blue00};
  border-radius: 8px;
  color: ${Colors.Blue00};
  margin-top: 16px;

  transition: all 0.3s;

  &:hover {
    background-color: ${Colors.Blue00};
    color: #fff;
  }
`;
