import React from "react";
import Select, { StylesConfig } from "react-select";
import { useField } from "formik";
import styled from "styled-components";
import { SingleOption } from "../../../../types/types";
import Colors from "../../../../styles/Colors";
import Tooltip, { TooltipProps } from "../Tooltip";

interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  options?: SingleOption<any, any>[];
  value?: SingleOption;
  onChange?: (e: SingleOption) => void;
  isLoading?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  height?: string;
  toolTipProps?: TooltipProps;
  noBorder?: boolean;
  coloredLabel?: string;
  bgColor?: string;
}

function SelectField({
  label,
  placeholder,
  options,
  onChange,
  isLoading,
  isRequired,
  isDisabled,
  height,
  toolTipProps,
  noBorder,
  value,
  coloredLabel,
  bgColor,
  ...props
}: Props) {
  const [field, meta, helpers] = useField(props);
  const isError = meta.touched && meta.error;
  const errorClass = `${isRequired ? "required" : ""}`;

  const selectStyles: StylesConfig = {
    control: (styles) => ({
      ...styles,
      border: noBorder
        ? "none"
        : isError && !isDisabled
        ? `1px solid red`
        : `1px solid ${Colors.GreyA7}`,
      boxShadow: "none",
      borderRadius: "0.25rem",
      paddingInlineStart: "0.5rem",
      height: height ? height : "2.5rem",
      background: isDisabled
        ? "rgba(27, 9, 44, 0.05)"
        : bgColor
        ? bgColor
        : "white",
      "&:hover": {
        border: noBorder ? "none" : `1px solid ${Colors.GreyA7}`,
      },
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: "400",
    }),
  };

  return (
    <InputContainer>
      {label && (
        <Label className={errorClass}>
          <div>
            {label} <Colored>{coloredLabel && coloredLabel}</Colored>
          </div>
          {toolTipProps && (
            <Tooltip toolTipProps={toolTipProps} parentName={props.name} />
          )}
        </Label>
      )}
      <Select
        {...props}
        {...field}
        value={field.value || (value ? value : null)}
        options={options}
        styles={selectStyles}
        placeholder={placeholder}
        components={{
          IndicatorSeparator: () => null,
        }}
        isDisabled={isDisabled}
        isLoading={isLoading}
        onChange={(e) => {
          const value = e as SingleOption;
          onChange && onChange(value);
          helpers.setValue(value);
        }}
      />
      {isError && !isDisabled && <Error>{meta.error}</Error>}
    </InputContainer>
  );
}

export default SelectField;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.label`
  margin-bottom: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: ${Colors.Black31};
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.required:after {
    content: "*";
    font-size: 24px;
    line-height: 0;
    vertical-align: middle;
    color: #d90b2c;
  }
`;

const Error = styled.div`
  color: red;
  font-size: 0.875rem;
`;

const Colored = styled.span`
  color: ${Colors.RedDA};
`;
