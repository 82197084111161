import React, { FC } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import Colors from "../../../../../styles/Colors";
import PdfReviewGeneric from "./PdfReviewGeneric";
import PdfReviewOutput from "./PdfReviewOutput";

const styles = StyleSheet.create({
  wrapper: {
    marginTop: "33px",
    // margin: 10,
    // padding: 10,
    // flexGrow: 1,
  },
  header: {
    padding: "13px 16px",
    // alignItems: "center",
    // justifyContent: "space-between",
    backgroundColor: Colors.BlueEA,
  },
  title: {
    color: Colors.Blue00,
    fontSize: "16px",
    fontWeight: 600,
    // lineHeight: 19,
    letterSpacing: 0,
  },
});

export interface ReviewSectionChildrenData extends ReviewData {}

export interface ReviewSectionData {
  title: string;
  children: ReviewSectionChildrenData[];
}

export interface ReviewData {
  label?: string;
  value?: string;
  section?: ReviewSectionData;
}

interface Props {
  title: string;
  data?: ReviewData[];
  isActive?: boolean;
  editUrl: string;
  extraComp?: JSX.Element;
  hideEdit?: boolean;
}

const PdfReviewBox: FC<Props> = ({
  title,
  data,
  isActive,
  editUrl,
  extraComp,
  hideEdit,
}) => {
  return (
    <View style={styles.wrapper}>
      <View style={styles.header}>
        <Text style={styles.title}>{title}</Text>
      </View>
      <>
        {extraComp
          ? extraComp
          : data &&
            data.map((ev) => {
              if (ev.section) {
                return (
                  <PdfReviewOutput
                    data={ev.section.children}
                    title={ev.section.title}
                  />
                );
              } else
                return <PdfReviewGeneric value={ev.value} label={ev.label} />;
            })}
      </>
    </View>
  );
};

export default PdfReviewBox;
