import React, { FC } from "react";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { FormN600Progress } from "../../types";
import { N600EligibilityInfoPayload } from "../../../../../../api/n600/types";

interface Props {
  title: string;
  data?: N600EligibilityInfoPayload;
}

const ReviewEligibilityInfo: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      label: "Eligibility Criteria",
      value: data?.eligibilityCriteria.replace(/_/g, " "),
    },
    {
      label: "Eligibility Description",
      value: data?.eligibilityDescription,
    },
  ];

  return (
    <ReviewBox
      title={title}
      data={section}
      editUrl={FormN600Progress.infoAboutEligibility}
    />
  );
};

export default ReviewEligibilityInfo;
