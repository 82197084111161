import React, { FC, useEffect } from "react";
import styled from "styled-components";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { FieldArray, Form, Formik } from "formik";
import FormBtn from "../../../components/FormBtn";
import { Modify, SingleOption } from "../../../../../types/types";
import { saveDS160SEVAdditionalContactObj } from "../../../../../api/ds160/types";
import {
  useGetDS160SEVAdditionalContact,
  useSaveDS160SEVAdditionalContact,
} from "../../../../../hooks/ds160/usDS160";
import {
  ArrayWrapper,
  GroupWrapper,
  Info,
  InfoBold,
  VerticalFormWrapper,
} from "../styles";
import GroupedSection from "../GroupedSection";
import AddAnotherBtn from "../AddAnotherBtn";
import { StudentExchangeVisaAdditionalValidation } from "../validation";
import {
  SpecialSeparator,
  formatMultiStates,
  saveDynamicCheck,
} from "../../../components/data";
import useToNextSection from "../../../../../hooks/useToNextSection";
import AdditionalArray from "./AdditionalArray";
import useMultiStatesByCountry from "../../../form 400/components/useMultiStatesByCountry";
import useAiReview from "../useAiReview";
import { ReviewSEVSectionA } from "../Review/components/data";
import { processReviewData } from "../data";
import { useSearchParams } from "react-router-dom";
import { BusinessQueryKey } from "../../../../../api/utils/types";

const initialContactArray = {
  surname: "",
  givenNames: "",
  streetAddressLine1: "",
  streetAddressLine2: "",
  city: "",
  stateOrProvince: null,
  doesNotApplyStateOrProvince: false,
  zipCode: "",
  doesNotApplyZipCode: false,
  countryOrRegion: null,
  telephoneNumber: "",
  doesNotApplyTelephoneNumber: false,
  emailAddress: "",
  doesNotApplyEmailAddress: false,
};

interface ContactArrayPropsPayload {
  surname: string;
  givenNames: string;
  streetAddressLine1: string;
  streetAddressLine2: string;
  city: string;
  stateOrProvince: SingleOption | null;
  doesNotApplyStateOrProvince: boolean;
  zipCode: string;
  doesNotApplyZipCode: boolean;
  countryOrRegion: SingleOption | null;
  telephoneNumber: string;
  doesNotApplyTelephoneNumber: boolean;
  emailAddress: string;
  doesNotApplyEmailAddress: boolean;
}
export interface StudentExchangeVisaAdditionalData
  extends Modify<
    Omit<
      saveDS160SEVAdditionalContactObj,
      | "surname"
      | "givenNames"
      | "streetAddressLine1"
      | "streetAddressLine2"
      | "city"
      | "stateOrProvince"
      | "doesNotApplyStateOrProvince"
      | "zipCode"
      | "doesNotApplyZipCode"
      | "countryOrRegion"
      | "telephoneNumber"
      | "doesNotApplyTelephoneNumber"
      | "emailAddress"
      | "doesNotApplyEmailAddress"
    >,
    {
      ContactArray: ContactArrayPropsPayload[];
    }
  > {}

interface Props {
  isToStudy: boolean;
}

const StudentExchangeVisaAdditional: FC<Props> = ({ isToStudy }) => {
  const { toNextSection } = useToNextSection();
  const [searchParams] = useSearchParams();
  const getApplicationId = searchParams.get(BusinessQueryKey.appId) || "";
  const { data, isFetching, isError } =
    useGetDS160SEVAdditionalContact(getApplicationId);
  const { mutate, isLoading: isSaving } = useSaveDS160SEVAdditionalContact();
  const { statesArray } = useMultiStatesByCountry(data?.countryOrRegion);
  const { postAiReview, riskData, riskError, riskLoading, riskSaving } =
    useAiReview();

  //skip this section if not to study
  useEffect(() => {
    if (!isToStudy) {
      toNextSection();
    }
  }, [isToStudy, toNextSection]);

  const savedContactArray = () => {
    if (data?.surname) {
      const surnameData = data?.surname
        .split(",")
        .map((ev) => ({ surname: ev }));

      const givenNamesData = data?.givenNames
        .split(",")
        .map((ev) => ({ givenNames: ev }));

      const streetAddressLine1Data = data?.streetAddressLine1
        .split(SpecialSeparator)
        .map((ev) => ({ streetAddressLine1: ev }));

      const streetAddressLine2Data = data?.streetAddressLine2
        .split(SpecialSeparator)
        .map((ev) => ({ streetAddressLine2: ev }));

      const cityData = data?.city.split(",").map((ev) => ({ city: ev }));

      const doesNotApplyStateOrProvinceData = data?.doesNotApplyStateOrProvince
        .split(",")
        .map((ev) => ({
          doesNotApplyStateOrProvince: saveDynamicCheck(ev),
        }));

      const zipCodeData = data?.zipCode
        .split(",")
        .map((ev) => ({ zipCode: ev }));

      const doesNotApplyZipCodeData = data?.doesNotApplyZipCode
        .split(",")
        .map((ev) => ({
          doesNotApplyZipCode: saveDynamicCheck(ev),
        }));

      // permanent patch for now
      const countryOrRegionData = data?.countryOrRegion
        .split(",")
        .map((ev) => ({
          countryOrRegion: { value: ev, label: ev },
        }));

      // permanent patch for now
      const stateOrProvinceData = formatMultiStates({
        dataKey: data?.stateOrProvince,
        fieldKey: "stateOrProvince",
        dataArray: statesArray,
      }) as { stateOrProvince: SingleOption }[];

      const telephoneNumberData = data?.telephoneNumber
        .split(",")
        .map((ev) => ({ telephoneNumber: ev }));

      const doesNotApplyTelephoneNumberData = data?.doesNotApplyTelephoneNumber
        .split(",")
        .map((ev) => ({
          doesNotApplyTelephoneNumber: saveDynamicCheck(ev),
        }));

      const emailAddressData = data?.emailAddress
        .split(",")
        .map((ev) => ({ emailAddress: ev }));

      const doesNotApplyEmailAddressData = data?.doesNotApplyEmailAddress
        .split(",")
        .map((ev) => ({
          doesNotApplyEmailAddress: saveDynamicCheck(ev),
        }));

      return surnameData.map((ev, i) => ({
        ...ev,
        ...givenNamesData[i],
        ...streetAddressLine1Data[i],
        ...streetAddressLine2Data[i],
        ...cityData[i],
        ...stateOrProvinceData[i],
        ...doesNotApplyStateOrProvinceData[i],
        ...zipCodeData[i],
        ...doesNotApplyZipCodeData[i],
        ...countryOrRegionData[i],
        ...telephoneNumberData[i],
        ...doesNotApplyTelephoneNumberData[i],
        ...emailAddressData[i],
        ...doesNotApplyEmailAddressData[i],
      }));
    } else return [initialContactArray];
  };

  const initialValues: StudentExchangeVisaAdditionalData = {
    ContactArray: savedContactArray(),
  };
  const onSubmit = (values: StudentExchangeVisaAdditionalData) => {
    const { ContactArray, ...rest } = values;

    const payload = {
      ...rest,
      surname: ContactArray.map((ev) => ev.surname).join(","),
      givenNames: ContactArray.map((ev) => ev.givenNames).join(","),
      streetAddressLine1: ContactArray.map((ev) => ev.streetAddressLine1).join(
        SpecialSeparator
      ),
      streetAddressLine2: ContactArray.map((ev) => ev.streetAddressLine2).join(
        SpecialSeparator
      ),
      city: ContactArray.map((ev) => ev.city).join(","),
      stateOrProvince: ContactArray.map(
        (ev) => ev.stateOrProvince?.value || ""
      ).join(","),
      doesNotApplyStateOrProvince: ContactArray.map((ev) =>
        ev.doesNotApplyStateOrProvince.toString()
      ).join(","),
      zipCode: ContactArray.map((ev) => ev.zipCode).join(","),
      doesNotApplyZipCode: ContactArray.map(
        (ev) => ev.doesNotApplyZipCode
      ).join(","),
      countryOrRegion: ContactArray.map(
        (ev) => ev.countryOrRegion?.value || ""
      ).join(","),
      telephoneNumber: ContactArray.map((ev) => ev.telephoneNumber).join(","),
      doesNotApplyTelephoneNumber: ContactArray.map(
        (ev) => ev.doesNotApplyTelephoneNumber
      ).join(","),
      emailAddress: ContactArray.map((ev) => ev.emailAddress).join(","),
      doesNotApplyEmailAddress: ContactArray.map(
        (ev) => ev.doesNotApplyEmailAddress
      ).join(","),
    };

    const section = ReviewSEVSectionA(payload);
    const result = processReviewData(section);

    const riskPayload = {
      ...riskData,
      sev: {
        ...riskData?.sev,
        ...result,
      },
    };

    const onSave = () => {
      mutate(
        { ...payload, applicationId: getApplicationId },
        {
          onSuccess: toNextSection,
        }
      );
    };

    postAiReview({
      riskPayload: riskPayload,
      onSave,
    });
  };

  return (
    <FormSectionWrapper
      isLoading={isFetching || riskLoading}
      isError={isError || riskError}
    >
      <Wrapper>
        <Info>
          <InfoBold>
            NOTE: Provide the following information concerning your Student
            Exchange Visa
          </InfoBold>
        </Info>
        <Formik
          initialValues={initialValues}
          validationSchema={StudentExchangeVisaAdditionalValidation}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <GroupWrapper>
                  <FieldArray
                    name="ContactArray"
                    render={(arrayHelpers) => (
                      <ArrayWrapper>
                        {formik.values.ContactArray.map((_, index) => (
                          <GroupedSection
                            key={index}
                            index={index}
                            onCancel={() => arrayHelpers.remove(index)}
                          >
                            <AdditionalArray
                              data={data}
                              formik={formik}
                              index={index}
                            />
                          </GroupedSection>
                        ))}

                        <AddAnotherBtn
                          onClick={() => arrayHelpers.push(initialContactArray)}
                        />
                      </ArrayWrapper>
                    )}
                  />
                </GroupWrapper>

                <FormBtn
                  isLoading={isSaving || riskSaving}
                  addBackBtn
                  isError={formik.dirty && !formik.isValid}
                />
              </FormWrapper>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default StudentExchangeVisaAdditional;

const Wrapper = styled.div``;

const FormWrapper = styled(VerticalFormWrapper)`
  & > div {
    /* width: 60%; */
  }
`;
