import { userDataStore } from "../store/userData";
import { useSnapshot } from "valtio";
import { useNavigate } from "react-router-dom";
import { WebPaths } from "../routes/data";
import useIsEligibilityFormFilled from "./useHasFilledCurrentEligibility";
import { FormCategories } from "../routes/type";

const useOnContinueNav = () => {
  const navigate = useNavigate();
  const { getIsEligibilityFormFilled } = useIsEligibilityFormFilled();
  const { userData } = useSnapshot(userDataStore);
  const formCategory = userData?.currentFormInfo.formCategory;
  const isFormCompleted = userData?.currentFormInfo.isFormCompleted;
  const formProgress = userData?.currentFormInfo.formProgress;
  const hasEligibility = getIsEligibilityFormFilled(
    formCategory as FormCategories
  );

  const onContinueNav = () => {
    if (!hasEligibility) {
      navigate(`${WebPaths.Eligibility}/${formCategory}`);
    } else if (isFormCompleted) {
      navigate(
        `${WebPaths.FormCategory}/${formCategory}?type=${formCategory}-review`
      );
    } else
      navigate(`${WebPaths.FormCategory}/${formCategory}?type=${formProgress}`);
  };
  return { onContinueNav };
};

export default useOnContinueNav;
