import PreparerInterpreter from "../../PI";
import { PreparerInterpreterObj } from "../../../../api/utils/types";
import {
  useGetN400Preparer,
  useSaveN400Preparer,
} from "../../../../hooks/n400/useN400";
import useToNextSection from "../../../../hooks/useToNextSection";

const N400Preparer = () => {
  const { toNextSection } = useToNextSection();
  const { data, isFetching, isError } = useGetN400Preparer();
  const { mutate, isLoading: isSaving } = useSaveN400Preparer();

  const onMutate = (payload: PreparerInterpreterObj) => {
    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  return (
    <PreparerInterpreter
      onMutate={onMutate}
      ptData={data}
      isFetching={isFetching}
      isError={isError}
      isSaving={isSaving}
    />
  );
};

export default N400Preparer;
