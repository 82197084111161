import React from "react";
import styled from "styled-components";
import Constaint from "../layout/components/Constaint";
import RightSection from "./components/RightSection";
import { FormN400Progress } from "./components/types";
import { n400LeftSideBarData } from "./components/data";
import LeftSideBar from "../components/LeftSideBar";
import useFormRouteGuard from "../../../hooks/useFormRouteGuard";
import { useGetN400Eligibility } from "../../../hooks/n400/useN400";
import { formEligibilityStore } from "../../../store/formEligibilityData";
import ChatSystem from "../landing page/components/ChatSystem";

const F400 = () => {
  const { getType, lastProgress } = useFormRouteGuard(FormN400Progress);
  const { data } = useGetN400Eligibility();
  formEligibilityStore.formEligibility = data;

  const getSection = () => {
    if (
      FormN400Progress.applicantInfo1 === getType ||
      FormN400Progress.applicantInfo2 === getType
    )
      return (
        <RightSection
          title="1. Applicant's Information"
          subText="Person applying for naturalization"
        />
      );
    else if (FormN400Progress.bioInfo === getType)
      return (
        <RightSection
          title="2. Biographic Information"
          subText={`USCIS requires you to complete the categories below to conduct background checks.`}
        />
      );
    else if (FormN400Progress.addressHistory === getType)
      return (
        <RightSection
          title="3. Information About Your Residence"
          subText={``}
        />
      );
    else if (FormN400Progress.maritalHistory === getType)
      return (
        <RightSection
          title="4. Information About Your Marital History"
          subText={``}
        />
      );
    else if (FormN400Progress.childrenInfo === getType)
      return (
        <RightSection title="5. Information About Your Children" subText={``} />
      );
    else if (FormN400Progress.employmentAndSchoolHistory === getType)
      return (
        <RightSection
          title="6. Information About Your Employment And Schools You Attended"
          subText={``}
        />
      );
    else if (FormN400Progress.timeoff === getType)
      return (
        <RightSection
          title="7. Time Outside The U.S"
          subText={`Please include your travel history and time spent outside the United States so that we can determine you meet the continuous residency and physical presence requirements. List any 24-hour or longer trips you took outside of the United States over the last 5 years. Begin from your oldest to most recent.`}
        />
      );
    else if (
      FormN400Progress.checkPart1 === getType ||
      FormN400Progress.checkPart2 === getType ||
      FormN400Progress.checkPart3 === getType ||
      FormN400Progress.checkPart4 === getType
    )
      return (
        <RightSection
          title="8. Additional Information About You"
          subText={``}
        />
      );
    else if (FormN400Progress.feeDeduct === getType)
      return <RightSection title="9. Request For Fee Reduction" subText={``} />;
    else if (FormN400Progress.PI === getType)
      return (
        <RightSection
          title="10. Preparer and Interpreter."
          subText={`Provide information about who prepared this form`}
        />
      );
    else if (FormN400Progress.review === getType)
      return (
        <RightSection
          title="11. Review and Pay"
          subHead="Review and Pay - N400"
          subText={`Please take a moment to review your answers before submitting your visa application. Ensure that all information is accurate and complete. You can make edits by clicking the 'Edit' button next to each section. Once you are satisfied with your responses, you can proceed to unlock the download by paying.`}
        />
      );
  };

  return (
    <Wrapper>
      <Constaint>
        <Container>
          <LeftSideBar
            title="(N-400) Application for Naturalization/Citizenship"
            data={n400LeftSideBarData}
            storeProgress={lastProgress}
            progressEnum={FormN400Progress}
          />
          {getSection()}
          <ChatSystem formName="N400" />
        </Container>
      </Constaint>
    </Wrapper>
  );
};

export default F400;

const Wrapper = styled.div`
  margin: 28px 0;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 48px;
  margin-bottom: 170px;

  @media screen and (min-width: 768px) {
    grid-template-columns: 267px 1fr;
  }
`;
