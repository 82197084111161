import StatusTag from "./StatusTag";
import { DataItemsProps } from "../../../../../components/type";
import CaseAction from "./CaseAction";
import { GetBusinessApplicationsObj } from "../../../../../../../api/business/applications/types";

export const CaseOverviewTableData = (removeName?: boolean) => [
  {
    columnName: "Case No.",
    columnKey: "applicationInfo",
    customComp: (data: DataItemsProps) => {
      const temp = data as GetBusinessApplicationsObj;
      return (
        <div className="capitalize">
          {temp.applicationInfo?.CaseNumber || "--"}
        </div>
      );
    },
  },
  {
    columnName: "User Name",
    columnKey: "applicationInfo",
    customComp: (data: DataItemsProps) => (
      <div className="capitalize">
        {(data as GetBusinessApplicationsObj).userInfo.name}
      </div>
    ),
    hideOnTable: removeName,
  },
  {
    columnName: "Case Type",
    columnKey: "applicationInfo",
    customComp: (data: DataItemsProps) => (
      <div className="capitalize">
        {(data as GetBusinessApplicationsObj).applicationType}
      </div>
    ),
  },
  {
    columnName: "Payment",
    columnKey: "Payment_Status",
    customComp: (data: DataItemsProps) =>
      (data as GetBusinessApplicationsObj).applicationInfo?.isPaid
        ? "Paid"
        : "N/A",
  },
  {
    columnName: "Status",
    columnKey: "Status",
    customComp: (data: DataItemsProps) => (
      <StatusTag
        status={
          (data as GetBusinessApplicationsObj).applicationInfo
            ?.isFormCompleted || false
        }
      />
    ),
  },
  {
    columnName: "Action",
    customComp: (data: DataItemsProps, isNearEnd: boolean) => (
      <CaseAction data={data as GetBusinessApplicationsObj} />
    ),
    hideOnDetails: true,
  },
];
