import React, { FC } from "react";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { n400TimeOutsideUSRes } from "../../../../../../api/n400/types";
import { FormN400Progress } from "../../types";

interface Props {
  title: string;
  data?: n400TimeOutsideUSRes;
}

const ReviewTime: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      label: "Have you left the United States in the past 5 years",
      value: data?.have_you_left_us_in_the_past_five_years,
    },
    {
      label: "Country Visited",
      value: data?.countryVisited,
    },
    {
      label: "Date you left (yyyy-mm-dd)",
      value: data?.dateYouLeft,
    },
    {
      label: "Date you returned (yyyy-mm-dd)",
      value: data?.dateYouReturned,
    },
  ];

  return (
    <ReviewBox
      title={title}
      data={section}
      editUrl={FormN400Progress.timeoff}
    />
  );
};

export default ReviewTime;
