import Select, { StylesConfig } from "react-select";
import { FC } from "react";
import { TiArrowSortedDown } from "react-icons/ti";
import { SingleOption } from "../../../types/types";
import Colors from "../../../styles/Colors";

interface Props {
  option: SingleOption[];
  onChange: (data: SingleOption) => void;
  isPlacementTop?: boolean;
  width?: string;
  value?: string;
}

export const CustomDropdown: FC<Props> = ({
  onChange,
  option,
  isPlacementTop,
  width,
  value,
}) => {
  const selectStyles: StylesConfig = {
    control: (styles) => ({
      ...styles,
      width: width || "114px",
      border: `0.5px solid ${Colors.GreyA7}`,
      backgroundColor: "transparent",
      boxShadow: "none",
      fontSize: "0.875rem",
      fontWeight: "400",
      lineHeight: "20px",
      letterSpacing: "0em",
      color: Colors.Black31,
      cursor: "pointer",
      whiteSpace: "nowrap",
      paddingRight: "8px",
      "&:hover": {
        border: `0.5px solid ${Colors.GreyA7}`,
      },
    }),
    option: (styles) => ({
      ...styles,
      fontSize: "0.875rem",
      fontWeight: "400",
      lineHeight: "20px",
      letterSpacing: "0em",
      color: Colors.Black31,
      cursor: "pointer",
      whiteSpace: "nowrap",
      backgroundColor: "transparent",
      "&:hover": {
        fontWeight: "500",
        transition: "all ease-out 0.3s",
      },
    }),
    menu: (styles) => ({
      ...styles,
      background: "white", //
      zIndex: "400",
    }),
    input: (styles) => ({
      ...styles,
      caretColor: "transparent",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: Colors.Black31,
    }),
  };

  return (
    <Select
      key={value}
      options={option}
      styles={selectStyles}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => <TiArrowSortedDown />,
      }}
      defaultValue={option[0]}
      value={option.filter((ev) => ev.value === value)[0]}
      menuPlacement={isPlacementTop ? "top" : "bottom"}
      maxMenuHeight={200}
      onChange={(e: unknown) => {
        const data = e as SingleOption;
        onChange(data);
      }}
      getOptionValue={(option: unknown) => (option as SingleOption).value}
    />
  );
};
