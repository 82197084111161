import React, { FC } from "react";
import { Title, TypeOfApplicationFormData } from "../TypeOfApplication";
import {
  N565NameChangedThroughData,
  N565NameChangedThroughDataValue,
} from "../data";
import { FormWrapper } from "../../../components/styles";
import RadioComp from "../../../components/RadioComp";
import { USDatePlaceholderFormat } from "../../../components/data";
import InputField from "../../../components/formik fields/InputField";
import { FormikProps } from "formik";
import DatePickerField from "../../../components/formik fields/DatePickerField";

interface Props {
  formik: FormikProps<TypeOfApplicationFormData>;
}

const Part5: FC<Props> = ({ formik }) => {
  return (
    <>
      {formik.values.basisForApplicationArray[0].nameLegallyChanged && (
        <div>
          <Title className="mb-4">
            Complete If Applying for a New Document Because of a Name Change
          </Title>
          <FormWrapper>
            <RadioComp
              title="My name changed through"
              name="nameChangedThrough"
              options={N565NameChangedThroughData}
            />

            <div></div>
          </FormWrapper>

          {formik.values.nameChangedThrough ===
            N565NameChangedThroughDataValue.marriageDivorceOrAnnulment && (
            <>
              <FormWrapper>
                <DatePickerField
                  label="Date of Event"
                  name="nameChangedDateOfEvent"
                  placeholder={USDatePlaceholderFormat}
                  disableFuture
                />

                <div></div>
              </FormWrapper>
            </>
          )}

          {formik.values.nameChangedThrough ===
            N565NameChangedThroughDataValue.courtOrder && (
            <>
              <FormWrapper>
                <DatePickerField
                  label="Date of Court Order"
                  name="nameChangedDateOfCourtOrder"
                  placeholder={USDatePlaceholderFormat}
                  disableFuture
                />

                <div></div>
              </FormWrapper>
            </>
          )}

          <FormWrapper>
            <InputField
              label="Family Name"
              coloredLabel="(Last Name)"
              placeholder=""
              name="newLegalLastName"
            />
            <InputField
              label="Given Name"
              coloredLabel="(First Name)"
              placeholder=""
              name="newLegalFirstName"
            />
          </FormWrapper>

          <FormWrapper>
            <InputField
              label="Middle Name(s)"
              coloredLabel="(optional)"
              placeholder=""
              name="newLegalMiddleName"
            />
            <div></div>
          </FormWrapper>
        </div>
      )}
    </>
  );
};

export default Part5;
