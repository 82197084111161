const SESSION = sessionStorage;

const session = {
  get(key: string) {
    const item = SESSION.getItem(key);
    return item && item !== "undefined" ? JSON.parse(item) : null;
  },
  set(key: string, value: Object) {
    SESSION.setItem(key, JSON.stringify(value));
  },
  remove(key: string) {
    SESSION.removeItem(key);
  },
  //too dangerous
  // clear() {
  //   SESSION.clear();
  // },
};

export default session;
