import { FC } from "react";
import { SingleOption } from "../../../../types/types";
import { CustomDropdown } from "../CustomDropdown";

interface Props {
  option: SingleOption[];
  onChange: (params: string) => void;
  isPlacementTop?: boolean;
}

const PageDropdown: FC<Props> = ({ option, onChange, isPlacementTop }) => {
  return (
    <div className="flex items-center gap-2 ">
      <div className=" text-[#333333] text-sm">Show</div>
      <CustomDropdown
        option={option}
        isPlacementTop={isPlacementTop}
        onChange={(value) => {
          const target = value.value;
          onChange(target);
        }}
      />
      <div className=" text-[#333333] text-sm">entries</div>
    </div>
  );
};

export default PageDropdown;
