import axios from "axios";
import { loginFailed } from "../utils";

const devText = "development";

const isDev =
  process.env.NODE_ENV === devText || process.env.REACT_APP_ENV === devText;

const ProdUrl = "https://svbackendai-production.up.railway.app/";
const DevUrl = "https://svbackendai-production.up.railway.app/";

export const BaseUrl = !isDev ? ProdUrl : DevUrl;

const aiRequest = axios.create({
  baseURL: BaseUrl,
  headers: {
    "x-api-key": "jqprFI7HuUc4jX1EZRIwa2SFhYbwlOdU",
  },
});

aiRequest.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };

    return newConfig;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
aiRequest.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response?.status === 400) {
      return Promise.reject(error.response?.data);
    }

    if (error.response?.status === 403) {
      return Promise.reject(
        "Sorry! You're not authorized to perform this action"
      );
    }

    if (error.response?.status === 401) {
      // logout
      // store.dispatch(loginFailed());
      // storage.clear();
      loginFailed();
    }

    return Promise.reject(error);
  }
);

export default aiRequest;
