// SoundWave.tsx
import React from "react";
import styled, { keyframes } from "styled-components";

interface SoundWaveProps {
  isPlaying: boolean;
  barsLength?: number;
}

const bounceAnimation = keyframes`
  0%, 100% {
    height: 5px; /* Height when not playing */
  }
  50% {
    height: 35px; /* Max height during animation (18px top, 18px bottom) */
  }
`;

const getRandomDelay = () => Math.random() * 0.5;
const getRandomDuration = () => 0.4 + Math.random() * 0.6;

const SoundWave: React.FC<SoundWaveProps> = ({
  isPlaying,
  barsLength = 10,
}) => {
  return (
    <SoundWaveContainer>
      {[...Array(barsLength)].map((_, index) => (
        <Bar
          key={index}
          isPlaying={isPlaying}
          delay={getRandomDelay()}
          duration={getRandomDuration()}
        />
      ))}
    </SoundWaveContainer>
  );
};

export default SoundWave;

const SoundWaveContainer = styled.div`
  display: flex;
  align-items: center; /* Center bars to grow from middle */
  gap: 5px;

  height: 45px;
  padding: 5px;
`;

const Bar = styled.div<{ isPlaying: boolean; delay: number; duration: number }>`
  width: 5px;
  background-color: ${({ isPlaying }) => (isPlaying ? "#0076E9" : "#B3BAC1")};
  height: 5px; /* Initial flat height */
  border-radius: 10px; /* Rounded edges */
  animation: ${(props) => (props.isPlaying ? bounceAnimation : "none")}
    ${(props) => props.duration}s infinite ease-in-out;
  animation-delay: ${(props) => props.delay}s;
  transition: height 0.2s ease;
`;
