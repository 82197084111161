import React, { FC, useEffect } from "react";
import styled from "styled-components";
import Colors from "../../../../styles/Colors";
import { HR, HeadFlex } from "../../components/styles";
import { FormN565Progress } from "./types";
import { useSearchParams } from "react-router-dom";
import N565Preparer from "./N565Preparer";
import FormProgressBar from "../../components/FormProgressBar";
import InfoFromDoc from "./InfoFromDoc";
import CurrentInfoAboutYou from "./CurrentInfoAboutYou";
import TypeOfApplication from "./TypeOfApplication";
import ReviewSection from "./Review";

interface Props {
  title: string;
  subHead?: string;
  subText: string;
}

const RightSection: FC<Props> = ({ title, subText, subHead }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const getType = searchParams.get("type");

  useEffect(() => {
    if (!getType) {
      searchParams.set("type", FormN565Progress.infoFromCurrentDoc);
      setSearchParams(searchParams);
    }
  }, [searchParams, getType, setSearchParams]);

  return (
    <Container>
      <HeadFlex>
        <Header>{title}</Header>
        <FormProgressBar section={getType || ""} enumVal={FormN565Progress} />
      </HeadFlex>
      <Line />
      {subHead && <SubHead>{subHead}</SubHead>}
      <Para>{subText}</Para>
      {FormN565Progress.infoFromCurrentDoc === getType && <InfoFromDoc />}
      {FormN565Progress.currentInfoAboutYou === getType && (
        <CurrentInfoAboutYou />
      )}
      {FormN565Progress.typeOfApplication === getType && <TypeOfApplication />}
      {FormN565Progress.PI === getType && <N565Preparer />}
      {FormN565Progress.review === getType && <ReviewSection />}
    </Container>
  );
};

export default RightSection;

const Container = styled.div`
  width: 100%;
  padding: 20px 20px 47px 20px;
  background-color: ${Colors.WhiteF9};
  border-radius: 12px;

  input,
  textarea,
  .css-1ulr2sp-control {
    background-color: transparent;
  }
`;

const Line = styled(HR)`
  margin: 0;
  margin-bottom: 16px;
`;

const Header = styled.div`
  color: ${Colors.Blue00};
`;

const Para = styled.p`
  color: ${Colors.Black31};
  margin-bottom: 32px;
  font-weight: 400px;
  font-size: 1rem;
`;

const SubHead = styled.div`
  color: ${Colors.Black31};
  margin: 16px 0;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
`;
