import { N400EligibilityPayload } from "../../../../api/n400/types";
import { FormCategories } from "../../../../routes/type";
import { N400ReasonForFiling } from "../../form 400/components/data";
import { ECongratulationType } from "./modals/EligibilityModal";
import {
  GenericEligibilityYesNoValues,
  N400AreYouMarriedToAUSCitizenLabels,
  N400DidYouServeHonorablyInActiveDutyLabels,
  N400HaveYouBeenMarriedForThreeMoreLabels,
  N400HaveYouLeftTheUSInThePastFiveYearsLabels,
  N400HaveYouLeftTheUSInThePastThreeYearsLabels,
  N400HaveYouMovedInThePastThreeMonthsLabels,
  N400HowLongHaveYouHadYourGreenCardLabels,
  N400HowOldAreYouLabels,
  checkQuestionsProps,
} from "./types";

export enum EQIds {
  q1 = 1,
  q2,
  q3a,
  q3b,
  q4,
  q5,
  q6,
  q7,
  q8,
  q9,
  q10,
  q11,
  q12,
  q13,
  q14,
  q15,
  q16,
  q17,
  q18,
}

const alreadyUS =
  "If one or both of your parents are U.S. citizens, you may need to file a different application. You may file the Application for Certificate of Citizenship (N-600). If you were born abroad and one (or both) of your parents is or was a U.S. citizen, you are automatically a citizen.";

export const checkFormN400Questions: checkQuestionsProps<
  keyof N400EligibilityPayload
>[] = [
  {
    id: EQIds.q1,
    title: "Are either of your parents U.S citizens?",
    storeField: "are_either_parents_us_citizen",
    options: [
      {
        label: "Yes – You may already be a U.S. citizen.", // needs design update
        // nextStep: EQIds.q2,
        terminate: {
          type: ECongratulationType.notEligible,
          formToRetake: FormCategories.n600,
        },
        lead: "good",
        note: alreadyUS,
      },
      {
        label: "No",
        nextStep: EQIds.q2,
        lead: "good",
      },
    ],
  },
  {
    id: EQIds.q2,
    title: "How old are you?",
    storeField: "how_old_are_you",
    options: [
      {
        label: N400HowOldAreYouLabels["18 or older"],
        nextStep: EQIds.q3a,
      },
      {
        label: N400HowOldAreYouLabels["Under 18"],
        nextStep: EQIds.q3b,
      },
    ],
  },
  {
    id: EQIds.q3a,
    title: "Are you a member of the U.S. armed forces?",
    subTitle:
      "U.S. armed forces include the U.S. Army, Navy, Air Force, Marine Corps, Coast Guard, and certain components of the National Guard and the Selected Reserve of the Ready Reserve.",
    storeField: "are_you_a_member_of_us_armed_forces",
    options: [
      {
        label: "Yes",
        nextStep: EQIds.q4,
      },
      {
        label: "No",
        nextStep: EQIds.q4,
      },
    ],
  },
  {
    id: EQIds.q3b,
    title: "Are you a member of the U.S. armed forces?",
    subTitle:
      "U.S. armed forces include the U.S. Army, Navy, Air Force, Marine Corps, Coast Guard, and certain components of the National Guard and the Selected Reserve of the Ready Reserve.",
    storeField: "are_you_a_member_of_us_armed_forces",
    options: [
      {
        label: "Yes",
        nextStep: EQIds.q4,
      },
      {
        label: "No",
        terminate: {
          type: ECongratulationType.reStart,
        },
      },
    ],
  },
  {
    id: EQIds.q4,
    title:
      "Did you serve honorably in active duty or reserve service in the U.S. armed forces?",
    storeField: "did_you_serve_honorably_in_active_duty",
    options: [
      {
        label: N400DidYouServeHonorablyInActiveDutyLabels.yesPeriodOfConflict,
        terminate: {
          type: ECongratulationType.eligible,
        },
        // note: "You may be eligible to apply for naturalization",
      },
      {
        label: N400DidYouServeHonorablyInActiveDutyLabels.yesYearOrMore,
        terminate: {
          type: ECongratulationType.eligible,
        },
        // note: "You may be eligible to apply for naturalization",
      },
      {
        label: N400DidYouServeHonorablyInActiveDutyLabels.neither,
        nextStep: EQIds.q5,
      },
    ],
  },
  {
    id: EQIds.q5,
    title: "Are you a lawful permanent resident? Green card holder",
    storeField: "are_you_a_lawful_permanent_resident",
    options: [
      {
        label: GenericEligibilityYesNoValues.Yes,
        nextStep: EQIds.q7,
      },
      {
        label: GenericEligibilityYesNoValues.No,
        nextStep: EQIds.q6,
      },
    ],
  },
  {
    id: EQIds.q6,
    title: "Are you a U.S national?",
    subTitle:
      "Individuals who were born in American Samoa or Swains Island may be eligible to become U.S. citizens if they take up residence in any U.S. state and are otherwise qualified for naturalization",
    storeField: "are_you_a_us_national",
    options: [
      {
        label: "Yes",
        terminate: {
          type: ECongratulationType.eligible,
        },
        // note: "You may be eligible to apply for naturalization",
      },
      {
        label: "No",
        terminate: {
          type: ECongratulationType.reStart,
        },
      },
    ],
  },
  {
    id: EQIds.q7,
    title: "How long have you had your green card?",
    storeField: "how_long_have_you_had_your_green_card",
    options: [
      {
        label: N400HowLongHaveYouHadYourGreenCardLabels.least5years,
        nextStep: EQIds.q8,
      },
      {
        label: N400HowLongHaveYouHadYourGreenCardLabels.threeToFourYears,
        // nextStep: EQIds.q10,
        nextStep: EQIds.q18,
      },
      {
        label: N400HowLongHaveYouHadYourGreenCardLabels.doesNotApply,
        // nextStep: EQIds.q10,
        terminate: {
          type: ECongratulationType.reStart,
        },
      },
    ],
  },
  {
    id: EQIds.q8,
    title: "Have you left the United States in the past 5 years?",
    storeField: "have_you_left_us_in_the_last_five_years",
    options: [
      {
        label: N400HaveYouLeftTheUSInThePastFiveYearsLabels.Yes,
        nextStep: EQIds.q9,
      },
      {
        label: N400HaveYouLeftTheUSInThePastFiveYearsLabels.No,
        nextStep: EQIds.q17,
      },
    ],
  },
  {
    id: EQIds.q9,
    title:
      "Have any of your trips outside of the United States been longer than 6 months?",
    storeField: "have_any_trip_outside_the_us_been_longer_than_six_months", //
    options: [
      {
        label: "Yes",
        nextStep: EQIds.q10,
      },
      {
        label: "No",
        nextStep: EQIds.q17,
      },
    ],
  },
  {
    id: EQIds.q10,
    title: "Select the statement that applies to you",
    storeField: "select_statement_that_applies_to_you_one",
    options: [
      {
        label:
          "I am an employee or individual under contract to the U.S. government",
        nextStep: EQIds.q12,
      },
      {
        label:
          "I am performing ministerial or priestly functions for a religious denomination or interdenominational organization with a valid presence in the United States",
        nextStep: EQIds.q12,
      },
      {
        label:
          "I have been employed for 5 years or more by a U.S. nonprofit organization that principally promotes the interests of the United States abroad through the communications media",
        terminate: {
          type: ECongratulationType.eligible,
        },
        // note: "You may be eligible to apply for naturalization.",
      },
      {
        label:
          "I am employed by an American institution of research recognized by the Attorney General",
        nextStep: EQIds.q12,
      },
      {
        label:
          "I am employed by an American-owned firm or corporation engaged in the development of foreign trade and commerce for the United States",
        nextStep: EQIds.q12,
      },
      {
        label:
          "I am employed by a public international organization of which the United States is a member by law or treaty (and my employment began after I became a permanent resident)",
        nextStep: EQIds.q12,
      },
      {
        label: "None of these apply to me",
        nextStep: EQIds.q11,
      },
    ],
  },
  {
    id: EQIds.q11,
    title: "Select the statement that applies you",
    storeField: "select_statement_that_applies_to_you_two",
    options: [
      {
        label:
          "I was the spouse of a member of the U.S. armed forces at the time of his or her death and my spouse was a U.S. citizen",
        terminate: {
          type: ECongratulationType.eligible,
        },
        // note: "You may be eligible to apply for naturalization",
      },
      {
        label:
          "I am the child of a deceased member of the U.S. armed forces and my parent was a U.S. citizen",
        terminate: {
          type: ECongratulationType.eligible,
        },
        // note: "You may be eligible to apply for naturalization",
      },
      {
        label:
          "I am the parent of a deceased member of the U.S. armed forces and my child was a U.S. citizen",
        terminate: {
          type: ECongratulationType.eligible,
        },
        // note: "You may be eligible to apply for naturalization",
      },
      {
        label: "None of these apply to me",
        nextStep: EQIds.q12,
      },
    ],
  },
  {
    id: EQIds.q12,
    title: "Are you married to a U.S. citizen?",
    subTitle:
      "Select no if you are divorced or legally separated, if your marriage was annulled, or if your spouse is deceased.",
    storeField: "are_you_married_to_us_citizen",
    options: [
      {
        label: N400AreYouMarriedToAUSCitizenLabels.Yes,
        nextStep: EQIds.q13,
      },
      {
        label: N400AreYouMarriedToAUSCitizenLabels.No,
        terminate: {
          type: ECongratulationType.reStart,
        },
      },
    ],
  },
  {
    id: EQIds.q13,
    title: "Have you been married for 3 years or longer?",
    storeField: "have_you_been_married_for_three_years_or_longer",
    options: [
      {
        label: N400HaveYouBeenMarriedForThreeMoreLabels.Yes,
        nextStep: EQIds.q14,
      },
      {
        label: N400HaveYouBeenMarriedForThreeMoreLabels.No,
        nextStep: EQIds.q14,
      },
    ],
  },
  {
    id: EQIds.q14,
    title: "Has your spouse been a U.S. citizen for 3 years or longer?",
    storeField: "has_your_spouse_been_us_citizen_for_three_years_or_longer",
    options: [
      {
        label: GenericEligibilityYesNoValues.Yes,
        nextStep: EQIds.q15,
      },
      {
        label: GenericEligibilityYesNoValues.No,
        terminate: {
          type: ECongratulationType.reStart,
        },
      },
    ],
  },
  {
    id: EQIds.q15,
    title: "Have you left the United States in the past 3 years?",
    storeField: "have_you_left_us_in_the_past_three_years",
    options: [
      {
        label: N400HaveYouLeftTheUSInThePastThreeYearsLabels.Yes,
        nextStep: EQIds.q16,
      },
      {
        label: N400HaveYouLeftTheUSInThePastThreeYearsLabels.No,
        nextStep: EQIds.q17,
      },
    ],
  },
  {
    id: EQIds.q16,
    title:
      "Have any of your trips outside of the United States been longer than 6 months?",
    storeField: "have_any_trip_outside_the_us_been_longer_than_six_months", //
    options: [
      {
        label: "Yes",
        nextStep: EQIds.q17,
      },
      {
        label: "No",
        terminate: {
          type: ECongratulationType.eligible,
        },
        // note: "You may be eligible to apply for naturalization",
      },
    ],
  },
  {
    id: EQIds.q17,
    title: "Have you moved in the past 3 months?",
    storeField: "have_you_moved_in_the_past_three_months",
    options: [
      {
        label: N400HaveYouMovedInThePastThreeMonthsLabels.Yes,
        terminate: {
          type: ECongratulationType.eligible,
        },
        note: (
          <>
            <div>
              1. Your eligibility may depend on the length of your international
              travel and the reason you were traveling. If you have been absent
              from the United States for one year or more, you are required to
              have an approved Application to Preserve Residence for
              Naturalization Purposes (N-470) before you apply for
              naturalization
            </div>
            <br />
            <div>
              2. Your eligibility may depend on where you moved from. You need
              to wait to apply if you moved to a new state in the last 3 months.
              You may apply once you have lived in your new location for 3
              months.
            </div>
          </>
        ),
      },
      {
        label: N400HaveYouMovedInThePastThreeMonthsLabels.No,
        terminate: {
          type: ECongratulationType.eligible,
        },
        // note: "You may be eligible to apply for naturalization",
      },
    ],
  },
  {
    id: EQIds.q18,
    title: "What is your reason for filing?",
    storeField: "choose_between_options", //
    options: [
      {
        label: N400ReasonForFiling.spouseUSCitizen,
        nextStep: EQIds.q10,
      },
      {
        label: N400ReasonForFiling.vawa,
        nextStep: EQIds.q10,
      },
      {
        label: N400ReasonForFiling.employmentSpouseUSCitizen,
        nextStep: EQIds.q10,
      },
    ],
  },
];
