import React, { FC } from "react";
import styled from "styled-components";
import { mediaObj } from "../../../../styles/Media";
import RadioField from "../../components/formik fields/RadioField";
import Colors from "../../../../styles/Colors";
import InputField from "../../components/formik fields/InputField";
import SelectField from "../../components/formik fields/SelectField";
import { ApartmentSuiteFloorData } from "../../components/data";
import { FormData } from "..";
import { FormikProps } from "formik";
import { SingleOption } from "../../../../types/types";

interface Props {
  formik: FormikProps<FormData>;
  preparerCountryData: SingleOption[];
  preparerCountryLoading: boolean;
  preparerStateData: SingleOption[];
  preparerStateLoading: boolean;
  preparerSetCountry: React.Dispatch<React.SetStateAction<string>>;
  preparerSelectedCountry: string;
}

const AccreditedRepSection: FC<Props> = ({
  formik,
  preparerCountryData,
  preparerCountryLoading,
  preparerStateData,
  preparerStateLoading,
  preparerSetCountry,
  preparerSelectedCountry,
}) => {
  return (
    <>
      <Header>Accredited Representative Information</Header>
      <RadioWrapper>
        <RadioLabel>
          My representation of the applicant in this case:
        </RadioLabel>
        <RadioFlex>
          <RadioField
            name="representationOfApplicant"
            value="extends"
            label="Extends beyond the preparation of this application."
          />
          <RadioField
            name="representationOfApplicant"
            value="does_not_extends"
            label="Does not extend beyond the preparation of this application."
          />
        </RadioFlex>
      </RadioWrapper>
      <SubHeader>Preparer’s Information </SubHeader>
      <FormWrapper>
        <Flexxer>
          <InputField
            label="Given Name"
            coloredLabel="(First Name)"
            placeholder=""
            name="preparerFirstName"
          />
          <InputField
            label="Family Name"
            coloredLabel="(Last Name)"
            placeholder=""
            name="preparerLastName"
          />
        </Flexxer>
        <Flexxer>
          <InputField
            label="Firm or Organization Name"
            placeholder=""
            name="preparerFirm"
          />
          <InputField
            label="State Bar Number"
            placeholder=""
            name="stateBarNum"
          />
        </Flexxer>
        <Flexxer>
          <InputField
            label="USCIS Online Account Number"
            placeholder=""
            name="uscisOnlineAccountNumber"
          />
          <div></div>
        </Flexxer>
        <SubHeader>Preparer’s Address</SubHeader>
        <Flexxer>
          <InputField
            label="Street Number and Name"
            placeholder=""
            name="preparerStreetNumAndName"
          />
          <InputField label="ZIP Code" placeholder="" name="preparerZipCode" />
        </Flexxer>
        <Flexxer>
          <SelectField
            label="Country"
            placeholder=""
            name="preparerCountry"
            options={preparerCountryData}
            isLoading={preparerCountryLoading}
            onChange={(value) => {
              preparerSetCountry(value.label);
              formik.setFieldValue(`preparerStateOrTerritory`, null);
            }}
          />

          <SelectField
            label="State"
            placeholder=""
            name="preparerStateOrTerritory"
            options={preparerStateData}
            isLoading={preparerStateLoading}
            isDisabled={!preparerSelectedCountry}
          />
        </Flexxer>

        <Flexxer>
          <SelectField
            name="preparerApartmentSuiteOrFloor"
            label="In there an apartment, suite or floor"
            placeholder="-Select-"
            options={ApartmentSuiteFloorData}
          />
          <div>
            {formik.values.preparerApartmentSuiteOrFloor?.value && (
              <InputField
                label={`${formik.values.preparerApartmentSuiteOrFloor.value} Address number`}
                placeholder=""
                name={`preparerAddressNum`}
              />
            )}
          </div>
        </Flexxer>
        <Flexxer>
          <InputField
            label="City or Town"
            placeholder=""
            name="preparerCityOrTown"
          />
          <div></div>
        </Flexxer>
        <SubHeader>Preparer’s Contact Information</SubHeader>
        <Flexxer>
          <InputField
            label="Daytime Phone Number"
            placeholder=""
            name="preparerDayTimePhoneNumber"
          />
          <InputField
            label="Mobile Phone Number"
            coloredLabel="(if any)"
            placeholder=""
            name="preparerMobilePhoneNumber"
          />
        </Flexxer>

        <Flexxer>
          <InputField label="Email" placeholder="" name="preparerEmail" />
          <div></div>
        </Flexxer>
      </FormWrapper>
    </>
  );
};

export default AccreditedRepSection;

const Header = styled.div`
  font-size: 1rem;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  padding-bottom: 6px;
  border-bottom: 1px solid ${Colors.GreyA7};
  margin-bottom: 30px;
`;

const SubHeader = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
`;

const RadioLabel = styled.div`
  margin-bottom: 12px;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: ${Colors.Black31};
  font-weight: 400;

  &.required:after {
    content: "*";
    font-size: 24px;
    line-height: 0;
    vertical-align: middle;
    color: #d90b2c;
  }

  ${mediaObj.mobile} {
    font-size: 0.875rem;
  }
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding-bottom: 14px;
  margin-bottom: 32px;

  input {
    accent-color: red;
  }
`;

const RadioFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 20px;
`;

const Flexxer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 70px;

  & > div {
    flex: 1;
  }
`;
