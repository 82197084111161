import React, { FC } from "react";
import Colors from "../../../styles/Colors";
import styled from "styled-components";
import { mediaObj } from "../../../styles/Media";
import { motion } from "framer-motion";
import Overlay from "../checkEligibilty/components/modals/Overlay";
import { createPortal } from "react-dom";
import { ContainerVariants } from "../checkEligibilty/components/modals/variant";
import { ImCancelCircle } from "react-icons/im";

interface Props {
  onModalClose: () => void;
  isLoading: boolean;
  isError: boolean;
  children: JSX.Element;
}

const PayWrapper: FC<Props> = ({
  onModalClose,
  isError,
  isLoading,
  children,
}) => {
  return createPortal(
    <Overlay onClose={() => {}}>
      <Wrapper
        onClick={(e) => e.stopPropagation()}
        variants={ContainerVariants}
      >
        <Cancel
          onClick={() => {
            if (isLoading && !isError) return;
            onModalClose();
          }}
        >
          <ImCancelCircle />
        </Cancel>

        {isLoading && !isError && (
          <ContentWrapper>
            <TextBold>Processing...</TextBold>
          </ContentWrapper>
        )}

        {isError && !isLoading && (
          <>
            <MainIcon>
              <ImCancelCircle />
            </MainIcon>
            <ContentWrapper>
              <TextBold>
                ⚠️ There was an error processing your payments! ⚠️
              </TextBold>
              <Text>
                An error occur during your payment process, please retry later.
              </Text>
            </ContentWrapper>
          </>
        )}

        {!isLoading && !isError && children}
      </Wrapper>
    </Overlay>,
    document.body
  );
};

export default PayWrapper;

const Wrapper = styled(motion.div)`
  width: 802px;
  max-height: 80vh;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 12px;
  padding: 76px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${mediaObj.smallDesktop} {
    margin: auto;
    width: 70vw;
    padding-left: 5%;
    padding-right: 5%;
  }

  ${mediaObj.tablet} {
    width: 80vw;
  }

  ${mediaObj.bigMobile} {
    width: 90vw;
  }
`;

const Cancel = styled.div`
  position: absolute;
  right: 40px;
  top: 48px;
  width: 24px;
  height: 24px;
  color: ${Colors.Black};
  cursor: pointer;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const MainIcon = styled.div`
  width: 108px;
  height: 108px;
  margin-bottom: 16px;

  & > svg,
  & > img {
    width: 100%;
    height: 100%;
    fill: ${Colors.RedC3};
  }
`;

const ContentWrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Text = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
`;

const TextBold = styled(Text)`
  font-weight: bold;
`;
