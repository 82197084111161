import * as Yup from "yup";
import { SchemaObject } from "../../components/type";
import { digitsOnly, optionalDigitsOnly } from "../../../../utils";
import { GenericYesNoOptionsValue, isZipCode } from "../../components/data";
import { N565DocInfoFormData } from "./InfoFromDoc";
import { N565CurrentInfoAboutYouFormData } from "./CurrentInfoAboutYou";
import { TypeOfApplicationFormData } from "./TypeOfApplication";
import {
  N565NameChangedThroughDataValue,
  N565TypeOfApplicationDataValue,
} from "./data";
import { onlyWhen } from "../../form 600/components/validation";

export const InfoFromDocValidation = Yup.object().shape<
  SchemaObject<N565DocInfoFormData>
>({
  certificateFirstName: Yup.string().required("Required").trim(),
  certificateMiddleName: Yup.string().trim(),
  certificateLastName: Yup.string().required("Required").trim(),
  certificateNumber: Yup.string()
    .min(6, "Must be 6 to 8 characters!")
    .max(8, "Must be 6 to 8 characters!")
    .required("Required")
    .trim(),
  whoIssuedCertificate: Yup.string().required("Required").trim(),
  certificateANumber: Yup.string()
    .min(8, "Minimum of 8 characters allowed")
    .max(9, "Maximum of 9 characters allowed")
    .test("Digits only", "Must be a number", digitsOnly),
  certificateDateOfBirth: Yup.string().nullable().required("Required"),
  certificateDateOfIssuance: Yup.string().nullable().required("Required"),
  certificateCountryOfBirth: Yup.object().nullable().required("Required"),
});

const dependsOnOtherNames = () => {
  return Yup.array().when("otherNames", ([otherNames], schema) => {
    if (otherNames === GenericYesNoOptionsValue.yes)
      return Yup.array().of(
        Yup.object().shape({
          otherNamesFirstName: Yup.string().required("Required").trim(),
          otherNamesMiddleName: Yup.string().trim(),
          otherNamesLastName: Yup.string().required("Required").trim(),
        })
      );

    return schema;
  });
};

export const CurrentInfoAboutYouValidation = Yup.object().shape<
  SchemaObject<N565CurrentInfoAboutYouFormData>
>({
  firstName: Yup.string().required("Required").trim(),
  middleName: Yup.string().trim(),
  lastName: Yup.string().required("Required").trim(),
  otherNames: Yup.string().required("Required").trim(),
  otherNamesArray: dependsOnOtherNames(),
  inCareOfName: Yup.string()
    .required("Required")
    .max(34, "Maximum of 34 characters allowed")
    .trim(),
  apartmentSuiteOrFloor: Yup.object().nullable().required("Required"),
  addressNumber: Yup.string().when(
    "apartmentSuiteOrFloor",
    ([apartmentSuiteOrFloor], schema) => {
      if (apartmentSuiteOrFloor?.value)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),
  country: Yup.object().nullable().required("Required"),
  addressCode: Yup.string()
    .required("Required")
    .when("country", ([country], schema) => {
      if (isZipCode(country?.value))
        return Yup.string()
          .length(5, "Maximum of 5 characters allowed")
          .required("Required")
          .trim()
          .test("Digits only", "Must be a number", digitsOnly);
      return schema;
    }),
  streetNumberAndName: Yup.string()
    .max(34, "Maximum of 34 character allowed")
    .required("Required")
    .trim(),
  province: Yup.string().trim(),
  cityOrTown: Yup.string()
    .max(20, "Maximum of 20 character allowed")
    .required("Required")
    .trim(),
  stateOrTerritory: Yup.object().required("Required"),
  didMaritalStatusChange: Yup.string().required().trim(),
  maritalStatus: Yup.object()
    .nullable()
    .when(`didMaritalStatusChange`, ([didMaritalStatusChange], schema) => {
      if (didMaritalStatusChange === GenericYesNoOptionsValue.yes)
        return Yup.object().nullable().required("Required");
      return schema;
    }),
  lostOrRenCitizenship: Yup.string().required("Required").trim(),
  reasonForLoss: Yup.string().when(
    `lostOrRenCitizenship`,
    ([lostOrRenCitizenship], schema) => {
      if (lostOrRenCitizenship === GenericYesNoOptionsValue.yes)
        return Yup.string().required("Required").trim();
      return schema;
    }
  ),

  applicantsDaytimeTelephoneNumber: Yup.string()
    .min(10, "Minimum 10 digits allowed")
    .max(10, "Maximum 10 digits allowed")
    .required("Required")
    .test("Digits only", "Must be a number", digitsOnly)
    .trim(),
  applicantsMobileTelephoneNumber: Yup.string()
    .min(10, "Minimum 10 digits allowed")
    .max(10, "Maximum 10 digits allowed")
    .test("Digits only", "Must be a number", optionalDigitsOnly)
    .trim(),
  applicantsEmailAddress: Yup.string().email().trim(),
});

const dependBasisForApplication = () => {
  return Yup.bool().test(
    "oneOfRequired",
    "One must be checked",
    function (item) {
      return (
        this.parent.lostStolenOrDestroyed ||
        this.parent.mutilated ||
        this.parent.typoError ||
        this.parent.nameLegallyChanged ||
        this.parent.dateOfBirthLegallyChanged ||
        this.parent.changeGender ||
        this.parent.reasonNotListed
      );
    }
  );
};

const dependTypeOfTypoError = () => {
  return Yup.bool().test(
    "oneOfRequired",
    "One must be checked",
    function (item) {
      return (
        this.parent.name ||
        this.parent.dateOfBirth ||
        this.parent.gender ||
        this.parent.other
      );
    }
  );
};

const dependDateOfBirthChangedThrough = () => {
  return Yup.bool().test(
    "oneOfRequired",
    "One must be checked",
    function (item) {
      return this.parent.courtOrder || this.parent.usGovIssuedDoc;
    }
  );
};

const dependGenderChange = () => {
  return Yup.bool().test(
    "oneOfRequired",
    "One must be checked",
    function (item) {
      return (
        this.parent.courtOrder ||
        this.parent.govIssuedDoc ||
        this.parent.licensedHealthCareCertification
      );
    }
  );
};

export const TypeOfApplicationValidation = Yup.object().shape<
  SchemaObject<TypeOfApplicationFormData>
>({
  typeOfApplication: Yup.string().required("Required").trim(),
  nameOfForeignCountry: onlyWhen(
    "typeOfApplication",
    N565TypeOfApplicationDataValue.recognition,
    "isString",
    "isSelect"
  ),
  foreignLastName: onlyWhen(
    "typeOfApplication",
    N565TypeOfApplicationDataValue.recognition,
    "isString",
    "isString"
  ),
  foreignFirstName: onlyWhen(
    "typeOfApplication",
    N565TypeOfApplicationDataValue.recognition,
    "isString",
    "isString"
  ),
  foreignMiddleName: Yup.string().trim(),
  officialTitle: onlyWhen(
    "typeOfApplication",
    N565TypeOfApplicationDataValue.recognition,
    "isString",
    "isString"
  ),
  nameOfGovernmentAgency: onlyWhen(
    "typeOfApplication",
    N565TypeOfApplicationDataValue.recognition,
    "isString",
    "isString"
  ),
  foreignStreetNumberAndName: onlyWhen(
    "typeOfApplication",
    N565TypeOfApplicationDataValue.recognition,
    "isString",
    "isStreetName"
  ),
  foreignCityOrTown: onlyWhen(
    "typeOfApplication",
    N565TypeOfApplicationDataValue.recognition,
    "isString",
    "isCityOrTown"
  ),
  foreignApartmentSuiteOrFloor: onlyWhen(
    "typeOfApplication",
    N565TypeOfApplicationDataValue.recognition,
    "isString",
    "isSelect"
  ),
  foreignAddressNumber: onlyWhen(
    "typeOfApplication",
    N565TypeOfApplicationDataValue.recognition,
    "isString",
    "isAptNumber"
  ),
  foreignCountry: onlyWhen(
    "typeOfApplication",
    N565TypeOfApplicationDataValue.recognition,
    "isString",
    "isSelect"
  ),
  foreignStateOrTerritory: onlyWhen(
    "typeOfApplication",
    N565TypeOfApplicationDataValue.recognition,
    "isString",
    "isSelect"
  ),
  foreignAddressCode: onlyWhen(
    "typeOfApplication",
    N565TypeOfApplicationDataValue.recognition,
    "isString",
    "isString",
    "foreignCountry"
  ),
  foreignProvince: Yup.string().trim(),
  //
  basisForApplicationArray: Yup.array().when(
    `typeOfApplication`,
    ([typeOfApplication], schema) => {
      if (
        typeOfApplication &&
        typeOfApplication !== N565TypeOfApplicationDataValue.recognition
      )
        return Yup.array().of(
          Yup.object().shape({
            lostStolenOrDestroyed: dependBasisForApplication(),
            mutilated: dependBasisForApplication(),
            typoError: dependBasisForApplication(),
            nameLegallyChanged: dependBasisForApplication(),
            dateOfBirthLegallyChanged: dependBasisForApplication(),
            changeGender: dependBasisForApplication(),
            reasonNotListed: dependBasisForApplication(),
          })
        );
      return schema;
    }
  ),
  lostStolenOrDestroyedExplanation: onlyWhen(
    "basisForApplicationArray",
    `lostStolenOrDestroyed`,
    "isCheck",
    "isString"
  ),
  //part 4
  typeOfTypoErrorArray: Yup.array().when(
    `basisForApplicationArray`,
    ([basisForApplicationArray], schema) => {
      if (basisForApplicationArray[0].typoError)
        return Yup.array().of(
          Yup.object().shape({
            name: dependTypeOfTypoError(),
            dateOfBirth: dependTypeOfTypoError(),
            gender: dependTypeOfTypoError(),
            other: dependTypeOfTypoError(),
          })
        );
      return schema;
    }
  ),
  explanationOfTypoError: onlyWhen(
    "basisForApplicationArray",
    `typoError`,
    "isCheck",
    "isString"
  ),
  //part 5
  nameChangedThrough: onlyWhen(
    "basisForApplicationArray",
    "nameLegallyChanged",
    "isCheck",
    "isString"
  ),
  nameChangedDateOfEvent: onlyWhen(
    "nameChangedThrough",
    N565NameChangedThroughDataValue.marriageDivorceOrAnnulment,
    "isString",
    "isDate"
  ),
  nameChangedDateOfCourtOrder: onlyWhen(
    "nameChangedThrough",
    N565NameChangedThroughDataValue.courtOrder,
    "isString",
    "isDate"
  ),
  newLegalLastName: onlyWhen(
    "basisForApplicationArray",
    "nameLegallyChanged",
    "isCheck",
    "isString"
  ),
  newLegalFirstName: onlyWhen(
    "basisForApplicationArray",
    "nameLegallyChanged",
    "isCheck",
    "isString"
  ),
  newLegalMiddleName: Yup.string().trim(),
  //part 6
  dateOfBirthChangedThroughArray: Yup.array().when(
    `basisForApplicationArray`,
    ([basisForApplicationArray], schema) => {
      if (basisForApplicationArray[0].dateOfBirthLegallyChanged)
        return Yup.array().of(
          Yup.object().shape({
            courtOrder: dependDateOfBirthChangedThrough(),
            usGovIssuedDoc: dependDateOfBirthChangedThrough(),
          })
        );
      return schema;
    }
  ),
  dateOfBirthChangedDateOfCourtOrder: onlyWhen(
    "dateOfBirthChangedThroughArray",
    "courtOrder",
    "isCheck",
    "isDate"
  ),
  dateOfBirthChangedUsGovIssuedDoc: onlyWhen(
    "dateOfBirthChangedThroughArray",
    "usGovIssuedDoc",
    "isCheck",
    "isDate"
  ),
  newDateOfBirth: onlyWhen(
    "basisForApplicationArray",
    "dateOfBirthLegallyChanged",
    "isCheck",
    "isDate"
  ),
  //part 7
  genderChangedThroughArray: Yup.array().when(
    `basisForApplicationArray`,
    ([basisForApplicationArray], schema) => {
      if (basisForApplicationArray[0].changeGender)
        return Yup.array().of(
          Yup.object().shape({
            courtOrder: dependGenderChange(),
            govIssuedDoc: dependGenderChange(),
            licensedHealthCareCertification: dependGenderChange(),
          })
        );
      return schema;
    }
  ),
  genderChangedThroughCurrentGender: onlyWhen(
    "basisForApplicationArray",
    `changeGender`,
    "isCheck",
    "isString"
  ),
  //
  explanationForNewReason: onlyWhen(
    "basisForApplicationArray",
    `reasonNotListed`,
    "isCheck",
    "isString"
  ),
});
