import { FaPlaneDeparture } from "react-icons/fa6";
import { HiAcademicCap } from "react-icons/hi2";
import { IoPeopleCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { WebPaths } from "../../../../../routes/data";
import PricingTab from "../../components/PricingTab";

const features = [
  {
    name: "Student Visas (F1 & M1)",
    description:
      "For those seeking to study in the United States, whether in academic or vocational programs.",
    href: `${WebPaths.checkEligibility}/ds160`,
    icon: HiAcademicCap,
  },
  {
    name: "Dependent Visas (F2)",
    description:
      "For dependents (spouse and children) of F1 visa holders, allowing them to join their family in the U.S.",
    href: `${WebPaths.checkEligibility}/ds160`,
    icon: IoPeopleCircleOutline,
  },
  {
    name: "Travel Visas (B1/B2)",
    description:
      "For short-term visitors traveling for business (B1) or leisure (B2) purposes.",
    href: `${WebPaths.checkEligibility}/ds160`,
    icon: FaPlaneDeparture,
  },
];

const Offers = () => {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          {/* <h2 className="text-base/7 font-semibold text-blue-600">
            Deploy faster
          </h2> */}
          <p className="mt-2 text-pretty text-4xl font-bold font-spartan tracking-tight text-gray-900 sm:text-5xl lg:text-balance">
            Popular Packages
          </p>
          {/* <p className="mt-6 text-lg/8 text-gray-600"></p> */}
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col items-center">
                <dt className="flex items-center gap-x-3 text-base/7 font-semibold text-gray-900">
                  <feature.icon
                    aria-hidden="true"
                    className="h-6 w-6 flex-none text-blue-600"
                  />
                  <span className="font-spartan font-bold text-xl">
                    {feature.name}
                  </span>
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base/7 text-gray-600">
                  <p className="flex-auto text-center">{feature.description}</p>
                </dd>
                <Link
                  to={feature.href}
                  className="group bg-Blue00/40 grow text-sm font-semibold text-Black shadow-sm flex items-center gap-x-16 justify-between w-fit mt-10"
                >
                  <span className="px-4">Get Started</span>
                  <div className="bg-Blue00 group-hover:bg-Blue00/40 p-3 text-white">
                    <feature.icon
                      aria-hidden="true"
                      className="h-6 w-6 flex-none "
                    />
                  </div>
                </Link>
              </div>
            ))}
          </dl>
        </div>
        <PricingTab />
      </div>
    </div>
  );
};

export default Offers;
