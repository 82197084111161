export const loginSlides = [
  {
    name: "Maria",
    img: "https://img.freepik.com/premium-vector/female-user-profile-avatar-is-woman-character-screen-saver-with-emotions_505620-617.jpg?w=740",
    country: "from united states",
    testimony:
      "Your platform interface and detailed guidance made the citizen visa application process seamless.",
  },
  {
    name: "Stephen",
    img: "https://img.freepik.com/free-icon/man_318-157617.jpg?t=st=1692053646~exp=1692054246~hmac=1a6fbe31ddb0ee539085afc4b87ee01b3fb76d34677aa6e855e97e5f914fde94",
    country: "from united states",
    testimony:
      "SeamlessVisa played a vital role in my citizenship application process. I got regular updates. Their immigration experts also ensured that I was well prepared every step of the way.",
  },
  {
    name: "Samuel Okafor",
    img: "https://img.freepik.com/premium-vector/female-user-profile-avatar-is-woman-character-screen-saver-with-emotions_505620-617.jpg?w=740",
    country: "from united states",
    testimony:
      "Outstanding service. I used them to apply for my student visa and I love how responsive they were.",
  },
  {
    name: "Zack. S",
    img: "https://img.freepik.com/free-icon/man_318-157617.jpg?t=st=1692053646~exp=1692054246~hmac=1a6fbe31ddb0ee539085afc4b87ee01b3fb76d34677aa6e855e97e5f914fde94",
    country: "from united states",
    testimony:
      "Filling out forms on this platform was easy. They were also available to assist when I needed them.",
  },
];

export enum BusinessTypeValues {
  Starter = "Starter",
  Registered = "Registered",
}

export const BusinessTypeData = [
  {
    label: "Starter Business",
    value: BusinessTypeValues.Starter,
  },

  {
    label: "Registered Business",
    value: BusinessTypeValues.Registered,
  },
];
