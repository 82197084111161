import React, { FC } from "react";
import {
  FormWrapper,
  RadioLabel,
  RadioWrapper,
} from "../../../components/styles";
import { Colored } from "../../../form 400/components/styles";
import { FieldArray, FormikProps } from "formik";
import {
  CheckWrap,
  Title,
  TypeOfApplicationFormData,
} from "../TypeOfApplication";
import CheckBoxField from "../../../components/formik fields/CheckBoxField";
import DatePickerField from "../../../components/formik fields/DatePickerField";
import { USDatePlaceholderFormat } from "../../../components/data";

interface Props {
  formik: FormikProps<TypeOfApplicationFormData>;
}

const Part6: FC<Props> = ({ formik }) => {
  return (
    <>
      {formik.values.basisForApplicationArray[0].dateOfBirthLegallyChanged && (
        <div className="flex flex-col gap-4">
          <Title>
            Complete If Applying for a New Certificate of Citizenship Because of
            an Official Date of Birth Change
          </Title>
          <RadioWrapper>
            <RadioLabel>
              My date of birth changed through{" "}
              <Colored>(select all applicable boxes)</Colored>
            </RadioLabel>
            <FieldArray
              name="dateOfBirthChangedThroughArray"
              render={(arrayHelpers) => (
                <>
                  {formik.values.dateOfBirthChangedThroughArray.map(
                    (_, index) => (
                      <CheckWrap key={index}>
                        <CheckBoxField
                          value={"Court Order"}
                          name={`dateOfBirthChangedThroughArray[${index}].courtOrder`}
                          label={"Court Order"}
                        />
                        <CheckBoxField
                          value={"U.S. Government-Issued Document"}
                          name={`dateOfBirthChangedThroughArray[${index}].usGovIssuedDoc`}
                          label={"U.S. Government-Issued Document"}
                        />
                      </CheckWrap>
                    )
                  )}
                </>
              )}
            />
          </RadioWrapper>

          {formik.values.dateOfBirthChangedThroughArray[0].courtOrder && (
            <>
              <FormWrapper>
                <DatePickerField
                  label="Date of Court Order"
                  name="dateOfBirthChangedDateOfCourtOrder"
                  placeholder={USDatePlaceholderFormat}
                  disableFuture
                />

                <div></div>
              </FormWrapper>
            </>
          )}

          {formik.values.dateOfBirthChangedThroughArray[0].usGovIssuedDoc && (
            <>
              <FormWrapper>
                <DatePickerField
                  label="Date of U.S. Government-Issued Document"
                  name="dateOfBirthChangedUsGovIssuedDoc"
                  placeholder={USDatePlaceholderFormat}
                  disableFuture
                />

                <div></div>
              </FormWrapper>
            </>
          )}

          <FormWrapper>
            <DatePickerField
              label="My new date of birth is (as shown in the court order or U.S. Government-issued document):"
              name="newDateOfBirth"
              placeholder={USDatePlaceholderFormat}
              disableFuture
            />
            <div></div>
          </FormWrapper>
        </div>
      )}
    </>
  );
};

export default Part6;
