import { AiOutlineComment } from "react-icons/ai";
import { BiSolidBusiness } from "react-icons/bi";
import { RiUser6Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import heroImage from "../../../../images/web/general-page-hero.jpg";
import { WebPaths } from "../../../../routes/data";

const Hero = () => {
  return (
    <div className="relative isolate overflow-hidden bg-white">
      <svg
        aria-hidden="true"
        className="absolute inset-0 -z-10 h-full w-full stroke-blue-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
      >
        <defs>
          <pattern
            x="50%"
            y={-1}
            id="0787a7c5-978c-4f66-83c7-11c213f99cb7"
            width={200}
            height={200}
            patternUnits="userSpaceOnUse"
          >
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <rect
          fill="url(#0787a7c5-978c-4f66-83c7-11c213f99cb7)"
          width="100%"
          height="100%"
          strokeWidth={0}
        />
      </svg>
      <div className="mx-auto max-w-7xl px-6 pb-24 py-10 sm:pb-24 lg:flex lg:px-8 ">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-shrink-0 lg:pt-8">
          <h1 className="mt-10 text-pretty text-5xl font-semibold font-spartan tracking-tight text-Blue00 sm:text-7xl capitalize">
            Avoid U.S. visa denial and boost your{" "}
            <span className="font-spartan font-semibold text-Blue00">
              approval
            </span>{" "}
            chances
          </h1>
          <p className="mt-8 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
            Thousands of people are using SeamlessVisa to review their
            applications for inconsistencies, practice for visa interviews with
            realistic simulations, and improve their approval chances.
          </p>
          <div className="mt-10 flex flex-col items-start md:flex-row md:items-center gap-4">
            <Link
              to={WebPaths.Individuals}
              className="group bg-Blue00/40 group-hover:bg-Blue00 grow text-sm font-semibold text-Black shadow-sm flex items-center justify-between w-64 md:w-auto duration-150 pl-5"
            >
              <span className="">For Individuals</span>
              <div className="bg-Blue00 p-4 text-white group-hover:bg-Blue00/40 duration-150">
                <RiUser6Line className="h-5 w-5" />
              </div>
            </Link>

            <Link
              to={WebPaths.Businesses}
              className="group bg-[#00C1A3] grow text-sm font-semibold text-Black shadow-sm flex items-center justify-between w-64 md:w-auto duration-150 pl-5"
            >
              <span className="">For Businesses</span>
              <div className="bg-[#1F8A79] group-hover:bg-[#1F8A79]/40 p-4 text-white duration-150">
                <BiSolidBusiness className="h-6 w-6" />
              </div>
            </Link>

            <Link
              to={WebPaths.FiBiAi}
              className="group bg-[#EAC5FC] grow text-sm font-semibold text-Black shadow-sm flex items-center justify-between w-64 md:w-auto duration-150 pl-5"
            >
              <span className="">Try Interview simulation</span>
              <div className="bg-[#A881DB] group-hover:bg-[#A881DB]/40 p-4 text-white duration-150">
                <AiOutlineComment className="h-6 w-6" />
              </div>
            </Link>
          </div>
        </div>
        <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
          <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
            <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
              <img
                alt="App screenshot"
                src={heroImage}
                // width={2432}
                // height={1442}
                className="w-[30rem] md:w-[50rem] h-[720px] object-cover rounded-md shadow-2xl ring-1 ring-gray-900/10"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Hero;
