import React from "react";
import styled from "styled-components";
import Constaint from "../layout/components/Constaint";
import RightSection from "./components/RightSection";
import { FormN565Progress } from "./components/types";
import LeftSideBar from "../components/LeftSideBar";
import { n565leftSideBarData } from "./components/data";
import useFormRouteGuard from "../../../hooks/useFormRouteGuard";
import ChatSystem from "../landing page/components/ChatSystem";

const F565 = () => {
  const { getType, lastProgress } = useFormRouteGuard(FormN565Progress);

  const getSection = () => {
    if (FormN565Progress.infoFromCurrentDoc === getType)
      return (
        <RightSection
          title="1. Information From Current Certificate or Declaration"
          subText=""
        />
      );
    else if (FormN565Progress.currentInfoAboutYou === getType)
      return (
        <RightSection title="2. Current Information About You" subText="" />
      );
    else if (FormN565Progress.typeOfApplication === getType)
      return <RightSection title="3. Type of Application" subText={``} />;
    else if (FormN565Progress.PI === getType)
      return <RightSection title="4. Preparer and Interpreter" subText={``} />;
    else if (FormN565Progress.review === getType)
      return (
        <RightSection
          title="5. Review and Pay"
          subHead="Review and Pay - N565"
          subText={`Please take a moment to review your answers before submitting your visa application. Ensure that all information is accurate and complete. You can make edits by clicking the 'Edit' button next to each section. Once you are satisfied with your responses, you can proceed to unlock the download by paying.`}
        />
      );
  };
  return (
    <Wrapper>
      <Constaint>
        <Container>
          <LeftSideBar
            title="(N-565) Application for Replacement Naturalization/Citizenship Document"
            data={n565leftSideBarData}
            storeProgress={lastProgress}
            progressEnum={FormN565Progress}
          />
          {getSection()}
          <ChatSystem formName="N565" />
        </Container>
      </Constaint>
    </Wrapper>
  );
};

export default F565;

const Wrapper = styled.div`
  margin: 28px 0;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 48px;
  margin-bottom: 170px;

  @media screen and (min-width: 768px) {
    grid-template-columns: 267px 1fr;
  }
`;
