import { N565EligibilityPayload } from "../../../../api/n565/types";
import { FormCategories } from "../../../../routes/type";
import { ECongratulationType } from "./modals/EligibilityModal";
import { checkQuestionsProps } from "./types";

export enum EQIds {
  q1 = 1,
  q2,
  q3,
  q4,
  q5,
  q6,
}

export const checkFormN565Questions: checkQuestionsProps<
  keyof N565EligibilityPayload
>[] = [
  {
    id: EQIds.q1,
    title: "Are you a US citizen?",
    storeField: "are_you_a_us_citizen",
    options: [
      {
        label: "Yes",
        nextStep: EQIds.q2,
      },
      {
        label: "No",
        nextStep: EQIds.q3,
      },
    ],
  },
  {
    id: EQIds.q2,
    title: "Which best describes your situation?",
    storeField: "which_best_describes_your_situation",
    options: [
      {
        label:
          "I want to replace my lost, stolen, mutilated or incorrect Naturalization or Citizenship Certificate",

        terminate: {
          type: ECongratulationType.eligible,
        },
      },
      {
        label: "I want to update my biographic information",
        terminate: {
          type: ECongratulationType.eligible,
        },
      },
      {
        label: "I am not a U.S Citizen and want to apply for citizenship",
        terminate: {
          formToRetake: FormCategories.n400,
          type: ECongratulationType.notEligible,
        },
      },
      {
        label:
          "I need proof of my U.S Citizenship and want to apply for a Certificate of Citizenship for the first time.",
        terminate: {
          formToRetake: FormCategories.n600,
          type: ECongratulationType.notEligible,
        },
      },
    ],
  },
  {
    id: EQIds.q3,
    title:
      "Have you ever previously applied for a document declaring your intention to become a U.S. citizen (a Declaration of Intention)?",
    subTitle:
      "(A “Declaration of Intention” is an official document that is submitted to the U.S. government for the purpose of declaring the applicant’s intention to become a U.S. Citizen in the future.)",
    storeField: "have_you_ever_applied_for_a_declaration_of_intention",
    options: [
      {
        label: "Yes",
        nextStep: EQIds.q4,
      },
      {
        label: "No",
        nextStep: EQIds.q5,
      },
    ],
  },
  {
    id: EQIds.q4,
    title: "Do you still have the Declaration of Intention?",
    storeField: "do_you_still_have_the_declaration_of_intention",
    options: [
      {
        label: "Yes",
        nextStep: EQIds.q6,
      },
      {
        label: "No, it’s lost.",
        terminate: {
          type: ECongratulationType.eligible,
        },
      },
      {
        label: "No, it’s stolen.",
        terminate: {
          type: ECongratulationType.eligible,
        },
      },
      {
        label: "No, it’s destroyed.",
        terminate: {
          type: ECongratulationType.eligible,
        },
      },
    ],
  },
  {
    id: EQIds.q5,
    title: "Would you like to Apply for U.S. Citizenship?",
    storeField: "would_you_like_to_apply_for_us_citizenship",
    options: [
      {
        label: "Yes",
        terminate: {
          formToRetake: FormCategories.n400,
          type: ECongratulationType.notEligible,
        },
      },
      {
        label: "No",
        terminate: {
          type: ECongratulationType.reStart,
        },
      },
    ],
  },
  {
    id: EQIds.q6,
    title: "Is any information in Declaration of Intention incorrect?",
    storeField: "is_any_information_on_your_declaration_of_intention_incorrect",
    options: [
      {
        label: "Yes",
        terminate: {
          type: ECongratulationType.eligible,
        },
      },
      {
        label: "No",
        terminate: {
          type: ECongratulationType.reStart,
        },
      },
    ],
  },
];
