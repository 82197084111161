import { Form, Formik } from "formik";
import styled from "styled-components";
import { initInput } from "../../components/data";
import RadioComp from "../../components/RadioComp";
import { FormWrapper } from "../../components/styles";
import FormBtn from "../../components/FormBtn";
import { N600EligibilityInfoPayload } from "../../../../api/n600/types";
import FormSectionWrapper from "../../components/FormSectionWrapper";
import { N600EligibilityBasis } from "./data";
import {
  useGetN600InfoAboutEligibility,
  useSaveN600InfoAboutEligibility,
} from "../../../../hooks/n600/useN600";
import useToNextSection from "../../../../hooks/useToNextSection";
import { EligibilityInfoValidation } from "./validation";

const EligibilityInfo = () => {
  const { data, isFetching, isError } = useGetN600InfoAboutEligibility();
  const { mutate, isLoading: isSaving } = useSaveN600InfoAboutEligibility();
  const { toNextSection } = useToNextSection();

  const onSubmit = (values: N600EligibilityInfoPayload) => {
    const { ...rest } = values;

    const payload = { ...rest };
    // console.log(payload);

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValues: N600EligibilityInfoPayload = {
    eligibilityCriteria: initInput(data?.eligibilityCriteria),
    eligibilityDescription: initInput(data?.eligibilityDescription),
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={EligibilityInfoValidation}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <RadioComp
                  title="This application is being filed based on the fact that: "
                  name="eligibilityCriteria"
                  options={N600EligibilityBasis}
                  column
                />
              </FormWrapper>

              {/* {formik.values.eligibility_basis ===
                N600EligibilityBasisValue.other && (
                <FormWrapper>
                  <InputField
                    name="eligibilityDescription"
                    label="Other (Explain)"
                    placeholder=""
                    inputType="text-area"
                  />
                  <div></div>
                </FormWrapper>
              )} */}

              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default EligibilityInfo;

const Wrapper = styled.div``;
