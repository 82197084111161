import { FC } from "react";
import styled from "styled-components";
import { PiDotsThreeOutlineFill } from "react-icons/pi";
import useClickAway from "../../../../hooks/useClickAway";
import { ItemActionOptionsProps } from "../type";
import Colors from "../../../../styles/Colors";

interface Props {
  options: ItemActionOptionsProps[];
}

const ItemAction: FC<Props> = ({ options }) => {
  const { ref, isOpen, setIsOpen } = useClickAway();

  const toggle = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setIsOpen((prev) => !prev);
  };

  return (
    <Actions ref={ref}>
      <MainLabel onClick={toggle}>
        <PiDotsThreeOutlineFill />
      </MainLabel>

      {isOpen && (
        <Options className="options-open">
          {options.map((ev, i) => (
            <OptionsItem
              color={ev.color}
              key={i}
              onClick={() => {
                if (ev.isDisabled) return;
                ev.onClick && ev.onClick();
                setIsOpen(false);
              }}
              isDisabled={ev.isDisabled}
            >
              {ev.icon}
              {ev.name}
            </OptionsItem>
          ))}
        </Options>
      )}
    </Actions>
  );
};

export default ItemAction;

const Actions = styled.div`
  position: relative;
`;

const MainLabel = styled.div`
  width: 16px;
  height: 16px;
  cursor: pointer;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const Options = styled.div`
  min-width: 90px;
  width: max-content;
  position: absolute;
  top: 16px;
  right: 0;
  left: 0;
  z-index: 90;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 4px 8px 0px #3333330a;
  border: 1px solid #e0dede;
  border-radius: 4px;
`;

interface OptionsItemProps {
  color?: string;
  isDisabled?: boolean;
}

const OptionsItem = styled.div<OptionsItemProps>`
  width: 100%;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  letter-spacing: 0.2px;
  color: ${({ color, isDisabled }) =>
    isDisabled ? Colors.Grey8B : color ? color : Colors.Black31};
  display: flex;
  align-items: center;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  padding: 8px 12px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 16px;

  & > svg {
    flex-shrink: 0;
  }

  &:hover {
    color: ${({ color, isDisabled }) =>
      isDisabled ? Colors.Grey8B : color ? Colors.Black : Colors.Black31};
    background-color: #f9f9f9;
    transition: all ease-out 0.3s;
  }
`;
