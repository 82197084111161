import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { WebPaths } from "../../../../../../../routes/data";
// import { ProfileSections } from "../../../types";
import ItemAction from "../../../../../components/table/ItemAction";
import { StaffRes } from "../../../../../../../api/business/staff/types";
import {
  useDeleteStaff,
  useGetAllStaff,
} from "../../../../../../../hooks/business/useStaff";
// import {
//   useDeleteStaff,
//   useGetAllStaff,
// } from "../../../hooks/staff/useStaff.tsx";
// import { AdminPaths } from "../../../routes/data";
// import ItemAction from "../../components/table/ItemAction";

interface Props {
  data: StaffRes;
}

const StaffAction: FC<Props> = ({ data }) => {
  const navigate = useNavigate();
  const { mutate: deleteStaff } = useDeleteStaff();
  const { refetch } = useGetAllStaff();
  const handleDelete = async (staffId: string) => {
    deleteStaff(staffId, {
      onSuccess: () => {
        toast.success("Staff Deleted Successfully!");
        refetch().then((data) => data);
      },
    });
  };

  const actionOptions = () => [
    {
      name: "View",
      onClick: () =>
        navigate(
          `${WebPaths.Profile}?active=staff-management&staffId=${data.staffId}`
        ),
    },
    {
      name: "Delete",
      onClick: () => handleDelete(data.staffId),
    },
  ];

  return (
    <>
      <ItemAction options={actionOptions()} />
    </>
  );
};

export default StaffAction;
