import React, { FC } from "react";
import { useGetCountries } from "../../../../hooks/utils/useUtils";
import { RenderAltCompProps } from "./types";
import EligibilitySelectField from "./EligibilitySelectField";

interface Props extends RenderAltCompProps {}

const CountryCodeEligibility: FC<Props> = ({ formData, onChange, result }) => {
  const { data: countryData, isLoading: countryLoading } = useGetCountries();

  const formattedCountryData =
    countryData?.map((ev) => ({
      label: ev.name + " (" + ev.iso3 + ")",
      value: ev.iso3,
    })) || [];

  return (
    <EligibilitySelectField
      formattedData={formattedCountryData}
      isLoading={countryLoading}
      formData={formData}
      onChange={onChange}
      result={result}
    />
  );
};

export default CountryCodeEligibility;
