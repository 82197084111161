import React from "react";
import styled from "styled-components";

interface Props {
  text: string;
  type: string;
  icon?: React.ReactNode;
  inverted?: boolean;
  reversed?: boolean;
  onClick?: () => void;
}

const Button: React.FC<Props> = ({
  text,
  type,
  icon,
  inverted,
  reversed,
  onClick,
}) => {
  return (
    <ButtonWrapper inverted={inverted} reversed={reversed} onClick={onClick}>
      {text} {icon}
    </ButtonWrapper>
  );
};

export default Button;

interface ButtonWrapperProps {
  inverted?: boolean;
  reversed?: boolean;
}
const ButtonWrapper = styled.button<ButtonWrapperProps>`
  background-color: ${({ inverted }) => (inverted ? "#f9fcff" : "#0076e9")};
  color: ${({ inverted }) => (inverted ? "#0076e9" : "#f9fcff")};
  border: 1px solid #0076e9;
  border-radius: 8px;
  padding: 0.85rem 1.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  flex-direction: ${({ reversed }) => (reversed ? "row-reverse" : "row")};

  font-size: 1rem;
  font-weight: bold;
  line-height: 1.0625rem;
  letter-spacing: 0em;

  transition: 300ms all ease;

  &:hover {
    background-color: ${({ inverted }) => (inverted ? "#f9fcff" : "#0076e9")};
    color: ${({ inverted }) => (inverted ? "#0076e9" : "#f9fcff")};
  }
`;
