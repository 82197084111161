import React, { FC, useState } from "react";
import styled from "styled-components";
import Colors from "../../../../../../styles/Colors";
import { aiExtractData, postRiskAssessment } from "../../../../../../api/ai";
import { toast } from "react-toastify";
import Spinner from "../../../../../../utils/Spinner";
import { useSnapshot } from "valtio";
import { applicationDataStore } from "../../../../../../store/applicationData";
import { isBusinessDataStore } from "../../../../../../store/isBusinessData";

interface Props {
  blobData: Blob;
  onDs160Risk: (params: string) => void;
}

const RiskAssesment: FC<Props> = ({ blobData, onDs160Risk }) => {
  const { isBusinessData } = useSnapshot(isBusinessDataStore);
  const { applicationData } = useSnapshot(applicationDataStore);
  const [loading, setLoading] = useState(false);
  // const [open, setOpen] = useState<string>();

  // const onClose = () => {
  //   setOpen(undefined);
  // };
  const onIndividual = async () => {
    try {
      setLoading(true);
      const payload = new FormData();
      const file = new File([blobData], "ds-160.pdf");
      payload.append("file", file);
      const result = await aiExtractData(payload);
      const result2 = await postRiskAssessment({
        data: result.data.result,
      });
      onDs160Risk(result2.data.result.content);
      // setOpen(result2.data.result);
    } catch (error) {
      toast.error("An error occured.");
    }
    setLoading(false);
  };

  const onBusiness = async () => {
    try {
      setLoading(true);
      if (!applicationData) return;
      const result2 = await postRiskAssessment({
        data: applicationData?.AI_data,
      });
      onDs160Risk(result2.data.result.content);
      // setOpen(result2.data.result);
    } catch (error) {
      toast.error("An error occured.");
    }
    setLoading(false);
  };

  return (
    <>
      <Btn
        onClick={isBusinessData ? onBusiness : onIndividual}
        disabled={loading}
      >
        {loading ? <Spinner /> : "Check Risk Assessment"}
      </Btn>

      {/* {open && <RiskAssessmentModal onClose={onClose} response={open} />} */}
    </>
  );
};

export default RiskAssesment;

const Btn = styled.button`
  /* font-weight: bold; */
  font-size: 1.125rem;
  line-height: 1.625rem;
  text-align: center;
  background-color: ${Colors.Blue00};
  color: white;
  white-space: nowrap;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  &:hover {
    color: ${Colors.Blue00};
    background-color: white;
    transition: all 0.3s ease-in-out;
    border: 1px solid ${Colors.Blue00};
  }
`;
