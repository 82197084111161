import * as Yup from "yup";

import {
  N400EligibilityPayload,
  n400CivicTestExemptionData,
  n400EnglishTestExemptionData,
} from "../../../../api/n400/types";
import {
  AddressHistoryData,
  physicalAddressArrayPropsPayload,
} from "./AddressHistory";
// import {
//   EmploymentHistoryData,
//   employmentHistoryPropsPayload,
// } from "./EmploymentHistory";
import { ChildrenInfoData } from "./ChildrenInfomation";
import {
  N400FormTimeOffData,
  timeOutsideUSArrayPropsPayload,
} from "./TimeOutsideUS";
import {
  GenericYesNoOptionsValue,
  MaritalStatusValues,
  isZipCode,
  riskyCheckBoxName,
  // riskyCheckBoxName,
} from "../../components/data";
import { AdditionalInfoData } from "./AdditionalInformation";
import { MaritalHistoryData } from "./MaritalHistory";
import {
  AIParentUSValCondition,
  ApplicantInfoDateLPRCondition,
  ApplicantInfoDateOfBirthCondition,
  ApplicantInfoIsLprCondition,
  GenericNumDropDownValues,
  IsDependingOnCategory,
  IsDependingOnSpousePresentEmployer,
  MHDateMarriage,
  MHMarriageStatusValCondition,
  TOUSLeftUsCondition1,
  TOUSMonthRangeCondition,
  WhenCurrentSpouseUSCitizenValues,
  childResidenceValues,
} from "./data";
import { AccommodationData } from "./Accommodation";
import { ContactInfoData } from "./BiographicInformation";
import dayjs from "dayjs";
import { digitsOnly, optionalDigitsOnly } from "../../../../utils";
import { ValidateOptionsExtended } from "../../components/type";
// import { CheckFormData1 } from "./CheckPart1";
// import { CheckFormData2 } from "./CheckPart2";
// import { CheckFormData3 } from "./CheckPart3";
// import { CheckFormData4 } from "./CheckPart4";
// import { CheckFormData5 } from "./CheckPart5";
// import { CheckFormData6, orgainizationArrayPropsPayload } from "./CheckPart6";
import {
  N400ApplicantInfo1Data,
  dateOfBirthArrayPropsPayload,
} from "./ApplicantInfo/ApplicantInfo1";
import { N400ApplicantInfo2Data } from "./ApplicantInfo/ApplicantInfo2";
import {
  EmploymentHistoryData,
  EmploymentHistoryPropsPayload,
} from "./EmploymentHistoryInfo";
import { CheckFormData1 } from "./AdditionalInfo/CheckPart1";
import { CheckFormData2 } from "./AdditionalInfo/CheckPart2";
import { CheckFormData3 } from "./AdditionalInfo/CheckPart3";
import { CheckFormData4 } from "./AdditionalInfo/CheckPart4";
import { FeeReductionData } from "./FeeReduction";

type SchemaObject<T> = {
  [key in keyof T]: Yup.Schema<any>;
};

const monthRangeTestVal = (
  context: Yup.TestContext<Yup.AnyObject>,
  value?: any
) => {
  const data = context.parent as timeOutsideUSArrayPropsPayload;

  if (
    TOUSMonthRangeCondition({
      returnedDate: value,
      leftDate: data.dateYouLeft,
    }) &&
    !data.dateYouReturned_risky_checkbox
  ) {
    return false; //not valid
  } else {
    return true;
  }
};

const leftUsTestVal = (
  context: Yup.TestContext<Yup.AnyObject>,
  formEligibility: N400EligibilityPayload
) => {
  const data = context.parent as N400FormTimeOffData;

  if (
    TOUSLeftUsCondition1(data, formEligibility) &&
    !data.have_you_left_us_in_the_past_five_years_risky_checkbox
  ) {
    return false; //not valid
  } else {
    return true;
  }
};

export const TimeOutsideUSValidation = (
  formEligibility: N400EligibilityPayload
) =>
  Yup.object().shape<SchemaObject<N400FormTimeOffData>>({
    have_you_left_us_in_the_past_five_years: Yup.string()
      .required("Required")
      .test("left_us", "Risk detected", (value, context) => {
        return leftUsTestVal(context, formEligibility);
      }),
    have_you_left_us_in_the_past_five_years_risky_checkbox: Yup.bool(),
    timeOutsideUSArray: Yup.array().when(
      "have_you_left_us_in_the_past_five_years",
      ([have_you_left_us_in_the_past_five_years], schema) => {
        if (
          have_you_left_us_in_the_past_five_years ===
          GenericYesNoOptionsValue.yes
        )
          return Yup.array().of(
            Yup.object().shape({
              countryVisited: Yup.object().nullable().required("Required"),
              dateYouLeft: Yup.string()
                .nullable()
                .required("Required")
                .test(
                  "prev-curr-date",
                  "This date cannot be earlier than the day you returned from your prior trip.",
                  (value, context) => {
                    const { options } = context as Yup.TestContext &
                      ValidateOptionsExtended;
                    const index = options.index;
                    const array = (options.context as N400FormTimeOffData)
                      .timeOutsideUSArray;
                    const prevDate = array[index - 1]?.dateYouReturned;
                    const currDate = array[index]?.dateYouLeft;

                    return currDate && prevDate
                      ? !currDate.isBefore(prevDate)
                      : true;
                  }
                ),
              doesNotApply: Yup.bool(),
              dateYouReturned: Yup.string()
                .nullable()
                .test("doesNotApply", "Required", (value, context) => {
                  const data = context.parent as timeOutsideUSArrayPropsPayload;
                  return !data.doesNotApply && !data.dateYouReturned
                    ? false
                    : true;
                })
                .test("6MonthRange", "Risk detected", (value, context) => {
                  return monthRangeTestVal(context, value);
                }),
              dateYouReturned_risky_checkbox: Yup.bool(),
            })
          );

        return schema;
      }
    ),
  });

const dependsOnOtherNames = () => {
  return Yup.array().when("otherNames", ([otherNames], schema) => {
    if (otherNames === GenericYesNoOptionsValue.yes)
      return Yup.array().of(
        Yup.object().shape({
          otherNamesFirstName: Yup.string().required("Required").trim(),
          otherNamesMiddleName: Yup.string().trim(),
          otherNamesLastName: Yup.string().required("Required").trim(),
        })
      );

    return schema;
  });
};

const dependsOnAnyNewName = () => {
  return Yup.string().when(`anyNewName`, ([anyNewName], schema) => {
    if (anyNewName === GenericYesNoOptionsValue.yes)
      return Yup.string().required("Required").trim();
    return schema;
  });
};

const dependsOnLPR = (formEligibility: N400EligibilityPayload) => {
  return Yup.string()
    .nullable()
    .when(`LPR`, ([LPR], schema) => {
      if (LPR === GenericYesNoOptionsValue.yes)
        return Yup.string()
          .nullable()
          .required("Required")
          .test("dateLPRTestVal", "Risk detected", (value, context) => {
            return dateLPRTestVal(context, formEligibility);
          });
      return schema;
    });
};

const isLprTestVal = (
  context: Yup.TestContext<Yup.AnyObject>,
  formEligibility: N400EligibilityPayload
) => {
  const data = context.parent as N400ApplicantInfo1Data;

  if (
    ApplicantInfoIsLprCondition(data, formEligibility) &&
    !data.isLPR_risky_checkbox
  ) {
    return false; //not valid
  } else {
    return true;
  }
};

const dateLPRTestVal = (
  context: Yup.TestContext<Yup.AnyObject>,
  formEligibility: N400EligibilityPayload
) => {
  const data = context.parent as N400ApplicantInfo1Data;

  if (
    ApplicantInfoDateLPRCondition(data, formEligibility) &&
    !data.dateLPR_risky_checkbox
  ) {
    return false; //not valid
  } else {
    return true;
  }
};

const dobTestVal = (
  context: Yup.TestContext<Yup.AnyObject>,
  formEligibility: N400EligibilityPayload
) => {
  const data = (context.parent as dateOfBirthArrayPropsPayload).dateOfBirth;

  if (ApplicantInfoDateOfBirthCondition(data, formEligibility)) {
    return false; //not valid
  } else {
    return true;
  }
};

export const ApplicantInfoValidation = (
  formEligibility: N400EligibilityPayload
) =>
  Yup.object().shape<SchemaObject<N400ApplicantInfo1Data>>({
    firstName: Yup.string().required("Required").trim(),
    middleName: Yup.string().trim(),
    lastName: Yup.string().required("Required").trim(),
    isLPR: Yup.string()
      .required("Required")
      .trim()
      .test("isLprTestVal", "Eligibility Error", (value, context) => {
        return isLprTestVal(context, formEligibility);
      }),
    isLPR_risky_checkbox: Yup.bool(),
    dateLPR: dependsOnLPR(formEligibility),
    dateLPR_risky_checkbox: Yup.bool(),
    otherNames: Yup.string().required("Required").trim(),
    otherNamesArray: dependsOnOtherNames(),
    anyNewName: Yup.string().required("Required").trim(),
    anyNewNameFirstName: dependsOnAnyNewName(),
    anyNewNameMiddleName: Yup.string().trim(),
    anyNewNameLastName: dependsOnAnyNewName(),
    uscisAcctNum: Yup.string()
      .label("USCIS Online Account Number")
      .length(12)
      .test("Digits only", "Must be a number", optionalDigitsOnly),
    gender: Yup.string().required("Required").trim(),
    dateOfBirthArray: Yup.array().of(
      Yup.object().shape({
        dateOfBirth: Yup.mixed()
          .nullable()
          .required("Required")
          .test("dobTestVal", "Eligibility Error", (value, context) => {
            return dobTestVal(context, formEligibility);
          }),
      })
    ),
    countryOfBirth: Yup.object().nullable().required("Required"),
    countryOfCitizenshipArray: Yup.array().of(
      Yup.object().shape({
        countryOfCitizenship: Yup.object().nullable().required("Required"),
      })
    ),
  });

const parentUSCitizenTestVal = (context: Yup.TestContext<Yup.AnyObject>) => {
  const data = context.parent as N400ApplicantInfo2Data;

  if (AIParentUSValCondition(data)) {
    return false; //not valid
  } else {
    return true;
  }
};

const dependsOnSSA = (isSSA?: boolean, isBool?: boolean) => {
  if (isSSA) {
    return Yup.string().when(
      `do_you_want_social_security_card`,
      ([do_you_want_social_security_card], schema) => {
        if (do_you_want_social_security_card === GenericYesNoOptionsValue.yes)
          return Yup.string()
            .label("Social Security number")
            .length(9)
            .test("Digits only", "Must be a number", optionalDigitsOnly);
        return schema;
      }
    );
  } else if (isBool) {
    return Yup.bool().when(
      `do_you_want_social_security_card`,
      ([do_you_want_social_security_card], schema) => {
        if (do_you_want_social_security_card === GenericYesNoOptionsValue.yes)
          return Yup.bool().oneOf([true]);
        return schema;
      }
    );
  } else {
    return Yup.string()
      .nullable()
      .when(
        `do_you_want_social_security_card`,
        ([do_you_want_social_security_card], schema) => {
          if (do_you_want_social_security_card === GenericYesNoOptionsValue.yes)
            return Yup.string().required("Required").trim();
          return schema;
        }
      );
  }
};

export const ApplicantInfo2Validation = Yup.object().shape<
  SchemaObject<N400ApplicantInfo2Data>
>({
  aNumber: Yup.string()
    .required("Required")
    .min(8, "Minimum of 8 characters allowed")
    .max(9, "Maximum of 9 characters allowed")
    .trim()
    .test("Digits only", "Must be a number", digitsOnly),
  was_one_of_parent_us_citizen: Yup.string()
    .required("Required")
    .trim()
    .test("parentUSCitizenTestVal", "Eligibility Error", (value, context) => {
      return parentUSCitizenTestVal(context);
    }),
  disability: Yup.string().required("Required").trim(),
  do_you_want_social_security_card: Yup.string().required("Required").trim(),
  usSocialSecurityNumber: dependsOnSSA(true),
  consentForDisclosure: dependsOnSSA(false, true),

  daytimeTelephoneNumber: Yup.string()
    .min(10, "Minimum 10 digits allowed")
    .max(10, "Maximum 10 digits allowed")
    .required("Required")
    .test("Digits only", "Must be a number", digitsOnly)
    .trim(),
  mobileTelephoneNumber: Yup.string().trim(),
  email: Yup.string().trim(),
});

const dependDisabilityInfo = () => {
  return Yup.bool().test(
    "disabilityInfoArray",
    "One disability info must be checked",
    function () {
      return this.parent.deaf || this.parent.blind || this.parent.others;
    }
  );
};

export const AccommodationValidation = Yup.object().shape<
  SchemaObject<AccommodationData>
>({
  reasonForRequest: Yup.string().required("Required").trim(),
  disabilityInfoArray: Yup.array().when(
    "reasonForRequest",
    ([reasonForRequest], schema) => {
      if (reasonForRequest === GenericYesNoOptionsValue.yes)
        return Yup.array().of(
          Yup.object().shape({
            deaf: dependDisabilityInfo(),
            blind: dependDisabilityInfo(),
            others: dependDisabilityInfo(),
          })
        );
      return schema;
    }
  ),
});

export const CiviTestExemption = Yup.object().shape<
  SchemaObject<n400CivicTestExemptionData>
>({
  isDisabilityOrImpairment: Yup.string().required("Required"),
});

export const EnglishTestExemtion = Yup.object().shape<
  SchemaObject<n400EnglishTestExemptionData>
>({
  fiftyYrsPlusAndTwentyYrsPlusPR: Yup.string().required("Required"),
  fiftyFiveYrsPlusAndFifteenYrsPlusPR: Yup.string().required("Required"),
  sixtyFiveYrsPlusAndTwentyYrsPlusPR: Yup.string().required("Required"),
});

export const AdditionalInfoValidation = Yup.object().shape<
  SchemaObject<AdditionalInfoData>
>({
  aNumber: Yup.string().when("dontKNowANumber", ([dontKNowANumber], schema) => {
    if (!dontKNowANumber)
      return Yup.string()
        .required("Required")
        .min(8, "Minimum of 8 characters allowed")
        .max(9, "Maximum of 9 characters allowed")
        .trim()
        .test("Digits only", "Must be a number", digitsOnly);
    return schema;
  }),
  dontKNowANumber: Yup.bool(),
  usSocialSecurityNumber: Yup.string()
    .length(9, "Must be 9 digits")
    .matches(/^\d{9}$/, "Must be a number")
    .when("dontKNowANumber", ([dontKNowANumber], schema) => {
      if (dontKNowANumber)
        return Yup.string()
          .length(9, "Must be 9 digits")
          .matches(/^\d{9}$/, "Must be a number")
          .required("Required");
      return schema;
    }),
  uscisOnlineAccountNumber: Yup.string()
    .label("USCIS Online Account Number")
    .length(12)
    .test("Digits only", "Must be a number", optionalDigitsOnly),
});

const dependRaces = () => {
  return Yup.bool().test(
    "oneOfRequired",
    "One race must be checked",
    function (item) {
      return (
        this.parent.white ||
        this.parent.black ||
        this.parent.indian ||
        this.parent.asian ||
        this.parent.islander
      );
    }
  );
};

export const BioInfoValidation = Yup.object().shape<
  SchemaObject<ContactInfoData>
>({
  ethnicity: Yup.string().required("Required").trim(),
  racesArray: Yup.array().of(
    Yup.object().shape({
      white: dependRaces(),
      black: dependRaces(),
      indian: dependRaces(),
      asian: dependRaces(),
      islander: dependRaces(),
    })
  ),
  heightFeet: Yup.string().required("Required").trim(),
  heightInches: Yup.string().required("Required").trim(),
  weight: Yup.string().required("Required").trim(),
  eyeColor: Yup.string().required("Required").trim(),
  hairColor: Yup.string().required("Required").trim(),
});

const dependsOnMailAddressSameAsPhysicalAddress = (isObj?: boolean) => {
  return isObj
    ? Yup.object()
        .nullable()
        .when(
          "mailAddressSameAsPhysicalAddress",
          ([mailAddressSameAsPhysicalAddress], schema) => {
            if (
              mailAddressSameAsPhysicalAddress === GenericYesNoOptionsValue.no
            )
              return Yup.object().nullable().required("Required");
            return schema;
          }
        )
    : Yup.string().when(
        "mailAddressSameAsPhysicalAddress",
        ([mailAddressSameAsPhysicalAddress], schema) => {
          if (mailAddressSameAsPhysicalAddress === GenericYesNoOptionsValue.no)
            return Yup.string().required("Required").trim();
          return schema;
        }
      );
};

// const sameMonthsTestVal = (context: Yup.TestContext<Yup.AnyObject>) => {
//   const data = context.parent as AddressHistoryData;

//   if (
//     AHSameStateCondition(data) &&
//     !data.livedSameStateForThreeMonths_risky_checkbox
//   ) {
//     return false; //not valid
//   } else {
//     return true;
//   }
// };

export const AddressHistoryValidation = () =>
  Yup.object().shape<SchemaObject<AddressHistoryData>>({
    physicalAddressArray: Yup.array().of(
      Yup.object().shape({
        inCareOfName: Yup.string()
          .max(34, "Maximum of 34 characters allowed")
          .trim(),
        streetNumberAndName: Yup.string()
          .max(34, "Maximum of 34 characters allowed")
          .required("Required")
          .trim(),
        cityOrTown: Yup.string()
          .max(20, "Maximum of 20 characters allowed")
          .required("Required")
          .trim(),
        addressCode: Yup.string()
          .required("Required")
          .when("country", ([country], schema) => {
            if (isZipCode(country?.value))
              return Yup.string()
                .length(5, "Maximum of 5 characters allowed")
                .required("Required")
                .trim()
                .test("Digits only", "Must be a number", digitsOnly);
            return schema;
          }),
        country: Yup.object().nullable().required("Required"),
        province: Yup.string().trim(),
        stateOrTerritory: Yup.object().nullable().required("Required"),
        apartmentSuiteOrFloor: Yup.object()
          .nullable()
          .test("apartmentSuiteOrFloor", "Required", (value, context) => {
            const data = context.parent as physicalAddressArrayPropsPayload;
            const { options } = context as Yup.TestContext &
              ValidateOptionsExtended;
            const index = options.index;

            return index !== 0 ? true : !!data.apartmentSuiteOrFloor;
          }),
        addressNumber: Yup.string().when(
          "apartmentSuiteOrFloor",
          ([apartmentSuiteOrFloor], schema) => {
            if (apartmentSuiteOrFloor?.value)
              return Yup.string().required("Required").trim();
            return schema;
          }
        ),
        dateYouBeganLivingAtAddress: Yup.string()
          .nullable()
          .required("Required")
          .trim(),
        yetToReturn: Yup.bool(),
        dateYouLeftAddress: Yup.string()
          .nullable()
          .test("yetToReturn", "Required", (value, context) => {
            const data = context.parent as physicalAddressArrayPropsPayload;
            const { options } = context as Yup.TestContext &
              ValidateOptionsExtended;
            const index = options.index;

            return index === 0
              ? true
              : !data.yetToReturn && !data.dateYouLeftAddress
              ? false
              : true;
          })
          .test(
            "began-left-date",
            "This date cannot be before than Date you began living at the address above",
            (value, context) => {
              const data = context.parent as physicalAddressArrayPropsPayload;

              const fromDate = dayjs(data.dateYouBeganLivingAtAddress);
              const toDate = dayjs(data.dateYouLeftAddress);

              return data.dateYouBeganLivingAtAddress && data.dateYouLeftAddress
                ? !toDate.isBefore(fromDate)
                : true;
            }
          ),
      })
    ),
    mailAddressSameAsPhysicalAddress: Yup.string().required("Required").trim(),
    inCareOfNameMail: Yup.string().when(
      "mailAddressSameAsPhysicalAddress",
      ([mailAddressSameAsPhysicalAddress], schema) => {
        if (mailAddressSameAsPhysicalAddress === GenericYesNoOptionsValue.no)
          return Yup.string()
            .max(34, "Maximum of 34 characters allowed")
            .trim();
        return schema;
      }
    ),
    apartmentSuiteOrFloorMail: Yup.object().nullable(), //
    addressNumberMail: Yup.string().when(
      "apartmentSuiteOrFloorMail",
      ([apartmentSuiteOrFloorMail], schema) => {
        if (apartmentSuiteOrFloorMail?.value)
          return Yup.string().required("Required").trim();
        return schema;
      }
    ),
    countryMail: dependsOnMailAddressSameAsPhysicalAddress(true),
    provinceMail: Yup.string().trim(), //
    addressCodeMail: Yup.string().when(
      "mailAddressSameAsPhysicalAddress",
      ([mailAddressSameAsPhysicalAddress], schema) => {
        if (mailAddressSameAsPhysicalAddress === GenericYesNoOptionsValue.no)
          return Yup.string()
            .required("Required")
            .when("countryMail", ([countryMail], schema) => {
              if (isZipCode(countryMail?.value))
                return Yup.string()
                  .length(5, "Maximum of 5 characters allowed")
                  .required("Required")
                  .trim()
                  .test("Digits only", "Must be a number", digitsOnly);
              return schema;
            });
        return schema;
      }
    ),
    streetNumberAndNameMail: Yup.string().when(
      "mailAddressSameAsPhysicalAddress",
      ([mailAddressSameAsPhysicalAddress], schema) => {
        if (mailAddressSameAsPhysicalAddress === GenericYesNoOptionsValue.no)
          return Yup.string()
            .max(34, "Maximum of 34 characters allowed")
            .required("Required")
            .trim();
        return schema;
      }
    ),
    cityOrTownMail: Yup.string().when(
      "mailAddressSameAsPhysicalAddress",
      ([mailAddressSameAsPhysicalAddress], schema) => {
        if (mailAddressSameAsPhysicalAddress === GenericYesNoOptionsValue.no)
          return Yup.string()
            .max(20, "Maximum of 20 characters allowed")
            .required("Required")
            .trim();
        return schema;
      }
    ),
    stateOrTerritoryMail: dependsOnMailAddressSameAsPhysicalAddress(true),
    // livedSameStateForThreeMonths: Yup.string()
    //   .required("Required")
    //   .trim()
    //   .test("sameMonthsTestVal", "Risk detected", (value, context) => {
    //     return sameMonthsTestVal(context);
    //   }),
    // livedSameStateForThreeMonths_risky_checkbox: Yup.bool(),

    // doesNotApply: Yup.bool(),
    // dateYouReturned: Yup.string()
    //   .nullable()
    //   .test("doesNotApply", "Required", (value, context) => {
    //     const data = context.parent as timeOutsideUSArrayPropsPayload;
    //     return !data.doesNotApply && !data.dateYouReturned
    //       ? false
    //       : true;
    //   })
  });

export const EmploymentHistoryValidation = Yup.object().shape<
  SchemaObject<EmploymentHistoryData>
>({
  employmentHistoryArray: Yup.array().of(
    Yup.object().shape({
      employerOrSchoolName: Yup.string().required("Required").trim(),
      // apartmentSuiteOrFloor: Yup.object().nullable(),
      // addressNumber: Yup.string().when(
      //   "apartmentSuiteOrFloor",
      //   ([apartmentSuiteOrFloor], schema) => {
      //     if (apartmentSuiteOrFloor?.value)
      //       return Yup.string().required("Required").trim();
      //     return schema;
      //   }
      // ),
      country: Yup.object().nullable().required("Required"),
      addressCode: Yup.string()
        .required("Required")
        .when("country", ([country], schema) => {
          if (isZipCode(country?.value))
            return Yup.string()
              .length(5, "Maximum of 5 characters allowed")
              .required("Required")
              .trim()
              .test("Digits only", "Must be a number", digitsOnly);
          return schema;
        }),
      cityOrTown: Yup.string()
        .max(20, "Maximum of 20 characters allowed")
        .required("Required")
        .trim(),
      stateOrProvince: Yup.object().nullable().required("Required"),
      dateFrom: Yup.string().nullable().required("Required").trim(),
      doesNotApplyDateTo: Yup.bool(),
      dateTo: Yup.string()
        .nullable()
        .test("doesNotApplyDateTo", "Required", (value, context) => {
          const data = context.parent as EmploymentHistoryPropsPayload;
          return !data.doesNotApplyDateTo && !data.dateTo ? false : true;
        })
        .test(
          "from-to-date",
          "This date cannot be before than the date from",
          (value, context) => {
            const data = context.parent as EmploymentHistoryPropsPayload;

            const fromDate = dayjs(data.dateFrom);
            const toDate = dayjs(data.dateTo);

            return data.dateFrom && data.dateTo
              ? !toDate.isBefore(fromDate)
              : true;
          }
        ),
      occupationOrFieldOfStudy: Yup.object().nullable().required("Required"),
    })
  ),
});

// const dependsOnOnce = (notRequired?: boolean) => {
//   if (notRequired) {
//     return Yup.string().when(
//       "numOfTimesMarried",
//       ([numOfTimesMarried], schema) => {
//         if (numOfTimesMarried?.value >= GenericNumDropDownValues.one)
//           return Yup.string().trim();
//         return schema;
//       }
//     );
//   } else {
//     return Yup.string().when(
//       "numOfTimesMarried",
//       ([numOfTimesMarried], schema) => {
//         if (numOfTimesMarried?.value >= GenericNumDropDownValues.one)
//           return Yup.string().required("Required").trim();
//         return schema;
//       }
//     );
//   }
// };

// const dependsOnOnceDate = (notRequired?: boolean) => {
//   if (notRequired) {
//     return Yup.string()
//       .nullable()
//       .when("numOfTimesMarried", ([numOfTimesMarried], schema) => {
//         if (numOfTimesMarried?.value >= GenericNumDropDownValues.one)
//           return Yup.string().nullable().trim();
//         return schema;
//       });
//   } else {
//     return Yup.string()
//       .nullable()
//       .when("numOfTimesMarried", ([numOfTimesMarried], schema) => {
//         if (numOfTimesMarried?.value >= GenericNumDropDownValues.one)
//           return Yup.string().nullable().required("Required").trim();
//         return schema;
//       });
//   }
// };

// const dependsOnOnceAndSpouseOtherNames = (notRequired?: boolean) => {
//   if (notRequired) {
//     return Yup.string().when(
//       ["numOfTimesMarried", "spouseOtherNames"],
//       ([numOfTimesMarried, spouseOtherNames], schema) => {
//         if (
//           numOfTimesMarried?.value >= GenericNumDropDownValues.one &&
//           spouseOtherNames === GenericYesNoOptionsValue.yes
//         )
//           return Yup.string().trim();
//         return schema;
//       }
//     );
//   } else {
//     return Yup.string().when(
//       ["numOfTimesMarried", "spouseOtherNames"],
//       ([numOfTimesMarried, spouseOtherNames], schema) => {
//         if (
//           numOfTimesMarried?.value >= GenericNumDropDownValues.one &&
//           spouseOtherNames === GenericYesNoOptionsValue.yes
//         )
//           return Yup.string().required("Required").trim();
//         return schema;
//       }
//     );
//   }
// };

// const timesMarriedTestVal = (
//   context: Yup.TestContext<Yup.AnyObject>,
//   formEligibility: N400EligibilityPayload
// ) => {
//   const data = context.parent as MaritalHistory;

//   if (MHTimesMarriedCondition(data, formEligibility)) {
//     return false; //not valid
//   } else {
//     return true;
//   }
// };

const marriageStatusTestVal = (
  context: Yup.TestContext<Yup.AnyObject>,
  formEligibility: N400EligibilityPayload
) => {
  const data = context.parent as MaritalHistoryData;

  if (MHMarriageStatusValCondition(data, formEligibility)) {
    return false; //not valid
  } else {
    return true;
  }
};

// const spouseUSTestVal = (
//   context: Yup.TestContext<Yup.AnyObject>,
//   formEligibility: N400EligibilityPayload
// ) => {
//   const data = context.parent as MaritalHistoryData;
//   if (
//     MHSpouseUSCondition(data, formEligibility) &&
//     !data.isSpouseUSCitizen_risky_checkbox
//   ) {
//     return false; //not valid
//   } else {
//     return true;
//   }
// };

const dateSpouseUSCitizenTestVal = (
  context: Yup.TestContext<Yup.AnyObject>,
  formEligibility: N400EligibilityPayload
) => {
  const data = context.parent as MaritalHistoryData;

  if (
    MHDateMarriage(data, formEligibility) &&
    !data.dateSpouseBecameUsCitizen_risky_checkbox
  ) {
    return false; //not valid
  } else {
    return true;
  }
};

const dateMarriageTestVal = (
  context: Yup.TestContext<Yup.AnyObject>,
  formEligibility: N400EligibilityPayload
) => {
  const data = context.parent as MaritalHistoryData;

  if (
    MHDateMarriage(data, formEligibility) &&
    !data.dateOfMarriage_risky_checkbox
  ) {
    return false; //not valid
  } else {
    return true;
  }
};

const dependsOnCategory = (
  formEligibility: N400EligibilityPayload,
  isDate?: boolean,
  isNo?: boolean
) => {
  if (isDate) {
    return Yup.string()
      .nullable()
      .when("dependsOnCategory", (_, schema) => {
        if (IsDependingOnCategory(formEligibility))
          return Yup.string().nullable().required("Required");
        return schema;
      });
  } else {
    return Yup.string()
      .trim()
      .when("dependsOnCategory", (_, schema) => {
        if (IsDependingOnCategory(formEligibility)) {
          if (isNo) {
            return Yup.string()
              .trim()
              .required("Required")
              .test("Digits only", "Must be a number", optionalDigitsOnly);
          } else {
            return Yup.string().trim().required("Required");
          }
        }
        return schema;
      });
  }
};

const dependsOnSpousePresentEmployer = (
  formEligibility: N400EligibilityPayload
) => {
  return Yup.string()
    .trim()
    .when("dependsOnSpousePresentEmployer", (_, schema) => {
      if (IsDependingOnSpousePresentEmployer(formEligibility))
        return Yup.string().trim().required("Required");
      return schema;
    });
};

const dependsOnSpouseAddress = (isObj?: boolean) => {
  return isObj
    ? Yup.object()
        .nullable()
        .when("isSpouseAtYourAddress", ([isSpouseAtYourAddress], schema) => {
          if (isSpouseAtYourAddress === GenericYesNoOptionsValue.no)
            return Yup.object().nullable().required("Required");
          return schema;
        })
    : Yup.string().when(
        "isSpouseAtYourAddress",
        ([isSpouseAtYourAddress], schema) => {
          if (isSpouseAtYourAddress === GenericYesNoOptionsValue.no)
            return Yup.string().required("Required").trim();
          return schema;
        }
      );
};

export const MaritalHistoryValidation = (
  formEligibility: N400EligibilityPayload
) =>
  Yup.object().shape<SchemaObject<MaritalHistoryData>>({
    currentMaritalStatus: Yup.object()
      .nullable()
      .required("Required")
      .test("marriageStatusTestVal", "Eligibility Error", (value, context) => {
        return marriageStatusTestVal(context, formEligibility);
      }),
    isSpouseMemberOfArmedForces: Yup.string().when(
      "currentMaritalStatus",
      ([currentMaritalStatus], schema) => {
        if (
          currentMaritalStatus?.value !==
          MaritalStatusValues["single & never married"]
        )
          return Yup.string().required("Required");

        return schema;
      }
    ),
    numOfTimesMarried: Yup.string().when(
      "currentMaritalStatus",
      ([currentMaritalStatus], schema) => {
        if (
          currentMaritalStatus?.value !==
          MaritalStatusValues["single & never married"]
        )
          return Yup.string()
            .required("Required")
            .test("Digits only", "Must be a number", optionalDigitsOnly)
            .test(
              "Can't be Zero only",
              "Number can't be zero",
              (value?: string | null) => {
                return value && Number(value) === 0 ? false : true;
              }
            );

        return schema;
      }
    ),
    spouseFirstName: dependsOnCategory(formEligibility),
    spouseMiddleName: Yup.string().trim(),
    spouseLastName: dependsOnCategory(formEligibility),
    spouseDateOfBirth: dependsOnCategory(formEligibility, true),
    dateOfMarriage: Yup.string()
      .nullable()
      .when("dependsOnCategory", (_, schema) => {
        if (IsDependingOnCategory(formEligibility)) {
          return Yup.string()
            .nullable()
            .required("Required")
            .test("dateMarriageTestVal", "Risk detected", (value, context) => {
              return dateMarriageTestVal(context, formEligibility);
            });
        }
        return schema;
      }),
    // dateOfMarriage: dependsOnCategory(formEligibility, true).test(
    //   "dateMarriageTestVal",
    //   "Risk detected",
    //   (value, context) => {
    //     return dateMarriageTestVal(context, formEligibility);
    //   }
    // ),
    dateOfMarriage_risky_checkbox: Yup.bool(),
    dateSpouseBecameUsCitizen_risky_checkbox: Yup.bool(),
    isSpouseAtYourAddress: dependsOnCategory(formEligibility),
    inCareOfName: Yup.string().when(
      "isSpouseAtYourAddress",
      ([isSpouseAtYourAddress], schema) => {
        if (isSpouseAtYourAddress === GenericYesNoOptionsValue.no)
          return Yup.string()
            .max(34, "Maximum of 34 characters allowed")
            .trim();
        return schema;
      }
    ),
    apartmentSuiteOrFloor: Yup.object().nullable(), //
    addressNumber: Yup.string().when(
      "apartmentSuiteOrFloor",
      ([apartmentSuiteOrFloor], schema) => {
        if (apartmentSuiteOrFloor?.value)
          return Yup.string().required("Required").trim();
        return schema;
      }
    ),
    country: dependsOnSpouseAddress(true),
    province: Yup.string().trim(), //
    addressCode: Yup.string().when(
      "isSpouseAtYourAddress",
      ([isSpouseAtYourAddress], schema) => {
        if (isSpouseAtYourAddress === GenericYesNoOptionsValue.no)
          return Yup.string()
            .required("Required")
            .when("country", ([country], schema) => {
              if (isZipCode(country?.value))
                return Yup.string()
                  .length(5, "Maximum of 5 characters allowed")
                  .required("Required")
                  .trim()
                  .test("Digits only", "Must be a number", digitsOnly);
              return schema;
            });
        return schema;
      }
    ),
    streetNumberAndName: Yup.string().when(
      "isSpouseAtYourAddress",
      ([isSpouseAtYourAddress], schema) => {
        if (isSpouseAtYourAddress === GenericYesNoOptionsValue.no)
          return Yup.string()
            .max(34, "Maximum of 34 characters allowed")
            .required("Required")
            .trim();
        return schema;
      }
    ),
    cityOrTown: Yup.string().when(
      "isSpouseAtYourAddress",
      ([isSpouseAtYourAddress], schema) => {
        if (isSpouseAtYourAddress === GenericYesNoOptionsValue.no)
          return Yup.string()
            .max(20, "Maximum of 20 characters allowed")
            .required("Required")
            .trim();
        return schema;
      }
    ),
    stateOrTerritory: dependsOnSpouseAddress(true),
    whenSpouseBecameUsCitizen: dependsOnCategory(formEligibility),
    dateSpouseBecameUsCitizen: Yup.string()
      .nullable()
      .when(
        "whenSpouseBecameUsCitizen",
        ([whenSpouseBecameUsCitizen], schema) => {
          if (
            whenSpouseBecameUsCitizen === WhenCurrentSpouseUSCitizenValues.other
          )
            return Yup.string()
              .nullable()
              .required("Required")
              .test(
                "dateSpouseUSCitizenTestVal",
                "Risk detected",
                (value, context) => {
                  return dateSpouseUSCitizenTestVal(context, formEligibility);
                }
              );
          return schema;
        }
      ),
    spouseAnumber: Yup.string()
      .min(8, "Minimum of 8 characters allowed")
      .max(9, "Maximum of 9 characters allowed")
      .trim()
      .test("Digits only", "Must be a number", optionalDigitsOnly),
    howManyTimeSpouseMarried: dependsOnCategory(formEligibility, false, true),
    spousePresentEmployer: dependsOnSpousePresentEmployer(formEligibility),
  });

const dependsOnResidence = (isObj?: boolean) => {
  if (isObj) {
    return Yup.object()
      .nullable()
      .when("residence", ([residence], schema) => {
        if (
          residence?.value === childResidenceValues["does not reside with me"]
        )
          return Yup.object().nullable().required("Required");
        return schema;
      });
  } else {
    return Yup.string().when("residence", ([residence], schema) => {
      if (residence?.value === childResidenceValues["does not reside with me"])
        return Yup.string().required("Required").trim();
      return schema;
    });
  }
};

export const ChildrenInfoValidation = Yup.object().shape<
  SchemaObject<ChildrenInfoData>
>({
  numOfChildren: Yup.string()
    .required("Required")
    .trim()
    .test("Digits only", "Must be a number", digitsOnly),
  childrenInforArray: Yup.array().when(
    "numOfChildren",
    ([numOfChildren], schema) => {
      if (Number(numOfChildren) >= GenericNumDropDownValues.one) {
        return Yup.array().of(
          Yup.object().shape({
            firstName: Yup.string().required("Required").trim(),
            lastName: Yup.string().required("Required").trim(),
            dateOfBirth: Yup.string().nullable().required("Required").trim(),
            residence: Yup.object().nullable().required("Required"),
            childRelationshipToYou: Yup.object().required("Required"),
            areYouProvidingSupport: Yup.string().required("Required"),
            inCareOfName: Yup.string().when(
              "residence",
              ([residence], schema) => {
                if (
                  residence?.value ===
                  childResidenceValues["does not reside with me"]
                )
                  return Yup.string()
                    .max(34, "Maximum of 34 characters allowed")
                    .trim();
                return schema;
              }
            ),
            streetNumberAndName: Yup.string().when(
              "residence",
              ([residence], schema) => {
                if (
                  residence?.value ===
                  childResidenceValues["does not reside with me"]
                )
                  return Yup.string()
                    .max(34, "Maximum of 34 characters allowed")
                    .required("Required")
                    .trim();
                return schema;
              }
            ),
            cityOrTown: Yup.string().when(
              "residence",
              ([residence], schema) => {
                if (
                  residence?.value ===
                  childResidenceValues["does not reside with me"]
                )
                  return Yup.string()
                    .max(20, "Maximum of 20 characters allowed")
                    .required("Required")
                    .trim();
                return schema;
              }
            ),
            addressNumber: Yup.string().when(
              "apartmentSuiteOrFloor",
              ([apartmentSuiteOrFloor], schema) => {
                if (apartmentSuiteOrFloor?.value)
                  return Yup.string().required("Required").trim();
                return schema;
              }
            ),
            apartmentSuiteOrFloor: dependsOnResidence(true),
            country: dependsOnResidence(true),
            province: dependsOnResidence(),
            stateOrTerritory: dependsOnResidence(true),
            addressCode: Yup.string().when(
              "residence",
              ([residence], schema) => {
                if (
                  residence?.value ===
                  childResidenceValues["does not reside with me"]
                )
                  return Yup.string()
                    .required("Required")
                    .when("country", ([country], schema) => {
                      if (isZipCode(country?.value))
                        return Yup.string()
                          .length(5, "Maximum of 5 characters allowed")
                          .required("Required")
                          .trim()
                          .test("Digits only", "Must be a number", digitsOnly);
                      return schema;
                    });
                return schema;
              }
            ),
          })
        );
      }
      return schema;
    }
  ),
});

const testChecklistVal = (
  context: Yup.TestContext<Yup.AnyObject>,
  value: string,
  isNo?: boolean
) => {
  const { options, parent } = context as Yup.TestContext &
    ValidateOptionsExtended;
  const key = options.key + riskyCheckBoxName;
  return (isNo
    ? value === GenericYesNoOptionsValue.no
    : value === GenericYesNoOptionsValue.yes) && !parent[key]
    ? false
    : true;
};

const dependsOnEverAMemberOfMilitary = (isDate?: boolean) => {
  if (isDate) {
    return Yup.string()
      .nullable()
      .when(
        [
          "ever_a_member_of_military_unit",
          "ever_a_member_of_paramilitary_unit",
        ],
        (
          [ever_a_member_of_military_unit, ever_a_member_of_paramilitary_unit],
          schema
        ) => {
          if (
            ever_a_member_of_military_unit === GenericYesNoOptionsValue.yes ||
            ever_a_member_of_paramilitary_unit === GenericYesNoOptionsValue.yes
          ) {
            return Yup.string().nullable().required("Required");
          }

          return schema;
        }
      );
  } else
    return Yup.string().when(
      ["ever_a_member_of_military_unit", "ever_a_member_of_paramilitary_unit"],
      (
        [ever_a_member_of_military_unit, ever_a_member_of_paramilitary_unit],
        schema
      ) => {
        if (
          ever_a_member_of_military_unit === GenericYesNoOptionsValue.yes ||
          ever_a_member_of_paramilitary_unit === GenericYesNoOptionsValue.yes
        ) {
          return Yup.string().required("Required").trim();
        }

        return schema;
      }
    );
};

const dependsOnChecklistYes = (key: string, isNo?: boolean) => {
  return Yup.string().when([key], ([key], schema) => {
    if (
      isNo
        ? key === GenericYesNoOptionsValue.no
        : key === GenericYesNoOptionsValue.yes
    ) {
      return Yup.string()
        .required("Required")
        .trim()
        .test("checklist", "Risk detected", (value, context) => {
          return testChecklistVal(context, value);
        });
    }

    return schema;
  });
};

export const CheckPart1Validation = Yup.object().shape<
  SchemaObject<CheckFormData1>
>({
  ever_claim_to_be_citizen: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_register_to_vote_in_election: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  do_you_owe_overdue_taxes: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_called_yourself_non_resident_on_tax: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_been_member_of_communist_or_totalitarian_party: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_supported_opposition_to_all_organized_government: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_supported_world_communism: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_supported_totalitarian_in_us: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_supported_overthrow_of_government: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_supported_killing_of_officers_of_us: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_supported_unlawful_damage_of_property: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_supported_sabotage: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  // ever_been_member_of_terrorist_organization: Yup.string()
  //   .required("Required")
  //   .trim()
  //   .test("checklist", "Risk detected", (value, context) => {
  //     return testChecklistVal(context, value);
  //   }),

  ever_used_weapon_with_intent_to_harm: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_engaged_in_kidnapping_assassination_hijacking: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_threatened_tried_conspired_prepared_planned_incited: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_involved_in_genocide: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_involved_in_torture: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_involved_in_killing_or_trying_to_kill: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_involved_in_hurting_or_trying_to_hurt: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_involved_in_forcing_or_trying_to_force_sexual_relation: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_involved_in_not_letting_one_practice_religion: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_caused_harm_because_of_race_religion_national_origin: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_a_member_of_military_unit: Yup.string().required("Required").trim(),
  name_of_country: dependsOnEverAMemberOfMilitary(),

  name_of_military_unit: dependsOnEverAMemberOfMilitary(),

  rank_or_position: dependsOnEverAMemberOfMilitary(),

  date_you_joined: dependsOnEverAMemberOfMilitary(true),

  date_you_left: dependsOnEverAMemberOfMilitary(true),

  ever_a_member_of_paramilitary_unit: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_served_in_any_other_place_people_were_forced_to_stay: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_part_of_a_group_that_used_weapon: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  did_you_use_a_weapon_on_another_person: dependsOnChecklistYes(
    "ever_part_of_a_group_that_used_weapon"
  ),
  did_you_threaten_to_use_a_weapon_on_another_person: dependsOnChecklistYes(
    "ever_part_of_a_group_that_used_weapon"
  ),

  ever_sell_weapons: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_receive_military_or_weapons_training: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_recruit_person_under_age_15_to_help_armed_force: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_used_person_under_age_15_to_help_people_in_combat: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_claim_to_be_citizen_risky_checkbox: Yup.bool(),
  ever_register_to_vote_in_election_risky_checkbox: Yup.bool(),
  do_you_owe_overdue_taxes_risky_checkbox: Yup.bool(),
  ever_called_yourself_non_resident_on_tax_risky_checkbox: Yup.bool(),
  ever_been_member_of_communist_or_totalitarian_party_risky_checkbox:
    Yup.bool(),
  ever_supported_opposition_to_all_organized_government_risky_checkbox:
    Yup.bool(),
  ever_supported_world_communism_risky_checkbox: Yup.bool(),
  ever_supported_totalitarian_in_us_risky_checkbox: Yup.bool(),
  ever_supported_overthrow_of_government_risky_checkbox: Yup.bool(),
  ever_supported_killing_of_officers_of_us_risky_checkbox: Yup.bool(),
  ever_supported_unlawful_damage_of_property_risky_checkbox: Yup.bool(),
  ever_supported_sabotage_risky_checkbox: Yup.bool(),
  // ever_been_member_of_terrorist_organization_risky_checkbox: Yup.bool(),
  ever_used_weapon_with_intent_to_harm_risky_checkbox: Yup.bool(),
  ever_engaged_in_kidnapping_assassination_hijacking_risky_checkbox: Yup.bool(),
  ever_threatened_tried_conspired_prepared_planned_incited_risky_checkbox:
    Yup.bool(),
  ever_involved_in_genocide_risky_checkbox: Yup.bool(),
  ever_involved_in_torture_risky_checkbox: Yup.bool(),
  ever_involved_in_killing_or_trying_to_kill_risky_checkbox: Yup.bool(),
  ever_involved_in_hurting_or_trying_to_hurt_risky_checkbox: Yup.bool(),
  ever_involved_in_forcing_or_trying_to_force_sexual_relation_risky_checkbox:
    Yup.bool(),
  ever_involved_in_not_letting_one_practice_religion_risky_checkbox: Yup.bool(),
  ever_caused_harm_because_of_race_religion_national_origin_risky_checkbox:
    Yup.bool(),
  // name_of_country_risky_checkbox: Yup.bool(),
  // name_of_military_unit_risky_checkbox: Yup.bool(),
  // rank_or_position_risky_checkbox: Yup.bool(),
  // date_you_joined_risky_checkbox: Yup.bool(),
  // date_you_left_risky_checkbox: Yup.bool(),
  ever_a_member_of_paramilitary_unit_risky_checkbox: Yup.bool(),
  ever_served_in_any_other_place_people_were_forced_to_stay_risky_checkbox:
    Yup.bool(),
  ever_part_of_a_group_that_used_weapon_risky_checkbox: Yup.bool(),
  did_you_use_a_weapon_on_another_person_risky_checkbox: Yup.bool(),
  did_you_threaten_to_use_a_weapon_on_another_person_risky_checkbox: Yup.bool(),
  ever_sell_weapons_risky_checkbox: Yup.bool(),
  ever_receive_military_or_weapons_training_risky_checkbox: Yup.bool(),
  ever_recruit_person_under_age_15_to_help_armed_force_risky_checkbox:
    Yup.bool(),
  ever_used_person_under_age_15_to_help_people_in_combat_risky_checkbox:
    Yup.bool(),
});

const dependsOnExplanationNeeded = (exp: string) => {
  return Yup.string().when(exp, ([exp], schema) => {
    if (exp === GenericYesNoOptionsValue.yes) {
      return Yup.string().required("Required").trim();
    }
    return schema;
  });
};

export const CheckPart2Validation = Yup.object().shape<
  SchemaObject<CheckFormData2>
>({
  ever_committed_or_attempted_to_commit_a_crime: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_been_arrested_by_law_enforcement_officer: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  crimeRecordsArray: Yup.array().when(
    [
      "ever_committed_or_attempted_to_commit_a_crime",
      "ever_been_arrested_by_law_enforcement_officer",
    ],
    (
      [
        ever_committed_or_attempted_to_commit_a_crime,
        ever_been_arrested_by_law_enforcement_officer,
      ],
      schema
    ) => {
      if (
        ever_committed_or_attempted_to_commit_a_crime ===
          GenericYesNoOptionsValue.yes ||
        ever_been_arrested_by_law_enforcement_officer ===
          GenericYesNoOptionsValue.yes
      ) {
        return Yup.array().of(
          Yup.object().shape({
            crime_or_offense: Yup.string().required("Required").trim(),
            date_of_crime: Yup.string().nullable().required("Required").trim(),
            date_of_conviction: Yup.string()
              .nullable()
              .required("Required")
              .trim(),
            place_of_crime: Yup.string().required("Required").trim(),
            disposition_of_arrest_citation_charge: Yup.string()
              .required("Required")
              .trim(),
            what_was_your_sentence: Yup.string().required("Required").trim(),
          })
        );
      }
      return schema;
    }
  ),

  ever_received_a_suspended_sentence: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value, true);
    }),

  ever_been_a_prostitute: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_been_a_prostitute_exp: dependsOnExplanationNeeded(
    "ever_been_a_prostitute"
  ),

  ever_sold_or_smuggled_narcotics: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_sold_or_smuggled_narcotics_exp: dependsOnExplanationNeeded(
    "ever_sold_or_smuggled_narcotics"
  ),

  ever_been_married_to_one_person_at_a_time: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_been_married_to_one_person_at_a_time_exp: dependsOnExplanationNeeded(
    "ever_been_married_to_one_person_at_a_time"
  ),

  ever_been_married_to_obtain_immigration_benefit: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_been_married_to_obtain_immigration_benefit_exp:
    dependsOnExplanationNeeded(
      "ever_been_married_to_obtain_immigration_benefit"
    ),

  ever_helped_anyone_enter_illegally: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_helped_anyone_enter_illegally_exp: dependsOnExplanationNeeded(
    "ever_helped_anyone_enter_illegally"
  ),

  ever_gambled_illegally: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_gambled_illegally_exp: dependsOnExplanationNeeded(
    "ever_gambled_illegally"
  ),

  ever_failed_to_support_dependents: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_failed_to_support_dependents_exp: dependsOnExplanationNeeded(
    "ever_failed_to_support_dependents"
  ),

  ever_made_any_misrepresentation_to_obtain_any_public_benefit: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_made_any_misrepresentation_to_obtain_any_public_benefit_exp:
    dependsOnExplanationNeeded(
      "ever_made_any_misrepresentation_to_obtain_any_public_benefit"
    ),

  ever_given_any_official_false_documentation: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_given_any_official_false_documentation_exp: dependsOnExplanationNeeded(
    "ever_given_any_official_false_documentation"
  ),

  ever_lied_to_any_official_to_gain_entry: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_lied_to_any_official_to_gain_entry_exp: dependsOnExplanationNeeded(
    "ever_lied_to_any_official_to_gain_entry"
  ),

  ever_committed_or_attempted_to_commit_a_crime_risky_checkbox: Yup.bool(),
  ever_been_arrested_by_law_enforcement_officer_risky_checkbox: Yup.bool(),
  ever_received_a_suspended_sentence_risky_checkbox: Yup.bool(),
  ever_been_a_prostitute_risky_checkbox: Yup.bool(),
  ever_sold_or_smuggled_narcotics_risky_checkbox: Yup.bool(),
  ever_been_married_to_one_person_at_a_time_risky_checkbox: Yup.bool(),
  ever_been_married_to_obtain_immigration_benefit_risky_checkbox: Yup.bool(),
  ever_helped_anyone_enter_illegally_risky_checkbox: Yup.bool(),
  ever_gambled_illegally_risky_checkbox: Yup.bool(),
  ever_failed_to_support_dependents_risky_checkbox: Yup.bool(),
  ever_given_any_official_false_documentation_risky_checkbox: Yup.bool(),
  ever_lied_to_any_official_to_gain_entry_risky_checkbox: Yup.bool(),
});

export const CheckPart3Validation = Yup.object().shape<
  SchemaObject<CheckFormData3>
>({
  ever_been_placed_in_deportation_proceedings: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_been_placed_in_deportation_proceedings_exp: dependsOnExplanationNeeded(
    "ever_been_placed_in_deportation_proceedings"
  ),

  ever_been_removed_or_deported: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_been_removed_or_deported_exp: dependsOnExplanationNeeded(
    "ever_been_removed_or_deported"
  ),

  are_you_a_male_in_the_us_between_18th_26th: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  did_you_register_for_selective_service: Yup.string().when(
    "are_you_a_male_in_the_us_between_18th_26th",
    ([are_you_a_male_in_the_us_between_18th_26th], schema) => {
      if (
        are_you_a_male_in_the_us_between_18th_26th ===
        GenericYesNoOptionsValue.yes
      ) {
        return Yup.string()
          .required("Required")
          .trim()
          .test("checklist", "Risk detected", (value, context) => {
            return testChecklistVal(context, value);
          });
      }
      return schema;
    }
  ),

  date_registered_for_selective_service: Yup.string()
    .nullable()
    .when(
      "did_you_register_for_selective_service",
      ([did_you_register_for_selective_service], schema) => {
        if (
          did_you_register_for_selective_service ===
          GenericYesNoOptionsValue.yes
        ) {
          return Yup.string().nullable().required("Required").trim();
        }
        return schema;
      }
    ),

  selective_service_number: Yup.string().when(
    "did_you_register_for_selective_service",
    ([did_you_register_for_selective_service], schema) => {
      if (
        did_you_register_for_selective_service === GenericYesNoOptionsValue.yes
      ) {
        return Yup.string()
          .matches(/^\d{10}$/, "Must be 10 digits")
          .required("Required")
          .nullable()
          .trim();
      }
      return schema;
    }
  ),

  ever_left_the_united_states_to_avoid_being_drafted: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_left_the_united_states_to_avoid_being_drafted_exp:
    dependsOnExplanationNeeded(
      "ever_left_the_united_states_to_avoid_being_drafted"
    ),

  ever_applied_for_any_kind_of_exemption_from_military_service: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  ever_applied_for_any_kind_of_exemption_from_military_service_exp:
    dependsOnExplanationNeeded(
      "ever_applied_for_any_kind_of_exemption_from_military_service"
    ),

  ever_served_in_armed_forces: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  are_you_currently_a_member_of_armed_forces: dependsOnChecklistYes(
    "ever_served_in_armed_forces"
  ),

  are_you_scheduled_to_deploy_overseas: dependsOnChecklistYes(
    "are_you_currently_a_member_of_armed_forces"
  ),
  are_you_currently_stationed_overseas: dependsOnChecklistYes(
    "are_you_currently_a_member_of_armed_forces"
  ),

  are_you_a_former_military_member_overseas: dependsOnChecklistYes(
    "are_you_currently_a_member_of_armed_forces",
    true
  ),

  ever_been_court_martialed: dependsOnChecklistYes(
    "ever_served_in_armed_forces"
  ),

  ever_been_court_martialed_exp: dependsOnExplanationNeeded(
    "ever_been_court_martialed"
  ),

  ever_been_discharged_because_you_were_a_alien: dependsOnChecklistYes(
    "ever_served_in_armed_forces"
  ),

  ever_been_discharged_because_you_were_a_alien_exp: dependsOnExplanationNeeded(
    "ever_been_discharged_because_you_were_a_alien"
  ),

  ever_deserted_the_armed_forces: dependsOnChecklistYes(
    "ever_served_in_armed_forces"
  ),

  ever_deserted_the_armed_forces_exp: dependsOnExplanationNeeded(
    "ever_deserted_the_armed_forces"
  ),

  ever_been_placed_in_deportation_proceedings_risky_checkbox: Yup.bool(),
  ever_been_removed_or_deported_risky_checkbox: Yup.bool(),
  are_you_a_male_in_the_us_between_18th_26th_risky_checkbox: Yup.bool(),
  did_you_register_for_selective_service_risky_checkbox: Yup.bool(),
  date_registered_for_selective_service_risky_checkbox: Yup.bool(),
  selective_service_number_risky_checkbox: Yup.bool(),
  ever_left_the_united_states_to_avoid_being_drafted_risky_checkbox: Yup.bool(),
  ever_applied_for_any_kind_of_exemption_from_military_service_risky_checkbox:
    Yup.bool(),
  ever_served_in_armed_forces_risky_checkbox: Yup.bool(),
  are_you_currently_a_member_of_armed_forces_risky_checkbox: Yup.bool(),
  are_you_scheduled_to_deploy_overseas_risky_checkbox: Yup.bool(),
  are_you_currently_stationed_overseas_risky_checkbox: Yup.bool(),
  are_you_a_former_military_member_overseas_risky_checkbox: Yup.bool(),
  ever_been_court_martialed_risky_checkbox: Yup.bool(),
  ever_been_discharged_because_you_were_a_alien_risky_checkbox: Yup.bool(),
  ever_deserted_the_armed_forces_risky_checkbox: Yup.bool(),
});

const dependOnAreYouUnableToTakeOathOfAllegiance = () => {
  return Yup.string().when(
    "are_you_unable_to_take_oath_of_allegiance",
    ([are_you_unable_to_take_oath_of_allegiance], schema) => {
      if (
        are_you_unable_to_take_oath_of_allegiance ===
        GenericYesNoOptionsValue.no
      ) {
        return Yup.string()
          .required("Required")
          .trim()
          .test("checklist", "Risk detected", (value, context) => {
            return testChecklistVal(context, value, true);
          });
      }
      return schema;
    }
  );
};

export const CheckPart4Validation = Yup.object().shape<
  SchemaObject<CheckFormData4>
>({
  do_you_have_hereditary_title: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  do_you_have_hereditary_title_exp: dependsOnExplanationNeeded(
    "do_you_have_hereditary_title"
  ),

  are_you_willing_to_give_up_inherited_titles: Yup.string().when(
    "do_you_have_hereditary_title",
    ([do_you_have_hereditary_title], schema) => {
      if (do_you_have_hereditary_title === GenericYesNoOptionsValue.yes) {
        return Yup.string()
          .required("Required")
          .trim()
          .test("checklist", "Risk detected", (value, context) => {
            return testChecklistVal(context, value, true);
          });
      }
      return schema;
    }
  ),

  list_of_titles: Yup.string().when(
    "do_you_have_hereditary_title",
    ([do_you_have_hereditary_title], schema) => {
      if (do_you_have_hereditary_title === GenericYesNoOptionsValue.yes) {
        return Yup.string().required("Required").trim();
      }
      return schema;
    }
  ),

  do_you_support_the_constitution: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value, true);
    }),

  do_you_understand_the_oath_of_allegiance: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value, true);
    }),

  are_you_unable_to_take_oath_of_allegiance: Yup.string()
    .required("Required")
    .trim()
    .test("checklist", "Risk detected", (value, context) => {
      return testChecklistVal(context, value);
    }),

  are_you_willing_to_take_oath_of_allegiance:
    dependOnAreYouUnableToTakeOathOfAllegiance(),

  are_you_willing_to_bear_arms: dependOnAreYouUnableToTakeOathOfAllegiance(),

  are_you_willing_to_perform_noncombatant_services:
    dependOnAreYouUnableToTakeOathOfAllegiance(),

  are_you_willing_to_perform_work_of_national_importance:
    dependOnAreYouUnableToTakeOathOfAllegiance(),

  do_you_have_hereditary_title_risky_checkbox: Yup.bool(),
  are_you_willing_to_give_up_inherited_titles_risky_checkbox: Yup.bool(),
  // list_of_titles_risky_checkbox: Yup.bool(),
  do_you_support_the_constitution_risky_checkbox: Yup.bool(),
  do_you_understand_the_oath_of_allegiance_risky_checkbox: Yup.bool(),
  are_you_unable_to_take_oath_of_allegiance_risky_checkbox: Yup.bool(),
  are_you_willing_to_take_oath_of_allegiance_risky_checkbox: Yup.bool(),
  are_you_willing_to_bear_arms_risky_checkbox: Yup.bool(),
  are_you_willing_to_perform_noncombatant_services_risky_checkbox: Yup.bool(),
  are_you_willing_to_perform_work_of_national_importance_risky_checkbox:
    Yup.bool(),
});

// export const CheckPart2Validation = Yup.object().shape<
//   SchemaObject<CheckFormData2>
// >({
//   ever_persecuted_any_person: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   did_you_work_for_the_nazi: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   did_you_work_with_any_government_allied_with_nazi: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   did_you_work_with_any_german_nazi_or_ss_military_unit: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_involved_in_genocide: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_involved_in_torture: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_involved_in_killing_or_trying_to_kill: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_involved_in_hurting_or_trying_to_hurt: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_involved_in_forcing_or_trying_to_force_sexual_relation: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_involved_in_not_letting_one_practice_religion: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_a_member_of_military_unit: Yup.string().required("Required").trim(),
//   ever_a_member_of_paramilitary_unit: Yup.string().required("Required").trim(),
//   ever_a_member_of_police_unit: Yup.string().required("Required").trim(),
//   ever_a_member_of_self_defense_unit: Yup.string().required("Required").trim(),
//   ever_a_member_of_vigilante_unit: Yup.string().required("Required").trim(),
//   ever_a_member_of_rebel_group: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_a_member_of_guerrilla_group: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_a_member_of_militia: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_a_member_of_insurgent: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_served_in_prison: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_served_in_prison_camp: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_served_in_detention_facility: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_served_in_labor_camp: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_served_in_any_other_place_people_were_forced_to_stay: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_part_of_a_group_that_used_weapon: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_sell_weapons: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_receive_military_or_weapons_training: Yup.string()
//     .required("Required")
//     .trim(),
//   ever_recruit_person_under_age_15_to_help_armed_force: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_used_person_under_age_15_to_help_people_in_combat: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_persecuted_any_person_risky_checkbox: Yup.bool(),
//   did_you_work_for_the_nazi_risky_checkbox: Yup.bool(),
//   did_you_work_with_any_government_allied_with_nazi_risky_checkbox: Yup.bool(),
//   did_you_work_with_any_german_nazi_or_ss_military_unit_risky_checkbox:
//     Yup.bool(),
//   ever_involved_in_genocide_risky_checkbox: Yup.bool(),
//   ever_involved_in_torture_risky_checkbox: Yup.bool(),
//   ever_involved_in_killing_or_trying_to_kill_risky_checkbox: Yup.bool(),
//   ever_involved_in_hurting_or_trying_to_hurt_risky_checkbox: Yup.bool(),
//   ever_involved_in_forcing_or_trying_to_force_sexual_relation_risky_checkbox:
//     Yup.bool(),
//   ever_involved_in_not_letting_one_practice_religion_risky_checkbox: Yup.bool(),
//   ever_a_member_of_rebel_group_risky_checkbox: Yup.bool(),
//   ever_a_member_of_guerrilla_group_risky_checkbox: Yup.bool(),
//   ever_a_member_of_militia_risky_checkbox: Yup.bool(),
//   ever_a_member_of_insurgent_risky_checkbox: Yup.bool(),
//   ever_served_in_prison_risky_checkbox: Yup.bool(),
//   ever_served_in_prison_camp_risky_checkbox: Yup.bool(),
//   ever_served_in_detention_facility_risky_checkbox: Yup.bool(),
//   ever_served_in_labor_camp_risky_checkbox: Yup.bool(),
//   ever_served_in_any_other_place_people_were_forced_to_stay_risky_checkbox:
//     Yup.bool(),
//   ever_part_of_a_group_that_used_weapon_risky_checkbox: Yup.bool(),
//   ever_sell_weapons_risky_checkbox: Yup.bool(),
//   ever_recruit_person_under_age_15_to_help_armed_force_risky_checkbox:
//     Yup.bool(),
//   ever_used_person_under_age_15_to_help_people_in_combat_risky_checkbox:
//     Yup.bool(),
// });

// export const CheckPart3Validation = Yup.object().shape<
//   SchemaObject<CheckFormData3>
// >({
//   ever_committed_or_attempted_to_commit_a_crime: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_been_arrested_by_law_enforcement_officer: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   any_incident_more_serious_than_a_parking_ticket: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_been_charged_with_committing_or_attempt_to_commit_a_crime: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_been_convicted_of_a_crime: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_been_placed_on_alternative_sentence: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_received_a_suspended_sentence: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_been_in_jail: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   jailReason: Yup.string().trim(),
//   jailDate: Yup.string().nullable().trim(),
//   jailLocation: Yup.string().trim(),
//   immigrationStatus: Yup.object().nullable(),
//   ever_been_a_habitual_drunkard: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_been_a_prostitute: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_sold_or_smuggled_narcotics: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_been_married_to_one_person_at_a_time: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_been_married_to_obtain_immigration_benefit: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_helped_anyone_enter_illegally: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_gambled_illegally: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_failed_to_support_dependents: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_made_any_misrepresentation_to_obtain_any_public_benefit: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_given_any_official_false_documentation: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_committed_or_attempted_to_commit_a_crime_risky_checkbox: Yup.bool(),
//   ever_been_arrested_by_law_enforcement_officer_risky_checkbox: Yup.bool(),
//   any_incident_more_serious_than_a_parking_ticket_risky_checkbox: Yup.bool(),
//   ever_been_charged_with_committing_or_attempt_to_commit_a_crime_risky_checkbox:
//     Yup.bool(),
//   ever_been_convicted_of_a_crime_risky_checkbox: Yup.bool(),
//   ever_been_placed_on_alternative_sentence_risky_checkbox: Yup.bool(),
//   ever_received_a_suspended_sentence_risky_checkbox: Yup.bool(),
//   ever_been_in_jail_risky_checkbox: Yup.bool(),
//   ever_been_a_habitual_drunkard_risky_checkbox: Yup.bool(),
//   ever_been_a_prostitute_risky_checkbox: Yup.bool(),
//   ever_sold_or_smuggled_narcotics_risky_checkbox: Yup.bool(),
//   ever_been_married_to_one_person_at_a_time_risky_checkbox: Yup.bool(),
//   ever_been_married_to_obtain_immigration_benefit_risky_checkbox: Yup.bool(),
//   ever_helped_anyone_enter_illegally_risky_checkbox: Yup.bool(),
//   ever_gambled_illegally_risky_checkbox: Yup.bool(),
//   ever_failed_to_support_dependents_risky_checkbox: Yup.bool(),
//   ever_made_any_misrepresentation_to_obtain_any_public_benefit_risky_checkbox:
//     Yup.bool(),
//   ever_given_any_official_false_documentation_risky_checkbox: Yup.bool(),
// });

// export const CheckPart4Validation = Yup.object().shape<
//   SchemaObject<CheckFormData4>
// >({
//   ever_lied_to_any_official_to_gain_entry: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_been_removed_or_deported: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_been_ordered_removed_or_deported: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_been_placed_in_deportation_proceedings: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   are_deportation_proceeding_pending_against_you: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_served_in_armed_forces: Yup.string().required("Required").trim(),
//   are_you_currently_a_member_of_armed_forces: Yup.string()
//     .required("Required")
//     .trim(),
//   are_you_scheduled_to_deploy_overseas: Yup.string()
//     .required("Required")
//     .trim(),
//   are_you_currently_stationed_overseas: Yup.string()
//     .required("Required")
//     .trim(),
//   ever_been_court_martialed: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_lied_to_any_official_to_gain_entry_risky_checkbox: Yup.bool(),
//   ever_been_removed_or_deported_risky_checkbox: Yup.bool(),
//   ever_been_ordered_removed_or_deported_risky_checkbox: Yup.bool(),
//   ever_been_placed_in_deportation_proceedings_risky_checkbox: Yup.bool(),
//   are_deportation_proceeding_pending_against_you_risky_checkbox: Yup.bool(),
//   ever_been_court_martialed_risky_checkbox: Yup.bool(),
// });

// export const CheckPart5Validation = Yup.object().shape<
//   SchemaObject<CheckFormData5>
// >({
//   ever_been_discharged_because_you_were_a_alien: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_left_the_united_states_to_avoid_being_drafted: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   ever_applied_for_any_kind_of_exemption_from_military_service: Yup.string()
//     .required("Required")
//     .trim(),
//   ever_deserted_the_armed_forces: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value);
//     }),
//   lived_in_the_united_states_between_your_18th_and_26th: Yup.string()
//     .required("Required")
//     .trim(),
//   do_you_support_the_constitution: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value, true);
//     }),
//   do_you_understand_the_oath_of_allegiance: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value, true);
//     }),
//   are_you_willing_to_take_oath_of_allegiance: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value, true);
//     }),
//   are_you_willing_to_bear_arms: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value, true);
//     }),
//   are_you_willing_to_perform_noncombatant_services: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value, true);
//     }),
//   ever_been_discharged_because_you_were_a_alien_risky_checkbox: Yup.bool(),
//   ever_left_the_united_states_to_avoid_being_drafted_risky_checkbox: Yup.bool(),
//   ever_deserted_the_armed_forces_risky_checkbox: Yup.bool(),
//   do_you_support_the_constitution_risky_checkbox: Yup.bool(),
//   do_you_understand_the_oath_of_allegiance_risky_checkbox: Yup.bool(),
//   are_you_willing_to_take_oath_of_allegiance_risky_checkbox: Yup.bool(),
//   are_you_willing_to_bear_arms_risky_checkbox: Yup.bool(),
//   are_you_willing_to_perform_noncombatant_services_risky_checkbox: Yup.bool(),
// });

// export const CheckPart6Validation = Yup.object().shape<
//   SchemaObject<CheckFormData6>
// >({
//   are_you_willing_to_perform_work_of_national_importance: Yup.string()
//     .required("Required")
//     .trim()
//     .test("checklist", "Risk detected", (value, context) => {
//       return testChecklistVal(context, value, true);
//     }),
//   ever_been_a_member_of_any_group: Yup.string().required("Required").trim(),
//   are_you_willing_to_perform_work_of_national_importance_risky_checkbox:
//     Yup.bool(),
//   organizationArray: Yup.array().when(
//     "ever_been_a_member_of_any_group",
//     ([ever_been_a_member_of_any_group], schema) => {
//       if (ever_been_a_member_of_any_group === GenericYesNoOptionsValue.yes)
//         return Yup.array().of(
//           Yup.object().shape({
//             nameOrganization: Yup.string().required("Required").trim(),
//             purposeOrganization: Yup.string().required("Required").trim(),
//             dateFrom: Yup.string().nullable().required("Required").trim(),
//             dateTo: Yup.string()
//               .nullable()
//               .test("yetToReturn", "Required", (value, context) => {
//                 const data = context.parent as orgainizationArrayPropsPayload;
//                 return !data.stillMember && !data.dateTo ? false : true;
//               })
//               .test(
//                 "began-left-date",
//                 "This date cannot be before than Date To",
//                 (value, context) => {
//                   const data = context.parent as orgainizationArrayPropsPayload;

//                   const fromDate = dayjs(data.dateFrom);
//                   const toDate = dayjs(data.dateTo);

//                   return data.dateFrom && data.dateTo
//                     ? !toDate.isBefore(fromDate)
//                     : true;
//                 }
//               ),
//             stillMember: Yup.bool(),
//           })
//         );

//       return schema;
//     }
//   ),
// });

export const N400FillingValidation = Yup.object().shape({
  filing_basis: Yup.string().required("Required"),
});

const onDependOnHouseholdIncome = () => {
  return Yup.string().when("household_income", ([household_income], schema) => {
    if (household_income === GenericYesNoOptionsValue.yes) {
      return Yup.string().required("Required").trim();
    }
    return schema;
  });
};

export const FeeReductionValidation = Yup.object().shape<
  SchemaObject<FeeReductionData>
>({
  household_income: Yup.string().required("Required").trim(),
  total_household_income: onDependOnHouseholdIncome(),
  household_size: onDependOnHouseholdIncome(),
  total_number_of_household_members: onDependOnHouseholdIncome(),
  head_of_household: onDependOnHouseholdIncome(),
  name_of_head_of_household: Yup.string().when(
    "head_of_household",
    ([head_of_household], schema) => {
      if (head_of_household === GenericYesNoOptionsValue.no) {
        return Yup.string().required("Required").trim();
      }
      return schema;
    }
  ),
});
