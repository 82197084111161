import * as Yup from "yup";

export type SchemaObject<T> = {
  [key in keyof T]: Yup.Schema<any>;
};

export interface leftSideBarDataProps {
  label: string;
  // url?: string;
  formProgress?: string;
  children?: {
    name: string;
    // url: string;
    formProgress: string;
  }[];
}

export enum ApplicationPreparerValues {
  iAmTheApplicant = "I am the applicant",
  iAmApreparer = "I am not an attorney but i am a preparer",
  iAmTheAttorney = "I am an attorney",
}

export enum ReceiveInterpreterHelpValues {
  yes = "yes",
  no = "no",
}

export interface ValidateOptionsExtended {
  options: {
    index: number;
    key: string;
  };
}

export interface DownloadFormsContentProps {
  hideMainform?: boolean;
  hideFilingForm?: boolean;
  hideG1145Form?: boolean;
  formType: string;
}

export type DataItemsProps = {};
// | GetAllCasesRes
// | GetDS160VisitorEmailRes
// | AgentListRes
// | UserListObj;

export enum CellSizePercentages {
  s5 = "5%",
  s10 = "10%",
  s15 = "15%",
  s20 = "20%",
  s25 = "25%",
  s30 = "30%",
  s35 = "35%",
  s40 = "40%",
  s45 = "45%",
  s50 = "50%",
}

export interface tableDataProps {
  columnName: string;
  columnWidth?: CellSizePercentages | string;
  columnKey?: string;
  maxLength?: number;
  customComp?: (
    data: DataItemsProps,
    isNearEnd: boolean
  ) => JSX.Element | string;
  hideOnTable?: boolean;
  hideOnDetails?: boolean;
  isAction?: boolean;
}

// export interface tableDataProps {
//   columnName: string;
//   columnWidth?: CellSizePercentages | string;
//   columnKey?: string;
//   canSort?: boolean;
//   maxLength?: number;
//   isStatus?: boolean;
//   isAction?: boolean;
//   customComp?: (data: DataItemsProps) => JSX.Element | string;
// }

export enum QueriesKey {
  search = "search",
  caseType = "caseType",
  status = "status",
}

export interface ItemActionOptionsProps {
  name: string;
  icon?: JSX.Element;
  color?: string;
  onClick?: () => void;
  isDisabled?: boolean;
}
