import React, { FC } from "react";
import { BackBtn, BtnWrapper, HR, NextBtn } from "./styles";
import Spinner from "../../../utils/Spinner";
import Colors from "../../../styles/Colors";
import { FaArrowLeft } from "react-icons/fa";
import useToNextSection from "../../../hooks/useToNextSection";
import { FaInfoCircle } from "react-icons/fa";

interface Props {
  isLoading: boolean;
  addBackBtn?: boolean;
  addContinueBtn?: boolean;
  onContinue?: () => void;
  removeHR?: boolean;
  isError: boolean;
  onBack?: () => void;
  text?: string;
}

const FormBtn: FC<Props> = ({
  isLoading,
  addBackBtn,
  onContinue,
  removeHR,
  addContinueBtn = true,
  onBack,
  isError,
  text,
}) => {
  const { toPrevSection } = useToNextSection();

  return (
    <>
      {!removeHR && <HR />}

      <>
        <BtnWrapper>
          {addBackBtn && !isLoading && (
            <BackBtn
              onClick={() => (onBack ? onBack() : toPrevSection())}
              type="button"
            >
              <FaArrowLeft />
              Back
            </BackBtn>
          )}
          {/* <SaveBtn type="submit">SAVE PROGRESS</SaveBtn> */}
          {addContinueBtn && (
            <NextBtn type="submit" disabled={isLoading} onClick={onContinue}>
              {isLoading && <Spinner color={Colors.White} size={20} />}
              {text ? text : "Save and Continue"}
            </NextBtn>
          )}
        </BtnWrapper>
      </>

      {isError ? (
        <div className="flex items-center gap-1 text-sm text-red-600">
          <FaInfoCircle />
          This form is not ready for submission. Please return to verify.
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default FormBtn;
