import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getN565ApplicantInfo,
  getN565ApplicationType,
  getN565Eligibility,
  getN565Files,
  getN565Form,
  getN565Info,
  getN565InfoFromCert,
  getN565PayFail,
  getN565PaySuccess,
  getN565PreparerInterpreter,
  getN565Trigger,
  payN565,
  saveN565ApplicantInfo,
  saveN565ApplicationType,
  saveN565Eligibility,
  saveN565InfoFromCert,
  saveN565PreparerInterpreter,
  updateN565Files,
  uploadN565Files,
} from "../../api/n565";
import { downloadPdf, parseError } from "../../utils";
import { n565QueryKeys } from "./types";
import { utilsQueryKeys } from "../utils/types";

export const useSaveN565InfoFromCert = () => {
  return useMutation(saveN565InfoFromCert, {
    onError: (error) => parseError(error),
  });
};

export const useGetN565InfoFromCert = () => {
  return useQuery([n565QueryKeys.getN565InfoFromCert], getN565InfoFromCert, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useSaveN565ApplicantInfo = () => {
  return useMutation(saveN565ApplicantInfo, {
    onError: (error) => parseError(error),
  });
};

export const useGetN565ApplicantInfo = () => {
  return useQuery(
    [n565QueryKeys.getN565ApplicationType],
    getN565ApplicantInfo,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveN565ApplicationType = () => {
  return useMutation(saveN565ApplicationType, {
    onError: (error) => parseError(error),
  });
};

export const useGetN565ApplicationType = () => {
  return useQuery(
    [n565QueryKeys.getN565ApplicationType],
    getN565ApplicationType,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetN565Form = (isEnabled?: boolean) => {
  return useQuery([n565QueryKeys.getN565Form], getN565Form, {
    enabled: isEnabled,
    onError: (error) => parseError(error),
    onSuccess: (data) => {
      downloadPdf(data.data.fileBuffer.data, `COMPLETED-N565-FORM.pdf`);
    },
    // select: (data) => data.data.fileBuffer.data,
    // refetchOnWindowFocus: false,
  });
};

export const useSaveN565PreparerInterpreter = () => {
  return useMutation(saveN565PreparerInterpreter, {
    onError: (error) => parseError(error),
  });
};

export const useGetN565PreparerInterpreter = () => {
  return useQuery(
    [n565QueryKeys.getN565PreparerInterpreter],
    getN565PreparerInterpreter,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetN565Info = () => {
  return useQuery([n565QueryKeys.getN565Info], getN565Info, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const usePayN565 = () => {
  return useMutation(payN565, {
    onError: (error) => parseError(error),
  });
};

export const useGetN565PaySuccess = (payId: string) => {
  const queryClient = useQueryClient();

  return useQuery(
    [n565QueryKeys.getN565PaySuccess, payId],
    () => getN565PaySuccess(payId),
    {
      enabled: !!payId,
      onSuccess: () =>
        queryClient.invalidateQueries([utilsQueryKeys.getUserData]),
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetN565PayFail = () => {
  const queryClient = useQueryClient();

  return useQuery([n565QueryKeys.getN565PayFail], getN565PayFail, {
    onSuccess: () =>
      queryClient.invalidateQueries([utilsQueryKeys.getUserData]),
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useSaveN565Eligibility = () => {
  const queryClient = useQueryClient();

  return useMutation(saveN565Eligibility, {
    onError: (error) => parseError(error),
    onSuccess: () =>
      queryClient.invalidateQueries([utilsQueryKeys.getUserData]),
  });
};

export const useGetN565Eligibility = () => {
  return useQuery([n565QueryKeys.getN565Eligibility], getN565Eligibility, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useUploadN565Files = () => {
  const queryClient = useQueryClient();

  return useMutation(uploadN565Files, {
    onError: (error) => parseError(error),
    onSuccess: () =>
      queryClient.invalidateQueries([n565QueryKeys.getN565Files]),
  });
};

export const useGetN565Files = () => {
  return useQuery([n565QueryKeys.getN565Files], getN565Files, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    // refetchOnWindowFocus: false,
  });
};

export const useUpdateN565Files = () => {
  const queryClient = useQueryClient();

  return useMutation(updateN565Files, {
    onError: (error) => parseError(error),
    onSuccess: () =>
      queryClient.invalidateQueries([n565QueryKeys.getN565Files]),
  });
};


export const useGetN565Trigger = (isEnabled: boolean) => {
  return useQuery([n565QueryKeys.getN565Trigger], getN565Trigger, {
    enabled: isEnabled,
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};