import FileBox from "./FileBox";
import { useState } from "react";
import { FormikProps } from "formik";
import FilePreview from "./FilePreview";
import { getImageDimensions } from "../../../../../../../utils";

interface Props {
  name: string;
  formik: FormikProps<any>;
  btnText: string;
  acceptedFormat?: string;
  maxFileSizeInBytes?: number;
  maxFileWidth?: number;
  maxFileHeight?: number;
}

const UploadBox = ({
  name,
  formik,
  acceptedFormat,
  maxFileSizeInBytes,
  maxFileWidth,
  maxFileHeight,
  btnText,
}: Props) => {
  const [file, setFile] = useState<File>();

  const handleFile = async (files: FileList) => {
    const file = files[0];
    if (maxFileSizeInBytes && file.size > maxFileSizeInBytes) {
      formik.setFieldTouched(name, true);
      formik.setFieldValue(name, file);
      return;
    }

    if (maxFileWidth || maxFileHeight) {
      const { width, height } = await getImageDimensions(file);

      if (maxFileWidth && width > maxFileWidth) {
        formik.setFieldTouched(name, true);
        formik.setFieldValue(name, file);
        return;
      }
      if (maxFileHeight && height > maxFileHeight) {
        formik.setFieldTouched(name, true);
        formik.setFieldValue(name, file);
        return;
      }
    }

    setFile(file);
    formik.setFieldValue(name, file);
  };

  const onClear = () => {
    setFile(undefined);
    formik.setFieldValue(name, null);
  };

  return (
    <>
      {file && formik.values.file ? (
        <FilePreview onClear={onClear} file={file} />
      ) : (
        <FileBox
          btnText={btnText}
          handleFile={handleFile}
          name={name}
          acceptedFormat={acceptedFormat}
        />
      )}
    </>
  );
};

export default UploadBox;
