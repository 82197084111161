import React from "react";
import styled from "styled-components";
import Constaint from "../layout/components/Constaint";
import LeftSideBar from "../components/LeftSideBar";
import useFormRouteGuard from "../../../hooks/useFormRouteGuard";
import { formEligibilityStore } from "../../../store/formEligibilityData";
// import { userDataStore } from "../../../store/userData";
// import { useSnapshot } from "valtio";
import { FormN600Progress } from "./components/types";
import { useGetN600Eligibility } from "../../../hooks/n600/useN600";
import { n600leftSideBarData } from "./components/data";
import RightSectionWrapper from "../components/RightSectionWrapper";
import EligibilityInfo from "./components/EligibilityInfo";
import InfoAboutYou1 from "./components/ApplicantInfo/InfoAboutYou1";
import InfoAboutYou2 from "./components/ApplicantInfo/InfoAboutYou2";
import InfoAboutYou3 from "./components/ApplicantInfo/InfoAboutYou3";
import InfoAboutYou4 from "./components/ApplicantInfo/InfoAboutYou4";
import BiographicInfo from "./components/BioInfo";
import PhysicalPresenceInUS from "./components/PhysicalPresenceInUS";
import USCitizenFatherP1 from "./components/USFatherInfo/Part1";
import USCitizenFatherP2 from "./components/USFatherInfo/Part2";
import USCitizenMotherP1 from "./components/USMotherInfo/Part1";
import USCitizenMotherP2 from "./components/USMotherInfo/Part2";
import MilitaryInfo from "./components/MilitaryInfo";
import N600Preparer from "./components/N600Preparer";
import N600Review from "./components/Review";
import ChatSystem from "../landing page/components/ChatSystem";

const F600 = () => {
  const { getType, lastProgress } = useFormRouteGuard(FormN600Progress);
  const { data } = useGetN600Eligibility();
  formEligibilityStore.formEligibility = data;
  const isFatherCitizen =
    data?.which_parent_is_a_us_citizen === "My father is a U.S citizen";
  const isMotherCitizen =
    data?.which_parent_is_a_us_citizen === "My mother is a U.S citizen";
  const isBothParent =
    data?.which_parent_is_a_us_citizen ===
    "Both of my parents are U.S citizens";

  return (
    <Wrapper>
      <Constaint>
        <Container>
          <LeftSideBar
            title="Application for Certificate of Citizenship"
            data={n600leftSideBarData(
              isFatherCitizen,
              isMotherCitizen,
              isBothParent
            )}
            storeProgress={lastProgress}
            progressEnum={FormN600Progress}
          />

          {/* Right section */}
          {
            <>
              {getType === FormN600Progress.infoAboutEligibility && (
                <RightSectionWrapper
                  title="1. Information About Your Eligibility"
                  subText=""
                  sectionComp={<EligibilityInfo />}
                  getType={getType}
                  enumVal={FormN600Progress}
                />
              )}

              {getType === FormN600Progress.infoAboutYouP1 && (
                <RightSectionWrapper
                  title="2. Information About You"
                  subText="Provide information about yourself if you are a person applying for the Certificate of Citizenship. Provide information about your child if you are a U.S. citizen parent applying for a Certificate of Citizenship for your minor child."
                  sectionComp={<InfoAboutYou1 />}
                  getType={getType}
                  enumVal={FormN600Progress}
                />
              )}

              {getType === FormN600Progress.infoAboutYouP2 && (
                <RightSectionWrapper
                  title="2. Information About You"
                  subText="Provide information about yourself if you are a person applying for the Certificate of Citizenship. Provide information about your child if you are a U.S. citizen parent applying for a Certificate of Citizenship for your minor child."
                  sectionComp={<InfoAboutYou2 />}
                  getType={getType}
                  enumVal={FormN600Progress}
                />
              )}

              {getType === FormN600Progress.infoAboutYouP3 && (
                <RightSectionWrapper
                  title="2. Information About You"
                  subText="Provide information about yourself if you are a person applying for the Certificate of Citizenship. Provide information about your child if you are a U.S. citizen parent applying for a Certificate of Citizenship for your minor child."
                  sectionComp={<InfoAboutYou3 />}
                  getType={getType}
                  enumVal={FormN600Progress}
                />
              )}

              {getType === FormN600Progress.infoAboutYouP4 && (
                <RightSectionWrapper
                  title="2. Information About You"
                  subText="Provide information about yourself if you are a person applying for the Certificate of Citizenship. Provide information about your child if you are a U.S. citizen parent applying for a Certificate of Citizenship for your minor child."
                  sectionComp={<InfoAboutYou4 />}
                  getType={getType}
                  enumVal={FormN600Progress}
                />
              )}

              {getType === FormN600Progress.bioInfo && (
                <RightSectionWrapper
                  title="3. Biographic Information"
                  subText=""
                  sectionComp={<BiographicInfo />}
                  getType={getType}
                  enumVal={FormN600Progress}
                />
              )}

              {getType === FormN600Progress.infoAboutUSCitizenFatherP1 && (
                <RightSectionWrapper
                  title="4. Information About Your U.S. Citizen Biological Father (or Adoptive Father)"
                  subText="Complete this section if you are claiming citizenship through a U.S. biological father (of adoptive father). Provide information about yourself if you are a U.S. citizen father applying for a Certificate of Citizenship on behalf of your minor biological or adopted child."
                  sectionComp={<USCitizenFatherP1 />}
                  getType={getType}
                  enumVal={FormN600Progress}
                />
              )}

              {getType === FormN600Progress.infoAboutUSCitizenFatherP2 && (
                <RightSectionWrapper
                  title="4. Information About Your U.S. Citizen Biological Father (or Adoptive Father)"
                  subText="Complete this section if you are claiming citizenship through a U.S. biological father (of adoptive father). Provide information about yourself if you are a U.S. citizen father applying for a Certificate of Citizenship on behalf of your minor biological or adopted child."
                  sectionComp={<USCitizenFatherP2 />}
                  getType={getType}
                  enumVal={FormN600Progress}
                />
              )}

              {getType === FormN600Progress.infoAboutUSCitizenMotherP1 && (
                <RightSectionWrapper
                  title="5. Information About Your U.S. Citizen Biological Mother (or Adoptive Mother)"
                  subText="Complete this section if you are claiming citizenship through a U.S. citizen biological mother (or adoptive mother). Provide information about yourself if you are a U.S. citizen mother applying for a Certificate of Citizenship on behalf of your minor biological or adopted child."
                  sectionComp={<USCitizenMotherP1 />}
                  getType={getType}
                  enumVal={FormN600Progress}
                />
              )}

              {getType === FormN600Progress.infoAboutUSCitizenMotherP2 && (
                <RightSectionWrapper
                  title="5. Information About Your U.S. Citizen Biological Mother (or Adoptive Mother)"
                  subText="Complete this section if you are claiming citizenship through a U.S. citizen biological mother (or adoptive mother). Provide information about yourself if you are a U.S. citizen mother applying for a Certificate of Citizenship on behalf of your minor biological or adopted child."
                  sectionComp={<USCitizenMotherP2 />}
                  getType={getType}
                  enumVal={FormN600Progress}
                />
              )}

              {getType === FormN600Progress.physicalPresenceFromBirth && (
                <RightSectionWrapper
                  title="6.  Physical Presence in the United States From Birth Until Filing of Form N-600"
                  subText="Only applicants born outside the United States claiming to have been born U.S. citizens are required to provide all the dates when your U.S. citizen biological father or U.S. citizen biological mother resided in the United States. Include all dates from your birth until the date you file your Form N-600."
                  sectionComp={<PhysicalPresenceInUS />}
                  getType={getType}
                  enumVal={FormN600Progress}
                />
              )}

              {getType === FormN600Progress.infoAboutParentsMilitary && (
                <RightSectionWrapper
                  title="7. Information About Military Service of U. S. Citizen Parents"
                  subText="Complete this only if you are an applicant claiming U.S. citizenship at time of birth abroad."
                  sectionComp={<MilitaryInfo />}
                  getType={getType}
                  enumVal={FormN600Progress}
                />
              )}

              {getType === FormN600Progress.PI && (
                <RightSectionWrapper
                  title="8. Preparer and Interpreter"
                  subText=""
                  sectionComp={<N600Preparer />}
                  getType={getType}
                  enumVal={FormN600Progress}
                />
              )}

              {getType === FormN600Progress.review && (
                <RightSectionWrapper
                  title="9. Review and Pay"
                  subText="Please take a moment to review your answers before submitting your visa application. Ensure that all information is accurate and complete. You can make edits by clicking the 'Edit' button next to each section. Once you are satisfied with your responses, you can proceed to unlock the download by paying."
                  sectionComp={<N600Review />}
                  getType={getType}
                  enumVal={FormN600Progress}
                />
              )}
            </>
          }
          <ChatSystem formName="N600" />
        </Container>
      </Constaint>
    </Wrapper>
  );
};

export default F600;

const Wrapper = styled.div`
  margin: 28px 0;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 48px;
  margin-bottom: 170px;

  @media screen and (min-width: 768px) {
    grid-template-columns: 267px 1fr;
  }
`;
