import React, { FC, useEffect, useState } from "react";
import useScrollVisible from "../../../components/Review/useScrollVisible";
import { useGetDS160Info } from "../../../../../hooks/ds160/usDS160";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import FormBtn from "../../../components/FormBtn";
import ReviewTop from "../../../components/Review/ReviewTop";
import ReviewBtns from "../../../components/Review/ReviewBtns";
import useIsPaid from "../../../../../hooks/useIsPaid";
import { BtnFooterWrapper } from "../../../components/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormDS160Progress } from "../types";
import { usePDF } from "@react-pdf/renderer";
import DownloadPage from "./components/DownloadPage";
import MyDoc from "./components/MyDoc";
import RiskAssesment from "./components/RiskAssesment";
import RiskAssessmentBox from "./components/RiskAssessmentBox";
import { BusinessQueryKey } from "../../../../../api/utils/types";
import DownloadForms from "../../../components/DownloadForms";
import { useSnapshot } from "valtio";
import { userDataStore } from "../../../../../store/userData";
import { CaseHistoryObj } from "../../../../../types/types";
import { FormCategories } from "../../../../../routes/type";

interface Props {
  isToStudy: boolean;
}

const DS160Review: FC<Props> = ({ isToStudy }) => {
  const [searchParams] = useSearchParams();
  const getApplicationId = searchParams.get(BusinessQueryKey.appId) || "";
  const navigate = useNavigate();
  const { isFormPaid } = useIsPaid();
  const { showTop } = useScrollVisible();
  const { data, isFetching, isError } = useGetDS160Info(
    undefined,
    getApplicationId
  );
  const { userData } = useSnapshot(userDataStore);
  const [onDownload, setOnDownload] = useState<CaseHistoryObj>();
  const [instance, updateInstance] = usePDF({
    document: <MyDoc data={data} isToStudy={isToStudy} />,
  });
  const [ds160Risk, setDs160Risk] = useState<string>();

  const onDs160Risk = (params: string) => {
    setDs160Risk(params);
  };

  const onOpenDownload = () => {
    const formType = FormCategories.ds160;
    const tableData = userData?.caseHistory.find(
      (ev) => ev.caseType === formType
    );
    setOnDownload(tableData);
  };

  const onCloseDownload = () => {
    setOnDownload(undefined);
  };

  useEffect(() => {
    updateInstance(<MyDoc data={data} isToStudy={isToStudy} />);
  }, [data, isToStudy, updateInstance]);

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <>
        <div className="flex justify-end gap-4 sm:gap-6 items-center [&>div]:mt-0 flex-col sm:flex-row [&>*]:w-full [&>div>button]:w-full">
          {/* this modal only comes up when the user clicks on the unlock form button from below that has the onPay function initially */}
          <ReviewBtns
            // if the user haven't paid, the form toggles the pricing modal passing in the N400Pricing data
            // onSave={async () => {
            //   if (instance.url) {
            //     createLinkForDownload(instance.url);
            //   }
            // }}
            onSave={onOpenDownload}
            isSaving={instance.loading}
            formType={"DS-160"}
            hasPaid={isFormPaid}
          />

          {instance.blob && getApplicationId && (
            <RiskAssesment blobData={instance.blob} onDs160Risk={onDs160Risk} />
          )}
        </div>
        {/* <ReviewBtns onSave={() => {}} isSaving={false} /> */}
        {/* <ReviewGetStarted title="1. Get Started" data={data?.getStarted} /> */}
        {ds160Risk && <RiskAssessmentBox response={ds160Risk} />}
        <DownloadPage data={data} isToStudy />
        <BtnFooterWrapper>
          <FormBtn
            isLoading={false}
            addBackBtn
            isError={false}
            addContinueBtn={false}
            removeHR
            onBack={
              !isToStudy
                ? () =>
                    navigate(`?type=${FormDS160Progress.securityBackgroundP5}`)
                : undefined
            }
          />
          <ReviewBtns
            // if the user haven't paid, the form toggles the pricing modal passing in the N400Pricing data
            // onSave={() => {
            //   updateInstance(<MyDoc data={data} isToStudy={isToStudy} />);
            // }}
            onSave={onOpenDownload}
            isSaving={instance.loading}
            formType={"DS-160"}
            hasPaid={isFormPaid}
          />
        </BtnFooterWrapper>
        <ReviewTop showTop={showTop} />

        {onDownload && (
          <DownloadForms
            onClose={onCloseDownload}
            caseType={onDownload.caseType}
            filingURL={onDownload.filingURL}
            formDownloadUrl={onDownload.formDownloadUrl}
            g1145URL={onDownload?.g1145URL || ""}
          />
        )}
      </>
    </FormSectionWrapper>
  );
};

export default DS160Review;
