import { FieldArray, Form, Formik } from "formik";
import styled from "styled-components";
import {
  initDate,
  initInput,
  ToSaveDate,
  USDateFormat,
} from "../../components/data";
import FormSectionWrapper from "../../components/FormSectionWrapper";
import { FormWrapper } from "../../components/styles";
import FormBtn from "../../components/FormBtn";
import { N600PhysicalPresencePayload } from "../../../../api/n600/types";
import { Modify } from "../../../../types/types";
import { Dayjs } from "dayjs";
import RadioComp from "../../components/RadioComp";
import { N600RelatesToData } from "./data";
import GroupedSection from "../../components/GroupedSection";
import AddAnotherBtn from "../../components/AddAnotherBtn";
import { toast } from "react-toastify";
import Colors from "../../../../styles/Colors";
import DatePickerField from "../../components/formik fields/DatePickerField";
import {
  useGetN600PhysicalPresence,
  useSaveN600PhysicalPresence,
} from "../../../../hooks/n600/useN600";
import useToNextSection from "../../../../hooks/useToNextSection";
import { N600PresenceInUSValidation } from "./validation";

const initialPhysicalPresencePeriodValues: PhysicalPresencePeriod = {
  fromDate: null,
  toDate: null,
};

export interface PhysicalPresencePeriod {
  fromDate: Dayjs | null; // From (mm/dd/yyyy)
  toDate: Dayjs | null; // To (mm/dd/yyyy)
}

export interface N600PresenceInUSData
  extends Modify<
    Omit<N600PhysicalPresencePayload, "fromDate" | "toDate">,
    {
      physicalPresencePeriodsArray: PhysicalPresencePeriod[];
    }
  > {}

const PhysicalPresenceInUS = () => {
  const { data, isFetching, isError } = useGetN600PhysicalPresence();
  const { mutate, isLoading: isSaving } = useSaveN600PhysicalPresence();

  const { toNextSection } = useToNextSection();

  const savedPhysicalPresencePeriod = () => {
    if (data?.fromDate || data?.toDate) {
      const fromDateData = data?.fromDate
        .split(",")
        .map((ev: any) => ({ fromDate: initDate(ev) }));

      const toDateData = data?.toDate
        ?.split(",")
        .map((ev: any) => ({ toDate: initDate(ev) }));

      return fromDateData.map((ev: any, i: any) => ({
        ...ev,
        ...toDateData[i],
      }));
    } else return [initialPhysicalPresencePeriodValues];
  };

  const onSubmit = (values: N600PresenceInUSData) => {
    const { physicalPresencePeriodsArray, ...rest } = values;

    const payload = {
      ...rest,
      fromDate: physicalPresencePeriodsArray
        .map((ev) => ToSaveDate(ev.fromDate, USDateFormat))
        .join(","),
      toDate: physicalPresencePeriodsArray
        .map((ev) => ToSaveDate(ev.toDate, USDateFormat))
        .join(","),
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValues: N600PresenceInUSData = {
    relatesTo: initInput(data?.relatesTo),

    physicalPresencePeriodsArray: savedPhysicalPresencePeriod(),
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={N600PresenceInUSValidation}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <div>
                  <RadioComp
                    title="Indicate whether this information relates to your U.S. citizen father or mother"
                    name="relatesTo"
                    options={N600RelatesToData}
                  />
                </div>
              </FormWrapper>

              <FieldArray
                name="physicalPresencePeriodsArray"
                render={(arrayHelpers) => (
                  <div className="px-2 py-3 mb-8 border rounded">
                    {formik.values.physicalPresencePeriodsArray.map(
                      (_, index) => (
                        <GroupedSection
                          key={index}
                          index={index}
                          onCancel={() => arrayHelpers.remove(index)}
                        >
                          <div className={`text-[${Colors.Blue00}]`}>
                            {index === 0
                              ? "Last presence in the U.S"
                              : `Entry${index + 1}:`}
                          </div>
                          <FormWrapper>
                            <div>
                              <DatePickerField
                                name={`physicalPresencePeriodsArray[${index}].fromDate`}
                                label="From (mm/dd/yyyy) "
                              />
                            </div>

                            <div>
                              <DatePickerField
                                name={`physicalPresencePeriodsArray[${index}].toDate`}
                                label="To (mm/dd/yyyy)"
                              />
                            </div>
                          </FormWrapper>
                          <div className="-mb-4" />
                        </GroupedSection>
                      )
                    )}
                    <AddAnotherBtn
                      onClick={() => {
                        if (
                          formik.values.physicalPresencePeriodsArray.length ===
                          8
                        ) {
                          toast.error("Limit Reached");
                          return;
                        }
                        arrayHelpers.push(initialPhysicalPresencePeriodValues);
                      }}
                    />
                  </div>
                )}
              />

              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default PhysicalPresenceInUS;

const Wrapper = styled.div``;
