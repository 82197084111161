import * as Yup from "yup";
import { DocTypeOthersValues } from "./data/data";

export const AdditionalDocumentsValidation = Yup.object().shape({
  doc_type: Yup.object().nullable().required("Required"),
  doc_label: Yup.string()
    .trim()
    .when(`doc_type`, ([doc_type], schema) => {
      if (doc_type?.value === DocTypeOthersValues.Others)
        return Yup.string().required("Required").trim();
      return schema;
    }),
});
