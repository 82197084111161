import React, { FC } from "react";
import { useGetN600PayFail } from "../../../../hooks/n600/useN600";
import PayFail from "../../components/PayFail";

interface Props {
  onRetry: () => void;
  isRetrying: boolean;
}

const N600PayFail: FC<Props> = ({ onRetry, isRetrying }) => {
  const { isLoading, isError } = useGetN600PayFail();

  return (
    <PayFail
      onRetry={onRetry}
      isRetrying={isRetrying}
      isLoading={isLoading}
      isError={isError}
    />
  );
};

export default N600PayFail;
