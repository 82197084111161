import React, { FC } from "react";
import { useGetN565PaySuccess } from "../../../../hooks/n565/useN565";
import { useSearchParams } from "react-router-dom";
import PaySuccess from "../../components/PaySuccess";

interface Props {
  formType: string;
}

const N565PaySuccess: FC<Props> = ({ formType }) => {
  const [searchParams] = useSearchParams();
  const payId = searchParams.get("payId") as string;
  const { isLoading, isError } = useGetN565PaySuccess(payId);

  return (
    <PaySuccess isLoading={isLoading} isError={isError} formType={formType} />
  );
};

export default N565PaySuccess;
