import React, { FC } from "react";
import styled from "styled-components";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Colors from "../../../styles/Colors";
import { getEnumIndex } from "./data";
import useIsCompleted from "../../../hooks/useIsCompleted";

interface Props {
  enumVal: any;
  section: string;
}

const FormProgressBar: FC<Props> = ({ enumVal, section }) => {
  const { getIsCompleted } = useIsCompleted();
  const currProgress = getEnumIndex(enumVal, section || "");
  const length = Object.keys(enumVal).length - 1; //review section is not part of the length
  const percentage = getIsCompleted()
    ? 100
    : Math.floor((currProgress / length) * 100);

  return (
    <ProgressFlex>
      {/* @ts-ignore */}
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        strokeWidth={10}
        styles={buildStyles({
          textColor: Colors.Blue00,
          pathColor: "#0076E9CC",
          trailColor: "#0076E91A",
          textSize: "30px",
        })}
        minValue={1}
      />
    </ProgressFlex>
  );
};

export default FormProgressBar;

const ProgressFlex = styled.div`
  width: 40px;
`;
