import React from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import { FaInfoCircle } from "react-icons/fa";
import { riskyCheckBoxName } from "./data";
import CheckBoxField from "./formik fields/CheckBoxField";

export enum TooltipType {
  info = "info",
  risky = "risky",
  deadly = "deadly",
}

export interface TooltipProps {
  body?: JSX.Element | string;
  heading?: string;
  size?: "sm" | "md" | "base" | "lg";
  type?: TooltipType;
  isReversed?: boolean;
  checkLabel?: string;
}

export interface Props {
  toolTipProps: TooltipProps;
  parentName?: string;
}

const Tooltip: React.FC<Props> = ({ toolTipProps, parentName }) => {
  const getHeader = () => {
    if (toolTipProps.type === TooltipType.info) {
      return "Important Information!";
    }
    if (toolTipProps.type === TooltipType.risky) {
      return "Please Note!";
    }
    if (toolTipProps.type === TooltipType.deadly) {
      return "We found a problem!";
    } else return "Important Information!";
  };

  const defaultRiskyText =
    "The option you've chosen does not match your eligibility data";

  return (
    <TipIcon
      type={toolTipProps.type || "info"}
      size={toolTipProps.size || "md"}
    >
      <FaInfoCircle />
      <TooltipContent isReversed={toolTipProps.isReversed}>
        <h2>{toolTipProps.heading || getHeader()}</h2>
        <p>
          {toolTipProps.body ||
            (toolTipProps.type === TooltipType.risky ? defaultRiskyText : "")}
        </p>

        {toolTipProps.type === TooltipType.risky && (
          <ConsultAttorney>
            <CheckBoxField
              name={`${parentName}${riskyCheckBoxName}`}
              label={
                toolTipProps.checkLabel ||
                "I acknowledge the risk of proceeding forward."
              }
            />
          </ConsultAttorney>
        )}
      </TooltipContent>
    </TipIcon>
  );
};

export default Tooltip;

const TipIcon = styled.div<{
  type: "info" | "risky" | "deadly";
  size: "sm" | "md" | "base" | "lg" | undefined;
}>`
  /* SIZES */
  width: 32px; // BASE
  ${({ size }) => size === "lg" && "width: 42px;"}
  ${({ size }) => size === "md" && "width: 24px;"}
  ${({ size }) => size === "sm" && "width: 20px;"}

  aspect-ratio: 1/1;
  border-radius: 50% 50% 50% 15%;

  /* COLORS || TYPES */
  background-color: ${Colors.AmberFE}; // INFO
  ${({ type }) => type === "risky" && `background-color: ${Colors.AmberFF};`}
  ${({ type }) => type === "deadly" && `background-color: ${Colors.RedC3};`}

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  position: relative;

  /* transition: 0.2s ease-in all; */

  z-index: 50;

  &:hover > div {
    /* display: block; */
    visibility: visible;
    opacity: 1;

    /* COLORS || TYPES */
    /* background-color: ${Colors.Blue00}; // INFO */
    background-color: #ffedc2;
    ${({ type }) => type === "risky" && `background-color: #ffe3a1;`}
    ${({ type }) => type === "deadly" && `background-color: #ff9999;`}
  }

  & > svg {
    color: #fff;
  }
`;

interface TooltipContentProps {
  isReversed?: boolean;
}

const TooltipContent = styled.div<TooltipContentProps>`
  padding: 1rem 2rem;
  border-radius: 8px;
  /* margin-left: 10%; */
  position: absolute;
  right: ${({ isReversed }) => (isReversed ? "unset" : "110%")};
  left: ${({ isReversed }) => (isReversed ? "110%" : "unset")};
  bottom: 0;

  min-width: 350px;
  /* background-color: white; */
  border: 1px solid ${Colors.GreyA7};
  color: ${Colors.Grey8B};

  /* display: none; */
  visibility: hidden;
  opacity: 0;
  transition: 0.2s ease-out;

  & > h2 {
    font-weight: bold;
    color: ${Colors.Black31};
    font-size: 0.9rem;
    text-transform: uppercase;

    margin-bottom: 8px;
  }

  & > p {
    font-weight: 400;
    color: #000;
  }
`;

const ConsultAttorney = styled.div`
  margin-top: 1rem;

  & > div > input {
    accent-color: #000 !important;
  }

  & > div > span {
    color: #000;
  }
`;
