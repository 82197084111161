export enum FormN565Progress {
  infoFromCurrentDoc = "n565 information from current certificate or declaration",
  currentInfoAboutYou = "n565 current information about you",
  typeOfApplication = "n565 type of application",
  PI = "n565 Preparer and interpreter",
  review = "n565-review",
}

export enum N565ApplicationPreparerValues {
  iAmTheApplicant = "I am the applicant",
  iAmApreparer = "I am not an attorney but i am a preparer",
  iAmTheAttorney = "I am an attorney",
}
export enum N565ReceiveInterpreterHelpValues {
  yes = "yes",
  no = "no",
}
