import React, { FC } from "react";
import InputField from "../../components/formik fields/InputField";
import SelectField from "../../components/formik fields/SelectField";
import { ApartmentSuiteFloorData, isZipCode } from "../../components/data";
import { FormikProps } from "formik";
import { AddressHistoryData } from "./AddressHistory";
import useCountries from "../../components/useCountries";
import useStatesByCountry from "../../components/useStatesByCountry";
import { FormWrapper } from "../../components/styles";
import { N400AddressHistoryObj } from "../../../../api/n400/types";

interface Props {
  formik: FormikProps<AddressHistoryData>;
  data?: N400AddressHistoryObj;
}

const AddressHistoryMail: FC<Props> = ({ formik, data }) => {
  const {
    formattedCountryData: mailCountryData,
    countryLoading: mailCountryLoading,
  } = useCountries();

  const {
    formattedStateData: mailStateData,
    stateLoading: mailStateLoading,
    setCountry: mailSetCountry,
    selectedCountry: mailSelectedCountry,
  } = useStatesByCountry(data?.countryMail);

  return (
    <>
      <div className="mb-8">
        <FormWrapper>
          <div>
            <InputField
              label="In Care of Name"
              coloredLabel=" (if any)"
              placeholder=""
              name={`inCareOfNameMail`}
            />
          </div>
          <div>
            <InputField
              label="Street Number and Name"
              placeholder=""
              name={`streetNumberAndNameMail`}
            />
          </div>
        </FormWrapper>

        <FormWrapper>
          <div>
            <SelectField
              name="countryMail"
              label="Country"
              placeholder="-Select-"
              options={mailCountryData}
              isLoading={mailCountryLoading}
              onChange={(value) => {
                mailSetCountry(value.label);
                formik.setFieldValue("stateOrTerritoryMail", null);
                formik.setFieldValue(`addressCodeMail`, "");
              }}
            />
          </div>
          <div>
            <SelectField
              name={`stateOrTerritoryMail`}
              label="State"
              placeholder="-Select-"
              options={mailStateData}
              isLoading={mailStateLoading}
              isDisabled={!mailSelectedCountry}
            />
          </div>
        </FormWrapper>

        <FormWrapper>
          <div>
            <InputField
              label="City or Town"
              placeholder=""
              name={`cityOrTownMail`}
            />
          </div>
          <div>
            <InputField
              label={`${
                isZipCode(formik.values.countryMail?.value) ? "Zip" : "Postal"
              } Code`}
              placeholder=""
              name={`addressCodeMail`}
            />
          </div>
        </FormWrapper>

        <FormWrapper>
          <InputField
            label="Province"
            coloredLabel="(If any)"
            placeholder=""
            name={`provinceMail`}
          />

          <div></div>
        </FormWrapper>

        <FormWrapper>
          <SelectField
            label="Is there an apartment, suite or floor?"
            coloredLabel="(If any)"
            name={`apartmentSuiteOrFloorMail`}
            options={ApartmentSuiteFloorData}
          />

          <div>
            {formik.values.apartmentSuiteOrFloorMail?.value && (
              <InputField
                label={`${formik.values.apartmentSuiteOrFloorMail.value} Address number`}
                placeholder=""
                name={`addressNumberMail`}
              />
            )}
          </div>
        </FormWrapper>
      </div>
    </>
  );
};

export default AddressHistoryMail;
