import React from "react";
import Hero from "./components/Hero";
import Steps from "./components/Steps";
import FAQ from "./components/FAQ";
import Contact from "./components/Contact";

const Eligibility = () => {
  return (
    <>
      <Hero />
      <Steps />
      <FAQ />
      <Contact />
    </>
  );
};

export default Eligibility;
