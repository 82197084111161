import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Overlay from "../checkEligibilty/components/modals/Overlay";
import { IoChatboxEllipses } from "react-icons/io5";
import { WebPaths } from "../../../routes/data";
import Colors from "../../../styles/Colors";
import { useNavigate } from "react-router-dom";

interface PopUpProps {
  isFormCompleted: boolean;
}

const PopUp: React.FC<PopUpProps> = ({ isFormCompleted }) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [popupCount, setPopupCount] = useState<number>(0);
  const navigate = useNavigate();
  const hasGivenFeedback = localStorage.getItem("hasGivenFeedback");

  useEffect(() => {
    if (isFormCompleted && !hasGivenFeedback) {
      // Determine if popup should be shown based on popupCount
      if (popupCount < 3) {
        const shouldShowPopup = popupCount === 0 || Math.random() < 0.7; // Guaranteed first time, random second time
        if (shouldShowPopup) {
          setShowPopup(true);
          setPopupCount(popupCount + 1);
        }
      }
    } else {
      // Reset popup count if isFormCompleted becomes false
      setPopupCount(0);
    }
  }, [isFormCompleted, showPopup, popupCount, hasGivenFeedback]);

  const onClose = () => {
    setShowPopup(false);
  };

  return (
    <Wrapper>
      {isFormCompleted && showPopup && (
        <Overlay onClose={() => {}}>
          <div
            className="rounded-2xl border border-blue-100 bg-white p-4 shadow-lg sm:p-6 lg:p-8 max-w-[550px]"
            role="alert"
          >
            <div className="flex items-center gap-4">
              <span className="shrink-0 rounded-full bg-blue-400 p-2 text-white">
                <IoChatboxEllipses />
              </span>

              <p className="font-medium sm:text-lg">Help us improve!</p>
            </div>

            <p className="mt-4 text-gray-500">
              Give a review about your experience so far, help us improve our
              services to serve you better!
            </p>

            <div className="mt-6 sm:flex sm:gap-4 items-center">
              <button
                className={`inline-block w-full rounded-lg bg-[${Colors.Blue00}] hover:bg-[${Colors.Blue02}] duration-300 px-5 py-3 text-center text-sm font-semibold text-white sm:w-auto`}
                onClick={() => navigate(WebPaths.Feedback)}
              >
                Give feedback
              </button>

              <button
                onClick={onClose}
                className="inline-block w-full rounded-lg bg-gray-50 duration-200 hover:bg-gray-100 px-5 py-3 text-center text-sm font-semibold text-gray-500 sm:w-auto "
              >
                Remind me later
              </button>
            </div>
          </div>
        </Overlay>
      )}
    </Wrapper>
  );
};

export default PopUp;

const Wrapper = styled.div``;
