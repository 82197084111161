import { Variants } from "framer-motion";

export const HeroImagesVariant1: Variants = {
  initial: {
    height: "380px",
  },
  animate: {
    height: "170px",
    transition: {
      duration: 2,
      repeat: Infinity,
      repeatType: "reverse",
      repeatDelay: 3,
      ease: "easeInOut",
    },
  },
};

export const HeroImagesVariant2: Variants = {
  initial: {
    height: "170px",
  },
  animate: {
    height: "380px",
    transition: {
      duration: 2,
      repeat: Infinity,
      repeatType: "reverse",
      repeatDelay: 3,
      ease: "easeInOut",
    },
  },
};

export const LeftHeroVariant: Variants = {
  initial: {
    x: -500,
    y: -100,
    rotate: -10,
    opacity: 0,
  },
  animate: {
    x: 0,
    y: 0,
    rotate: 0,
    opacity: 1,
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

export const RightHeroVariant: Variants = {
  initial: {
    x: 500,
    y: -100,
    rotate: -10,
    opacity: 0,
  },
  animate: {
    x: 0,
    y: 0,
    rotate: 0,
    opacity: 1,
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

export const staggerContainer: Variants = {
  initial: {},
  animate: (i = 1) => ({
    transition: {
      staggerChildren: i * 0.5,
      delayChildren: 0.2,
    },
  }),
};

export const listItemContainerVariant = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: { staggerChildren: 0.5 },
  },
};

export const listItemVariant = {
  hidden: { opacity: 0, scale: 0.1 },
  show: {
    opacity: 1,
    scale: 1,
    transition: { duration: 0.5, ease: "easeOut" },
  },
};

export const TitleVariant = {
  initial: { opacity: 0, scale: 0.8 },
  animate: {
    transition: { duration: 0.5, ease: "easeOut" },
    opacity: [0, 0, 0, 1, 1],
    scale: 1,
  },
};

export const AboutTextVariant: Variants = {
  initial: {
    y: 100,
    opacity: 0,
    scale: 0.8,
    x: "-50%",
  },
  animate: {
    y: 0,
    opacity: 1,
    scale: 1,
    x: "-50%",
    transition: {
      duration: 0.5,
      ease: "easeOut",
    },
  },
};
