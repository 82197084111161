import React, { FC } from "react";
import styled from "styled-components";
import { MdCancel } from "react-icons/md";
import Colors from "../../../styles/Colors";
import Spinner from "../../../utils/Spinner";
import PayWrapper from "./PayWrapper";
import { useSearchParams } from "react-router-dom";

interface Props {
  onRetry: () => void;
  isRetrying: boolean;
  isLoading: boolean;
  isError: boolean;
}

const PayFail: FC<Props> = ({ onRetry, isRetrying, isLoading, isError }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const removeAllSearchParams = () => {
    const failParam = searchParams.get("fail");

    if (failParam) {
      searchParams.delete("fail");
    }

    setSearchParams(searchParams);
  };

  const onModalClose = () => {
    removeAllSearchParams();
  };

  return (
    <PayWrapper
      onModalClose={onModalClose}
      isError={isError}
      isLoading={isLoading}
    >
      <>
        <MainIcon>
          <MdCancel />
        </MainIcon>
        <ContentWrapper>
          <TextBold>😓 Oops! Something went wrong. 😓</TextBold>
          <Text>
            We're sorry, but it seems that your payment did not go through
            successfully. Please try again.
          </Text>

          <TextBold>
            If the issue persists, please contact our{" "}
            <BlueColored>support team</BlueColored> for assistance. We're here
            to help!
          </TextBold>

          <CTA onClick={onRetry} disabled={isRetrying}>
            {isRetrying && <Spinner color={Colors.White} size={20} />}
            TRY AGAIN
          </CTA>
        </ContentWrapper>
      </>
    </PayWrapper>
  );
};

export default PayFail;

const MainIcon = styled.div`
  width: 108px;
  height: 108px;
  margin-bottom: 16px;

  & > svg,
  & > img {
    width: 100%;
    height: 100%;
    fill: ${Colors.RedC3};
  }
`;

const ContentWrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Text = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
`;

const TextBold = styled(Text)`
  font-weight: bold;
`;

const BlueColored = styled.span`
  color: ${Colors.Blue00};
`;

const Btn = styled.button`
  width: max-content;
  height: 46px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 24px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
  white-space: nowrap;
  text-transform: uppercase;
  margin: auto;

  & > svg {
    width: 20px;
    height: 20px;
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const CTA = styled(Btn)`
  background: ${Colors.Blue00};
  color: ${Colors.WhiteF9};
  padding: 18.5px 47px;

  &:hover {
    background: #022e58;
    color: ${Colors.WhiteF9};
    transition: 0.3s ease-in-out;
  }
`;
