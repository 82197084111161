import { useEffect, useState } from "react";

const useScrollVisible = () => {
  const [showTop, setShowTop] = useState(false);

  useEffect(() => {
    const onScrollVisible = () => {
      window.scrollY > 300 ? setShowTop(true) : setShowTop(false);
    };

    window.addEventListener("scroll", onScrollVisible);

    return () => {
      window.removeEventListener("scroll", onScrollVisible);
    };
  }, []);
  return { showTop };
};

export default useScrollVisible;
