import React, { FC } from "react";
import styled from "styled-components";
import { SaveBtn } from "../styles";
import Spinner from "../../../../utils/Spinner";
import Colors from "../../../../styles/Colors";
import { FiDownload } from "react-icons/fi";

interface Props {
  onSave: () => void;
  isSaving: boolean;
  formType: string; //remove optional later
  hasPaid: boolean | undefined; //remove optional later
}

const ReviewBtns: FC<Props> = ({ onSave, isSaving, formType, hasPaid }) => {
  return (
    <BtnWrapper>
      <SaveBtn onClick={onSave} disabled={isSaving}>
        {hasPaid ? `Download ${formType} form` : `Unlock ${formType} Download`}
        {isSaving ? (
          <Spinner color={Colors.Blue00} size={20} />
        ) : (
          <FiDownload />
        )}
      </SaveBtn>
    </BtnWrapper>
  );
};

export default ReviewBtns;

const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -16px;
`;
