import dayjs from "dayjs";
import { DataItemsProps } from "../../../../../components/type";
import { GetBusinessUsersObj } from "../../../../../../../api/business/users/types";
import UsersAction from "./UsersAction";

export const UsersTableData = [
  {
    columnName: "Name",
    columnKey: "name",
  },
  {
    columnName: "Email",
    columnKey: "email",
  },
  {
    columnName: "Date Created",
    columnKey: "createdAt",
    customComp: (data: DataItemsProps) => {
      const temp = data as GetBusinessUsersObj;
      return temp.createdAt
        ? dayjs(temp.createdAt.split(" ")[0]).format("DD MMM, YYYY")
        : "--";
    },
  },
  {
    columnName: "Action",
    customComp: (data: DataItemsProps, isNearEnd: boolean) => (
      <UsersAction data={data as GetBusinessUsersObj} />
    ),
    hideOnDetails: true,
  },
];
