// import Contraints from "./components/Contraints";
import { useEffect } from "react";
import useIsLoggedIn from "../../../hooks/useIsLoggedIn";
import Login from "./components/Login";
import { useNavigate } from "react-router-dom";
import { WebPaths } from "../../../routes/data";

const Auth = () => {
  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();

  useEffect(() => {
    if (isLoggedIn) navigate(WebPaths.Profile);
  }, [isLoggedIn, navigate]);

  return <Login />;
};
export default Auth;
