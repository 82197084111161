import TableSection from "../../../../components/table/TableSection";
import { RoleOptions, StaffTableData } from "./components/data";
import { DataItemsProps, QueriesKey } from "../../../../components/type";
import StaffAction from "./components/StaffAction";
import { StaffRes } from "../../../../../../api/business/staff/types";
import { useGetAllStaff } from "../../../../../../hooks/business/useStaff";
import styled from "styled-components";
import { ModuleTitle } from "../../../../components/styles";
import CustomButton from "../../../../components/CustomButton";
import { FaPlus } from "react-icons/fa6";
import { useState } from "react";
import TableSearch from "../../../../components/table/TableSearch";
import TableDropdown from "../../../../components/table/TableDropdown";
import StaffForm from "./components/StaffForm";

const StaffManagement = () => {
  const { data, isLoading, isError } = useGetAllStaff();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <Wrapper>
      <Header>Staff Management</Header>

      <ButtonWrapper>
        <CustomButton
          name={"ADD NEW STAFF"}
          icon={<FaPlus />}
          onClick={() => setModalOpen(true)}
          type={"button"}
        />
      </ButtonWrapper>

      <MiddleWrapper>
        <TableSearch placeholder="Search staff" />

        <MiniFlex>
          <TableDropdown
            width="200px"
            name="Role"
            option={RoleOptions}
            queryName={QueriesKey.status}
          />
        </MiniFlex>
      </MiddleWrapper>

      <StaffForm
        isOpen={modalOpen}
        data={null}
        onClose={() => setModalOpen(false)}
      />

      <TableSection
        dataItems={data}
        tableData={StaffTableData}
        actionComp={(data: DataItemsProps) => (
          <StaffAction data={data as StaffRes} />
        )}
        isError={isError}
        isLoading={isLoading}
      />
    </Wrapper>
  );
};

export default StaffManagement;

const Wrapper = styled.div``;

const Header = styled(ModuleTitle)`
  margin-bottom: 20px;
`;

const MiddleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MiniFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 1.24rem;
`;
