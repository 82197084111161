import styled from "styled-components";
import Colors from "../../../../styles/Colors";
import { mediaObj } from "../../../../styles/Media";

export const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: flex-end;
`;

const Btn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 8px;
  height: 46px;

  :disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

export const BackBtn = styled(Btn)`
  background-color: ${Colors.DarkB3};
  color: ${Colors.Black31};
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
`;

export const SaveBtn = styled(Btn)`
  border: 1px solid ${Colors.Blue00};
  color: ${Colors.Blue00};
`;

export const NextBtn = styled(Btn)`
  background-color: ${Colors.Blue00};
  color: ${Colors.White};
`;

export const HR = styled.div`
  height: 4px;
  width: 100%;
  background-color: ${Colors.Blue00};
  opacity: 10%;
  margin-top: 43px;
  margin-bottom: 40px;
`;

export const Label = styled.h5`
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const RadioLabel = styled.div`
  margin-bottom: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: ${Colors.Black31};
  font-weight: bold;

  &.required:after {
    content: "*";
    font-size: 24px;
    line-height: 0;
    vertical-align: middle;
    color: #d90b2c;
  }

  ${mediaObj.mobile} {
    font-size: 14px;
  }
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  /* margin-bottom: 24px; */

  input {
    accent-color: red;
  }
`;

// export const RadioWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 0.8rem;
//   /* margin-bottom: 24px; */

//   input {
//     accent-color: red;
//   }
// `;

export const RadioFlex = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
`;

export const CheckBoxFlex = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 16px;
`;

export const Colored = styled.span`
  color: #da5002;
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Title = styled.div`
  color: ${Colors.Black31};
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const Sub = styled.div`
  color: ${Colors.Black31};
  margin-top: 19px;
  margin-bottom: 30px;
  font-weight: 300;
  font-size: 0.875rem;
`;

export const FormWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 38px;
  margin-bottom: 30px;
  & > div {
    flex: 1;
  }
`;

export const Wrapper = styled.div``;

export const Note = styled.div`
  color: ${Colors.Black31};
  font-weight: 300;
  margin-bottom: 30px;
  font-size: 0.875rem;
`;

export const Skip = styled.span`
  font-weight: normal;
`;

export const Line = styled(HR)`
  margin: 0;
  margin-bottom: 16px;
`;

export const Header = styled.div`
  color: ${Colors.Blue00};
  padding-bottom: 19px;
`;

export const Para = styled.p`
  color: ${Colors.Black31};
  margin-bottom: 32px;
`;

export const DashboardTitle = styled.div`
  color: ${Colors.Black31};
  font-weight: bold;
  font-size: 1.25rem;
`;

export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
`;

export const BoxWrapper = styled.div`
  min-height: 246px;
  height: 100%;
  background-color: ${Colors.WhiteF9};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

export const SettingsWrapper = styled.div`
  min-width: 500px;
`;

export const SettingsTitle = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: ${Colors.Black31};
  margin-bottom: 1.625rem;
`;
