import React, { useEffect, useState } from "react";
import Hero from "./components/Hero";
// import Offers from "./components/Offers";
// import Steps from "./components/Steps";
// import Process from "./components/Process";
// import About from "./components/About";
// import Testmonial from "./components/Testmonial";
import ForIndividual from "./components/ForIndividual";
import ForBusinesses from "./components/ForBusinesses";
import FiBiSection from "./components/FiBiSection";
import FiBiPopUp from "../components/FiBiPopUp";
// import Consultation from "./components/Consultation";
// import News from "./components/News";

const LandingPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Check if the user has already dismissed the modal in this session
    const hasSeenModal = sessionStorage.getItem("hasSeenModal");

    if (!hasSeenModal) {
      setIsModalOpen(true);
      sessionStorage.setItem("hasSeenModal", "true"); // Set flag for this session
    }
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Hero />
      <ForIndividual />
      <ForBusinesses />
      <FiBiSection />
      {/* <Offers /> */}
      {/* <Steps /> */}
      {/* <Process /> */}
      {/* <About /> */}
      {/* <Testmonial /> */}
      {/* <Consultation /> */}
      {/* <News /> */}
      <FiBiPopUp isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  );
};

export default LandingPage;
