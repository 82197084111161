import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ds160QueryKeys } from "./types";
import {
  getDS160AddressAndPhone,
  getDS160Family,
  getDS160Passport,
  getDS160PersonalInfo,
  getDS160PersonalInfo2,
  getDS160PreviousUSTravel,
  getDS160Travel,
  getDS160TravelCompanions,
  getDS160USContact,
  saveDS160AddressAndPhone,
  saveDS160Family,
  saveDS160Passport,
  getDS160WETPresent,
  getDS160WETPrevious,
  getDS160WETAdditional,
  getDS160SEVAdditionalContact,
  getDS160Sives,
  getSecurityBackgroundP1,
  getSecurityBackgroundP2,
  getSecurityBackgroundP3,
  getSecurityBackgroundP4,
  getSecurityBackgroundP5,
  saveDS160PersonalInfo,
  saveDS160PersonalInfo2,
  saveDS160PreviousUSTravel,
  saveDS160Travel,
  saveDS160TravelCompanions,
  saveDS160USContact,
  saveDS160WETPresent,
  saveDS160WETPrevious,
  saveDS160WETAdditional,
  saveDS160SEVAdditionalContact,
  saveDS160Sives,
  saveDS160SecurityBackgroundP1,
  saveDS160SecurityBackgroundP2,
  saveDS160SecurityBackgroundP3,
  saveDS160SecurityBackgroundP4,
  saveDS160SecurityBackgroundP5,
  getDS160Info,
  saveDS160GetStarted,
  getDS160GetStarted,
  saveDS160Eligibility,
  getDS160Eligibility,
  payDS160,
  getDS160Form,
  getDS160PaySuccess,
  getDS160PayFail,
  uploadDS160Files,
  getDS160Files,
  updateDS160Files,
  saveDS160VistorEmail,
  getDS160Trigger,
  getDS160MaritalStatus,
  saveDS160MaritalStatus,
} from "../../api/ds160";
import { downloadPdf, parseError } from "../../utils";
import { utilsQueryKeys } from "../utils/types";
import {
  assessmentDs160AddressPhone,
  assessmentDs160Family,
  assessmentDs160Passport,
  assessmentDs160Security,
  assessmentDs160Sevis,
  assessmentDs160Travel,
  assessmentDs160USContact,
  assessmentDs160WET,
  getChatGptDs160Assessment,
  getDS160AIData,
  getDs160Assessment,
  riskAssessmentPersonalInfo,
  saveDs160Assessment,
} from "../../api/ai";

export const useSaveDS1600GetStarted = () => {
  return useMutation(saveDS160GetStarted, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160GetStarted = () => {
  return useQuery([ds160QueryKeys.getDS160GetStarted], getDS160GetStarted, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useSaveDS160PersonalInfo = () => {
  return useMutation(saveDS160PersonalInfo, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160PersonalInfo = (applicationId?: string) => {
  return useQuery(
    [ds160QueryKeys.getDS160PersonalInfo, applicationId],
    () => getDS160PersonalInfo(applicationId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160PersonalInfo2 = () => {
  return useMutation(saveDS160PersonalInfo2, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160PersonalInfo2 = (applicationId?: string) => {
  return useQuery(
    [ds160QueryKeys.getDS160PersonalInfo2, applicationId],
    () => getDS160PersonalInfo2(applicationId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160Travel = () => {
  return useMutation(saveDS160Travel, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160Travel = (applicationId?: string) => {
  return useQuery(
    [ds160QueryKeys.getDS160Travel, applicationId],
    () => getDS160Travel(applicationId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160TravelCompanions = () => {
  return useMutation(saveDS160TravelCompanions, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160TravelCompanions = (applicationId?: string) => {
  return useQuery(
    [ds160QueryKeys.getDS160TravelCompanions, applicationId],
    () => getDS160TravelCompanions(applicationId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160PreviousUSTravel = () => {
  return useMutation(saveDS160PreviousUSTravel, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160PreviousUSTravel = (applicationId?: string) => {
  return useQuery(
    [ds160QueryKeys.getDS160PreviousUSTravel, applicationId],
    () => getDS160PreviousUSTravel(applicationId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160AddressAndPhone = () => {
  return useMutation(saveDS160AddressAndPhone, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160AddressAndPhone = (applicationId?: string) => {
  return useQuery(
    [ds160QueryKeys.getDS160AddressAndPhone, applicationId],
    () => getDS160AddressAndPhone(applicationId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160Passport = () => {
  return useMutation(saveDS160Passport, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160Passport = (applicationId?: string) => {
  return useQuery(
    [ds160QueryKeys.getDS160Passport, applicationId],
    () => getDS160Passport(applicationId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160USContact = () => {
  return useMutation(saveDS160USContact, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160USContact = (applicationId?: string) => {
  return useQuery(
    [ds160QueryKeys.getDS160USContact, applicationId],
    () => getDS160USContact(applicationId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160Family = () => {
  return useMutation(saveDS160Family, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160Family = (applicationId?: string) => {
  return useQuery(
    [ds160QueryKeys.getDS160Family, applicationId],
    () => getDS160Family(applicationId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160WETPresent = () => {
  return useMutation(saveDS160WETPresent, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160WETPresent = (applicationId?: string) => {
  return useQuery(
    [ds160QueryKeys.getDS160WETPresent, applicationId],
    () => getDS160WETPresent(applicationId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160WETPrevious = () => {
  return useMutation(saveDS160WETPrevious, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160WETPrevious = (applicationId?: string) => {
  return useQuery(
    [ds160QueryKeys.getDS160WETPrevious, applicationId],
    () => getDS160WETPrevious(applicationId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160SEVAdditionalContact = () => {
  return useMutation(saveDS160SEVAdditionalContact, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160SEVAdditionalContact = (applicationId?: string) => {
  return useQuery(
    [ds160QueryKeys.getDS160SEVAdditionalContact, applicationId],
    () => getDS160SEVAdditionalContact(applicationId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160Sives = () => {
  return useMutation(saveDS160Sives, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160Sives = (applicationId?: string) => {
  return useQuery(
    [ds160QueryKeys.getDS160Sives, applicationId],
    () => getDS160Sives(applicationId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160WETAdditional = () => {
  return useMutation(saveDS160WETAdditional, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160WETAdditional = (applicationId?: string) => {
  return useQuery(
    [ds160QueryKeys.getDS160WETAdditional, applicationId],
    () => getDS160WETAdditional(applicationId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160SecurityBackgroundP1 = () => {
  return useMutation(saveDS160SecurityBackgroundP1, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160SecurityBackgroundP1 = (applicationId?: string) => {
  return useQuery(
    [ds160QueryKeys.getSecurityBackgroundP1, applicationId],
    () => getSecurityBackgroundP1(applicationId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160SecurityBackgroundP2 = () => {
  return useMutation(saveDS160SecurityBackgroundP2, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160SecurityBackgroundP2 = (applicationId?: string) => {
  return useQuery(
    [ds160QueryKeys.getSecurityBackgroundP2, applicationId],
    () => getSecurityBackgroundP2(applicationId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160SecurityBackgroundP3 = () => {
  return useMutation(saveDS160SecurityBackgroundP3, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160SecurityBackgroundP3 = (applicationId?: string) => {
  return useQuery(
    [ds160QueryKeys.getSecurityBackgroundP3, applicationId],
    () => getSecurityBackgroundP3(applicationId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160SecurityBackgroundP4 = () => {
  return useMutation(saveDS160SecurityBackgroundP4, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160SecurityBackgroundP4 = (applicationId?: string) => {
  return useQuery(
    [ds160QueryKeys.getSecurityBackgroundP4, applicationId],
    () => getSecurityBackgroundP4(applicationId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160SecurityBackgroundP5 = () => {
  return useMutation(saveDS160SecurityBackgroundP5, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160SecurityBackgroundP5 = (applicationId?: string) => {
  return useQuery(
    [ds160QueryKeys.getSecurityBackgroundP5, applicationId],
    () => getSecurityBackgroundP5(applicationId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetDS160Info = (
  isEnabled?: boolean,
  applicationId?: string
) => {
  return useQuery(
    [ds160QueryKeys.getDs160Info, applicationId],
    () => getDS160Info(applicationId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      enabled: isEnabled,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160Eligibility = () => {
  const queryClient = useQueryClient();

  return useMutation(saveDS160Eligibility, {
    onError: (error) => parseError(error),
    onSuccess: () =>
      queryClient.invalidateQueries([utilsQueryKeys.getUserData]),
  });
};

export const useGetDS160Eligibility = (
  isEnabled?: boolean,
  applicationId?: string
) => {
  return useQuery(
    [ds160QueryKeys.getDS160Eligibility, applicationId],
    () => getDS160Eligibility(applicationId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      enabled: isEnabled,
      refetchOnWindowFocus: false,
    }
  );
};

export const usePayDS160 = () => {
  return useMutation(payDS160, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160Form = (
  isEnabled?: boolean,
  applicationId?: string
) => {
  return useQuery(
    [ds160QueryKeys.getDS160Form, applicationId],
    () => getDS160Form(applicationId),
    {
      enabled: isEnabled,
      onError: (error) => parseError(error),
      onSuccess: (data) => {
        downloadPdf(data.data.fileBuffer.data, `ds160-form.pdf`);
      },
      // select: (data) => data.data.fileBuffer.data,
      // refetchOnWindowFocus: false,
    }
  );
};

export const useGetDS160PaySuccess = (
  payId: string,
  applicationId?: string
) => {
  const queryClient = useQueryClient();

  return useQuery(
    [ds160QueryKeys.getDS160PaySuccess, payId, applicationId],
    () => getDS160PaySuccess(payId, applicationId),
    {
      enabled: !!payId,
      onSuccess: () =>
        queryClient.invalidateQueries([utilsQueryKeys.getUserData]),
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetDS160PayFail = (applicationId?: string) => {
  const queryClient = useQueryClient();

  return useQuery(
    [ds160QueryKeys.getDS160PayFail, applicationId],
    () => getDS160PayFail(applicationId),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([utilsQueryKeys.getUserData]),
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useUploadDS160Files = () => {
  const queryClient = useQueryClient();

  return useMutation(uploadDS160Files, {
    onError: (error) => parseError(error),
    onSuccess: () =>
      queryClient.invalidateQueries([ds160QueryKeys.getDS160Files]),
  });
};

export const useGetDS160Files = () => {
  return useQuery([ds160QueryKeys.getDS160Files], getDS160Files, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    // refetchOnWindowFocus: false,
  });
};

export const useUpdateDS160Files = () => {
  const queryClient = useQueryClient();

  return useMutation(updateDS160Files, {
    onError: (error) => parseError(error),
    onSuccess: () =>
      queryClient.invalidateQueries([ds160QueryKeys.getDS160Files]),
  });
};

export const useSaveDS160VistorEmail = () => {
  return useMutation(saveDS160VistorEmail, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160Trigger = (
  isEnabled: boolean,
  applicationId?: string
) => {
  return useQuery(
    [ds160QueryKeys.getDS160Trigger, applicationId],
    () => getDS160Trigger(applicationId),
    {
      enabled: isEnabled,
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetDS160MaritalStatus = (applicationId?: string) => {
  return useQuery(
    [ds160QueryKeys.getDS160MaritalStatus, applicationId],
    () => getDS160MaritalStatus(applicationId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveDS160MaritalStatus = () => {
  return useMutation(saveDS160MaritalStatus, {
    onError: (error) => parseError(error),
  });
};

export const useRiskAssessmentPersonalInfo = () => {
  return useMutation(riskAssessmentPersonalInfo, {
    onError: (error) => parseError(error),
  });
};

export const useGetDS160AIData = (applicationId?: string) => {
  return useQuery(
    [ds160QueryKeys.getDS160AIData, applicationId],
    () => getDS160AIData(applicationId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useAssessmentDs160Travel = () => {
  return useMutation(assessmentDs160Travel, {
    onError: (error) => parseError(error),
  });
};

export const useAssessmentDs160AddressPhone = () => {
  return useMutation(assessmentDs160AddressPhone, {
    onError: (error) => parseError(error),
  });
};

export const useAssessmentDs160Passport = () => {
  return useMutation(assessmentDs160Passport, {
    onError: (error) => parseError(error),
  });
};

export const useAssessmentDs160USContact = () => {
  return useMutation(assessmentDs160USContact, {
    onError: (error) => parseError(error),
  });
};

export const useAssessmentDs160Family = () => {
  return useMutation(assessmentDs160Family, {
    onError: (error) => parseError(error),
  });
};

export const useAssessmentDs160WET = () => {
  return useMutation(assessmentDs160WET, {
    onError: (error) => parseError(error),
  });
};

export const useAssessmentDs160Sevis = () => {
  return useMutation(assessmentDs160Sevis, {
    onError: (error) => parseError(error),
  });
};

export const useAssessmentDs160Security = () => {
  return useMutation(assessmentDs160Security, {
    onError: (error) => parseError(error),
  });
};

export const useSaveDs160Assessment = () => {
  const queryClient = useQueryClient();

  return useMutation(saveDs160Assessment, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([ds160QueryKeys.getDs160Assessment]);
      queryClient.invalidateQueries([ds160QueryKeys.getChatGptDs160Assessment]);
    },
  });
};

export const useGetDs160Assessment = () => {
  return useQuery(
    [ds160QueryKeys.getDs160Assessment],
    () => getDs160Assessment(),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetChatGptDs160Assessment = (applicationId?: string) => {
  return useQuery(
    [ds160QueryKeys.getChatGptDs160Assessment, applicationId],
    () => getChatGptDs160Assessment(applicationId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data,
      refetchOnWindowFocus: false,
    }
  );
};
