import React, { FC } from "react";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { n400CheckPart2Res } from "../../../../../../api/n400/types";
import { FormN400Progress } from "../../types";

interface Props {
  title: string;
  data?: n400CheckPart2Res;
}

const ReviewCheckPart2: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      label:
        "15.a. Have you EVER committed, agreed to commit, asked someone else to commit, helped commit, or tried to commit a crime or offense for which you were NOT arrested?",
      value: data?.ever_committed_or_attempted_to_commit_a_crime,
    },
    {
      label:
        "15.b. Have you EVER been arrested, cited, detained or confined by any law enforcement officer, military official (in the U.S. or elsewhere), or immigration official for any reason, or been charged with a crime or offense?",
      value: data?.ever_been_arrested_by_law_enforcement_officer,
    },

    {
      section: {
        title: "Crime Records",
        children: [
          {
            label: "What was the crime or offense?",
            value: data?.crime_or_offense,
          },
          {
            label: "Date of the Crime or Offense",
            value: data?.date_of_crime,
          },
          {
            label: "Date of your conviction or guilty plea",
            value: data?.date_of_conviction,
          },
          {
            label: "Place of Crime or Offense",
            value: data?.place_of_crime,
          },
          {
            label:
              "What was the result or disposition of the arrest, citation, or charge?",
            value: data?.disposition_of_arrest_citation_charge,
          },
          {
            label: "What was your sentence",
            value: data?.what_was_your_sentence,
          },
        ],
      },
    },

    {
      label:
        "16. If you received a suspended sentence, were placed on probation, or were paroled, have you completed your suspended sentence, probation, or parole?",
      value: data?.ever_received_a_suspended_sentence,
    },

    {
      label:
        "17.a. Engaged in prostitution, attempted to procure or import prostitutes or persons for the purpose of prostitution, or received any proceeds or money from prostitution?",
      value: data?.ever_been_a_prostitute,
    },
    {
      label:
        "17.b. Manufactured, cultivated, produced, distributed, dispensed, sold, or smuggled (trafficked) any controlled substances, illegal drugs, narcotics, or drug paraphernalia in violation of any law or regulation of a U.S. state, the United States, or a foreign country?",
      value: data?.ever_sold_or_smuggled_narcotics,
    },
    {
      label: "17.c. Been married to more than one person at the same time?",
      value: data?.ever_been_married_to_one_person_at_a_time,
    },
    {
      label: "17.d. Married someone in order to obtain an immigration benefit?",
      value: data?.ever_been_married_to_obtain_immigration_benefit,
    },
    {
      label:
        "17.e. Helped anyone to enter, or try to enter, the United States illegally?",
      value: data?.ever_helped_anyone_enter_illegally,
    },
    {
      label:
        "17.f. Gambled illegally or received income from illegal gambling?",
      value: data?.ever_gambled_illegally,
    },
    {
      label:
        "17.g. Failed to support your dependents (pay child support) or to pay alimony (court-ordered financial support after divorce or separation)?",
      value: data?.ever_failed_to_support_dependents,
    },
    {
      label:
        "17.h. Made any misrepresentation to obtain any public benefit in the United States?",
      value: data?.ever_made_any_misrepresentation_to_obtain_any_public_benefit,
    },
    {
      label:
        "18. Have you EVER given any U.S. Government officials any information or documentation that was false, fraudulent, or misleading?",
      value: data?.ever_given_any_official_false_documentation,
    },
    {
      label:
        "19. Have you EVER lied to any U.S. Government officials to gain entry or admission into the United States or to gain immigration benefits while in the United States?",
      value: data?.ever_lied_to_any_official_to_gain_entry,
    },
  ];

  return (
    <ReviewBox
      title={title}
      data={section}
      editUrl={FormN400Progress.checkPart2}
    />
  );
};

export default ReviewCheckPart2;
