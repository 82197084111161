import React from "react";
import styled from "styled-components";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { Form, Formik } from "formik";
import InputField from "../../../components/formik fields/InputField";
import FormBtn from "../../../components/FormBtn";
import CheckBoxField from "../../../components/formik fields/CheckBoxField";
import SelectField from "../../../components/formik fields/SelectField";
import DatePickerField from "../../../components/formik fields/DatePickerField";
import {
  ExplicitNGDateFormat,
  ToSaveDate,
  resetDynamicFields,
} from "../../../components/data";
import { Info, InfoBold, SubTitle, TermsWrapper, Wrapper } from "../styles";
import RadioComp from "../../../components/RadioComp";
import {
  FormattedVisaLocationsData,
  PIPersonal1Gender,
  processReviewData,
} from "../data";
import {
  GenericYesNo,
  GenericYesNoOptionsValue,
  initCheck,
  initDate,
  initInput,
  initSelect,
} from "../../../components/data";
import { Modify, SingleOption } from "../../../../../types/types";
import { saveDS160PersonalInfoObj } from "../../../../../api/ds160/types";
import { Dayjs } from "dayjs";
import {
  useGetDS160PersonalInfo,
  useSaveDS160PersonalInfo,
} from "../../../../../hooks/ds160/usDS160";
import { PIPersonal1Validation } from "../validation";
import useCountries from "../../../components/useCountries";
import useStatesByCountry from "../../../components/useStatesByCountry";
import useToNextSection from "../../../../../hooks/useToNextSection";
import { ReviewPersonalSectionA } from "../Review/components/data";
import useAiReview from "../useAiReview";
import { mediaObj } from "../../../../../styles/Media";
import { BusinessQueryKey } from "../../../../../api/utils/types";
import { useSearchParams } from "react-router-dom";

export interface PIPersonal1FormData
  extends Modify<
    saveDS160PersonalInfoObj,
    {
      // maritalStatus: SingleOption | null;
      stateAndProvince: SingleOption | null;
      countryOrRegion: SingleOption | null;
      location: SingleOption | null;
      dateOfBirth: Dayjs | null;
    }
  > {}

const PIPersonal1 = () => {
  const { toNextSection } = useToNextSection();
  const [searchParams] = useSearchParams();
  const getApplicationId = searchParams.get(BusinessQueryKey.appId) || "";
  const { data, isFetching, isError } =
    useGetDS160PersonalInfo(getApplicationId);
  const { mutate, isLoading: isSaving } = useSaveDS160PersonalInfo();
  const { formattedCountryData, countryLoading } = useCountries();
  const { formattedStateData, stateLoading, setCountry, selectedCountry } =
    useStatesByCountry(data?.countryOrRegion);
  const { postAiReview, riskData, riskError, riskLoading, riskSaving } =
    useAiReview();

  const initialValues: PIPersonal1FormData = {
    lastName: initInput(data?.lastName),
    givenNames: initInput(data?.givenNames),
    fullName: initInput(data?.fullName),
    doesNotApply: initCheck(data?.doesNotApply),
    anyOtherNames: initInput(data?.anyOtherNames),
    otherFirstName: initInput(data?.otherFirstName),
    otherMiddleName: initInput(data?.otherMiddleName),
    otherLastName: initInput(data?.otherLastName),
    teleCode: initInput(data?.teleCode),
    teleCodeSurname: initInput(data?.teleCodeSurname),
    teleCodeGivenNames: initInput(data?.teleCodeGivenNames),
    sex: initInput(data?.sex),
    // maritalStatus: initSelect(MaritalStatusData, data?.maritalStatus),
    stateAndProvince: initSelect(formattedStateData, data?.stateAndProvince),
    dateOfBirth: initDate(data?.dateOfBirth, ExplicitNGDateFormat),
    city: initInput(data?.city),
    countryOrRegion: initSelect(formattedCountryData, data?.countryOrRegion),
    location: initSelect(FormattedVisaLocationsData, data?.location),
  };

  const onSubmit = (values: PIPersonal1FormData) => {
    const {
      // maritalStatus,
      stateAndProvince,
      countryOrRegion,
      dateOfBirth,
      location,
      ...rest
    } = values;

    if (
      // !maritalStatus ||
      !stateAndProvince ||
      !countryOrRegion ||
      !dateOfBirth ||
      !location
    )
      return;

    const payload = {
      ...rest,
      // maritalStatus: maritalStatus.value,
      stateAndProvince: stateAndProvince.value,
      countryOrRegion: countryOrRegion.value,
      location: location.value,
      dateOfBirth: ToSaveDate(dateOfBirth, ExplicitNGDateFormat),
    };

    const sectionA = ReviewPersonalSectionA(payload);

    const result = processReviewData(sectionA);

    const riskPayload = {
      ...riskData,
      personal_Info: {
        ...riskData?.personal_Info,
        ...result,
      },
    };

    const onSave = () => {
      mutate(
        { ...payload, applicationId: getApplicationId },
        {
          onSuccess: toNextSection,
        }
      );
    };

    postAiReview({
      riskPayload: riskPayload,
      onSave,
    });
  };

  return (
    <>
      <FormSectionWrapper
        isLoading={isFetching || riskLoading}
        isError={isError || riskError}
      >
        <Wrapper>
          <Formik
            initialValues={initialValues}
            validationSchema={PIPersonal1Validation}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(formik) => (
              <Form>
                <FormWrapper>
                  <SelectField
                    name="location"
                    label="Select a location where you will be applying for this visa"
                    placeholder="-Select-"
                    options={FormattedVisaLocationsData}
                  />
                  <div></div>
                </FormWrapper>

                <Info>
                  <InfoBold>
                    NOTE: Data on this page must match information as written on
                    your passport.
                  </InfoBold>
                </Info>

                <FormWrapper>
                  <InputField
                    label="Family Name"
                    coloredLabel="(Last Name)"
                    placeholder=""
                    name="lastName"
                  />
                  <InputField
                    label="Given Names"
                    placeholder=""
                    name="givenNames"
                  />
                </FormWrapper>

                <FormWrapper>
                  <InputField
                    label="Full Name in Native Alphabet"
                    placeholder=""
                    name="fullName"
                    isDisabled={!!formik.values.doesNotApply}
                  />
                  <div></div>
                </FormWrapper>
                <Terms>
                  <CheckBoxField
                    label={`Does not apply`}
                    name="doesNotApply"
                    onChange={() => {
                      resetDynamicFields(formik, [
                        { name: "fullName", value: "" },
                      ]);
                    }}
                  />
                </Terms>

                <FormWrapper>
                  <RadioComp
                    title="Have you used any other names since birth?"
                    name="anyOtherNames"
                    options={GenericYesNo}
                    onChange={() => {
                      resetDynamicFields(formik, [
                        { name: "otherFirstName", value: "" },
                        { name: "otherMiddleName", value: "" },
                        { name: "otherLastName", value: "" },
                      ]);
                    }}
                  />
                </FormWrapper>

                {formik.values.anyOtherNames ===
                  GenericYesNoOptionsValue.yes && (
                  <>
                    <FormWrapper>
                      <InputField
                        label="Given Name"
                        coloredLabel="(First Name)"
                        placeholder=""
                        name="otherFirstName"
                      />
                      <InputField
                        label="Middle Name"
                        coloredLabel="(s)"
                        placeholder=""
                        name="otherMiddleName"
                      />
                    </FormWrapper>
                    <FormWrapper>
                      <InputField
                        label="Family Name"
                        coloredLabel="(Last Name)"
                        placeholder=""
                        name="otherLastName"
                      />
                      <div></div>
                    </FormWrapper>
                  </>
                )}

                <FormWrapper>
                  <RadioComp
                    title="Do you have a telecode that represents your name?"
                    name="teleCode"
                    options={GenericYesNo}
                    onChange={() => {
                      resetDynamicFields(formik, [
                        { name: "teleCodeSurname", value: "" },
                        { name: "teleCodeGivenNames", value: "" },
                      ]);
                    }}
                  />
                </FormWrapper>

                {formik.values.teleCode === GenericYesNoOptionsValue.yes && (
                  <>
                    <FormWrapper>
                      <InputField
                        label="Telecode Surname"
                        placeholder=""
                        name="teleCodeSurname"
                      />
                      <div></div>
                    </FormWrapper>
                    <FormWrapper>
                      <InputField
                        label="Telecode Given Names"
                        placeholder=""
                        name="teleCodeGivenNames"
                      />
                      <div></div>
                    </FormWrapper>
                  </>
                )}

                <FormWrapper>
                  <RadioComp
                    title="Sex"
                    name="sex"
                    options={PIPersonal1Gender}
                  />
                </FormWrapper>
                {/* <FormWrapper>
                <SelectField
                  name="maritalStatus"
                  label="Marital status"
                  placeholder="-Select-"
                  options={MaritalStatusData}
                />
                <div></div>
              </FormWrapper> */}

                <SubTitle>Birth Information</SubTitle>
                <FormWrapper>
                  <DatePickerField
                    name="dateOfBirth"
                    label="Date of birth"
                    placeholder={ExplicitNGDateFormat}
                    format={ExplicitNGDateFormat}
                    disableFuture={true}
                  />
                  <InputField name="city" label="City" placeholder="" />
                </FormWrapper>

                <FormWrapper>
                  <SelectField
                    name="countryOrRegion"
                    label="Country/Region"
                    placeholder="-Select-"
                    options={formattedCountryData}
                    isLoading={countryLoading}
                    onChange={(value) => {
                      setCountry(value.label);
                      formik.setFieldValue("stateAndProvince", null);
                    }}
                  />
                  <SelectField
                    name="stateAndProvince"
                    label="State/Province"
                    placeholder="-Select-"
                    options={formattedStateData}
                    isLoading={stateLoading}
                    isDisabled={!selectedCountry}
                  />
                </FormWrapper>
                <FormBtn
                  isLoading={isSaving || riskSaving}
                  addBackBtn
                  isError={formik.dirty && formik.dirty && !formik.isValid}
                />
              </Form>
            )}
          </Formik>
        </Wrapper>
      </FormSectionWrapper>
      {/* 
      {open && (
        <SectionAssessment
          onClose={onCloseModal}
          onSave={onSave}
          payload={open}
        />
      )} */}
    </>
  );
};

export default PIPersonal1;

const FormWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 38px;
  margin-bottom: 30px;

  & > div {
    flex: 1;
  }

  ${mediaObj.bigMobile} {
    flex-direction: column;
  }
`;

const Terms = styled(TermsWrapper)`
  margin-bottom: 30px;
  margin-top: -18px;
`;
