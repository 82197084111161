export const OverlayVariants = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.15,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.15,
    },
  },
};

export const ContainerVariants = {
  hidden: {
    y: -50,
  },
  visible: {
    y: 0,
  },
};
