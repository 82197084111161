import React, { FC } from "react";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { N600InfoAboutYouPart1Payload } from "../../../../../../api/n600/types";
import { FormN600Progress } from "../../types";

interface Props {
  title: string;
  data?: N600InfoAboutYouPart1Payload;
}

const ReviewInfoAboutYouPart1: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      label: "Family Name (Last Name)",
      value: data?.familyName,
    },
    {
      label: "Given Name (First Name)",
      value: data?.givenName,
    },
    {
      label: "Middle Name",
      value: data?.middleName,
    },
    {
      label: "Has your name changed?",
      value: data?.hasNameChanged,
    },
    {
      label: "Changed Family Name (Last Name)",
      value: data?.changedFamilyName,
    },
    {
      label: "Changed Given Name (First Name)",
      value: data?.changedGivenName,
    },
    {
      label: "Changed Middle Name",
      value: data?.changedMiddleName,
    },
    {
      label: "Have you used other names since birth?",
      value: data?.otherNamesSinceBirth,
    },
    {
      label: "Other Family Name (Last Name)",
      value: data?.otherFamilyName,
    },
    {
      label: "Other Given Name (First Name)",
      value: data?.otherGivenName,
    },
    {
      label: "Other Middle Name",
      value: data?.otherMiddleName,
    },
    {
      label: "Alien Number",
      value: data?.aNumber,
    },
    {
      label: "Social Security Number",
      value: data?.ssn,
    },
    {
      label: "USCIS Online Account Number",
      value: data?.uscisOnlineAccountNumber,
    },
    {
      label: "Date of Birth",
      value: data?.dateOfBirth,
    },
    {
      label: "Country of Birth",
      value: data?.countryOfBirth,
    },
    {
      label: "Country of Citizenship",
      value: data?.countryOfPriorCitizenship,
    },
    {
      label: "Gender",
      value: data?.gender,
    },
  ];

  return (
    <ReviewBox
      title={title}
      data={section}
      editUrl={FormN600Progress.infoAboutYouP1}
    />
  );
};

export default ReviewInfoAboutYouPart1;
