import React, { FC } from "react";
import { PreparerInterpreterObj } from "../../../../api/utils/types";
import ReviewBox, { ReviewData } from "../../components/Review/ReviewBox";

interface Props {
  title: string;
  data?: PreparerInterpreterObj;
  editUrl: string;
}

const ReviewPI: FC<Props> = ({ title, data, editUrl }) => {
  const section: ReviewData[] = [
    {
      section: {
        title: "Preparer",
        children: [
          {
            label: "Who prepared this document?",
            value: data?.applicationPreparer,
          },
          {
            label:
              "Did you receive help from an interpreter to translate this application?",
            value: data?.receiveInterpreterHelp,
          },
          {
            label: "Which language was used to translate into English?",
            value: data?.language,
          },
          {
            label: "Interpreter’s Given Name (First Name)",
            value: data?.interpreterFirstName,
          },
          {
            label: "Interpreter’s Family Name (Last Name)",
            value: data?.interpreterLastName,
          },
          {
            label: "Interpreter’s Firm or Organization Name ",
            value: data?.interpreterFirm,
          },
          {
            label: "Interpreter’s Address",
            value: data?.interpreterAddress,
          },
          {
            label: "Street Number and Name ",
            value: data?.interpreterStreetNumAndName,
          },
          {
            label: "Is there an apartment, suite or floor?",
            value: data?.interpreterApartmentSuiteOrFloor,
          },
          {
            label: "Address Number",
            value: data?.interpreterAddressNum,
          },
          {
            label: "City or Town?",
            value: data?.interpreterCityOrTown,
          },
          {
            label: "Country",
            value: data?.interpreterCountry,
          },
          {
            label: "Daytime Phone Number",
            value: data?.interpreterDayTimePhoneNumber,
          },
          {
            label: "Mobile Phone Number (if any)",
            value: data?.interpreterMobilePhoneNumber,
          },
          {
            label: "State (or Territory)",
            value: data?.interpreterStateOrTerritory,
          },
          {
            label: "ZIP Code",
            value: data?.interpreterZipCode,
          },
          {
            label: "Email",
            value: data?.interpreterEmail,
          },
          {
            label:
              "I acknowledge that each and every question and instruction on this form, as well as my answer to each question, has been read to me by the person named above in a language in which I am fluent. I have provided true and correct responses in the language indicated above.",
            value: data?.interpreterAcknowledgement ? "Yes" : "",
          },
          {
            label:
              "I can read and understand English, and have read and understand every question and instruction on this application, as well as my answer to every question.",
            value: data?.englishConfirmation ? "Yes" : "",
          },
          {
            section: {
              title: "Preparer’s Information",
              children: [
                {
                  label: "Preparer’s Given Name (First Name)",
                  value: data?.preparerFirstName,
                },
                {
                  label: "Preparer’s Family Name (Last Name)",
                  value: data?.preparerLastName,
                },
                {
                  label: "Preparer’s Firm or Organization Name",
                  value: data?.preparerFirm,
                },
                {
                  label: "Preparer’s Address",
                  value: data?.preparerAddress,
                },
                {
                  label: "Street Number and Name",
                  value: data?.preparerStreetNumAndName,
                },
                {
                  label: "In there an apartment, suite or floor",
                  value: data?.preparerApartmentSuiteOrFloor,
                },
                {
                  label: "Address Number",
                  value: data?.preparerAddressNum,
                },
                {
                  label: "City or Town",
                  value: data?.preparerCityOrTown,
                },
                {
                  label: "Country",
                  value: data?.preparerCountry,
                },
                {
                  label: "Daytime Phone Number",
                  value: data?.preparerDayTimePhoneNumber,
                },
                {
                  label: "Mobile Phone Number (if any)",
                  value: data?.preparerMobilePhoneNumber,
                },
                {
                  label: "State (or Territory)",
                  value: data?.preparerStateOrTerritory,
                },
                {
                  label: "ZIP Code",
                  value: data?.preparerZipCode,
                },
                {
                  label: "Email",
                  value: data?.preparerEmail,
                },
                {
                  label:
                    "I acknowledge that each and every question and instruction on this form, as well as my answer to each question, has been read to me by the person named above in a language in which I am fluent. I have provided true and correct responses in the language indicated above.",
                  value: data?.interpreterAcknowledgement ? "Yes" : "",
                },
              ],
            },
          },
          {
            section: {
              title: "Accredited Representative Information",
              children: [
                {
                  label: "My representation of the applicant in this case:",
                  value: data?.representationOfApplicant,
                },
                {
                  section: {
                    title: "Preparer’s Information",
                    children: [
                      {
                        label: "Given Name (First Name)",
                        value: data?.preparerFirstName,
                      },
                      {
                        label: "Family Name (Last Name)",
                        value: data?.preparerLastName,
                      },
                      {
                        label: "Firm or Organization Name",
                        value: data?.preparerFirm,
                      },
                      {
                        label: "State Bar Number",
                        value: data?.stateBarNum,
                      },
                      {
                        label: "USCIS Online Account Number",
                        value: data?.uscisOnlineAccountNumber,
                      },
                    ],
                  },
                },
                {
                  section: {
                    title: "Preparer’s Address",
                    children: [
                      {
                        label: "Street Number and Name",
                        value: data?.preparerStreetNumAndName,
                      },
                      {
                        label: "preparerStreetNumAndName",
                        value: data?.preparerApartmentSuiteOrFloor,
                      },
                      {
                        label: "City or Town",
                        value: data?.preparerCityOrTown,
                      },
                      {
                        label: "Country",
                        value: data?.preparerCountry,
                      },
                      {
                        label: "State (or Territory)",
                        value: data?.preparerStateOrTerritory,
                      },
                      {
                        label: "ZIP Code",
                        value: data?.preparerZipCode,
                      },
                    ],
                  },
                },
                {
                  section: {
                    title: "Preparer’s Contact Information",
                    children: [
                      {
                        label: "Daytime Phone Number",
                        value: data?.preparerDayTimePhoneNumber,
                      },
                      {
                        label: "Mobile Phone Number (if any)",
                        value: data?.preparerMobilePhoneNumber,
                      },
                      {
                        label: "Email",
                        value: data?.preparerEmail,
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ];
  return <ReviewBox title={title} data={section} editUrl={editUrl} />;
};

export default ReviewPI;
