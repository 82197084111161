import { UserDataObj } from "../../types/types";

export interface countriesObj {
  name: string;
  iso3: string;
  iso2: string;
  states: statesObj[];
}

export interface statesObj {
  name: string;
  state_code: string;
}

export interface PreparerInterpreterObj {
  applicationPreparer: string;
  representationOfApplicant: string;
  preparerFirstName: string;
  preparerLastName: string;
  preparerFirm: string;
  stateBarNum: string;
  uscisOnlineAccountNumber: string;
  preparerAddress: string;
  preparerStreetNumAndName: string;
  preparerApartmentSuiteOrFloor: string;
  preparerAddressNum: string;
  preparerCityOrTown: string;
  preparerCountry: string;
  preparerStateOrTerritory: string;
  preparerZipCode: string;
  preparerDayTimePhoneNumber: string;
  preparerMobilePhoneNumber: string;
  preparerEmail: string;
  receiveInterpreterHelp: string;
  englishConfirmation: boolean;
  interpreterAcknowledgement: boolean;
  preparerAcknowledgement: boolean;
  language: string;
  interpreterFirstName: string;
  interpreterLastName: string;
  interpreterFirm: string;
  interpreterAddress: string;
  interpreterStreetNumAndName: string;
  interpreterApartmentSuiteOrFloor: string;
  interpreterAddressNum: string;
  interpreterCityOrTown: string;
  interpreterCountry: string;
  interpreterStateOrTerritory: string;
  interpreterZipCode: string;
  interpreterDayTimePhoneNumber: string;
  interpreterMobilePhoneNumber: string;
  interpreterEmail: string;
}

export interface userDataResponse {
  hasError: boolean;
  code: number;
  userData: UserDataObj;
  message: string;
}
// export interface UserData {
//   formCategory: string;
//   formProgress: string;
//   isDeleted: boolean;
//   isFormCompleted: boolean;
//   ["string"]: string;
//   user: {
//     disabled: boolean;
//     ds160Paid: boolean;
//     email: string;
//     emailVerified: string;
//     n400Paid: boolean;
//     n565Paid: boolean;
//     n600Paid: boolean;
//     name: string;
//     paidDocument: string;
//     paymentStatus: string;
//     userId: string;
//   };
// }

export interface PaginationQueries {
  limit?: number;
  page?: number;
}

export interface BusinessApplicationId {
  applicationId?: string;
}

export enum BusinessQueryKey {
  appId = "appId",
  userId = "userId",
}
