import React from "react";
import WebRoutes from "../views/web/routes";
import ScrollToTop from "../hooks/scrollToTop";

const AppRoutes: React.FC = () => {
  return (
    <>
      <ScrollToTop />
      <WebRoutes />
    </>
  );
};

export default AppRoutes;
