import { FormCategories } from "../../../../routes/type";
import {
  eligibilityDataStore,
  seamlessEligibilityData,
} from "../../../../store/eligibilityData";
import storage from "../../../../utils/storage";
import { checkFormDS160dataQuestions } from "./ds160data";
import { checkFormN400Questions } from "./n400data";
import { checkFormN565Questions } from "./n565data";
import { checkFormN600Questions } from "./n600data";
import { checkQuestionsProps } from "./types";

export const checkFormQuestions: Record<string, checkQuestionsProps<any>[]> = {
  [FormCategories.n565]: checkFormN565Questions,
  [FormCategories.n400]: checkFormN400Questions,
  [FormCategories.n600]: checkFormN600Questions,
  [FormCategories.ds160]: checkFormDS160dataQuestions,
};

export const clearEligibilityStoreAndLocalStorage = () => {
  eligibilityDataStore.eligibilityData.formData = undefined;
  eligibilityDataStore.eligibilityData.formType = undefined;
  storage.remove(seamlessEligibilityData);
};
