import React, { FC } from "react";
import DocChecklistItem from "../DocChecklistItem";
import { GetFileDetailObj } from "../../../../../../../api/n400/types";
import { additionalKeyText } from "../AdditionalDoc";

interface Props {
  filedetail: GetFileDetailObj[];
  onSubmit: (file: File, name: string, fileId?: string) => void;
  loadingField: string;
  loadingState: boolean;
}

const AdditionalDocItem: FC<Props> = ({
  filedetail,
  onSubmit,
  loadingField,
  loadingState,
}) => {
  const getIsLoading = (name: string) => {
    return loadingField === name && loadingState;
  };

  return (
    <>
      {[...filedetail]
        // .reverse()
        .filter((ev) => ev.name.includes(`${additionalKeyText}`))
        .map((ev, i) => (
          <DocChecklistItem
            key={i}
            data={{
              name: ev.name,
              label: ev.name
                .split(`${additionalKeyText}`)[1]
                .replaceAll("_", " "),
            }}
            onSubmit={onSubmit}
            isLoading={getIsLoading(ev.name)}
            savedData={ev}
            isAdditional
          />
        ))}
    </>
  );
};

export default AdditionalDocItem;
