import * as Yup from "yup";
import { SchemaObject } from "../../components/type";
import { BusinessSignUpFormData } from "./SignUp";

export const LoginValidation = Yup.object().shape({
  email: Yup.string().trim().email().required("Required"),
  password: Yup.string().required("Required"),
  // agreement: Yup.bool().oneOf([true]),
});

export const SignUpValidation = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  phoneNumber: Yup.string()
    .required("Required")
    .test("phoneNumber", "Invalid Phone number", function (value) {
      return value?.split(" ").length === 1 ? false : true;
    }),
  email: Yup.string().trim().email().required("Required"),
  password: Yup.string().required("Required"),
  confirmPassword: Yup.string()
    .required("Please confirm your password.")
    .oneOf([Yup.ref("password")], "Your passwords do not match."),
  agreement: Yup.bool().oneOf([true]),
});

export const ResetPasswordValidation = Yup.string().required("Required");

export const BusinessSignUpValidation = Yup.object().shape<
  SchemaObject<BusinessSignUpFormData>
>({
  businessType: Yup.object().nullable().required("Required"),
  businessName: Yup.string().required("Required"),
  businessNumber: Yup.string()
    .required("Required")
    .test("phoneNumber", "Invalid Phone number", function (value) {
      return value?.split(" ").length === 1 ? false : true;
    }),
  businessEmail: Yup.string().trim().email().required("Required"),
  businessAddress: Yup.string().required("Required"),
  businessPassword: Yup.string().required("Required"),
  businessConfirmPassword: Yup.string()
    .required("Please confirm your password.")
    .oneOf([Yup.ref("businessPassword")], "Your passwords do not match."),
  businessAgreement: Yup.bool().oneOf([true]),
});
