import styled from "styled-components";
import Header from "./Header";
import { CaseOverviewTableData } from "./data";
import { useNavigate } from "react-router-dom";
import TableSection from "../../../../../components/table/TableSection";
import { useGetBusinessApplications } from "../../../../../../../hooks/business/useBusinessApplications";
import {
  InitialPageLimit,
  InitialPageNo,
} from "../../../../../components/data";
import { ProfileSections } from "../../../types";

const Case = () => {
  const navigate = useNavigate();
  const { data, isLoading, isError } = useGetBusinessApplications({
    page: InitialPageNo,
    limit: InitialPageLimit,
  });

  return (
    <Wrapper>
      <Header
        name="Case History"
        onViewAll={() => navigate(`?active=${ProfileSections.caseManagement}`)}
      />
      <TableSection
        dataItems={data?.applicationsData}
        tableData={CaseOverviewTableData()}
        isError={isError}
        isLoading={isLoading}
      />
    </Wrapper>
  );
};

export default Case;

const Wrapper = styled.div`
  margin: 56px 0;
  height: 100%;
`;
