import React, { useState } from "react";
import styled from "styled-components";
import { Form, Formik } from "formik";
import { GenericYesNo } from "../../components/data";
import Colors from "../../../../styles/Colors";
import { feedbackData } from "../../../../api/feedback/types";
import { NeedsImprovementsData, Q3Data, Q6Data } from "./data";
import Question from "./Question";
import { HR } from "../../components/styles";
import { FormWrapper } from "../../profile/components/styles";
import FormBtn from "../../components/FormBtn";
import { useSaveFeedback } from "../../../../hooks/feedback/useFeedback";
import { useNavigate } from "react-router-dom";
import { WebPaths } from "../../../../routes/data";
import FeedbackSubmitted from "./FeedbackSubmitted";
import InputField from "../../components/formik fields/InputField";

const Survey: React.FC = () => {
  // HOOKS
  const { mutate, isLoading: isSaving } = useSaveFeedback();
  const navigate = useNavigate();

  // STATES
  const [feedbackSent, setFeedbackSent] = useState<boolean>(false);

  // INITIAL VALUES
  const initialValue: feedbackData = {
    first_time_using_service: "",
    which_feature_needs_improvement: "",
    otherExplain: "",
    account_and_transaction_are_safe: "",
    seamless_will_address_my_concern: "",
    is_filing_instruction_correct: "",
    recommend_seamless_to_friend: "",
    recommendation_or_comment: "",
  };

  const onSubmit = (values: feedbackData) => {
    const { ...rest } = values;
    const payload = {
      ...rest,
    };

    mutate(payload, {
      onSuccess: () => {
        setFeedbackSent(true);
        localStorage.setItem("hasGivenFeedback", "true");
      },
    });
  };

  return (
    <Wrapper>
      {feedbackSent && (
        <FeedbackSubmitted onClose={() => navigate(WebPaths.Root)} />
      )}
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        // validationSchema={CheckPart3Validation}
      >
        {(formik) => (
          <Form>
            <Title>Customer Survey</Title>
            <Sub>
              Help us improve your experience by answering these questions.
            </Sub>

            <FormWrapper>
              <Question
                name="first_time_using_service"
                options={GenericYesNo}
                title="Is this your first time using a service like SeamlessVisa?*"
              />
            </FormWrapper>

            <FormWrapper>
              <Question
                name="which_feature_needs_improvement"
                options={NeedsImprovementsData}
                title="Which feature needs the most improvement?*"
              />
            </FormWrapper>

            <FormWrapper>
              {formik.values.which_feature_needs_improvement === "Other" && (
                <InputField
                  label="Explain:"
                  placeholder="Explanation..."
                  name="otherExplain"
                  inputType="text-area"
                />
              )}
            </FormWrapper>

            <HR />

            <HeadText>
              Trustworthiness is about deserving trust. Please rate
              SeamlessVisa's trustworthiness.
            </HeadText>
            <FormWrapper>
              <Question
                title="My account and transactions are safe and secured?*"
                name="account_and_transaction_are_safe"
                options={Q3Data}
              />
            </FormWrapper>
            <FormWrapper>
              <Question
                title="I'm confident that SeamlessVisa will address my questions and concerns?*"
                name="seamless_will_address_my_concern"
                options={Q3Data}
              />
            </FormWrapper>
            <FormWrapper>
              <Question
                title="The forms and filing instructions I received are correct and reliable?*"
                name="is_filing_instruction_correct"
                options={Q3Data}
              />
            </FormWrapper>
            <FormWrapper>
              <Question
                title="Would you recommend SeamlessVisa to a friend or family member?*"
                name="recommend_seamless_to_friend"
                options={Q6Data}
              />
            </FormWrapper>
            <FormWrapper>
              <InputField
                label="Do you have any other recommendations or comments that you would like to share with us?*"
                placeholder="Recommendations..."
                name="recommendation_or_comment"
                inputType="text-area"
              />
            </FormWrapper>
            <FormBtn
              isLoading={isSaving}
              text="Submit Feedback"
              isError={false}
            />
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default Survey;

const Wrapper = styled.div`
  margin: 28px 0;
  width: 100%;
`;

const Title = styled.div`
  color: ${Colors.Black31};
  font-weight: bold;
  font-size: 2.24rem;
`;

const Sub = styled.div`
  color: ${Colors.Black31};
  margin-top: 19px;
  margin-bottom: 30px;
  font-weight: 300;
  font-size: 0.875rem;
`;

const HeadText = styled.div`
  font-size: 1.24rem;
  margin-bottom: 1rem;
`;
