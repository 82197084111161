import React, { FC } from "react";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { N600BiographicInfoPayload } from "../../../../../../api/n600/types";
import { FormN600Progress } from "../../types";

interface Props {
  title: string;
  data?: N600BiographicInfoPayload;
}

const ReviewBioInfo: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      label: "Ethnicity",
      value: data?.ethnicity.replace(/([A-Z])/g, " $1").trim(),
    },
    {
      label: "Race",
      // value: data?.races.join(", "),
      value: data?.races
        ? Object.entries(data.races[0])
            .filter(([key, value]) => value)
            .map(([key]) => key.charAt(0).toUpperCase() + key.slice(1))
            .join(", ")
        : "",
    },
    {
      label: "Height (Feet)",
      value: data?.heightFeet,
    },
    {
      label: "Height (Inches)",
      value: data?.heightInches,
    },
    {
      label: "Weight (Pounds)",
      value: data?.weightPounds,
    },
    {
      label: "Eye Color",
      value: data?.eyeColor,
    },
    {
      label: "Hair Color",
      value: data?.hairColor,
    },
  ];

  return (
    <ReviewBox
      title={title}
      data={section}
      editUrl={FormN600Progress.bioInfo}
    />
  );
};

export default ReviewBioInfo;
