import * as Yup from "yup";
import { CreateBusinessUserPayload } from "../../../../../../../api/business/users/types";
import { SchemaObject } from "../../../../../components/type";

export const BusinessCreateUserValidation = Yup.object().shape<
  SchemaObject<CreateBusinessUserPayload>
>({
  name: Yup.string().required("Required"),
  email: Yup.string().trim().email().required("Required"),
  phoneNumber: Yup.string()
    .required("Required")
    .test("phoneNumber", "Invalid Phone number", function (value) {
      return value?.length > 5 ? true : false;
    }),
});
