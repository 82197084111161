import { PlusIcon } from "@heroicons/react/24/solid";
import React, { FC } from "react";

interface Props {
  text: string;
  icon?: string;
  onClick: () => void;
}

const CreateBtn: FC<Props> = ({ text, icon, onClick }) => {
  return (
    <button
      className="flex items-center gap-2 px-6 py-2 font-medium text-white capitalize transition-all border rounded-lg bg-primaryColor border-primaryColor hover:bg-transparent hover:text-primaryColor"
      onClick={onClick}
    >
      {icon || <PlusIcon width={16} height={16} />} {text}
    </button>
  );
};

export default CreateBtn;
