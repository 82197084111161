import FormSectionWrapper from "../../components/FormSectionWrapper";
import {
  Info,
  InfoBold,
  SubTitle,
  VerticalFormWrapper,
  Wrapper,
} from "./styles";
import { Form, Formik } from "formik";
import SelectField from "../../components/formik fields/SelectField";
import FormBtn from "../../components/FormBtn";
import styled from "styled-components";
import InputField from "../../components/formik fields/InputField";
import CheckBoxField from "../../components/formik fields/CheckBoxField";
import { processReviewData, RelationshipToYou, USText } from "./data";
import {
  useGetDS160USContact,
  useSaveDS160USContact,
} from "../../../../hooks/ds160/usDS160";
import { Modify, SingleOption } from "../../../../types/types";
import { saveDS160USContactObj } from "../../../../api/ds160/types";
import { USCOntactValidation } from "./validation";
import {
  resetDynamicFields,
  initCheck,
  initInput,
  initSelect,
} from "../../components/data";
import useToNextSection from "../../../../hooks/useToNextSection";
import useStatesByCountry from "../../components/useStatesByCountry";
import useAiReview from "./useAiReview";
import { ReviewUSContactSection } from "./Review/components/data";
import { useSearchParams } from "react-router-dom";
import { BusinessQueryKey } from "../../../../api/utils/types";

export interface USContactData
  extends Modify<
    saveDS160USContactObj,
    {
      relationshipToYou: SingleOption | null;
      state: SingleOption | null;
    }
  > {}

const USContact = () => {
  const { toNextSection } = useToNextSection();
  const [searchParams] = useSearchParams();
  const getApplicationId = searchParams.get(BusinessQueryKey.appId) || "";
  const { data, isFetching, isError } = useGetDS160USContact(getApplicationId);
  const { mutate, isLoading: isSaving } = useSaveDS160USContact();
  const { formattedStateData, stateLoading } = useStatesByCountry(USText);
  const { postAiReview, riskData, riskError, riskLoading, riskSaving } =
    useAiReview();

  const onSubmit = (values: USContactData) => {
    const { relationshipToYou, state, ...rest } = values;

    if (!relationshipToYou || !state) return;

    const payload = {
      ...rest,
      relationshipToYou: relationshipToYou.value,
      state: state.value,
    };

    const section = ReviewUSContactSection(payload);
    const result = processReviewData(section);

    const riskPayload = {
      ...riskData,
      us_contact: {
        ...riskData?.us_contact,
        ...result,
      },
    };

    const onSave = () => {
      mutate(
        { ...payload, applicationId: getApplicationId },
        {
          onSuccess: toNextSection,
        }
      );
    };

    postAiReview({
      riskPayload: riskPayload,
      onSave,
    });
  };

  const initialValues: USContactData = {
    surname: initInput(data?.surname),
    givenNames: initInput(data?.givenNames),
    doesNotApply: initCheck(data?.doesNotApply),
    organizationName: initInput(data?.organizationName),
    relationshipToYou: initSelect(RelationshipToYou, data?.relationshipToYou),
    usStreetAddressLine1: initInput(data?.usStreetAddressLine1),
    usStreetAddressLine2: initInput(data?.usStreetAddressLine2),
    city: initInput(data?.city),
    state: initSelect(formattedStateData, data?.state),
    zipCode: initInput(data?.zipCode),
    phoneNumber: initInput(data?.phoneNumber),
    emailAddress: initInput(data?.emailAddress),
    emailDoesNotApply: initCheck(data?.emailDoesNotApply),
  };

  return (
    <FormSectionWrapper
      isLoading={isFetching || riskLoading}
      isError={isError || riskError}
    >
      <Wrapper>
        <Info>
          <InfoBold>
            NOTE: Provide the following information concerning your US Contact.
          </InfoBold>
        </Info>
        <Formik
          initialValues={initialValues}
          validationSchema={USCOntactValidation}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <Sub>Contact person or organization in the united states</Sub>

                <InputField label="Surname" placeholder="" name="surname" />

                <div className="flex flex-col gap-2">
                  <InputField
                    label="Given Name"
                    placeholder=""
                    name="givenNames"
                    isDisabled={!!formik.values.doesNotApply}
                  />
                  <CheckBoxField
                    name="doesNotApply"
                    label="Does not apply"
                    onChange={() => {
                      resetDynamicFields(formik, [
                        { name: "givenNames", value: "" },
                      ]);
                    }}
                  />
                </div>

                <InputField
                  label="Organization Name"
                  placeholder=""
                  name="organizationName"
                />

                <SelectField
                  name="relationshipToYou"
                  label="Relationship to you"
                  options={RelationshipToYou}
                />

                <Sub>Address and phone number of the point of contact</Sub>

                <InputField
                  label="U.S Street Address (Line 1)"
                  placeholder=""
                  name="usStreetAddressLine1"
                />

                <InputField
                  label="U.S Street Address Line 2"
                  coloredLabel="(optional)"
                  placeholder=""
                  name="usStreetAddressLine2"
                />

                <InputField label="City" placeholder="" name="city" />

                <SelectField
                  label="State"
                  placeholder=""
                  name="state"
                  isLoading={stateLoading}
                  options={formattedStateData}
                />

                <InputField
                  label="Zip Code"
                  coloredLabel="(if known)"
                  placeholder=""
                  name="zipCode"
                />

                <InputField
                  label="Phone Number"
                  placeholder=""
                  name="phoneNumber"
                />

                <div className="flex flex-col gap-2">
                  <InputField
                    label="Email Address"
                    placeholder=""
                    name="emailAddress"
                    isDisabled={!!formik.values.emailDoesNotApply}
                  />
                  <CheckBoxField
                    name="emailDoesNotApply"
                    label="Does not apply"
                    onChange={() => {
                      resetDynamicFields(formik, [
                        { name: "emailAddress", value: "" },
                      ]);
                    }}
                  />
                </div>

                {/* end both */}
              </FormWrapper>
              <FormBtn
                isLoading={isSaving || riskSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default USContact;

const FormWrapper = styled(VerticalFormWrapper)`
  & > div {
    /* width: 50%; */
  }
`;

export const Sub = styled(SubTitle)`
  margin-bottom: 0;
`;
