import React, { FC } from "react";
import styled from "styled-components";
import CongratsImage from "../../../images/web/congrats.svg";
import Colors from "../../../styles/Colors";
import { useNavigate, useSearchParams } from "react-router-dom";
import PayWrapper from "./PayWrapper";
import { WebPaths } from "../../../routes/data";
import DownloadFormsContent from "./DownloadFormsContent";
import { userDataStore } from "../../../store/userData";
import { useSnapshot } from "valtio";
import { DownloadFormsContentProps } from "./type";

interface Props extends DownloadFormsContentProps {
  formType: string;
  isLoading: boolean;
  isError: boolean;
}

const PaySuccess: FC<Props> = ({
  formType,
  isLoading,
  isError,
  hideMainform,
  hideFilingForm,
  hideG1145Form,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { userData } = useSnapshot(userDataStore);

  const removeAllSearchParams = () => {
    const payParam = searchParams.get("pay");
    const payIdParam = searchParams.get("payId");

    if (payParam) {
      searchParams.delete("pay");
    }

    if (payIdParam) {
      searchParams.delete("payId");
    }
    setSearchParams(searchParams);
  };

  const onModalClose = () => {
    removeAllSearchParams();
    navigate(WebPaths.Profile);
  };

  const caseHistory = userData?.caseHistory.find(
    (ev) => ev.caseType === formType
  );

  return (
    <PayWrapper
      onModalClose={onModalClose}
      isError={isError}
      isLoading={isLoading}
    >
      <>
        <MainIcon>
          <img src={CongratsImage} alt="" />
        </MainIcon>
        <ContentWrapper>
          <TextBold>
            🎉 Your Payment has been processed successfully! 🎉
          </TextBold>

          {caseHistory && (
            <DownloadFormsContent
              headerText="Below are documents available for you to download, all of which are also accessible from your dashboard."
              hideMainform={hideMainform}
              hideFilingForm={hideFilingForm}
              hideG1145Form={hideG1145Form}
              formType={formType}
              caseType={caseHistory.caseType}
              filingURL={caseHistory.filingURL}
              formDownloadUrl={caseHistory.formDownloadUrl}
              g1145URL={caseHistory.g1145URL || ""}
            />
          )}
        </ContentWrapper>
      </>
    </PayWrapper>
  );
};

export default PaySuccess;

const MainIcon = styled.div`
  width: 108px;
  height: 108px;
  margin-bottom: 16px;

  & > svg,
  & > img {
    width: 100%;
    height: 100%;
    fill: ${Colors.RedC3};
  }
`;

const ContentWrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Text = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
`;

const TextBold = styled(Text)`
  font-weight: bold;
`;
