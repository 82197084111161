import React, { FC } from "react";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { N400AddressHistoryObj } from "../../../../../../api/n400/types";
import { FormN400Progress } from "../../types";

interface Props {
  title: string;
  data?: N400AddressHistoryObj;
}

const ReviewAddressHistory: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      section: {
        title: "Physical Addresses",
        children: [
          {
            label: "In Care Of Name (if any)",
            value: data?.inCareOfName,
          },
          {
            label: "Street Number and Name",
            value: data?.streetNumberAndName,
          },
          {
            label: "Is there an Appartment Suit or Floor",
            value: data?.apartmentSuiteOrFloor,
          },
          {
            label: "Address number",
            value: data?.addressNumber,
          },
          {
            label: "City or Town",
            value: data?.cityOrTown,
          },
          {
            label: "Province",
            value: data?.province,
          },
          {
            label: "State or Territory",
            value: data?.stateOrTerritory,
          },
          {
            label: "Country",
            value: data?.country,
          },
          {
            label: `Zip Code`,
            value: data?.zipCode,
          },
          {
            label: `Postal Code`,
            value: data?.postalCode,
          },
          {
            label: `Date you began living at the address above`,
            value: data?.dateYouBeganLivingAtAddress,
          },
          {
            label: `Date you left the address above`,
            value: data?.dateYouLeftAddress,
          },
          {
            label: `Still lives in there?`,
            value: data?.yetToReturn,
          },
        ],
      },
    },
    {
      label:
        "Is your current physical address also your current mailing address?",
      value: data?.mailAddressSameAsPhysicalAddress,
    },
    {
      section: {
        title: "Applicant’s Mailing Address",
        children: [
          {
            label: "In Care of Name (if any)",
            value: data?.inCareOfNameMail.replace(/,/g, ", "),
          },
          {
            label: "Street Number and Name",
            value: data?.streetNumberAndNameMail.replace(/,/g, ", "),
          },
          {
            label: "Is there an apartment, suite or floor?",
            value: data?.apartmentSuiteOrFloor.replace(/,/g, ", "),
          },
          {
            label: "Address number",
            value: data?.addressNumberMail,
          },
          {
            label: "City or Town",
            value: data?.cityOrTownMail.replace(/,/g, ", "),
          },
          {
            label: "Country",
            value: data?.countryMail.replace(/,/g, ", "),
          },
          {
            label: "State or Territory",
            value: data?.stateOrTerritoryMail.replace(/,/g, ", "),
          },
          {
            label: "Province",
            value: data?.provinceMail,
          },
          {
            label: "Zip Code",
            value: data?.zipCodeMail,
          },
          {
            label: "Postal Code",
            value: data?.postalCodeMail,
          },
        ],
      },
    },
    {
      section: {
        title: "Date Applicant began living in current physical Address",
        children: [
          {
            label: "Date you began living at the address above",
            value: data?.dateYouBeganLivingAtAddress,
          },
        ],
      },
    },
  ];

  return (
    <ReviewBox
      title={title}
      data={section}
      editUrl={FormN400Progress.addressHistory}
    />
  );
};

export default ReviewAddressHistory;
