import styled from "styled-components";
import { FC } from "react";

// import dayjs from "dayjs";

import { ArticleRes } from "../../../api/blogs/types";
import Colors from "../../../styles/Colors";
import { truncateTxt } from "../../../utils";
import { WebPaths } from "../../../routes/data";
import { useNavigate } from "react-router-dom";

interface Props {
  data: ArticleRes;
  showActions?: boolean;
  $removeBoxShadow?: boolean;
}

const NewsCard: FC<Props> = ({ showActions, $removeBoxShadow, data }) => {
  const navigate = useNavigate();
  //   const { mutate, isPending: isDeleting } = useDeleteArticle();
  //   const [open, setOpen] = useState(false);

  //   const onOpenDelete = () => {
  //     setOpen(true);
  //   };

  //   const onCloseDelete = () => {
  //     setOpen(false);
  //   };

  //   const onDelete = () => {
  //     mutate(data.article_id, {
  //       onSuccess: () => {
  //         toast.success(`Article, ${data.title} deleted successfully!`);
  //         onCloseDelete();
  //       },
  //     });
  //   };

  const onView = () => {
    navigate(`${WebPaths.Blogs}/${data.slug}`);
  };

  //   const onEdit = () => {
  //     navigate(`${AdminPaths.NewArticle}?${newTitleQuery}=${data.title}`);
  //   };

  return (
    <>
      <Wrapper $removeBoxShadow={$removeBoxShadow}>
        <Img src={data.image.split("&")[0]} />
        <ContentWrapper>
          <MiddleWrapper>
            <Tag>{data.tags}</Tag>
            {/* <Date>
              Date: {dayjs(data.publication_date).format("DD-MM-YYYY")}
            </Date> */}
          </MiddleWrapper>
          <Title onClick={onView}>{truncateTxt(data.title, 73)}</Title>
          {/* {showActions && (
            <BtnWrapper>
              <Btn onClick={onView}>View</Btn>
              <Btn onClick={onEdit}>Edit</Btn>
              <Btn onClick={onOpenDelete}>Delete</Btn>
            </BtnWrapper>
          )} */}
        </ContentWrapper>
      </Wrapper>
      {/* {open && (
        <TerminateModal
          title={`Are you sure you want to delete this article?`}
          subText={`The article will be deleted permanently.`}
          onClose={onCloseDelete}
          onConfirm={onDelete}
          isLoading={isDeleting}
        />
      )} */}
    </>
  );
};

export default NewsCard;

interface WrapperProps {
  $removeBoxShadow?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  /* width: 380px; */
  /* min-height: 360px; */
  box-shadow: ${({ $removeBoxShadow }) =>
    $removeBoxShadow ? "" : "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"};
  border-radius: 6px;

  padding-bottom: 1rem;
`;

const Img = styled.img`
  border-radius: 6px 6px 0 0;
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const ContentWrapper = styled.div`
  padding: 1rem;
`;

const MiddleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const Tag = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 0.9375rem;
  color: ${Colors.Blue00};
`;

// const Date = styled.div`
//   font-size: 0.625rem;
//   font-weight: 500;
//   line-height: 12px;
//   letter-spacing: 0em;
//   color: ${Colors.GreyA7};
// `;

const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: ${Colors.Black31};
  transition: 300ms all ease;
  text-transform: capitalize;

  cursor: pointer;

  &:hover {
    color: ${Colors.Blue00};
    text-decoration: underline;
  }
`;

// const BtnWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 11px;
//   margin-top: 16px;
// `;

// const Btn = styled.button`
//   height: 30px;
//   padding: 4px 12px;
//   border: 1px solid ${Colors.Black31};
//   border-radius: 3px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-size: 0.75rem;
//   font-weight: 500;
//   line-height: 12px;
//   letter-spacing: 0em;
// `;
