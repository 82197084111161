import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createArticle,
  deleteArticle,
  getAllArticle,
  getSingleArticle,
  updateArticle,
} from "../../api/blogs";
import { parseError } from "../../utils";
import { ArticleQueryKeys } from "./types";

export const useCreateArticle = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createArticle,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ArticleQueryKeys.getAllArticle],
      });
    },
    onError: (error) => parseError(error),
  });
};

export const useUpdateArticle = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateArticle,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ArticleQueryKeys.getAllArticle],
      });
    },
    onError: (error) => parseError(error),
  });
};

export const useGetAllArticle = () => {
  return useQuery({
    queryKey: [ArticleQueryKeys.getAllArticle],
    queryFn: getAllArticle,
    select: (data) => data.data.data,
  });
};

export const useGetSingleArticle = (articleId: string) => {
  return useQuery({
    queryKey: [ArticleQueryKeys.getSingleArticle, articleId],
    enabled: !!articleId,
    queryFn: () => getSingleArticle(articleId),
    select: (data) => data.data.data,
  });
};

export const useDeleteArticle = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteArticle,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ArticleQueryKeys.getAllArticle],
      });
    },
    onError: (error) => parseError(error),
  });
};
