import { recurringBillingData } from "./data";
import { CheckIcon } from "@heroicons/react/24/solid";

const MonthlyBilling = () => {
  return (
    <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">
      {recurringBillingData.map((tier, tierIdx) => (
        <div
          key={tier.id}
          className={`
                  ${
                    tier.featured
                      ? "relative bg-gray-800 shadow-2xl"
                      : "bg-white/60 sm:mx-8 lg:mx-0"
                  } ${
            tier.featured
              ? ""
              : tierIdx === 0
              ? "rounded-t-3xl sm:rounded-b-none lg:rounded-bl-3xl lg:rounded-tr-none"
              : "sm:rounded-t-none lg:rounded-bl-none lg:rounded-tr-3xl"
          } rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10 `}
        >
          <h3
            id={tier.id}
            className={`
                  ${
                    tier.featured ? "text-Blue00" : "text-Blue00"
                  } text-base font-semibold leading-7
                `}
          >
            {tier.name}
          </h3>
          <p className="mt-4 flex items-baseline gap-x-2">
            <span
              className={`
                    ${
                      tier.featured ? "text-white" : "text-gray-900"
                    } text-5xl font-semibold tracking-tight
                  `}
            >
              {tier.priceMonthly}
            </span>
            <span
              className={`
                    ${
                      tier.featured ? "text-gray-400" : "text-gray-500"
                    } text-base
                  `}
            >
              /month
            </span>
          </p>
          <p
            className={`
                  ${
                    tier.featured ? "text-gray-300" : "text-gray-600"
                  } mt-6 text-base leading-7
                )`}
          >
            {tier.description}
          </p>
          <ul
            className={`
                  ${
                    tier.featured ? "text-gray-300" : "text-gray-600"
                  } mt-8 space-y-3 text-sm leading-6 sm:mt-10
                `}
          >
            {tier.features.map((feature) => (
              <li key={feature} className="flex gap-x-3">
                <CheckIcon
                  aria-hidden="true"
                  className={`
                       ${
                         tier.featured ? "text-Blue00" : "text-Blue00"
                       } h-6 w-5 flex-none
                      `}
                />
                {feature}
              </li>
            ))}
          </ul>
          <a
            href={tier.href}
            aria-describedby={tier.id}
            className={`
                  ${
                    tier.featured
                      ? "bg-Blue00 text-white shadow-sm hover:bg-Blue00 focus-visible:outline-Blue00"
                      : "text-Blue00 ring-1 ring-inset ring-Blue00 hover:ring-Blue00 focus-visible:outline-Blue00"
                  }
                  mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10
                `}
          >
            Get started today
          </a>
        </div>
      ))}
    </div>
  );
};

export default MonthlyBilling;
