import React from "react";
import Constaint from "../../layout/components/Constaint";
import styled from "styled-components";
import Colors from "../../../../styles/Colors";
import { useParams } from "react-router-dom";
import { ReactComponent as Document } from "../../../../images/web/Document Review.svg";
import { ReactComponent as SolarDocument } from "../../../../images/web/solar_document-add-broken.svg";
import { ReactComponent as File } from "../../../../images/web/File Upload.svg";
import { getFormatedFormName } from "../../components/data";

const Steps = () => {
  const { formType } = useParams();

  return (
    <Wrapper>
      <Constaint>
        <Container>
          <Quote>
            “Immigration is not just a journey to a new country, it's a journey
            to finding yourself and building a better future for your family." -
            SeamlessVisa
          </Quote>
          <StepsWrapper>
            <StepsTitle>Steps</StepsTitle>
            <StepsCardsWrapper>
              <StepsCard>
                <IconWrapper>
                  <Document />
                </IconWrapper>
                <StepsCardName>Eligibility check</StepsCardName>
                <StepsCardPara>
                  Before starting the{" "}
                  <span
                    className={`font-bold ${
                      formType === "ds160" ? "capitalize" : "uppercase"
                    }`}
                  >
                    {formType === "ds160"
                      ? "Nonimmigrant Visa"
                      : getFormatedFormName(formType)}
                  </span>{" "}
                  application process, it's important to check your eligibility
                  by reviewing the USCIS eligibility requirements.
                </StepsCardPara>
              </StepsCard>
              <StepsCard>
                <IconWrapper>
                  <SolarDocument className="not_colored" />
                </IconWrapper>
                <StepsCardName>Form Filling</StepsCardName>
                <StepsCardPara>
                  Once you have determined your eligibility, you can begin
                  filling out the{" "}
                  <span
                    className={`font-bold ${
                      formType === "ds160" ? "capitalize" : "uppercase"
                    }`}
                  >
                    {formType === "ds160"
                      ? "Nonimmigrant Visa"
                      : getFormatedFormName(formType)}
                  </span>{" "}
                  form. This includes providing personal information, answering
                  questions about your background, and more.
                </StepsCardPara>
              </StepsCard>
              <StepsCard>
                <IconWrapper>
                  <File />
                </IconWrapper>
                <StepsCardName>Review & Submit</StepsCardName>
                <StepsCardPara>
                  After completing the{" "}
                  <span
                    className={`font-bold ${
                      formType === "ds160" ? "capitalize" : "uppercase"
                    }`}
                  >
                    {formType === "ds160"
                      ? "Nonimmigrant Visa"
                      : getFormatedFormName(formType)}
                  </span>{" "}
                  form, our experts reviews it carefully to ensure accuracy and
                  completeness before submitting to USCIS for processing.
                </StepsCardPara>
              </StepsCard>
            </StepsCardsWrapper>
          </StepsWrapper>
        </Container>
      </Constaint>
    </Wrapper>
  );
};

export default Steps;

const Wrapper = styled.div`
  padding: 64px 0 100px 0;
  background-color: ${Colors.WhiteF9};
`;

const Container = styled.div`
  color: ${Colors.Black31};
  padding-bottom: 100px;
  border-bottom: 1px solid ${Colors.GreyA7};
`;

const Quote = styled.div`
  font-size: 1.25rem;
  text-align: center;
  height: 182px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.White};
  border-top: 20px solid ${Colors.Blue00};
  padding: 80px 55px 55px 55px;
  margin-bottom: 152px;

  @media screen and (min-width: 768px) {
    padding: 80px 130px 55px 130px;
  }

  &::before,
  &::after {
    position: absolute;
    width: 20px;
    height: 95%;
    content: "";
    top: 0;
    background-color: ${Colors.Blue00};
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
`;

const StepsWrapper = styled.div``;

const StepsTitle = styled.h5`
  color: ${Colors.Black};
  font-size: 1.5rem;
  position: relative;
  padding-bottom: 5px;

  &::before {
    position: absolute;
    width: 24px;
    height: 3px;
    content: "";
    left: 0;
    bottom: 0;
    background-color: ${Colors.Blue00};
  }
`;

const StepsCardsWrapper = styled.div`
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const StepsCard = styled.div`
  width: 350px;
  height: 382px;
  padding: 32px 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.White};
  border-radius: 12px;
  margin-bottom: 16px;

  @media screen and (min-width: 768px) {
    width: 315px;
    margin-bottom: 0;
  }
`;

const StepsCardName = styled.div`
  margin: 25px 0 30px 0;
  font-size: 1.25rem;
  font-weight: bold;
`;

const StepsCardPara = styled.p`
  font-size: 1rem;
  text-align: center;
`;

const IconWrapper = styled.div`
  width: 94px;
  height: 94px;
  background-color: ${Colors.Blue00 + "12"};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  flex-shrink: 0;

  & > svg {
    width: 48px;
    height: 48px;
  }

  & > svg:not(.not_colored) {
    & > path {
      fill: ${Colors.Blue00};
    }
  }
`;
