import FormSectionWrapper from "../../components/FormSectionWrapper";
import {
  ArrayWrapper,
  Info,
  InfoBold,
  SubTitle,
  VerticalFormWrapper,
  Wrapper,
} from "./styles";
import { FieldArray, Form, Formik } from "formik";
import SelectField from "../../components/formik fields/SelectField";
import FormBtn from "../../components/FormBtn";
import styled from "styled-components";
import InputField from "../../components/formik fields/InputField";
import RadioComp from "../../components/RadioComp";
import CheckBoxField from "../../components/formik fields/CheckBoxField";
import { processReviewData, SocialMediaData } from "./data";
import {
  GenericYesNo,
  GenericYesNoOptionsValue,
  initCheck,
  initInput,
  getSelectedOption,
  initSelect,
} from "../../components/data";
import {
  useGetDS160AddressAndPhone,
  useSaveDS160AddressAndPhone,
} from "../../../../hooks/ds160/usDS160";
import { saveDS160AddressAndPhoneObj } from "../../../../api/ds160/types";
import { AddressAndPhoneValidation } from "./validation";
import { Modify, SingleOption } from "../../../../types/types";
import GroupedSection from "./GroupedSection";
import AddAnotherBtn from "./AddAnotherBtn";
import { resetDynamicFields } from "../../components/data";
import useToNextSection from "../../../../hooks/useToNextSection";
import useCountries from "../../components/useCountries";
import useStatesByCountry from "../../components/useStatesByCountry";
import useAiReview from "./useAiReview";
import { ReviewAddressAndPhoneSection } from "./Review/components/data";
import { BusinessQueryKey } from "../../../../api/utils/types";
import { useSearchParams } from "react-router-dom";

const initialPhoneNumberArray = {
  additionalPhoneNumber: "",
};

const initialEmailArray = {
  additionalEmail: "",
};

const initialSocialMediaArray = {
  socialMedia: null,
  socialMediaIdentifier: "",
};

const initialAdditionalSocialMediaArray = {
  additionalSocialMedia: "",
  additionalSocialMediaHandle: "",
};

export interface phoneNumberArrayPropsPayload {
  additionalPhoneNumber: string;
}
export interface emailArrayPropsPayload {
  additionalEmail: string;
}
export interface socialMediaArrayPropsPayload {
  socialMedia: SingleOption | null;
  socialMediaIdentifier: string;
}

export interface additionalSocialMediaArrayPropsPayload {
  additionalSocialMedia: string;
  additionalSocialMediaHandle: string;
}

export interface AddressAndPhoneData
  extends Modify<
    Omit<
      saveDS160AddressAndPhoneObj,
      | "socialMedia"
      | "socialMediaIdentifier"
      | "additionalPhoneNumber"
      | "additionalEmail"
      | "additionalSocialMedia"
      | "additionalSocialMediaHandle"
    >,
    {
      phoneNumberArray: phoneNumberArrayPropsPayload[];
      emailArray: emailArrayPropsPayload[];
      socialMediaArray: socialMediaArrayPropsPayload[];
      countryOrRegion: SingleOption | null;
      stateOrProvince: SingleOption | null;
      mailCountryOrRegion: SingleOption | null;
      mailStateOrProvince: SingleOption | null;
      additionalSocialMediaArray: additionalSocialMediaArrayPropsPayload[];
    }
  > {}

const AddressAndPhone = () => {
  const { toNextSection } = useToNextSection();
  const [searchParams] = useSearchParams();
  const getApplicationId = searchParams.get(BusinessQueryKey.appId) || "";
  const { data, isFetching, isError } =
    useGetDS160AddressAndPhone(getApplicationId);
  const { mutate, isLoading: isSaving } = useSaveDS160AddressAndPhone();
  const { postAiReview, riskData, riskError, riskLoading, riskSaving } =
    useAiReview();

  const {
    formattedCountryData: phyFormattedCountryData,
    countryLoading: phyCountryLoading,
  } = useCountries();

  const {
    formattedCountryData: mailFormattedCountryData,
    countryLoading: mailCountryLoading,
  } = useCountries();

  const {
    formattedStateData: phyFormattedStateData,
    stateLoading: phyStateLoading,
    setCountry: phySetCountry,
    selectedCountry: phySelectedCountry,
  } = useStatesByCountry(data?.countryOrRegion);

  const {
    formattedStateData: mailFormattedStateData,
    stateLoading: mailStateLoading,
    setCountry: mailSetCountry,
    selectedCountry: mailSelectedCountry,
  } = useStatesByCountry(data?.mailCountryOrRegion);

  const savedPhoneNumberArray = () => {
    if (data?.additionalPhoneNumber) {
      const additionalPhoneNumberData = data?.additionalPhoneNumber
        .split(",")
        .map((ev) => ({ additionalPhoneNumber: ev }));

      return additionalPhoneNumberData.map((ev, i) => ({
        ...ev,
      }));
    } else return [initialPhoneNumberArray];
  };

  const savedEmailArray = () => {
    if (data?.additionalEmail) {
      const additionalEmailData = data?.additionalEmail
        .split(",")
        .map((ev) => ({ additionalEmail: ev }));

      return additionalEmailData.map((ev, i) => ({
        ...ev,
      }));
    } else return [initialEmailArray];
  };

  const savedSocialMediaArray = () => {
    if (data?.socialMedia || data?.socialMediaIdentifier) {
      const socialMediaIdentifierData = data?.socialMediaIdentifier
        .split(",")
        .map((ev) => ({ socialMediaIdentifier: ev }));
      const socialMediaData = data?.socialMedia.split(",").map((ev) => ({
        socialMedia: getSelectedOption(SocialMediaData, ev),
      }));

      return socialMediaData.map((ev, i) => ({
        ...ev,
        ...socialMediaIdentifierData[i],
      }));
    } else return [initialSocialMediaArray];
  };

  const savedAdditionalSocialMediaArray = () => {
    if (data?.additionalSocialMedia || data?.additionalSocialMediaHandle) {
      const additionalSocialMediaData = data?.additionalSocialMedia
        .split(",")
        .map((ev) => ({ additionalSocialMedia: ev }));

      const additionalSocialMediaHandleData = data?.additionalSocialMediaHandle
        .split(",")
        .map((ev) => ({ additionalSocialMediaHandle: ev }));

      return additionalSocialMediaData.map((ev, i) => ({
        ...ev,
        ...additionalSocialMediaHandleData[i],
      }));
    } else return [initialAdditionalSocialMediaArray];
  };

  const onSubmit = (values: AddressAndPhoneData) => {
    const {
      phoneNumberArray,
      emailArray,
      socialMediaArray,
      countryOrRegion,
      mailCountryOrRegion,
      stateOrProvince,
      mailStateOrProvince,
      additionalSocialMediaArray,
      ...rest
    } = values;

    const payload = {
      ...rest,
      additionalPhoneNumber: phoneNumberArray
        .map((ev) => ev.additionalPhoneNumber)
        .join(","),
      additionalEmail: emailArray.map((ev) => ev.additionalEmail).join(","),
      socialMedia: socialMediaArray
        .map((ev) => ev.socialMedia?.value || "")
        .join(","),
      socialMediaIdentifier: socialMediaArray
        .map((ev) => ev.socialMediaIdentifier)
        .join(","),
      countryOrRegion: countryOrRegion?.value || "",
      stateOrProvince: stateOrProvince?.value || "",
      mailCountryOrRegion: mailCountryOrRegion?.value || "",
      mailStateOrProvince: mailStateOrProvince?.value || "",
      additionalSocialMedia: additionalSocialMediaArray
        .map((ev) => ev.additionalSocialMedia)
        .join(","),
      additionalSocialMediaHandle: additionalSocialMediaArray
        .map((ev) => ev.additionalSocialMediaHandle)
        .join(","),
    };

    const section = ReviewAddressAndPhoneSection(payload);
    const result = processReviewData(section);

    const riskPayload = {
      ...riskData,
      address_phone: {
        ...riskData?.address_phone,
        ...result,
      },
    };

    const onSave = () => {
      mutate(
        { ...payload, applicationId: getApplicationId },
        {
          onSuccess: toNextSection,
        }
      );
    };

    postAiReview({
      riskPayload: riskPayload,
      onSave,
    });
  };

  const initialValues: AddressAndPhoneData = {
    homeAddress: initInput(data?.homeAddress),
    streetAddressLine1: initInput(data?.streetAddressLine1),
    streetAddressLine2: initInput(data?.streetAddressLine2),
    city: initInput(data?.city),
    stateOrProvince: initSelect(phyFormattedStateData, data?.stateOrProvince),
    zipCode: initInput(data?.zipCode),
    countryOrRegion: initSelect(phyFormattedCountryData, data?.countryOrRegion),
    mailAddressSameAsHomeAddress: initInput(data?.mailAddressSameAsHomeAddress),
    mailHomeAddress: initInput(data?.mailHomeAddress),
    mailStreetAddressLine1: initInput(data?.mailStreetAddressLine1),
    mailStreetAddressLine2: initInput(data?.mailStreetAddressLine2),
    mailCity: initInput(data?.mailCity),
    mailStateOrProvince: initSelect(
      mailFormattedStateData,
      data?.mailStateOrProvince
    ),
    mailZipCode: initInput(data?.mailZipCode),
    mailCountryOrRegion: initSelect(
      mailFormattedCountryData,
      data?.mailCountryOrRegion
    ),
    primaryPhoneNumber: initInput(data?.primaryPhoneNumber),
    secondaryPhoneNumber: initInput(data?.secondaryPhoneNumber),
    doesNotApplySecPN: initCheck(data?.doesNotApplySecPN),
    workPhoneNumber: initInput(data?.workPhoneNumber),
    doesNotApplyWorkPN: initCheck(data?.doesNotApplyWorkPN),
    emailArray: savedEmailArray(),
    anyOtherPhoneNumber: initInput(data?.anyOtherPhoneNumber),
    phoneNumberArray: savedPhoneNumberArray(),
    anyOtherEmail: initInput(data?.anyOtherEmail),
    socialMediaArray: savedSocialMediaArray(),
    presenceOnWebOrApp: initInput(data?.presenceOnWebOrApp),
    additionalSocialMediaArray: savedAdditionalSocialMediaArray(),
    // additionalSocialMedia: initInput(data?.additionalSocialMedia),
    //
    email: initInput(data?.email),
    // additionalSocialMediaHandle: initInput(data?.additionalSocialMediaHandle),
  };

  return (
    <FormSectionWrapper
      isLoading={isFetching || riskLoading}
      isError={isError || riskError}
    >
      <Wrapper>
        <Info>
          <InfoBold>
            NOTE: Provide the following information concerning your Address.
          </InfoBold>
        </Info>
        <Formik
          initialValues={initialValues}
          validationSchema={AddressAndPhoneValidation}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <InputField
                  label="Home Address"
                  placeholder=""
                  name="homeAddress"
                />

                <InputField
                  label="Street Address Line 1"
                  placeholder=""
                  name="streetAddressLine1"
                />

                <InputField
                  label="Street Address Line 2"
                  coloredLabel="(Optional)"
                  placeholder=""
                  name="streetAddressLine2"
                />

                <SelectField
                  label="Country/Region"
                  placeholder=""
                  name="countryOrRegion"
                  options={phyFormattedCountryData}
                  isLoading={phyCountryLoading}
                  onChange={(value) => {
                    phySetCountry(value.label);
                    formik.setFieldValue(`stateOrProvince`, null);
                  }}
                />

                <SelectField
                  label="State/Province"
                  placeholder=""
                  name="stateOrProvince"
                  options={phyFormattedStateData}
                  isLoading={phyStateLoading}
                  isDisabled={!phySelectedCountry}
                />

                <InputField label="City" placeholder="" name="city" />

                <InputField
                  label="Postal Code/Zip Code"
                  placeholder=""
                  name="zipCode"
                />

                <Sub>MAILING ADDRESS</Sub>

                <RadioComp
                  title="Is your mailing address the same as your home address?"
                  name="mailAddressSameAsHomeAddress"
                  options={GenericYesNo}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "mailHomeAddress", value: "" },
                      { name: "mailStreetAddressLine1", value: "" },
                      { name: "mailStreetAddressLine2", value: "" },
                      { name: "mailCity", value: "" },
                      { name: "mailStateOrProvince", value: null },
                      { name: "mailCountryOrRegion", value: null },
                      { name: "mailZipCode", value: "" },
                      { name: "primaryPhoneNumber", value: "" },
                      { name: "secondaryPhoneNumber", value: "" },
                      { name: "doesNotApplySecPN", value: false },
                      { name: "workPhoneNumber", value: "" },
                      { name: "doesNotApplyWorkPN", value: false },
                    ]);
                  }}
                />

                {/* IF MAILING ADDRESS IS SAME AS HOME ADDRESS IS "NO" */}
                {formik.values.mailAddressSameAsHomeAddress ===
                  GenericYesNoOptionsValue.no && (
                  <div className="px-2 py-3 border rounded">
                    <span className="block mb-5 font-bold">
                      Provide your mailing address
                    </span>
                    <div className="mb-4">
                      <InputField
                        label="Home Address"
                        placeholder=""
                        name="mailHomeAddress"
                      />
                    </div>

                    <div className="mb-4">
                      <InputField
                        label="Street Address Line 1"
                        placeholder=""
                        name="mailStreetAddressLine1"
                      />
                    </div>

                    <div className="mb-4">
                      <InputField
                        label="Street Address Line 2"
                        coloredLabel="(Optional)"
                        placeholder=""
                        name="mailStreetAddressLine2"
                      />
                    </div>

                    <div className="mb-4">
                      <SelectField
                        label="Country/Region"
                        placeholder=""
                        name="mailCountryOrRegion"
                        options={mailFormattedCountryData}
                        isLoading={mailCountryLoading}
                        onChange={(value) => {
                          mailSetCountry(value.label);
                          formik.setFieldValue(`mailStateOrProvince`, null);
                        }}
                      />
                    </div>

                    <div className="mb-4">
                      <SelectField
                        label="Sate/Province"
                        placeholder=""
                        name="mailStateOrProvince"
                        options={mailFormattedStateData}
                        isLoading={mailStateLoading}
                        isDisabled={!mailSelectedCountry}
                      />
                    </div>

                    <div className="mb-4">
                      <InputField label="City" placeholder="" name="mailCity" />
                    </div>

                    <div className="">
                      <InputField
                        label="Postal Code/Zip Code"
                        placeholder=""
                        name="mailZipCode"
                      />
                    </div>
                  </div>
                )}

                {/* IF MAILING ADDRESS IS SAME AS HOME ADDRESS IS "YES" */}
                {formik.values.mailAddressSameAsHomeAddress ===
                  GenericYesNoOptionsValue.yes && (
                  <div className="px-2 py-3 border rounded">
                    <div className="mb-4">
                      <InputField
                        label="Primary Phone Number"
                        placeholder=""
                        name="primaryPhoneNumber"
                      />
                    </div>

                    <div className="flex flex-col gap-2 mb-4">
                      <InputField
                        label="Secondary Phone Number"
                        placeholder=""
                        name="secondaryPhoneNumber"
                        isDisabled={!!formik.values.doesNotApplySecPN}
                      />
                      <CheckBoxField
                        name="doesNotApplySecPN"
                        label="Does not apply"
                        onChange={() => {
                          resetDynamicFields(formik, [
                            { name: "secondaryPhoneNumber", value: "" },
                          ]);
                        }}
                      />
                    </div>

                    <div className="flex flex-col gap-2 mb-4">
                      <InputField
                        label="Work Phone Number"
                        placeholder=""
                        name="workPhoneNumber"
                        isDisabled={!!formik.values.doesNotApplyWorkPN}
                      />
                      <CheckBoxField
                        name="doesNotApplyWorkPN"
                        label="Does not apply"
                        onChange={() => {
                          resetDynamicFields(formik, [
                            { name: "workPhoneNumber", value: "" },
                          ]);
                        }}
                      />
                    </div>
                  </div>
                )}

                {/*  NEW DYNAMIC QUESTION "USED  ANY OTHER NUMBER" */}

                <RadioComp
                  title="Have you used any other phone number in the last five (5) years?"
                  name="anyOtherPhoneNumber"
                  options={GenericYesNo}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      {
                        name: "phoneNumberArray",
                        value: [initialPhoneNumberArray],
                      },
                    ]);
                  }}
                />

                {/* IF USED ANY OTHER NUMBER IS "YES" */}
                {formik.values.anyOtherPhoneNumber ===
                  GenericYesNoOptionsValue.yes && (
                  <div className="px-2 py-3 border rounded">
                    <FieldArray
                      name="phoneNumberArray"
                      render={(arrayHelpers) => (
                        <ArrayWrapper>
                          {formik.values.phoneNumberArray.map((_, index) => (
                            <GroupedSection
                              key={index}
                              index={index}
                              onCancel={() => arrayHelpers.remove(index)}
                            >
                              <InputField
                                label={`Additional Phone Number ${
                                  formik.values.phoneNumberArray.length === 1
                                    ? ""
                                    : index + 1
                                }`}
                                placeholder=""
                                name={`phoneNumberArray[${index}].additionalPhoneNumber`}
                              />
                            </GroupedSection>
                          ))}
                          <AddAnotherBtn
                            onClick={() =>
                              arrayHelpers.push(initialPhoneNumberArray)
                            }
                          />
                        </ArrayWrapper>
                      )}
                    />
                  </div>
                )}

                <InputField label="Email Address" placeholder="" name="email" />

                {/*  NEW DYNAMIC QUESTION "USED  ANY OTHER EMAIL" */}
                <RadioComp
                  title="Have you used any other email address in the last five (5) years?"
                  name="anyOtherEmail"
                  options={GenericYesNo}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      {
                        name: "emailArray",
                        value: [initialEmailArray],
                      },
                    ]);
                  }}
                />

                {/* IF USED ANY OTHER EMAIL IS "YES" */}
                {formik.values.anyOtherEmail ===
                  GenericYesNoOptionsValue.yes && (
                  <div className="px-2 py-3 border rounded">
                    <FieldArray
                      name="emailArray"
                      render={(arrayHelpers) => (
                        <ArrayWrapper>
                          {formik.values.emailArray.map((_, index) => (
                            <GroupedSection
                              key={index}
                              index={index}
                              onCancel={() => arrayHelpers.remove(index)}
                            >
                              <InputField
                                label={`Additional Email Address ${
                                  formik.values.emailArray.length === 1
                                    ? ""
                                    : index + 1
                                }`}
                                placeholder=""
                                name={`emailArray[${index}].additionalEmail`}
                              />
                            </GroupedSection>
                          ))}
                          <AddAnotherBtn
                            onClick={() => arrayHelpers.push(initialEmailArray)}
                          />
                        </ArrayWrapper>
                      )}
                    />
                  </div>
                )}

                <div className="px-2 py-3 border rounded">
                  <FieldArray
                    name="socialMediaArray"
                    render={(arrayHelpers) => (
                      <ArrayWrapper>
                        {formik.values.socialMediaArray.map((_, index) => (
                          <GroupedSection
                            key={index}
                            index={index}
                            onCancel={() => arrayHelpers.remove(index)}
                          >
                            <div className="mb-4">
                              <SelectField
                                name={`socialMediaArray[${index}].socialMedia`}
                                label="Social Media"
                                placeholder="-Select-"
                                options={SocialMediaData}
                              />
                            </div>

                            <div className="mb-4">
                              <InputField
                                label="Social Media Identifier"
                                placeholder=""
                                name={`socialMediaArray[${index}].socialMediaIdentifier`}
                              />
                            </div>
                          </GroupedSection>
                        ))}
                        <AddAnotherBtn
                          onClick={() =>
                            arrayHelpers.push(initialSocialMediaArray)
                          }
                        />
                      </ArrayWrapper>
                    )}
                  />
                </div>

                <RadioComp
                  title="Do you wish to provide information about your presence and any other websites or applications you have used within the last 5 years to create or share content (photos videos updates)"
                  name="presenceOnWebOrApp"
                  options={GenericYesNo}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      {
                        name: "additionalSocialMediaArray",
                        value: [initialAdditionalSocialMediaArray],
                      },
                    ]);
                  }}
                />

                {/* IF YES */}

                {formik.values.presenceOnWebOrApp ===
                  GenericYesNoOptionsValue.yes && (
                  <div className="px-2 py-3 border rounded">
                    <div className="text-[14px] font-bold mb-6">
                      Provide the name of the platform and the associated unique
                      social media identifier username or handle for each social
                      media platform you would like to list. This does not
                      include private messaging or person-to-person messaging
                      services, such as WhatsApp.
                    </div>

                    <div className="px-2 py-3 border rounded">
                      <FieldArray
                        name="additionalSocialMediaArray"
                        render={(arrayHelpers) => (
                          <ArrayWrapper>
                            {formik.values.additionalSocialMediaArray.map(
                              (_, index) => (
                                <GroupedSection
                                  key={index}
                                  index={index}
                                  onCancel={() => arrayHelpers.remove(index)}
                                >
                                  <div className="mb-4">
                                    <InputField
                                      name={`additionalSocialMediaArray[${index}].additionalSocialMedia`}
                                      label="Additional social media platform"
                                      placeholder=""
                                    />
                                  </div>

                                  <div className="mb-4">
                                    <InputField
                                      name={`additionalSocialMediaArray[${index}].additionalSocialMediaHandle`}
                                      label="Additional social media handle"
                                      placeholder=""
                                    />
                                  </div>
                                </GroupedSection>
                              )
                            )}
                            <AddAnotherBtn
                              onClick={() =>
                                arrayHelpers.push(initialSocialMediaArray)
                              }
                            />
                          </ArrayWrapper>
                        )}
                      />
                    </div>
                  </div>
                )}

                {/* end both */}
              </FormWrapper>
              <FormBtn
                isLoading={isSaving || riskSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default AddressAndPhone;

const FormWrapper = styled(VerticalFormWrapper)`
  & > div {
    /* width: 50%; */
  }
`;

export const Sub = styled(SubTitle)`
  margin-bottom: 0;
`;
