import React, { FC } from "react";
import { saveDS160USContactObj } from "../../../../../../api/ds160/types";
import ReviewBox from "../../../../components/Review/ReviewBox";
import { FormDS160Progress } from "../../types";
import { GenericDs160ReviewProps } from "./DownloadPage";
import PdfReviewBox from "../../../../components/Review/Pdf Review/PdfReviewBox";
import { ReviewUSContactSection } from "./data";

interface Props extends GenericDs160ReviewProps {
  data?: saveDS160USContactObj;
}

const ReviewUSContact: FC<Props> = ({ title, data, isDownload }) => {
  const section = data ? ReviewUSContactSection(data) : [];

  const compProps = {
    title: title,
    data: section,
    editUrl: FormDS160Progress.usContact,
  };

  return isDownload ? (
    <PdfReviewBox {...compProps} />
  ) : (
    <ReviewBox {...compProps} />
  );
};

export default ReviewUSContact;
