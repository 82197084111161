import React, { FC } from "react";
import styled from "styled-components";
import Colors from "../../../../styles/Colors";
import { useField } from "formik";

interface Props {
  isDisabled?: boolean;
  value: string;
  name: string;
  label: string;
  boxSize?: string;
  onChange?: (value: string, name: string) => void;
  isChecked?: boolean;
}

const RadioField: FC<Props> = ({
  isDisabled,
  value,
  label,
  boxSize,
  onChange,
  isChecked,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const errorClass = `${meta.touched && meta.error && "error"}`;

  const onChangeValue = (value: string) => {
    onChange && onChange(value, props.name);
    helpers.setValue(value);
  };

  return (
    <Radio
      isDisabled={isDisabled}
      boxSize={boxSize}
      onClick={() => onChangeValue(value)}
    >
      <input
        {...field}
        {...props}
        type="radio"
        value={value}
        checked={value === field.value}
        className={errorClass}
        // onChange={() => onChangeValue(value)}
        // onClick={() => onChangeValue(value)}
        disabled={isDisabled}
      />{" "}
      {label}
    </Radio>
  );
};

export default RadioField;

interface RadioProps {
  isDisabled?: boolean;
  boxSize?: string;
}

const Radio = styled.div<RadioProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ isDisabled }) => (isDisabled ? "#1b092c99" : Colors.Dark6B)};
  font-size: 0.875rem;

  cursor: pointer;

  & > input[type="radio"] {
    width: ${({ boxSize }) => boxSize && boxSize};
    /* height: ${({ boxSize }) => boxSize && boxSize}; */
    flex-shrink: 0;
    position: relative;
    accent-color: ${Colors.Blue00};
  }

  & > input[type="radio"].error::before {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: 0.3s;
    background-color: #ff000026;
    box-shadow: 0px 0px 0px 3px #ff000051;
    border-radius: 50%;
    opacity: 1;
    cursor: pointer;
  }

  &:hover {
    & > input[type="radio"]:hover:before {
      background-color: #0076e91f;
      box-shadow: 0px 0px 0px 5px #0076e91f;
      border-radius: 50%;
      opacity: 1;
      cursor: pointer;
    }

    & > input[type="radio"]:before {
      content: "";
      top: 0;
      left: 0;
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transition: 0.3s;
    }
  }
`;
