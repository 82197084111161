import React, { useState } from "react";
import styled from "styled-components";
import Colors from "../../../../../../styles/Colors";
import { Form, Formik } from "formik";
import InputField from "../../../../components/formik fields/InputField";
import { useSnapshot } from "valtio";
import { BusinessProfileSettingsValidation } from "../../validation";
import { SettingsTitle, SettingsWrapper } from "../../styles";
import { toast } from "react-toastify";
import Spinner from "../../../../../../utils/Spinner";
import TerminateModal from "../../../../components/TerminateModal";
import { loginFailed } from "../../../../../../utils";
import { useNavigate } from "react-router-dom";
import { WebPaths } from "../../../../../../routes/data";
import PhoneFormatter from "../../../../components/formik fields/PhoneFormatter";
import { BusinessSignUpFormData } from "../../../../auth/components/SignUp";
import { initSelect } from "../../../../components/data";
import { businessUserDataStore } from "../../../../../../store/businessUserData";
import { BusinessTypeData } from "../../../../auth/components/data";
import {
  useBusinessEditProfile,
  useDeleteBusinessAccount,
} from "../../../../../../hooks/business/useAuth";
import SelectField from "../../../../components/formik fields/SelectField";

export interface BusinessProfileSettingsFormData
  extends Omit<
    BusinessSignUpFormData,
    "businessPassword" | "businessConfirmPassword" | "businessAgreement"
  > {}

const BusinessProfileSettings = () => {
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const { businessUserData } = useSnapshot(businessUserDataStore);
  const [open, setOpen] = useState(false);
  const { mutate, isLoading } = useBusinessEditProfile();
  const { mutate: deletemutate, isLoading: isDeleting } =
    useDeleteBusinessAccount();

  const onOpenDelete = () => {
    setOpen(true);
  };

  const onCloseDelete = () => {
    setOpen(false);
  };

  const onDelete = () => {
    if (!businessUserData) return;

    deletemutate(undefined, {
      onSuccess: () => {
        toast.success(`Account deleted successfully!`);
        onCloseDelete();
        loginFailed();
        navigate(WebPaths.Join);
      },
    });
  };

  const onSubmit = (values: BusinessProfileSettingsFormData) => {
    if (!values.businessType) return;

    const payload = {
      businessType: values.businessType?.value,
      businessName: values.businessName,
      businessNumber: values.businessNumber,
      businessAddress: values.businessAddress,
    };

    mutate(payload, {
      onSuccess: () => {
        toast.success("Profile successfully updated!");
        setIsEdit(false);
      },
    });
  };

  return (
    <>
      <SettingsWrapper>
        <SettingsTitle>Profile Settings</SettingsTitle>{" "}
        <Formik
          initialValues={
            {
              businessType: initSelect(
                BusinessTypeData,
                businessUserData?.businessInfo.businessType
              ),
              businessName: businessUserData?.businessInfo.name || "",
              businessNumber: businessUserData?.businessInfo.phoneNumber || "",
              businessEmail: businessUserData?.businessInfo.email || "",
              businessAddress:
                businessUserData?.businessInfo.businessAddress || "",
            } as BusinessProfileSettingsFormData
          }
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={BusinessProfileSettingsValidation}
        >
          <Former>
            <SelectField
              name="businessType"
              label="What type of business do you own?"
              options={BusinessTypeData}
              isDisabled={!isEdit}
            />
            <InputField
              name="businessName"
              label="Business Name"
              placeholder=""
              isDisabled={!isEdit}
            />

            <PhoneFormatter
              name="businessNumber"
              label="Business Number"
              isDisabled={!isEdit}
            />

            <InputField
              name="businessEmail"
              label="Business Email"
              placeholder=""
              isDisabled
            />

            <InputField
              name="businessAddress"
              label="Business Address"
              placeholder=""
              isDisabled={!isEdit}
            />

            <FormWrapper>
              {isEdit ? (
                <>
                  {isLoading && <Spinner color={Colors.White} size={14} />}
                  <UpdateBtn type="submit" disabled={isLoading}>
                    Update profile
                  </UpdateBtn>
                </>
              ) : (
                <EditBtn type="button" onClick={() => setIsEdit(true)}>
                  Edit profile
                </EditBtn>
              )}
            </FormWrapper>
          </Former>
        </Formik>
        <DeleteWrapper>
          <div>
            <DeleteTitle>Delete account ?</DeleteTitle>
            <DeletePara>
              By deleting your account, all data will be lost.
            </DeletePara>
          </div>
          <DeleteBtn onClick={onOpenDelete}>Delete account</DeleteBtn>
        </DeleteWrapper>
      </SettingsWrapper>

      {open && (
        <TerminateModal
          title={`Are you sure you want to delete this business account?`}
          subText={`This action is irreversible.`}
          onClose={onCloseDelete}
          onConfirm={onDelete}
          isLoading={isDeleting}
        />
      )}
    </>
  );
};

export default BusinessProfileSettings;

const FormWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Former = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Btn = styled.button`
  padding: 10px 29px;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 8px;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const UpdateBtn = styled(Btn)`
  background-color: ${Colors.Blue00};
  color: ${Colors.White};
`;

const EditBtn = styled(Btn)`
  color: ${Colors.Blue00};
  border: 1px solid ${Colors.Blue00};
`;

const DeleteWrapper = styled.div`
  margin-top: 26px;
  padding-top: 30px;
  border-top: 1px solid ${Colors.GreyA7};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
`;

const DeleteTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.1875rem;
  margin-bottom: 12px;
`;

const DeletePara = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.0625rem;
  color: ${Colors.Black31};
`;

const DeleteBtn = styled(Btn)`
  color: ${Colors.RedE3};
  border: 1px solid ${Colors.RedE3};
`;
