export enum FormN600Progress {
  infoAboutEligibility = "n600 Information About Your Eligibility", // n600+Information+About+Your+Eligibility
  infoAboutYouP1 = "n600 Information About You 1", // n600+Information+About+You+1
  infoAboutYouP2 = "n600 Information About You 2", // n600+Information+About+You+2
  infoAboutYouP3 = "n600 Information About You 3", // n600+Information+About+You+3
  infoAboutYouP4 = "n600 Information About You 4", // n600+Information+About+You+4
  bioInfo = "n600 Biographic Information", // n600+Biographic+Information
  infoAboutUSCitizenFatherP1 = "n600 Information about your U.S. citizen father 1", // n600+Information+about+your+U+S+citizen+father+1
  infoAboutUSCitizenFatherP2 = "n600 Information about your U.S. citizen father 2", // n600+Information+about+your+U+S+citizen+father+2
  infoAboutUSCitizenMotherP1 = "n600 Information about your U.S. citizen mother 1", // n600+Information+about+your+U+S+citizen+mother+1
  infoAboutUSCitizenMotherP2 = "n600 Information about your U.S. citizen mother 2", // n600+Information+about+your+U+S+citizen+mother+2
  physicalPresenceFromBirth = "n600 Physical Presence in the United States From Birth Until Filing of Form N-600", // n600+Physical+Presence+in+the+United+States+From+Birth+Until+Filing+of+Form+N-600
  infoAboutParentsMilitary = "n600 Information about your U.S. citizen father",
  PI = "n600 Preparer and interpreter",
  review = "n600-review",
}
