import React, { FC } from "react";
import PayFail from "../../components/PayFail";
import { useGetDS160PayFail } from "../../../../hooks/ds160/usDS160";

interface Props {
  onRetry: () => void;
  isRetrying: boolean;
}

const DS160PayFail: FC<Props> = ({ onRetry, isRetrying }) => {
  const { isLoading, isError } = useGetDS160PayFail();

  return (
    <PayFail
      onRetry={onRetry}
      isRetrying={isRetrying}
      isLoading={isLoading}
      isError={isError}
    />
  );
};

export default DS160PayFail;
