import React, { FC } from "react";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { FormN400Progress } from "../../types";
import { N400BioInfoObj } from "../../../../../../api/n400/types";
import { turnCheckFieldToReadable } from "../../../../components/data";

interface Props {
  title: string;
  data?: N400BioInfoObj;
}

const ReviewBioInfo: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      section: {
        title: "Biographic information",
        children: [
          {
            label: "Ethnicity",
            value: data?.ethnicity,
          },
          {
            label: "Races",
            value: turnCheckFieldToReadable(data?.races[0]),
          },
          {
            label: "Height Feet",
            value: data?.heightFeet,
          },
          {
            label: "Height Inches",
            value: data?.heightInches,
          },
          {
            label: "Weight",
            value: data?.weight,
          },
          {
            label: "Eye color",
            value: data?.eyeColor,
          },
          {
            label: "Hair color",
            value: data?.hairColor,
          },
        ],
      },
    },
  ];

  return (
    <ReviewBox
      title={title}
      data={section}
      editUrl={FormN400Progress.bioInfo}
    />
  );
};

export default ReviewBioInfo;
