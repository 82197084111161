import React, { useEffect } from "react";
import styled from "styled-components";
import Constaint from "../layout/components/Constaint";
import Survey from "./components/Survey";

const Feedback: React.FC = () => {
  useEffect(() => {
    document.title = "Customer Feedback - SeamlessVisa";

    return () => {
      document.title =
        "SeamlessVisa | The Trusted Partner in Your Immigration Journey";
    };
  }, []);
  return (
    <Wrapper>
      <Constaint>
        <Container>
          <Survey />
        </Container>
      </Constaint>
    </Wrapper>
  );
};

export default Feedback;

const Wrapper = styled.div`
  margin: 28px 0;
`;

const Container = styled.div`
  display: grid;
  // grid-template-columns: 1fr;
  // gap: 48px;
  place-content: center;
  margin-bottom: 170px;

  // @media screen and (min-width: 768px) {
  //   grid-template-columns: 267px 1fr;
  // }
`;
