import happyGroup from "../../../../images/web/for-individual-section.jpg";
import { HiMiniAcademicCap } from "react-icons/hi2";
import { MdAirplaneTicket, MdOutlinePeopleAlt } from "react-icons/md";
import { RiUser6Line } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { WebPaths } from "../../../../routes/data";

const features = [
  {
    name: "U.S. Student Visas (F1 and M1)",
    description:
      "For those seeking to study in the United States, whether in academic or vocational programs.",
    icon: (
      <HiMiniAcademicCap aria-hidden="true" className="h-5 w-5 text-blue-600" />
    ),
    path: `${WebPaths.checkEligibility}/ds160`,
  },
  {
    name: "F2 Visas for Dependents",
    description:
      "For dependents (spouse and children) of F1 visa holders, allowing them to join their family in the U.S.",
    icon: (
      <MdOutlinePeopleAlt
        aria-hidden="true"
        className="h-5 w-5 text-blue-600"
      />
    ),
    path: `${WebPaths.checkEligibility}/ds160`,
  },
  {
    name: "U.S. Travel Visas (B1/B2). ",
    description:
      "For short-term visitors traveling for business (B1) or leisure (B2) purposes.",
    icon: (
      <MdAirplaneTicket aria-hidden="true" className="h-5 w-5 text-blue-600" />
    ),
    path: `${WebPaths.checkEligibility}/ds160`,
  },
];

const ForIndividual = () => {
  const navigate = useNavigate();
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid place-content-center max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:ml-auto lg:pl-4 lg:pt-4">
            <div className="lg:max-w-lg">
              {/* <h2 className="text-base/7 font-semibold text-blue-600"></h2> */}
              <p className="mt-2 text-pretty font-spartan text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                For Individuals
              </p>
              <p className="mt-6 text-lg/8 text-gray-600">
                Let us guide you through your visa application to maximize your
                chances of success, giving you the peace of mind you deserve.
              </p>
              <Link
                to={`${WebPaths.checkEligibility}/ds160`}
                className="group bg-Blue00/40 grow text-sm font-semibold text-Black shadow-sm flex items-center gap-x-6 justify-between w-fit mt-10 pl-5"
              >
                <span className="">Check Eligibility For Free</span>
                <div className="bg-Blue00 group-hover:bg-Blue00/40 p-4 text-white">
                  <RiUser6Line className="h-5 w-5" />
                </div>
              </Link>

              <p className="text-lg font-spartan font-bold mt-10">
                Non-Immigrant Visa Categories for Individuals
              </p>

              <dl className="mt-5 max-w-xl space-y-8 text-base/7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div
                    role="button"
                    onClick={() => navigate(feature.path)}
                    key={feature.name}
                    className="relative flex items-start space-x-4 hover:bg-BlueEA duration-150 p-4 rounded"
                  >
                    <dt className="mt-1">{feature.icon}</dt>
                    <div>
                      <dt className="inline font-semibold text-gray-900">
                        {feature.name}
                      </dt>{" "}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  </div>
                ))}
              </dl>

              <Link
                to={WebPaths.Individuals}
                className="group bg-Blue00/40 grow text-sm font-semibold text-Black shadow-sm flex items-center gap-x-16 justify-between w-fit mt-10 pl-5"
              >
                <span className="">Get Started</span>
                <div className="bg-Blue00 group-hover:bg-Blue00/40 p-4 text-white">
                  <RiUser6Line className="h-5 w-5" />
                </div>
              </Link>
            </div>
          </div>
          <div className="flex items-start justify-end lg:order-first">
            <img
              alt="Product screenshot"
              src={happyGroup}
              // width={2432}
              // height={1442}
              className="w-[20rem] shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] object-contain"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForIndividual;
