import { SingleOption } from "../../../../types/types";
import { leftSideBarDataProps } from "../../components/type";
import { FormN600Progress } from "./types";

const data = [
  {
    label: "Information About Your Eligibility",
    formProgress: FormN600Progress.infoAboutEligibility,
  },
  {
    label: "Information About You",
    children: [
      {
        name: "Part 1",
        formProgress: FormN600Progress.infoAboutYouP1,
      },
      {
        name: "Part 2",
        formProgress: FormN600Progress.infoAboutYouP2,
      },
      {
        name: "Part 3",
        formProgress: FormN600Progress.infoAboutYouP3,
      },
      {
        name: "Part 4",
        formProgress: FormN600Progress.infoAboutYouP4,
      },
    ],
  },
  {
    label: "Biographic Information",
    formProgress: FormN600Progress.bioInfo,
  },
  {
    label:
      "Physical Presence in the United States From Birth Until Filing of Form N-600",
    formProgress: FormN600Progress.physicalPresenceFromBirth,
  },
  {
    label: "Information About Military Service of U. S. Citizen Parents",
    formProgress: FormN600Progress.infoAboutParentsMilitary,
  },
  {
    label: "Preparer and Interpreter",
    formProgress: FormN600Progress.PI,
  },
  {
    label: "Review and Pay",
    formProgress: FormN600Progress.review,
  },
];

export const n600leftSideBarData = (
  isFatherCitizen: boolean,
  isMotherCitizen: boolean,
  isBothParent: boolean
): leftSideBarDataProps[] => {
  const temp: leftSideBarDataProps[] = [...data];

  const fatherObj = {
    label:
      "Information About Your U.S. Citizen Biological Father (or Adoptive Father)",
    children: [
      {
        name: "Part 1",
        formProgress: FormN600Progress.infoAboutUSCitizenFatherP1,
      },
      {
        name: "Part 2",
        formProgress: FormN600Progress.infoAboutUSCitizenFatherP2,
      },
    ],
  };

  const motherObj = {
    label:
      "Information About Your U.S. Citizen Biological Mother (or Adoptive Mother)",
    children: [
      {
        name: "Part 1",
        formProgress: FormN600Progress.infoAboutUSCitizenMotherP1,
      },
      {
        name: "Part 2",
        formProgress: FormN600Progress.infoAboutUSCitizenMotherP2,
      },
    ],
  };

  if (isBothParent) {
    temp.splice(3, 0, fatherObj);
    temp.splice(4, 0, motherObj);
  } else if (isFatherCitizen) {
    temp.splice(3, 0, fatherObj);
  } else if (isMotherCitizen) {
    temp.splice(3, 0, motherObj);
  }

  return temp;
};

export enum FatherCitizenByDataValues {
  birthInUS = "Birth in the United States",
  byAlienParents = "Acquisition after birth through naturalization of alien parents",
  birthAbroad = "Birth abroad to U.S. citizen parents",
  naturalization = "Naturalization",
}

export const FatherCitizenByData: SingleOption[] = [
  {
    label: "Birth in the United States",
    value: FatherCitizenByDataValues.birthInUS,
  },
  {
    label: "Acquisition after birth through naturalization of alien parents",
    value: FatherCitizenByDataValues.byAlienParents,
  },
  {
    label: "Birth abroad to U.S. citizen parents",
    value: FatherCitizenByDataValues.birthAbroad,
  },
  {
    label: "Naturalization",
    value: FatherCitizenByDataValues.naturalization,
  },
];

// export const EligibilityBasis =

export enum N600EligibilityBasisValue {
  // yes = "yes",
  // no = "no",
  biological_child_us_citizen = "i_am_a_BIOLOGICAL_child_of_a_US_citizen_parent.",
  adopted_child_us_citizen = "i_am_an_ADOPTED_child_of_a_US_citizen_parent.",
  other = "Other",
}

export const N600EligibilityBasis = [
  {
    label: "I am a BIOLOGICAL child of a U.S. citizen parent.",
    value: N600EligibilityBasisValue.biological_child_us_citizen,
  },
  {
    label: "I am an ADOPTED child of a U.S. citizen parent.",
    value: N600EligibilityBasisValue.adopted_child_us_citizen,
  },
  // {
  //   label: "Other",
  //   value: N600EligibilityBasisValue.other,
  // },
];

export const N600RelatesToData: SingleOption[] = [
  {
    label: "U.S. Citizen Father",
    value: "U.S. Citizen Father",
  },
  {
    label: "U.S. Citizen Mother",
    value: "U.S. Citizen Mother",
  },
];

export const SpouseImmigrationStatusData: SingleOption[] = [
  { label: "U.S. Citizen", value: "U.S. Citizen" },
  { label: "Lawful Permanent Resident", value: "Lawful Permanent Resident" },
  { label: "Other", value: "Other" },
];
export enum SpouseImmigrationStatusValues {
  usCitizen = "U.S. Citizen",
  lpr = "Lawful Permanent Resident",
  other = "Other",
}
