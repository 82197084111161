// import { HiCloudUpload, HiLockClosed } from "react-icons/hi";
// import { BiServer } from "react-icons/bi";
import { Link } from "react-router-dom";
import dashboard from "../../../../images/web/dashboard.png";
import { BiSolidBusiness } from "react-icons/bi";
import { WebPaths } from "../../../../routes/data";

// const features = [
//   {
//     name: "Push to deploy.",
//     description:
//       "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
//     icon: (
//       <HiCloudUpload
//         aria-hidden="true"
//         className="absolute left-1 top-1 h-5 w-5 text-blue-600"
//       />
//     ),
//   },
//   {
//     name: "SSL certificates.",
//     description:
//       "Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.",
//     icon: (
//       <HiLockClosed
//         aria-hidden="true"
//         className="absolute left-1 top-1 h-5 w-5 text-blue-600"
//       />
//     ),
//   },
//   {
//     name: "Database backups.",
//     description:
//       "Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.",
//     icon: (
//       <BiServer
//         aria-hidden="true"
//         className="absolute left-1 top-1 h-5 w-5 text-blue-600"
//       />
//     ),
//   },
// ];

const ForBusinesses = () => {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
          <div className="px-6 lg:px-0 lg:pr-4 lg:pt-4">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
              {/* <h2 className="text-base/7 font-semibold text-blue-600">
                Deploy faster
              </h2> */}
              <p className="mt-2 text-pretty text-4xl font-spartan font-bold tracking-tight text-gray-900 sm:text-5xl">
                For Businesses
              </p>
              <p className="mt-6 text-lg/8 text-gray-600">
                A Smarter Way to Manage Visa Applications for Your Clients. Use
                our software to automatically check for errors, review
                applications for inconsistency, and minimize the risk of U.S.
                visa denials.
              </p>
              {/* <dl className="mt-10 max-w-xl space-y-8 text-base/7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      {feature.icon}
                      {feature.name}
                    </dt>{" "}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl> */}

              <Link
                to={WebPaths.Businesses}
                className="group bg-[#00C1A3] grow text-sm font-semibold text-Black shadow-sm flex items-center justify-between gap-x-16 w-64 md:w-fit mt-10 duration-150 pl-5"
              >
                <span className="">See how we can help</span>
                <div className="bg-[#1F8A79] group-hover:bg-[#1F8A79]/40 p-4 text-white duration-150">
                  <BiSolidBusiness className="h-6 w-6" />
                </div>
              </Link>
            </div>
          </div>
          <div className="sm:px-6 lg:px-0">
            <div className="relative isolate overflow-hidden bg-blue-500 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pl-16 sm:pr-0 sm:pt-16 lg:mx-0 lg:max-w-none">
              <div
                aria-hidden="true"
                className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-blue-100 opacity-20 ring-1 ring-inset ring-white"
              />
              <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                <img
                  alt="Product screenshot"
                  src={dashboard}
                  width={2432}
                  height={1442}
                  className="-mb-12 w-[57rem] max-w-none rounded-tl-3xl bg-gray-800 ring-1 ring-white/10"
                />
              </div>
              <div
                aria-hidden="true"
                className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForBusinesses;
