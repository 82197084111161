import React from "react";
import Constaint from "./Constaint";
import styled from "styled-components";
import { FaXTwitter } from "react-icons/fa6";
import { IoLogoInstagram } from "react-icons/io";
import { TfiLinkedin } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import { WebPaths } from "../../../../routes/data";
import Colors from "../../../../styles/Colors";
import { ReactComponent as LogoGrayscale } from "../../../../images/icons/logo-grayscale.svg";
import { IoLocation, IoMail } from "react-icons/io5";
import { RiFacebookFill } from "react-icons/ri";
import { BsFillChatRightDotsFill } from "react-icons/bs";

const Footer = () => {
  const navigate = useNavigate();

  // const onSendMail = () => {
  //   window.open("mailto:hello@seamlessvisa.com");
  // };

  return (
    <Wrapper>
      <Constaint>
        <Container>
          <Sections>
            <LeftSection>
              <LogoGrayscale />
              <FollowUs>
                <RightLabel>Follow us</RightLabel>
                <SocialsWrapper>
                  <a
                    href="https://x.com/seamlessvisa_?s=21&t=fIKPYb2-339hoq41xEiiWw"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaXTwitter />
                  </a>

                  <a
                    href="https://www.instagram.com/seamlessvisa_?igsh=OGNrdG9qdDRtN213&utm_source=qr"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IoLogoInstagram />
                  </a>
                  <a
                    href="https://linkedin.com/company/seamlessvisa/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TfiLinkedin />
                  </a>
                  <a
                    href="https://web.facebook.com/profile.php?id=61555803434533"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <RiFacebookFill />
                  </a>
                </SocialsWrapper>
              </FollowUs>
            </LeftSection>

            <MiddleSection>
              <QuickLinks>
                <MiddleLinks onClick={() => navigate(WebPaths.Services)}>
                  Services
                </MiddleLinks>
                <MiddleLinks onClick={() => navigate(WebPaths.About)}>
                  About Us
                </MiddleLinks>
                <MiddleLinks onClick={() => navigate(WebPaths.Help)}>
                  Help
                </MiddleLinks>
                <MiddleLinks onClick={() => navigate(WebPaths.Blogs)}>
                  Blogs
                </MiddleLinks>
              </QuickLinks>

              <QuickLinks>
                <MiddleLinks href="https://app.termly.io/document/disclaimer/17049508-9dd3-44ff-9249-dd61cf7e5868">
                  Disclaimer
                </MiddleLinks>
                <MiddleLinks href="https://app.termly.io/document/terms-of-service/52cfeaf3-bc98-4aee-8e68-ae9526870a50">
                  Terms of Use
                </MiddleLinks>
                <MiddleLinks href="https://app.termly.io/document/privacy-policy/22fda0c1-0085-4dac-aabd-11a41a97895a">
                  Privacy Policy
                </MiddleLinks>
                <MiddleLinks href="https://app.termly.io/document/acceptable-use/f015bdfa-e9e6-48b2-838c-f5f672992aa9">
                  Acceptable Use Policy
                </MiddleLinks>
                <MiddleLinks
                  href="/guarantee-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Guarantee Policy
                </MiddleLinks>
                <MiddleLinks
                  href={WebPaths.AiPolicy}
                  target="_blank"
                  rel="noreferrer"
                >
                  Use of AI Tools Policy
                </MiddleLinks>
              </QuickLinks>
            </MiddleSection>

            <RightSection>
              <RightLabel>Contact us</RightLabel>
              <ContactCard
                onClick={() => navigate(`${WebPaths.Help}/?contact=true`)}
              >
                <div>
                  <BsFillChatRightDotsFill size={18} />
                </div>
                <p>Chat with our support team</p>
              </ContactCard>

              <ContactCard
                href="mailto:hello@seamlessvisa.com"
                // onClick={() => onSendMail()}
              >
                <div>
                  <IoMail size={18} />
                </div>
                <p>hello@seamlessvisa.com</p>
              </ContactCard>

              <ContactCard>
                <div>
                  <IoLocation size={18} />
                </div>
                <p>8 The Green # 19607, Dover, DE 19901.</p>
              </ContactCard>
            </RightSection>
          </Sections>

          <SubBox>
            <Disclaimer>
              <h2>disclaimer:</h2>
              <p>
                SeamlessVisa is not a law firm, and the information provided on
                this website does not, and is not intended to amount to legal
                advice. SeamlessVisa's guides and resources are provided subject
                to additional Terms of Use and are not a substitute for a
                licensed attorney or a law firm. The United States Citizenship
                and Immigration Services (USCIS) provides blank immigration
                forms and instructions on the USCIS website at no cost.
                SeamlessVisa is not affiliated with USCIS or any government
                agency. Please consult with a licensed attorney for legal advice
                tailored to your individual case and circumstance.
              </p>
            </Disclaimer>
            <Copyright>
              © {new Date().getFullYear()} SeamlessVisa Inc.
            </Copyright>
          </SubBox>
        </Container>
      </Constaint>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.div`
  background-color: ${Colors.Black1A};
`;

const Container = styled.div`
  padding-top: 80px;
  padding-bottom: 41px;
`;

// const H1 = styled.h1`
//   font-size: 2.25rem;
//   font-weight: 700;
//   line-height: 2.6875rem;
//   letter-spacing: 0em;
// `;

// const SubText = styled.div`
//   font-size: 1.125rem;
//   font-weight: 400;
//   line-height: 1.375rem;
//   letter-spacing: 0em;
// `;

const Sections = styled.div`
  display: flex;
  align-items: start;
  gap: 64px;
  justify-content: space-between;

  flex-direction: column;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    gap: 103px;
    align-items: center;
  }
`;

const LeftSection = styled.div`
  color: #b3bac1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 0.33;
`;

const MiddleSection = styled.div`
  flex: 0.33;

  display: flex;
  justify-content: center;
  gap: 32px;

  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const MiddleLinks = styled.a`
  /* font-size: 1.125rem; */
  font-weight: 500;
  line-height: 1.375rem;
  letter-spacing: 0em;
  color: #b3bac1;

  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  cursor: pointer;
`;

const QuickLinks = styled.div`
  & > a {
    display: block;
    font-size: 0.95rem;

    margin: 8px 0;
    padding: 8px;
  }
`;

// const ItemWrapper = styled.div`
//   display: flex;
//   gap: 36px;

//   flex-direction: column;

//   @media only screen and (min-width: 768px) {
//     flex-direction: row;
//     align-items: center;
//   }
// `;

const RightSection = styled.div`
  flex: 0.25;

  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (min-width: 768px) {
    /* align-items: flex-end; */
  }
`;

const RightLabel = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  color: #b3bac1;

  margin-bottom: 1rem;
`;

const SocialsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 33px;

  & > a {
    color: #b3bac1;
    transition: 0.3s all ease;

    & > svg {
      height: 18px;
      width: 18px;
    }

    &:hover {
      color: #fff;
    }
  }
`;

const ContactCard = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;

  margin-bottom: 8px;

  color: #b3bac1;

  cursor: pointer;
  transition: 0.3s all ease-out;

  & > div {
    background-color: ${Colors.Black31};
    height: 42px;
    width: 42px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-shrink: 0;

    /* & > svg {
      width: 24px;
      height: 24px;

      stroke: ${Colors.Black1A};
      fill: #fff;
    } */
  }

  &:hover {
    color: #fff;
  }
`;

const SubBox = styled.div`
  border-top: 1px solid #b3bac1;
  display: flex;

  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: 400;
  letter-spacing: 0em;
  margin-top: 80px;
  color: #b3bac1;

  padding-top: 2rem;

  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }

  @media only screen and (min-width: 768px) {
    justify-content: space-between;
  }
`;

const FollowUs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  margin-top: 2rem;

  @media screen and (min-width: 768px) {
    /* align-items: flex-end; */
  }
`;

const Disclaimer = styled.div`
  flex: 1;
  margin-bottom: 2rem;

  @media only screen and (min-width: 768px) {
    flex: 0.6;
  }

  & > h2 {
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 1rem;
  }

  & > p {
    font-size: 0.85rem;
  }
`;

const Copyright = styled.div``;
