import React, { FC } from "react";
import InputField from "../../../components/formik fields/InputField";
import { CheckGroupedWrapper, CheckWrapper } from "../styles";
import SelectField from "../../../components/formik fields/SelectField";
import CheckBoxField from "../../../components/formik fields/CheckBoxField";
import { FormikProps } from "formik";
import { StudentExchangeVisaAdditionalData } from "./AdditionalContact";
import { saveDS160SEVAdditionalContactObj } from "../../../../../api/ds160/types";
import useCountries from "../../../components/useCountries";
import useStatesByCountry from "../../../components/useStatesByCountry";

interface Props {
  data?: saveDS160SEVAdditionalContactObj;
  index: number;
  formik: FormikProps<StudentExchangeVisaAdditionalData>;
}

const AdditionalArray: FC<Props> = ({ data, index, formik }) => {
  const savedCountry = data?.countryOrRegion.split(",")[index] || "";
  const { formattedCountryData, countryLoading } = useCountries();
  const { formattedStateData, stateLoading, setCountry, selectedCountry } =
    useStatesByCountry(savedCountry);

  return (
    <>
      <InputField
        label="Surname"
        placeholder=""
        name={`ContactArray[${index}].surname`}
      />
      <InputField
        label="Given Names"
        placeholder=""
        name={`ContactArray[${index}].givenNames`}
      />
      <InputField
        label="Street Address Line 1"
        placeholder=""
        name={`ContactArray[${index}].streetAddressLine1`}
      />
      <InputField
        label="Street Address Line 2"
        coloredLabel="(optional)"
        placeholder=""
        name={`ContactArray[${index}].streetAddressLine2`}
      />
      <InputField
        label="City"
        placeholder=""
        name={`ContactArray[${index}].city`}
      />
      <SelectField
        name={`ContactArray[${index}].countryOrRegion`}
        label="Country/Region"
        placeholder="-Select-"
        options={formattedCountryData}
        isLoading={countryLoading}
        onChange={(value) => {
          setCountry(value.label);
          formik.setFieldValue(`ContactArray[${index}].stateOrProvince`, null);
        }}
      />
      <CheckGroupedWrapper>
        <SelectField
          label="State/Province"
          placeholder=""
          name={`ContactArray[${index}].stateOrProvince`}
          options={formattedStateData}
          isLoading={stateLoading}
          isDisabled={
            !!formik.values.ContactArray[index].doesNotApplyStateOrProvince ||
            !selectedCountry
          }
        />
        <CheckWrapper>
          <CheckBoxField
            label={`Does not apply`}
            name={`ContactArray[${index}].doesNotApplyStateOrProvince`}
            onChange={() => {
              formik.setFieldValue(
                `ContactArray[${index}].stateOrProvince`,
                null
              );
              formik.setFieldTouched(
                `ContactArray[${index}].stateOrProvince`,
                false
              );
            }}
          />
        </CheckWrapper>
      </CheckGroupedWrapper>
      <CheckGroupedWrapper>
        <InputField
          label="Postal Code/Zip Code"
          placeholder=""
          name={`ContactArray[${index}].zipCode`}
          isDisabled={!!formik.values.ContactArray[index].doesNotApplyZipCode}
        />
        <CheckWrapper>
          <CheckBoxField
            label={`Does not apply`}
            name={`ContactArray[${index}].doesNotApplyZipCode`}
            onChange={() => {
              formik.setFieldTouched(`ContactArray[${index}].zipCode`, false);
              formik.setFieldValue(`ContactArray[${index}].zipCode`, "");
            }}
          />
        </CheckWrapper>
      </CheckGroupedWrapper>
      <CheckGroupedWrapper>
        <InputField
          label="Phone Number"
          placeholder=""
          name={`ContactArray[${index}].telephoneNumber`}
          isDisabled={
            !!formik.values.ContactArray[index].doesNotApplyTelephoneNumber
          }
        />
        <CheckWrapper>
          <CheckBoxField
            label={`Does not apply`}
            name={`ContactArray[${index}].doesNotApplyTelephoneNumber`}
            onChange={() => {
              formik.setFieldTouched(
                `ContactArray[${index}].telephoneNumber`,
                false
              );
              formik.setFieldValue(
                `ContactArray[${index}].telephoneNumber`,
                ""
              );
            }}
          />
        </CheckWrapper>
      </CheckGroupedWrapper>
      <CheckGroupedWrapper>
        <InputField
          label="Email Address"
          placeholder=""
          name={`ContactArray[${index}].emailAddress`}
          isDisabled={
            !!formik.values.ContactArray[index].doesNotApplyEmailAddress
          }
        />
        <CheckWrapper>
          <CheckBoxField
            label={`Does not apply`}
            name={`ContactArray[${index}].doesNotApplyEmailAddress`}
            onChange={() => {
              formik.setFieldTouched(
                `ContactArray[${index}].emailAddress`,
                false
              );
              formik.setFieldValue(`ContactArray[${index}].emailAddress`, "");
            }}
          />
        </CheckWrapper>
      </CheckGroupedWrapper>
    </>
  );
};

export default AdditionalArray;
