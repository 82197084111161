import axios from "axios";
import { useState } from "react";

interface UseOpenAiTTSResult {
  isLoading: boolean;
  error: Error | null;
  audioUrl: string | null;
  synthesize: (text: string) => Promise<void>;
}

const apiKey =
  "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";
// const ttsUrl: string = `https://api.openai.com/v1/audio/speech`;

// const api = axios.create({
//   baseURL: "https://api.openai.com/v1/", // Replace with your base URL if applicable
//   headers: {
//     Authorization: `Bearer ${apiKey}`,
//   },
// });

const useOpenAiTTS = (): UseOpenAiTTSResult => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);

  const synthesize = async (text: string) => {
    setIsLoading(true);

    setError(null);

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/audio/speech",
        {
          model: "tts-1", // or 'tts-1-hd'
          input: text,
          voice: "echo",
          response_format: "mp3", // Explicitly request mp3 or any other supported format
          speed: 1.0, // Optional: set audio speed, default is 1.0
        },
        {
          headers: {
            Authorization: `Bearer ${apiKey}`,
            "Content-Type": "application/json",
          },
          responseType: "blob", // This is crucial for handling binary data (audio file)
        }
      );

      // Convert the binary data (Blob) into an object URL that the browser can handle
      const blob = new Blob([response.data], { type: "audio/mpeg" }); // Ensure the MIME type matches the response format (audio/mpeg for mp3)
      const url = URL.createObjectURL(blob);

      // Log the URL for debugging purposes
      // console.log("Generated audio URL:", { url });

      setAudioUrl(url);

      //   const url = response.data.audio_url;
      setAudioUrl(url);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, error, audioUrl, synthesize };
};

export default useOpenAiTTS;
