import {
  UserCircleIcon,
  ClipboardDocumentCheckIcon,
  PencilSquareIcon,
  ShieldCheckIcon,
  DocumentArrowUpIcon,
  UserGroupIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { WebPaths } from "../../../../../routes/data";
import { BsUiChecks } from "react-icons/bs";

const features = [
  {
    name: "Create Your Profile",
    description: "Sign up on our platform.",
    icon: <UserCircleIcon className="h-6 w-6 text-Blue00" aria-hidden="true" />,
  },
  {
    name: "Check Your Eligibility",
    description:
      "Answer a series of questions to confirm if you are eligible for the visa category you are interested in. For FREE.",
    icon: (
      <ClipboardDocumentCheckIcon
        className="h-6 w-6 text-Blue00"
        aria-hidden="true"
      />
    ),
  },
  {
    name: "Fill Out Your Application",
    description:
      "Using our user-friendly interface, the system guides you through the process from start to finish. Our error-checking system will check for errors and alert you to ensure everything is accurate.",
    icon: (
      <PencilSquareIcon className="h-6 w-6 text-Blue00" aria-hidden="true" />
    ),
  },
  {
    name: "Risk Analysis",
    description:
      "Our Risk Evaluation Tool will analyze your application and offer personalized improvement tips to enhance your application.",
    icon: (
      <ShieldCheckIcon className="h-6 w-6 text-Blue00" aria-hidden="true" />
    ),
  },
  {
    name: "Upload Documents",
    description:
      "We would generate a checklist of supporting documents required for a successful application. Go ahead and upload all your documents.",
    icon: (
      <DocumentArrowUpIcon className="h-6 w-6 text-Blue00" aria-hidden="true" />
    ),
  },
  {
    name: "Expert Reviews",
    description:
      "Enjoy peace of mind by having your application reviewed and submitted by our immigration experts.",
    icon: <UserGroupIcon className="h-6 w-6 text-Blue00" aria-hidden="true" />,
  },
  {
    name: "Prepare for Your Interview",
    description:
      "Get ready for your interview with FiBi AI. Receive expert feedback and develop the confidence you need to impress immigration officers.",
    icon: (
      <ChatBubbleLeftRightIcon
        className="h-6 w-6 text-Blue00"
        aria-hidden="true"
      />
    ),
  },
];

const HowItWorks = () => {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-5 ">
          <div className="col-span-2">
            {/* <h2 className="text-base font-semibold text-blue-600">
              All the tools you need
            </h2> */}
            <p className="mt-2 text-4xl font-bold font-spartan tracking-tight text-gray-900 sm:text-5xl">
              How it works
            </p>
            {/* <p className="mt-6 text-base text-gray-600">
              SeamlessVisa offers a complete suite of tools and expert support
              to make your visa application journey as smooth as possible.
            </p> */}
          </div>
          <dl className="col-span-3 grid grid-cols-1 gap-x-8 gap-y-10 text-base/7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt className="font-semibold text-gray-900 flex items-center gap-x-2">
                  {feature.icon}
                  {feature.name}
                </dt>
                <dd className="mt-2">{feature.description}</dd>
              </div>
            ))}
          </dl>
          <div className="col-span-6 flex items-center justify-center">
            <div>
              <Link
                to={`${WebPaths.checkEligibility}/ds160`}
                className="group bg-Blue00/40 grow text-sm font-semibold text-Black shadow-sm flex items-center gap-x-16 justify-between w-fit mt-10 pl-5"
              >
                <span className="">Check eligibility for free</span>
                <div className="bg-Blue00 group-hover:bg-Blue00/40 p-4 text-white">
                  <BsUiChecks className="h-5 w-5" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
