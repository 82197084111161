import React, { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const MiniLists: FC<Props> = ({ children }) => {
  return (
    <ul className="flex flex-col gap-1 list-disc list-inside">{children}</ul>
  );
};

export default MiniLists;
