import React, { FC } from "react";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { n400CheckPart4Res } from "../../../../../../api/n400/types";
import { FormN400Progress } from "../../types";

interface Props {
  title: string;
  data?: n400CheckPart4Res;
}

const ReviewCheckPart4: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      label:
        "30.a. Do you now have, or did you EVER have, a hereditary title or an order of nobility in any foreign country?",
      value: data?.do_you_have_hereditary_title,
    },
    {
      label: "30.b. Are you willing to give up any inherited titles or orders",
      value: data?.are_you_willing_to_give_up_inherited_titles,
    },
    {
      label: "30.c. List titles",
      value: data?.list_of_titles,
    },
    {
      label:
        "31. Do you support the Constitution and form of Government of the United States?",
      value: data?.do_you_support_the_constitution,
    },
    {
      label:
        "32. Do you understand the full Oath of Allegiance to the United States (see Part 16. Oath of Allegiance)?",
      value: data?.do_you_understand_the_oath_of_allegiance,
    },
    {
      label:
        "33. Are you unable to take the Oath of Allegiance because of a physical or developmental disability or mental impairment?",
      value: data?.are_you_unable_to_take_oath_of_allegiance,
    },
    {
      label:
        "34. Are you willing to take the full Oath of Allegiance to the United States?",
      value: data?.are_you_willing_to_take_oath_of_allegiance,
    },
    {
      label:
        "35. If the law requires it, are you willing to bear arms (carry weapons) on behalf of the United States?",
      value: data?.are_you_willing_to_bear_arms,
    },
    {
      label:
        "36. If the law requires it, are you willing to perform noncombatant services (do something that does not include fighting in a war) in the U.S. armed forces?",
      value: data?.are_you_willing_to_perform_noncombatant_services,
    },
    {
      label:
        "37. If the law requires it, are you willing to perform work of national importance under civilian direction (do non-military work that the U.S. Government says is important to the country)?",
      value: data?.are_you_willing_to_perform_work_of_national_importance,
    },
  ];

  return (
    <ReviewBox
      title={title}
      data={section}
      editUrl={FormN400Progress.checkPart4}
    />
  );
};

export default ReviewCheckPart4;
