import React from "react";
import { useSnapshot } from "valtio";
import { userDataStore } from "../../../../../../store/userData";
import styled from "styled-components";
import Colors from "../../../../../../styles/Colors";
import { FaCopy } from "react-icons/fa6";
import { useCopyToClipboard } from "../../../../../../hooks/utils/useUtils";

const Referral: React.FC = () => {
  const { userData } = useSnapshot(userDataStore);
  const referralLink = `https://seamlessvisa.com/auth/register?ref=${userData?.userInfo.referralCode}`;
  const [copied, copyToClipboard] = useCopyToClipboard();
  const onCopy = () => copyToClipboard(referralLink);

  return (
    <Wrapper>
      <h1>Refer Your Friends and earn $10!</h1>
      <p>
        Help your friends benefit from our services and earn $10* when they
        complete an application on our platform. It's easy to share. Get your
        unique referral link below.
      </p>

      <p>*Terms and Conditions apply.</p>

      <ReferralLink>
        <p>
          https://seamlessvisa.com/auth/register?ref=
          <span>{userData?.userInfo.referralCode}</span>
        </p>
        <button onClick={onCopy}>
          <FaCopy />
          {copied ? "copied" : "share link"}
        </button>
      </ReferralLink>

      <AltShare>
        <p>
          You have referred{" "}
          <span>{userData?.userInfo.referralss.length! - 1}</span> people.
        </p>
      </AltShare>
    </Wrapper>
  );
};

export default Referral;

const Wrapper = styled.div`
  text-align: center;

  h1 {
    font-weight: bold;
    font-size: 1.24rem;

    margin-bottom: 1.24rem;
  }
`;

const ReferralLink = styled.div`
  margin-top: 2rem;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  p {
    border: 3px solid ${Colors.BlueEA};
    border-radius: 8px;
    padding: 1rem;
    font-size: 0.85rem;
  }

  span {
    font-size: 1rem;
    font-weight: bold;
    color: ${Colors.Blue00};
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    width: 180px;

    padding: 1.15rem 2.25rem;
    border-radius: 8px;

    text-transform: uppercase;
    font-size: 0.85rem;
    font-weight: bold;

    background-color: ${Colors.Blue00};
    color: #fff;

    transition: 300ms all ease;

    &:hover {
      background-color: ${Colors.Blue02};
    }
  }
`;

const AltShare = styled.div`
  margin-top: 2rem;

  p {
    /* border: 3px solid ${Colors.BlueEA}; */
    border-radius: 8px;
    padding: 1rem;
    font-size: 0.85rem;
  }

  span {
    font-size: 1rem;
    font-weight: bold;
    color: ${Colors.Blue00};
  }
`;
