import { ApiResponse } from "../../types/types";
import { saveDS160MaritalStatusObj } from "../../views/web/form ds 160/components/FamilyRelatives/MaritalStatus";
import { GetFileDetailObj } from "../n400/types";
import request from "../request";
import {
  saveDS160AddressAndPhoneObj,
  saveDS160FamilyObj,
  saveDS160PassportObj,
  saveDS160PersonalInfo2Obj,
  saveDS160PersonalInfoObj,
  saveDS160PreviousUSTravelObj,
  saveDS160TravelCompanionsObj,
  saveDS160TravelObj,
  saveDS160USContactObj,
  saveDS160WETPresentObj,
  saveDS160WETPreviousObj,
  saveDS160WETAdditionalObj,
  saveDS160SEVAdditionalContactObj,
  saveDS160SivesObj,
  saveDS160SecurityBackgroundP1Obj,
  saveDS160SecurityBackgroundP2Obj,
  saveDS160SecurityBackgroundP3Obj,
  saveDS160SecurityBackgroundP4Obj,
  saveDS160SecurityBackgroundP5Obj,
  GetDs160Obj,
  GetDS160GetStartedRes,
  DS160EligibilityPayload,
  payDS160Data,
  UpdateDS160FilesPayload,
  SaveDS160VistorEmailPayload,
} from "./types";

export const saveDS160GetStarted = async (payload: FormData) => {
  return await request.post<ApiResponse>(`/user/ds160-get-started`, payload);
};

export const getDS160GetStarted = async () => {
  return await request.get<ApiResponse<GetDS160GetStartedRes>>(
    `/user/get-ds160-get-started`
  );
};

export const saveDS160PersonalInfo = async (
  payload: saveDS160PersonalInfoObj
) => {
  const { applicationId, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/personal-info${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`,
    rest
  );
};

export const getDS160PersonalInfo = async (applicationId?: string) => {
  return await request.get<ApiResponse<saveDS160PersonalInfoObj>>(
    `/${applicationId ? "business" : "user"}/get-personal-info${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const saveDS160PersonalInfo2 = async (
  payload: saveDS160PersonalInfo2Obj
) => {
  const { applicationId, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/personal-info-2${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`,
    rest
  );
};

export const getDS160PersonalInfo2 = async (applicationId?: string) => {
  return await request.get<ApiResponse<saveDS160PersonalInfo2Obj>>(
    `/${applicationId ? "business" : "user"}/get-personal-info-2${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const saveDS160Travel = async (payload: saveDS160TravelObj) => {
  const { applicationId, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/travel${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`,
    rest
  );
};

export const getDS160Travel = async (applicationId?: string) => {
  return await request.get<ApiResponse<saveDS160TravelObj>>(
    `/${applicationId ? "business" : "user"}/get-travel${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const saveDS160TravelCompanions = async (
  payload: saveDS160TravelCompanionsObj
) => {
  const { applicationId, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/travel-companions${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`,
    rest
  );
};

export const getDS160TravelCompanions = async (applicationId?: string) => {
  return await request.get<ApiResponse<saveDS160TravelCompanionsObj>>(
    `/${applicationId ? "business" : "user"}/get-travel-companions${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const saveDS160PreviousUSTravel = async (
  payload: saveDS160PreviousUSTravelObj
) => {
  const { applicationId, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/previous-us-travel${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`,
    rest
  );
};

export const getDS160PreviousUSTravel = async (applicationId?: string) => {
  return await request.get<ApiResponse<saveDS160PreviousUSTravelObj>>(
    `/${applicationId ? "business" : "user"}/get-previous-us-travel${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const saveDS160AddressAndPhone = async (
  payload: saveDS160AddressAndPhoneObj
) => {
  const { applicationId, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/address-and-phone${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`,
    rest
  );
};

export const getDS160AddressAndPhone = async (applicationId?: string) => {
  return await request.get<ApiResponse<saveDS160AddressAndPhoneObj>>(
    `/${applicationId ? "business" : "user"}/get-address-and-phone${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const saveDS160Passport = async (payload: saveDS160PassportObj) => {
  const { applicationId, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/passport${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`,
    rest
  );
};

export const getDS160Passport = async (applicationId?: string) => {
  return await request.get<ApiResponse<saveDS160PassportObj>>(
    `/${applicationId ? "business" : "user"}/get-passport${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const saveDS160USContact = async (payload: saveDS160USContactObj) => {
  const { applicationId, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/us-contact${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`,
    rest
  );
};

export const getDS160USContact = async (applicationId?: string) => {
  return await request.get<ApiResponse<saveDS160USContactObj>>(
    `/${applicationId ? "business" : "user"}/get-us-contact${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const saveDS160Family = async (payload: saveDS160FamilyObj) => {
  const { applicationId, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/family${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`,
    rest
  );
};

export const getDS160Family = async (applicationId?: string) => {
  return await request.get<ApiResponse<saveDS160FamilyObj>>(
    `/${applicationId ? "business" : "user"}/get-family${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const saveDS160WETPresent = async (payload: saveDS160WETPresentObj) => {
  const { applicationId, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/wet-present${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`,
    rest
  );
};

export const getDS160WETPresent = async (applicationId?: string) => {
  return await request.get<ApiResponse<saveDS160WETPresentObj>>(
    `/${applicationId ? "business" : "user"}/get-wet-present${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const saveDS160WETPrevious = async (
  payload: saveDS160WETPreviousObj
) => {
  const { applicationId, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/wet-previous${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`,
    rest
  );
};

export const getDS160WETPrevious = async (applicationId?: string) => {
  return await request.get<ApiResponse<saveDS160WETPreviousObj>>(
    `/${applicationId ? "business" : "user"}/get-wet-previous${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const saveDS160WETAdditional = async (
  payload: saveDS160WETAdditionalObj
) => {
  const { applicationId, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/wet-additional${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`,
    rest
  );
};

export const getDS160WETAdditional = async (applicationId?: string) => {
  return await request.get<ApiResponse<saveDS160WETAdditionalObj>>(
    `/${applicationId ? "business" : "user"}/get-wet-additional${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const saveDS160SEVAdditionalContact = async (
  payload: saveDS160SEVAdditionalContactObj
) => {
  const { applicationId, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/sev-additional-contact${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`,
    rest
  );
};

export const getDS160SEVAdditionalContact = async (applicationId?: string) => {
  return await request.get<ApiResponse<saveDS160SEVAdditionalContactObj>>(
    `/${applicationId ? "business" : "user"}/get-sev-additional-contact${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const saveDS160Sives = async (payload: saveDS160SivesObj) => {
  const { applicationId, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/sevis${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`,
    rest
  );
};

export const getDS160Sives = async (applicationId?: string) => {
  return await request.get<ApiResponse<saveDS160SivesObj>>(
    `/${applicationId ? "business" : "user"}/get-sevis${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const saveDS160SecurityBackgroundP1 = async (
  payload: saveDS160SecurityBackgroundP1Obj
) => {
  const { applicationId, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/security-part-1${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`,
    rest
  );
};

export const getSecurityBackgroundP1 = async (applicationId?: string) => {
  return await request.get<ApiResponse<saveDS160SecurityBackgroundP1Obj>>(
    `/${applicationId ? "business" : "user"}/get-security-part-1${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const saveDS160SecurityBackgroundP2 = async (
  payload: saveDS160SecurityBackgroundP2Obj
) => {
  const { applicationId, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/security-part-2${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`,
    rest
  );
};

export const getSecurityBackgroundP2 = async (applicationId?: string) => {
  return await request.get<ApiResponse<saveDS160SecurityBackgroundP2Obj>>(
    `/${applicationId ? "business" : "user"}/get-security-part-2${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const saveDS160SecurityBackgroundP3 = async (
  payload: saveDS160SecurityBackgroundP3Obj
) => {
  const { applicationId, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/security-part-3${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`,
    rest
  );
};

export const getSecurityBackgroundP3 = async (applicationId?: string) => {
  return await request.get<ApiResponse<saveDS160SecurityBackgroundP3Obj>>(
    `/${applicationId ? "business" : "user"}/get-security-part-3${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const saveDS160SecurityBackgroundP4 = async (
  payload: saveDS160SecurityBackgroundP4Obj
) => {
  const { applicationId, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/security-part-4${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`,
    rest
  );
};

export const getSecurityBackgroundP4 = async (applicationId?: string) => {
  return await request.get<ApiResponse<saveDS160SecurityBackgroundP4Obj>>(
    `/${applicationId ? "business" : "user"}/get-security-part-4${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const saveDS160SecurityBackgroundP5 = async (
  payload: saveDS160SecurityBackgroundP5Obj
) => {
  const { applicationId, ...rest } = payload;
  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/security-part-5${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`,
    rest
  );
};

export const getSecurityBackgroundP5 = async (applicationId?: string) => {
  return await request.get<ApiResponse<saveDS160SecurityBackgroundP5Obj>>(
    `/${applicationId ? "business" : "user"}/get-security-part-5${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const getDS160Info = async (applicationId?: string) => {
  return await request.get<ApiResponse<GetDs160Obj>>(
    `${applicationId ? "business" : "user"}/get-ds-160-info${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const saveDS160Eligibility = async (
  payload: DS160EligibilityPayload
) => {
  const { applicationId, ...rest } = payload;

  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/ds-160-eligibility${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`,
    rest
  );
};

export const getDS160Eligibility = async (applicationId?: string) => {
  return await request.get<ApiResponse<DS160EligibilityPayload>>(
    `/${applicationId ? "business" : "user"}/get-ds-160-eligibility${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const payDS160 = async (payload: payDS160Data) => {
  const { applicationId, ...rest } = payload;

  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/ds160-payment${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`,
    rest
  );
};

export const getDS160Form = async (applicationId?: string) => {
  return await request.get<ApiResponse>(
    `/${applicationId ? "business" : "user"}/get-ds-160-application${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const getDS160PaySuccess = async (
  payId: string,
  applicationId?: string
) => {
  return await request.get<ApiResponse>(
    `/${applicationId ? "business" : "user"}/ds160-payment-success/${payId}${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const getDS160PayFail = async (applicationId?: string) => {
  return await request.get<ApiResponse>(
    `/${applicationId ? "business" : "user"}/ds160-payment-cancel${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const uploadDS160Files = async (payload: FormData) => {
  return await request.post<ApiResponse>(`/user/upload-ds160-files`, payload);
};

export const getDS160Files = async () => {
  return await request.get<ApiResponse<GetFileDetailObj[]>>(
    `/user/get-upload-ds160-files`
  );
};

export const updateDS160Files = async (payload: UpdateDS160FilesPayload) => {
  return await request.post<ApiResponse>(
    `/user/update-ds160-files/${payload.id}/${payload.formCategory}`,
    payload.data
  );
};

export const saveDS160VistorEmail = async (
  payload: SaveDS160VistorEmailPayload
) => {
  return await request.post<ApiResponse>(`user/visitor-email`, payload);
};

export const getDS160Trigger = async (applicationId?: string) => {
  return await request.get(
    `/${applicationId ? "business" : "user"}/download-ds160-application${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const getDS160MaritalStatus = async (applicationId?: string) => {
  return await request.get<ApiResponse<saveDS160MaritalStatusObj>>(
    `/${applicationId ? "business" : "user"}/get-ds160-marital-status${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`
  );
};

export const saveDS160MaritalStatus = async (
  payload: saveDS160MaritalStatusObj
) => {
  const { applicationId, ...rest } = payload;

  return await request.post<ApiResponse>(
    `/${applicationId ? "business" : "user"}/ds160-marital-status${
      applicationId ? `?applicationId=${applicationId}` : ""
    }`,
    rest
  );
};
