import React, { FC } from "react";
import { saveDS160TravelObj } from "../../../../../../api/ds160/types";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { FormDS160Progress } from "../../types";
import { GenericDs160ReviewProps } from "./DownloadPage";
import PdfReviewBox from "../../../../components/Review/Pdf Review/PdfReviewBox";
import { ReviewTravelSection } from "./data";

interface Props extends GenericDs160ReviewProps {
  data?: saveDS160TravelObj;
}

const ReviewTravel: FC<Props> = ({ title, data, isDownload }) => {
  const section: ReviewData[] = data ? ReviewTravelSection(data) : [];

  const compProps = {
    title: title,
    data: section,
    editUrl: FormDS160Progress.travel,
  };

  return isDownload ? (
    <PdfReviewBox {...compProps} />
  ) : (
    <ReviewBox {...compProps} />
  );
};

export default ReviewTravel;
