import React, { FC } from "react";
import InputField from "../../components/formik fields/InputField";
import SelectField from "../../components/formik fields/SelectField";
import { ApartmentSuiteFloorData, isZipCode } from "../../components/data";
import { FormikProps } from "formik";
import useCountries from "../../components/useCountries";
import useStatesByCountry from "../../components/useStatesByCountry";
import { FormWrapper } from "../../components/styles";
import { N400MaritalHistoryObj } from "../../../../api/n400/types";
import { MaritalHistoryData } from "./MaritalHistory";

interface Props {
  formik: FormikProps<MaritalHistoryData>;
  data?: N400MaritalHistoryObj;
}

const SpousePhysicalAddress: FC<Props> = ({ formik, data }) => {
  const { formattedCountryData, countryLoading } = useCountries();

  const { formattedStateData, stateLoading, setCountry, selectedCountry } =
    useStatesByCountry(data?.country);

  return (
    <>
      <div className="mb-8">
        <FormWrapper>
          <div>
            <InputField
              label="In Care of Name"
              coloredLabel=" (if any)"
              placeholder=""
              name={`inCareOfName`}
            />
          </div>
          <div>
            <InputField
              label="Street number and name"
              placeholder=""
              name={`streetNumberAndName`}
            />
          </div>
        </FormWrapper>

        <FormWrapper>
          <div>
            <SelectField
              name="country"
              label="Country"
              placeholder="-Select-"
              options={formattedCountryData}
              isLoading={countryLoading}
              onChange={(value) => {
                setCountry(value.label);
                formik.setFieldValue("stateOrTerritory", null);
                formik.setFieldValue(`addressCode`, "");
              }}
            />
          </div>
          <div>
            <SelectField
              name={`stateOrTerritory`}
              label="State"
              placeholder="-Select-"
              options={formattedStateData}
              isLoading={stateLoading}
              isDisabled={!selectedCountry}
            />
          </div>
        </FormWrapper>

        <FormWrapper>
          <div>
            <InputField
              label="City or Town"
              placeholder=""
              name={`cityOrTown`}
            />
          </div>
          <div>
            <InputField
              label={`${
                isZipCode(formik.values.country?.value) ? "Zip" : "Postal"
              } Code`}
              placeholder=""
              name={`addressCode`}
            />
          </div>
        </FormWrapper>

        <FormWrapper>
          <InputField
            label="Province"
            coloredLabel="(If any)"
            placeholder=""
            name={`province`}
          />

          <div></div>
        </FormWrapper>

        <FormWrapper>
          <SelectField
            label="Is there an apartment, suite or floor?"
            coloredLabel="(If any)"
            name={`apartmentSuiteOrFloor`}
            options={ApartmentSuiteFloorData}
          />

          <div>
            {formik.values.apartmentSuiteOrFloor?.value && (
              <InputField
                label={`${formik.values.apartmentSuiteOrFloor.value} Address number`}
                placeholder=""
                name={`addressNumber`}
              />
            )}
          </div>
        </FormWrapper>
      </div>
    </>
  );
};

export default SpousePhysicalAddress;
