import styled from "styled-components";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import DatePickerField from "../../components/formik fields/DatePickerField";
import { TOUSLeftUsCondition1, TOUSMonthRangeCondition } from "./data";
import {
  N400EligibilityPayload,
  n400TimeOutsideUSData,
} from "../../../../api/n400/types";
import {
  useGetN400TimeOutsideUS,
  useSaveN400TimeOutsideUS,
} from "../../../../hooks/n400/useN400";
import { TimeOutsideUSValidation } from "./validation";
import FormSectionWrapper from "../../components/FormSectionWrapper";
import dayjs, { Dayjs } from "dayjs";
import { Modify, SingleOption } from "../../../../types/types";
import {
  GenericYesNo,
  GenericYesNoOptionsValue,
  ToSaveDate,
  USDateFormat,
  USDatePlaceholderFormat,
  getSelectedOption,
  initDate,
  resetDynamicFields,
  saveDynamicCheck,
} from "../../components/data";
import AddAnotherBtn from "../../components/AddAnotherBtn";
import GroupedSection from "../../components/GroupedSection";
import useToNextSection from "../../../../hooks/useToNextSection";
import FormBtn from "../../components/FormBtn";
import SelectField from "../../components/formik fields/SelectField";
import RadioComp from "../../components/RadioComp";
import { FC } from "react";
import { TooltipType } from "../../components/Tooltip";
import relativeTime from "dayjs/plugin/relativeTime";
import { CheckWrapper } from "../../form ds 160/components/styles";
import CheckBoxField from "../../components/formik fields/CheckBoxField";
import Colors from "../../../../styles/Colors";
import useCountries from "../../components/useCountries";
import { FormWrapper } from "../../components/styles";
dayjs.extend(relativeTime);

const initialTimeOutsideUSArray = {
  countryVisited: null,
  dateYouLeft: null,
  dateYouReturned: null,
  doesNotApply: false,
  dateYouReturned_risky_checkbox: false,
};

export interface timeOutsideUSArrayPropsPayload {
  countryVisited: SingleOption | null;
  dateYouLeft: Dayjs | null;
  dateYouReturned: Dayjs | null;
  doesNotApply: boolean;
  dateYouReturned_risky_checkbox: boolean;
}

export interface N400FormTimeOffData
  extends Modify<
    Omit<
      n400TimeOutsideUSData,
      "countryVisited" | "dateYouLeft" | "dateYouReturned" | "doesNotApply"
    >,
    {
      have_you_left_us_in_the_past_five_years_risky_checkbox: boolean;
      timeOutsideUSArray: timeOutsideUSArrayPropsPayload[];
    }
  > {}

interface Props {
  formEligibility: N400EligibilityPayload;
}

const TimeOutsideUS: FC<Props> = ({ formEligibility }) => {
  const { toNextSection } = useToNextSection();
  const { formattedCountryData, countryLoading } = useCountries();
  const { data, isFetching, isError } = useGetN400TimeOutsideUS();
  const { mutate, isLoading: isSaving } = useSaveN400TimeOutsideUS();

  const savedTimeOutsideUSArray = () => {
    if (data?.countryVisited || data?.dateYouLeft || data?.dateYouReturned) {
      const countryVisitedData = data?.countryVisited.split(",").map((ev) => ({
        countryVisited: getSelectedOption(formattedCountryData, ev),
      }));
      const dateYouLeftData = data?.dateYouLeft
        .split(",")
        .map((ev) => ({ dateYouLeft: initDate(ev) }));
      const dateYouReturnedData = data?.dateYouReturned
        .split(",")
        .map((ev) => ({ dateYouReturned: initDate(ev) }));
      const doesNotApplyData = data?.doesNotApply?.split(",").map((ev) => ({
        doesNotApply: saveDynamicCheck(ev),
      }));

      return countryVisitedData.map((ev, i) => ({
        ...ev,
        ...dateYouLeftData[i],
        ...dateYouReturnedData[i],
        ...doesNotApplyData[i],
        dateYouReturned_risky_checkbox: false,
      }));
    } else return [initialTimeOutsideUSArray];
  };

  const onSubmit = (values: N400FormTimeOffData) => {
    const {
      timeOutsideUSArray,
      have_you_left_us_in_the_past_five_years_risky_checkbox,
      ...rest
    } = values;

    // if (!countryVisited) return;

    const payload = {
      ...rest,
      countryVisited: timeOutsideUSArray
        .map((ev) => ev.countryVisited?.value || "")
        .join(","),
      dateYouLeft: timeOutsideUSArray
        .map((ev) => ToSaveDate(ev.dateYouLeft, USDateFormat))
        .join(","),
      dateYouReturned: timeOutsideUSArray
        .map((ev, i) =>
          timeOutsideUSArray[i].doesNotApply
            ? ""
            : ToSaveDate(ev.dateYouReturned, USDateFormat)
        )
        .join(","),
      doesNotApply: timeOutsideUSArray
        .map((ev) => ev.doesNotApply.toString())
        .join(","),
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };
  const initialValue: N400FormTimeOffData = {
    have_you_left_us_in_the_past_five_years:
      data?.have_you_left_us_in_the_past_five_years || "",
    have_you_left_us_in_the_past_five_years_risky_checkbox: false,
    timeOutsideUSArray: savedTimeOutsideUSArray(),
  };

  const getEligibilityValidation = (
    formik: FormikProps<N400FormTimeOffData>,
    name: string
  ) => {
    if (name === "have_you_left_us_in_the_past_five_years") {
      if (TOUSLeftUsCondition1(formik.values, formEligibility))
        return {
          isReversed: true,
          type: TooltipType.risky,
        };
    }
  };

  const getFieldEligibilityValidation = (
    formik: FormikProps<N400FormTimeOffData>,
    name: string,
    index: number
  ) => {
    if (name === `timeOutsideUSArray[${index}].dateYouReturned`) {
      if (
        TOUSMonthRangeCondition({
          data: formik.values,
          index: index + 1, //will minus it back wthin the function
        })
      )
        return {
          body: (
            <>
              This trip appears to be 6 months or longer. An absence from the
              United States of 6 months or more may disrupt your continuous
              residence requirement. <br /> Unless you can prove that you did
              not abandon your residence in the United States, USCIS will
              presume that you have broken your continuous residence.
              <br />
              <br /> We recommend you consult an immigration attorney before
              filing N-400.
            </>
          ),
          type: TooltipType.risky,
          checkLabel:
            "I acknowledge that I have consulted with an attorney and/or want to continue anyway",
        };
    }
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValue}
          validationSchema={TimeOutsideUSValidation(formEligibility)}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <Mini>
                <RadioComp
                  title={`Have you left the United States in the past 5 years`}
                  name="have_you_left_us_in_the_past_five_years"
                  options={GenericYesNo}
                  onChange={() => {
                    if (!data) {
                      resetDynamicFields(formik, [
                        {
                          name: "timeOutsideUSArray",
                          value: [initialTimeOutsideUSArray],
                        },
                      ]);
                    }
                  }}
                  toolTipProps={getEligibilityValidation(
                    formik,
                    "have_you_left_us_in_the_past_five_years"
                  )}
                />

                {formik.values.have_you_left_us_in_the_past_five_years ===
                  GenericYesNoOptionsValue.yes && (
                  <FieldArray
                    name="timeOutsideUSArray"
                    render={(arrayHelpers) => (
                      <div className="flex flex-col gap-6">
                        {formik.values.timeOutsideUSArray.map((_, index) => (
                          <div className="p-4 border rounded">
                            <GroupedSection
                              key={index}
                              index={index}
                              onCancel={() => arrayHelpers.remove(index)}
                            >
                              <div className={`text-[${Colors.Blue00}]`}>
                                Entry {index + 1}:
                              </div>
                              <FormWrapper>
                                <div>
                                  <SelectField
                                    name={`timeOutsideUSArray[${index}].countryVisited`}
                                    label={`Country Visited`}
                                    placeholder="-Select-"
                                    options={formattedCountryData}
                                    isLoading={countryLoading}
                                  />
                                </div>
                                <div></div>
                              </FormWrapper>
                              <FormWrapper>
                                <div>
                                  <DatePickerField
                                    name={`timeOutsideUSArray[${index}].dateYouLeft`}
                                    label="Date you left"
                                    placeholder={USDatePlaceholderFormat}
                                    disableFuture={true}
                                  />
                                </div>
                                <CheckDateWrapper>
                                  <DatePickerField
                                    name={`timeOutsideUSArray[${index}].dateYouReturned`}
                                    label="Date you returned"
                                    placeholder={USDatePlaceholderFormat}
                                    disableFuture={true}
                                    toolTipProps={getFieldEligibilityValidation(
                                      formik,
                                      `timeOutsideUSArray[${index}].dateYouReturned`,
                                      index
                                    )}
                                    isDisabled={
                                      formik.values.timeOutsideUSArray.length -
                                        1 ===
                                        index &&
                                      !!formik.values.timeOutsideUSArray[index]
                                        .doesNotApply
                                    }
                                  />
                                  {formik.values.timeOutsideUSArray.length -
                                    1 ===
                                    index && (
                                    <CheckWrapper>
                                      <CheckBoxField
                                        label={`Yet to return.`}
                                        name={`timeOutsideUSArray[${index}].doesNotApply`}
                                        onChange={() => {
                                          formik.setFieldTouched(
                                            `timeOutsideUSArray[${index}].dateYouReturned`,
                                            false
                                          );
                                          formik.setFieldValue(
                                            `timeOutsideUSArray[${index}].dateYouReturned`,
                                            null
                                          );
                                        }}
                                      />
                                    </CheckWrapper>
                                  )}
                                </CheckDateWrapper>
                              </FormWrapper>
                            </GroupedSection>
                          </div>
                        ))}

                        {formik.values.timeOutsideUSArray.length < 10 && (
                          <AddAnotherBtn
                            onClick={() => {
                              arrayHelpers.push(initialTimeOutsideUSArray);
                              const index =
                                formik.values.timeOutsideUSArray.length - 1;
                              if (
                                formik.values.timeOutsideUSArray[index]
                                  .doesNotApply
                              ) {
                                formik.setFieldValue(
                                  `timeOutsideUSArray[${index}].doesNotApply`,
                                  false
                                );
                                formik.setFieldValue(
                                  `timeOutsideUSArray[${index}].dateYouReturned`,
                                  null
                                );
                              }
                            }}
                          />
                        )}
                      </div>
                    )}
                  />
                )}
              </Mini>

              <FormBtn
                isLoading={isSaving}
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default TimeOutsideUS;

const Wrapper = styled.div``;

const Mini = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const CheckDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
