import styled from "styled-components";
import Stats from "./components/Stats";
import Case from "./components/Case";

const BusinessDashboardPage = () => {
  return (
    <Wrapper>
      <Stats />
      <Case />
    </Wrapper>
  );
};

export default BusinessDashboardPage;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
