import React from "react";
import styled from "styled-components";
import { Form, Formik } from "formik";
import { Modify } from "../../../../types/types";
import { n400FeeReductionObj } from "../../../../api/n400/types";
import FormBtn from "../../components/FormBtn";
import { FeeReductionValidation } from "./validation";
import useToNextSection from "../../../../hooks/useToNextSection";
import InputField from "../../components/formik fields/InputField";
import FormSectionWrapper from "../../components/FormSectionWrapper";
import RadioComp from "../../components/RadioComp";
import { HalfFormWrapper } from "../../form ds 160/components/styles";
import {
  useGetN400FeeReduction,
  useSaveN400FeeReduction,
} from "../../../../hooks/n400/useN400";
import {
  GenericYesNo,
  GenericYesNoOptionsValue,
  initInput,
  resetDynamicFields,
} from "../../components/data";

export interface FeeReductionData extends Modify<n400FeeReductionObj, {}> {}

const FeeReduction = () => {
  const { toNextSection } = useToNextSection();
  const { data, isFetching, isError } = useGetN400FeeReduction();
  const { mutate, isLoading: isSaving } = useSaveN400FeeReduction();

  const onSubmit = (values: FeeReductionData) => {
    const payload = {
      ...values,
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValue: FeeReductionData = {
    household_income: initInput(data?.household_income),
    total_household_income: initInput(data?.total_household_income),
    household_size: initInput(data?.household_size),
    total_number_of_household_members: initInput(
      data?.total_number_of_household_members
    ),
    head_of_household: initInput(data?.head_of_household),
    name_of_head_of_household: initInput(data?.name_of_head_of_household),
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValue}
          onSubmit={onSubmit}
          validationSchema={FeeReductionValidation}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <HalfFormWrapper>
                <RadioComp
                  name="household_income"
                  title="My household income is less than or equal to 400% of the Federal Poverty Guidelines (see Instructions for required documentation)."
                  options={GenericYesNo}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "total_household_income", value: "" },
                      { name: "household_size", value: "" },
                      { name: "total_number_of_household_members", value: "" },
                      { name: "head_of_household", value: "" },
                      { name: "name_of_head_of_household", value: "" },
                    ]);
                  }}
                />

                {formik.values.household_income ===
                  GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      name="total_household_income"
                      label="Total household income:"
                      placeholder=""
                    />
                    <InputField
                      name="household_size"
                      label="My household size is:"
                      placeholder=""
                    />
                    <InputField
                      name="total_number_of_household_members"
                      label="Total number of household members earning income including yourself:"
                      placeholder=""
                    />

                    <RadioComp
                      name="head_of_household"
                      title="I am the head of household."
                      options={GenericYesNo}
                    />

                    {formik.values.head_of_household ===
                      GenericYesNoOptionsValue.no && (
                      <InputField
                        name="name_of_head_of_household"
                        label="Name of head of household"
                        placeholder=""
                      />
                    )}
                  </>
                )}
              </HalfFormWrapper>

              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default FeeReduction;

const Wrapper = styled.div``;
