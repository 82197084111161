import React from "react";
import styled from "styled-components";
import { mediaObj } from "../../../../styles/Media";
import Constaint from "../../layout/components/Constaint";
import { faqData } from "./data";
import FAQItems from "./FAQItems";
import Colors from "../../../../styles/Colors";

const FAQ = () => {
  return (
    <Wrapper>
      <Constaint>
        <Container>
          <H1>Frequently Asked Questions</H1>
          <MiniWrapper>
            {faqData.map((ev, i) => (
              <FAQItems key={i} label={ev.label} value={ev.value} />
            ))}
          </MiniWrapper>
        </Container>
      </Constaint>
    </Wrapper>
  );
};

export default FAQ;

const Wrapper = styled.div`
  background-color: ${Colors.WhiteF9};
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 100px 0;
  width: 70%;

  ${mediaObj.tablet} {
    width: 90%;
  }

  ${mediaObj.bigMobile} {
    width: 100%;
  }
`;

const H1 = styled.h1`
  font-size: 2.5rem;
  text-align: center;
  color: ${Colors.Black31};
  margin-bottom: 43px;
`;

const MiniWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
