import { proxy } from "valtio";
import session from "../utils/session";

const shownDisclaimer = session.get("seenDisclaimer");

export const noticeStore = proxy<{
  seenDisclaimer: boolean | undefined;
  seenFiBiBanner: boolean | undefined;
}>({
  seenDisclaimer: shownDisclaimer,
  seenFiBiBanner: session.get("seenFiBiBanner"),
});
