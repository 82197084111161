export enum FormN400Progress {
  applicantInfo1 = "n400 Applicant Info 1",
  applicantInfo2 = "n400 Applicant Info 2",
  bioInfo = "n400 Applicant bio Info",
  addressHistory = "n400 Applicant Address History",
  maritalHistory = "n400 Applicant Marital History",
  childrenInfo = "n400 Applicant info about children",
  employmentAndSchoolHistory = "n400 Applicant Employment and School History",
  timeoff = "n400 Time outside the US",
  checkPart1 = "n400 Checklist 1 for naturalization",
  checkPart2 = "n400 Checklist 2 for naturalization",
  checkPart3 = "n400 Checklist 3 for naturalization",
  checkPart4 = "n400 Checklist 4 for naturalization",
  feeDeduct = "n400 request for fee reduction",
  PI = "n400 Preparer and interpreter",
  review = "n400-review",
}
