import React, { useEffect } from "react";
// import Hero from "./components/Hero";
// import Why from "./components/Why";
// import HowItWorks from "./components/HowItWorks";
import Pricing from "./components/Pricing";
import Cta from "./components/Cta";
import Banner from "./components/Banner";
import ToHelp from "./components/ToHelp";
import Dashboard from "./components/Dashboard";

const BusinessService = () => {
  useEffect(() => {
    document.title = `For Businesses | Maximize Visa Approval`;

    return () => {
      document.title =
        "SeamlessVisa | The Trusted Partner in Your Immigration Journey";
    };
  }, []);
  return (
    <>
      {/* <Hero /> */}
      <Banner />
      {/* <Why /> */}
      <ToHelp />
      {/* <HowItWorks /> */}
      <Dashboard />
      <Pricing />
      <Cta />
    </>
  );
};

export default BusinessService;
