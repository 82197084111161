import React, { FC } from "react";
import DownloadPage from "./DownloadPage";
import { GetDs160Obj } from "../../../../../../api/ds160/types";
import { Document, Image, Page, StyleSheet } from "@react-pdf/renderer";
import Logo from "../../../../../../images/icons/logo.png";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "white",
    // margin: 10,
  },
});

interface Props {
  isToStudy: boolean;
  data?: GetDs160Obj;
}

const MyDoc: FC<Props> = ({ data, isToStudy }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image
          style={{
            width: "170px",
            height: "35px",
            margin: "auto",
          }}
          src={Logo}
        />
        <DownloadPage data={data} isToStudy={isToStudy} isDownload />
      </Page>
    </Document>
  );
};

export default MyDoc;
