import { FC } from "react";
import { bytesForHuman, truncateTxt } from "../../../../../../../utils";
import { MdCancel } from "react-icons/md";

interface Props {
  onClear: () => void;
  file: File;
}

const FilePreview: FC<Props> = ({ onClear, file }) => {
  const regex = new RegExp("[^.]+$");
  const extension = file.name.match(regex)?.[0];
  const label = extension ? file.name.split(extension)[0] : "";
  const size = bytesForHuman(file.size);

  return (
    <div className="flex items-center gap-4 px-4 py-1 border-[2px] border-solid border-gray-500-600 h-max">
      <div className="flex flex-col flex-1 ">
        <div className="text-sm whitespace-nowrap">
          {truncateTxt(label || "", 15)}
          {extension}
        </div>
        <div className="text-xs ">{size}</div>
      </div>
      <MdCancel
        className="text-red-600 cursor-pointer"
        width={16}
        height={16}
        onClick={onClear}
      />
    </div>
  );
};

export default FilePreview;
