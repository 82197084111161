import {
  ArrowPathIcon,
  ChatBubbleBottomCenterTextIcon,
  MagnifyingGlassIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/solid";

interface Feature {
  name: string;
  description: string;
  icon: JSX.Element;
}

const features: Feature[] = [
  {
    name: "Automated Error Checking",
    description:
      "Our software scans for common errors, ensuring that every detail of your application is accurate and complete.",
    icon: (
      <MagnifyingGlassIcon className="h-5 w-5 text-white" aria-hidden="true" />
    ),
  },
  {
    name: "AI Risk Evaluation",
    description:
      "By analyzing your application data, our AI-driven tool provides insights into your visa approval chances, allowing you to make any necessary improvements.",
    icon: <ArrowPathIcon className="h-5 w-5 text-white" aria-hidden="true" />,
  },
  {
    name: "FiBi AI Interview Coach",
    description:
      "With personalized AI-driven interview preparation, you’ll feel ready and confident for your consular interview.",
    icon: (
      <ChatBubbleBottomCenterTextIcon
        className="h-5 w-5 text-white"
        aria-hidden="true"
      />
    ),
  },
  {
    name: "Expert Support",
    description:
      "Whether you’re an individual applicant or a business handling multiple cases, our immigration experts are here to help you every step of the way.",
    icon: <ShieldCheckIcon className="h-5 w-5 text-white" aria-hidden="true" />,
  },
];

const WhySeamless = () => {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          {/* <h2 className="text-base/7 font-semibold text-indigo-600">
            Deploy faster
          </h2> */}
          <p className="mt-2 text-pretty text-4xl font-bold font-spartan tracking-tight text-gray-900 sm:text-5xl lg:text-balance">
            Why Choose SeamlessVisa?
          </p>
          <p className="mt-6 text-lg/8 text-gray-600">
            SeamlessVisa is designed to empower users by reducing the risk of
            visa rejections through our innovative and accessible tools:
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base/7 font-semibold text-gray-900">
                  <div className="absolute left-0 top-0 h-10 w-10 flex items-center justify-center rounded-lg bg-Blue00">
                    {feature.icon}
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base/7 text-gray-600">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default WhySeamless;
