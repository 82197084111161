import React, { FC } from "react";
import ReviewPersonal from "./ReviewPersonal";
import ReviewTravel from "./ReviewTravel";
import ReviewTravelComp from "./ReviewTravelComp";
import ReviewPreviousUS from "./ReviewPreviousUS";
import ReviewAddressAndPhone from "./ReviewAddressAndPhone";
import ReviewPassport from "./ReviewPassport";
import ReviewUSContact from "./ReviewUSContact";
import ReviewFamily from "./ReviewFamily";
import ReviewWETAdditional from "./ReviewWETAdditional";
import ReviewSecurity from "./ReviewSecurity";
import ReviewSEV from "./ReviewSEV";
import { GetDs160Obj } from "../../../../../../api/ds160/types";

export interface GenericDs160ReviewProps {
  title: string;
  isDownload?: boolean;
}

interface Props {
  isToStudy: boolean;
  data?: GetDs160Obj;
  isDownload?: boolean;
}

const DownloadPage: FC<Props> = ({ isToStudy, data, isDownload }) => {
  return (
    <>
      <ReviewPersonal
        title="1. Personal Information"
        data={data?.personalInfo}
        isDownload={isDownload}
      />
      <ReviewTravel
        title="2. Travel Information"
        data={data?.travel}
        isDownload={isDownload}
      />
      <ReviewTravelComp
        title="3. Travel Companion"
        data={data?.travelCompanions}
        isDownload={isDownload}
      />
      <ReviewPreviousUS
        title="4. Previous US Travel"
        data={data?.previousUsTravel}
        isDownload={isDownload}
      />
      <ReviewAddressAndPhone
        title="5. Address and Phone"
        data={data?.addressAndPhone}
        isDownload={isDownload}
      />
      <ReviewPassport
        title="6. Passport"
        data={data?.passport}
        isDownload={isDownload}
      />
      <ReviewUSContact
        title="7. US Contact"
        data={data?.usContact}
        isDownload={isDownload}
      />
      <ReviewFamily
        title="8. Family"
        data={data?.family}
        maritalStatusData={data?.maritalStatus}
        isDownload={isDownload}
      />
      <ReviewWETAdditional
        title="9. Work/Education/Training"
        data={data?.workEducationTraining}
        isDownload={isDownload}
      />
      <ReviewSecurity
        title="10. Security and Background"
        data={data?.Security}
        isDownload={isDownload}
      />
      {isToStudy && (
        <ReviewSEV
          title="11. Student Exchange Visa"
          data={data?.studentExchangeVisa}
          isDownload={isDownload}
        />
      )}
    </>
  );
};

export default DownloadPage;
