import React, { FC } from "react";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { N600InfoAboutUSCitizenFatherPart1Payload } from "../../../../../../api/n600/types";
import { FormN600Progress } from "../../types";

interface Props {
  title: string;
  data?: N600InfoAboutUSCitizenFatherPart1Payload;
}

const ReviewUSFatherInfoPart1: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      label: "Family Name (Last Name)",
      value: data?.fatherCurrentLegalFamilyName,
    },
    {
      label: "Given Name (First Name)",
      value: data?.fatherCurrentLegalGivenName,
    },
    {
      label: "Middle Name",
      value: data?.fatherCurrentLegalMiddleName,
    },
    {
      label: "Date of Birth",
      value: data?.fatherDateOfBirth,
    },
    {
      label: "Country of Birth",
      value: data?.fatherCountryOfBirth,
    },
    {
      label: "Country of Citizenship",
      value: data?.fatherCountryOfCitizenship,
    },
    {
      label: "Is your father presently alive?",
      value: data?.isFatherAlive,
    },
    {
      label: "Date of Death",
      value: data?.fatherDateOfDeath,
    },
    {
      label: "Street Number and Name",
      value: data?.fatherPhysicalAddressStreetNumberAndName,
    },
    {
      label: "Apt. Ste. Flr",
      value: data?.fatherPhysicalAddressAptSteFlr,
    },
    {
      label: "Apt. Ste. Flr Number",
      value: data?.fatherPhysicalAddressAptSteFlrNumber,
    },
    {
      label: "City or Town",
      value: data?.fatherPhysicalAddressCityOrTown,
    },
    {
      label: "State",
      value: data?.fatherPhysicalAddressState,
    },
    {
      label: "ZIP Code",
      value: data?.fatherPhysicalAddressZipCode,
    },
    {
      label: "Province (foreign address only)",
      value: data?.fatherPhysicalAddressProvince,
    },
    {
      label: "Postal Code (foreign address only)",
      value: data?.fatherPhysicalAddressPostalCode,
    },
    {
      label: "Country (foreign address only)",
      value: data?.fatherPhysicalAddressCountry,
    },
    {
      label: "My father is a U.S. citizen by",
      value: data?.fatherCitizenshipStatus,
    },
    {
      label: "Certificate of Citizenship Number",
      value: data?.fatherCertificateOfCitizenshipNumber,
    },
    {
      label: "Alien Registration Number (A-Number)",
      value: data?.fatherAlienRegistrationNumber,
    },
    {
      label: "Place of Naturalization (Name of Court or USCIS Office Location)",
      value: data?.fatherPlaceOfNaturalizationCourtOrUSCISOffice,
    },
    {
      label: "Place of Naturalization City or Town",
      value: data?.fatherPlaceOfNaturalizationCityOrTown,
    },
    {
      label: "Place of Naturalization State",
      value: data?.fatherPlaceOfNaturalizationState,
    },
    {
      label: "Certificate of Naturalization Number",
      value: data?.fatherCertificateOfNaturalizationNumber,
    },
    {
      label: "A-Number (if any)",
      value: data?.fatherNaturalizationANumber,
    },
    {
      label: "Date of Naturalization",
      value: data?.fatherDateOfNaturalization,
    },
  ];

  return (
    <ReviewBox
      title={title}
      data={section}
      editUrl={FormN600Progress.infoAboutUSCitizenFatherP1}
    />
  );
};

export default ReviewUSFatherInfoPart1;
