import React, { FC } from "react";
import useCountries from "../../components/useCountries";
import { RenderAltCompProps } from "./types";
import EligibilitySelectField from "./EligibilitySelectField";

interface Props extends RenderAltCompProps {}

const CountryEligibility: FC<Props> = ({ formData, onChange, result }) => {
  const { formattedCountryData, countryLoading } = useCountries();

  return (
    <EligibilitySelectField
      formattedData={formattedCountryData}
      isLoading={countryLoading}
      formData={formData}
      onChange={onChange}
      result={result}
    />
  );
};

export default CountryEligibility;
