import styled from "styled-components";
import { FieldArray, Form, Formik } from "formik";
import { Note } from "./styles";
import { Modify, SingleOption } from "../../../../types/types";
import {
  N400EligibilityPayload,
  n400EmploymentHistoryData,
} from "../../../../api/n400/types";
import { Dayjs } from "dayjs";
import {
  useGetN400EmploymentHistory,
  useSaveN400EmploymentHistory,
} from "../../../../hooks/n400/useN400";
import FormSectionWrapper from "../../components/FormSectionWrapper";
import GroupedSection from "../../components/GroupedSection";
import AddAnotherBtn from "../../components/AddAnotherBtn";
import useToNextSection from "../../../../hooks/useToNextSection";
import {
  ToSaveDate,
  USDateFormat,
  formatMultiStates,
  initDate,
  isZipCode,
  saveDynamicCheck,
} from "../../components/data";
import FormBtn from "../../components/FormBtn";

import useMultiStatesByCountry from "./useMultiStatesByCountry";
import EmploymentBox from "./EmploymentBox";
import { EmploymentHistoryValidation } from "./validation";
import { GetN400ReasonForFiling, N400ReasonForFiling } from "./data";
import Colors from "../../../../styles/Colors";
import { FC } from "react";
import { toast } from "react-toastify";

const initialEmploymentHistoryArray: EmploymentHistoryPropsPayload = {
  employerOrSchoolName: "",
  cityOrTown: "",
  stateOrProvince: null,
  addressCode: "",
  country: null,
  dateFrom: null,
  dateTo: null,
  occupationOrFieldOfStudy: null,
  doesNotApplyDateTo: false,
};

export interface EmploymentHistoryPropsPayload {
  employerOrSchoolName: string;
  cityOrTown: string;
  stateOrProvince: SingleOption | null;
  addressCode: string;
  country: SingleOption | null;
  dateFrom: Dayjs | null;
  dateTo: Dayjs | null;
  occupationOrFieldOfStudy: SingleOption | null;
  doesNotApplyDateTo: boolean;
}

export interface EmploymentHistoryData
  extends Modify<
    Omit<
      n400EmploymentHistoryData,
      | "employerOrSchoolName"
      | "country"
      | "zipCode"
      | "postalCode"
      | "cityOrTown"
      | "stateOrProvince"
      | "dateFrom"
      | "dateTo"
      | "occupationOrFieldOfStudy"
      | "doesNotApplyDateTo"
    >,
    {
      employmentHistoryArray: EmploymentHistoryPropsPayload[];
    }
  > {}

interface Props {
  formEligibility: N400EligibilityPayload;
}

const EmploymentHistoryInfo: FC<Props> = ({ formEligibility }) => {
  const { toNextSection } = useToNextSection();
  const { data, isFetching, isError } = useGetN400EmploymentHistory();
  const { mutate, isLoading: isSaving } = useSaveN400EmploymentHistory();
  const { statesArray } = useMultiStatesByCountry(data?.country);

  const savedEmploymentHistoryArray = () => {
    if (
      data?.employerOrSchoolName ||
      data?.country ||
      data?.zipCode ||
      data?.cityOrTown ||
      data?.stateOrProvince ||
      data?.dateFrom ||
      data?.dateTo ||
      data?.occupationOrFieldOfStudy
    ) {
      const employerOrSchoolNameData = data?.employerOrSchoolName
        .split(",")
        .map((ev) => ({ employerOrSchoolName: ev }));

      const cityOrTownData = data?.cityOrTown
        .split(",")
        .map((ev) => ({ cityOrTown: ev }));

      const countryArray = data?.country.split(",");

      // permanent patch for now
      const countryData = countryArray.map((ev) => ({
        country: { value: ev, label: ev },
      }));

      // permanent patch for now
      const stateOrProvince = formatMultiStates({
        dataKey: data?.stateOrProvince,
        fieldKey: "stateOrProvince",
        dataArray: statesArray,
      }) as { stateOrProvince: SingleOption }[];

      const dateFromData = data?.dateFrom
        .split(",")
        .map((ev) => ({ dateFrom: initDate(ev) }));
      const dateToData = data?.dateTo
        .split(",")
        .map((ev) => ({ dateTo: initDate(ev) }));

      // rubbish patch for now
      const occupationOrFieldOfStudyData = data?.occupationOrFieldOfStudy
        .split(",")
        .map((ev) => ({ occupationOrFieldOfStudy: { value: ev, label: ev } }));

      const addressCodeData = data?.zipCode?.split(",").map((ev, index) => ({
        addressCode: isZipCode(countryArray[index])
          ? ev
          : data?.postalCode?.split(",")[index],
      }));

      const doesNotApplyDateToData = data?.doesNotApplyDateTo
        ?.split(",")
        .map((ev) => ({
          doesNotApplyDateTo: saveDynamicCheck(ev),
        }));

      return employerOrSchoolNameData.map((ev, i) => ({
        ...ev,
        ...cityOrTownData[i],
        ...stateOrProvince[i],
        ...countryData[i],
        ...dateFromData[i],
        ...dateToData[i],
        ...occupationOrFieldOfStudyData[i],
        ...addressCodeData[i],
        ...doesNotApplyDateToData[i],
      }));
    } else return [initialEmploymentHistoryArray];
  };

  const onSubmit = (values: EmploymentHistoryData) => {
    const { employmentHistoryArray, ...rest } = values;

    const payload = {
      ...rest,
      employerOrSchoolName: employmentHistoryArray
        .map((ev) => ev.employerOrSchoolName)
        .join(","),

      country: employmentHistoryArray
        .map((ev) => ev.country?.value || "")
        .join(","),
      zipCode: employmentHistoryArray
        .map((ev) => (isZipCode(ev.country?.value) ? ev.addressCode : ""))
        .join(","),
      postalCode: employmentHistoryArray
        .map((ev) => (!isZipCode(ev.country?.value) ? ev.addressCode : ""))
        .join(","),

      cityOrTown: employmentHistoryArray.map((ev) => ev.cityOrTown).join(","),

      stateOrProvince: employmentHistoryArray
        .map((ev) => ev.stateOrProvince?.value || "")
        .join(","),

      dateFrom: employmentHistoryArray
        .map((ev) => ToSaveDate(ev.dateFrom, USDateFormat))
        .join(","),

      dateTo: employmentHistoryArray
        .map((ev) => ToSaveDate(ev.dateTo, USDateFormat))
        .join(","),

      occupationOrFieldOfStudy: employmentHistoryArray
        .map((ev) => ev.occupationOrFieldOfStudy?.value || "")
        .join(","),

      doesNotApplyDateTo: employmentHistoryArray
        .map((ev) => ev.doesNotApplyDateTo.toString())
        .join(","),
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValue: EmploymentHistoryData = {
    employmentHistoryArray: savedEmploymentHistoryArray(),
  };

  const getInfo = () => {
    if (
      GetN400ReasonForFiling(formEligibility) ===
        N400ReasonForFiling.spouseUSCitizen ||
      GetN400ReasonForFiling(formEligibility) ===
        N400ReasonForFiling.employmentSpouseUSCitizen ||
      GetN400ReasonForFiling(formEligibility) === N400ReasonForFiling.vawa
    )
      return "List where you have worked or attended school full time or part time during the last 3 years.";
    else if (
      GetN400ReasonForFiling(formEligibility) ===
      N400ReasonForFiling.hostilitiesMilitary
    )
      return "List where you have worked or attended school full time or part time during the last year";

    return "List where you have worked or attended school full time or part time during the last 5 years.";
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValue}
          onSubmit={onSubmit}
          validationSchema={EmploymentHistoryValidation}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <Note>{getInfo()}</Note>
              {/* <Title>Employer or School</Title> */}

              <FieldArray
                name="employmentHistoryArray"
                render={(arrayHelpers) => (
                  <div className="px-2 py-3 mb-8 border rounded">
                    {formik.values.employmentHistoryArray.map((_, index) => (
                      <GroupedSection
                        key={index}
                        index={index}
                        onCancel={() => arrayHelpers.remove(index)}
                      >
                        <div className={`text-[${Colors.Blue00}]`}>
                          Entry {index + 1}:
                        </div>

                        <EmploymentBox
                          data={data}
                          formik={formik}
                          index={index}
                        />
                      </GroupedSection>
                    ))}
                    <AddAnotherBtn
                      onClick={() => {
                        if (formik.values.employmentHistoryArray.length === 8) {
                          toast.error("Limit Reached");
                          return;
                        }
                        arrayHelpers.push(initialEmploymentHistoryArray);
                      }}
                    />
                  </div>
                )}
              />

              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default EmploymentHistoryInfo;

const Wrapper = styled.div``;

// const Title = styled.div`
//   color: ${Colors.Black31};
//   font-weight: bold;
// `;

// const Sub = styled.div`
//   color: ${Colors.Black31};
//   margin-top: 19px;
//   margin-bottom: 30px;
//   font-weight: 300;
//   font-size: 0.875rem;
// `;
