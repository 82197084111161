import React from "react";

const AiDisclaimer = () => {
  return (
    <div className="py-2 text-sm text-center text-primaryColor">
      Our AI offers broad insights but isn't a substitute for legal advice.
      Immigration laws are complex and vary, so always consult a qualified
      immigration attorney for personalized guidance.
    </div>
  );
};

export default AiDisclaimer;
