import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { getFormatedFormName } from "../../components/data";

const ParamFormName = () => {
  const { formType } = useParams();

  if (formType === "ds160") return <span>Non-immigrant Visa</span>;
  return <Subber>{getFormatedFormName(formType)}</Subber>;
};

export default ParamFormName;

const Subber = styled.span`
  text-transform: uppercase;
`;
