import React, { FC, ReactNode } from "react";
import { GiCancel } from "react-icons/gi";
import styled from "styled-components";

interface Props {
  index: number;
  children: ReactNode;
  onCancel: () => void;
}

const GroupedSection: FC<Props> = ({ index, children, onCancel }) => {
  return (
    <SingleArrayWrapper key={index}>
      {index + 1 > 1 && (
        <LocationCancel className="cancel" onClick={onCancel}>
          <GiCancel />
        </LocationCancel>
      )}
      {children}
    </SingleArrayWrapper>
  );
};

export default GroupedSection;

const SingleArrayWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const LocationCancel = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  transform: translateY(100%);

  & > svg {
    color: red;
    cursor: pointer;
  }
`;
