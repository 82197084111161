import React, { FC } from "react";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { N400ApplicantInfo2Obj } from "../../../../../../api/n400/types";
import { FormN400Progress } from "../../types";

interface Props {
  title: string;
  data?: N400ApplicantInfo2Obj;
}

const ReviewApplicantInfo2: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      label: "Applicant's Daytime Telephone Number",
      value: data?.daytimeTelephoneNumber,
    },
    {
      label: "Applicant's Mobile Telephone Number",
      value: data?.mobileTelephoneNumber,
    },
    {
      label: "Applicant's Email Address",
      value: data?.email,
    },
    {
      label: "Alien Registration Number",
      value: data?.aNumber,
    },
    {
      label:
        "Was one of your parents (including adoptive parents) a U.S. citizen before your 18th birthday?",
      value: data?.was_one_of_parent_us_citizen,
    },
    {
      label:
        "Do you have a physical or developmental disability or mental impairment that prevents you from demonstrating your knowledge and understanding of the English language or civics requirements for naturalization?",
      value: data?.disability,
    },
    {
      section: {
        title: "Social Security Update",
        children: [
          {
            label:
              "Do you want the Social Security Administration (SSA) to issue you an original or replacement Social Security card and update your immigration status with the SSA if and when you are naturalized?",
            value: data?.do_you_want_social_security_card,
          },
          {
            label: "Provide your Social Security number (SSN)",
            value: data?.usSocialSecurityNumber,
          },
          {
            label:
              "Consent for Disclosure: I authorize disclosure of information from this application and USCIS systems to the SSA as required for the purpose of assigning me an SSN, issuing me an original or replacement Social Security card, and updating my immigration status with the SSA.",
            value: data?.consentForDisclosure ? "Agree" : "Disagree",
          },
        ],
      },
    },
  ];

  return (
    <ReviewBox
      title={title}
      data={section}
      editUrl={FormN400Progress.applicantInfo1}
    />
  );
};

export default ReviewApplicantInfo2;
