import aboutPattern from "../../../../../images/web/about-image.svg";

const Hero = () => {
  return (
    <div className="bg-white">
      {/* Hero section */}
      <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-14">
        <div
          aria-hidden="true"
          className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
        />
        <div className="mx-auto max-w-7xl px-6 py-32 sm:py-40 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-8 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
            <h2 className="text-5xl font-bold font-spartan tracking-tight text-gray-900 sm:text-7xl">
              About Us:{" "}
              <span className="font-bold font-spartan text-Blue00">
                SeamlessVisa
              </span>
            </h2>
            <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
              <p className="text-lg font-medium text-gray-500 sm:text-xl">
                At SeamlessVisa, we know that every immigration journey is
                deeply personal, often marked by challenges, aspirations, and
                emotions. We’re here to make this journey as smooth and
                stress-free as possible, for both individuals and businesses.
                With a powerful, technology-driven platform, SeamlessVisa
                streamlines the U.S. visa application process, eliminating the
                guesswork and complexity that so many applicants face.
              </p>
            </div>
            <img
              alt=""
              src={aboutPattern}
              className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
            />
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>
    </div>
  );
};

export default Hero;
