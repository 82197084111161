import { useSnapshot } from "valtio";
import { FormCategories } from "../../../../../routes/type";
import { pricingData } from "../../../components/data";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import PricingModal from "../../../components/PricingModal";
import ReviewBtns from "../../../components/Review/ReviewBtns";
import ReviewTop from "../../../components/Review/ReviewTop";
import useIsPaid from "../../../../../hooks/useIsPaid";
import useScrollVisible from "../../../components/Review/useScrollVisible";
import {
  useGetN600Eligibility,
  useGetN600Info,
  usePayN600,
} from "../../../../../hooks/n600/useN600";
import { userDataStore } from "../../../../../store/userData";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { CaseHistoryObj } from "../../../../../types/types";
import N600PaySuccess from "../N600PaySuccesss";
import N600PayFail from "../N600PayFail";
import DownloadForms from "../../../components/DownloadForms";
import ReviewEligibilityInfo from "./components/ReviewEligibilityInfo";
import ReviewInfoAboutYouPart1 from "./components/ReviewInfoAboutYouPart1";
import ReviewInfoAboutYouPart2 from "./components/ReviewInfoAboutYouPart2";
import ReviewInfoAboutYouPart3 from "./components/ReviewInfoAboutYouPart3";
import ReviewInfoAboutYouPart4 from "./components/ReviewInfoAboutYouPart4";
import ReviewBioInfo from "./components/ReviewBioInfo";
import ReviewUSFatherInfoPart1 from "./components/ReviewUSFatherInfoPart1";
import ReviewUSMotherInfoPart1 from "./components/ReviewUSMotherInfoPart1";
import ReviewUSMotherInfoPart2 from "./components/ReviewUSMotherInfoPart2";
import ReviewUSFatherInfoPart2 from "./components/ReviewUSFatherInfoPart2";
import ReviewPhysicalPresence from "./components/ReviewPhysicalPresence";
import ReviewParentMilitaryService from "./components/ReviewParentMilitaryService";
import { formEligibilityStore } from "../../../../../store/formEligibilityData";
import ReviewPI from "../../../PI/components/ReviewPI";
import { FormN400Progress } from "../../../form 400/components/types";
import { BtnFooterWrapper } from "../../../components/styles";
import FormBtn from "../../../components/FormBtn";

const N600Review = () => {
  const { isFormPaid } = useIsPaid();
  const { showTop } = useScrollVisible();
  const { data, isFetching, isError } = useGetN600Info();
  const { mutate: payMutate, isLoading: isPaying } = usePayN600();
  const { userData } = useSnapshot(userDataStore);

  const [searchParams] = useSearchParams();
  const isSuccess = searchParams.get("pay");
  const isFail = searchParams.get("fail");

  const [selectPlan, setSelectPlan] = useState<boolean>(false);

  const [onDownload, setOnDownload] = useState<CaseHistoryObj>();

  const { data: eligibilityData } = useGetN600Eligibility();
  formEligibilityStore.formEligibility = eligibilityData;
  const isFatherCitizen =
    eligibilityData?.which_parent_is_a_us_citizen ===
    "My father is a U.S citizen";
  const isMotherCitizen =
    eligibilityData?.which_parent_is_a_us_citizen ===
    "My mother is a U.S citizen";
  const isBothParent =
    eligibilityData?.which_parent_is_a_us_citizen ===
    "Both of my parents are U.S citizens";

  const onOpenDownload = () => {
    const formType = FormCategories.n600;
    const tableData = userData?.caseHistory.find(
      (ev) => ev.caseType === formType
    );
    setOnDownload(tableData);
  };

  const onCloseDownload = () => {
    setOnDownload(undefined);
  };

  const onSelectPlan = () => setSelectPlan(true);

  const onPay = (plan: "standard" | "premium", couponCode: string) => {
    payMutate(
      { plan, couponCode },
      {
        onSuccess: (data) => {
          window.location.replace(data.data.url);
        },
      }
    );
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <>
        {selectPlan && (
          <PricingModal
            pricing={pricingData.N600Pricing}
            onModalClose={() => setSelectPlan(false)}
            onContinue={onPay}
          />
        )}
        <ReviewBtns
          onSave={isFormPaid ? onOpenDownload : onSelectPlan}
          isSaving={isPaying}
          formType={"N-600"}
          hasPaid={isFormPaid}
        />
        {/* Add other Review components specific to N600 here */}
        <ReviewEligibilityInfo
          title="1. Information About Your Eligibility"
          data={data?.infoAboutEligibility}
        />

        <ReviewInfoAboutYouPart1
          title="2.a. Information About You Part 1"
          data={data?.applicantInfoOne}
        />

        <ReviewInfoAboutYouPart2
          title="2.b. Information About You Part 2"
          data={data?.applicantInfoTwo}
        />

        <ReviewInfoAboutYouPart3
          title="2.c. Information About You Part 3"
          data={data?.applicantInfoThree}
        />

        <ReviewInfoAboutYouPart4
          title="2.d. Information About You Part 4"
          data={data?.applicantInfoFour}
        />

        <ReviewBioInfo
          title="3. Biographic Information"
          data={data?.applicantBioInfo}
        />
        {isFatherCitizen && (
          <>
            <ReviewUSFatherInfoPart1
              title="4.a. Information About Your U.S. Citizen Biological Father (or Adoptive Father) Part 1"
              data={data?.applicantFatherOne}
            />

            <ReviewUSFatherInfoPart2
              title="4.b. Information About Your U.S. Citizen Biological Father (or Adoptive Father) Part 2"
              data={data?.applicantFatherTwo}
            />
          </>
        )}

        {isMotherCitizen && (
          <>
            <ReviewUSMotherInfoPart1
              title="4.a Information About Your U.S. Citizen Biological Mother (or Adoptive Mother) Part 1"
              data={data?.applicantMotherOne}
            />

            <ReviewUSMotherInfoPart2
              title="4.b Information About Your U.S. Citizen Biological Mother (or Adoptive Mother) Part 2"
              data={data?.applicantMotherTwo}
            />
          </>
        )}

        {isBothParent && (
          <>
            <ReviewUSFatherInfoPart1
              title="4.a Information About Your U.S. Citizen Biological Father (or Adoptive Father) Part 1"
              data={data?.applicantFatherOne}
            />

            <ReviewUSFatherInfoPart2
              title="4.b Information About Your U.S. Citizen Biological Father (or Adoptive Father) Part 2"
              data={data?.applicantFatherTwo}
            />

            <ReviewUSMotherInfoPart1
              title="5.a Information About Your U.S. Citizen Biological Mother (or Adoptive Mother) Part 1"
              data={data?.applicantMotherOne}
            />

            <ReviewUSMotherInfoPart2
              title="5.b Information About Your U.S. Citizen Biological Mother (or Adoptive Mother) Part 2"
              data={data?.applicantMotherTwo}
            />
          </>
        )}

        <ReviewPhysicalPresence
          title={`${
            isFatherCitizen || isMotherCitizen ? "5." : "6."
          } Physical Presence in the United States From Birth Until Filing of Form N-600`}
          data={data?.applicantPhysicalPresence}
        />

        <ReviewParentMilitaryService
          title={`${
            isFatherCitizen || isMotherCitizen ? "6." : "7."
          } Information About Military Service of U. S. Citizen Parents`}
          data={data?.militaryService}
        />

        <ReviewPI
          title={`${
            isFatherCitizen || isMotherCitizen ? "7." : "8."
          } Preparer and Interpreter`}
          data={data?.preparerAndInterpreter}
          editUrl={FormN400Progress.PI}
        />

        {/*  */}
        <BtnFooterWrapper>
          <FormBtn
            isLoading={false}
            addBackBtn
            isError={false}
            addContinueBtn={false}
            removeHR
          />
          <ReviewBtns
            // if the user haven't paid, the form toggles the pricing modal passing in the N400Pricing data
            onSave={isFormPaid ? onOpenDownload : onSelectPlan}
            isSaving={isPaying}
            formType={"N-600"}
            hasPaid={isFormPaid}
          />
        </BtnFooterWrapper>
        <ReviewTop showTop={showTop} />
        {isSuccess && <N600PaySuccess formType={FormCategories.n600} />}
        {isFail && <N600PayFail onRetry={onSelectPlan} isRetrying={isPaying} />}
        {onDownload && (
          <DownloadForms
            onClose={onCloseDownload}
            caseType={onDownload.caseType}
            filingURL={onDownload.filingURL}
            formDownloadUrl={onDownload.formDownloadUrl}
            g1145URL={onDownload.g1145URL || ""}
          />
        )}
      </>
    </FormSectionWrapper>
  );
};

export default N600Review;
