import React, { FC, useEffect } from "react";
import styled from "styled-components";
import Colors from "../../../../styles/Colors";
import { FormN400Progress } from "./types";
import { useSearchParams } from "react-router-dom";
import { HR, HeadFlex } from "../../components/styles";
import { N400EligibilityPayload } from "../../../../api/n400/types";
import { useSnapshot } from "valtio";
import { formEligibilityStore } from "../../../../store/formEligibilityData";
import FormProgressBar from "../../components/FormProgressBar";
import ApplicantInfo1 from "./ApplicantInfo/ApplicantInfo1";
import ApplicantInfo2 from "./ApplicantInfo/ApplicantInfo2";
import BiographicInformation from "./BiographicInformation";
import AddressHistory from "./AddressHistory";
import MaritalHistory from "./MaritalHistory";
import EmploymentHistoryInfo from "./EmploymentHistoryInfo";
import TimeOutsideUS from "./TimeOutsideUS";
import CheckPart1 from "./AdditionalInfo/CheckPart1";
import CheckPart2 from "./AdditionalInfo/CheckPart2";
import CheckPart3 from "./AdditionalInfo/CheckPart3";
import CheckPart4 from "./AdditionalInfo/CheckPart4";
import ChildrenInfomation from "./ChildrenInfomation";
import FeeReduction from "./FeeReduction";
import N400Preparer from "./N400Preparer";
import N400Review from "./Review";

interface Props {
  title: string;
  subHead?: string;
  subText: string;
}

const RightSection: FC<Props> = ({ title, subHead, subText }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const getType = searchParams.get("type");
  const { formEligibility }: { formEligibility?: N400EligibilityPayload } =
    useSnapshot(formEligibilityStore);

  useEffect(() => {
    if (!getType) {
      searchParams.set("type", FormN400Progress.applicantInfo1);
      setSearchParams(searchParams);
    }
  }, [searchParams, getType, setSearchParams]);

  return (
    <Container>
      <HeadFlex>
        <Header>{title}</Header>
        <FormProgressBar section={getType || ""} enumVal={FormN400Progress} />
      </HeadFlex>
      <Line />
      {subHead && <SubHead>{subHead}</SubHead>}
      <Para>{subText}</Para>
      {FormN400Progress.applicantInfo1 === getType && formEligibility && (
        <ApplicantInfo1 formEligibility={formEligibility} />
      )}
      {FormN400Progress.applicantInfo2 === getType && formEligibility && (
        <ApplicantInfo2 />
      )}
      {FormN400Progress.bioInfo === getType && formEligibility && (
        <BiographicInformation />
      )}
      {FormN400Progress.addressHistory === getType && formEligibility && (
        <AddressHistory formEligibility={formEligibility} />
      )}
      {FormN400Progress.maritalHistory === getType && formEligibility && (
        <MaritalHistory formEligibility={formEligibility} />
      )}
      {FormN400Progress.childrenInfo === getType && <ChildrenInfomation />}

      {FormN400Progress.employmentAndSchoolHistory === getType &&
        formEligibility && (
          <EmploymentHistoryInfo formEligibility={formEligibility} />
        )}

      {FormN400Progress.timeoff === getType && formEligibility && (
        <TimeOutsideUS formEligibility={formEligibility} />
      )}

      {FormN400Progress.checkPart1 === getType && formEligibility && (
        <CheckPart1 formEligibility={formEligibility} />
      )}

      {FormN400Progress.checkPart2 === getType && formEligibility && (
        <CheckPart2 formEligibility={formEligibility} />
      )}

      {FormN400Progress.checkPart3 === getType && formEligibility && (
        <CheckPart3 formEligibility={formEligibility} />
      )}

      {FormN400Progress.checkPart4 === getType && formEligibility && (
        <CheckPart4 formEligibility={formEligibility} />
      )}

      {FormN400Progress.feeDeduct === getType && <FeeReduction />}
      {FormN400Progress.PI === getType && <N400Preparer />}
      {FormN400Progress.review === getType && <N400Review />}
      {/* {FormN400Progress.timeoff === getType && formEligibility && (
        <TIMEOFF formEligibility={formEligibility} />
      )}
      {FormN400Progress.applicantInfo === getType && formEligibility && (
        <AINFO formEligibility={formEligibility} />
      )}
      {FormN400Progress.accommodations === getType && <ACCOM />}
      {FormN400Progress.exemptionToTestRequirements === getType && <REQEXMP />}
      {FormN400Progress.exemptionFromEnglishTest === getType && <ENGTESTEXMP />}
      {FormN400Progress.additionalInfo === getType && <ADDINFO />}
      {FormN400Progress.contactInfo === getType && formEligibility && (
        <CBBINFO formEligibility={formEligibility} />
      )}
      {FormN400Progress.addressHistory === getType && formEligibility && (
        <ADDRHSTR formEligibility={formEligibility} />
      )}
      {FormN400Progress.employmentAndSchoolHistory === getType && <EMPHSTR />}
      {FormN400Progress.maritalHistory === getType && formEligibility && (
        <MARRHSTR formEligibility={formEligibility} />
      )}
      {FormN400Progress.childrenInfo === getType && <CHILDRNINFO />}
      {FormN400Progress.checkPart1 === getType && <CheckPart1 />}
      {FormN400Progress.checkPart2 === getType && <CheckPart2 />}
      {FormN400Progress.checkPart3 === getType && <CheckPart3 />}
      {FormN400Progress.checkPart4 === getType && <CheckPart4 />}
      {FormN400Progress.checkPart5 === getType && <CheckPart5 />}
      {FormN400Progress.checkPart6 === getType && <CheckPart6 />}
      {FormN400Progress.PI === getType && <N400Preparer />}
      {FormN400Progress.review === getType && <N400Review />} */}
    </Container>
  );
};

export default RightSection;

const Container = styled.div`
  width: 100%;
  padding: 20px 20px 47px 20px;
  background-color: ${Colors.WhiteF9};
  border-radius: 12px;

  input,
  textarea,
  .css-1qmnlw8-control {
    background-color: transparent;
  }
`;

const Line = styled(HR)`
  margin: 0;
  margin-bottom: 16px;
`;

const Header = styled.div`
  color: ${Colors.Blue00};
`;

const Para = styled.p`
  color: ${Colors.Black31};
  margin-bottom: 32px;
`;

const SubHead = styled.div`
  color: ${Colors.Black31};
  margin: 16px 0;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
`;
