import { FC } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import { QueriesKey } from "../type";
import { SingleOption } from "../../../../types/types";
import { CustomDropdown } from "../CustomDropdown";

interface Props {
  name: string;
  queryName: QueriesKey;
  option: SingleOption[];
  onParamsChange?: (passedParams: URLSearchParams) => void;
  width?: string;
}

const TableDropdown: FC<Props> = ({
  option,
  name,
  queryName,
  onParamsChange,
  width,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = searchParams.get(QueriesKey[queryName]);

  return (
    <Wrapper>
      <Text>{name}:</Text>
      <CustomDropdown
        option={option}
        value={queryParams || undefined}
        width={width}
        onChange={(value) => {
          const target = value.value;
          if (target === "") {
            searchParams.delete(QueriesKey[queryName]);
            setSearchParams(searchParams);
          } else {
            searchParams.set(QueriesKey[queryName], value.value);
            setSearchParams(searchParams);
          }
          onParamsChange && onParamsChange(searchParams);
        }}
      />
    </Wrapper>
  );
};

export default TableDropdown;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Text = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
`;
