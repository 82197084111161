import { FormCategories } from "../../../../routes/type";
import { ProfileSections, SettingsTabs } from "./types";
import * as Icons from "@heroicons/react/24/solid";
import { IoDocuments } from "react-icons/io5";

export const leftSideBarData = (isBusiness: boolean) => {
  const individualProfile = [
    {
      label: "Dashboard",
      url: `?active=${ProfileSections.dashboard}`,
      icon: <Icons.RectangleGroupIcon className="h-8" />,
    },
    {
      label: "Applications",
      url: `?active=${ProfileSections.applications}`,
      icon: <Icons.DocumentTextIcon className="h-8" />,
    },
    {
      label: "Manage Case",
      url: `?active=${ProfileSections.cases}`,
      icon: <Icons.BanknotesIcon className="h-8" />,
    },
    {
      label: "Upload Documents",
      url: `?active=${ProfileSections.documents}`,
      icon: <IoDocuments className="h-8" />,
    },
    {
      label: "Settings",
      url: `?active=${ProfileSections.settings}`,
      icon: <Icons.Cog6ToothIcon className="h-8" />,
    },
  ];

  const businessProfile = [
    {
      label: "Dashboard",
      url: `?active=${ProfileSections.dashboard}`,
      icon: <Icons.RectangleGroupIcon className="h-8" />,
    },
    {
      label: "Case Management",
      url: `?active=${ProfileSections.caseManagement}`,
      icon: <Icons.DocumentTextIcon className="h-8" />,
    },
    {
      label: "Users",
      url: `?active=${ProfileSections.users}`,
      icon: <Icons.UserGroupIcon className="h-8" />,
    },
    {
      label: "Staff Management",
      url: `?active=${ProfileSections.staffManagement}`,
      icon: <Icons.UserGroupIcon className="h-8" />,
    },
    {
      label: "Billing",
      url: `?active=${ProfileSections.billing}`,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z"
          />
        </svg>
      ),
    },
    {
      label: "Settings",
      url: `?active=${ProfileSections.settings}`,
      icon: <Icons.Cog6ToothIcon className="h-8" />,
    },
    // {
    //   label: "Logout",
    //   url: ``,
    //   icon: <HiOutlineLogout className="h-8" />,
    // },
  ];

  return isBusiness ? businessProfile : individualProfile;
};

export interface NavItemsProps {
  label: string;
  url?: string;
  children?: {
    name: JSX.Element;
    url: string;
  }[];
}

export const NavItems: NavItemsProps[] = [
  {
    label: "My Account",
    children: [
      {
        name: <div>Settings</div>,
        url: `?active=${ProfileSections.settings}`,
      },
      {
        name: <div>Sign Out</div>,
        url: "",
      },
    ],
  },
];

export const settingsTab = [
  {
    title: "Profile Settings",
    url: SettingsTabs.setting,
  },
  {
    title: "Password",
    url: SettingsTabs.password,
  },
  {
    title: "Referral",
    url: SettingsTabs.referral,
  },
];

export const newApplicationData = [
  // {
  //   value: FormCategories.n400,
  //   label: "N-400 Citizenship through Naturalization",
  // },
  // {
  //   value: FormCategories.n565,
  //   label: "N-565 Replacement of Citizenship Document",
  // },
  // { value: FormCategories.n600, label: "N-600 Certficate of Citizenship" },
  { value: FormCategories.ds160, label: "Student Visa (F1 & M1)" },
  { value: FormCategories.ds160, label: "Travel Visa (B1/B2)" },
  { value: FormCategories.ds160, label: "Dependent Visa (F2)" },
];
