import { motion } from "framer-motion";
import React, { FC, useRef, useState } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";
import styled from "styled-components";
import { items } from "./variants";
import Colors from "../../../../styles/Colors";

interface Props {
  label: string;
  value: string;
}

const FAQItems: FC<Props> = ({ value, label }) => {
  const contentEl = useRef<HTMLDivElement>(null);
  const [active, setActive] = useState(false);
  const toggle = () => setActive((prev) => !prev);

  return (
    <Container
      initial={items.closed}
      whileInView={items.open}
      viewport={{ once: true }}
    >
      <Wrapper>
        {label}
        <ArrowDown onClick={toggle}>
          {active ? <FiMinus /> : <FiPlus />}
        </ArrowDown>
      </Wrapper>
      <FullDetails ref={contentEl} contentEl={contentEl} isActive={active}>
        {value}
      </FullDetails>
    </Container>
  );
};

export default FAQItems;

const Container = styled(motion.div)`
  padding: 21px 24px;
  border-bottom: 1px solid ${Colors.DarkB3};
  border-radius: 4px;
  background: ${Colors.White};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ArrowDown = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

interface FullDetailsProps {
  isActive: boolean;
  contentEl: React.RefObject<HTMLDivElement>;
}

const FullDetails = styled.div<FullDetailsProps>`
  height: ${({ isActive, contentEl }) =>
    !isActive ? "0" : `${contentEl.current?.scrollHeight}px`};
  overflow: hidden;
  transition: height ease 0.3s;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #1b092c;
  margin-top: ${({ isActive }) => isActive && "24px"};
  opacity: ${({ isActive }) => !isActive && "0"};
`;
