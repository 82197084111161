import React, { useEffect } from "react";
import Hero from "./components/Hero";
import WhySeamless from "./components/WhySeamless";
import Mission from "./components/Mission";

const About = () => {
  useEffect(() => {
    document.title = `About Us | SeamlessVisa`;

    return () => {
      document.title =
        "SeamlessVisa | The Trusted Partner in Your Immigration Journey";
    };
  }, []);
  return (
    <div>
      <Hero />
      <WhySeamless />
      <Mission />
    </div>
  );
};

export default About;
