import { Outlet } from "react-router";
// import Header from "./components/Header";
import Footer from "./components/Footer";
import Navbar from "./components/NavBar";
import ChatSystem from "../landing page/components/ChatSystem";

const Layout = () => {
  return (
    <>
      {/* <Header /> */}
      <Navbar />
      {/* <div className="mt-24"> */}
      <Outlet />
      {/* </div> */}
      <ChatSystem />
      <Footer />
    </>
  );
};

export default Layout;
