import MiniLists from "../MiniLists";
import { DocChecklistProps } from "./types";

export enum n565DocChecklistNames {}

export const n565DocChecklist: DocChecklistProps[] = [
  {
    name: "n565_money_paid_to_DHS",
    label: `Check or money order made payable to "U.S. Department of Homeland Security" for the appropriate amount`,
    info: (
      <MiniLists>
        <li>USCIS fee is $555</li>
      </MiniLists>
    ),
    isNotFile: true,
  },
  {
    name: "n565_two_US_passport",
    label: "Two identical passport-style photographs ",
    info: "if you live outside the United States",
    optional: true,
  },
  {
    name: "n565_original_doc_or_cert",
    label: "Your original document or certificate",
    info: "if you are applying for a new or replacement document due to a name, date of birth, or gender change.",
    optional: true,
  },
  {
    name: "n565_police_report_or_sworn_statement",
    label: "A police report or a sworn statement",
    info: "if you are applying for replacement of a lost, stolen, or destroyed document.",
    optional: true,
  },
  {
    name: "n565_evidence_of_USCIS_error",
    label: "Evidence of a USCIS typographical or clerical error",
    optional: true,
  },
  {
    name: "n565_evidence_of_legal_name_change",
    label: "Evidence of your legal name change",
    optional: true,
  },
  {
    name: "n565_evidence_of_legal_dob",
    label: "Evidence of your legal date of birth change",
    optional: true,
  },
  {
    name: "n565_evidence_of_legal_gender_change",
    label: "Evidence of your legal gender change",
    optional: true,
  },
  {
    name: "n565_evidence_of_martial_status_change",
    label: "Evidence of your marital status change",
    optional: true,
  },
];
