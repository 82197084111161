import { FieldArray, Form, Formik } from "formik";
import styled from "styled-components";

// import { Dayjs } from "dayjs";
import { N600BiographicInfoPayload } from "../../../../api/n600/types";
import { Modify } from "../../../../types/types";
import { initInput } from "../../components/data";
import FormBtn from "../../components/FormBtn";
import FormSectionWrapper from "../../components/FormSectionWrapper";
import { FormWrapper, RadioLabel, RadioWrapper } from "../../components/styles";
import { Colored } from "../../form 400/components/styles";
import CheckBoxField from "../../components/formik fields/CheckBoxField";
import RadioComp from "../../components/RadioComp";
import {
  BioInfoEthnicity,
  BioInfoEyeColor,
  BioInfoHairColor,
} from "../../form 400/components/data";
import InputField from "../../components/formik fields/InputField";
import {
  useGetN600BiographicInfo,
  useSaveN600BiographicInfo,
} from "../../../../hooks/n600/useN600";
import useToNextSection from "../../../../hooks/useToNextSection";
import { BioInfoValidation } from "./validation";

const initialRacesArray = {
  white: false,
  black: false,
  indian: false,
  asian: false,
  islander: false,
};

export interface RacesPropsPayload {
  white: boolean;
  black: boolean;
  indian: boolean;
  asian: boolean;
  islander: boolean;
}

export interface BiographicInfoData
  extends Modify<
    Omit<N600BiographicInfoPayload, "races">,
    {
      racesArray: RacesPropsPayload[];
    }
  > {}

const BiographicInfo = () => {
  const { data, isFetching, isError } = useGetN600BiographicInfo();
  const { mutate, isLoading: isSaving } = useSaveN600BiographicInfo();
  const { toNextSection } = useToNextSection();

  const onSubmit = (values: BiographicInfoData) => {
    const { racesArray, ...rest } = values;

    const payload = {
      ...rest,
      races: racesArray,
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValues: BiographicInfoData = {
    ethnicity: initInput(data?.ethnicity), // Ethnicity (Select only one box)
    racesArray: data?.races ? data?.races : [initialRacesArray], // Race (Select all applicable boxes)
    heightFeet: initInput(data?.heightFeet), // Height Feet
    heightInches: initInput(data?.heightInches), // Height Inches
    weightPounds: initInput(data?.weightPounds), // Weight Pounds
    eyeColor: initInput(data?.eyeColor), // Eye color (Select only one box)
    hairColor: initInput(data?.hairColor), // Hair color (Select only one box)
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={BioInfoValidation}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <div>
                  <RadioComp
                    name="ethnicity"
                    title="Ethnicity"
                    coloredLabel="(Select only one box)"
                    options={BioInfoEthnicity}
                  />
                </div>
                <div></div>
              </FormWrapper>

              <RadioWrapper>
                <RadioLabel>
                  Races <Colored>(select all applicable boxes)</Colored>
                </RadioLabel>
                <FieldArray
                  name="racesArray"
                  render={(arrayHelpers) => (
                    <>
                      {formik.values.racesArray.map((_, index) => (
                        <CheckWrap key={index}>
                          <CheckBoxField
                            value={"American Indian or Alaska Native"}
                            name={`racesArray[${index}].indian`}
                            label={"American Indian or Alaska Native"}
                          />
                          <CheckBoxField
                            value={"Asian"}
                            name={`racesArray[${index}].asian`}
                            label={"Asian"}
                          />
                          <CheckBoxField
                            value={"Black or African American"}
                            name={`racesArray[${index}].black`}
                            label={"Black or African American"}
                          />

                          <CheckBoxField
                            value={"Native Hawaiian or Other Pacific Islander"}
                            name={`racesArray[${index}].islander`}
                            label={"Native Hawaiian or Other Pacific Islander"}
                          />
                          <CheckBoxField
                            value={"White"}
                            name={`racesArray[${index}].white`}
                            label={"White"}
                          />
                        </CheckWrap>
                      ))}
                    </>
                  )}
                />
              </RadioWrapper>

              <div className="my-8">
                <RadioLabel>Height</RadioLabel>
                <HeightFlex>
                  <InputField
                    label=" "
                    coloredLabel="Feet"
                    placeholder=""
                    name="heightFeet"
                  />
                  <InputField
                    label=" "
                    coloredLabel="Inches"
                    placeholder=""
                    name="heightInches"
                  />
                </HeightFlex>
              </div>

              <FormWrapper>
                <div>
                  <InputField
                    label="Weight:"
                    placeholder="lbs"
                    coloredLabel=" (lbs)"
                    name="weightPounds"
                  />
                </div>

                <div></div>
              </FormWrapper>

              <FormWrapper>
                <div>
                  <RadioComp
                    name="eyeColor"
                    title="Eye color"
                    coloredLabel="(Select only one box)"
                    options={BioInfoEyeColor}
                  />
                </div>
              </FormWrapper>

              <FormWrapper>
                <div>
                  <RadioComp
                    name="hairColor"
                    title="Hair color"
                    coloredLabel="(Select only one box)"
                    options={BioInfoHairColor}
                  />
                </div>
              </FormWrapper>

              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default BiographicInfo;

const Wrapper = styled.div``;

const CheckWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const HeightFlex = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;

  & > div {
    width: 150px;
  }
`;
