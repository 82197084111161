import { proxy } from "valtio";
import storage from "../utils/storage";

export const seamlessEligibilityData = "seamlessEligibilityData";
const storedEligibility = storage.get(seamlessEligibilityData) as
  | eligibilityStoreProps
  | undefined;
export interface eligibilityStoreProps {
  formType?: string;
  formData?: Record<string, string>;
}

export const eligibilityDataStore = proxy<{
  eligibilityData: eligibilityStoreProps;
}>({
  eligibilityData: {
    formType: storedEligibility?.formType,
    formData: storedEligibility?.formData,
  },
});
