import React from "react";
import styled from "styled-components";
import CongratsImage from "../../../../images/web/congrats.svg";
import { useSearchParams } from "react-router-dom";
import Colors from "../../../../styles/Colors";
import PayWrapper from "../../components/PayWrapper";
import { useGetDS160PaySuccess } from "../../../../hooks/ds160/usDS160";
import { BusinessQueryKey } from "../../../../api/utils/types";

const Ds160PayModal = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const payId = searchParams.get("payId") as string;
  const getApplicationId = searchParams.get(BusinessQueryKey.appId) || "";
  const { isLoading, isError } = useGetDS160PaySuccess(payId, getApplicationId);

  const removeAllSearchParams = () => {
    const payParam = searchParams.get("pay");
    const payIdParam = searchParams.get("payId");

    if (payParam) {
      searchParams.delete("pay");
    }

    if (payIdParam) {
      searchParams.delete("payId");
    }
    setSearchParams(searchParams);
  };

  const onModalClose = () => {
    removeAllSearchParams();
  };

  return (
    <PayWrapper
      onModalClose={onModalClose}
      isError={isError}
      isLoading={isLoading}
    >
      <>
        <MainIcon>
          <img src={CongratsImage} alt="" />
        </MainIcon>
        <ContentWrapper>
          <TextBold>
            🎉 Your Payment has been processed successfully! 🎉
          </TextBold>

          <Text>
            Your transaction was successful, and your payment has been
            processed.
          </Text>

          <CTA onClick={onModalClose}>continue</CTA>
        </ContentWrapper>
      </>
    </PayWrapper>
  );
};

export default Ds160PayModal;

const MainIcon = styled.div`
  width: 108px;
  height: 108px;
  margin-bottom: 16px;

  & > svg,
  & > img {
    width: 100%;
    height: 100%;
    fill: ${Colors.RedC3};
  }
`;

const ContentWrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Text = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
`;

const TextBold = styled(Text)`
  font-weight: bold;
`;

const Btn = styled.button`
  width: max-content;
  height: 46px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 24px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
  white-space: nowrap;
  text-transform: uppercase;
  margin: auto;

  & > svg {
    width: 20px;
    height: 20px;
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const CTA = styled(Btn)`
  background: ${Colors.Blue00};
  color: ${Colors.WhiteF9};
  padding: 18.5px 47px;

  &:hover:not(:disabled) {
    background: #022e58;
    color: ${Colors.WhiteF9};
    transition: 0.3s ease-in-out;
  }
`;
