import React from "react";
import styled from "styled-components";
import { FieldArray, Form, Formik } from "formik";
import { Colored } from "./styles";
import { Modify } from "../../../../types/types";
import { N400BioInfoObj, RacesPropsPayload } from "../../../../api/n400/types";
import FormBtn from "../../components/FormBtn";
import { BioInfoValidation } from "./validation";
import CheckBoxField from "../../components/formik fields/CheckBoxField";
import useToNextSection from "../../../../hooks/useToNextSection";
import InputField from "../../components/formik fields/InputField";
import { RadioLabel, RadioWrapper } from "../../components/styles";
import FormSectionWrapper from "../../components/FormSectionWrapper";
import RadioComp from "../../components/RadioComp";
import { BioInfoEthnicity, BioInfoEyeColor, BioInfoHairColor } from "./data";
import { HalfFormWrapper } from "../../form ds 160/components/styles";
import {
  useGetN400BioInfo,
  useSaveN400BioInfo,
} from "../../../../hooks/n400/useN400";

const initialRacesArray = {
  white: false,
  black: false,
  indian: false,
  asian: false,
  islander: false,
};
export interface ContactInfoData
  extends Modify<
    Omit<N400BioInfoObj, "races">,
    {
      racesArray: RacesPropsPayload[];
    }
  > {}

const BiographicInformation = () => {
  const { toNextSection } = useToNextSection();
  const { data, isFetching, isError } = useGetN400BioInfo();
  const { mutate, isLoading: isSaving } = useSaveN400BioInfo();

  const onSubmit = (values: ContactInfoData) => {
    const { racesArray, ...rest } = values;

    const payload = {
      ...rest,
      races: racesArray,
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValue: ContactInfoData = {
    ethnicity: data?.ethnicity || "",
    racesArray: data?.races ? data?.races : [initialRacesArray],
    heightFeet: data?.heightFeet || "",
    heightInches: data?.heightInches || "",
    weight: data?.weight || "",
    eyeColor: data?.eyeColor || "",
    hairColor: data?.hairColor || "",
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValue}
          onSubmit={onSubmit}
          validationSchema={BioInfoValidation}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <HalfFormWrapper>
                <RadioComp
                  name="ethnicity"
                  title="Ethnicity"
                  options={BioInfoEthnicity}
                />

                <RadioWrapper>
                  <RadioLabel>
                    Races <Colored>(select all applicable boxes)</Colored>
                  </RadioLabel>
                  <FieldArray
                    name="racesArray"
                    render={(arrayHelpers) => (
                      <>
                        {formik.values.racesArray.map((_, index) => (
                          <CheckWrap key={index}>
                            <CheckBoxField
                              value={"American Indian or Alaska Native"}
                              name={`racesArray[${index}].indian`}
                              label={"American Indian or Alaska Native"}
                            />
                            <CheckBoxField
                              value={"Asian"}
                              name={`racesArray[${index}].asian`}
                              label={"Asian"}
                            />
                            <CheckBoxField
                              value={"Black or African American"}
                              name={`racesArray[${index}].black`}
                              label={"Black or African American"}
                            />

                            <CheckBoxField
                              value={
                                "Native Hawaiian or Other Pacific Islander"
                              }
                              name={`racesArray[${index}].islander`}
                              label={
                                "Native Hawaiian or Other Pacific Islander"
                              }
                            />
                            <CheckBoxField
                              value={"White"}
                              name={`racesArray[${index}].white`}
                              label={"White"}
                            />
                          </CheckWrap>
                        ))}
                      </>
                    )}
                  />
                </RadioWrapper>

                <div>
                  <RadioLabel>Height</RadioLabel>
                  <HeightFlex>
                    <InputField
                      label=" "
                      coloredLabel="Feet"
                      placeholder=""
                      name="heightFeet"
                      numberInput="number"
                      min={0}
                    />
                    <InputField
                      label=" "
                      coloredLabel="Inches"
                      placeholder=""
                      name="heightInches"
                      numberInput="number"
                      min={0}
                    />
                  </HeightFlex>
                </div>

                <InputField
                  label="Weight:"
                  placeholder="lbs"
                  coloredLabel=" (lbs)"
                  name="weight"
                />

                <RadioComp
                  name="eyeColor"
                  title="Eye color"
                  coloredLabel="(Select only one box)"
                  options={BioInfoEyeColor}
                />

                <RadioComp
                  name="hairColor"
                  title="Hair color"
                  coloredLabel="(Select only one box)"
                  options={BioInfoHairColor}
                />
              </HalfFormWrapper>

              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default BiographicInformation;

const Wrapper = styled.div``;

const HeightFlex = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;

  & > div {
    width: 150px;
  }
`;

const CheckWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
