import { utilsQueryKeys } from "./types";
import {
  deleteAccount,
  deleteForm,
  getCountries,
  getStates,
  getUserData,
} from "../../api/utils";
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { parseError } from "../../utils";
import { useCallback, useState } from "react";
import {
  deleteInterviewHistory,
  getChatGptInterviewHistory,
  getChatGptInterviewVoiceHistory,
} from "../../api/ai";

export const useGetCountries = () => {
  return useQuery([utilsQueryKeys.getCountries], getCountries, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useGetStates = (country?: string) => {
  return useQuery(
    [utilsQueryKeys.getStates, country],
    () => getStates(country),
    {
      enabled: !!country,
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetMultiStates = (country?: string) => {
  const data = useQueries({
    queries: (country || "").split(",").map((ev) => {
      return {
        enabled: !!ev,
        queryKey: [utilsQueryKeys.getStates, ev],
        queryFn: () => getStates(ev),
        refetchOnWindowFocus: false,
      };
    }),
  });

  return data.map((ev) => ev.data?.data.data);
};

export const useGetUserData = (isEnabled?: boolean) => {
  return useQuery([utilsQueryKeys.getUserData], getUserData, {
    enabled: isEnabled,
    onError: (error) => parseError(error),
    select: (data) => data.data.userData,
    refetchOnWindowFocus: false,
  });
};

export const useDeleteForm = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteForm, {
    onSuccess: () =>
      queryClient.invalidateQueries([utilsQueryKeys.getUserData]),
  });
};

export const useDeleteAccount = () => {
  return useMutation(deleteAccount);
};

export const useCopyToClipboard = (): [
  boolean,
  (str: string) => Promise<void>
] => {
  const [copied, setCopied] = useState(false);
  const copyToClipboard = useCallback(async (str: string) => {
    try {
      await navigator.clipboard.writeText(str);
      setCopied(true);
    } catch (error) {
      console.error("Error copying text:", error);
    } finally {
      // Improved reset logic for better user experience
      setTimeout(() => setCopied(false), 500); // Reset immediately after copy attempt (success or failure)
    }
  }, []); // Empty dependency array for efficient useCallback usage
  return [copied, copyToClipboard];
};

// export const useGetInterviewHistory = () => {
//   return useQuery([utilsQueryKeys.getInterviewHistory], getInterviewHistory, {
//     onError: (error) => parseError(error),
//     select: (data) => data.data.result,
//     refetchOnWindowFocus: false,
//   });
// };

export const useGetChatGptInterviewHistory = () => {
  return useQuery(
    [utilsQueryKeys.getChatGptInterviewHistory],
    getChatGptInterviewHistory,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.result,
      refetchOnWindowFocus: false,
    }
  );
};

// export const useGetInterviewVoiceHistory = () => {
//   return useQuery(
//     [utilsQueryKeys.getInterviewVoiceHistory],
//     getInterviewVoiceHistory,
//     {
//       onError: (error) => parseError(error),
//       select: (data) => data.data.result,
//       refetchOnWindowFocus: false,
//     }
//   );
// };

export const useGetChatGptInterviewVoiceHistory = () => {
  return useQuery(
    [utilsQueryKeys.getChatGptInterviewVoiceHistory],
    getChatGptInterviewVoiceHistory,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.result,
      refetchOnWindowFocus: false,
    }
  );
};

export const useDeleteInterviewHistory = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteInterviewHistory, {
    onSuccess: () => {
      // queryClient.invalidateQueries([utilsQueryKeys.getInterviewHistory]);
      // queryClient.invalidateQueries([utilsQueryKeys.getInterviewVoiceHistory]);
      queryClient.invalidateQueries([
        utilsQueryKeys.getChatGptInterviewHistory,
      ]);
      queryClient.invalidateQueries([
        utilsQueryKeys.getChatGptInterviewVoiceHistory,
      ]);
    },
  });
};
