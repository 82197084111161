import React, { FC } from "react";
import { N565TypeOfApplicationDataValue } from "../data";
import { FormWrapper } from "../../../components/styles";
import InputField from "../../../components/formik fields/InputField";
import { Title, TypeOfApplicationFormData } from "../TypeOfApplication";
import SelectField from "../../../components/formik fields/SelectField";
import { ApartmentSuiteFloorData, isZipCode } from "../../../components/data";
import { SingleOption } from "../../../../../types/types";
import Colors from "../../../../../styles/Colors";
import styled from "styled-components";
import { FormikProps } from "formik";

interface Props {
  formik: FormikProps<TypeOfApplicationFormData>;
  formattedCountryData: SingleOption[];
  countryLoading: boolean;
  stateLoading: boolean;
  formattedStateData: SingleOption[];
  setCountry: React.Dispatch<React.SetStateAction<string>>;
  selectedCountry: string;
}

const Part8: FC<Props> = ({
  formik,
  formattedCountryData,
  formattedStateData,
  countryLoading,
  stateLoading,
  setCountry,
  selectedCountry,
}) => {
  return (
    <div>
      {formik.values.typeOfApplication ===
        N565TypeOfApplicationDataValue.recognition && (
        <div>
          <Title>
            Complete If Applying for a Special Certificate of Recognition as a
            Citizen of the United States to the Government of a Foreign Country
          </Title>

          <FormWrapper>
            <SelectField
              name="nameOfForeignCountry"
              label="Name of Foreign Country"
              placeholder="-Select-"
              options={formattedCountryData}
              isLoading={countryLoading}
            />

            <div></div>
          </FormWrapper>

          <Title>Information About Foreign Official</Title>
          <Sub className="mt-0 ">
            Provide the following information about the official of a foreign
            country who has requested this certificate (if known).
          </Sub>

          <FormWrapper>
            <InputField
              label="Family Name"
              coloredLabel="(Last Name)"
              placeholder=""
              name="foreignLastName"
            />
            <InputField
              label="Given Name"
              coloredLabel="(First Name)"
              placeholder=""
              name="foreignFirstName"
            />
          </FormWrapper>

          <FormWrapper>
            <InputField
              label="Middle Name(s)"
              coloredLabel="(optional)"
              placeholder=""
              name="foreignMiddleName"
            />
            <div></div>
          </FormWrapper>

          <FormWrapper>
            <InputField
              name="officialTitle"
              label="Official Title"
              placeholder=""
            />
            <InputField
              name="nameOfGovernmentAgency"
              label="Name of Government Agency"
              placeholder=""
            />
          </FormWrapper>

          <Title className="mb-6">Foreign Official's Address</Title>

          <FormWrapper>
            <InputField
              label="Street number and name"
              placeholder=""
              name="foreignStreetNumberAndName"
            />

            <InputField
              label="City or Town"
              placeholder=""
              name="foreignCityOrTown"
            />
          </FormWrapper>

          <FormWrapper>
            <SelectField
              name="foreignApartmentSuiteOrFloor"
              label="In there an apartment, suite or floor"
              placeholder="-Select-"
              options={ApartmentSuiteFloorData}
            />

            <div>
              {formik.values.foreignApartmentSuiteOrFloor?.value && (
                <InputField
                  label={`${formik.values.foreignApartmentSuiteOrFloor?.value} Address number`}
                  placeholder=""
                  name={`foreignAddressNumber`}
                />
              )}
            </div>
          </FormWrapper>

          <FormWrapper>
            <SelectField
              name="foreignCountry"
              label="Country"
              placeholder="-Select-"
              options={formattedCountryData}
              isLoading={countryLoading}
              onChange={(value) => {
                setCountry(value.label);
                formik.setFieldValue("stateOrTerritory", null);
              }}
            />

            <SelectField
              name="foreignStateOrTerritory"
              label="State or Territory"
              placeholder="-Select-"
              options={formattedStateData}
              isLoading={stateLoading}
              isDisabled={!selectedCountry}
            />
          </FormWrapper>

          <FormWrapper>
            <InputField
              label={`${
                isZipCode(formik.values.foreignCountry?.value)
                  ? "Zip"
                  : "Postal"
              } Code`}
              placeholder=""
              name={`foreignAddressCode`}
            />
            <InputField
              label="Province"
              coloredLabel="(If any)"
              placeholder=""
              name={`foreignProvince`}
            />
          </FormWrapper>
        </div>
      )}
    </div>
  );
};

export default Part8;

const Sub = styled.div`
  color: ${Colors.Black31};
  margin-top: 0;
  margin-bottom: 30px;
  font-weight: 300;
  font-size: 0.875rem;
`;
