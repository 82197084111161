import React, { FC } from "react";
import SelectField from "../../components/formik fields/SelectField";
import { RenderAltCompProps } from "./types";
import { SingleOption } from "../../../../types/types";

interface Props extends RenderAltCompProps {
  formattedData: SingleOption<string, string>[];
  isLoading: boolean;
}

const EligibilitySelectField: FC<Props> = ({
  formData,
  onChange,
  result,
  formattedData,
  isLoading,
}) => {
  const title = result.getTitle ? result.getTitle(formData) : result.title;

  const getSelectedValue = () => {
    if (!formData) return;
    const value = formData[result.storeField];

    return formattedData.filter((ev) => ev.value === value)[0];
  };

  const selectedValue = getSelectedValue();

  return (
    <SelectField
      name={title}
      label=""
      value={selectedValue}
      options={formattedData}
      isLoading={isLoading}
      placeholder={`Select ${title}...`}
      noBorder
      bgColor="transparent"
      onChange={(value) => {
        onChange({
          selectedValue: value.value,
          nextStep: result.singleOption?.nextStep,
          dynamicNextStep: result.singleOption?.dynamicNextStep,
          terminate: result.singleOption?.terminate,
        });
      }}
    />
  );
};

export default EligibilitySelectField;
