import React, { FC } from "react";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { N600InfoAboutYouPart2Payload } from "../../../../../../api/n600/types";
import { FormN600Progress } from "../../types";

interface Props {
  title: string;
  data?: N600InfoAboutYouPart2Payload;
}

const ReviewInfoAboutYouPart2: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      label: "In Care Of Name (if any)",
      value: data?.mailingInCareOfName,
    },
    {
      label: "Street Number and Name",
      value: data?.mailingStreetNumberName,
    },
    {
      label: "Apt. Ste. Flr.",
      value: data?.mailingAptSteFlr,
    },
    {
      label: "Apt. Ste. Flr. Number",
      value: data?.mailingAptSteFlrNumber,
    },
    {
      label: "City or Town",
      value: data?.mailingCityOrTown,
    },
    {
      label: "State",
      value: data?.mailingState,
    },
    {
      label: "ZIP Code",
      value: data?.mailingZipCode,
    },
    {
      label: "Province (foreign address only)",
      value: data?.mailingProvince,
    },
    {
      label: "Postal Code (foreign address only)",
      value: data?.mailingPostalCode,
    },
    {
      label: "Country (foreign address only)",
      value: data?.mailingCountry,
    },
    {
      label: "Street Number and Name (Physical Address)",
      value: data?.physicalStreetNumberName,
    },
    {
      label: "Apt. Ste. Flr. (Physical Address)",
      value: data?.physicalAptSteFlr,
    },
    {
      label: "Apt. Ste. Flr. Number (Physical Address)",
      value: data?.physicalAptSteFlrNumber,
    },
    {
      label: "City or Town (Physical Address)",
      value: data?.physicalCityOrTown,
    },
    {
      label: "State (Physical Address)",
      value: data?.physicalState,
    },
    {
      label: "ZIP Code (Physical Address)",
      value: data?.physicalZipCode,
    },
    {
      label: "Province (foreign address only) (Physical Address)",
      value: data?.physicalProvince,
    },
    {
      label: "Postal Code (foreign address only) (Physical Address)",
      value: data?.physicalPostalCode,
    },
    {
      label: "Country (foreign address only) (Physical Address)",
      value: data?.physicalCountry,
    },
    {
      label: "Marital Status",
      value: data?.maritalStatus,
    },
    {
      label: "Explain when marital status is other",
      value: data?.maritalStatusExp,
    },
    {
      label: "U.S. Armed Forces",
      value: data?.usArmedForces,
    },
    {
      label: "Applicant's Daytime Telephone Number",
      value: data?.applicantsDaytimeTelephoneNumber,
    },
    {
      label: "Applicant's Mobile Telephone Number (if any)",
      value: data?.applicantsMobileTelephoneNumber,
    },
    {
      label: "Applicant's Email Address (if any)",
      value: data?.applicantsEmailAddress,
    },
  ];

  return (
    <ReviewBox
      title={title}
      data={section}
      editUrl={FormN600Progress.infoAboutYouP2}
    />
  );
};

export default ReviewInfoAboutYouPart2;
