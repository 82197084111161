import { N600EligibilityPayload } from "../../../../api/n600/types";
import { FormCategories } from "../../../../routes/type";
import { ECongratulationType } from "./modals/EligibilityModal";
import { checkQuestionsProps } from "./types";

export enum EQIds {
  q1 = 1,
  q2,
  q3,
  q4,
  q5,
  q6,
  q7,
  q8,
  q9,
}

export const checkFormN600Questions: checkQuestionsProps<
  keyof N600EligibilityPayload
>[] = [
  {
    id: EQIds.q1,
    title: "Which best describes your situation?",
    storeField: "which_best_describes_your_situation",
    options: [
      {
        label:
          "I need proof of U.S. Citizenship and want to apply for a Certificate of Citizenship for the first time.",
        nextStep: EQIds.q2,
      },
      {
        label:
          "I want to replace my lost, stolen, mutilated, or incorrect Naturalization or Citizenship Certificate.",
        terminate: {
          formToRetake: FormCategories.n565,
          type: ECongratulationType.notEligible,
        },
      },
      {
        label: "I am not a U.S. Citizen and want to apply for Citizenship.",
        terminate: {
          formToRetake: FormCategories.n400,
          type: ECongratulationType.notEligible,
        },
      },
    ],
  },
  {
    id: EQIds.q2,
    title: "Who is seeking Proof of Citizenship?",
    storeField: "who_is_seeking_proof_of_citizenship",
    options: [
      {
        label: "I want proof of citizenship for myself.",
        nextStep: EQIds.q3,
      },
      {
        label:
          "I want proof of citizenship for my biological child who is under 18.", //needs design update
      },
      {
        label:
          "I want proof of citizenship for my adopted child who is under 18.", //needs design update
      },
    ],
  },
  {
    id: EQIds.q3,
    title: "Were you born outside the United States?",
    storeField: "were_you_born_outside_the_us",
    options: [
      {
        label: "Yes",
        nextStep: EQIds.q4,
      },
      {
        label: "No",
        terminate: {
          type: ECongratulationType.reStart,
        },
      },
    ],
  },
  {
    id: EQIds.q4,
    title: "Do you currently live in the U.S.?",
    storeField: "do_you_currently_live_in_the_us",
    options: [
      {
        label: "Yes",
        nextStep: EQIds.q5,
      },
      {
        label: "No",
        terminate: {
          type: ECongratulationType.reStart,
        },
      },
    ],
  },
  {
    id: EQIds.q5,
    title: "Have you ever had a U.S. passport?",
    storeField: "have_you_ever_had_a_us_passport",
    options: [
      {
        label: "Yes",
        nextStep: EQIds.q6,
      },
      {
        label: "No",
        nextStep: EQIds.q6,
      },
    ],
  },
  {
    id: EQIds.q6,
    title: "Have you ever been issued a Certificate of U.S. Citizenship?",
    storeField:
      "have_you_been_ever_been_issued_a_certificate_of_us_citizenship",
    options: [
      {
        label: "Yes",
        terminate: {
          type: ECongratulationType.notEligible,
          formToRetake: FormCategories.n565,
        },
      },
      {
        label: "No",
        nextStep: EQIds.q7,
      },
    ],
  },
  {
    id: EQIds.q7,
    title: "Were you born to at least one U.S. citizen parent?",
    storeField: "were_you_born_to_at_least_one_us_citizen_parent",
    options: [
      {
        label: "Yes",
        nextStep: EQIds.q8,
      },
      {
        label: "No",
        terminate: {
          type: ECongratulationType.reStart,
        },
      },
    ],
  },
  {
    id: EQIds.q8,
    title: "Which parent is a U.S. citizen?",
    storeField: "which_parent_is_a_us_citizen",
    options: [
      {
        label: "My mother is a U.S citizen",
        nextStep: EQIds.q9,
      },
      {
        label: "My father is a U.S citizen",
        nextStep: EQIds.q9,
      },
      {
        label: "Both of my parents are U.S citizens",
        nextStep: EQIds.q9,
      },
    ],
  },

  {
    id: EQIds.q9,
    title: "How did your parent(s) become a U.S. citizen?",
    storeField: "how_did_your_parents_become_us_citizen",
    options: [
      {
        label: "Birth in the United States",
        terminate: {
          type: ECongratulationType.eligible,
        },
      },
      {
        label: "Birth abroad to U.S. citizen parents",
        terminate: {
          type: ECongratulationType.eligible,
        },
      },
      {
        label: "Through parents that naturalized as U.S. citizens",
        terminate: {
          type: ECongratulationType.eligible,
        },
      },
      {
        label: "Through naturalization",
        terminate: {
          type: ECongratulationType.eligible,
        },
      },
    ],
  },
];
