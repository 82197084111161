import styled from "styled-components";
import Applications from "./applications";
import Case from "./case";

const Dashboard = () => {
  return (
    <Wrapper>
      <Applications />
      <Case />
    </Wrapper>
  );
};

export default Dashboard;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  height: 100%;
`;
