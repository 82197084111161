// import { GenericYesNoOptionsValue } from "../../components/data";
// import { SchemaObject } from "../../components/type";
// import { USCitizenFatherP1FormData } from "./US Citizen Father/Part1";
import * as Yup from "yup";
// import { FatherCitizenByDataValues } from "./data";
import { N600EligibilityInfoPayload } from "../../../../api/n600/types";
import { digitsOnly, optionalDigitsOnly } from "../../../../utils";
import {
  ApartmentSuiteFloorValues,
  GenericYesNoOptionsValue,
  ImmigrationStatusValues,
  MaritalStatusValues,
  isZipCode,
} from "../../components/data";
import { SchemaObject } from "../../components/type";
import { N600InfoAboutYouPart1Data } from "./ApplicantInfo/InfoAboutYou1";
import { N600InfoAboutYouPart2Data } from "./ApplicantInfo/InfoAboutYou2";
import { N600InfoAboutYouPart3Data } from "./ApplicantInfo/InfoAboutYou3";
import { N600InfoAboutYouPart4Data } from "./ApplicantInfo/InfoAboutYou4";
import { BiographicInfoData } from "./BioInfo";
import { USCitizenFatherP1FormData } from "./USFatherInfo/Part1";
import { FatherCitizenByDataValues } from "./data";
import { USCitizenFatherP2FormData } from "./USFatherInfo/Part2";
import { USCitizenMotherP1FormData } from "./USMotherInfo/Part1";
import { USCitizenMotherP2FormData } from "./USMotherInfo/Part2";
import { N600PresenceInUSData } from "./PhysicalPresenceInUS";
import { N600MilitaryServiceData } from "./MilitaryInfo";

// UTILITIES VALIDATIONS FUNCTIONS

// STRING VALIDATIONS
// IS-REQUIRED: determines if the string is required
// LEN: Sets a max length for the string
// export const validateString = (
//   isRequired?: "required" | "not-required",
//   len?: number
// ) => {
//   if (isRequired === "not-required" && len)
//     return Yup.string().max(len, `Maximum of ${len} characters allowed`).trim();
//   else if (isRequired === "required" && len)
//     return Yup.string()
//       .max(len, `Maximum of ${len} characters allowed`)
//       .required("Required")
//       .trim();
//   else if (isRequired === "required")
//     return Yup.string().required("Required").trim();
//   else return Yup.string().trim();
// };

export const validateString = (
  isRequired?: "required" | "not-required",
  len?: number
) => {
  let schema = Yup.string();

  if (len) {
    schema = schema.max(len, `Maximum of ${len} characters allowed`);
  }

  if (isRequired === "required") {
    schema = schema.required("Required");
  }

  return schema.trim();
};

// NUMBER VALIDATIONS
// IS-REQUIRED: determines if the string is required
// LEN: Sets a max length for the string
export const validateNumber = (isRequired?: "required", length?: number) => {
  if (isRequired && length)
    return Yup.string()
      .test("Digits only", "Must be a number", digitsOnly)
      .max(length, `Maximum of ${length} allowed`)
      .required("Required")
      .trim();
  else if (isRequired)
    return Yup.string()
      .test("Digits only", "Must be a number", digitsOnly)
      .required("Required")
      .trim();
  else
    return Yup.string()
      .test("Digits only", "Must be a number", digitsOnly)
      .trim();
};

// NUMBER VALIDATIONS
// IS-REQUIRED: determines if the string is required
// LEN: Sets a max length for the string
export const validateANumber = (isRequired?: "required") => {
  if (isRequired)
    return Yup.string()
      .test("Digits only", "Must be a number", digitsOnly)
      .min(9, `Minimum of 9 allowed`)
      .max(9, `Maximum of 9 allowed`)
      .required("Required")
      .trim();
  else
    return Yup.string()
      .min(9, `Minimum of 9 allowed`)
      .max(9, `Maximum of 9 allowed`)
      .test("Digits only", "Must be a number", digitsOnly)
      .trim();
};
// USCIS NUMBER FIELD VALIDATION
// IS-REQUIRED: States wether field is required or not
export const validateUSCISNumber = (isRequired?: "isRequired") => {
  if (isRequired)
    return Yup.string()
      .label("USCIS Online Account Number")
      .length(12)
      .test("Digits only", "Must be a number", optionalDigitsOnly)
      .required("Required");
  return Yup.string()
    .label("USCIS Online Account Number")
    .length(12)
    .test("Digits only", "Must be a number", optionalDigitsOnly);
};

// SSN NUMBER FIELD VALIDATION
// IS-REQUIRED: States wether field is required or not
export const validateSSNNumber = (isRequired?: "isRequired") => {
  if (isRequired)
    return Yup.string()
      .label("Social Security number")
      .length(9)
      .test("Digits only", "Must be a number", optionalDigitsOnly)
      .required("Required");
  return Yup.string()
    .label("Social Security number")
    .length(9)
    .test("Digits only", "Must be a number", optionalDigitsOnly);
};

// SELECT FIELD VALIDATION
// IS-REQUIRED: States wether field is required or not
export const validateSelect = (isRequired?: "required") => {
  if (isRequired) return Yup.object().nullable().required("Required");
  return Yup.object().nullable();
};

// SELECT FIELD VALIDATION
// IS-REQUIRED: States wether field is required or not
export const validateDate = (isRequired?: "required") => {
  if (isRequired) return Yup.string().nullable().required("Required");
  return Yup.string().nullable();
};

// ONLY-WHEN: A UTILITY TO VALIDATE A FIELD BASED ON ANOTHER FIELD'S VALUE
// FIELD: the field that the condition is based on
// VALUE: the value which the validation is required ("yes" | "no" makes the field using this function required based on the passed "field")

// interface SelectType {
//   label: string;
//   value: string;
// }

export const onlyWhen = (
  field: string,
  value: string | boolean,
  basedOn: "isSelect" | "isString" | "isCheck",
  toValidate:
    | "isDate"
    | "isNumber"
    | "isString"
    | "isSelect"
    | "isANumber"
    | "isZipCode"
    | "isStreetName"
    | "isAptNumber"
    | "isCityOrTown"
    | "isAddressCode",
  addressCodeDepends?: string
) => {
  // if the validation is based on a select field's value,
  // and field to be validated is supposed to be numbers only
  if (basedOn === "isSelect" && toValidate === "isNumber")
    return Yup.string().when(`${field}`, ([field], schema) => {
      if (field?.value === value) return validateNumber("required");
      return schema;
    });
  // if the validation is based on a select field's value,
  // and field to be validated is supposed to be a aNumber
  else if (basedOn === "isSelect" && toValidate === "isANumber")
    return Yup.string().when(`${field}`, ([field], schema) => {
      if (field?.value === value) return validateANumber("required");
      return schema;
    });
  // if the validation is based on a select field's value,
  // and field to be validated is supposed to be a zip-code
  else if (basedOn === "isSelect" && toValidate === "isZipCode")
    return Yup.string().when(`${field}`, ([field], schema) => {
      if (field?.value === value) return validateNumber("required", 5);
      return schema;
    });
  // if the validation is based on a select field's value,
  // and field to be validated is supposed to be a zip-code
  else if (basedOn === "isSelect" && toValidate === "isAptNumber")
    return Yup.string().when(`${field}`, ([field], schema) => {
      if (field?.value === value) return validateString("required", 4);
      return schema;
    });
  // if the validation is based on a select field's value,
  // and field to be validated is supposed to be a "street name and number"
  else if (basedOn === "isSelect" && toValidate === "isStreetName")
    return Yup.string().when(`${field}`, ([field], schema) => {
      if (field?.value === value) return validateString("required", 34);
      return schema;
    });
  // if the validation is based on a select field's value,
  // and field to be validated is supposed to be date
  else if (basedOn === "isSelect" && toValidate === "isDate")
    return Yup.string()
      .nullable()
      .when(`${field}`, ([field], schema) => {
        if (field?.value === value) return validateDate("required");
        return schema;
      });
  // if the validation is based on a select field's value,
  // and field to be validated is supposed to be a select field
  else if (basedOn === "isSelect" && toValidate === "isSelect")
    return Yup.object()
      .nullable()
      .when(`${field}`, ([field], schema) => {
        if (field?.value === value) return validateSelect("required");
        return schema;
      });
  // if the validation is based on a select field's value,
  // and field to be validated is supposed to be a string
  else if (basedOn === "isSelect" && toValidate === "isString")
    return Yup.string().when(`${field}`, ([field], schema) => {
      if (field?.value === value) return validateString("required");
      return schema;
    });
  // if the validation is based on a string or a yes/no field,
  // and field to be validated is supposed to be a number only
  else if (basedOn === "isString" && toValidate === "isNumber")
    return Yup.string().when(`${field}`, ([field], schema) => {
      if (field === value) return validateNumber("required");
      return schema;
    });
  // if the validation is based on a string or a yes/no field,
  // and field to be validated is supposed to be a aNumber
  else if (basedOn === "isString" && toValidate === "isANumber")
    return Yup.string().when(`${field}`, ([field], schema) => {
      if (field === value) return validateANumber("required");
      return schema;
    });
  // if the validation is based on a string or a yes/no field,
  // and field to be validated is supposed to be a zip-code
  else if (basedOn === "isString" && toValidate === "isZipCode")
    return Yup.string().when(`${field}`, ([field], schema) => {
      if (field === value) return validateNumber("required", 5);
      return schema;
    });
  // if the validation is based on a string or a yes/no field,
  // and field to be validated is supposed to be a zip-code
  else if (basedOn === "isString" && toValidate === "isStreetName")
    return Yup.string().when(`${field}`, ([field], schema) => {
      if (field === value) return validateString("required", 34);
      return schema;
    });
  // if the validation is based on a string or a yes/no field,
  // and field to be validated is supposed to be a date field
  else if (basedOn === "isString" && toValidate === "isDate")
    return Yup.string()
      .nullable()
      .when(`${field}`, ([field], schema) => {
        if (field === value) return validateDate("required");
        return schema;
      });
  // if the validation is based on a string or a yes/no field,
  // and field to be validated is supposed to be a select field
  else if (basedOn === "isString" && toValidate === "isSelect")
    return Yup.object()
      .nullable()
      .when(`${field}`, ([field], schema) => {
        if (field === value) return validateSelect("required");
        return schema;
      });
  // if the validation is based on a string or a yes/no field,
  // and field to be validated is supposed to be a string
  else if (basedOn === "isString" && toValidate === "isString")
    return Yup.string().when(`${field}`, ([field], schema) => {
      if (field === value) return validateString("required");
      return schema;
    });
  //new
  else if (basedOn === "isSelect" && toValidate === "isCityOrTown")
    return Yup.string().when(`${field}`, ([field], schema) => {
      if (field?.value === value) return validateString("required", 20);
      return schema;
    });
  else if (basedOn === "isSelect" && toValidate === "isAddressCode")
    return Yup.string().when(`${field}`, ([field], schema) => {
      if (field?.value === value)
        return Yup.string()
          .required("Required")
          .when(`${addressCodeDepends}`, ([addressCodeDepends], schema) => {
            if (isZipCode(addressCodeDepends?.value))
              return validateNumber("required", 5);
            return schema;
          });
      return schema;
    });
  else if (basedOn === "isCheck" && toValidate === "isSelect")
    return Yup.object()
      .nullable()
      .when(`${field}`, ([field], schema) => {
        if (field[0][value as string]) return validateSelect("required");
        return schema;
      });
  else if (basedOn === "isCheck" && toValidate === "isDate")
    return Yup.string()
      .nullable()
      .when(`${field}`, ([field], schema) => {
        if (field[0][value as string]) return validateDate("required");
        return schema;
      });
  else if (basedOn === "isString" && toValidate === "isAddressCode")
    return Yup.string().when(`${field}`, ([field], schema) => {
      if (field?.value === value)
        return Yup.string()
          .required("Required")
          .when(`${addressCodeDepends}`, ([addressCodeDepends], schema) => {
            if (isZipCode(addressCodeDepends?.value))
              return validateNumber("required", 5);
            return schema;
          });
      return schema;
    });
  else if (basedOn === "isCheck" && toValidate === "isString")
    return Yup.string().when(`${field}`, ([field], schema) => {
      if (field[0][value as string]) return validateString("required");
      return schema;
    });
  else if (basedOn === "isString" && toValidate === "isCityOrTown")
    return Yup.string().when(`${field}`, ([field], schema) => {
      if (field === value) return validateString("required", 20);
      return schema;
    });
  else return Yup.string();
};

// SECTION 1:
// ELIGIBILITY INFO

// MAIN VALIDATION
export const EligibilityInfoValidation = Yup.object().shape<
  SchemaObject<N600EligibilityInfoPayload>
>({
  eligibilityCriteria: validateString("required"),
  eligibilityDescription: validateString(),
});

// SECTION 2:
// INFORMATION ABOUT YOU PART 1

// CUSTOM FUNCTION VALIDATION
const dependsOnHaNameChanged = () => {
  return Yup.array().when(
    "otherNamesSinceBirth",
    ([otherNamesSinceBirth], schema) => {
      if (otherNamesSinceBirth === GenericYesNoOptionsValue.yes)
        return Yup.array().of(
          Yup.object().shape({
            otherFamilyName: validateString("required"),
            otherGivenName: validateString("required"),
            otherMiddleName: validateString(),
          })
        );

      return schema;
    }
  );
};

// MAIN VALIDATION
export const InfoAboutYouPart1Validation = Yup.object().shape<
  SchemaObject<N600InfoAboutYouPart1Data>
>({
  familyName: validateString("required"),
  givenName: validateString("required"),
  middleName: validateString(),
  hasNameChanged: validateString("required"),
  changedFamilyName: onlyWhen(
    "hasNameChanged",
    GenericYesNoOptionsValue.yes,
    "isString",
    "isString"
  ),
  changedGivenName: onlyWhen(
    "hasNameChanged",
    GenericYesNoOptionsValue.yes,
    "isString",
    "isString"
  ),
  changedMiddleName: validateString(),
  otherNamesSinceBirth: validateString("required"),
  otherNamesArray: dependsOnHaNameChanged(),
  aNumber: validateANumber("required"),
  ssn: validateSSNNumber(),
  uscisOnlineAccountNumber: validateUSCISNumber(),
  dateOfBirth: validateDate("required"),
  countryOfBirth: validateSelect("required"),
  countryOfPriorCitizenship: validateSelect("required"),
  gender: validateString("required"),
});

// SECTION 2:
// INFORMATION ABOUT YOU PART 2

// MAIN VALIDATION
export const InfoAboutYouPart2Validation = Yup.object().shape<
  SchemaObject<N600InfoAboutYouPart2Data>
>({
  mailingInCareOfName: validateString("not-required", 34),
  mailingStreetNumberName: validateString("required", 34),
  mailingAptSteFlr: validateSelect(),
  mailingAptSteFlrNumber: onlyWhen(
    "mailingAptSteFlr",
    ApartmentSuiteFloorValues.Apartment ||
      ApartmentSuiteFloorValues.Suite ||
      ApartmentSuiteFloorValues.Floor,
    "isSelect",
    "isAptNumber"
  ),
  mailingCityOrTown: validateString("required"),
  mailingState: validateSelect("required"),
  mailingZipCode: validateNumber("required", 5),
  mailingProvince: Yup.string().when(
    `mailingCountry`,
    ([mailingCountry], schema) => {
      if (mailingCountry?.value && mailingCountry?.value !== "United States")
        return validateString("required");
      return schema;
    }
  ),
  mailingPostalCode: Yup.string().when(
    `mailingCountry`,
    ([mailingCountry], schema) => {
      if (mailingCountry?.value && mailingCountry?.value !== "United States")
        return validateNumber("required", 20);
      return schema;
    }
  ),

  mailingCountry: validateSelect(),

  physicalStreetNumberName: validateString("required", 34),
  physicalAptSteFlr: validateSelect(),
  physicalAptSteFlrNumber: onlyWhen(
    "physicalAptSteFlr",
    ApartmentSuiteFloorValues.Apartment ||
      ApartmentSuiteFloorValues.Suite ||
      ApartmentSuiteFloorValues.Floor,
    "isSelect",
    "isAptNumber"
  ),
  physicalCityOrTown: validateString("required"),
  physicalState: validateSelect("required"),
  physicalZipCode: validateNumber("required", 5),
  physicalProvince: Yup.string().when(
    `physicalCountry`,
    ([physicalCountry], schema) => {
      if (physicalCountry?.value && physicalCountry?.value !== "United States")
        return validateString("required");
      return schema;
    }
  ),
  physicalPostalCode: Yup.string().when(
    `physicalCountry`,
    ([physicalCountry], schema) => {
      if (physicalCountry?.value && physicalCountry?.value !== "United States")
        return validateNumber("required", 20);
      return schema;
    }
  ),
  physicalCountry: validateSelect(),

  maritalStatus: validateSelect("required"),
  maritalStatusExp: onlyWhen(
    "maritalStatus",
    MaritalStatusValues.other,
    "isSelect",
    "isString"
  ),
  usArmedForces: validateString("required"),

  applicantsDaytimeTelephoneNumber: Yup.string()
    .min(10, "Minimum 10 digits allowed")
    .max(10, "Maximum 10 digits allowed")
    .required("Required")
    .test("Digits only", "Must be a number", digitsOnly)
    .trim(),
  applicantsMobileTelephoneNumber: Yup.string()
    .min(10, "Minimum 10 digits allowed")
    .max(10, "Maximum 10 digits allowed")
    .test("Digits only", "Must be a number", optionalDigitsOnly)
    .trim(),
  applicantsEmailAddress: Yup.string().email().trim(),
  // applicantsDaytimeTelephoneNumber: validateString("required"),
  // applicantsMobileTelephoneNumber: validateString(),
  // applicantsEmailAddress: validateString(),
});

// SECTION 2:
// INFORMATION ABOUT YOU PART 3

// MAIN VALIDATION
export const InfoAboutYouPart3Validation = Yup.object().shape<
  SchemaObject<N600InfoAboutYouPart3Data>
>({
  cityOrTown: validateString("required"),
  state: validateSelect("required"),
  dateOfEntry: validateDate("required"),

  familyName: validateString("required"),
  givenName: validateString("required"),
  middleName: validateString(),

  travelDocumentUsed: validateSelect("required"),
  travelDocumentNumber: validateString("required"),
  travelDocumentCountryOfIssuance: validateSelect("required"),
  travelDocumentDateIssued: validateDate("required"),

  immigrationStatus: validateSelect("required"),
  immigrationStatusExplain: onlyWhen(
    "immigrationStatus",
    "Other",
    "isSelect",
    "isString"
  ),
  dateBecameLPR: onlyWhen(
    "immigrationStatus",
    ImmigrationStatusValues.lpr,
    "isSelect",
    "isDate"
  ),
  uscisOfficeGrantedLPR: onlyWhen(
    "immigrationStatus",
    ImmigrationStatusValues.lpr,
    "isSelect",
    "isDate"
  ),

  previouslyAppliedForCitizenshipOrPassport: validateString("required"),
  previouslyAppliedForCitizenshipOrPassportExplanation: onlyWhen(
    "previouslyAppliedForCitizenshipOrPassport",
    GenericYesNoOptionsValue.yes,
    "isString",
    "isString"
  ),

  abandonedOrLostLPRStatus: validateString("required"),
  abandonedOrLostLPRStatusExplanation: onlyWhen(
    "abandonedOrLostLPRStatus",
    GenericYesNoOptionsValue.yes,
    "isString",
    "isString"
  ),

  adopted: validateString("required"),

  cityOrTownOfAdoption: onlyWhen("adopted", "yes", "isString", "isString"),

  stateOfAdoption: onlyWhen("adopted", "yes", "isString", "isSelect"),

  countryOfAdoption: onlyWhen("adopted", "yes", "isString", "isSelect"),

  dateOfAdoption: onlyWhen("adopted", "yes", "isString", "isDate"),

  dateLegalCustodyBegan: onlyWhen("adopted", "yes", "isString", "isDate"),

  datePhysicalCustodyBegan: onlyWhen("adopted", "yes", "isString", "isDate"),

  reAdoptedInUS: onlyWhen(
    "adopted",
    GenericYesNoOptionsValue.yes,
    "isString",
    "isString"
  ),

  cityOrTownOfReAdoption: onlyWhen(
    "reAdoptedInUS",
    "yes",
    "isString",
    "isString"
  ),

  stateOfReAdoption: onlyWhen("reAdoptedInUS", "yes", "isString", "isSelect"),
  countryOfReAdoption: onlyWhen("reAdoptedInUS", "yes", "isString", "isSelect"),
  dateOfReAdoption: onlyWhen("reAdoptedInUS", "yes", "isString", "isDate"),
  dateReAdoptionLegalCustodyBegan: onlyWhen(
    "reAdoptedInUS",
    "yes",
    "isString",
    "isDate"
  ),
  dateReAdoptionPhysicalCustodyBegan: onlyWhen(
    "reAdoptedInUS",
    "yes",
    "isString",
    "isDate"
  ),
});

// SECTION 2:
// INFORMATION ABOUT YOU PART 4

// MAIN VALIDATION
export const InfoAboutYouPart4Validation = Yup.object().shape<
  SchemaObject<N600InfoAboutYouPart4Data>
>({
  parentsMarriedAtBirth: validateString("required"),
  parentsMarriedAfterBirth: onlyWhen(
    "parentsMarriedAtBirth",
    GenericYesNoOptionsValue.no,
    "isString",
    "isString"
  ),
  resideInUSWithCitizenParents: validateString("required"),
  absentFromUS: validateString("required"),

  firstDepartureDate: validateDate(),
  firstReturnDate: validateDate(),
  firstPlaceOfEntryCity: validateString(),
  firstPlaceOfEntryState: validateSelect(),

  secondDepartureDate: validateDate(),
  secondReturnDate: validateDate(),
  secondPlaceOfEntryCity: validateString(),
  secondPlaceOfEntryState: validateSelect(),
});

const dependRaces = () => {
  return Yup.bool().test(
    "oneOfRequired",
    "One race must be checked",
    function () {
      return (
        this.parent.white ||
        this.parent.black ||
        this.parent.indian ||
        this.parent.asian ||
        this.parent.islander
      );
    }
  );
};

// SECTION 3:
// BIOGRAPHIC INFORMATION

// MAIN VALIDATION
export const BioInfoValidation = Yup.object().shape<
  SchemaObject<BiographicInfoData>
>({
  ethnicity: validateString("required"),
  racesArray: Yup.array().of(
    Yup.object().shape({
      white: dependRaces(),
      black: dependRaces(),
      indian: dependRaces(),
      asian: dependRaces(),
      islander: dependRaces(),
    })
  ),
  heightFeet: validateNumber("required"),
  heightInches: validateNumber("required"),
  weightPounds: validateNumber("required"),
  eyeColor: validateString("required"),
  hairColor: validateString("required"),
});

// SECTION 4:
// INFORMATION ABOUT YOUR U.S CITIZEN FATHER PART 1

// MAIN VALIDATION
export const USCitizenFatherP1Validation = Yup.object().shape<
  SchemaObject<USCitizenFatherP1FormData>
>({
  fatherCurrentLegalFamilyName: validateString("required"),
  fatherCurrentLegalGivenName: validateString("required"),
  fatherCurrentLegalMiddleName: validateString(),

  fatherDateOfBirth: validateDate("required"),
  fatherCountryOfBirth: validateSelect("required"),
  fatherCountryOfCitizenship: validateSelect("required"),

  isFatherAlive: validateString("required"),
  fatherDateOfDeath: onlyWhen(
    "isFatherAlive",
    GenericYesNoOptionsValue.no,
    "isString",
    "isDate"
  ),

  fatherPhysicalAddressStreetNumberAndName: onlyWhen(
    "isFatherAlive",
    GenericYesNoOptionsValue.yes,
    "isString",
    "isStreetName"
  ),
  fatherPhysicalAddressAptSteFlr: onlyWhen(
    "isFatherAlive",
    GenericYesNoOptionsValue.yes,
    "isString",
    "isSelect"
  ),
  fatherPhysicalAddressAptSteFlrNumber: Yup.string().when(
    ["isFatherAlive", `fatherPhysicalAddressAptSteFlr`],
    ([isFatherAlive, fatherPhysicalAddressAptSteFlr], schema) => {
      if (
        isFatherAlive === GenericYesNoOptionsValue.yes &&
        fatherPhysicalAddressAptSteFlr?.value
      )
        return validateNumber("required", 4);
      return schema;
    }
  ),
  fatherPhysicalAddressCityOrTown: onlyWhen(
    "isFatherAlive",
    GenericYesNoOptionsValue.yes,
    "isString",
    "isString"
  ),

  fatherPhysicalAddressCountry: validateSelect(),

  fatherPhysicalAddressState: onlyWhen(
    "isFatherAlive",
    GenericYesNoOptionsValue.yes,
    "isString",
    "isSelect"
  ),

  fatherPhysicalAddressZipCode: Yup.string().when(
    "isFatherAlive",
    ([isFatherAlive], schema) => {
      if (isFatherAlive === GenericYesNoOptionsValue.yes)
        return validateNumber("required", 5);
      return schema;
    }
  ),
  fatherPhysicalAddressProvince: Yup.string().when(
    ["isFatherAlive", "fatherPhysicalAddressCountry"],
    ([isFatherAlive, fatherPhysicalAddressCountry], schema) => {
      if (
        isFatherAlive === GenericYesNoOptionsValue.yes &&
        fatherPhysicalAddressCountry?.value &&
        fatherPhysicalAddressCountry?.value.toLowerCase() !== "united states"
      )
        return validateString("required");
      return schema;
    }
  ),

  fatherPhysicalAddressPostalCode: Yup.string().when(
    ["isFatherAlive", "fatherPhysicalAddressCountry"],
    ([isFatherAlive, fatherPhysicalAddressCountry], schema) => {
      if (
        isFatherAlive === GenericYesNoOptionsValue.yes &&
        fatherPhysicalAddressCountry?.value &&
        fatherPhysicalAddressCountry?.value.toLowerCase() !== "united states"
      )
        return validateNumber("required", 20);
      return schema;
    }
  ),

  fatherCitizenshipStatus: validateSelect("required"),

  fatherCertificateOfCitizenshipNumber: onlyWhen(
    "fatherCitizenshipStatus",
    FatherCitizenByDataValues.birthAbroad,
    "isSelect",
    "isNumber"
  ),
  fatherAlienRegistrationNumber: onlyWhen(
    "fatherCitizenshipStatus",
    FatherCitizenByDataValues.birthAbroad,
    "isSelect",
    "isNumber"
  ),
  fatherPlaceOfNaturalizationCourtOrUSCISOffice: onlyWhen(
    "fatherCitizenshipStatus",
    FatherCitizenByDataValues.naturalization,
    "isSelect",
    "isString"
  ),
  fatherPlaceOfNaturalizationCityOrTown: onlyWhen(
    "fatherCitizenshipStatus",
    FatherCitizenByDataValues.naturalization,
    "isSelect",
    "isString"
  ),

  fatherPlaceOfNaturalizationState: onlyWhen(
    "fatherCitizenshipStatus",
    FatherCitizenByDataValues.naturalization,
    "isSelect",
    "isSelect"
  ),

  fatherCertificateOfNaturalizationNumber: onlyWhen(
    "fatherCitizenshipStatus",
    FatherCitizenByDataValues.naturalization,
    "isSelect",
    "isNumber"
  ),

  fatherNaturalizationANumber: validateString(),

  fatherDateOfNaturalization: onlyWhen(
    "fatherCitizenshipStatus",
    FatherCitizenByDataValues.naturalization,
    "isSelect",
    "isDate"
  ),
});

// SECTION 4:
// INFORMATION ABOUT YOUR U.S CITIZEN FATHER PART 2

// MAIN VALIDATION
export const USCitizenFatherP2Validation = Yup.object().shape<
  SchemaObject<USCitizenFatherP2FormData>
>({
  fatherLostCitizenship: validateString(),
  fatherLostCitizenshipExplanation: onlyWhen(
    "fatherLostCitizenship",
    GenericYesNoOptionsValue.yes,
    "isString",
    "isString"
  ),

  fatherCurrentMaritalStatus: validateSelect(),

  fatherNumberOfMarriages: Yup.string().when(
    "fatherCurrentMaritalStatus",
    ([fatherCurrentMaritalStatus], schema) => {
      if (
        fatherCurrentMaritalStatus?.value === MaritalStatusValues.married ||
        fatherCurrentMaritalStatus?.value === MaritalStatusValues.divorced ||
        fatherCurrentMaritalStatus?.value ===
          MaritalStatusValues["marriage annulled"] ||
        fatherCurrentMaritalStatus?.value === MaritalStatusValues.separated ||
        fatherCurrentMaritalStatus?.value === MaritalStatusValues.widowed
      )
        return validateNumber("required");
      return schema;
    }
  ),

  fatherCurrentMaritalStatusExplanation: onlyWhen(
    "fatherCurrentMaritalStatus",
    MaritalStatusValues.other,
    "isSelect",
    "isString"
  ),

  fatherSpouseFamilyName: onlyWhen(
    "fatherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isString"
  ),

  fatherSpouseGivenName: onlyWhen(
    "fatherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isString"
  ),

  fatherSpouseMiddleName: validateString(),

  fatherSpouseDateOfBirth: onlyWhen(
    "fatherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isDate"
  ),

  fatherSpouseCountryOfBirth: onlyWhen(
    "fatherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isSelect"
  ),

  fatherSpouseCountryOfCitizenship: onlyWhen(
    "fatherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isSelect"
  ),

  fatherSpousePhysicalAddressStreetNumberAndName: onlyWhen(
    "fatherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isStreetName"
  ),

  fatherSpousePhysicalAddressAptSteFlr: onlyWhen(
    "fatherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isSelect"
  ),

  fatherSpousePhysicalAddressAptSteFlrNumber: Yup.string().when(
    ["fatherCurrentMaritalStatus", "fatherSpousePhysicalAddressAptSteFlr"],
    (
      [fatherCurrentMaritalStatus, fatherSpousePhysicalAddressAptSteFlr],
      schema
    ) => {
      if (
        fatherCurrentMaritalStatus?.value === MaritalStatusValues.married &&
        fatherSpousePhysicalAddressAptSteFlr?.value
      )
        return validateNumber("required", 4);
      return schema;
    }
  ),

  fatherSpousePhysicalAddressCityOrTown: onlyWhen(
    "fatherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isString"
  ),

  fatherSpousePhysicalAddressState: onlyWhen(
    "fatherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isSelect"
  ),

  fatherSpousePhysicalAddressZipCode: onlyWhen(
    "fatherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isZipCode"
  ),

  fatherSpousePhysicalAddressProvince: Yup.string().when(
    ["fatherCurrentMaritalStatus", "fatherSpousePhysicalAddressCountry"],
    (
      [fatherCurrentMaritalStatus, fatherSpousePhysicalAddressCountry],
      schema
    ) => {
      if (
        fatherCurrentMaritalStatus?.value === MaritalStatusValues.married &&
        fatherSpousePhysicalAddressCountry?.value &&
        fatherSpousePhysicalAddressCountry?.value.toLowerCase() !==
          "united states"
      )
        return validateString("required");
      return schema;
    }
  ),

  fatherSpousePhysicalAddressPostalCode: Yup.string().when(
    ["fatherCurrentMaritalStatus", "fatherSpousePhysicalAddressCountry"],
    (
      [fatherCurrentMaritalStatus, fatherSpousePhysicalAddressCountry],
      schema
    ) => {
      if (
        fatherCurrentMaritalStatus?.value === MaritalStatusValues.married &&
        fatherSpousePhysicalAddressCountry?.value &&
        fatherSpousePhysicalAddressCountry?.value.toLowerCase() !==
          "united states"
      )
        return validateNumber("required");
      return schema;
    }
  ),
  fatherSpousePhysicalAddressCountry: validateSelect(),

  fatherDateOfMarriage: onlyWhen(
    "fatherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isDate"
  ),

  fatherPlaceOfMarriageCityOrTown: onlyWhen(
    "fatherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isString"
  ),

  fatherPlaceOfMarriageState: onlyWhen(
    "fatherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isSelect"
  ),

  fatherPlaceOfMarriageCountry: onlyWhen(
    "fatherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isSelect"
  ),

  fatherSpouseImmigrationStatus: onlyWhen(
    "fatherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isSelect"
  ),

  fatherSpouseImmigrationStatusExplanation: onlyWhen(
    "fatherSpouseImmigrationStatus",
    ImmigrationStatusValues.other,
    "isSelect",
    "isString"
  ),

  isFatherCurrentSpouseBiologicalOrAdoptiveMother: onlyWhen(
    "fatherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isString"
  ),
});

// SECTION 5:
// INFORMATION ABOUT YOUR U.S CITIZEN MOTHER PART 1

// MAIN VALIDATION
export const USCitizenMOtherP1Validation = Yup.object().shape<
  SchemaObject<USCitizenMotherP1FormData>
>({
  motherCurrentLegalFamilyName: validateString("required"),
  motherCurrentLegalGivenName: validateString("required"),
  motherCurrentLegalMiddleName: validateString(),

  motherDateOfBirth: validateDate("required"),
  motherCountryOfBirth: validateSelect("required"),
  motherCountryOfCitizenship: validateSelect("required"),

  isMotherAlive: validateString("required"),
  motherDateOfDeath: onlyWhen(
    "isMotherAlive",
    GenericYesNoOptionsValue.no,
    "isString",
    "isDate"
  ),

  motherPhysicalAddressStreetNumberAndName: onlyWhen(
    "isMotherAlive",
    GenericYesNoOptionsValue.yes,
    "isString",
    "isStreetName"
  ),
  motherPhysicalAddressAptSteFlr: onlyWhen(
    "isMotherAlive",
    GenericYesNoOptionsValue.yes,
    "isString",
    "isSelect"
  ),
  motherPhysicalAddressAptSteFlrNumber: Yup.string().when(
    ["isMotherAlive", `motherPhysicalAddressAptSteFlr`],
    ([isMotherAlive, motherPhysicalAddressAptSteFlr], schema) => {
      if (
        isMotherAlive === GenericYesNoOptionsValue.yes &&
        motherPhysicalAddressAptSteFlr?.value
      )
        return validateNumber("required", 4);
      return schema;
    }
  ),
  motherPhysicalAddressCityOrTown: onlyWhen(
    "isMotherAlive",
    GenericYesNoOptionsValue.yes,
    "isString",
    "isString"
  ),

  motherPhysicalAddressCountry: validateSelect(),

  motherPhysicalAddressState: onlyWhen(
    "isMotherAlive",
    GenericYesNoOptionsValue.yes,
    "isString",
    "isSelect"
  ),

  motherPhysicalAddressZipCode: Yup.string().when(
    "isMotherAlive",
    ([isMotherAlive], schema) => {
      if (isMotherAlive === GenericYesNoOptionsValue.yes)
        return validateNumber("required", 5);
      return schema;
    }
  ),
  motherPhysicalAddressProvince: Yup.string().when(
    ["isMotherAlive", "motherPhysicalAddressCountry"],
    ([isMotherAlive, motherPhysicalAddressCountry], schema) => {
      if (
        isMotherAlive === GenericYesNoOptionsValue.yes &&
        motherPhysicalAddressCountry?.value &&
        motherPhysicalAddressCountry?.value.toLowerCase() !== "united states"
      )
        return validateString("required");
      return schema;
    }
  ),

  motherPhysicalAddressPostalCode: Yup.string().when(
    ["isMotherAlive", "motherPhysicalAddressCountry"],
    ([isMotherAlive, motherPhysicalAddressCountry], schema) => {
      if (
        isMotherAlive === GenericYesNoOptionsValue.yes &&
        motherPhysicalAddressCountry?.value &&
        motherPhysicalAddressCountry?.value.toLowerCase() !== "united states"
      )
        return validateNumber("required", 20);
      return schema;
    }
  ),

  motherCitizenshipStatus: validateSelect("required"),

  motherCertificateOfCitizenshipNumber: onlyWhen(
    "motherCitizenshipStatus",
    FatherCitizenByDataValues.birthAbroad,
    "isSelect",
    "isNumber"
  ),
  motherAlienRegistrationNumber: onlyWhen(
    "motherCitizenshipStatus",
    FatherCitizenByDataValues.birthAbroad,
    "isSelect",
    "isNumber"
  ),
  motherPlaceOfNaturalizationCourtOrUSCISOffice: onlyWhen(
    "motherCitizenshipStatus",
    FatherCitizenByDataValues.naturalization,
    "isSelect",
    "isString"
  ),

  motherPlaceOfNaturalizationCityOrTown: onlyWhen(
    "motherCitizenshipStatus",
    FatherCitizenByDataValues.naturalization,
    "isSelect",
    "isString"
  ),

  motherPlaceOfNaturalizationState: onlyWhen(
    "motherCitizenshipStatus",
    FatherCitizenByDataValues.naturalization,
    "isSelect",
    "isSelect"
  ),

  motherCertificateOfNaturalizationNumber: onlyWhen(
    "motherCitizenshipStatus",
    FatherCitizenByDataValues.naturalization,
    "isSelect",
    "isNumber"
  ),
  motherNaturalizationANumber: validateString(),

  motherDateOfNaturalization: onlyWhen(
    "motherCitizenshipStatus",
    FatherCitizenByDataValues.naturalization,
    "isSelect",
    "isDate"
  ),
});

// SECTION 5:
// INFORMATION ABOUT YOUR U.S CITIZEN MOTHER PART 2

// MAIN VALIDATION
export const USCitizenMotherP2Validation = Yup.object().shape<
  SchemaObject<USCitizenMotherP2FormData>
>({
  motherLostCitizenship: validateString("required"),
  motherLostCitizenshipExplanation: onlyWhen(
    "motherLostCitizenship",
    GenericYesNoOptionsValue.yes,
    "isString",
    "isString"
  ),

  motherCurrentMaritalStatus: validateSelect("required"),

  motherNumberOfMarriages: Yup.string().when(
    "motherCurrentMaritalStatus",
    ([motherCurrentMaritalStatus], schema) => {
      if (
        motherCurrentMaritalStatus?.value === MaritalStatusValues.married ||
        motherCurrentMaritalStatus?.value === MaritalStatusValues.divorced ||
        motherCurrentMaritalStatus?.value ===
          MaritalStatusValues["marriage annulled"] ||
        motherCurrentMaritalStatus?.value === MaritalStatusValues.separated ||
        motherCurrentMaritalStatus?.value === MaritalStatusValues.widowed
      )
        return validateNumber("required");
      return schema;
    }
  ),

  motherCurrentMaritalStatusExplanation: onlyWhen(
    "motherCurrentMaritalStatus",
    MaritalStatusValues.other,
    "isSelect",
    "isString"
  ),

  motherSpouseFamilyName: onlyWhen(
    "motherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isString"
  ),

  motherSpouseGivenName: onlyWhen(
    "motherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isString"
  ),

  motherSpouseMiddleName: validateString(),

  motherSpouseDateOfBirth: onlyWhen(
    "motherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isDate"
  ),

  motherSpouseCountryOfBirth: onlyWhen(
    "motherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isSelect"
  ),

  motherSpouseCountryOfCitizenship: onlyWhen(
    "motherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isSelect"
  ),

  motherSpousePhysicalAddressStreetNumberAndName: onlyWhen(
    "motherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isStreetName"
  ),

  motherSpousePhysicalAddressAptSteFlr: onlyWhen(
    "motherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isSelect"
  ),

  motherSpousePhysicalAddressAptSteFlrNumber: Yup.string().when(
    ["motherCurrentMaritalStatus", "motherSpousePhysicalAddressAptSteFlr"],
    (
      [motherCurrentMaritalStatus, motherSpousePhysicalAddressAptSteFlr],
      schema
    ) => {
      if (
        motherCurrentMaritalStatus?.value === MaritalStatusValues.married &&
        motherSpousePhysicalAddressAptSteFlr?.value
      )
        return validateNumber("required", 4);
      return schema;
    }
  ),

  motherSpousePhysicalAddressCityOrTown: onlyWhen(
    "motherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isString"
  ),

  motherSpousePhysicalAddressState: onlyWhen(
    "motherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isSelect"
  ),

  motherSpousePhysicalAddressZipCode: onlyWhen(
    "motherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isString"
  ),

  motherSpousePhysicalAddressProvince: Yup.string().when(
    ["motherCurrentMaritalStatus", "motherSpousePhysicalAddressCountry"],
    (
      [motherCurrentMaritalStatus, motherSpousePhysicalAddressCountry],
      schema
    ) => {
      if (
        motherCurrentMaritalStatus?.value === MaritalStatusValues.married &&
        motherSpousePhysicalAddressCountry?.value &&
        motherSpousePhysicalAddressCountry?.value.toLowerCase() !==
          "united states"
      )
        return validateString("required");
      return schema;
    }
  ),

  motherSpousePhysicalAddressPostalCode: Yup.string().when(
    ["motherCurrentMaritalStatus", "motherSpousePhysicalAddressCountry"],
    (
      [motherCurrentMaritalStatus, motherSpousePhysicalAddressCountry],
      schema
    ) => {
      if (
        motherCurrentMaritalStatus?.value === MaritalStatusValues.married &&
        motherSpousePhysicalAddressCountry?.value &&
        motherSpousePhysicalAddressCountry?.value.toLowerCase() !==
          "united states"
      )
        return validateNumber("required");
      return schema;
    }
  ),
  motherSpousePhysicalAddressCountry: validateSelect(),

  motherDateOfMarriage: onlyWhen(
    "motherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isDate"
  ),

  motherPlaceOfMarriageCityOrTown: onlyWhen(
    "motherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isString"
  ),

  motherPlaceOfMarriageState: onlyWhen(
    "motherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isSelect"
  ),

  motherPlaceOfMarriageCountry: onlyWhen(
    "motherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isSelect"
  ),

  motherSpouseImmigrationStatus: onlyWhen(
    "motherCurrentMaritalStatus",
    MaritalStatusValues.married,
    "isSelect",
    "isSelect"
  ),

  motherSpouseImmigrationStatusExplanation: Yup.string().when(
    ["motherCurrentMaritalStatus", "motherSpouseImmigrationStatus"],
    ([motherCurrentMaritalStatus, motherSpouseImmigrationStatus], schema) => {
      if (
        motherCurrentMaritalStatus?.value === MaritalStatusValues.married &&
        motherSpouseImmigrationStatus?.value === ImmigrationStatusValues.other
      )
        return validateString("required");
      return schema;
    }
  ),

  isMotherCurrentSpouseBiologicalOrAdoptiveFather: Yup.string().when(
    "motherCurrentMaritalStatus",
    ([motherCurrentMaritalStatus], schema) => {
      if (motherCurrentMaritalStatus?.value === MaritalStatusValues.married)
        return validateString("required");
      return schema;
    }
  ),
});

// SECTION 6:
// PHYSICAL PRESENCE IN THE U.S FROM BIRTH

// MAIN VALIDATION
export const N600PresenceInUSValidation = Yup.object().shape<
  SchemaObject<N600PresenceInUSData>
>({
  relatesTo: validateString("required"),
  physicalPresencePeriodsArray: Yup.array().of(
    Yup.object().shape({
      fromDate: validateDate("required"),
      toDate: validateDate("required"),
    })
  ),
});

// SECTION 7:
// INFORMATION ABOUT MILITARY SERVICE OF U.S PARENT

// CUSTOM FUNCTION VALIDATION
const whenParentServed = () => {
  return Yup.array().when(
    "parentServedInUSArmedForces",
    ([parentServedInUSArmedForces], schema) => {
      if (parentServedInUSArmedForces === GenericYesNoOptionsValue.yes)
        return Yup.array().of(
          Yup.object().shape({
            fromDate: validateDate("required"),
            toDate: validateDate("required"),
          })
        );

      return schema;
    }
  );
};

// MAIN VALIDATION
export const N600MilitaryServiceValidation = Yup.object().shape<
  SchemaObject<N600MilitaryServiceData>
>({
  parentServedInUSArmedForces: validateString("required"),
  parentWhoServed: onlyWhen(
    "parentServedInUSArmedForces",
    GenericYesNoOptionsValue.yes,
    "isString",
    "isString"
  ),

  dateOfServiceArray: whenParentServed(),
  typeOfDischarge: onlyWhen(
    "parentServedInUSArmedForces",
    GenericYesNoOptionsValue.yes,
    "isString",
    "isString"
  ),
});
