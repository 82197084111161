import styled from "styled-components";
import Colors from "../../../../styles/Colors";

export const Wrapper = styled.div``;

export const Info = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  margin-bottom: 30px;
`;

export const InfoBold = styled.div`
  font-weight: 700;
`;

export const VerticalFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const TermsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 14.4px;
  letter-spacing: 0em;
  color: ${Colors.Black};
  margin-top: 10px;
  margin-left: auto;
  text-align: end;

  & > input {
    width: 24px;
    height: 24px;
  }
`;

export const SubTitle = styled.div`
  font-size: 1rem;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  margin-bottom: 30px;
`;

export const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: ${Colors.WhiteF3};
  padding: 16px;
`;

export const ArrayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CheckWrapper = styled.div`
  flex-shrink: 0;
`;

export const HalfFormWrapper = styled(VerticalFormWrapper)`
  & > div {
    width: 50%;
  }
`;

export const CheckGroupedWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
`;
