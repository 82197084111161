import React, { FC } from "react";
import styled from "styled-components";
import RadioField from "../../components/formik fields/RadioField";

interface Props {
  title: string;
  name: string;
  link?: string;
  options: { label: string; value: string }[];
  onChange?: (value: string, name: string) => void;
}

const Question: FC<Props> = ({ title, link, name, options, onChange }) => {
  return (
    <Wrapper>
      <Label>
        {title} {link}
      </Label>
      <OptionsFlex>
        {options.map((ev, i) => (
          <RadioField
            key={i}
            name={name}
            value={ev.value}
            label={ev.label}
            onChange={onChange}
          />
        ))}
      </OptionsFlex>
    </Wrapper>
  );
};

export default Question;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

const Label = styled.div`
  font-size: 1.2rem;
  margin-bottom: 0.45rem;
`;

const OptionsFlex = styled.div`
  font-size: 1.1rem;
  width: max-content;

  & > div {
    margin-bottom: 0.45rem;
    cursor: pointer;
  }

  input {
    height: 18px;
    width: 18px;
  }
`;
