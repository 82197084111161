import React, { FC } from "react";
import InputField from "../../components/formik fields/InputField";
// import SelectField from "../../components/formik fields/SelectField";
import DatePickerField from "../../components/formik fields/DatePickerField";
// import CheckBoxField from "../../components/formik fields/CheckBoxField";
// import { CheckWrapper } from "../../form ds 160/components/styles";
// import styled from "styled-components";
import { FormikProps } from "formik";
import { n400EmploymentHistoryRes } from "../../../../api/n400/types";
import // ApartmentSuiteFloorData,
// OccupationDropDown,
// USDatePlaceholderFormat,
"../../components/data";
// import useCountries from "../../components/useCountries";
// import useStatesByCountry from "../../components/useStatesByCountry";
import { FormWrapper } from "../../components/styles";
import { EmploymentHistoryData } from "./EmploymentHistoryInfo";
import styled from "styled-components";
import SelectField from "../../components/formik fields/SelectField";
import useStatesByCountry from "../../components/useStatesByCountry";
import useCountries from "../../components/useCountries";
// import { CheckWrapper } from "../../form ds 160/components/styles";
// import CheckBoxField from "../../components/formik fields/CheckBoxField";
import {
  OccupationDropDown,
  // OccupationDropDown,
  USDatePlaceholderFormat,
  isZipCode,
} from "../../components/data";
import { CheckWrapper } from "../../form ds 160/components/styles";
import CheckBoxField from "../../components/formik fields/CheckBoxField";

interface Props {
  data?: n400EmploymentHistoryRes;
  index: number;
  formik: FormikProps<EmploymentHistoryData>;
}

const EmploymentBox: FC<Props> = ({ data, index, formik }) => {
  const savedCountry = data?.country.split(",")[index] || "";
  const { formattedCountryData, countryLoading } = useCountries();
  const { formattedStateData, stateLoading, setCountry, selectedCountry } =
    useStatesByCountry(savedCountry);

  return (
    <>
      <FormWrapper>
        <InputField
          name={`employmentHistoryArray[${index}].employerOrSchoolName`}
          label="Employer or School Name"
          placeholder=""
        />
        <InputField
          name={`employmentHistoryArray[${index}].cityOrTown`}
          label="City or Town"
          placeholder=""
        />
      </FormWrapper>

      <FormWrapper>
        <div>
          <SelectField
            name={`employmentHistoryArray[${index}].country`}
            label="Country"
            placeholder="-Select-"
            options={formattedCountryData}
            isLoading={countryLoading}
            onChange={(value) => {
              setCountry(value.label);
              formik.setFieldValue(
                `employmentHistoryArray[${index}].stateOrProvince`,
                null
              );
            }}
          />
        </div>
        <div>
          <SelectField
            name={`employmentHistoryArray[${index}].stateOrProvince`}
            label="State/ Province"
            placeholder="-Select-"
            options={formattedStateData}
            isLoading={stateLoading}
            isDisabled={!selectedCountry}
          />
        </div>
      </FormWrapper>

      <FormWrapper>
        <InputField
          label={`${
            isZipCode(
              formik.values.employmentHistoryArray[index].country?.value
            )
              ? "Zip"
              : "Postal"
          } Code`}
          placeholder=""
          name={`employmentHistoryArray[${index}].addressCode`}
        />
        <div>
          <SelectField
            label="Your Occupation or Field of Study"
            name={`employmentHistoryArray[${index}].occupationOrFieldOfStudy`}
            options={OccupationDropDown}
            placeholder=""
          />
        </div>
      </FormWrapper>

      <FormWrapper>
        <div>
          <DatePickerField
            name={`employmentHistoryArray[${index}].dateFrom`}
            label="Date From (mm/dd/yyyy)"
            disableFuture={true}
            placeholder={USDatePlaceholderFormat}
          />
        </div>
        <LastWrapper>
          <DatePickerField
            name={`employmentHistoryArray[${index}].dateTo`}
            label="Date to"
            placeholder={USDatePlaceholderFormat}
            disableFuture={true}
            isDisabled={
              index === 0 &&
              !!formik.values.employmentHistoryArray[index].doesNotApplyDateTo
            }
          />

          {index === 0 && (
            <CheckWrapper>
              <CheckBoxField
                label={`Present`}
                name={`employmentHistoryArray[${index}].doesNotApplyDateTo`}
                onChange={() => {
                  formik.setFieldTouched(
                    `employmentHistoryArray[${index}].dateTo`,
                    false
                  );
                  formik.setFieldValue(
                    `employmentHistoryArray[${index}].dateTo`,
                    null
                  );
                }}
              />
            </CheckWrapper>
          )}
        </LastWrapper>
      </FormWrapper>
    </>
  );
};

export default EmploymentBox;

const LastWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  margin-bottom: 2rem;
`;
