import styled from "styled-components";
import Colors from "../../../../styles/Colors";

export const Colored = styled.span`
  color: #da5002;
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Title = styled.div`
  color: ${Colors.Black31};
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const Sub = styled.div`
  color: ${Colors.Black31};
  margin-top: 19px;
  margin-bottom: 30px;
  font-weight: 300;
  font-size: 0.875rem;
`;

export const Wrapper = styled.div``;

export const Note = styled.div`
  color: ${Colors.Black31};
  font-weight: 300;
  margin-bottom: 30px;
  font-size: 0.875rem;
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 1rem;

  & > svg {
    color: ${Colors.AmberFE};
    flex-shrink: 0;
  }

  & > p {
    font-size: 0.85rem;
  }
`;

export const RadioListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1.5rem 0;

  & > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1.2rem;
  }

  input {
    accent-color: red;
  }
`;
