import React, { FC } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { ReviewData } from "../ReviewBox";
import Colors from "../../../../../styles/Colors";
import PdfReviewGeneric, { PdfReviewGenericstyles } from "./PdfReviewGeneric";

const styles = StyleSheet.create({
  subHead: {
    justifySelf: "flex-start",
    fontSize: "16px",
    fontWeight: 400,
    // lineHeight: "19px",
    letterSpacing: "0",
    color: Colors.Blue00,
  },
});

interface Props {
  title: string;
  data: ReviewData[];
}

const PdfReviewOutput: FC<Props> = ({ data, title }) => {
  let number = 0;

  const childrenJsx = data.map((child) => {
    if (child.section) {
      return (
        <PdfReviewOutput
          data={child.section.children}
          title={child.section.title}
        />
      );
    } else {
      if (child.value) {
        number += 1;
      }

      return <PdfReviewGeneric value={child.value} label={child.label} />;
    }
  });

  return number > 0 ? (
    <>
      <View style={PdfReviewGenericstyles.row}>
        <Text style={styles.subHead}>{title}</Text>
      </View>
      {childrenJsx}
    </>
  ) : (
    <></>
  );
};

export default PdfReviewOutput;
