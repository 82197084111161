import React, { FC } from "react";
import { Title } from "./styles";
import InputField from "../../components/formik fields/InputField";
import SelectField from "../../components/formik fields/SelectField";
import useCountries from "../../components/useCountries";
import useStatesByCountry from "../../components/useStatesByCountry";
import { ChildrenInfoData } from "./ChildrenInfomation";
import { FormikProps } from "formik";
import { ApartmentSuiteFloorData, isZipCode } from "../../components/data";
import { FormWrapper } from "../../components/styles";
import { N400ChildrenInfoObj } from "../../../../api/n400/types";

interface Props {
  data?: N400ChildrenInfoObj;
  index: number;
  formik: FormikProps<ChildrenInfoData>;
}

const ChildCurrentAddress: FC<Props> = ({ formik, index, data }) => {
  const savedCountry = data?.country.split(",")[index] || "";
  const { formattedCountryData, countryLoading } = useCountries();
  const { formattedStateData, stateLoading, setCountry, selectedCountry } =
    useStatesByCountry(savedCountry);

  return (
    <>
      <Title>
        Child {formik.values.childrenInforArray.length === 1 ? "1" : index + 1}{" "}
        Current Address
      </Title>
      <FormWrapper>
        <InputField
          label="In Care of Name"
          coloredLabel=" (if any)"
          placeholder=""
          name={`childrenInforArray[${index}].inCareOfName`}
        />

        <InputField
          label="Street number and name"
          placeholder=""
          name={`childrenInforArray[${index}].streetNumberAndName`}
        />
      </FormWrapper>
      <FormWrapper>
        <div>
          <SelectField
            label="Country"
            placeholder="-Select-"
            name={`childrenInforArray[${index}].country`}
            options={formattedCountryData}
            isLoading={countryLoading}
            onChange={(value) => {
              setCountry(value.label);
              formik.setFieldValue(
                `childrenInforArray[${index}].stateOrTerritory`,
                null
              );
            }}
          />
        </div>
        <div>
          <SelectField
            name={`childrenInforArray[${index}].stateOrTerritory`}
            label="State or Territory"
            placeholder="-Select-"
            options={formattedStateData}
            isLoading={stateLoading}
            isDisabled={!selectedCountry}
          />
        </div>
      </FormWrapper>
      <FormWrapper>
        <InputField
          label={`${
            isZipCode(formik.values.childrenInforArray[index].country?.value)
              ? "Zip"
              : "Postal"
          } Code`}
          placeholder=""
          name={`childrenInforArray[${index}].addressCode`}
        />
        <InputField
          label="City or Town"
          placeholder=""
          name={`childrenInforArray[${index}].cityOrTown`}
        />
      </FormWrapper>

      <FormWrapper>
        <InputField
          label="Province"
          coloredLabel="(If any)"
          placeholder=""
          name={`childrenInforArray[${index}].province`}
        />

        <div></div>
      </FormWrapper>

      <FormWrapper>
        <div>
          <SelectField
            label="Is there an apartment, suite or floor?"
            name={`childrenInforArray[${index}].apartmentSuiteOrFloor`}
            options={ApartmentSuiteFloorData}
          />
        </div>

        <div>
          {formik.values.childrenInforArray[index].apartmentSuiteOrFloor
            ?.value && (
            <InputField
              label={`${formik.values.childrenInforArray[index].apartmentSuiteOrFloor?.value} Address number`}
              placeholder=""
              name={`childrenInforArray[${index}].addressNumber`}
            />
          )}
        </div>
      </FormWrapper>
    </>
  );
};

export default ChildCurrentAddress;
