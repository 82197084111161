import React, { FC } from "react";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { n400CheckPart1Res } from "../../../../../../api/n400/types";
import { FormN400Progress } from "../../types";

interface Props {
  title: string;
  data?: n400CheckPart1Res;
}

const ReviewCheckPart1: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      label:
        "1. Have you EVER claimed to be a U.S. citizen (in writing or any other way)",
      value: data?.ever_claim_to_be_citizen,
    },
    {
      label:
        "2. Have you EVER registered to vote in any Federal, state or local election in the United States?)",
      value: data?.ever_register_to_vote_in_election,
    },
    {
      label:
        "3.Do you currently owe any overdue Federal, state, or local taxes in the United States?",
      value: data?.do_you_owe_overdue_taxes,
    },
    {
      label:
        "4. Since you became a lawful permanent resident, have you called yourself a “nonresident alien” on a Federal, state, or local tax return or decided not to file a tax return because you considered yourself to be a nonresident?",
      value: data?.ever_called_yourself_non_resident_on_tax,
    },
    {
      label:
        "5.a. Been a member of, involved in, or in any way associated with any Communist or totalitarian party anywhere in the world?",
      value: data?.ever_been_member_of_communist_or_totalitarian_party,
    },

    {
      section: {
        title:
          "5.b. Have you EVERAdvocated (supported and promoted) any of the following, or been a member of, involved in, or in any way associated with any group anywhere in the world that advocated any of the following:",
        children: [
          {
            label: "5.b.i Opposition to all organized government;",
            value: data?.ever_supported_opposition_to_all_organized_government,
          },
          {
            label: "5.b.ii World communism;",
            value: data?.ever_supported_world_communism,
          },
          {
            label:
              "5.b.iii The establishment in the United States of a totalitarian dictatorship;",
            value: data?.ever_supported_totalitarian_in_us,
          },
          {
            label:
              "5.b.iv The overthrow by force or violence or other unconstitutional means of the Government of the United States or all forms of law;",
            value: data?.ever_supported_overthrow_of_government,
          },
          {
            label:
              "5.b.v The unlawful assaulting or killing of any officer or officers of the Government of the United States or of any other organized government because of their official character;",
            value: data?.ever_supported_killing_of_officers_of_us,
          },
          {
            label:
              "5.b.vi The unlawful damage, injury, or destruction of property;",
            value: data?.ever_supported_unlawful_damage_of_property,
          },
          {
            label: "5.b.vii Sabotage",
            value: data?.ever_supported_sabotage,
          },
        ],
      },
    },
    {
      section: {
        title:
          "6. Have you EVER been a member of, involved in, or in any way associated with, or have you EVER provided money,  a thing of value, services or labor, or any other assistance or support to a group that:",
        children: [
          {
            label:
              "6.a. Used a weapon or explosive with intent to harm another person or cause damage to property?",
            value: data?.ever_used_weapon_with_intent_to_harm,
          },
          {
            label:
              "6.b. Engaged (participated) in kidnapping, assassination, or hijacking or sabotage of an airplane, ship, vehicle, or other mode of transportation?",
            value: data?.ever_engaged_in_kidnapping_assassination_hijacking,
          },
          {
            label:
              "6.c. Threatened, attempted (tried), conspired (planned with others), prepared, planned, advocated for, or incited (encouraged) others to commit any of the acts listed in Item Numbers 6.a. or 6.b.?",
            value:
              data?.ever_threatened_tried_conspired_prepared_planned_incited,
          },
        ],
      },
    },
    {
      section: {
        title:
          "7. Have you EVER ordered, incited, called for, committed, assisted, helped with, or otherwise participated in any of the following:",
        children: [
          {
            label: "A. Torture?",
            value: data?.ever_involved_in_torture,
          },
          {
            label: "B. Genocide?",
            value: data?.ever_involved_in_genocide,
          },
          {
            label: "C. Killing, or trying to kill, someone?",
            value: data?.ever_involved_in_killing_or_trying_to_kill,
          },
          {
            label:
              "D. Intentionally and severely injuring or trying to injure any person?",
            value: data?.ever_involved_in_hurting_or_trying_to_hurt,
          },
          {
            label:
              "E.  Any kind of sexual contact or activity with any person who did not consent (did not agree) or was unable to consent (could not agree), or was being forced or threatened by you or by someone else?",
            value:
              data?.ever_involved_in_forcing_or_trying_to_force_sexual_relation,
          },
          {
            label: "F. Not letting someone practice their religion?",
            value: data?.ever_involved_in_not_letting_one_practice_religion,
          },
          {
            label:
              "G. Causing harm or suffering to any person because of their race, religion, national origin, membership in a particular social group, or political opinion?",
            value:
              data?.ever_caused_harm_because_of_race_religion_national_origin,
          },
        ],
      },
    },
    {
      label:
        "8.a. Have you EVER served in, been a member of, assisted (helped), or participated in any military or police unit?",
      value: data?.ever_a_member_of_military_unit,
    },
    {
      label:
        "8.b. Have you EVER served in, been a member of, assisted (helped), or participated in any armed group (a group that carries weapons), for example: paramilitary unit (a group of people who act like a military group but are not part of the official military), self-defense unit, vigilante unit, rebel group, or guerrilla group?",
      value: data?.ever_a_member_of_paramilitary_unit,
    },
    {
      label: "Name of Country",
      value: data?.name_of_country,
    },
    {
      label: "Name of Military Unit",
      value: data?.name_of_military_unit,
    },
    {
      label: "Rank or Position",
      value: data?.rank_or_position,
    },
    {
      label: "Date you joined (MM/DD/YYYY)",
      value: data?.date_you_joined,
    },
    {
      label: "Date you left (MM/DD/YYYY)",
      value: data?.date_you_left,
    },
    {
      label:
        "9. Have you EVER worked, volunteered, or otherwise served in a place where people were detained (forced to stay), for example, a prison, jail, prison camp (a camp where prisoners of war or political prisoners are kept), detention facility, or labor camp, or have you EVER directed or participated in any other activity that involved detaining people?",
      value: data?.ever_served_in_any_other_place_people_were_forced_to_stay,
    },
    {
      label:
        "10.a. Were you EVER a part of any group, or did you EVER help any group, unit, or organization that used a weapon against any person, or threatened to do so?",
      value: data?.ever_part_of_a_group_that_used_weapon,
    },
    {
      label:
        "10.b. If you answered “Yes” to Item Number 10.a., when you were part of this group, or when you helped this group, did you ever use a weapon against another person?",
      value: data?.did_you_use_a_weapon_on_another_person,
    },
    {
      label:
        "10.c. If you answered “Yes” to Item Number 10.a., when you were part of this group, or when you helped this group, did you ever threaten another person that you would use a weapon against that person?",
      value: data?.did_you_threaten_to_use_a_weapon_on_another_person,
    },
    {
      label:
        "11. Have you EVER sold, provided, or transported weapons, or assisted any person in selling, providing, or transporting weapons, which you knew or believed would be used against another person?",
      value: data?.ever_sell_weapons,
    },
    {
      label:
        "12. Have you EVER received any weapons training, paramilitary training, or other military-type training?",
      value: data?.ever_receive_military_or_weapons_training,
    },
    {
      label:
        "13. Have you EVER recruited (asked), enlisted (signed up), conscripted (required to join), or used any person under 15 years of age to serve in or help an armed group, or attempted or worked with others to do so?",
      value: data?.ever_recruit_person_under_age_15_to_help_armed_force,
    },
    {
      label:
        "14. Have you EVER used any person under 15 years of age to take part in hostilities or attempted or worked with others to do so? This could include participating in combat or providing services related to combat (such as serving as a messenger or transporting supplies).",
      value: data?.ever_used_person_under_age_15_to_help_people_in_combat,
    },
  ];

  return (
    <ReviewBox
      title={title}
      data={section}
      editUrl={FormN400Progress.checkPart1}
    />
  );
};

export default ReviewCheckPart1;
