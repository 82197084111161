import React, { FC, useState } from "react";
import styled from "styled-components";
import Colors from "../../../../../../../styles/Colors";
import useOpener from "../../../../../../../hooks/useOpener";
import { GetBusinessApplicationsObj } from "../../../../../../../api/business/applications/types";
import { FormCategories } from "../../../../../../../routes/type";
import useBusinessContinueToForm from "../../../../../../../hooks/useBusinessContinueToForm";
import DownloadForms from "../../../../../components/DownloadForms";
import CreateUser from "../../users/components/CreateUser";
import { postRiskAssessment } from "../../../../../../../api/ai";
import { toast } from "react-toastify";
import Spinner from "../../../../../../../utils/Spinner";
import { clearEligibilityStoreAndLocalStorage } from "../../../../../checkEligibilty/components/data";

interface Props {
  data: GetBusinessApplicationsObj;
  onDs160Risk: (params: string) => void;
}

const SingleCaseActions: FC<Props> = ({ data, onDs160Risk }) => {
  const [loading, setLoading] = useState(false);
  const { businessContinueToForm } = useBusinessContinueToForm();
  const {
    open: downloadOpen,
    onClose: downloadClose,
    onOpen: onDownloadOpen,
  } = useOpener();
  const {
    open: editOpen,
    onClose: onEditClose,
    onOpen: onEditOpen,
  } = useOpener();

  const onBusiness = async () => {
    setLoading(true);
    try {
      const result2 = await postRiskAssessment({
        data: data?.AI_data,
      });
      onDs160Risk(result2.data.result.content);
      // setOpen(result2.data.result);
    } catch (error) {
      toast.error("An error occured.");
    }
    setLoading(false);
  };

  return (
    <>
      <Wrapper>
        <H5>Action</H5>
        <MiniWrapper>
          <EditUserBtn onClick={onEditOpen}>Edit User</EditUserBtn>
          {data.applicationInfo && (
            <>
              {(data.applicationInfo.formCategory === FormCategories.ds160
                ? data.applicationInfo.isFormCompleted
                : data.applicationInfo.isPaid) && (
                <DownloadFormsBtn onClick={onDownloadOpen}>
                  Download Forms
                </DownloadFormsBtn>
              )}
              <RiskBtn disabled={loading} onClick={onBusiness}>
                {loading ? (
                  <Spinner size={16} color={Colors.Black31} />
                ) : (
                  "Check Risk Assessment"
                )}
              </RiskBtn>
            </>
          )}

          <RiskBtn
            onClick={() => {
              if (!data.applicationInfo) {
                clearEligibilityStoreAndLocalStorage();
              }
              businessContinueToForm(data);
            }}
          >
            Continue To {data.applicationInfo ? "Form" : "Eligiblity"}
          </RiskBtn>
        </MiniWrapper>
      </Wrapper>
      {downloadOpen && data.applicationInfo && (
        <DownloadForms
          onClose={downloadClose}
          caseType={data.applicationInfo.formCategory}
          filingURL={""}
          formDownloadUrl={""}
          g1145URL={""}
        />
      )}
      {editOpen && <CreateUser onClose={onEditClose} data={data.userInfo} />}
    </>
  );
};

export default SingleCaseActions;

const Wrapper = styled.div`
  position: sticky;
  top: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* background-color: white; */
  padding: 24px;
  /* border: 1px solid #a7aabd45; */
  border-radius: 12px;
  background-color: ${Colors.WhiteF9};
  border-bottom: 0.5px solid #a7aabd80;
`;

const H5 = styled.h5`
  font-size: 1rem;
  font-weight: 600;
  line-height: 19.36px;
  text-align: left;
  padding-bottom: 10px;
  border-bottom: 0.5px solid #a7aabd80;
  width: 100%;
`;

const MiniWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Btn = styled.button`
  width: 180px;
  max-width: 180px;
  min-height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 24px;
  font-size: 0.75rem;
  font-weight: 600;
  border-radius: 8px;
  white-space: nowrap;
  text-transform: uppercase;

  & > svg {
    width: 20px;
    height: 20px;
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const EditUserBtn = styled(Btn)`
  color: ${Colors.Blue00};
  border: 1px solid ${Colors.Blue00};

  &:hover:not(:disabled) {
    background: #022e58;
    color: ${Colors.WhiteF9};
    transition: 0.3s ease-in-out;
  }
`;

const DownloadFormsBtn = styled(Btn)`
  color: ${Colors.Black31};
  border: 1px solid ${Colors.Black31};

  &:hover:not(:disabled) {
    background: ${Colors.Black31};
    color: ${Colors.WhiteF9};
    transition: 0.3s ease-in-out;
  }
`;

const RiskBtn = styled(Btn)`
  color: ${Colors.Black31};
  border: 1px solid ${Colors.Black31};

  &:hover:not(:disabled) {
    background: ${Colors.Black31};
    color: ${Colors.WhiteF9};
    transition: 0.3s ease-in-out;
  }
`;
