import { useEffect } from "react";
import { WebPaths } from "../routes/data";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import { userDataStore } from "../store/userData";
import { getEnumIndex } from "../views/web/components/data";
import useIsCurrentOrFreshForm from "./useIsCurrentOrFreshForm";
import useBasedOnFormPaid from "./useBasedOnFormPaid";

const useFormRouteGuard = (enumVal: any) => {
  const { basedOnFormPaid } = useBasedOnFormPaid();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const getType = searchParams.get("type");
  const { getIsCurrentForm } = useIsCurrentOrFreshForm();
  const { userData } = useSnapshot(userDataStore);
  const formProgress = userData?.currentFormInfo.formProgress;
  const lastProgress = getEnumIndex(enumVal, formProgress);

  //route guards
  useEffect(() => {
    const isCurrentOrFresh = getIsCurrentForm();

    //user can only go to form if they are filling it currently or if they are done paying
    //for ds 160 you can go to form if form completed
    //else redirect
    if (isCurrentOrFresh || basedOnFormPaid) return;
    navigate(WebPaths.Profile);
  }, [getIsCurrentForm, navigate, basedOnFormPaid]);

  return { getType, lastProgress };
};

export default useFormRouteGuard;
