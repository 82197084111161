export enum n600QueryKeys {
  getN600Eligibility = "get-N600-eligibility",
  getN600Files = "get-N600-files",
  getN600InfoAboutEligibility = "get-n600-info-about-eligibility",
  getN600InfoAboutYouPart1 = "get-n600-applicant-info-one",
  getN600InfoAboutYouPart2 = "get-n600-applicant-info-two",
  getN600InfoAboutYouPart3 = "get-n600-applicant-info-three",
  getN600InfoAboutYouPart4 = "get-n600-applicant-info-four",
  getN600BiographicInfo = "get-n600-applicant-bio-info",
  getN600InfoAboutUSCitizenFatherPart1 = "get-n600-applicant-father-one",
  getN600InfoAboutUSCitizenFatherPart2 = "get-n600-applicant-father-two",
  getN600InfoAboutUSCitizenMotherPart1 = "get-n600-applicant-mother-one",
  getN600InfoAboutUSCitizenMotherPart2 = "get-n600-applicant-mother-two",
  getN600PhysicalPresence = "get-n600-applicant-physical-presence",
  getN600MilitaryService = "get-n600-military-service",
  getN600Preparer = "get-n600-preparer-and-interpreter",
  getN600PaySuccess = "get-N600-pay-success",
  getN600PayFail = "get-N600-pay-fail",
  getN600Info = "get-N600-Info",
  getN600Trigger = "get-N600-trigger",
}
