import React, { FC } from "react";
import { truncateTxt } from "../../../../../../utils";
import { MdOutlineFileDownload } from "react-icons/md";
import { GetFileDetailObj } from "../../../../../../api/n400/types";

interface Props {
  data: GetFileDetailObj;
}

const FileDownload: FC<Props> = ({ data }) => {
  return (
    <div className="flex items-center gap-4 px-4 py-4 bg-[#0076E9] text-white h-max">
      <div className="text-sm capitalize">
        {truncateTxt(data.name || "", 15)}
      </div>
      <a href={data.url} download>
        <MdOutlineFileDownload
          className="cursor-pointer"
          width={16}
          height={16}
        />
      </a>
    </div>
  );
};

export default FileDownload;
