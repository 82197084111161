import React, { FC } from "react";
import InputField from "../../../components/formik fields/InputField";
import SelectField from "../../../components/formik fields/SelectField";
import { CheckGroupedWrapper, CheckWrapper } from "../styles";
import CheckBoxField from "../../../components/formik fields/CheckBoxField";
import DatePickerField from "../../../components/formik fields/DatePickerField";
import useStatesByCountry from "../../../components/useStatesByCountry";
import useCountries from "../../../components/useCountries";
import { FormikProps } from "formik";
import { WETPreviousFormData } from "./Previous";
import { saveDS160WETPreviousObj } from "../../../../../api/ds160/types";
import { ExplicitNGDateFormat } from "../../../components/data";

interface Props {
  data?: saveDS160WETPreviousObj;
  index: number;
  formik: FormikProps<WETPreviousFormData>;
}

const PrevEmployment: FC<Props> = ({ data, index, formik }) => {
  const savedCountry = data?.countryOrRegion.split(",")[index] || "";
  const { formattedCountryData, countryLoading } = useCountries();
  const { formattedStateData, stateLoading, setCountry, selectedCountry } =
    useStatesByCountry(savedCountry);

  return (
    <>
      <InputField
        label="Employer Name"
        placeholder=""
        name={`PrevEmpArray[${index}].employerName`}
      />
      <InputField
        label="Street Address Line 1"
        placeholder=""
        name={`PrevEmpArray[${index}].employerStreetAddressLine1`}
      />
      <InputField
        label="Street Address Line 2"
        coloredLabel="(optional)"
        placeholder=""
        name={`PrevEmpArray[${index}].employerStreetAddressLine2`}
      />
      <InputField
        label="City"
        placeholder=""
        name={`PrevEmpArray[${index}].city`}
      />
      <SelectField
        label="Country/Region"
        placeholder="-Select-"
        name={`PrevEmpArray[${index}].countryOrRegion`}
        options={formattedCountryData}
        isLoading={countryLoading}
        onChange={(value) => {
          setCountry(value.label);
          formik.setFieldValue(`PrevEmpArray[${index}].stateOrProvince`, null);
        }}
      />
      <SelectField
        label="State or Province"
        placeholder=""
        name={`PrevEmpArray[${index}].stateOrProvince`}
        options={formattedStateData}
        isLoading={stateLoading}
        isDisabled={!selectedCountry}
      />
      <InputField
        label="Postal Code/Zip Code"
        placeholder=""
        name={`PrevEmpArray[${index}].zipCode`}
      />
      <InputField
        label="Phone number"
        placeholder=""
        name={`PrevEmpArray[${index}].phoneNumber`}
      />
      <InputField
        label="Job title"
        placeholder=""
        name={`PrevEmpArray[${index}].jobTitle`}
      />
      <CheckGroupedWrapper>
        <InputField
          label="Supervisor's Surname"
          placeholder=""
          name={`PrevEmpArray[${index}].supervisorSurname`}
          isDisabled={!!formik.values.PrevEmpArray[index].doesNotApplySurname}
        />
        <CheckWrapper>
          <CheckBoxField
            label={`Does not apply`}
            name={`PrevEmpArray[${index}].doesNotApplySurname`}
            onChange={() => {
              formik.setFieldTouched(
                `PrevEmpArray[${index}].supervisorSurname`,
                false
              );
              formik.setFieldValue(
                `PrevEmpArray[${index}].supervisorSurname`,
                ""
              );
            }}
          />
        </CheckWrapper>
      </CheckGroupedWrapper>
      <CheckGroupedWrapper>
        <InputField
          label="Supervisor's Given Name"
          placeholder=""
          name={`PrevEmpArray[${index}].supervisorGivenNames`}
          isDisabled={
            !!formik.values.PrevEmpArray[index].doesNotApplyGivenNames
          }
        />

        <CheckWrapper>
          <CheckBoxField
            label={`Does not apply`}
            name={`PrevEmpArray[${index}].doesNotApplyGivenNames`}
            onChange={() => {
              formik.setFieldTouched(
                `PrevEmpArray[${index}].supervisorGivenNames`,
                false
              );
              formik.setFieldValue(
                `PrevEmpArray[${index}].supervisorGivenNames`,
                ""
              );
            }}
          />
        </CheckWrapper>
      </CheckGroupedWrapper>
      <DatePickerField
        name={`PrevEmpArray[${index}].employmentDateFrom`}
        label="Employment Date from"
        placeholder={ExplicitNGDateFormat}
        disableFuture={true}
      />
      <DatePickerField
        name={`PrevEmpArray[${index}].employmentDateTo`}
        label="Employment Date to"
        placeholder={ExplicitNGDateFormat}
        disableFuture={true}
      />
      <InputField
        label="Briefly Describe your duties."
        placeholder="Explanation..."
        name={`PrevEmpArray[${index}].dutiesDescription`}
        inputType="text-area"
      />
    </>
  );
};

export default PrevEmployment;
