import React from "react";
import { BoxWrapper, DashboardTitle, DashboardWrapper } from "../styles";
import CaseTable from "./components/CaseTable";

const Case = () => {
  return (
    <DashboardWrapper>
      <DashboardTitle>Case History</DashboardTitle>
      <BoxWrapper>
        <CaseTable />
      </BoxWrapper>
    </DashboardWrapper>
  );
};

export default Case;
