import React, { FC } from "react";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { N600InfoAboutUSCitizenMotherPart2Payload } from "../../../../../../api/n600/types";
import { FormN600Progress } from "../../types";

interface Props {
  title: string;
  data?: N600InfoAboutUSCitizenMotherPart2Payload;
}

const ReviewUSMotherInfoPart2: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      label:
        "Has your mother ever lost U.S. citizenship or taken any action that would cause loss of U.S. citizenship?",
      value: data?.motherLostCitizenship,
    },
    {
      label:
        "Explanation if mother lost U.S. citizenship or took any action that would cause loss of U.S. citizenship",
      value: data?.motherLostCitizenshipExplanation,
    },
    {
      label: "How many times has your U.S. citizen mother been married?",
      value: data?.motherNumberOfMarriages,
    },
    {
      label: "What is your U.S. citizen mother's current marital status?",
      value: data?.motherCurrentMaritalStatus,
    },
    {
      label: "Explanation if mother's current marital status requires it",
      value: data?.motherCurrentMaritalStatusExplanation,
    },
    {
      label: "Mother's Spouse Family Name (Last Name)",
      value: data?.motherSpouseFamilyName,
    },
    {
      label: "Mother's Spouse Given Name (First Name)",
      value: data?.motherSpouseGivenName,
    },
    {
      label: "Mother's Spouse Middle Name",
      value: data?.motherSpouseMiddleName,
    },
    {
      label: "Mother's Spouse Date of Birth",
      value: data?.motherSpouseDateOfBirth,
    },
    {
      label: "Mother's Spouse Country of Birth",
      value: data?.motherSpouseCountryOfBirth,
    },
    {
      label: "Mother's Spouse Country of Citizenship",
      value: data?.motherSpouseCountryOfCitizenship,
    },
    {
      label: "Mother's Spouse Physical Address Street Number and Name",
      value: data?.motherSpousePhysicalAddressStreetNumberAndName,
    },
    {
      label: "Mother's Spouse Physical Address Apt. Ste. Flr.",
      value: data?.motherSpousePhysicalAddressAptSteFlr,
    },
    {
      label: "Mother's Spouse Physical Address Apt. Ste. Flr. Number",
      value: data?.motherSpousePhysicalAddressAptSteFlrNumber,
    },
    {
      label: "Mother's Spouse Physical Address City or Town",
      value: data?.motherSpousePhysicalAddressCityOrTown,
    },
    {
      label: "Mother's Spouse Physical Address State",
      value: data?.motherSpousePhysicalAddressState,
    },
    {
      label: "Mother's Spouse Physical Address ZIP Code",
      value: data?.motherSpousePhysicalAddressZipCode,
    },
    {
      label: "Mother's Spouse Physical Address Province (foreign address only)",
      value: data?.motherSpousePhysicalAddressProvince,
    },
    {
      label:
        "Mother's Spouse Physical Address Postal Code (foreign address only)",
      value: data?.motherSpousePhysicalAddressPostalCode,
    },
    {
      label: "Mother's Spouse Physical Address Country (foreign address only)",
      value: data?.motherSpousePhysicalAddressCountry,
    },
    {
      label: "Date of Marriage",
      value: data?.motherDateOfMarriage,
    },
    {
      label: "Place of Marriage City or Town",
      value: data?.motherPlaceOfMarriageCityOrTown,
    },
    {
      label: "Place of Marriage State",
      value: data?.motherPlaceOfMarriageState,
    },
    {
      label: "Place of Marriage Country",
      value: data?.motherPlaceOfMarriageCountry,
    },
    {
      label: "Mother's Spouse Immigration Status",
      value: data?.motherSpouseImmigrationStatus,
    },
    {
      label: "Explanation if spouse's immigration status requires it",
      value: data?.motherSpouseImmigrationStatusExplanation,
    },
    {
      label:
        "Is your U.S. citizen mother's current spouse also your biological (or adopted) father?",
      value: data?.isMotherCurrentSpouseBiologicalOrAdoptiveFather,
    },
  ];

  return (
    <ReviewBox
      title={title}
      data={section}
      editUrl={FormN600Progress.infoAboutUSCitizenMotherP2}
    />
  );
};

export default ReviewUSMotherInfoPart2;
