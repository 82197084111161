import React from "react";
import styled from "styled-components";
import { FieldArray, Form, Formik } from "formik";
import Colors from "../../../../styles/Colors";
import InputField from "../../components/formik fields/InputField";
import { n565TypeOfApplicationObj } from "../../../../api/n565/types";
import {
  useGetN565ApplicationType,
  useSaveN565ApplicationType,
} from "../../../../hooks/n565/useN565";
import FormBtn from "../../components/FormBtn";
import { TypeOfApplicationValidation } from "./validation";
import { Modify, SingleOption } from "../../../../types/types";
import {
  ApartmentSuiteFloorData,
  ToSaveDate,
  initDate,
  initInput,
  initSelect,
  isZipCode,
} from "../../components/data";
import { Dayjs } from "dayjs";
import useCountries from "../../components/useCountries";
import useToNextSection from "../../../../hooks/useToNextSection";
import { FormWrapper, RadioLabel, RadioWrapper } from "../../components/styles";
import RadioComp from "../../components/RadioComp";
import FormSectionWrapper from "../../components/FormSectionWrapper";
import {
  N565TypeOfApplicationData,
  N565TypeOfApplicationDataValue,
} from "./data";
import useStatesByCountry from "../../components/useStatesByCountry";
import Part4 from "./Basis of application/Part4";
import Part5 from "./Basis of application/Part5";
import Part6 from "./Basis of application/Part6";
import Part8 from "./Basis of application/Part8";
import { Colored } from "../../form 400/components/styles";
import CheckBoxField from "../../components/formik fields/CheckBoxField";
import Part7 from "./Basis of application/Part7";

export interface TypeOfTypoErrorPayload {
  name: boolean;
  dateOfBirth: boolean;
  gender: boolean;
  other: boolean;
}

export interface DateOfBirthChangedThroughPayload {
  courtOrder: boolean;
  usGovIssuedDoc: boolean;
}

export interface BasisForApplicationArrayPayload {
  lostStolenOrDestroyed: boolean;
  mutilated: boolean;
  typoError: boolean;
  nameLegallyChanged: boolean;
  dateOfBirthLegallyChanged: boolean;
  changeGender: boolean;
  reasonNotListed: boolean;
}

export interface GenderChangedThroughPayload {
  courtOrder: boolean;
  govIssuedDoc: boolean;
  licensedHealthCareCertification: boolean;
}

const initialTypeOfTypoErrorArray = {
  name: false,
  dateOfBirth: false,
  gender: false,
  other: false,
};

const initialDateOfBirthChangedThrough = {
  courtOrder: false,
  usGovIssuedDoc: false,
};

const initialGenderChangedThrough = {
  courtOrder: false,
  govIssuedDoc: false,
  licensedHealthCareCertification: false,
};

const initialBasisForApplicationArray = {
  lostStolenOrDestroyed: false,
  mutilated: false,
  typoError: false,
  nameLegallyChanged: false,
  dateOfBirthLegallyChanged: false,
  changeGender: false,
  reasonNotListed: false,
};

export interface TypeOfApplicationFormData
  extends Modify<
    Omit<n565TypeOfApplicationObj, "foreignZipCode" | "foreignPostalCode">,
    {
      nameOfForeignCountry: SingleOption | null;
      foreignStateOrTerritory: SingleOption | null;
      foreignApartmentSuiteOrFloor: SingleOption | null;
      foreignCountry: SingleOption | null;
      foreignAddressCode: string;
      nameChangedDateOfEvent: Dayjs | null;
      nameChangedDateOfCourtOrder: Dayjs | null;
      dateOfBirthChangedDateOfCourtOrder: Dayjs | null;
      dateOfBirthChangedUsGovIssuedDoc: Dayjs | null;
      newDateOfBirth: Dayjs | null;
    }
  > {}

const TypeOfApplication = () => {
  const { data, isFetching, isError } = useGetN565ApplicationType();
  const { mutate, isLoading: isSaving } = useSaveN565ApplicationType();
  const { formattedCountryData, countryLoading } = useCountries();
  const { toNextSection } = useToNextSection();
  const { formattedStateData, stateLoading, setCountry, selectedCountry } =
    useStatesByCountry(data?.foreignCountry);

  const onSubmit = (values: TypeOfApplicationFormData) => {
    const {
      nameOfForeignCountry,
      foreignCountry,
      foreignApartmentSuiteOrFloor,
      foreignStateOrTerritory,
      foreignAddressCode,
      nameChangedDateOfEvent,
      nameChangedDateOfCourtOrder,
      dateOfBirthChangedDateOfCourtOrder,
      dateOfBirthChangedUsGovIssuedDoc,
      newDateOfBirth,
      ...rest
    } = values;

    const payload = {
      ...rest,
      foreignApartmentSuiteOrFloor: foreignApartmentSuiteOrFloor?.value || "",
      foreignCountry: foreignCountry?.value || "",
      foreignStateOrTerritory: foreignStateOrTerritory?.value || "",
      nameOfForeignCountry: nameOfForeignCountry?.value || "",
      foreignPostalCode: !isZipCode(foreignCountry?.value)
        ? foreignAddressCode
        : "",
      foreignZipCode: isZipCode(foreignCountry?.value)
        ? foreignAddressCode
        : "",
      nameChangedDateOfEvent: ToSaveDate(nameChangedDateOfEvent),
      nameChangedDateOfCourtOrder: ToSaveDate(nameChangedDateOfCourtOrder),
      dateOfBirthChangedDateOfCourtOrder: ToSaveDate(
        dateOfBirthChangedDateOfCourtOrder
      ),
      dateOfBirthChangedUsGovIssuedDoc: ToSaveDate(
        dateOfBirthChangedUsGovIssuedDoc
      ),
      newDateOfBirth: ToSaveDate(newDateOfBirth),
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValues: TypeOfApplicationFormData = {
    typeOfApplication: initInput(data?.typeOfApplication),
    //part 8
    nameOfForeignCountry: initSelect(
      formattedStateData,
      data?.nameOfForeignCountry
    ),
    foreignLastName: initInput(data?.foreignLastName),
    foreignFirstName: initInput(data?.foreignFirstName),
    foreignMiddleName: initInput(data?.foreignMiddleName),
    officialTitle: initInput(data?.officialTitle),
    nameOfGovernmentAgency: initInput(data?.nameOfGovernmentAgency),
    foreignStreetNumberAndName: initInput(data?.foreignStreetNumberAndName),
    foreignCityOrTown: initInput(data?.foreignCityOrTown),
    foreignApartmentSuiteOrFloor: initSelect(
      ApartmentSuiteFloorData,
      data?.foreignApartmentSuiteOrFloor
    ),
    foreignAddressNumber: initInput(data?.foreignAddressNumber),
    foreignCountry: initSelect(formattedCountryData, data?.foreignCountry),
    foreignStateOrTerritory: initSelect(
      formattedStateData,
      data?.foreignStateOrTerritory
    ),
    foreignAddressCode: isZipCode(data?.foreignCountry)
      ? initInput(data?.foreignZipCode)
      : initInput(data?.foreignPostalCode),
    foreignProvince: initInput(data?.foreignProvince),
    //
    basisForApplicationArray: data?.basisForApplicationArray
      ? data?.basisForApplicationArray
      : [initialBasisForApplicationArray],
    lostStolenOrDestroyedExplanation: initInput(
      data?.lostStolenOrDestroyedExplanation
    ),
    //part 4
    typeOfTypoErrorArray: data?.typeOfTypoErrorArray
      ? data?.typeOfTypoErrorArray
      : [initialTypeOfTypoErrorArray],
    explanationOfTypoError: initInput(data?.explanationOfTypoError),
    //part 5
    nameChangedThrough: initInput(data?.nameChangedThrough),
    nameChangedDateOfEvent: initDate(data?.nameChangedDateOfEvent),
    nameChangedDateOfCourtOrder: initDate(data?.nameChangedDateOfCourtOrder),
    newLegalLastName: initInput(data?.newLegalLastName),
    newLegalFirstName: initInput(data?.newLegalFirstName),
    newLegalMiddleName: initInput(data?.newLegalMiddleName),
    //part 6
    dateOfBirthChangedThroughArray: data?.dateOfBirthChangedThroughArray
      ? data?.dateOfBirthChangedThroughArray
      : [initialDateOfBirthChangedThrough],
    dateOfBirthChangedDateOfCourtOrder: initDate(
      data?.dateOfBirthChangedDateOfCourtOrder
    ),
    dateOfBirthChangedUsGovIssuedDoc: initDate(
      data?.dateOfBirthChangedUsGovIssuedDoc
    ),
    newDateOfBirth: initDate(data?.newDateOfBirth),
    //part 7
    genderChangedThroughArray: data?.genderChangedThroughArray
      ? data?.genderChangedThroughArray
      : [initialGenderChangedThrough],
    genderChangedThroughCurrentGender: initInput(
      data?.genderChangedThroughCurrentGender
    ),
    //
    explanationForNewReason: initInput(data?.explanationForNewReason),
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={TypeOfApplicationValidation}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form className="flex flex-col gap-4">
              <FormWrapper>
                <RadioComp
                  title="I am applying for a"
                  coloredLabel="(select only one box):"
                  name="typeOfApplication"
                  options={N565TypeOfApplicationData}
                  column
                />

                <div></div>
              </FormWrapper>

              <Part8
                formik={formik}
                formattedCountryData={formattedCountryData}
                formattedStateData={formattedStateData}
                countryLoading={countryLoading}
                stateLoading={stateLoading}
                setCountry={setCountry}
                selectedCountry={selectedCountry}
              />

              {formik.values.typeOfApplication &&
                formik.values.typeOfApplication !==
                  N565TypeOfApplicationDataValue.recognition && (
                  <div className="flex flex-col gap-6">
                    <Title>Basis for My Application</Title>
                    <RadioWrapper>
                      <RadioLabel>
                        <Colored>Select all applicable boxes </Colored>
                        and provide explanations where requested.{" "}
                      </RadioLabel>
                      <FieldArray
                        name="basisForApplicationArray"
                        render={(arrayHelpers) => (
                          <>
                            {formik.values.basisForApplicationArray.map(
                              (_, index) => (
                                <CheckWrap key={index}>
                                  <CheckBoxField
                                    value={
                                      "My certificate or declaration was lost, stolen, or destroyed."
                                    }
                                    name={`basisForApplicationArray[${index}].lostStolenOrDestroyed`}
                                    label={
                                      "My certificate or declaration was lost, stolen, or destroyed."
                                    }
                                  />
                                  <CheckBoxField
                                    value={
                                      "My certificate or declaration is mutilated."
                                    }
                                    name={`basisForApplicationArray[${index}].mutilated`}
                                    label={
                                      "My certificate or declaration is mutilated."
                                    }
                                  />
                                  <CheckBoxField
                                    value={
                                      "My certificate or declaration is incorrect due to a typographical or clerical error by USCIS."
                                    }
                                    name={`basisForApplicationArray[${index}].typoError`}
                                    label={
                                      "My certificate or declaration is incorrect due to a typographical or clerical error by USCIS."
                                    }
                                  />

                                  <CheckBoxField
                                    value={"My name has legally changed."}
                                    name={`basisForApplicationArray[${index}].nameLegallyChanged`}
                                    label={"My name has legally changed."}
                                  />

                                  <CheckBoxField
                                    value={
                                      "My date of birth has legally changed through a court order or U.S. Government-issued document, and I am applying for a replacement Certificate of Citizenship."
                                    }
                                    name={`basisForApplicationArray[${index}].dateOfBirthLegallyChanged`}
                                    label={
                                      "My date of birth has legally changed through a court order or U.S. Government-issued document, and I am applying for a replacement Certificate of Citizenship."
                                    }
                                  />

                                  <CheckBoxField
                                    value={
                                      "I am seeking to change the gender listed on my document."
                                    }
                                    name={`basisForApplicationArray[${index}].changeGender`}
                                    label={
                                      "I am seeking to change the gender listed on my document."
                                    }
                                  />

                                  <CheckBoxField
                                    value={
                                      "My reason for applying for a new document is not listed above."
                                    }
                                    name={`basisForApplicationArray[${index}].reasonNotListed`}
                                    label={
                                      "My reason for applying for a new document is not listed above."
                                    }
                                  />
                                </CheckWrap>
                              )
                            )}
                          </>
                        )}
                      />
                    </RadioWrapper>

                    {formik.values.basisForApplicationArray[0]
                      .lostStolenOrDestroyed && (
                      <>
                        <FormWrapper>
                          <InputField
                            name="lostStolenOrDestroyedExplanation"
                            label="Provide an explanation of when, where, and how this happened"
                            placeholder=""
                            inputType="text-area"
                          />

                          <div></div>
                        </FormWrapper>
                      </>
                    )}

                    <Part4 formik={formik} />
                    <Part5 formik={formik} />
                    <Part6 formik={formik} />
                    <Part7 formik={formik} />

                    <>
                      {formik.values.basisForApplicationArray[0]
                        .reasonNotListed && (
                        <>
                          <FormWrapper>
                            <InputField
                              name="explanationForNewReason"
                              label="Provide an explanation"
                              coloredLabel="(My reason for applying for a new document is not listed above)"
                              placeholder=""
                              inputType="text-area"
                            />
                            <div></div>
                          </FormWrapper>
                        </>
                      )}
                    </>
                  </div>
                )}

              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default TypeOfApplication;

const Wrapper = styled.div``;

export const Title = styled.div`
  color: ${Colors.Blue00};
  font-weight: bold;
`;

export const CheckWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
