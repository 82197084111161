import { CheckIcon } from "@heroicons/react/24/solid";
import React from "react";

const includedFeatures = [
  "Pay only for what you use",
  "No long-term commitment",
];

const PerApplicationBilling = () => {
  return (
    <div className="">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl bg-white/20 ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-3xl font-semibold tracking-tight text-gray-900">
              Pay Per Application
            </h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
              This plan suits businesses that prefer to pay per application,
              offering flexible pricing based on the number of applications and
              support level needed.
            </p>
            <div className="mt-4 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-Blue00">
                What's included
              </h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul className="mt-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6">
              {includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3 my-3">
                  <CheckIcon
                    aria-hidden="true"
                    className="h-6 w-5 flex-none text-Blue00"
                  />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">
                  Pay for each application
                </p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-semibold tracking-tight text-gray-900">
                    $49
                  </span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                    USD
                  </span>
                </p>
                <a
                  href="https://www.google.com"
                  className="mt-10 block w-full rounded-md bg-Blue00 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-Blue00 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-Blue00"
                >
                  Get access
                </a>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  Invoices and receipts available for easy company reimbursement
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerApplicationBilling;
