import React, { useState } from "react";
import styled from "styled-components";
import Colors from "../../../../styles/Colors";
import AiDisclaimer from "../../components/AiDisclaimer";
import { HiOutlineSparkles } from "react-icons/hi";
import { useGetChatGptDs160Assessment } from "../../../../hooks/ds160/usDS160";
import { SpinnerWrapper } from "../../components/styles";
import Spinner from "../../../../utils/Spinner";
import Markdown from "markdown-to-jsx";
import { IoClose } from "react-icons/io5";
import { useSearchParams } from "react-router-dom";
import { BusinessQueryKey } from "../../../../api/utils/types";

const FibiSidebar = () => {
  const [searchParams] = useSearchParams();
  const getApplicationId = searchParams.get(BusinessQueryKey.appId);
  const { data, isFetching } = useGetChatGptDs160Assessment(
    getApplicationId || ""
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Wrapper className="border border-gray-100 shadow-sm" isOpen={isOpen}>
      <Toggle isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? (
          <IoClose />
        ) : (
          <h5 className="text-white whitespace-nowrap"> FiBi Review</h5>
        )}
      </Toggle>
      <Header>
        <h5>FiBi Review</h5>
        <HiOutlineSparkles size={25} />
      </Header>

      <Content>
        {isFetching ? (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        ) : !data || !data.result ? (
          <div className="flex items-center justify-center h-full">
            No Fibi Review v2 available yet.
          </div>
        ) : (
          <div className="">
            <Markdown>{data.result.replace(/\n/gi, "&nbsp; \n\n")}</Markdown>
          </div>
        )}
      </Content>

      <div className="px-4  border-t-[2px] border-[#0076E91A]">
        <AiDisclaimer />
      </div>
    </Wrapper>
  );
};

export default FibiSidebar;

interface WrapperProps {
  isOpen: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  border-radius: 5px;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 20px;
  height: calc(100vh - 100px);
  background-color: white;

  @media screen and (max-width: 767px) {
    position: fixed;
    top: 90px;
    right: 0;
    width: 80vw;
    height: calc(100vh - 100px);
    /* padding-left: 24px; */
    transition: 300ms all ease;
    transform: ${({ isOpen }) =>
      isOpen ? "translateX(-10px)" : "translateX(100%)"};
    z-index: ${({ isOpen }) => (isOpen ? "1000" : "")};
  }
`;

const Toggle = styled.button<{ isOpen: boolean }>`
  width: max-content;
  border-radius: 8px 0px 0px 8px;
  padding: 0.6rem;
  background-color: ${Colors.Blue00};
  display: grid;
  place-content: center;
  position: fixed !important;
  right: 100%;
  top: 24px;
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0.5")};
  transition: 300ms all ease;

  &:hover {
    opacity: 1;
  }

  & > svg {
    height: 32px;
    width: 32px;
    transition: 300ms all ease;
    color: white;
  }
`;

const Content = styled.div`
  height: 100%;
  padding: 1rem;
  overflow-y: auto;
`;

const Header = styled.div`
  padding: 26px;
  background-color: ${Colors.Blue00};
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  color: white;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;

  & > h5 {
    font-weight: 600;
    font-size: 1.25rem;
  }
`;
