import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createBusinessApplication,
  getBusinessApplications,
  getSingleBusinessApplication,
  getTotalCases,
  getTotalCompletedCases,
  getTotalPaidCases,
  getTotalPendingCases,
} from "../../api/business/applications";
import { parseError } from "../../utils";
import { businessApplicationsQueryKeys } from "./types";
import { GetBusinessApplicationsQueries } from "../../api/business/applications/types";

export const useCreateBusinessApplication = () => {
  const queryClient = useQueryClient();

  return useMutation(createBusinessApplication, {
    onError: (error) => parseError(error),
    onSuccess: () =>
      queryClient.invalidateQueries([
        businessApplicationsQueryKeys.getBusinessApplications,
      ]),
  });
};

export const useGetBusinessApplications = (
  queries: GetBusinessApplicationsQueries
) => {
  return useQuery(
    [businessApplicationsQueryKeys.getBusinessApplications, { ...queries }],
    () => getBusinessApplications(queries),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data,
    }
  );
};

export const useGetSingleBusinessApplication = (
  applicationId: string,
  isEnabled?: boolean
) => {
  return useQuery(
    [businessApplicationsQueryKeys.getSingleBusinessApplication, applicationId],
    () => getSingleBusinessApplication(applicationId),
    {
      enabled: isEnabled,
      onError: (error) => parseError(error),
      select: (data) => data.data.applicationData,
    }
  );
};

export const useGetTotalCases = () => {
  return useQuery(
    [businessApplicationsQueryKeys.getTotalCases],
    getTotalCases,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.total,
    }
  );
};

export const useGetTotalPaidCases = () => {
  return useQuery(
    [businessApplicationsQueryKeys.getTotalPaidCases],
    getTotalPaidCases,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.total,
    }
  );
};

export const useGetTotalPendingCases = () => {
  return useQuery(
    [businessApplicationsQueryKeys.getTotalPendingCases],
    getTotalPendingCases,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.total,
    }
  );
};

export const useGetTotalCompletedCases = () => {
  return useQuery(
    [businessApplicationsQueryKeys.getTotalCompletedCases],
    getTotalCompletedCases,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.total,
    }
  );
};
