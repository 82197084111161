import React, { useState } from "react";
import EligibleConfirmation from "./components/EligibleConfirmation";
import { useParams } from "react-router-dom";
import CheckEligibilityQuestions from "./components/CheckEligibilityQuestions";
import { checkFormQuestions } from "./components/data";

const CheckEligibility = () => {
  const [first, setfirst] = useState(true);
  const { formType } = useParams();

  const onChangeState = (value: boolean) => {
    setfirst(value);
  };

  return (
    <>
      {first ? (
        <EligibleConfirmation onChangeState={onChangeState} />
      ) : (
        formType && (
          <CheckEligibilityQuestions
            onChangeState={onChangeState}
            questions={checkFormQuestions[formType]}
          />
        )
      )}
    </>
  );
};

export default CheckEligibility;
