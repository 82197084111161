import PhotoRequirement from "../../../../../form ds 160/components/PhotoRequirement";
import { DocChecklistProps } from "./types";

export const ds160CommonDocChecklist: DocChecklistProps[] = [
  {
    name: "ds160_passport_photography",
    label: "Passport Photography",
    info: <PhotoRequirement />,
  },
  {
    name: "ds160_valid_international_passport_data_page",
    label: "Valid International Passport Data Page",
  },
  {
    name: "ds160_passport_data_page_with_other_visa_page",
    label: "Passport Data Page with other Visas page",
  },
  {
    name: "ds160_proof_of_fund",
    label: "Proof of Fund",
    optional: true,
  },
  {
    name: "ds160_resume",
    label: "Resume: Employment History",
    optional: true,
  },
];

export const ds160StudentDocChecklist: DocChecklistProps[] = [
  {
    name: "ds160_latest_i20_doc",
    label: "Latest I-20 documents",
  },
  {
    name: "ds160_admission_letter",
    label: "Admission letter",
    info: "if you have funding or scholarships please include documents",
  },
];

export const ds160TourismBusinessDocChecklist: DocChecklistProps[] = [
  {
    name: "ds160_conference_or_business_related_doc",
    label: "Conference or business related documents",
    optional: true,
  },
];
