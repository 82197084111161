import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { downloadPdf, parseError } from "../../utils";
import { n400QueryKeys } from "./types";
import {
  getN400Accommodation,
  getN400AdditionalInfo,
  getN400AddressHistory,
  getN400ApplicantInfo1,
  getN400ApplicantInfo2,
  getN400BioInfo,
  getN400CheckPart1,
  getN400CheckPart2,
  getN400CheckPart3,
  getN400CheckPart4,
  getN400CheckPart5,
  getN400CheckPart6,
  getN400ChildrenInfo,
  getN400CivicTestExemption,
  getN400Eligibility,
  getN400EmploymentHistory,
  getN400EnglishTestExemption,
  getN400FeeReduction,
  getN400Files,
  getN400Form,
  getN400Info,
  getN400MaritalHistory,
  getN400PayFail,
  getN400PaySuccess,
  getN400Preparer,
  getN400TimeOutsideUs,
  getN400Trigger,
  payN400,
  saveN400Accommodation,
  saveN400AdditionalInfo,
  saveN400AddressHistory,
  saveN400ApplicantInfo1,
  saveN400ApplicantInfo2,
  saveN400BioInfo,
  saveN400CheckPart1,
  saveN400CheckPart2,
  saveN400CheckPart3,
  saveN400CheckPart4,
  saveN400CheckPart5,
  saveN400CheckPart6,
  saveN400ChildrenInfo,
  saveN400CivicTestExemption,
  saveN400Eligibility,
  saveN400EmploymentHistory,
  saveN400EnglishTestExemption,
  saveN400FeeReduction,
  saveN400FilingInfo,
  saveN400MaritalHistory,
  saveN400Preparer,
  saveN400TimeOutsideUS,
  updateN400Files,
  uploadN400Files,
} from "../../api/n400";
import { utilsQueryKeys } from "../utils/types";

export const useSaveN400TimeOutsideUS = () => {
  return useMutation(saveN400TimeOutsideUS, {
    onError: (error) => parseError(error),
  });
};

export const useGetN400TimeOutsideUS = () => {
  return useQuery([n400QueryKeys.getN400TimeOutsideUs], getN400TimeOutsideUs, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useSaveN400ApplicantInfo1 = () => {
  return useMutation(saveN400ApplicantInfo1, {
    onError: (error) => parseError(error),
  });
};

export const useGetN400ApplicantInfo1 = () => {
  return useQuery(
    [n400QueryKeys.getN400ApplicantInfo1],
    getN400ApplicantInfo1,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveN400ApplicantInfo2 = () => {
  return useMutation(saveN400ApplicantInfo2, {
    onError: (error) => parseError(error),
  });
};

export const useGetN400ApplicantInfo2 = () => {
  return useQuery(
    [n400QueryKeys.getN400ApplicantInfo2],
    getN400ApplicantInfo2,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveN400BioInfo = () => {
  return useMutation(saveN400BioInfo, {
    onError: (error) => parseError(error),
  });
};

export const useGetN400BioInfo = () => {
  return useQuery([n400QueryKeys.getN400BioInfo], getN400BioInfo, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useSaveN400Accommodation = () => {
  return useMutation(saveN400Accommodation, {
    onError: (error) => parseError(error),
  });
};

export const useGetN400Accommodation = () => {
  return useQuery([n400QueryKeys.getN400Accommodation], getN400Accommodation, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useSaveN400CivicTestExemption = () => {
  return useMutation(saveN400CivicTestExemption, {
    onError: (error) => parseError(error),
  });
};

export const useGetN400CivicTestExemption = () => {
  return useQuery(
    [n400QueryKeys.getN400CivicTestExemption],
    getN400CivicTestExemption,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveN400EnglishTestExemption = () => {
  return useMutation(saveN400EnglishTestExemption, {
    onError: (error) => parseError(error),
  });
};

export const useGetN400EnglishTestExemption = () => {
  return useQuery(
    [n400QueryKeys.getN400EnglishTestExemption],
    getN400EnglishTestExemption,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveN400AdditionalInfo = () => {
  return useMutation(saveN400AdditionalInfo, {
    onError: (error) => parseError(error),
  });
};

export const useGetN400AdditionalInfo = () => {
  return useQuery(
    [n400QueryKeys.getN400AdditionalInfo],
    getN400AdditionalInfo,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveN400AddressHistory = () => {
  return useMutation(saveN400AddressHistory, {
    onError: (error) => parseError(error),
  });
};

export const useGetN400AddressHistory = () => {
  return useQuery(
    [n400QueryKeys.getN400AddressHistory],
    getN400AddressHistory,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveN400EmploymentHistory = () => {
  return useMutation(saveN400EmploymentHistory, {
    onError: (error) => parseError(error),
  });
};

export const useGetN400EmploymentHistory = () => {
  return useQuery(
    [n400QueryKeys.getN400EmploymentHistory],
    getN400EmploymentHistory,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveN400MaritalHistory = () => {
  return useMutation(saveN400MaritalHistory, {
    onError: (error) => parseError(error),
  });
};

export const useGetN400MaritalHistory = () => {
  return useQuery(
    [n400QueryKeys.getN400MaritalHistory],
    getN400MaritalHistory,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSaveN400ChildrenInfo = () => {
  return useMutation(saveN400ChildrenInfo, {
    onError: (error) => parseError(error),
  });
};

export const useGetN400ChildrenInfo = () => {
  return useQuery([n400QueryKeys.getN400ChildrenInfo], getN400ChildrenInfo, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

// export const useSaveN400Checlist = () => {
//   return useMutation(saveN400Checklist, {
//     onError: (error) => parseError(error),
//   });
// };

export const useSaveN400CheckPart1 = () => {
  return useMutation(saveN400CheckPart1, {
    onError: (error) => parseError(error),
  });
};

export const useSaveN400CheckPart2 = () => {
  return useMutation(saveN400CheckPart2, {
    onError: (error) => parseError(error),
  });
};

export const useSaveN400CheckPart3 = () => {
  return useMutation(saveN400CheckPart3, {
    onError: (error) => parseError(error),
  });
};

export const useSaveN400CheckPart4 = () => {
  return useMutation(saveN400CheckPart4, {
    onError: (error) => parseError(error),
  });
};

export const useSaveN400CheckPart5 = () => {
  return useMutation(saveN400CheckPart5, {
    onError: (error) => parseError(error),
  });
};

export const useSaveN400CheckPart6 = () => {
  return useMutation(saveN400CheckPart6, {
    onError: (error) => parseError(error),
  });
};

export const useSaveN400FeeReduction = () => {
  return useMutation(saveN400FeeReduction, {
    onError: (error) => parseError(error),
  });
};

export const useGetN400FeeReduction = () => {
  return useQuery([n400QueryKeys.getN400FeeReduction], getN400FeeReduction, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

// export const useSaveN400CheclistPart2 = () => {
//   return useMutation(saveN400ChecklistPart2, {
//     onError: (error) => parseError(error),
//   });
// };

// export const useGetN400Checklist = () => {
//   return useQuery([n400QueryKeys.getN400Checklist], getN400Checklist, {
//     onError: (error) => parseError(error),
//     select: (data) => data.data.data,
//     refetchOnWindowFocus: false,
//   });
// };

export const useGetN400CheckPart1 = () => {
  return useQuery([n400QueryKeys.getN400CheckPart1], getN400CheckPart1, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useGetN400CheckPart2 = () => {
  return useQuery([n400QueryKeys.getN400CheckPart2], getN400CheckPart2, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useGetN400CheckPart3 = () => {
  return useQuery([n400QueryKeys.getN400CheckPart3], getN400CheckPart3, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useGetN400CheckPart4 = () => {
  return useQuery([n400QueryKeys.getN400CheckPart4], getN400CheckPart4, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useGetN400CheckPart5 = () => {
  return useQuery([n400QueryKeys.getN400CheckPart5], getN400CheckPart5, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useGetN400CheckPart6 = () => {
  return useQuery([n400QueryKeys.getN400CheckPart6], getN400CheckPart6, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useSaveN400Preparer = () => {
  return useMutation(saveN400Preparer, {
    onError: (error) => parseError(error),
  });
};

export const useGetN400Preparer = () => {
  return useQuery([n400QueryKeys.getN400Preparer], getN400Preparer, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useGetN400Form = (isEnabled?: boolean) => {
  return useQuery([n400QueryKeys.getN400Form], getN400Form, {
    enabled: isEnabled,
    onError: (error) => parseError(error),
    onSuccess: (data) => {
      downloadPdf(data.data.fileBuffer.data, `COMPLETED-N400-FORM.pdf`);
    },
    // select: (data) => data.data.fileBuffer.data,
    // refetchOnWindowFocus: false,
  });
};

export const useGetN400Info = () => {
  return useQuery([n400QueryKeys.getN400Info], getN400Info, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const usePayN400 = () => {
  return useMutation(payN400, {
    onError: (error) => parseError(error),
  });
};

export const useGetN400PaySuccess = (payId: string) => {
  const queryClient = useQueryClient();

  return useQuery(
    [n400QueryKeys.getN400PaySuccess, payId],
    () => getN400PaySuccess(payId),
    {
      enabled: !!payId,
      onSuccess: () =>
        queryClient.invalidateQueries([utilsQueryKeys.getUserData]),
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetN400PayFail = () => {
  const queryClient = useQueryClient();

  return useQuery([n400QueryKeys.getN400PayFail], getN400PayFail, {
    onSuccess: () =>
      queryClient.invalidateQueries([utilsQueryKeys.getUserData]),
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useSaveN400Eligibility = () => {
  const queryClient = useQueryClient();

  return useMutation(saveN400Eligibility, {
    onError: (error) => parseError(error),
    onSuccess: () =>
      queryClient.invalidateQueries([utilsQueryKeys.getUserData]),
  });
};

export const useGetN400Eligibility = () => {
  return useQuery([n400QueryKeys.getN400Eligibility], getN400Eligibility, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};

export const useUploadN400Files = () => {
  const queryClient = useQueryClient();

  return useMutation(uploadN400Files, {
    onError: (error) => parseError(error),
    onSuccess: () =>
      queryClient.invalidateQueries([n400QueryKeys.getN400Files]),
  });
};

export const useGetN400Files = () => {
  return useQuery([n400QueryKeys.getN400Files], getN400Files, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    // refetchOnWindowFocus: false,
  });
};

export const useSaveN400FilingInfo = () => {
  const queryClient = useQueryClient();

  return useMutation(saveN400FilingInfo, {
    onError: (error) => parseError(error),
    onSuccess: () =>
      queryClient.invalidateQueries([n400QueryKeys.getN400Eligibility]),
  });
};

export const useUpdateN400Files = () => {
  const queryClient = useQueryClient();

  return useMutation(updateN400Files, {
    onError: (error) => parseError(error),
    onSuccess: () =>
      queryClient.invalidateQueries([n400QueryKeys.getN400Files]),
  });
};

export const useGetN400Trigger = (isEnabled: boolean) => {
  return useQuery([n400QueryKeys.getN400Trigger], getN400Trigger, {
    enabled: isEnabled,
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: false,
  });
};
