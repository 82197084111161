import React, { useState } from "react";
import DocChecklistItem from "../DocChecklistItem";
import { toast } from "react-toastify";
import RequestWrapper from "../../../../../components/RequestWrapper";
import { UpdateN400FilesPayload } from "../../../../../../../api/n400/types";
import { useSearchParams } from "react-router-dom";
import { n600DocChecklist } from "../data/n600Data";
import {
  useGetN600Files,
  useUpdateN600Files,
  useUploadN600Files,
} from "../../../../../../../hooks/n600/useN600";
import AdditionalDocItem from "./AdditionalDocItem";
import AdditionalDoc from "../AdditionalDoc";

const N600DocChecklist = () => {
  const [searchParams] = useSearchParams();
  const [loadingField, setLoading] = useState("");
  const getType = searchParams.get("form");
  const { data, isLoading: isGetting, isError } = useGetN600Files();
  const { mutate, isLoading } = useUploadN600Files();
  const { mutate: updateMutate, isLoading: isUpdating } = useUpdateN600Files();

  const onSubmit = (
    params: File,
    name: string,
    fileId?: string,
    onSuccessful?: () => void
  ) => {
    const payload = new FormData();
    payload.append("file", params);

    if (fileId && getType) {
      const temp: UpdateN400FilesPayload = {
        id: fileId,
        formCategory: getType,
        data: payload,
      };

      updateMutate(temp, {
        onSuccess: () => toast.success("Update Successful"),
      });
    } else {
      mutate(payload, {
        onSuccess: () => {
          onSuccessful && onSuccessful();
          toast.success("Upload Successful");
        },
      });
    }

    setLoading(name); //
  };

  return (
    <RequestWrapper
      isLoading={isGetting}
      isError={isError}
      data={data && data?.length > 0 ? data : true}
    >
      <>
        {n600DocChecklist.map((ev, i) => (
          <DocChecklistItem
            key={i}
            data={ev}
            onSubmit={onSubmit}
            isLoading={loadingField === ev.name && (isLoading || isUpdating)}
            savedData={
              data
                ? [...data].reverse().find((item) => item.name === ev.name)
                : undefined
            }
          />
        ))}
        {data && (
          <AdditionalDocItem
            filedetail={data}
            onSubmit={onSubmit}
            loadingField={loadingField}
            loadingState={isLoading || isUpdating}
          />
        )}
        <AdditionalDoc
          onSubmit={onSubmit}
          loadingField={loadingField}
          loadingState={isLoading || isUpdating}
        />
      </>
    </RequestWrapper>
  );
};

export default N600DocChecklist;
