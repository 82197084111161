import { FC } from "react";
import styled from "styled-components";
import { GetFileDetailObj } from "../../../../../../api/n400/types";

enum DocStatusValues {
  recommended = "recommended",
  pending = "pending",
  notRecommended = "not-recommended",
}

interface Props {
  data: GetFileDetailObj;
}

const DocStatus: FC<Props> = ({ data }) => {
  const getStatus = () => {
    if (!data.not_recommended && !data.recommended) {
      return DocStatusValues.pending;
    }

    if (data.recommended) {
      return DocStatusValues.recommended;
    }

    if (data.not_recommended) {
      return DocStatusValues.notRecommended;
    }
  };
  const status = getStatus();
  return <Wrapper status={status}>{status}</Wrapper>;
};

export default DocStatus;

interface WrapperProps {
  status?: DocStatusValues;
}

const Wrapper = styled.span<WrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.125rem;
  border-radius: 24px;
  text-transform: capitalize;
  height: max-content;
  color: ${({ status }) =>
    status === DocStatusValues.pending
      ? "#CCA300"
      : status === DocStatusValues.recommended
      ? "#00A341"
      : status === DocStatusValues.notRecommended
      ? "#CC0000"
      : ""};
  background-color: ${({ status }) =>
    status === DocStatusValues.pending
      ? "#CCA30029"
      : status === DocStatusValues.recommended
      ? "#00A34129"
      : status === DocStatusValues.notRecommended
      ? "#CC000029"
      : ""};
`;
