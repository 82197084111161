import styled from "styled-components";
import { Form, Formik, FormikProps } from "formik";
import { InfoWrapper, Note, Title } from "./styles";
import { Modify, SingleOption } from "../../../../types/types";
import DatePickerField from "../../components/formik fields/DatePickerField";
import {
  N400EligibilityPayload,
  N400MaritalHistoryObj,
} from "../../../../api/n400/types";
import { Dayjs } from "dayjs";
import {
  useGetN400MaritalHistory,
  useSaveN400MaritalHistory,
} from "../../../../hooks/n400/useN400";
import FormSectionWrapper from "../../components/FormSectionWrapper";
import FormBtn from "../../components/FormBtn";
import {
  MHDateMarriage,
  MHMarriageStatusValCondition,
  WhenCurrentSpouseUSCitizen,
  GetN400ReasonForFiling,
  N400ReasonForFiling,
  WhenCurrentSpouseUSCitizenValues,
  IsDependingOnCategory,
  MHDateSpouseUSCitizen,
} from "./data";
import {
  ApartmentSuiteFloorData,
  GenericYesNo,
  GenericYesNoOptionsValue,
  MaritalStatusData,
  MaritalStatusValues,
  ToSaveDate,
  USDatePlaceholderFormat,
  initDate,
  initInput,
  initSelect,
  isZipCode,
} from "../../components/data";
import RadioComp from "../../components/RadioComp";
import { MaritalHistoryValidation } from "./validation";
import SelectField from "../../components/formik fields/SelectField";
import useToNextSection from "../../../../hooks/useToNextSection";
import InputField from "../../components/formik fields/InputField";
import { FC } from "react";
import { TooltipProps, TooltipType } from "../../components/Tooltip";
import { FormWrapper } from "../../components/styles";
import { FaInfoCircle } from "react-icons/fa";
import SpousePhysicalAddress from "./SpousePhysicalAddress";
import useCountries from "../../components/useCountries";
import useStatesByCountry from "../../components/useStatesByCountry";

export interface MaritalHistoryData
  extends Modify<
    Omit<N400MaritalHistoryObj, "zipCode" | "postalCode">,
    {
      addressCode: string;
      currentMaritalStatus: SingleOption | null;
      spouseDateOfBirth: Dayjs | null;
      dateOfMarriage: Dayjs | null;
      dateSpouseBecameUsCitizen: Dayjs | null;
      // isSpouseUSCitizen_risky_checkbox: boolean;
      dateOfMarriage_risky_checkbox: boolean;
      dateSpouseBecameUsCitizen_risky_checkbox: boolean;
      apartmentSuiteOrFloor: SingleOption | null;
      country: SingleOption | null;
      stateOrTerritory: SingleOption | null;
    }
  > {}

interface Props {
  formEligibility: N400EligibilityPayload;
}

const MaritalHistory: FC<Props> = ({ formEligibility }) => {
  const { toNextSection } = useToNextSection();
  const { data, isFetching, isError } = useGetN400MaritalHistory();
  const { mutate, isLoading: isSaving } = useSaveN400MaritalHistory();

  const { formattedCountryData } = useCountries();
  const { formattedStateData } = useStatesByCountry(data?.country);

  const onSubmit = (values: MaritalHistoryData) => {
    const {
      addressCode,
      currentMaritalStatus,
      spouseDateOfBirth,
      dateOfMarriage,
      dateSpouseBecameUsCitizen,
      apartmentSuiteOrFloor,
      country,
      stateOrTerritory,
      dateOfMarriage_risky_checkbox,
      dateSpouseBecameUsCitizen_risky_checkbox,
      ...rest
    } = values;

    if (!currentMaritalStatus) return;

    const payload = {
      ...rest,
      currentMaritalStatus: currentMaritalStatus.value,
      spouseDateOfBirth: ToSaveDate(spouseDateOfBirth),
      dateOfMarriage: ToSaveDate(dateOfMarriage),
      dateSpouseBecameUsCitizen: ToSaveDate(dateSpouseBecameUsCitizen),
      apartmentSuiteOrFloor: apartmentSuiteOrFloor?.value || "",
      country: country?.value || "",
      stateOrTerritory: stateOrTerritory?.value || "",
      postalCode: !isZipCode(country?.value) ? addressCode : "",
      zipCode: isZipCode(country?.value) ? addressCode : "",
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValue: MaritalHistoryData = {
    currentMaritalStatus: initSelect(
      MaritalStatusData,
      data?.currentMaritalStatus
    ),
    numOfTimesMarried: initInput(data?.numOfTimesMarried),
    spouseFirstName: initInput(data?.spouseFirstName),
    spouseMiddleName: initInput(data?.spouseMiddleName),
    spouseLastName: initInput(data?.spouseLastName),
    spouseDateOfBirth: initDate(data?.spouseDateOfBirth),
    dateOfMarriage: initDate(data?.dateOfMarriage),
    spousePresentEmployer: initInput(data?.spousePresentEmployer),
    isSpouseMemberOfArmedForces: initInput(data?.isSpouseMemberOfArmedForces),
    isSpouseAtYourAddress: initInput(data?.isSpouseAtYourAddress),
    howManyTimeSpouseMarried: initInput(data?.howManyTimeSpouseMarried),
    whenSpouseBecameUsCitizen: initInput(data?.whenSpouseBecameUsCitizen),
    dateSpouseBecameUsCitizen: initDate(data?.dateSpouseBecameUsCitizen),
    spouseAnumber: initInput(data?.spouseAnumber),
    // isSpouseUSCitizen_risky_checkbox: false,
    dateOfMarriage_risky_checkbox: false,
    dateSpouseBecameUsCitizen_risky_checkbox: false,
    //
    inCareOfName: initInput(data?.inCareOfName),
    apartmentSuiteOrFloor: initSelect(
      ApartmentSuiteFloorData,
      data?.apartmentSuiteOrFloor
    ),
    streetNumberAndName: initInput(data?.streetNumberAndName),
    addressNumber: initInput(data?.addressNumber),
    cityOrTown: initInput(data?.cityOrTown),
    province: initInput(data?.province),
    country: initSelect(formattedCountryData, data?.country),
    stateOrTerritory: initSelect(formattedStateData, data?.stateOrTerritory),
    addressCode: isZipCode(data?.country)
      ? initInput(data?.zipCode)
      : initInput(data?.postalCode),
  };

  const getEligibilityValidation = (
    formik: FormikProps<MaritalHistoryData>,
    name: string
  ): TooltipProps | undefined => {
    if (name === "currentMaritalStatus") {
      if (MHMarriageStatusValCondition(formik.values, formEligibility))
        return {
          body: "Your selected option does not match your eligibility data",
          type: TooltipType.deadly,
        };
    }

    // if (name === "isSpouseUSCitizen") {
    //   if (MHSpouseUSCondition(formik.values, formEligibility))
    //     return {
    //       type: TooltipType.risky,
    //     };
    // }

    if (name === "dateSpouseBecameUsCitizen") {
      if (MHDateSpouseUSCitizen(formik.values, formEligibility))
        return {
          type: TooltipType.risky,
        };
    }

    if (name === "dateOfMarriage") {
      if (MHDateMarriage(formik.values, formEligibility))
        return {
          type: TooltipType.risky,
        };
    }
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValue}
          onSubmit={onSubmit}
          validationSchema={MaritalHistoryValidation(formEligibility)}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <SelectField
                  name="currentMaritalStatus"
                  label="What is your current marital status?"
                  placeholder="-Select-"
                  options={MaritalStatusData}
                  toolTipProps={getEligibilityValidation(
                    formik,
                    "currentMaritalStatus"
                  )}
                />

                <div></div>
              </FormWrapper>

              {formik.values.currentMaritalStatus?.value &&
                formik.values.currentMaritalStatus?.value ===
                  MaritalStatusValues.married && (
                  <FormWrapper>
                    <RadioComp
                      title="Is your spouse a current member of the U.S. armed forces?"
                      name="isSpouseMemberOfArmedForces"
                      options={GenericYesNo}
                    />

                    <div></div>
                  </FormWrapper>
                )}

              {formik.values.currentMaritalStatus?.value &&
                formik.values.currentMaritalStatus?.value !==
                  MaritalStatusValues["single & never married"] && (
                  <>
                    <FormWrapper>
                      <InputField
                        name="numOfTimesMarried"
                        label="How many times have you been married?"
                        placeholder=""
                      />

                      <div></div>
                    </FormWrapper>

                    <InfoWrapper>
                      <FaInfoCircle />
                      <p>
                        Provide current marriage certificate and any divorce
                        decree, annulment decree, or death certificate showing
                        that your prior marriages were terminated (if
                        applicable).
                      </p>
                    </InfoWrapper>

                    {/* {true && ( */}
                    {IsDependingOnCategory(formEligibility) && (
                      <>
                        <Title>Your Current Marriage</Title>
                        <Note>
                          If you are currently married, including if you are
                          legally separated, provide the following information
                          about your current spouse.
                        </Note>

                        {/* {formik.values.currentMaritalStatus?.value &&
                          (formik.values.currentMaritalStatus?.value ===
                            MaritalStatusValues.married ||
                            formik.values.currentMaritalStatus?.value ===
                              MaritalStatusValues.separated) && ( */}
                        <div className="p-4 border rounded">
                          <Title>Current Spouse's Legal Name</Title>

                          <FormWrapper>
                            <InputField
                              label="Family Name"
                              coloredLabel="(Last Name)"
                              placeholder=""
                              name="spouseLastName"
                            />

                            <InputField
                              label="Given Name"
                              coloredLabel="(First Name)"
                              placeholder=""
                              name="spouseFirstName"
                            />
                          </FormWrapper>

                          <FormWrapper>
                            <InputField
                              label="Middle Name"
                              coloredLabel="(If applicable)"
                              placeholder=""
                              name="spouseMiddleName"
                            />
                            <div></div>
                          </FormWrapper>

                          <FormWrapper>
                            <DatePickerField
                              name="spouseDateOfBirth"
                              label="Current Spouse's Date of Birth"
                              placeholder={USDatePlaceholderFormat}
                              disableFuture={true}
                            />

                            <DatePickerField
                              name="dateOfMarriage"
                              label="Date You Entered into Marriage with Current Spouse"
                              placeholder={USDatePlaceholderFormat}
                              disableFuture={true}
                              toolTipProps={getEligibilityValidation(
                                formik,
                                "dateOfMarriage"
                              )}
                            />
                          </FormWrapper>

                          <FormWrapper>
                            <RadioComp
                              title="Is your current spouse's present physical address the same as your physical address?"
                              name="isSpouseAtYourAddress"
                              options={GenericYesNo}
                            />

                            <div></div>
                          </FormWrapper>

                          {formik.values.isSpouseAtYourAddress ===
                            GenericYesNoOptionsValue.no && (
                            <SpousePhysicalAddress
                              formik={formik}
                              data={data}
                            />
                          )}

                          <FormWrapper>
                            <RadioComp
                              title="How did your current spouse become a U.S. citizen?"
                              name="whenSpouseBecameUsCitizen"
                              options={WhenCurrentSpouseUSCitizen}
                            />

                            <div></div>
                          </FormWrapper>

                          {formik.values.whenSpouseBecameUsCitizen ===
                            WhenCurrentSpouseUSCitizenValues.other && (
                            <FormWrapper>
                              <DatePickerField
                                name="dateSpouseBecameUsCitizen"
                                label="Date Your Current Spouse Became a U.S. Citizen"
                                placeholder={USDatePlaceholderFormat}
                                disableFuture={true}
                                toolTipProps={getEligibilityValidation(
                                  formik,
                                  "dateSpouseBecameUsCitizen"
                                )}
                              />

                              <InputField
                                label="Current Spouse's Alien Registration Number (A-Number)"
                                coloredLabel="(if any)"
                                placeholder=""
                                name="spouseAnumber"
                              />
                            </FormWrapper>
                          )}

                          <FormWrapper>
                            <InputField
                              name="howManyTimeSpouseMarried"
                              label="How many times has your current spouse been married?"
                              placeholder=""
                            />

                            {GetN400ReasonForFiling(formEligibility) ===
                            N400ReasonForFiling.employmentSpouseUSCitizen ? (
                              <InputField
                                name="spousePresentEmployer"
                                label="Current Spouse's Current Employer or Company"
                                placeholder=""
                              />
                            ) : (
                              <div></div>
                            )}
                          </FormWrapper>

                          {/* kl */}
                        </div>
                        {/* )} */}
                      </>
                    )}
                  </>
                )}

              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default MaritalHistory;

const Wrapper = styled.div``;
