import React, { FC } from "react";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { n400CheckPart3Res } from "../../../../../../api/n400/types";
import { FormN400Progress } from "../../types";

interface Props {
  title: string;
  data?: n400CheckPart3Res;
}

const ReviewCheckPart3: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      label:
        "20. Have you EVER been placed in removal, rescission, or deportation proceedings?",
      value: data?.ever_been_placed_in_deportation_proceedings,
    },
    {
      label:
        "21. Have you EVER been removed or deported from the United States?",
      value: data?.ever_been_removed_or_deported,
    },
    {
      label:
        "22.a. Are you a person born as a male who lived in the United States at any time between your 18th and 26th birthdays?",
      value: data?.are_you_a_male_in_the_us_between_18th_26th,
    },
    {
      label: "22.b. Did you register for the Selective Service?",
      value: data?.did_you_register_for_selective_service,
    },
    {
      label: "Date Registered (mm/dd/yyyy)",
      value: data?.date_registered_for_selective_service,
    },
    {
      label: "Selective Service Number",
      value: data?.selective_service_number,
    },
    {
      label:
        "23. Have you EVER left the United States to avoid being drafted in the U.S. armed forces?",
      value: data?.ever_left_the_united_states_to_avoid_being_drafted,
    },
    {
      label:
        "24. Have you EVER applied for any kind of exemption from military service in the U.S. armed forces?",
      value: data?.ever_applied_for_any_kind_of_exemption_from_military_service,
    },
    {
      label: "25. Have you EVER served in the U.S. armed forces?",
      value: data?.ever_served_in_armed_forces,
    },
    {
      label: "26.a. Are you currently a member of the U.S. armed forces?",
      value: data?.are_you_currently_a_member_of_armed_forces,
    },
    {
      label:
        "26.b. Are you scheduled to deploy outside the United States, including to a vessel, within the next 3 months?",
      value: data?.are_you_scheduled_to_deploy_overseas,
    },
    {
      label: "26.c. Are you currently stationed outside the United States?",
      value: data?.are_you_currently_stationed_overseas,
    },
    {
      label:
        "26.d. Are you a former U.S. military service member who is currently residing outside of the U.S.?",
      value: data?.are_you_a_former_military_member_overseas,
    },
    {
      label:
        "27. Have you EVER been court-martialed or have you received a discharge characterized as other than honorable, bad conduct, or dishonorable, while in the U.S. armed forces?",
      value: data?.ever_been_court_martialed,
    },
    {
      label:
        "28. Have you EVER been discharged from training or service in the U.S. armed forces because you were an alien?",
      value: data?.ever_been_discharged_because_you_were_a_alien,
    },
    {
      label: "29. Have you EVER deserted from the U.S. armed forces?",
      value: data?.ever_deserted_the_armed_forces,
    },
  ];

  return (
    <ReviewBox
      title={title}
      data={section}
      editUrl={FormN400Progress.checkPart3}
    />
  );
};

export default ReviewCheckPart3;
