import React, { FC } from "react";
import styled from "styled-components";
import Colors from "../../../../styles/Colors";
import { useField } from "formik";

interface Props {
  isDisabled?: boolean;
  value?: string;
  name: string;
  label: string | JSX.Element;
  boxSize?: string;
  onChange?: () => void;
}

const CheckBoxField: FC<Props> = ({
  isDisabled,
  label,
  boxSize,
  onChange,
  ...props
}) => {
  // const [checked, setChecked] = useState(false);

  const [field, meta, helpers] = useField(props);
  const errorClass = `${meta.touched && meta.error && "error"}`;

  const handleChange = (e: any) => {
    // setChecked(!checked);
    helpers.setValue(e.target.checked);
    onChange && onChange();
  };

  return (
    <CheckBoxWrapper isDisabled={isDisabled} boxSize={boxSize}>
      <input
        {...field}
        {...props}
        type="checkbox"
        checked={field.value}
        className={errorClass}
        onChange={handleChange}
        disabled={isDisabled}
      />{" "}
      <LabelWrapper>{label}</LabelWrapper>
    </CheckBoxWrapper>
  );
};

export default CheckBoxField;

interface CheckBoxWrapperProps {
  isDisabled?: boolean;
  boxSize?: string;
}

const CheckBoxWrapper = styled.div<CheckBoxWrapperProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${({ isDisabled }) => (isDisabled ? "#1b092c99" : Colors.Dark6B)};
  font-size: 0.875rem;

  & > input[type="checkbox"] {
    width: ${({ boxSize }) => boxSize && boxSize};
    height: ${({ boxSize }) => boxSize && boxSize};
    flex-shrink: 0;
    position: relative;
    accent-color: ${Colors.Blue00};
  }

  & > input[type="checkbox"].error::before {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    transition: 0.3s;
    background-color: #ff000026;
    box-shadow: 0px 0px 0px 3px #ff000051;
    border-radius: 4px;
    opacity: 1;
    cursor: pointer;
  }

  &:hover {
    & > input[type="checkbox"]:hover:before {
      background-color: #0076e91f;
      box-shadow: 0px 0px 0px 5px #0076e91f;
      border-radius: 4px;
      opacity: 1;
      cursor: pointer;
    }

    & > input[type="checkbox"]:before {
      content: "";
      top: 0;
      left: 0;
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      transition: 0.3s;
    }
  }
`;

const LabelWrapper = styled.span``;
