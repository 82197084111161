import React, { useEffect } from "react";
import styled from "styled-components";
import LeftSideBar from "./components/LeftSideBar";
import RightSection from "./components/RightSection";
import { ProfileSections } from "./components/types";
import { useNavigate, useSearchParams } from "react-router-dom";
import { WebPaths } from "../../../routes/data";
import Constaint from "../layout/components/Constaint";
import useIsLoggedIn from "../../../hooks/useIsLoggedIn";
import PopUp from "../components/PopUp";
import { useSnapshot } from "valtio";
import { userDataStore } from "../../../store/userData";

const PROFILE = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const getType = searchParams.get("active");
  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();

  const { userData } = useSnapshot(userDataStore);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(WebPaths.Auth);
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    if (isLoggedIn && !getType) {
      searchParams.set("active", ProfileSections.dashboard);
      setSearchParams(searchParams);
    }
  }, [searchParams, getType, setSearchParams, isLoggedIn]);

  return (
    <>
      <Constaint>
        <Wrapper>
          <PopUp isFormCompleted={userData?.currentFormInfo.isFormCompleted!} />
          <Container>
            <LeftSideBar />
            <RightSection />
          </Container>
        </Wrapper>
      </Constaint>
    </>
  );
};

export default PROFILE;

const Wrapper = styled.div`
  margin: 28px 0;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 267px 1fr;
  gap: 48px;
  margin-bottom: 170px;
`;
