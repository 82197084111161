import React from "react";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { Form, Formik, FormikProps } from "formik";
import styled from "styled-components";
import { FormWrapper } from "../../../components/styles";
import RadioComp from "../../../components/RadioComp";
import {
  GenericYesNo,
  GenericYesNoOptionsValue,
  initCheck,
  initInput,
} from "../../../components/data";
import { TooltipProps, TooltipType } from "../../../components/Tooltip";
import { Modify } from "../../../../../types/types";
import { AIParentUSValCondition } from "../data";
import { ApplicantInfo2Validation } from "../validation";
import FormBtn from "../../../components/FormBtn";
import { Note, Title } from "../styles";
import InputField from "../../../components/formik fields/InputField";
import CheckBoxField from "../../../components/formik fields/CheckBoxField";
import { N400ApplicantInfo2Obj } from "../../../../../api/n400/types";
import {
  useGetN400ApplicantInfo2,
  useSaveN400ApplicantInfo2,
} from "../../../../../hooks/n400/useN400";
import useToNextSection from "../../../../../hooks/useToNextSection";

export interface N400ApplicantInfo2Data
  extends Modify<N400ApplicantInfo2Obj, {}> {}

const ApplicantInfo2 = () => {
  const { toNextSection } = useToNextSection();
  const { data, isFetching, isError } = useGetN400ApplicantInfo2();
  const { mutate, isLoading: isSaving } = useSaveN400ApplicantInfo2();

  const initialValues = {
    aNumber: initInput(data?.aNumber),
    was_one_of_parent_us_citizen: initInput(data?.was_one_of_parent_us_citizen),
    disability: initInput(data?.disability),
    do_you_want_social_security_card: initInput(
      data?.do_you_want_social_security_card
    ),
    usSocialSecurityNumber: initInput(data?.usSocialSecurityNumber),
    consentForDisclosure: initCheck(data?.consentForDisclosure),

    daytimeTelephoneNumber: initInput(data?.daytimeTelephoneNumber),
    mobileTelephoneNumber: initInput(data?.mobileTelephoneNumber),
    email: initInput(data?.email),
  };

  const onSubmit = (values: N400ApplicantInfo2Data) => {
    const payload: N400ApplicantInfo2Obj = { ...values };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const getEligibilityValidation = (
    formik: FormikProps<N400ApplicantInfo2Data>,
    name: string
  ): TooltipProps | undefined => {
    if (name === "was_one_of_parent_us_citizen") {
      if (AIParentUSValCondition(formik.values))
        return {
          body: "Based on your answer, you may already be a U.S. citizen. You should not complete Form N-400",
          type: TooltipType.deadly,
          isReversed: true,
        };
    }

    if (name === "disability") {
      if (formik.values.disability === GenericYesNoOptionsValue.yes)
        return {
          body: (
            <div className="text-sm">
              Based on your answer, submit a completed Form N-648, Medical
              Certification for Disability Exceptions, <br /> when you file your
              Form N-400.
            </div>
          ),
          type: TooltipType.info,
          isReversed: true,
        };
    }
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={ApplicantInfo2Validation}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <InputField
                  label="Applicant's Daytime Telephone Number"
                  placeholder=""
                  name="daytimeTelephoneNumber"
                />

                <InputField
                  label="Applicant's Mobile Telephone Number"
                  placeholder=""
                  name="mobileTelephoneNumber"
                  coloredLabel="(if any)"
                />
              </FormWrapper>

              <FormWrapper>
                <InputField
                  label="Applicant's Email Address"
                  placeholder=""
                  name="email"
                  coloredLabel="(if any)"
                />

                <div>
                  <InputField
                    label="Alien Registration Number"
                    coloredLabel="(A-Number)"
                    placeholder=""
                    name="aNumber"
                  />
                  <Note className="mt-1">
                    Alien Registration Number (A-Number) Also known as an
                    A-Number, your Alien Registration Number is an 8- or 9-digit
                    number that can be found on your Permanent Resident Card.
                  </Note>
                </div>
              </FormWrapper>

              <FormWrapper>
                <RadioComp
                  name="was_one_of_parent_us_citizen"
                  title="Was one of your parents (including adoptive parents) a U.S. citizen before your 18th birthday?"
                  options={GenericYesNo}
                  toolTipProps={getEligibilityValidation(
                    formik,
                    "was_one_of_parent_us_citizen"
                  )}
                />
              </FormWrapper>

              <FormWrapper>
                <RadioComp
                  name="disability"
                  title="Do you have a physical or developmental disability or mental impairment that prevents you from demonstrating your knowledge and understanding of the English language or civics requirements for
                  naturalization?"
                  options={GenericYesNo}
                  toolTipProps={getEligibilityValidation(formik, "disability")}
                />
              </FormWrapper>

              <Title>Social Security Update</Title>

              <FormWrapper>
                <RadioComp
                  name="do_you_want_social_security_card"
                  title="Do you want the Social Security Administration (SSA) to issue you an original or replacement Social Security card and update your immigration status with the SSA if and when you are naturalized?"
                  options={GenericYesNo}
                />
              </FormWrapper>

              {formik.values.do_you_want_social_security_card ===
                GenericYesNoOptionsValue.yes && (
                <>
                  <FormWrapper>
                    <InputField
                      label="Provide your Social Security number (SSN)"
                      coloredLabel="(if any)"
                      placeholder=""
                      name="usSocialSecurityNumber"
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <CheckBoxField
                      name="consentForDisclosure"
                      label="Consent for Disclosure: I authorize disclosure of information from this application and USCIS systems to the SSA as required for the purpose of assigning me an SSN, issuing me an original or replacement Social Security card, and updating my immigration status with the SSA."
                    />
                  </FormWrapper>
                </>
              )}

              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default ApplicantInfo2;

const Wrapper = styled.div``;
