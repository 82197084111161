import { ApiResponse } from "../../types/types";
import request from "../request";
import { PreparerInterpreterObj } from "../utils/types";
import {
  GetN400Files,
  N400ApplicantInfo1Obj,
  N400ApplicantInfo2Obj,
  N400BioInfoObj,
  N400EligibilityPayload,
  N400InfoObj,
  N400MaritalHistoryObj,
  SaveN400FilingInfoPayload,
  UpdateN400FilesPayload,
  n400AccommodationData,
  n400AccommodationRes,
  n400AdditionalInfoData,
  n400AdditionalInfoRes,
  N400AddressHistoryObj,
  n400CheckPart1Res,
  n400CheckPart2Res,
  n400CheckPart3Res,
  n400CheckPart4Res,
  n400CheckPart5Res,
  n400CheckPart6Res,
  n400CivicTestExemptionData,
  n400CivicTestExemptionRes,
  n400EmploymentHistoryData,
  n400EmploymentHistoryRes,
  n400EnglishTestExemptionData,
  n400EnglishTestExemptionRes,
  n400TimeOutsideUSData,
  n400TimeOutsideUSRes,
  payN400Data,
  N400ChildrenInfoObj,
  n400FeeReductionObj,
} from "./types";

export const saveN400TimeOutsideUS = async (payload: n400TimeOutsideUSData) => {
  return await request.post<ApiResponse>(`/user/n400-time-outside`, payload);
};

export const getN400TimeOutsideUs = async () => {
  return await request.get<ApiResponse<n400TimeOutsideUSRes>>(
    `/user/get-n400-time-outside`
  );
};

export const saveN400ApplicantInfo1 = async (
  payload: N400ApplicantInfo1Obj
) => {
  return await request.post<ApiResponse>(
    `/user/n400-applicant-info-one`,
    payload
  );
};

export const getN400ApplicantInfo1 = async () => {
  return await request.get<ApiResponse<N400ApplicantInfo1Obj>>(
    `/user/get-n400-applicant-info-one`
  );
};

export const saveN400ApplicantInfo2 = async (
  payload: N400ApplicantInfo2Obj
) => {
  return await request.post<ApiResponse>(
    `/user/n400-applicant-info-two`,
    payload
  );
};

export const getN400ApplicantInfo2 = async () => {
  return await request.get<ApiResponse<N400ApplicantInfo2Obj>>(
    `/user/get-n400-applicant-info-two`
  );
};

export const saveN400BioInfo = async (payload: N400BioInfoObj) => {
  return await request.post<ApiResponse>(
    `/user/n400-applicant-bio-info`,
    payload
  );
};

export const getN400BioInfo = async () => {
  return await request.get<ApiResponse<N400BioInfoObj>>(
    `/user/get-n400-applicant-bio-info`
  );
};

export const saveN400AddressHistory = async (
  payload: N400AddressHistoryObj
) => {
  return await request.post<ApiResponse>(`/user/n400-address-history`, payload);
};

export const getN400AddressHistory = async () => {
  return await request.get<ApiResponse<N400AddressHistoryObj>>(
    `/user/get-n400-address-history`
  );
};

//

export const saveN400Accommodation = async (payload: n400AccommodationData) => {
  return await request.post<ApiResponse>(
    `/user/n400-for-disabilities`,
    payload
  );
};

export const getN400Accommodation = async () => {
  return await request.get<ApiResponse<n400AccommodationRes>>(
    `/user/get-n400-for-disabilities`
  );
};

export const saveN400CivicTestExemption = async (
  payload: n400CivicTestExemptionData
) => {
  return await request.post<ApiResponse>(
    `/user/n400-exemption-to-english-and-civics`,
    payload
  );
};

export const getN400CivicTestExemption = async () => {
  return await request.get<ApiResponse<n400CivicTestExemptionRes>>(
    `/user/get-n400-exemption-to-english-and-civics`
  );
};

export const saveN400EnglishTestExemption = async (
  payload: n400EnglishTestExemptionData
) => {
  return await request.post<ApiResponse>(
    `/user/n400-exemption-to-english`,
    payload
  );
};

export const getN400EnglishTestExemption = async () => {
  return await request.get<ApiResponse<n400EnglishTestExemptionRes>>(
    `/user/get-n400-exemption-to-english`
  );
};

export const saveN400AdditionalInfo = async (
  payload: n400AdditionalInfoData
) => {
  return await request.post<ApiResponse>(`/user/n400-additional-info`, payload);
};

export const getN400AdditionalInfo = async () => {
  return await request.get<ApiResponse<n400AdditionalInfoRes>>(
    `/user/get-n400-additional-info`
  );
};

export const saveN400EmploymentHistory = async (
  payload: n400EmploymentHistoryData
) => {
  return await request.post<ApiResponse>(
    `/user/n400-employment-and-school-history`,
    payload
  );
};

export const getN400EmploymentHistory = async () => {
  return await request.get<ApiResponse<n400EmploymentHistoryRes>>(
    `/user/get-n400-employment-and-school-history`
  );
};

export const saveN400MaritalHistory = async (
  payload: N400MaritalHistoryObj
) => {
  return await request.post<ApiResponse>(`/user/n400-marital-history`, payload);
};

export const getN400MaritalHistory = async () => {
  return await request.get<ApiResponse<N400MaritalHistoryObj>>(
    `/user/get-n400-marital-history`
  );
};

export const saveN400ChildrenInfo = async (payload: N400ChildrenInfoObj) => {
  return await request.post<ApiResponse>(
    `/user/n400-info-about-children`,
    payload
  );
};

export const getN400ChildrenInfo = async () => {
  return await request.get<ApiResponse<N400ChildrenInfoObj>>(
    `/user/get-n400-info-about-children`
  );
};

// export const saveN400Checklist = async (payload: n400CheckListData) => {
//   return await request.post<ApiResponse>(
//     `/user/n400-checklist-for-naturalization`,
//     payload
//   );
// };

export const saveN400CheckPart1 = async (payload: n400CheckPart1Res) => {
  return await request.post<ApiResponse>(
    `/user/n400-checklist-for-naturalization_1`,
    payload
  );
};

export const saveN400CheckPart2 = async (payload: n400CheckPart2Res) => {
  return await request.post<ApiResponse>(
    `/user/n400-checklist-for-naturalization_2`,
    payload
  );
};

export const saveN400CheckPart3 = async (payload: n400CheckPart3Res) => {
  return await request.post<ApiResponse>(
    `/user/n400-checklist-for-naturalization_3`,
    payload
  );
};

export const saveN400CheckPart4 = async (payload: n400CheckPart4Res) => {
  return await request.post<ApiResponse>(
    `/user/n400-checklist-for-naturalization_4`,
    payload
  );
};

export const saveN400CheckPart5 = async (payload: n400CheckPart5Res) => {
  return await request.post<ApiResponse>(
    `/user/n400-checklist-for-naturalization_5`,
    payload
  );
};

export const saveN400CheckPart6 = async (payload: n400CheckPart6Res) => {
  return await request.post<ApiResponse>(
    `/user/n400-checklist-for-naturalization_6`,
    payload
  );
};

export const saveN400FeeReduction = async (payload: n400FeeReductionObj) => {
  return await request.post<ApiResponse>(
    `/user/n400-request-fee-reduction`,
    payload
  );
};

export const getN400FeeReduction = async () => {
  return await request.get<ApiResponse<n400FeeReductionObj>>(
    `/user/get-n400-request-fee-reduction`
  );
};
// export const getN400Checklist = async () => {
//   return await request.get<ApiResponse<n400CheckListRes>>(
//     `/user/get-n400-checklist-for-naturalization`
//   );
// };

export const getN400CheckPart1 = async () => {
  return await request.get<ApiResponse<n400CheckPart1Res>>(
    `/user/get-n400-checklist-for-naturalization_1`
  );
};

export const getN400CheckPart2 = async () => {
  return await request.get<ApiResponse<n400CheckPart2Res>>(
    `/user/get-n400-checklist-for-naturalization_2`
  );
};

export const getN400CheckPart3 = async () => {
  return await request.get<ApiResponse<n400CheckPart3Res>>(
    `/user/get-n400-checklist-for-naturalization_3`
  );
};

export const getN400CheckPart4 = async () => {
  return await request.get<ApiResponse<n400CheckPart4Res>>(
    `/user/get-n400-checklist-for-naturalization_4`
  );
};

export const getN400CheckPart5 = async () => {
  return await request.get<ApiResponse<n400CheckPart5Res>>(
    `/user/get-n400-checklist-for-naturalization_5`
  );
};

export const getN400CheckPart6 = async () => {
  return await request.get<ApiResponse<n400CheckPart6Res>>(
    `/user/get-n400-checklist-for-naturalization_6`
  );
};

export const saveN400Preparer = async (payload: PreparerInterpreterObj) => {
  return await request.post<ApiResponse>(
    `/user/n400-preparer-and-interpreter`,
    payload
  );
};

export const getN400Preparer = async () => {
  return await request.get<ApiResponse<PreparerInterpreterObj>>(
    `/user/get-n400-preparer-and-interpreter`
  );
};

export const getN400Form = async () => {
  return await request.get<ApiResponse>(`/user/get-n400-application`, {
    // responseType: "blob",
  });
};

export const getN400Info = async () => {
  return await request.get<ApiResponse<N400InfoObj>>(`user/get-n400-info`);
};

export const payN400 = async (payload: payN400Data) => {
  return await request.post<ApiResponse>(`user/n400-payment`, payload);
};

export const getN400PaySuccess = async (payId: string) => {
  return await request.get<ApiResponse>(`/user/n400-payment-success/${payId}`);
};

export const getN400PayFail = async () => {
  return await request.get<ApiResponse<N400InfoObj>>(
    `/user/n400-payment-cancel`
  );
};

export const saveN400Eligibility = async (payload: N400EligibilityPayload) => {
  return await request.post<ApiResponse>(`/user/n400-eligibility`, payload);
};

export const getN400Eligibility = async () => {
  return await request.get<ApiResponse<N400EligibilityPayload>>(
    `/user/get-n400-eligibility`
  );
};

export const uploadN400Files = async (payload: FormData) => {
  return await request.post<ApiResponse>(`/user/upload-n400-files`, payload);
};

export const getN400Files = async () => {
  return await request.get<ApiResponse<GetN400Files>>(
    `/user/get-upload-n400-files`
  );
};

export const updateN400Files = async (payload: UpdateN400FilesPayload) => {
  return await request.post<ApiResponse>(
    `/user/update-n400-files/${payload.id}/${payload.formCategory}`,
    payload.data
  );
};

export const saveN400FilingInfo = async (
  payload: SaveN400FilingInfoPayload
) => {
  return await request.post<ApiResponse>(`/user/filing-info`, payload);
};

export const getN400Trigger = async () => {
  return await request.get(`/user/download-n400-application`);
};
