import React, { FC } from "react";
import styled from "styled-components";
import Constaint from "../../layout/components/Constaint";
import Colors from "../../../../styles/Colors";
import { BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs";
import { FaInfoCircle } from "react-icons/fa";
import EligibilityImage from "../../../../images/web/eligible-image.png";
import { useNavigate, useParams } from "react-router-dom";
import { WebPaths } from "../../../../routes/data";
import ParamFormName from "./ParamFormName";
import ImportantNotice from "./ImportantNotice";
import { eligibilityDataStore } from "../../../../store/eligibilityData";
import { VscDebugRestart } from "react-icons/vsc";
import { clearEligibilityStoreAndLocalStorage } from "./data";
import useIsLoggedIn from "../../../../hooks/useIsLoggedIn";
import useIsCurrentOrFreshForm from "../../../../hooks/useIsCurrentOrFreshForm";
import useIsEligibilityFormFilled from "../../../../hooks/useHasFilledCurrentEligibility";
import { FormCategories } from "../../../../routes/type";
import { useSnapshot } from "valtio";
import useFormsEligibility from "../../../../hooks/useFormsEligibility";
import Spinner from "../../../../utils/Spinner";

interface Props {
  onChangeState: (value: boolean) => void;
}

const EligibleConfirmation: FC<Props> = ({ onChangeState }) => {
  const navigate = useNavigate();
  const { formType } = useParams();
  const isLoggedIn = useIsLoggedIn();
  const { getIsEligibilityFormFilled } = useIsEligibilityFormFilled();
  const { getIsCurrentForm } = useIsCurrentOrFreshForm();
  const { eligibilityData } = useSnapshot(eligibilityDataStore);
  const { onSaveFormEligibility, formEligiblityLoading } =
    useFormsEligibility();

  const prevEligibilityData = () => {
    if (
      (isLoggedIn && eligibilityData.formData && eligibilityData?.formType) ||
      (eligibilityData.formData && eligibilityData?.formType)
    ) {
      const hasPrevEligibility = eligibilityData.formData;
      const prevEligibility = eligibilityData?.formType;

      return { hasPrevEligibility, prevEligibility };
    } else {
      const hasPrevEligibility = getIsEligibilityFormFilled(
        formType as FormCategories
      );
      const prevEligibility = hasPrevEligibility ? formType : undefined;
      return { hasPrevEligibility, prevEligibility };
    }
  };

  const onStartOver = () => {
    clearEligibilityStoreAndLocalStorage();
    onChangeState(false);
  };

  const onContinueToForm = () => {
    if (isLoggedIn) {
      if (eligibilityData) {
        // save to backend
        onSaveFormEligibility(formType);
        clearEligibilityStoreAndLocalStorage();
      }
      if (getIsCurrentForm(formType)) {
        navigate(
          `${WebPaths.FormCategory}/${prevEligibilityData().prevEligibility}`
        );
      } else {
        navigate(WebPaths.Profile);
      }
    } else {
      navigate(WebPaths.Join);
    }
  };

  return (
    <Wrapper>
      <Constaint>
        <Container>
          <Card>
            <Back
              onClick={() => navigate(`${WebPaths.Eligibility}/${formType}`)}
            >
              <BackIconWrapper>
                <BsArrowLeftShort />
              </BackIconWrapper>
              Back
            </Back>
            <ImgWrapper>
              <img src={EligibilityImage} alt="" />
            </ImgWrapper>
            <ImportantNotice />
            <SubText>
              {prevEligibilityData().hasPrevEligibility ? (
                <>
                  <FaInfoCircle />
                  {prevEligibilityData().prevEligibility === formType ? (
                    <span>
                      You have an eligibility pending on this form (
                      <Bold>{<ParamFormName />}</Bold>), will you like to start
                      over or continue to the form
                    </span>
                  ) : (
                    <span>
                      You have an eligibility pending on{" "}
                      <Bold>{prevEligibilityData().prevEligibility}</Bold>,
                      starting a new eligibility will overwrite your stored
                      progress!
                    </span>
                  )}
                </>
              ) : (
                <> Answer few questions to get started</>
              )}
            </SubText>
            {prevEligibilityData().prevEligibility === formType ? (
              <CTAWrapper>
                <CTA onClick={onStartOver} outline>
                  <VscDebugRestart />
                  Start over
                </CTA>
                <CTA onClick={onContinueToForm}>
                  {formEligiblityLoading && <Spinner color="white" size={20} />}
                  Continue to form
                </CTA>
              </CTAWrapper>
            ) : (
              <CTAWrapper>
                {prevEligibilityData().hasPrevEligibility && (
                  <CTA
                    onClick={() =>
                      navigate(
                        `${WebPaths.checkEligibility}/${
                          prevEligibilityData().prevEligibility
                        }`
                      )
                    }
                    outline
                  >
                    <BsArrowLeftShort />
                    Return to {prevEligibilityData().prevEligibility}{" "}
                    Eligibility
                  </CTA>
                )}
                <CTA
                  onClick={() => {
                    onChangeState(false);
                    clearEligibilityStoreAndLocalStorage();
                  }}
                >
                  <span>
                    Check <ParamFormName /> Eligibility
                  </span>
                  <BsArrowRightShort />
                </CTA>
              </CTAWrapper>
            )}
          </Card>
        </Container>
      </Constaint>
    </Wrapper>
  );
};

export default EligibleConfirmation;

const Wrapper = styled.div``;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 53px;
  padding-bottom: 112px;
`;

const Card = styled.div`
  width: 924px;
  /* height: 649px; */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.WhiteF9};
  border-radius: 12px;
  padding: 60px 87px;
  box-shadow: 0 0 5px 5px ${Colors.Blue00 + "0D"};
  color: #0075e971;
`;

const Back = styled.div`
  position: absolute;
  left: 20px;
  top: 48px;
  color: ${Colors.Black31};
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 0.875rem;
  cursor: pointer;
`;

const BackIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const ImgWrapper = styled.div`
  width: 250px;
  height: 127px;

  img {
    object-fit: contain;
  }
`;

// const Title = styled.h2`
//   font-size: 2.5rem;
//   font-weight: 700;
//   margin-top: 40px;
//   color: ${Colors.Black};
//   text-align: center;

//   ${mediaObj.bigMobile} {
//     font-size: 2rem;
//   }
// `;

const SubText = styled.div`
  color: ${Colors.Black31};
  margin-bottom: 16px;
  text-align: center;
  font-size: 0.95rem;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;

  & > svg {
    color: ${Colors.RedDA};
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    margin-top: 3px;
  }
`;

const CTAWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
`;

interface CTAProps {
  outline?: boolean;
}

const CTA = styled.button<CTAProps>`
  width: max-content;
  height: 56px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 18px 36px;
  font-size: 1rem;
  font-weight: 600;
  background: ${({ outline }) => (outline ? Colors.White : Colors.Blue00)};
  border: ${({ outline }) => outline && "1px solid #0076E9"};
  border-radius: 8px;
  color: ${({ outline }) => (outline ? Colors.Blue00 : Colors.WhiteF9)};
  white-space: nowrap;
  text-transform: uppercase;

  & > svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    /* background: ${Colors.Blue02}; */
    background: ${({ outline }) => (outline ? Colors.Blue00 : Colors.Blue02)};
    color: ${Colors.WhiteF9};
    transition: 0.3s ease-in-out;
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;
