import React, { useEffect } from "react";
import styled from "styled-components";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { Form, Formik } from "formik";
import InputField from "../../../components/formik fields/InputField";
import { FormWrapper } from "../../../components/styles";
import DatePickerField from "../../../components/formik fields/DatePickerField";
import {
  ApartmentSuiteFloorData,
  GenericYesNo,
  GenericYesNoOptionsValue,
  ToSaveDate,
  USDatePlaceholderFormat,
  initDate,
  initInput,
  initSelect,
  resetDynamicFields,
} from "../../../components/data";
import SelectField from "../../../components/formik fields/SelectField";
import useCountries from "../../../components/useCountries";
import RadioComp from "../../../components/RadioComp";
import useStatesByCountry from "../../../components/useStatesByCountry";
import { FatherCitizenByData, FatherCitizenByDataValues } from "../data";
import { Modify, SingleOption } from "../../../../../types/types";
import { Dayjs } from "dayjs";
import { Info, InfoBold } from "../../../form ds 160/components/styles";
import FormBtn from "../../../components/FormBtn";
import { N600InfoAboutUSCitizenFatherPart1Payload } from "../../../../../api/n600/types";
import useToNextSection from "../../../../../hooks/useToNextSection";
import {
  useGetN600Eligibility,
  useGetN600InfoAboutUSCitizenFatherPart1,
  useSaveN600InfoAboutUSCitizenFatherPart1,
} from "../../../../../hooks/n600/useN600";
import { USCitizenFatherP1Validation } from "../validation";

export interface USCitizenFatherP1FormData
  extends Modify<
    N600InfoAboutUSCitizenFatherPart1Payload,
    {
      fatherDateOfBirth: Dayjs | null;
      fatherCountryOfBirth: SingleOption | null;
      fatherCountryOfCitizenship: SingleOption | null;
      fatherDateOfDeath: Dayjs | null;
      fatherPhysicalAddressCountry: SingleOption | null;
      fatherPhysicalAddressState: SingleOption | null;
      fatherPhysicalAddressAptSteFlr: SingleOption | null;
      fatherPlaceOfNaturalizationState: SingleOption | null;
      fatherCitizenshipStatus: SingleOption | null;
      fatherDateOfNaturalization: Dayjs | null;
    }
  > {}

const USCitizenFatherP1 = () => {
  const { data, isFetching, isError } =
    useGetN600InfoAboutUSCitizenFatherPart1();
  const { mutate, isLoading: isSaving } =
    useSaveN600InfoAboutUSCitizenFatherPart1();
  const { data: eligibilityData } = useGetN600Eligibility();

  const { toNextSection } = useToNextSection();

  const isFatherCitizen =
    eligibilityData?.which_parent_is_a_us_citizen ===
    "My father is a U.S citizen";

  //skip this section if not to study
  useEffect(() => {
    if (!isFatherCitizen) {
      toNextSection();
    }
  }, [isFatherCitizen, toNextSection]);

  const { formattedCountryData, countryLoading } = useCountries();
  const {
    formattedCountryData: nationCountryData,
    countryLoading: nationCountryLoading,
  } = useCountries();

  const {
    formattedCountryData: addressCountryData,
    countryLoading: addressCountryLoading,
  } = useCountries();
  const { formattedStateData, stateLoading, setCountry, selectedCountry } =
    useStatesByCountry(data?.fatherPhysicalAddressCountry || "United States");

  const {
    formattedStateData: naturalizationStateData,
    stateLoading: naturalizationStateLoading,
    // setCountry: naturalizationSetCountry,
    selectedCountry: naturalizationSelectedCountry,
  } = useStatesByCountry("United States");

  const onSubmit = (values: USCitizenFatherP1FormData) => {
    const {
      fatherDateOfBirth,
      fatherCountryOfBirth,
      fatherCountryOfCitizenship,
      fatherDateOfDeath,
      fatherPhysicalAddressCountry,
      fatherPhysicalAddressState,
      fatherPhysicalAddressAptSteFlr,
      fatherPlaceOfNaturalizationState,
      fatherCitizenshipStatus,
      fatherDateOfNaturalization,
      ...rest
    } = values;

    const payload = {
      ...rest,
      fatherDateOfBirth: ToSaveDate(fatherDateOfBirth),
      fatherCountryOfBirth: fatherCountryOfBirth?.value || "",
      fatherCountryOfCitizenship: fatherCountryOfCitizenship?.value || "",
      fatherDateOfDeath: ToSaveDate(fatherDateOfDeath),
      fatherPhysicalAddressCountry: fatherPhysicalAddressCountry?.value || "",
      fatherPhysicalAddressState: fatherPhysicalAddressState?.value || "",
      fatherPhysicalAddressAptSteFlr:
        fatherPhysicalAddressAptSteFlr?.value || "",
      fatherPlaceOfNaturalizationState:
        fatherPlaceOfNaturalizationState?.value || "",
      fatherCitizenshipStatus: fatherCitizenshipStatus?.value || "",
      fatherDateOfNaturalization: ToSaveDate(fatherDateOfNaturalization),
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValues: USCitizenFatherP1FormData = {
    fatherCurrentLegalFamilyName: initInput(data?.fatherCurrentLegalFamilyName),
    fatherCurrentLegalGivenName: initInput(data?.fatherCurrentLegalGivenName),
    fatherCurrentLegalMiddleName: initInput(data?.fatherCurrentLegalMiddleName),

    fatherDateOfBirth: initDate(data?.fatherDateOfBirth),
    fatherCountryOfBirth: initSelect(
      formattedCountryData,
      data?.fatherCountryOfBirth
    ),
    fatherCountryOfCitizenship: initSelect(
      nationCountryData,
      data?.fatherCountryOfCitizenship
    ),

    isFatherAlive: initInput(data?.isFatherAlive),
    fatherDateOfDeath: initDate(data?.fatherDateOfDeath),

    fatherPhysicalAddressStreetNumberAndName: initInput(
      data?.fatherPhysicalAddressStreetNumberAndName
    ),
    fatherPhysicalAddressAptSteFlr: initSelect(
      ApartmentSuiteFloorData,
      data?.fatherPhysicalAddressAptSteFlr
    ),
    fatherPhysicalAddressAptSteFlrNumber: initInput(
      data?.fatherPhysicalAddressAptSteFlrNumber
    ),
    fatherPhysicalAddressCityOrTown: initInput(
      data?.fatherPhysicalAddressCityOrTown
    ),

    fatherPhysicalAddressCountry: initSelect(
      addressCountryData,
      data?.fatherPhysicalAddressCountry
    ),
    fatherPhysicalAddressState: initSelect(
      formattedStateData,
      data?.fatherPhysicalAddressState
    ),
    fatherPhysicalAddressZipCode: initInput(data?.fatherPhysicalAddressZipCode),
    fatherPhysicalAddressProvince: initInput(
      data?.fatherPhysicalAddressProvince
    ),
    fatherPhysicalAddressPostalCode: initInput(
      data?.fatherPhysicalAddressPostalCode
    ),
    // fatherPhysicalAddressCountry: initInput(data?.fatherPhysicalAddressCountry),

    fatherCitizenshipStatus: initSelect(
      FatherCitizenByData,
      data?.fatherCitizenshipStatus
    ),
    fatherCertificateOfCitizenshipNumber: initInput(
      data?.fatherCertificateOfCitizenshipNumber
    ),
    fatherAlienRegistrationNumber: initInput(
      data?.fatherAlienRegistrationNumber
    ),
    fatherPlaceOfNaturalizationCourtOrUSCISOffice: initInput(
      data?.fatherPlaceOfNaturalizationCourtOrUSCISOffice
    ),
    fatherPlaceOfNaturalizationCityOrTown: initInput(
      data?.fatherPlaceOfNaturalizationCityOrTown
    ),
    fatherPlaceOfNaturalizationState: initSelect(
      naturalizationStateData,
      data?.fatherPlaceOfNaturalizationState
    ),
    fatherCertificateOfNaturalizationNumber: initInput(
      data?.fatherCertificateOfNaturalizationNumber
    ),
    fatherNaturalizationANumber: initInput(data?.fatherNaturalizationANumber),
    fatherDateOfNaturalization: initDate(data?.fatherDateOfNaturalization),
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={USCitizenFatherP1Validation}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <Info>
                <InfoBold>Current Legal Name of U.S. Citizen Father</InfoBold>
              </Info>
              <FormWrapper>
                <InputField
                  name="fatherCurrentLegalFamilyName"
                  label="Family Name"
                  coloredLabel="(Last Name)"
                  placeholder=""
                />
                <InputField
                  name="fatherCurrentLegalGivenName"
                  label="Given Name"
                  coloredLabel="(First Name)"
                  placeholder=""
                />
              </FormWrapper>

              <FormWrapper>
                <InputField
                  name="fatherCurrentLegalMiddleName"
                  label="Middle Name"
                  placeholder=""
                />
                <div></div>
              </FormWrapper>

              <FormWrapper>
                <DatePickerField
                  name="fatherDateOfBirth"
                  label="Date of Birth"
                  placeholder={USDatePlaceholderFormat}
                  disableFuture={true}
                />
                <SelectField
                  name="fatherCountryOfBirth"
                  label="Country of Birth"
                  placeholder="-Select-"
                  options={formattedCountryData}
                  isLoading={countryLoading}
                />
              </FormWrapper>

              <FormWrapper>
                <SelectField
                  name="fatherCountryOfCitizenship"
                  label="Country of Citizenship/Nationality"
                  placeholder="-Select-"
                  options={nationCountryData}
                  isLoading={nationCountryLoading}
                />
                <div></div>
              </FormWrapper>

              <FormWrapper>
                <RadioComp
                  title="Is your father presently alive?"
                  name="isFatherAlive"
                  options={GenericYesNo}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "fatherDateOfDeath", value: null },
                      {
                        name: "fatherPhysicalAddressStreetNumberAndName",
                        value: "",
                      },
                      { name: "fatherPhysicalAddressCityOrTown", value: "" },
                      { name: "fatherPhysicalAddressState", value: null },
                      { name: "fatherPhysicalAddressAptSteFlr", value: null },
                      {
                        name: "fatherPhysicalAddressAptSteFlrNumber",
                        value: "",
                      },
                      { name: "fatherPhysicalAddressZipCode", value: "" },
                    ]);
                  }}
                />
                <div></div>
              </FormWrapper>

              {formik.values.isFatherAlive === GenericYesNoOptionsValue.no && (
                <FormWrapper>
                  <DatePickerField
                    name="fatherDateOfDeath"
                    label="Date of Death"
                    placeholder={USDatePlaceholderFormat}
                    disableFuture={true}
                  />

                  <div></div>
                </FormWrapper>
              )}

              {formik.values.isFatherAlive === GenericYesNoOptionsValue.yes && (
                <>
                  <FormWrapper>
                    <InputField
                      label="Street number and name"
                      placeholder=""
                      name={`fatherPhysicalAddressStreetNumberAndName`}
                    />

                    <InputField
                      label="City or Town"
                      placeholder=""
                      name={`fatherPhysicalAddressCityOrTown`}
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <SelectField
                      label="Is there an apartment, suite or floor?"
                      name={`fatherPhysicalAddressAptSteFlr`}
                      options={ApartmentSuiteFloorData}
                    />

                    <div>
                      {formik.values.fatherPhysicalAddressAptSteFlr?.value && (
                        <InputField
                          label={`${formik.values.fatherPhysicalAddressAptSteFlr?.value} number`}
                          placeholder=""
                          name={`fatherPhysicalAddressAptSteFlrNumber`}
                        />
                      )}
                    </div>
                  </FormWrapper>

                  <FormWrapper>
                    <SelectField
                      name={`fatherPhysicalAddressCountry`}
                      label="Country"
                      coloredLabel="(foreign address only)"
                      placeholder="-Select-"
                      options={addressCountryData}
                      isLoading={addressCountryLoading}
                      onChange={(value) => {
                        setCountry(value.label);
                        formik.setFieldValue(
                          `fatherPhysicalAddressState`,
                          null
                        );
                      }}
                    />
                    <SelectField
                      name={`fatherPhysicalAddressState`}
                      label="State or Territory"
                      placeholder="-Select-"
                      options={formattedStateData}
                      isLoading={stateLoading}
                      isDisabled={!selectedCountry}
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <div>
                      <InputField
                        label="ZIP Code"
                        placeholder=""
                        name={`fatherPhysicalAddressZipCode`}
                      />
                    </div>

                    <div>
                      <InputField
                        label="Province"
                        coloredLabel="(foreign address only)"
                        placeholder=""
                        name={`fatherPhysicalAddressProvince`}
                        isDisabled={
                          !formik.values.fatherPhysicalAddressCountry ||
                          formik.values.fatherPhysicalAddressCountry?.value.toLowerCase() ===
                            "united states"
                        }
                      />
                    </div>
                  </FormWrapper>
                  <FormWrapper>
                    <div>
                      <InputField
                        label="Postal Code"
                        coloredLabel="(foreign address only)"
                        placeholder=""
                        name={`fatherPhysicalAddressPostalCode`}
                        isDisabled={
                          !formik.values.fatherPhysicalAddressCountry?.value ||
                          formik.values.fatherPhysicalAddressCountry?.value.toLowerCase() ===
                            "united states"
                        }
                      />
                    </div>

                    <div></div>
                  </FormWrapper>
                </>
              )}

              <FormWrapper>
                <SelectField
                  label="My father is a U.S. citizen by:"
                  name="fatherCitizenshipStatus"
                  options={FatherCitizenByData}
                  onChange={(value) => {
                    // console.log(value);

                    formik.setFieldValue(
                      "fatherSpousePhysicalAddressState",
                      ""
                    );
                    formik.setFieldValue("fatherAlienRegistrationNumber", "");
                    formik.setFieldValue(
                      "fatherPlaceOfNaturalizationCourtOrUSCISOffice",
                      ""
                    );
                    formik.setFieldValue(
                      "fatherPlaceOfNaturalizationCityOrTown",
                      ""
                    );
                    formik.setFieldValue(
                      "fatherPlaceOfNaturalizationState",
                      null
                    );
                    formik.setFieldValue(
                      "fatherCertificateOfNaturalizationNumber",
                      ""
                    );
                    formik.setFieldValue("fatherNaturalizationANumber", "");
                    formik.setFieldValue("fatherDateOfNaturalization", null);
                  }}
                />
                <div></div>
              </FormWrapper>

              {formik.values.fatherCitizenshipStatus?.value ===
                FatherCitizenByDataValues.birthAbroad && (
                <FormWrapper>
                  <InputField
                    name="fatherCertificateOfCitizenshipNumber"
                    label="Certificate of Citizenship Number"
                    placeholder=""
                  />

                  <InputField
                    label="Alien Registration Number (A-Number)"
                    coloredLabel="(optional)"
                    placeholder=""
                    name="fatherAlienRegistrationNumber"
                  />
                </FormWrapper>
              )}

              {formik.values.fatherCitizenshipStatus?.value ===
                FatherCitizenByDataValues.naturalization && (
                <>
                  <FormWrapper>
                    <InputField
                      name="fatherPlaceOfNaturalizationCourtOrUSCISOffice"
                      label="Place of Naturalization"
                      coloredLabel="(Name of Court or USCIS Office Location)"
                      placeholder=""
                    />

                    <InputField
                      label="City or Town"
                      placeholder=""
                      name={`fatherPlaceOfNaturalizationCityOrTown`}
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <SelectField
                      name={`fatherPlaceOfNaturalizationState`}
                      label="State"
                      placeholder="-Select-"
                      options={naturalizationStateData}
                      isLoading={naturalizationStateLoading}
                      isDisabled={!naturalizationSelectedCountry}
                    />

                    <InputField
                      name="fatherCertificateOfNaturalizationNumber"
                      label="Certificate of Naturalization Number"
                      placeholder=""
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <InputField
                      label="Alien Registration Number (A-Number)"
                      coloredLabel="(optional)"
                      placeholder=""
                      name="fatherNaturalizationANumber"
                    />

                    <DatePickerField
                      name="fatherDateOfNaturalization"
                      label="Date of Naturalization"
                      placeholder={USDatePlaceholderFormat}
                      disableFuture={true}
                    />
                  </FormWrapper>
                </>
              )}

              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default USCitizenFatherP1;

const Wrapper = styled.div``;
