import React from "react";
import FAQ from "../eligibility/components/FAQ";
import Process from "../landing page/components/Process";
import Testmonial from "../landing page/components/Testmonial";
import Hero from "../../../images/web/pricing-hero.png";
import styled from "styled-components";
import PricingTab from "./components/PricingTab";

const Services = () => {
  return (
    <Wrapper>
      <ServicesHero>
        <h1>our services & packages</h1>
      </ServicesHero>
      <PricingTab />
      <Process />
      <FAQ />
      <Testmonial />
    </Wrapper>
  );
};

export default Services;

const Wrapper = styled.div``;

const ServicesHero = styled.div`
  background-image: url(${Hero});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 6rem 2rem;

  @media screen and (min-width: 768px) {
    padding: 10rem;
  }

  & > h1 {
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: capitalize;

    @media screen and (min-width: 768px) {
      font-size: 2.5rem;
    }
  }
`;
