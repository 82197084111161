import { FieldArray, Form, Formik } from "formik";
import styled from "styled-components";
import {
  GenericYesNo,
  GenericYesNoOptionsValue,
  initDate,
  initInput,
  ToSaveDate,
  USDateFormat,
} from "../../components/data";
import FormSectionWrapper from "../../components/FormSectionWrapper";
import { FormWrapper } from "../../components/styles";
import FormBtn from "../../components/FormBtn";
import { N600MilitaryServicePayload } from "../../../../api/n600/types";
import { Modify } from "../../../../types/types";
import { Dayjs } from "dayjs";
import RadioComp from "../../components/RadioComp";
import { N600RelatesToData } from "./data";
import GroupedSection from "../../components/GroupedSection";
import AddAnotherBtn from "../../components/AddAnotherBtn";
import { toast } from "react-toastify";
import Colors from "../../../../styles/Colors";
import DatePickerField from "../../components/formik fields/DatePickerField";
import {
  useGetN600MilitaryService,
  useSaveN600MilitaryService,
} from "../../../../hooks/n600/useN600";
import useToNextSection from "../../../../hooks/useToNextSection";
import { N600MilitaryServiceValidation } from "./validation";

const dateOfServiceArrayValues: DateOfServiceArrayPayload = {
  fromDate: null,
  toDate: null,
};

export interface DateOfServiceArrayPayload {
  fromDate: Dayjs | null; // From (mm/dd/yyyy)
  toDate: Dayjs | null; // To (mm/dd/yyyy)
}

export interface N600MilitaryServiceData
  extends Modify<
    Omit<N600MilitaryServicePayload, "fromDate" | "toDate">,
    {
      dateOfServiceArray: DateOfServiceArrayPayload[];
    }
  > {}

const MilitaryInfo = () => {
  const { data, isFetching, isError } = useGetN600MilitaryService();
  const { mutate, isLoading: isSaving } = useSaveN600MilitaryService();

  const { toNextSection } = useToNextSection();

  const savedDateOfServiceArray = () => {
    if (data?.fromDate || data?.toDate) {
      const fromDateData = data?.fromDate
        .split(",")
        .map((ev: any) => ({ fromDate: initDate(ev) }));

      const toDateData = data?.toDate
        ?.split(",")
        .map((ev: any) => ({ toDate: initDate(ev) }));

      return fromDateData.map((ev: any, i: any) => ({
        ...ev,
        ...toDateData[i],
      }));
    } else return [dateOfServiceArrayValues];
  };

  const onSubmit = (values: N600MilitaryServiceData) => {
    const { dateOfServiceArray, ...rest } = values;

    const payload = {
      ...rest,
      fromDate: dateOfServiceArray
        .map((ev) => ToSaveDate(ev.fromDate, USDateFormat))
        .join(","),
      toDate: dateOfServiceArray
        .map((ev) => ToSaveDate(ev.toDate, USDateFormat))
        .join(","),
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValues: N600MilitaryServiceData = {
    parentServedInUSArmedForces: initInput(data?.parentServedInUSArmedForces),
    parentWhoServed: initInput(data?.parentWhoServed),

    dateOfServiceArray: savedDateOfServiceArray(),
    typeOfDischarge: initInput(data?.typeOfDischarge),
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={N600MilitaryServiceValidation}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <div>
                  <RadioComp
                    title="Has your U.S. citizen parent served in the U.S. Armed Forces?"
                    name="parentServedInUSArmedForces"
                    options={GenericYesNo}
                  />
                </div>
              </FormWrapper>

              {formik.values.parentServedInUSArmedForces ===
                GenericYesNoOptionsValue.yes && (
                <>
                  <FormWrapper>
                    <div>
                      <RadioComp
                        title="Which parent served in the U.S. Armed Forces?"
                        name="parentWhoServed"
                        options={N600RelatesToData}
                      />
                    </div>
                  </FormWrapper>

                  <FieldArray
                    name="dateOfServiceArray"
                    render={(arrayHelpers) => (
                      <div className="px-2 py-3 mb-8 border rounded">
                        {formik.values.dateOfServiceArray.map((_, index) => (
                          <GroupedSection
                            key={index}
                            index={index}
                            onCancel={() => arrayHelpers.remove(index)}
                          >
                            <div className={`text-[${Colors.Blue00}]`}>
                              {index === 0
                                ? "Dates of Service"
                                : `Entry${index + 1}:`}
                            </div>
                            <FormWrapper>
                              <div>
                                <DatePickerField
                                  name={`dateOfServiceArray[${index}].fromDate`}
                                  label="From (mm/dd/yyyy) "
                                />
                              </div>

                              <div>
                                <DatePickerField
                                  name={`dateOfServiceArray[${index}].toDate`}
                                  label="To (mm/dd/yyyy)"
                                />
                              </div>
                            </FormWrapper>
                            <div className="-mb-4" />
                          </GroupedSection>
                        ))}
                        <AddAnotherBtn
                          onClick={() => {
                            if (formik.values.dateOfServiceArray.length === 2) {
                              toast.error("Limit Reached");
                              return;
                            }
                            arrayHelpers.push(dateOfServiceArrayValues);
                          }}
                        />
                      </div>
                    )}
                  />

                  <FormWrapper>
                    <div>
                      <RadioComp
                        title="Type of Discharge"
                        name="typeOfDischarge"
                        options={[
                          { label: "Honorable", value: "Honorable" },
                          {
                            value: "Other than Honorable",
                            label: "Other than Honorable",
                          },
                          {
                            value: "Dishonorable",
                            label: "Dishonorable",
                          },
                        ]}
                      />
                    </div>
                  </FormWrapper>
                </>
              )}

              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default MilitaryInfo;

const Wrapper = styled.div``;
