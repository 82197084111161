import { FC } from "react";
import styled from "styled-components";
import Colors from "../../../styles/Colors";
import Spinner from "../../../utils/Spinner";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  isLoading: boolean;
  isError: boolean;
  children: JSX.Element;
}

const RequestWrapper: FC<Props> = ({ data, isLoading, isError, children }) => {
  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner color={Colors.Blue00} size={35} />
      </SpinnerWrapper>
    );

  if (isError)
    return (
      <SpinnerWrapper>
        An error occurred. Please try again later.
      </SpinnerWrapper>
    );

  if (!data || data.length === 0)
    return (
      <SpinnerWrapper>There is no data available at the moment.</SpinnerWrapper>
    );

  return children;
};

export default RequestWrapper;

const SpinnerWrapper = styled.div<{ $height?: string; $width?: string }>`
  display: flex;
  height: ${({ $height }) => ($height ? $height : `300px`)};
  width: ${({ $width }) => $width && $width};
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.75rem;
  letter-spacing: 0.2px;
  color: ${Colors.Black31};
`;
