import styled from "styled-components";
import Colors from "../../../../../../../styles/Colors";

export const SingleCaseBoxWrapper = styled.div`
  background-color: white;
  border-radius: 12px;
  padding: 24px 20px;
`;

export const SingleCaseBoxHeader = styled.div`
  font-size: 1rem;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  padding-bottom: 6px;
  border-bottom: 0.5px solid #a7aabd80;
  margin-bottom: 24px;
`;

export const BusinessBoxWrapper = styled.div`
  border-bottom: 0.5px solid #a7aabd80;
  padding: 24px 20px;
  background-color: ${Colors.WhiteF9};
  border-radius: 12px;
`;
