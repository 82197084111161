import React, { useState } from "react";
import { BillingType } from "./components/data";
import MonthlyBilling from "./components/MonthlyBilling";
import PerApplicationBilling from "./components/PerApplicationBilling";

const Billing = () => {
  const [billingType, setBillingType] = useState<BillingType>(
    BillingType.monthly
  );

  const changeBillingType = () => {
    setBillingType(
      billingType === BillingType.perApplication
        ? BillingType.monthly
        : BillingType.perApplication
    );
  };

  return (
    <div>
      <div className="relative isolate bg-white px-6 lg:px-8">
        <div
          aria-hidden="true"
          className="absolute inset-x-0 -top-3 -z-10 transform-gpu overflow-hidden px-36 blur-3xl"
        >
          <div
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
            className="mx-auto aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
          />
        </div>

        <div className="mt-16 flex justify-center">
          <fieldset aria-label="Payment frequency">
            <div
              role="button"
              onClick={changeBillingType}
              className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
            >
              <div
                className={`cursor-pointer rounded-full px-2.5 py-1 text-gray-500 ${
                  billingType === BillingType.perApplication &&
                  "bg-Blue00 text-white"
                }`}
              >
                Per Application
              </div>
              <div
                className={`cursor-pointer rounded-full px-2.5 py-1 text-gray-500 ${
                  billingType === BillingType.monthly && "bg-Blue00 text-white"
                }`}
              >
                Monthly
              </div>
            </div>
          </fieldset>
        </div>

        {billingType === BillingType.perApplication && (
          <PerApplicationBilling />
        )}

        {billingType === BillingType.monthly && <MonthlyBilling />}
      </div>
    </div>
  );
};

export default Billing;
