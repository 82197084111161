import styled from "styled-components";
import { Form, Formik } from "formik";
import {
  n400CheckPart1Res,
  N400EligibilityPayload,
} from "../../../../../api/n400/types";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { FormWrapper } from "../../../components/styles";
import RadioComp from "../../../components/RadioComp";
import {
  GenericYesNo,
  GenericYesNoOptionsValue,
  ToSaveDate,
  initDate,
  initInput,
} from "../../../components/data";
import { getCheckListFieldValidation } from "../data";
import { RadioListWrapper, Title } from "../styles";
import FormBtn from "../../../components/FormBtn";
// import useToNextSection from "../../../../../hooks/useToNextSection";
import {
  useGetN400CheckPart1,
  useSaveN400CheckPart1,
} from "../../../../../hooks/n400/useN400";
import { Modify } from "../../../../../types/types";
// import { Sub } from "../../../profile/components/styles";
import { CheckPart1Validation } from "../validation";
import InputField from "../../../components/formik fields/InputField";
import DatePickerField from "../../../components/formik fields/DatePickerField";
import useToNextSection from "../../../../../hooks/useToNextSection";
import { Dayjs } from "dayjs";

export interface CheckFormData1
  extends Modify<
    n400CheckPart1Res,
    {
      date_you_joined: Dayjs | null;
      date_you_left: Dayjs | null;
      ever_claim_to_be_citizen_risky_checkbox: boolean;
      ever_register_to_vote_in_election_risky_checkbox: boolean;
      do_you_owe_overdue_taxes_risky_checkbox: boolean;
      ever_called_yourself_non_resident_on_tax_risky_checkbox: boolean;
      ever_been_member_of_communist_or_totalitarian_party_risky_checkbox: boolean;
      ever_supported_opposition_to_all_organized_government_risky_checkbox: boolean;
      ever_supported_world_communism_risky_checkbox: boolean;
      ever_supported_totalitarian_in_us_risky_checkbox: boolean;
      ever_supported_overthrow_of_government_risky_checkbox: boolean;
      ever_supported_killing_of_officers_of_us_risky_checkbox: boolean;
      ever_supported_unlawful_damage_of_property_risky_checkbox: boolean;
      ever_supported_sabotage_risky_checkbox: boolean;
      // ever_been_member_of_terrorist_organization_risky_checkbox: boolean;
      ever_used_weapon_with_intent_to_harm_risky_checkbox: boolean;
      ever_engaged_in_kidnapping_assassination_hijacking_risky_checkbox: boolean;
      ever_threatened_tried_conspired_prepared_planned_incited_risky_checkbox: boolean;
      ever_involved_in_genocide_risky_checkbox: boolean;
      ever_involved_in_torture_risky_checkbox: boolean;
      ever_involved_in_killing_or_trying_to_kill_risky_checkbox: boolean;
      ever_involved_in_hurting_or_trying_to_hurt_risky_checkbox: boolean;
      ever_involved_in_forcing_or_trying_to_force_sexual_relation_risky_checkbox: boolean;
      ever_involved_in_not_letting_one_practice_religion_risky_checkbox: boolean;
      ever_caused_harm_because_of_race_religion_national_origin_risky_checkbox: boolean;
      // name_of_country_risky_checkbox: boolean;
      // name_of_military_unit_risky_checkbox: boolean;
      // rank_or_position_risky_checkbox: boolean;
      // date_you_joined_risky_checkbox: boolean;
      // date_you_left_risky_checkbox: boolean;
      ever_a_member_of_paramilitary_unit_risky_checkbox: boolean;
      ever_served_in_any_other_place_people_were_forced_to_stay_risky_checkbox: boolean;
      ever_part_of_a_group_that_used_weapon_risky_checkbox: boolean;
      did_you_use_a_weapon_on_another_person_risky_checkbox: boolean;
      did_you_threaten_to_use_a_weapon_on_another_person_risky_checkbox: boolean;
      ever_sell_weapons_risky_checkbox: boolean;
      ever_receive_military_or_weapons_training_risky_checkbox: boolean;
      ever_recruit_person_under_age_15_to_help_armed_force_risky_checkbox: boolean;
      ever_used_person_under_age_15_to_help_people_in_combat_risky_checkbox: boolean;
    }
  > {}

interface Props {
  formEligibility: N400EligibilityPayload;
}

const CheckPart1: React.FC<Props> = ({ formEligibility }) => {
  const { toNextSection } = useToNextSection();
  const { data, isFetching, isError } = useGetN400CheckPart1();
  const { mutate, isLoading: isSaving } = useSaveN400CheckPart1();

  const onSubmit = (values: CheckFormData1) => {
    const {
      ever_claim_to_be_citizen_risky_checkbox,
      ever_register_to_vote_in_election_risky_checkbox,
      do_you_owe_overdue_taxes_risky_checkbox,
      ever_called_yourself_non_resident_on_tax_risky_checkbox,
      ever_been_member_of_communist_or_totalitarian_party_risky_checkbox,
      ever_supported_opposition_to_all_organized_government_risky_checkbox,
      ever_supported_world_communism_risky_checkbox,
      ever_supported_totalitarian_in_us_risky_checkbox,
      ever_supported_overthrow_of_government_risky_checkbox,
      ever_supported_killing_of_officers_of_us_risky_checkbox,
      ever_supported_unlawful_damage_of_property_risky_checkbox,
      ever_supported_sabotage_risky_checkbox,
      // ever_been_member_of_terrorist_organization_risky_checkbox,
      ever_used_weapon_with_intent_to_harm_risky_checkbox,
      ever_engaged_in_kidnapping_assassination_hijacking_risky_checkbox,
      ever_threatened_tried_conspired_prepared_planned_incited_risky_checkbox,
      ever_involved_in_genocide_risky_checkbox,
      ever_involved_in_torture_risky_checkbox,
      ever_involved_in_killing_or_trying_to_kill_risky_checkbox,
      ever_involved_in_hurting_or_trying_to_hurt_risky_checkbox,
      ever_involved_in_forcing_or_trying_to_force_sexual_relation_risky_checkbox,
      ever_involved_in_not_letting_one_practice_religion_risky_checkbox,
      ever_caused_harm_because_of_race_religion_national_origin_risky_checkbox,
      // name_of_country_risky_checkbox,
      // name_of_military_unit_risky_checkbox,
      // rank_or_position_risky_checkbox,
      // date_you_joined_risky_checkbox,
      // date_you_left_risky_checkbox,
      ever_a_member_of_paramilitary_unit_risky_checkbox,
      ever_served_in_any_other_place_people_were_forced_to_stay_risky_checkbox,
      ever_part_of_a_group_that_used_weapon_risky_checkbox,
      did_you_use_a_weapon_on_another_person_risky_checkbox,
      did_you_threaten_to_use_a_weapon_on_another_person_risky_checkbox,
      ever_sell_weapons_risky_checkbox,
      ever_receive_military_or_weapons_training_risky_checkbox,
      ever_recruit_person_under_age_15_to_help_armed_force_risky_checkbox,
      ever_used_person_under_age_15_to_help_people_in_combat_risky_checkbox,
      date_you_joined,
      date_you_left,
      ...rest
    } = values;

    const payload = {
      ...rest,
      date_you_joined: ToSaveDate(date_you_joined),
      date_you_left: ToSaveDate(date_you_left),
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValue: CheckFormData1 = {
    ever_claim_to_be_citizen: initInput(data?.ever_claim_to_be_citizen),
    ever_register_to_vote_in_election: initInput(
      data?.ever_register_to_vote_in_election
    ),
    do_you_owe_overdue_taxes: initInput(data?.do_you_owe_overdue_taxes),
    ever_called_yourself_non_resident_on_tax: initInput(
      data?.ever_called_yourself_non_resident_on_tax
    ),
    ever_been_member_of_communist_or_totalitarian_party: initInput(
      data?.ever_been_member_of_communist_or_totalitarian_party
    ),
    ever_supported_opposition_to_all_organized_government: initInput(
      data?.ever_supported_opposition_to_all_organized_government
    ),
    ever_supported_world_communism: initInput(
      data?.ever_supported_world_communism
    ),
    ever_supported_totalitarian_in_us: initInput(
      data?.ever_supported_totalitarian_in_us
    ),
    ever_supported_overthrow_of_government: initInput(
      data?.ever_supported_overthrow_of_government
    ),
    ever_supported_killing_of_officers_of_us: initInput(
      data?.ever_supported_killing_of_officers_of_us
    ),
    ever_supported_unlawful_damage_of_property: initInput(
      data?.ever_supported_unlawful_damage_of_property
    ),
    ever_supported_sabotage: initInput(data?.ever_supported_sabotage),
    // ever_been_member_of_terrorist_organization: initInput(
    //   data?.ever_been_member_of_terrorist_organization
    // ),
    ever_used_weapon_with_intent_to_harm: initInput(
      data?.ever_used_weapon_with_intent_to_harm
    ),
    ever_engaged_in_kidnapping_assassination_hijacking: initInput(
      data?.ever_engaged_in_kidnapping_assassination_hijacking
    ),
    ever_threatened_tried_conspired_prepared_planned_incited: initInput(
      data?.ever_threatened_tried_conspired_prepared_planned_incited
    ),
    ever_involved_in_genocide: initInput(data?.ever_involved_in_genocide),
    ever_involved_in_torture: initInput(data?.ever_involved_in_torture),
    ever_involved_in_killing_or_trying_to_kill: initInput(
      data?.ever_involved_in_killing_or_trying_to_kill
    ),
    ever_involved_in_hurting_or_trying_to_hurt: initInput(
      data?.ever_involved_in_hurting_or_trying_to_hurt
    ),
    ever_involved_in_forcing_or_trying_to_force_sexual_relation: initInput(
      data?.ever_involved_in_forcing_or_trying_to_force_sexual_relation
    ),
    ever_involved_in_not_letting_one_practice_religion: initInput(
      data?.ever_involved_in_not_letting_one_practice_religion
    ),
    ever_caused_harm_because_of_race_religion_national_origin: initInput(
      data?.ever_caused_harm_because_of_race_religion_national_origin
    ),
    ever_a_member_of_military_unit: initInput(
      data?.ever_a_member_of_military_unit
    ),
    name_of_country: initInput(data?.name_of_country),
    name_of_military_unit: initInput(data?.name_of_military_unit),
    rank_or_position: initInput(data?.rank_or_position),
    date_you_joined: initDate(data?.date_you_joined),
    date_you_left: initDate(data?.date_you_left),
    ever_a_member_of_paramilitary_unit: initInput(
      data?.ever_a_member_of_paramilitary_unit
    ),
    ever_served_in_any_other_place_people_were_forced_to_stay: initInput(
      data?.ever_served_in_any_other_place_people_were_forced_to_stay
    ),
    ever_part_of_a_group_that_used_weapon: initInput(
      data?.ever_part_of_a_group_that_used_weapon
    ),
    did_you_use_a_weapon_on_another_person: initInput(
      data?.did_you_use_a_weapon_on_another_person
    ),
    did_you_threaten_to_use_a_weapon_on_another_person: initInput(
      data?.did_you_threaten_to_use_a_weapon_on_another_person
    ),
    ever_sell_weapons: initInput(data?.ever_sell_weapons),
    ever_receive_military_or_weapons_training: initInput(
      data?.ever_receive_military_or_weapons_training
    ),
    ever_recruit_person_under_age_15_to_help_armed_force: initInput(
      data?.ever_recruit_person_under_age_15_to_help_armed_force
    ),
    ever_used_person_under_age_15_to_help_people_in_combat: initInput(
      data?.ever_used_person_under_age_15_to_help_people_in_combat
    ),

    ever_claim_to_be_citizen_risky_checkbox: false,
    ever_register_to_vote_in_election_risky_checkbox: false,
    do_you_owe_overdue_taxes_risky_checkbox: false,
    ever_called_yourself_non_resident_on_tax_risky_checkbox: false,
    ever_been_member_of_communist_or_totalitarian_party_risky_checkbox: false,
    ever_supported_opposition_to_all_organized_government_risky_checkbox: false,
    ever_supported_world_communism_risky_checkbox: false,
    ever_supported_totalitarian_in_us_risky_checkbox: false,
    ever_supported_overthrow_of_government_risky_checkbox: false,
    ever_supported_killing_of_officers_of_us_risky_checkbox: false,
    ever_supported_unlawful_damage_of_property_risky_checkbox: false,
    ever_supported_sabotage_risky_checkbox: false,
    // ever_been_member_of_terrorist_organization_risky_checkbox: false,
    ever_used_weapon_with_intent_to_harm_risky_checkbox: false,
    ever_engaged_in_kidnapping_assassination_hijacking_risky_checkbox: false,
    ever_threatened_tried_conspired_prepared_planned_incited_risky_checkbox:
      false,
    ever_involved_in_genocide_risky_checkbox: false,
    ever_involved_in_torture_risky_checkbox: false,
    ever_involved_in_killing_or_trying_to_kill_risky_checkbox: false,
    ever_involved_in_hurting_or_trying_to_hurt_risky_checkbox: false,
    ever_involved_in_forcing_or_trying_to_force_sexual_relation_risky_checkbox:
      false,
    ever_involved_in_not_letting_one_practice_religion_risky_checkbox: false,
    ever_caused_harm_because_of_race_religion_national_origin_risky_checkbox:
      false,
    // name_of_country_risky_checkbox: false,
    // name_of_military_unit_risky_checkbox: false,
    // rank_or_position_risky_checkbox: false,
    // date_you_joined_risky_checkbox: false,
    // date_you_left_risky_checkbox: false,
    ever_a_member_of_paramilitary_unit_risky_checkbox: false,
    ever_served_in_any_other_place_people_were_forced_to_stay_risky_checkbox:
      false,
    ever_part_of_a_group_that_used_weapon_risky_checkbox: false,
    did_you_use_a_weapon_on_another_person_risky_checkbox: false,
    did_you_threaten_to_use_a_weapon_on_another_person_risky_checkbox: false,
    ever_sell_weapons_risky_checkbox: false,
    ever_receive_military_or_weapons_training_risky_checkbox: false,
    ever_recruit_person_under_age_15_to_help_armed_force_risky_checkbox: false,
    ever_used_person_under_age_15_to_help_people_in_combat_risky_checkbox:
      false,
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValue}
          onSubmit={onSubmit}
          validationSchema={CheckPart1Validation}
        >
          {(formik) => (
            <Form>
              {/* <Title>
                When a question includes the word “EVER,” you must provide
                information about any of your actions or conduct that occurred
                anywhere in the world at any time unless the question specifies
                otherwise
              </Title> */}

              <FormWrapper>
                <RadioComp
                  name="ever_claim_to_be_citizen"
                  title="1. Have you EVER claimed to be a U.S. citizen (in writing or any other way)?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_claim_to_be_citizen"
                  )}
                />
              </FormWrapper>

              <FormWrapper>
                <RadioComp
                  name="ever_register_to_vote_in_election"
                  title={`2. Have you EVER registered to vote or voted in any Federal, state, or local election in the United States?`}
                  coloredLabel="If you lawfully voted only in a local election where noncitizens are eligible to vote, you may answer “No.”"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_register_to_vote_in_election"
                  )}
                />
              </FormWrapper>

              <FormWrapper>
                <RadioComp
                  name="do_you_owe_overdue_taxes"
                  title="3.Do you currently owe any overdue Federal, state, or local taxes in the United States?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "do_you_owe_overdue_taxes"
                  )}
                />
              </FormWrapper>

              <FormWrapper>
                <RadioComp
                  name="ever_called_yourself_non_resident_on_tax"
                  title="4. Since you became a lawful permanent resident, have you called yourself a “nonresident alien” on a Federal, state, or local tax return or decided not to file a tax return because you considered yourself to be a nonresident?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_called_yourself_non_resident_on_tax"
                  )}
                />
              </FormWrapper>

              <FormWrapper>
                <RadioComp
                  name="ever_been_member_of_communist_or_totalitarian_party"
                  title="5.a. Have you EVER Been a member of, involved in, or in any way associated with any Communist or totalitarian party anywhere in the world?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_been_member_of_communist_or_totalitarian_party"
                  )}
                />
              </FormWrapper>

              <Title>
                Have you <strong>EVER</strong> Advocated (supported and
                promoted) any of the following, or been a member of, involved
                in, or in any way associated with any group anywhere in the
                world that advocated any of the following:
              </Title>

              <RadioListWrapper>
                <RadioComp
                  name="ever_supported_opposition_to_all_organized_government"
                  title="5.b.i Opposition to all organized government;"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_supported_opposition_to_all_organized_government"
                  )}
                />

                <RadioComp
                  name="ever_supported_world_communism"
                  title="5.b.ii World communism;"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_supported_world_communism"
                  )}
                />

                <RadioComp
                  name="ever_supported_totalitarian_in_us"
                  title="5.b.iii The establishment in the United States of a totalitarian dictatorship;"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_supported_totalitarian_in_us"
                  )}
                />

                <RadioComp
                  name="ever_supported_overthrow_of_government"
                  title="5.b.iv The overthrow by force or violence or other unconstitutional means of the Government of the United States or all forms of law;"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_supported_overthrow_of_government"
                  )}
                />

                <RadioComp
                  name="ever_supported_killing_of_officers_of_us"
                  title="5.b.v The unlawful assaulting or killing of any officer or officers of the Government of the United States or of any other organized government because of their official character;"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_supported_killing_of_officers_of_us"
                  )}
                />
                <RadioComp
                  name="ever_supported_unlawful_damage_of_property"
                  title="5.b.vi The unlawful damage, injury, or destruction of property;"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_supported_unlawful_damage_of_property"
                  )}
                />

                <RadioComp
                  name="ever_supported_sabotage"
                  title="5.b.vii Sabotage"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_supported_sabotage"
                  )}
                />
              </RadioListWrapper>

              <Title>
                Have you <strong>EVER</strong> been a member of, involved in, or
                in any way associated with, or have you EVER provided money, a
                thing of value, services or labor, or any other assistance or
                support to a group that:
              </Title>

              <FormWrapper>
                <RadioComp
                  name="ever_used_weapon_with_intent_to_harm"
                  title="6.a. Used a weapon or explosive with intent to harm another person or cause damage to property?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_used_weapon_with_intent_to_harm"
                  )}
                />
              </FormWrapper>

              <FormWrapper>
                <RadioComp
                  name="ever_engaged_in_kidnapping_assassination_hijacking"
                  title="6.b. Engaged (participated) in kidnapping, assassination, or hijacking or sabotage of an airplane, ship, vehicle, or other mode of transportation?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_engaged_in_kidnapping_assassination_hijacking"
                  )}
                />
              </FormWrapper>

              <FormWrapper>
                <RadioComp
                  name="ever_threatened_tried_conspired_prepared_planned_incited"
                  title="6.c. Threatened, attempted (tried), conspired (planned with others), prepared, planned, advocated for, or incited (encouraged) others to commit any of the acts listed in Item Numbers 6.a. or 6.b.?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_threatened_tried_conspired_prepared_planned_incited"
                  )}
                />
              </FormWrapper>

              <Title>
                Have you <strong>EVER</strong> ordered, incited, called for,
                committed, assisted, helped with, or otherwise participated in
                any of the following:
              </Title>
              {/* <RadioListWrapper></RadioListWrapper> */}
              <RadioListWrapper>
                <RadioComp
                  name="ever_involved_in_torture"
                  title="7.a. Torture?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_involved_in_torture"
                  )}
                />

                <RadioComp
                  name="ever_involved_in_genocide"
                  title="7.b. Genocide?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_involved_in_genocide"
                  )}
                />

                <RadioComp
                  name="ever_involved_in_killing_or_trying_to_kill"
                  title="7.c. Killing or trying to kill any person?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_involved_in_killing_or_trying_to_kill"
                  )}
                />

                <RadioComp
                  name="ever_involved_in_hurting_or_trying_to_hurt"
                  title="7.d. Intentionally and severely injuring or trying to injure any person?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_involved_in_hurting_or_trying_to_hurt"
                  )}
                />

                <RadioComp
                  name="ever_involved_in_forcing_or_trying_to_force_sexual_relation"
                  title="7.e. Any kind of sexual contact or activity with any person who did not consent (did not agree) or was unable to consent (could not agree), or was being forced or threatened by you or by someone else?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_involved_in_forcing_or_trying_to_force_sexual_relation"
                  )}
                />

                <RadioComp
                  name="ever_involved_in_not_letting_one_practice_religion"
                  title="7.f. Not letting someone practice their religion?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_involved_in_not_letting_one_practice_religion"
                  )}
                />

                <RadioComp
                  name="ever_caused_harm_because_of_race_religion_national_origin"
                  title="7.g. Causing harm or suffering to any person because of their race, religion, national origin, membership in a particular social group, or political opinion?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_caused_harm_because_of_race_religion_national_origin"
                  )}
                />
              </RadioListWrapper>

              <FormWrapper>
                <RadioComp
                  name="ever_a_member_of_military_unit"
                  title="8.a. Have you EVER served in, been a member of, assisted (helped), or participated in any military or police unit?"
                  options={GenericYesNo}
                />
              </FormWrapper>

              <FormWrapper>
                <RadioComp
                  name="ever_a_member_of_paramilitary_unit"
                  title="8.b. Have you EVER served in, been a member of, assisted (helped), or participated in any armed group (a group that carries weapons), for example: paramilitary unit (a group of people who act like a military group but are not part of the official military), self-defense unit, vigilante unit, rebel group, or guerrilla group?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_a_member_of_paramilitary_unit"
                  )}
                />
              </FormWrapper>

              {(formik.values.ever_a_member_of_military_unit ===
                GenericYesNoOptionsValue.yes ||
                formik.values.ever_a_member_of_paramilitary_unit ===
                  GenericYesNoOptionsValue.yes) && (
                <div>
                  <FormWrapper>
                    <div>
                      <InputField
                        name="name_of_country"
                        label="Name of Country"
                        placeholder=""
                      />
                    </div>
                    <div>
                      <InputField
                        name="name_of_military_unit"
                        label="Name of Military Unit"
                        placeholder=""
                      />
                    </div>
                  </FormWrapper>

                  <FormWrapper>
                    <div>
                      <InputField
                        name="rank_or_position"
                        label="Rank or Position"
                        placeholder=""
                      />
                    </div>
                    <div></div>
                  </FormWrapper>

                  <FormWrapper>
                    <div>
                      <DatePickerField
                        name="date_you_joined"
                        label="Date you joined (MM/DD/YYYY)"
                        placeholder=""
                        disableFuture={true}
                      />
                    </div>
                    <div>
                      <DatePickerField
                        name="date_you_left"
                        label="Date you left (MM/DD/YYYY)"
                        placeholder=""
                        disableFuture={true}
                      />
                    </div>
                  </FormWrapper>
                </div>
              )}
              {/* 
              <Sub>
                If you answered “Yes” to Item Number 8.a. or Item Number 8.b.,
                include the name of the country, the name of the military unit
                or armed group, your rank or position, and your dates of
                involvement in your explanation in Part 14. Additional
                Information.
              </Sub> */}

              <FormWrapper>
                <RadioComp
                  name="ever_served_in_any_other_place_people_were_forced_to_stay"
                  title="9. Have you EVER worked, volunteered, or otherwise served in a place where people were detained (forced to stay), for example, a prison, jail, prison camp (a camp where prisoners of war or political prisoners are kept), detention facility, or labor camp, or have you EVER directed or participated in any other activity that involved detaining people?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_served_in_any_other_place_people_were_forced_to_stay"
                  )}
                />
              </FormWrapper>

              <FormWrapper>
                <RadioComp
                  name="ever_part_of_a_group_that_used_weapon"
                  title="10.a. Were you EVER a part of any group, or did you EVER help any group, unit, or organization that used a weapon against any person, or threatened to do so?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_part_of_a_group_that_used_weapon"
                  )}
                />
              </FormWrapper>

              {formik.values.ever_part_of_a_group_that_used_weapon ===
                GenericYesNoOptionsValue.yes && (
                <>
                  <FormWrapper>
                    <RadioComp
                      name="did_you_use_a_weapon_on_another_person"
                      title="10.b. when you were part of this group, or when you helped this group, did you ever use a weapon against another person?"
                      options={GenericYesNo}
                      toolTipProps={getCheckListFieldValidation(
                        formik,
                        "did_you_use_a_weapon_on_another_person"
                      )}
                    />
                  </FormWrapper>

                  <FormWrapper>
                    <RadioComp
                      name="did_you_threaten_to_use_a_weapon_on_another_person"
                      title="10.c. when you were part of this group, or when you helped this group, did you ever threaten another person that you would use a weapon against that person?"
                      options={GenericYesNo}
                      toolTipProps={getCheckListFieldValidation(
                        formik,
                        "did_you_threaten_to_use_a_weapon_on_another_person"
                      )}
                    />
                  </FormWrapper>
                </>
              )}

              <FormWrapper>
                <RadioComp
                  name="ever_sell_weapons"
                  title="11. Have you EVER sold, provided, or transported weapons, or assisted any person in selling, providing, or transporting weapons, which you knew or believed would be used against another person?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_sell_weapons"
                  )}
                />
              </FormWrapper>

              <FormWrapper>
                <RadioComp
                  name="ever_receive_military_or_weapons_training"
                  title="12. Have you EVER received any weapons training, paramilitary training, or other military-type training?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_receive_military_or_weapons_training"
                  )}
                />
              </FormWrapper>

              <FormWrapper>
                <RadioComp
                  name="ever_recruit_person_under_age_15_to_help_armed_force"
                  title="13. Have you EVER recruited (asked), enlisted (signed up), conscripted (required to join), or used any person under 15 years of age to serve in or help an armed group, or attempted or worked with others to do so?"
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_recruit_person_under_age_15_to_help_armed_force"
                  )}
                />
              </FormWrapper>

              <FormWrapper>
                <RadioComp
                  name="ever_used_person_under_age_15_to_help_people_in_combat"
                  title="14. Have you EVER used any person under 15 years of age to take part in hostilities or attempted or worked with others to do so? This could include participating in combat or providing services related to combat (such as serving as a messenger or transporting supplies)."
                  options={GenericYesNo}
                  toolTipProps={getCheckListFieldValidation(
                    formik,
                    "ever_used_person_under_age_15_to_help_people_in_combat"
                  )}
                />
              </FormWrapper>
              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default CheckPart1;

const Wrapper = styled.div``;
