import { FC } from "react";
import RequestWrapper from "../RequestWrapper";
import { TBODY, TD, TH, THEAD, TR, Table, TableWrapper } from "../styles";
import { DataItemsProps, tableDataProps } from "../type";
import { truncateTxt } from "../../../../utils";

interface Props {
  dataItems?: DataItemsProps[];
  tableData: tableDataProps[];
  isLoading: boolean;
  isError: boolean;
  actionComp?: (data: DataItemsProps) => JSX.Element | string;
}

const TableSection: FC<Props> = ({
  dataItems,
  tableData,
  isLoading,
  isError,
  actionComp,
}) => {
  const mainTableData = tableData.filter((ev) => !ev.hideOnTable);

  return (
    <RequestWrapper data={dataItems} isError={isError} isLoading={isLoading}>
      <TableWrapper>
        <Table>
          <THEAD>
            <TR>
              {mainTableData.map((ev, i) => (
                <TH width={ev.columnWidth} key={i}>
                  {ev.columnName}
                </TH>
              ))}
            </TR>
          </THEAD>

          <TBODY>
            {dataItems?.map((items, i) => (
              <TR key={i}>
                {mainTableData.map((data, index) => {
                  const itemValue = data.columnKey
                    ? items[data.columnKey as keyof typeof items]
                    : "--";
                  const lastEl = index === dataItems.length - 1;
                  const isNear = lastEl || index === dataItems.length - 2;

                  return (
                    <TD width={data.columnWidth} key={index}>
                      {data.maxLength ? (
                        truncateTxt(itemValue.toString() || " ", data.maxLength)
                      ) : data.isAction && actionComp ? (
                        <>{actionComp(items)}</>
                      ) : data.customComp ? (
                        <>{data.customComp(items, isNear)}</>
                      ) : (
                        itemValue?.toString()
                      )}
                    </TD>
                  );
                })}
              </TR>
            ))}
          </TBODY>
        </Table>
      </TableWrapper>
    </RequestWrapper>
  );
};

export default TableSection;
