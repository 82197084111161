import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  businessChangePassword,
  businessEditProfile,
  businessForgotPassword,
  businessGetChangePasswordCode,
  businessSignin,
  businessSignup,
  deleteBusinessAccount,
  getBusinessProfile,
} from "../../api/business/auth";
import { parseError } from "../../utils";
import { businessAuthQueryKeys } from "./types";

export const useBusinessChangePassword = () => {
  return useMutation(businessChangePassword, {
    onError: (error) => parseError(error),
  });
};

export const useBusinessSignup = () => {
  return useMutation(businessSignup, {
    onError: (error) => parseError(error),
  });
};

export const useBusinessSignin = () => {
  return useMutation(businessSignin, {
    onError: (error) => parseError(error),
  });
};

export const useBusinessForgotPassword = () => {
  return useMutation(businessForgotPassword, {
    onError: (error) => parseError(error),
  });
};

export const useBusinessGetChangePasswordCode = () => {
  return useQuery(
    [businessAuthQueryKeys.getChangePasswordCode],
    businessGetChangePasswordCode,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetBusinessProfile = (isEnabled?: boolean) => {
  return useQuery(
    [businessAuthQueryKeys.getBusinessProfile],
    getBusinessProfile,
    {
      enabled: isEnabled,
      onError: (error) => parseError(error),
      select: (data) => data.data.businessData,
      refetchOnWindowFocus: false,
    }
  );
};

export const useBusinessEditProfile = () => {
  const queryClient = useQueryClient();

  return useMutation(businessEditProfile, {
    onError: (error) => parseError(error),
    onSuccess: () =>
      queryClient.invalidateQueries([businessAuthQueryKeys.getBusinessProfile]),
  });
};

export const useDeleteBusinessAccount = () => {
  return useMutation(deleteBusinessAccount);
};
