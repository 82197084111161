import React, { useEffect } from "react";
import styled from "styled-components";
import { ProfileSections } from "./types";
import { useSearchParams } from "react-router-dom";
import Dashboard from "./Dashboard";
import Applications from "./applications";
import Case from "./case";
import Setting from "./settings/index";
import { userDataStore } from "../../../../store/userData";
import Spinner from "../../../../utils/Spinner";
import Colors from "../../../../styles/Colors";
import Documents from "./documents";
import BusinessDashboardPage from "./business/dashboard";
import BusinessCasePage from "./business/case";
import BusinessUsersPage from "./business/users";
import { useSnapshot } from "valtio";
import { isBusinessDataStore } from "../../../../store/isBusinessData";
import { useGetUserData } from "../../../../hooks/utils/useUtils";
import { useGetBusinessProfile } from "../../../../hooks/business/useAuth";
import { businessUserDataStore } from "../../../../store/businessUserData";
import { BusinessQueryKey } from "../../../../api/utils/types";
import SingleCase from "./business/case/components/SingleCase";
import UsersDetails from "./business/users/components/UsersDetails";
import StaffManagement from "./business/staff";
import StaffDetails from "./business/staff/components/StaffDetails";
import Billing from "./business/billing";

const RightSection = () => {
  const { isBusinessData } = useSnapshot(isBusinessDataStore);
  const [searchParams, setSearchParams] = useSearchParams();
  const getType = searchParams.get("active");
  const staffId = searchParams.get("staffId");
  const getApplicationId = searchParams.get(BusinessQueryKey.appId);
  const getUserId = searchParams.get(BusinessQueryKey.userId);
  const { data, isFetching } = useGetUserData(!isBusinessData);
  userDataStore.userData = data;

  const { data: businessData } = useGetBusinessProfile(isBusinessData);
  businessUserDataStore.businessUserData = businessData;

  useEffect(() => {
    if (!getType) {
      searchParams.set("active", ProfileSections.dashboard);
      setSearchParams(searchParams);
    }
  }, [searchParams, getType, setSearchParams]);

  return (
    <Container>
      {isFetching ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <>
          {ProfileSections.dashboard === getType &&
            (isBusinessData ? <BusinessDashboardPage /> : <Dashboard />)}
          {ProfileSections.applications === getType && <Applications />}
          {ProfileSections.cases === getType && <Case />}
          {ProfileSections.documents === getType && <Documents />}
          {ProfileSections.settings === getType && <Setting />}

          {/* business */}
          {ProfileSections.caseManagement === getType &&
            (getApplicationId ? <SingleCase /> : <BusinessCasePage />)}
          {ProfileSections.users === getType &&
            (getUserId ? <UsersDetails /> : <BusinessUsersPage />)}
          {ProfileSections.staffManagement === getType && !staffId && (
            <StaffManagement />
          )}
          {ProfileSections.staffManagement === getType && staffId && (
            <StaffDetails />
          )}
          {ProfileSections.billing === getType && <Billing />}
        </>
      )}
    </Container>
  );
};

export default RightSection;

const Container = styled.div`
  width: 100%;
  height: 100%;

  input,
  textarea,
  .css-1qmnlw8-control {
    background-color: transparent;
  }
`;

const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.WhiteF9};
  border-radius: 12px;
`;
