import React, { useState } from "react";
import useIsPaid from "../../../../../hooks/useIsPaid";
import useScrollVisible from "../../../components/Review/useScrollVisible";
import {
  useGetN565Form,
  useGetN565Info,
  usePayN565,
} from "../../../../../hooks/n565/useN565";
import { useSearchParams } from "react-router-dom";
import ReviewBox, { ReviewData } from "../../../components/Review/ReviewBox";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import {
  pricingData,
  turnCheckFieldToReadable,
} from "../../../components/data";
import PricingModal from "../../../components/PricingModal";
import ReviewBtns from "../../../components/Review/ReviewBtns";
import { FormN565Progress } from "../types";
import { BtnFooterWrapper } from "../../../components/styles";
import FormBtn from "../../../components/FormBtn";
import ReviewTop from "../../../components/Review/ReviewTop";
import N565PaySuccess from "../N565PaySuccess";
import N565PayFail from "../N565PayFail";
import { FormCategories } from "../../../../../routes/type";
import ReviewPI from "../../../PI/components/ReviewPI";
// import { BasisForApplicationArrayPayload } from "../TypeOfApplication";
// import { camelCaseToWords } from "../../../../../utils";

const ReviewSection = () => {
  const { isFormPaid } = useIsPaid();
  const { showTop } = useScrollVisible();
  const { mutate: payMutate, isLoading: isPaying } = usePayN565();
  const { data, isFetching, isError } = useGetN565Info();
  const [searchParams] = useSearchParams();
  const isSuccess = searchParams.get("pay");
  const isFail = searchParams.get("fail");

  const { refetch, isFetching: isDownloading } = useGetN565Form(false);

  const docData = data?.infoFromCertificate;
  const currentInfoData = data?.infoAboutApplicant;
  const typeOfApplicationData = data?.applicationType;

  const [selectPlan, setSelectPlan] = useState<boolean>(false);

  const onSelectPlan = () => setSelectPlan(true);

  const onPay = (plan: "standard" | "premium", couponCode: string) => {
    payMutate(
      { plan, couponCode },
      {
        onSuccess: (data) => {
          window.location.replace(data.data.url);
        },
      }
    );
  };

  // const myObj = typeOfApplicationData?.basisForApplicationArray[0];
  // type basisForApplicationType = keyof BasisForApplicationArrayPayload;

  // console.log(
  //   typeOfApplicationData?.basisForApplicationArray[0],
  //   myObj &&
  //     Object.keys(myObj)
  //       .filter((k) => myObj[k as basisForApplicationType] === true)
  //       .map((ev) => camelCaseToWords(ev))
  // );

  const onDownload = () => refetch();

  const section1: ReviewData[] = [
    {
      label: "Given Name (First Name)",
      value: docData?.certificateFirstName,
    },
    {
      label: "Middle Name(s)",
      value: docData?.certificateMiddleName,
    },
    {
      label: "Family Name (Last Name)",
      value: docData?.certificateLastName,
    },
    {
      label: "Date of Birth on Certificate or Declaration",
      value: docData?.certificateDateOfBirth,
    },
    {
      label: "Country of Former Citizenship or Nationality",
      value: docData?.certificateCountryOfBirth,
    },
    {
      label: "Certificate or Declaration Number",
      value: docData?.certificateNumber,
    },
    {
      label: "Alien Registration Number",
      value: docData?.certificateANumber,
    },
    {
      label:
        "U.S. Citizenship and Immigration Services (USCIS) Office or Name of Court",
      value: docData?.whoIssuedCertificate,
    },
    {
      label: "Date of Issuance",
      value: docData?.certificateDateOfIssuance,
    },
  ];

  const section2: ReviewData[] = [
    {
      label: "Given Name (First Name)",
      value: currentInfoData?.firstName,
    },
    {
      label: "Middle Name(s)",
      value: currentInfoData?.middleName,
    },
    {
      label: "Family Name (Last Name)",
      value: currentInfoData?.lastName,
    },
    {
      label: "Have you used any other names since birth?",
      value: currentInfoData?.otherNames,
    },
    {
      section: {
        title: "Other Names",
        children: [
          {
            label: "Have you used any other names since birth?",
            value: currentInfoData?.otherNames,
          },
          {
            label: "Family Name",
            value: currentInfoData?.otherNamesLastName,
          },
          {
            label: "Given Name",
            value: currentInfoData?.otherNamesFirstName,
          },
          {
            label: "Middle Name(s)",
            value: currentInfoData?.otherNamesMiddleName,
          },
        ],
      },
    },
    {
      label: "In Care of Name",
      value: currentInfoData?.inCareOfName,
    },
    {
      label: "Street number and name",
      value: currentInfoData?.streetNumberAndName,
    },
    {
      label: "Is there an apartment, suite or floor?",
      value: currentInfoData?.apartmentSuiteOrFloor,
    },
    {
      label: "Address number",
      value: currentInfoData?.addressNumber,
    },
    {
      label: "City or Town",
      value: currentInfoData?.cityOrTown,
    },
    {
      label: "Country",
      value: currentInfoData?.country,
    },
    {
      label: "State or Territory",
      value: currentInfoData?.stateOrTerritory,
    },
    {
      label: "ZIP Code",
      value: currentInfoData?.zipCode,
    },
    {
      label: "Postal Code",
      value: currentInfoData?.postalCode,
    },
    {
      label: "Province (If any)",
      value: currentInfoData?.province,
    },
    {
      label:
        "Has your marital status changed since your last document was issued?",
      value: currentInfoData?.didMaritalStatusChange,
    },
    {
      label: "Your Current Marital Status",
      value: currentInfoData?.maritalStatus,
    },
    {
      label:
        "Since becoming a U.S. citizen, have you lost or renounced your U.S. citizenship in any manner?",
      value: currentInfoData?.lostOrRenCitizenship,
    },
    {
      label: "If yes, when and how did this happen?",
      value: currentInfoData?.reasonForLoss,
    },
  ];

  const section3: ReviewData[] = [
    {
      label: "I am applying for a",
      value: typeOfApplicationData?.typeOfApplication,
    },
    {
      section: {
        title:
          "Complete If Applying for a Special Certificate of Recognition as a Citizen of the United States to the Government of a Foreign Country",
        children: [
          {
            label: "Name of Foreign Country",
            value: typeOfApplicationData?.nameOfForeignCountry,
          },
          {
            section: {
              title: "Information About Foreign Official",
              children: [
                {
                  label: "Family Name",
                  value: typeOfApplicationData?.foreignLastName,
                },
                {
                  label: "Given Name",
                  value: typeOfApplicationData?.foreignFirstName,
                },
                {
                  label: "Middle Name(s)",
                  value: typeOfApplicationData?.foreignMiddleName,
                },
                {
                  label: "Official Title",
                  value: typeOfApplicationData?.officialTitle,
                },
                {
                  label: "Name of Government Agency",
                  value: typeOfApplicationData?.nameOfGovernmentAgency,
                },
                {
                  label: "Street number and name",
                  value: typeOfApplicationData?.foreignStreetNumberAndName,
                },
                {
                  label: "Is there an apartment, suite or floor?",
                  value: typeOfApplicationData?.foreignApartmentSuiteOrFloor,
                },
                {
                  label: "Address number",
                  value: typeOfApplicationData?.foreignAddressNumber,
                },
                {
                  label: "City or Town",
                  value: typeOfApplicationData?.foreignCityOrTown,
                },
                {
                  label: "Country",
                  value: typeOfApplicationData?.foreignCountry,
                },
                {
                  label: "State or Territory",
                  value: typeOfApplicationData?.foreignStateOrTerritory,
                },
                {
                  label: "ZIP Code",
                  value: typeOfApplicationData?.foreignZipCode,
                },
                {
                  label: "Postal Code",
                  value: typeOfApplicationData?.foreignPostalCode,
                },
                {
                  label: "Province (If any)",
                  value: typeOfApplicationData?.foreignProvince,
                },
              ],
            },
          },
        ],
      },
    },
    {
      label: "Basis for My Application",
      value: turnCheckFieldToReadable(
        typeOfApplicationData?.basisForApplicationArray[0]
      ),
    },
    {
      label: "Provide an explanation of when, where, and how this happened",
      value: typeOfApplicationData?.lostStolenOrDestroyedExplanation,
    },
    {
      section: {
        title:
          "Complete If Applying to Correct Your Document Due to a USCIS Typographical or Clerical Error",
        children: [
          {
            label:
              "What was the typographical or clerical error in your document that needs to be corrected?",
            value: turnCheckFieldToReadable(
              typeOfApplicationData?.typeOfTypoErrorArray[0]
            ),
          },
          {
            label:
              "Provide an explanation of what is incorrect on your current certificate or declaration",
            value: typeOfApplicationData?.explanationOfTypoError,
          },
        ],
      },
    },
    {
      section: {
        title:
          "Complete If Applying for a New Document Because of a Name Change",
        children: [
          {
            label: "My name changed through",
            value: typeOfApplicationData?.nameChangedThrough,
          },
          {
            label: "Date of Event",
            value: typeOfApplicationData?.nameChangedDateOfEvent,
          },
          {
            label: "Date of Court Order",
            value: typeOfApplicationData?.nameChangedDateOfCourtOrder,
          },
          {
            label: "Family Name",
            value: typeOfApplicationData?.newLegalLastName,
          },
          {
            label: "Given Name",
            value: typeOfApplicationData?.newLegalFirstName,
          },
          {
            label: "Middle Name(s)",
            value: typeOfApplicationData?.newLegalFirstName,
          },
        ],
      },
    },
    {
      section: {
        title:
          "Complete If Applying for a New Certificate of Citizenship Because of an Official Date of Birth Change",
        children: [
          {
            label: "My date of birth changed through",
            value: turnCheckFieldToReadable(
              typeOfApplicationData?.dateOfBirthChangedThroughArray[0]
            ),
          },
          {
            label: "Date of Court Order",
            value: typeOfApplicationData?.dateOfBirthChangedDateOfCourtOrder,
          },
          {
            label: "Date of U.S. Government-Issued Document",
            value: typeOfApplicationData?.dateOfBirthChangedUsGovIssuedDoc,
          },
          {
            label:
              "My new date of birth is (as shown in the court order or U.S. Government-issued document):",
            value: typeOfApplicationData?.newDateOfBirth,
          },
        ],
      },
    },
    {
      section: {
        title:
          "Complete If Applying for a New Document Because of an Official Change in Gender",
        children: [
          {
            label: "My gender officially changed through",
            value: turnCheckFieldToReadable(
              typeOfApplicationData?.genderChangedThroughArray[0]
            ),
          },
          {
            label: "My current gender designation is",
            value: typeOfApplicationData?.genderChangedThroughCurrentGender,
          },
        ],
      },
    },
  ];

  const formType = "N-565";

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <>
        {selectPlan && (
          <PricingModal
            pricing={pricingData.N565Pricing}
            onModalClose={() => setSelectPlan(false)}
            onContinue={onPay}
          />
        )}
        <ReviewBtns
          onSave={isFormPaid ? onDownload : onSelectPlan}
          isSaving={isFormPaid ? isDownloading : isPaying}
          formType={formType}
          hasPaid={isFormPaid}
        />
        <ReviewBox
          title="1. Information From Current Certificate or Declaration"
          data={section1}
          isActive
          editUrl={FormN565Progress.infoFromCurrentDoc}
        />
        <ReviewBox
          title="2. Current Information About Yous"
          data={section2}
          editUrl={FormN565Progress.currentInfoAboutYou}
        />
        <ReviewBox
          title="3. Type of Application"
          data={section3}
          editUrl={FormN565Progress.typeOfApplication}
        />
        <ReviewPI
          title="4. Preparer and Interpreter"
          data={data?.preparerAndInterpreter}
          editUrl={FormN565Progress.PI}
        />
        <BtnFooterWrapper>
          <FormBtn
            isLoading={isFormPaid ? isDownloading : isPaying}
            addBackBtn
            isError={false}
            addContinueBtn={false}
            removeHR
          />
          <ReviewBtns
            onSave={isFormPaid ? onDownload : onSelectPlan}
            isSaving={isFormPaid ? isDownloading : isPaying}
            formType={formType}
            hasPaid={isFormPaid}
          />
        </BtnFooterWrapper>
        <ReviewTop showTop={showTop} />
        {isSuccess && <N565PaySuccess formType={FormCategories.n565} />}
        {isFail && <N565PayFail onRetry={onSelectPlan} isRetrying={isPaying} />}
      </>
    </FormSectionWrapper>
  );
};

export default ReviewSection;
