import PreparerInterpreter from "../../PI";
import { PreparerInterpreterObj } from "../../../../api/utils/types";
import useToNextSection from "../../../../hooks/useToNextSection";
import {
  useGetN600Preparer,
  useSaveN600Preparer,
} from "../../../../hooks/n600/useN600";

const N600Preparer = () => {
  const { toNextSection } = useToNextSection();
  const { data, isFetching, isError } = useGetN600Preparer();
  const { mutate, isLoading: isSaving } = useSaveN600Preparer();

  const onMutate = (payload: PreparerInterpreterObj) => {
    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  return (
    <PreparerInterpreter
      onMutate={onMutate}
      ptData={data}
      isFetching={isFetching}
      isError={isError}
      isSaving={isSaving}
    />
  );
};

export default N600Preparer;
