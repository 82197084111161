import React from "react";
import styled from "styled-components";
import ParamFormName from "./ParamFormName";

const ImportantNotice: React.FC = () => {
  return (
    <NoticeWrapper>
      <h1 className="uppercase">
        <ParamFormName />: Important Notice!
      </h1>
      <p>
        Our eligibility quizzes are formulated using guidelines, instructions,
        and regulations provided by the U.S. Citizenship and Immigration Service
        (USCIS). Quiz results indicate potential eligibility based on USCIS
        standards but may not cover all factors. They do not guarantee
        application success. The quizzes are not a substitute for legal advice.
        If you have questions about your eligibility to apply for any
        immigration benefit or status, we recommend you consult with an
        immigration attorney.
      </p>
    </NoticeWrapper>
  );
};

export default ImportantNotice;

const NoticeWrapper = styled.div`
  color: #31393c;
  margin: 2rem auto;

  & > h1 {
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1rem;
  }

  & > p {
    font-size: 0.9375rem;
  }
`;
