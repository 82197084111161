import { proxy } from "valtio";
import storage from "../utils/storage";

export const seamlessIsBusiness = "seamlessIsBusiness";
const isBusinessToken = storage.get(seamlessIsBusiness);

export const isBusinessDataStore = proxy<{
  isBusinessData: boolean;
}>({
  isBusinessData: isBusinessToken || false,
});
