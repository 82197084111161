import React from "react";
import styled from "styled-components";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { Form, Formik } from "formik";
import FormBtn from "../../../components/FormBtn";
import { saveDS160SecurityBackgroundP3Obj } from "../../../../../api/ds160/types";
import {
  useGetDS160SecurityBackgroundP3,
  useSaveDS160SecurityBackgroundP3,
} from "../../../../../hooks/ds160/usDS160";
import { Info, InfoBold, VerticalFormWrapper } from "../styles";
import { SecurityBackgroundP3Validation } from "../validation";
import RadioComp from "../../../components/RadioComp";
import {
  GenericYesNo,
  GenericYesNoOptionsValue,
  initInput,
} from "../../../components/data";
import InputField from "../../../components/formik fields/InputField";
import { resetDynamicFields } from "../../../components/data";
import useToNextSection from "../../../../../hooks/useToNextSection";
import { getSecurityBackgroundValidations, processReviewData } from "../data";
import useAiReview from "../useAiReview";
import { ReviewSecuritySectionC } from "../Review/components/data";
import { useSearchParams } from "react-router-dom";
import { BusinessQueryKey } from "../../../../../api/utils/types";

const SecurityBackgroundP3 = () => {
  const { toNextSection } = useToNextSection();
  const [searchParams] = useSearchParams();
  const getApplicationId = searchParams.get(BusinessQueryKey.appId) || "";
  const { data, isFetching, isError } =
    useGetDS160SecurityBackgroundP3(getApplicationId);
  const { mutate, isLoading: isSaving } = useSaveDS160SecurityBackgroundP3();
  const { postAiReview, riskData, riskError, riskLoading, riskSaving } =
    useAiReview();

  const onSubmit = (values: saveDS160SecurityBackgroundP3Obj) => {
    const payload = values;

    const section = ReviewSecuritySectionC(payload);
    const result = processReviewData(section);

    const riskPayload = {
      ...riskData,
      security: {
        ...riskData?.security,
        ...result,
      },
    };

    const onSave = () => {
      mutate(
        { ...payload, applicationId: getApplicationId },
        {
          onSuccess: toNextSection,
        }
      );
    };

    postAiReview({
      riskPayload: riskPayload,
      onSave,
    });
  };

  const initialValues: saveDS160SecurityBackgroundP3Obj = {
    partThreeI: initInput(data?.partThreeI),
    explanationPartThreeI: initInput(data?.explanationPartThreeI),
    partThreeII: initInput(data?.partThreeII),
    explanationPartThreeII: initInput(data?.explanationPartThreeII),
    partThreeIII: initInput(data?.partThreeIII),
    explanationPartThreeIII: initInput(data?.explanationPartThreeIII),
    partThreeIV: initInput(data?.partThreeIV),
    explanationPartThreeIV: initInput(data?.explanationPartThreeIV),
    partThreeV: initInput(data?.partThreeV),
    explanationPartThreeV: initInput(data?.explanationPartThreeV),
    partThreeVI: initInput(data?.partThreeVI),
    explanationPartThreeVI: initInput(data?.explanationPartThreeVI),
    partThreeVII: initInput(data?.partThreeVII),
    explanationPartThreeVII: initInput(data?.explanationPartThreeVII),
    partThreeVIII: initInput(data?.partThreeVIII),
    explanationPartThreeVIII: initInput(data?.explanationPartThreeVIII),
    partThreeIX: initInput(data?.partThreeIX),
    explanationPartThreeIX: initInput(data?.explanationPartThreeIX),
    partThreeX: initInput(data?.partThreeX),
    explanationPartThreeX: initInput(data?.explanationPartThreeX),
    partThreeXI: initInput(data?.partThreeXI),
    explanationPartThreeXI: initInput(data?.explanationPartThreeXI),
  };

  return (
    <FormSectionWrapper
      isLoading={isFetching || riskLoading}
      isError={isError || riskError}
    >
      <Wrapper>
        <Info>
          <InfoBold>
            NOTE: Provide the following information concerning your Security and
            Background.
          </InfoBold>
        </Info>
        <Formik
          initialValues={initialValues}
          validationSchema={SecurityBackgroundP3Validation}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <RadioComp
                  title="Do you seek to engage in espionage sabotage, exports control
                violations or any other illegal activity while in the United
                States?"
                  name="partThreeI"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partThreeI"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartThreeI", value: "" },
                    ]);
                  }}
                />

                {formik.values.partThreeI === GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartThreeI"
                      inputType="text-area"
                    />
                  </>
                )}
                <RadioComp
                  title="Do you seek to engage in terrorist activities while in the
                United States or have you ever engage in terrorist activities?"
                  name="partThreeII"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partThreeII"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartThreeII", value: "" },
                    ]);
                  }}
                />

                {formik.values.partThreeII === GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartThreeII"
                      inputType="text-area"
                    />
                  </>
                )}
                <RadioComp
                  title="Have you ever or do you intend to provide financial assistance
                or other support to terrorists or terrorist organisations?"
                  name="partThreeIII"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partThreeIII"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartThreeIII", value: "" },
                    ]);
                  }}
                />
                {formik.values.partThreeIII ===
                  GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartThreeIII"
                      inputType="text-area"
                    />
                  </>
                )}
                <RadioComp
                  title="Are you a member or representative of a terrorist
                organization?"
                  name="partThreeIV"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partThreeIV"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartThreeIV", value: "" },
                    ]);
                  }}
                />
                {formik.values.partThreeIV === GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartThreeIV"
                      inputType="text-area"
                    />
                  </>
                )}
                <RadioComp
                  title="Are you the spouse son or daughter of an individual who has
                engaged in terrorist activity including providing financial
                assistance all the support 2 terrorist or terrorist
                organizations, in the last 5 years?"
                  name="partThreeV"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partThreeV"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartThreeV", value: "" },
                    ]);
                  }}
                />
                {formik.values.partThreeV === GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartThreeV"
                      inputType="text-area"
                    />
                  </>
                )}
                <RadioComp
                  title="Have you ever ordered incited committed assisted or otherwise
                participated in genocide?"
                  name="partThreeVI"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partThreeVI"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartThreeVI", value: "" },
                    ]);
                  }}
                />
                {formik.values.partThreeVI === GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartThreeVI"
                      inputType="text-area"
                    />
                  </>
                )}
                <RadioComp
                  title="Have you ever committed, ordered, incited, assisted, or
                otherwise participated in torture?"
                  name="partThreeVII"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partThreeVII"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartThreeVII", value: "" },
                    ]);
                  }}
                />
                {formik.values.partThreeVII ===
                  GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartThreeVII"
                      inputType="text-area"
                    />
                  </>
                )}
                <RadioComp
                  title="Have you ever committed, ordered, incited, assisted, or
                otherwise participated in extrajudicial killings, political
                killings, or other acts of violence?"
                  name="partThreeVIII"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partThreeVIII"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartThreeVIII", value: "" },
                    ]);
                  }}
                />
                {formik.values.partThreeVIII ===
                  GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartThreeVIII"
                      inputType="text-area"
                    />
                  </>
                )}
                <RadioComp
                  title="Have you ever engaged in the recruitment or the use of child
                soldiers?"
                  name="partThreeIX"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partThreeIX"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartThreeIX", value: "" },
                    ]);
                  }}
                />
                {formik.values.partThreeIX === GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartThreeIX"
                      inputType="text-area"
                    />
                  </>
                )}
                <RadioComp
                  title="Have you ever been directly involved in the establishment or
                enforcement of population controls forcing a woman to undergo
                an abortion against her free choice or a man or a woman to
                undergo sterilization against her free will?"
                  name="partThreeX"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partThreeX"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartThreeX", value: "" },
                    ]);
                  }}
                />
                {formik.values.partThreeX === GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartThreeX"
                      inputType="text-area"
                    />
                  </>
                )}
                <RadioComp
                  title="Have you ever been directly involved in the coercive
                transplantation of human organs or bodily tissue?"
                  name="partThreeXI"
                  options={GenericYesNo}
                  toolTipProps={getSecurityBackgroundValidations(
                    formik,
                    "partThreeXI"
                  )}
                  onChange={() => {
                    resetDynamicFields(formik, [
                      { name: "explanationPartThreeXI", value: "" },
                    ]);
                  }}
                />
                {formik.values.partThreeXI === GenericYesNoOptionsValue.yes && (
                  <>
                    <InputField
                      label="Explain:"
                      placeholder="Explanation..."
                      name="explanationPartThreeXI"
                      inputType="text-area"
                    />
                  </>
                )}
                <FormBtn
                  isLoading={isSaving || riskSaving}
                  addBackBtn
                  isError={formik.dirty && !formik.isValid}
                />
              </FormWrapper>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default SecurityBackgroundP3;

const Wrapper = styled.div``;

const FormWrapper = styled(VerticalFormWrapper)``;
