import React, { FC } from "react";
import PayFail from "../../components/PayFail";
import { useGetN565PayFail } from "../../../../hooks/n565/useN565";

interface Props {
  onRetry: () => void;
  isRetrying: boolean;
}

const N565PayFail: FC<Props> = ({ onRetry, isRetrying }) => {
  const { isLoading, isError } = useGetN565PayFail();

  return (
    <PayFail
      onRetry={onRetry}
      isRetrying={isRetrying}
      isLoading={isLoading}
      isError={isError}
    />
  );
};

export default N565PayFail;
