import React from "react";
import { BoxWrapper, DashboardTitle, DashboardWrapper } from "../styles";
import LeftSide from "./components/LeftSide";
import styled from "styled-components";
import RightSide from "./components/RightSide";

const Setting = () => {
  return (
    <DashboardWrapper>
      <DashboardTitle>Settings</DashboardTitle>
      <BoxWrapper>
        <MiniWrapper>
          <LeftSide />
          <RightSide />
        </MiniWrapper>
      </BoxWrapper>
    </DashboardWrapper>
  );
};

export default Setting;

const MiniWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 64px;
  padding-top: 48px;
`;
