export const recurringBillingData = [
  {
    name: "Standard",
    id: "tier-standard",
    href: "#",
    priceMonthly: "$99",
    description: "Essential tools for your AI needs.",
    features: ["API Integration", "AI Risk Checker", "AI Interview Trainer"],
    featured: false,
  },
  {
    name: "Premium",
    id: "tier-premium",
    href: "#",
    priceMonthly: "$199",
    description: "Advanced AI features with collaboration tools.",
    features: [
      "API Integration",
      "AI Risk Checker",
      "AI Interview Trainer",
      "Case Evaluation",
      "Client Collaboration",
      "Customized Dashboard",
    ],
    featured: true, // Highlight the premium plan
  },
  //   {
  //     name: "Enterprise",
  //     id: "tier-enterprise",
  //     href: "#",
  //     priceMonthly: "$99",
  //     description: "Tailored solutions for your business needs.",
  //     features: ["Get tailored solutions for your business needs"],
  //     featured: false,
  //   },
];

export enum BillingType {
  perApplication = "perApplication",
  monthly = "monthly",
}
