import { ApiResponse } from "../../../types/types.js";
import request from "../../request";
import { CreateStaffPayload, StaffRes, UpdateStaffPayload } from "./types.jsx";

export const getAllStaff = async () => {
  return await request.get<ApiResponse<StaffRes[]>>(`business/get-all-staff`);
};

export const getSingleStaff = async (staffId: string) => {
  return await request.get<ApiResponse<StaffRes>>(
    `business/get-staff/${staffId}`
  );
};

export const createStaff = async (payload: CreateStaffPayload) => {
  return await request.post<ApiResponse>(`business/create-staff`, payload);
};
export const updateStaff = async (payload: UpdateStaffPayload) => {
  return await request.post<ApiResponse>(
    `business/update-staff/${payload.staffId}`,
    payload.data
  );
};
export const deleteStaff = async (staffId: string) => {
  return await request.delete<ApiResponse>(`business/delete-staff/${staffId}`);
};
