"use client";
import { useField } from "formik";
import React, { FC } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Colors from "../../../../styles/Colors";
import styled from "styled-components";
import { mediaObj } from "../../../../styles/Media";

interface Props {
  name: string;
  label: string;
  onChange?: (value: string) => void;
  isDisabled?: boolean;
  isRequired?: boolean;
  borderStyle?: string;
  value?: string;
}

const PhoneFormatter: FC<Props> = ({
  label,
  onChange,
  isDisabled,
  value,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);
  const isError = !!(meta.touched && meta.error);

  return (
    <InputContainer>
      <Label>{label}</Label>
      <PhoneInput
        containerStyle={{
          height: "2.5rem",
          fontFamily: "inherit",
        }}
        buttonStyle={{
          background: "transparent",
          border: "none",
        }}
        inputStyle={{
          height: "100%",
          border:
            isError && !isDisabled
              ? `1px solid red`
              : `1px solid ${Colors.GreyA7}`,
          borderRadius: "0.25rem",
          width: "100%",
          paddingLeft: "50px",
          background: isDisabled ? `rgba(27, 9, 44, 0.05)` : `#ffffff`,
        }}
        buttonClass="[&_.selected-flag]:bg-transparent [&_.selected-flag]:hover:!bg-transparent [&_.selected-flag]:!pl-[1rem]"
        inputClass="disabled:!bg-[rgba(239, 239, 239, 0.7)] focus:shadow-none focus:!border-[black]"
        country={"ng"}
        disabled={isDisabled}
        countryCodeEditable={false}
        inputProps={{
          ...props,
          name: props.name,
        }}
        value={value || field.value || null}
        onChange={(value, country, e, formattedValue) => {
          helpers.setValue(formattedValue);
          onChange && onChange(formattedValue);
        }}
      />
      {isError ? (
        <div className="text-sm text-red-500 ">{meta.error}</div>
      ) : null}
    </InputContainer>
  );
};

export default PhoneFormatter;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.label`
  margin-bottom: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: ${Colors.Black31};
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &.required:after {
    content: "*";
    font-size: 24px;
    line-height: 0;
    vertical-align: middle;
    color: #d90b2c;
  }

  ${mediaObj.mobile} {
    font-size: 14px;
  }
`;
