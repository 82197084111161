import React, { FC } from "react";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { N600InfoAboutUSCitizenFatherPart2Payload } from "../../../../../../api/n600/types";
import { FormN600Progress } from "../../types";

interface Props {
  title: string;
  data?: N600InfoAboutUSCitizenFatherPart2Payload;
}

const ReviewUSFatherInfoPart2: FC<Props> = ({ title, data }) => {
  const section: ReviewData[] = [
    {
      label:
        "Has your father ever lost U.S. citizenship or taken any action that would cause loss of U.S. citizenship?",
      value: data?.fatherLostCitizenship,
    },
    {
      label:
        "Explanation if father lost U.S. citizenship or took any action that would cause loss of U.S. citizenship",
      value: data?.fatherLostCitizenshipExplanation,
    },
    {
      label: "How many times has your U.S. citizen father been married?",
      value: data?.fatherNumberOfMarriages,
    },
    {
      label: "What is your U.S. citizen father's current marital status?",
      value: data?.fatherCurrentMaritalStatus,
    },
    {
      label: "Explanation if father's current marital status requires it",
      value: data?.fatherCurrentMaritalStatusExplanation,
    },
    {
      label: "Father's Spouse Family Name (Last Name)",
      value: data?.fatherSpouseFamilyName,
    },
    {
      label: "Father's Spouse Given Name (First Name)",
      value: data?.fatherSpouseGivenName,
    },
    {
      label: "Father's Spouse Middle Name",
      value: data?.fatherSpouseMiddleName,
    },
    {
      label: "Father's Spouse Date of Birth",
      value: data?.fatherSpouseDateOfBirth,
    },
    {
      label: "Father's Spouse Country of Birth",
      value: data?.fatherSpouseCountryOfBirth,
    },
    {
      label: "Father's Spouse Country of Citizenship",
      value: data?.fatherSpouseCountryOfCitizenship,
    },
    {
      label: "Father's Spouse Physical Address Street Number and Name",
      value: data?.fatherSpousePhysicalAddressStreetNumberAndName,
    },
    {
      label: "Father's Spouse Physical Address Apt. Ste. Flr.",
      value: data?.fatherSpousePhysicalAddressAptSteFlr,
    },
    {
      label: "Father's Spouse Physical Address Apt. Ste. Flr. Number",
      value: data?.fatherSpousePhysicalAddressAptSteFlrNumber,
    },
    {
      label: "Father's Spouse Physical Address City or Town",
      value: data?.fatherSpousePhysicalAddressCityOrTown,
    },
    {
      label: "Father's Spouse Physical Address State",
      value: data?.fatherSpousePhysicalAddressState,
    },
    {
      label: "Father's Spouse Physical Address ZIP Code",
      value: data?.fatherSpousePhysicalAddressZipCode,
    },
    {
      label: "Father's Spouse Physical Address Province (foreign address only)",
      value: data?.fatherSpousePhysicalAddressProvince,
    },
    {
      label:
        "Father's Spouse Physical Address Postal Code (foreign address only)",
      value: data?.fatherSpousePhysicalAddressPostalCode,
    },
    {
      label: "Father's Spouse Physical Address Country (foreign address only)",
      value: data?.fatherSpousePhysicalAddressCountry,
    },
    {
      label: "Date of Marriage",
      value: data?.fatherDateOfMarriage,
    },
    {
      label: "Place of Marriage City or Town",
      value: data?.fatherPlaceOfMarriageCityOrTown,
    },
    {
      label: "Place of Marriage State",
      value: data?.fatherPlaceOfMarriageState,
    },
    {
      label: "Place of Marriage Country",
      value: data?.fatherPlaceOfMarriageCountry,
    },
    {
      label: "Father's Spouse Immigration Status",
      value: data?.fatherSpouseImmigrationStatus,
    },
    {
      label: "Explanation if spouse's immigration status requires it",
      value: data?.fatherSpouseImmigrationStatusExplanation,
    },
    {
      label:
        "Is your U.S. citizen father's current spouse also your biological (or adopted) mother?",
      value: data?.isFatherCurrentSpouseBiologicalOrAdoptiveMother,
    },
  ];

  return (
    <ReviewBox
      title={title}
      data={section}
      editUrl={FormN600Progress.infoAboutUSCitizenFatherP2}
    />
  );
};

export default ReviewUSFatherInfoPart2;
