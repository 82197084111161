import React, { FC } from "react";
import styled from "styled-components";
import useActivate from "../../../../components/useActivate";
import ActivateIcon from "../../../../components/ActivateIcon";
import Colors from "../../../../../../styles/Colors";
import Markdown from "markdown-to-jsx";

interface Props {
  response: string;
}

const RiskAssessmentBox: FC<Props> = ({ response }) => {
  const { active, toggle, contentEl } = useActivate(true);

  return (
    <Wrapper>
      <Header>
        <Title>DS 160 RISK ASSESSMENT</Title>

        <ActivateIcon active={active} toggle={toggle} />
      </Header>
      <MiniWrapper ref={contentEl} contentEl={contentEl} isActive={active}>
        <div className="px-4 mt-4">
          <Markdown>{response.replace(/\n/gi, "&nbsp; \n\n")}</Markdown>
        </div>
      </MiniWrapper>
    </Wrapper>
  );
};

export default RiskAssessmentBox;

const Wrapper = styled.div`
  margin-top: 33px;
`;

interface MiniWrapperProps {
  isActive: boolean;
  contentEl: React.RefObject<HTMLDivElement>;
}

const MiniWrapper = styled.div<MiniWrapperProps>`
  height: ${({ isActive, contentEl }) =>
    !isActive ? "0" : `${contentEl.current?.scrollHeight}px`};
  overflow: hidden;
  transition: height ease 0.3s;
`;

const Header = styled.div`
  padding: 13px 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${Colors.BlueEA};
  font-size: 1rem;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;

  & > svg {
    cursor: pointer;
  }
`;

const Title = styled.div`
  color: ${Colors.Blue00};
`;
