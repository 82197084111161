const Colors = {
  AmberFE: "#FEB204",
  AmberFF: "#FF8503",
  Blue00: "#0076E9",
  Blue02: "#022e58",
  BlueEA: "#EAF5FF",
  Black1A: "#1A1A1A",
  Black31: "#31393C",
  Black: "#000000",
  White: "#FFFFFF",
  WhiteF9: "#F9FCFF",
  WhiteF3: "#F3F4F8",
  GreyA7: "#A7AABD",
  Grey8B: "#8B8B8B",
  GreyEB: "#EBE8E8",
  RedDA: "#DA5002",
  RedC3: "#C33636",
  RedE3: "#E31D1C",
  DarkB3: "#B3BAC1",
  Dark6B: "#6B6D70",
  DarkD9: "#D9D9D9",
  Dark4E: "#4E4E4E",
};

export default Colors;
