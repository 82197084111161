import React, { FC } from "react";
import { AiOutlineArrowUp } from "react-icons/ai";
import styled from "styled-components";

interface Props {
  showTop: boolean;
}

const ReviewTop: FC<Props> = ({ showTop }) => {
  return (
    <>
      {showTop && (
        <TopWrapper
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
        >
          <AiOutlineArrowUp />
        </TopWrapper>
      )}
    </>
  );
};

export default ReviewTop;

const TopWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #73baffc2;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 60px;
  bottom: 15px;
  cursor: pointer;
`;
