import React, { useState } from "react";
import styled from "styled-components";
import Colors from "../../../../../../styles/Colors";
import NewApplication from "./NewApplication";

const ActiveApplication = () => {
  const [isSecond, setIsSecond] = useState(false);

  return (
    <>
      {!isSecond ? (
        <Wrapper>
          <Label>No active application</Label>
          <ActionBtn type="button" onClick={() => setIsSecond(true)}>
            Start a new application
          </ActionBtn>
        </Wrapper>
      ) : (
        <NewApplication />
      )}
    </>
  );
};

export default ActiveApplication;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Label = styled.div`
  font-size: 1rem;
`;

const ActionBtn = styled.button`
  width: max-content;
  border: 1px solid ${Colors.Blue00};
  outline: none;
  text-transform: uppercase;
  color: ${Colors.Blue00};
  padding: 0.625rem 1.25rem;
  border-radius: 8px;
  transition: all 0.3s;

  &:hover {
    background-color: ${Colors.Blue00};
    color: white;
  }
`;
