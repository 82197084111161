import React from "react";
import styled from "styled-components";
import Constaint from "../../layout/components/Constaint";
import EligibilityBg from "../../../../images/web/eligibility-bg.png";
import Colors from "../../../../styles/Colors";
import { useNavigate, useParams } from "react-router-dom";
import { WebPaths } from "../../../../routes/data";
import { FormCategories } from "../../../../routes/type";
import ParamFormName from "../../checkEligibilty/components/ParamFormName";

const Hero = () => {
  const navigate = useNavigate();
  const { formType } = useParams();

  const getSubText = () => {
    if (formType === FormCategories.n565)
      return "Application for Replacement Naturalization/Citizenship Document";
    if (formType === FormCategories.n400) return "Apply for U.S. Citizenship";
    if (formType === FormCategories.n600)
      return "Application for Certificate of Citizenship";
  };

  return (
    <Wrapper>
      <Constaint>
        <Container>
          <Title>
            {(formType === "n400" ||
              formType === "n600" ||
              formType === "n565") &&
              "Form "}
            <ParamFormName />
          </Title>
          <Para>{getSubText()}</Para>
          <CTA
            onClick={() => navigate(`${WebPaths.checkEligibility}/${formType}`)}
          >
            Check Eligibility
          </CTA>
        </Container>
      </Constaint>
    </Wrapper>
  );
};

export default Hero;

const Wrapper = styled.div`
  background: url(${EligibilityBg});
  background-size: cover;
  height: 523px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${Colors.White};
  padding-top: 170px;
  padding-bottom: 180px;
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 2.5rem;
`;

const Para = styled.p`
  margin: 20px 0 27px 0;
  font-size: 1.25rem;
`;

const CTA = styled.button`
  width: 222px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 59px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.1875rem;
  letter-spacing: 0em;
  background: #0076e9;
  border-radius: 8px;
  color: #f9fcff;
  white-space: nowrap;

  &:hover {
    background: #022e58;
    color: #f9fcff;
    transition: 0.3s ease-in-out;
  }
`;
