import { DragEvent, FC, useRef, useState } from "react";
import FileInput from "../../../../../components/formik fields/FileInput";
import styled from "styled-components";
import Colors from "../../../../../../../styles/Colors";
import { MdOutlineFileUpload } from "react-icons/md";

interface Props {
  btnText: string;
  handleFile: (files: FileList) => void;
  name: string;
  acceptedFormat?: string;
}

const FileBox: FC<Props> = ({ handleFile, name, acceptedFormat, btnText }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>();

  const handleClick = () => {
    if (!inputRef.current) return;
    inputRef.current.click();
  };

  const onError = (errorState: boolean) => {
    setIsError(errorState);
  };
  const onErrorMsg = (msg?: string) => {
    setErrorMsg(msg);
  };

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
  };

  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFile(files);
  };
  return (
    <div className="relative h-max">
      <div
        className={`h-max`}
        onClick={handleClick}
        onDragOver={onDragOver}
        onDrop={onDrop}
      >
        <Btn>
          {btnText}
          <MdOutlineFileUpload className="flex-shrink-0 " size={14} />
        </Btn>
        <FileInput
          ref={inputRef}
          name={name}
          handleFile={handleFile}
          accept={
            acceptedFormat
              ? acceptedFormat
              : "image/jpeg, image/png,.pdf, .doc,.docx"
          }
          onError={onError}
          onErrorMsg={onErrorMsg}
        />
      </div>
      {isError ? (
        <div className="absolute bottom-0 left-0 pt-2 text-xs text-center text-red-500 translate-y-full">
          {errorMsg}
        </div>
      ) : null}
    </div>
  );
};

export default FileBox;

const Btn = styled.div`
  text-transform: uppercase;
  border: 0.5px solid ${Colors.Black31};
  padding: 7px 20px;
  border-radius: 6px;
  font-size: 0.75rem;
  color: ${Colors.Black31};
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
`;
