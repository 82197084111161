import { Form, Formik } from "formik";
import styled from "styled-components";
import {
  GenericYesNo,
  GenericYesNoOptionsValue,
  initDate,
  initInput,
  initSelect,
  ToSaveDate,
} from "../../../components/data";
import FormBtn from "../../../components/FormBtn";
import InputField from "../../../components/formik fields/InputField";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import RadioComp from "../../../components/RadioComp";
import { FormWrapper, ModuleTitle } from "../../../components/styles";

import { N600InfoAboutYouPart4Payload } from "../../../../../api/n600/types";
import { Modify, SingleOption } from "../../../../../types/types";
import DatePickerField from "../../../components/formik fields/DatePickerField";
import SelectField from "../../../components/formik fields/SelectField";
import useStatesByCountry from "../../../components/useStatesByCountry";
import { Dayjs } from "dayjs";
import {
  useGetN600InfoAboutYouPart4,
  useSaveN600InfoAboutYouPart4,
} from "../../../../../hooks/n600/useN600";
import useToNextSection from "../../../../../hooks/useToNextSection";
import { InfoAboutYouPart4Validation } from "../validation";

export interface N600InfoAboutYouPart4Data
  extends Modify<
    N600InfoAboutYouPart4Payload,
    {
      firstDepartureDate: Dayjs | null;
      firstReturnDate: Dayjs | null;
      secondDepartureDate: Dayjs | null;
      secondReturnDate: Dayjs | null;

      firstPlaceOfEntryState: SingleOption | null;
      secondPlaceOfEntryState: SingleOption | null;
    }
  > {}

const InfoAboutYou4 = () => {
  const { data, isFetching, isError } = useGetN600InfoAboutYouPart4();
  const { mutate, isLoading: isSaving } = useSaveN600InfoAboutYouPart4();
  const { toNextSection } = useToNextSection();

  const {
    formattedStateData: firstDepartureStatesData,
    stateLoading: firstDepartureStatesLoading,
    selectedCountry: firstDepartureSelectedCountry,
  } = useStatesByCountry("United States");

  const {
    formattedStateData: secondDepartureStatesData,
    stateLoading: secondDepartureStatesLoading,
    selectedCountry: secondDepartureSelectedCountry,
  } = useStatesByCountry("United States");

  const onSubmit = (values: N600InfoAboutYouPart4Data) => {
    const {
      firstDepartureDate,
      firstReturnDate,
      secondDepartureDate,
      secondReturnDate,
      firstPlaceOfEntryState,
      secondPlaceOfEntryState,
      ...rest
    } = values;

    const payload = {
      ...rest,
      firstDepartureDate: ToSaveDate(firstDepartureDate),
      firstReturnDate: ToSaveDate(firstReturnDate),
      secondDepartureDate: ToSaveDate(secondDepartureDate),
      secondReturnDate: ToSaveDate(secondReturnDate),
      firstPlaceOfEntryState: firstPlaceOfEntryState?.value || "",
      secondPlaceOfEntryState: secondPlaceOfEntryState?.value || "",
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValues: N600InfoAboutYouPart4Data = {
    parentsMarriedAtBirth: initInput(data?.parentsMarriedAtBirth), // Were your parents married to each other when you were born (or adopted)?
    parentsMarriedAfterBirth: initInput(data?.parentsMarriedAfterBirth), // Did your parents marry after you were born?
    resideInUSWithCitizenParents: initInput(data?.resideInUSWithCitizenParents), // Do you regularly reside in the United States in the legal and physical custody of your U.S. citizen parents?
    absentFromUS: initInput(data?.absentFromUS), // Have you been absent from the United States since you first arrived?

    firstDepartureDate: initDate(data?.firstDepartureDate), // Date You Left the United States (mm/dd/yyyy)
    firstReturnDate: initDate(data?.firstReturnDate), // Date You Returned to the United States (mm/dd/yyyy)
    firstPlaceOfEntryCity: initInput(data?.firstPlaceOfEntryCity), // Place of Entry Upon Return to the United States City or Town
    firstPlaceOfEntryState: initSelect(
      firstDepartureStatesData,
      data?.firstPlaceOfEntryState
    ), // Place of Entry Upon Return to the United States State

    secondDepartureDate: initDate(data?.secondDepartureDate), // Date You Left the United States (mm/dd/yyyy) (optional for multiple entries)
    secondReturnDate: initDate(data?.secondReturnDate), // Date You Returned to the United States (mm/dd/yyyy) (optional for multiple entries)
    secondPlaceOfEntryCity: initInput(data?.secondPlaceOfEntryCity), // Place of Entry Upon Return to the United States City or Town (optional for multiple entries)
    secondPlaceOfEntryState: initSelect(
      secondDepartureStatesData,
      data?.secondPlaceOfEntryState
    ), // Place of Entry Upon Return to the United States State (optional for multiple entries)
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={InfoAboutYouPart4Validation}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <FormWrapper>
                <div>
                  <RadioComp
                    title="Were your parents married to each other when you were born (or adopted)?"
                    options={GenericYesNo}
                    name="parentsMarriedAtBirth"
                  />
                </div>
              </FormWrapper>

              {formik.values.parentsMarriedAtBirth ===
                GenericYesNoOptionsValue.no && (
                <FormWrapper>
                  <div>
                    <RadioComp
                      title="Did your parents marry after you were born?"
                      options={GenericYesNo}
                      name="parentsMarriedAfterBirth"
                    />
                  </div>
                </FormWrapper>
              )}

              <FormWrapper>
                <div>
                  <RadioComp
                    title="Do you regularly reside in the United States in the legal and physical custody of your U.S. citizen parents?"
                    options={GenericYesNo}
                    name="resideInUSWithCitizenParents"
                  />
                </div>
              </FormWrapper>

              <FormWrapper>
                <div>
                  <RadioComp
                    title="Have you been absent from the United States since you first arrived?"
                    options={GenericYesNo}
                    name="absentFromUS"
                  />
                </div>
              </FormWrapper>

              <FormWrapper>
                <div>
                  <DatePickerField
                    label="Date You Left the United States (mm/dd/yyyy)"
                    name="firstDepartureDate"
                    disableFuture
                  />
                </div>
                <div>
                  <DatePickerField
                    label="Date You Returned to the United States (mm/dd/yyyy)"
                    name="firstReturnDate"
                    disableFuture
                  />
                </div>
              </FormWrapper>

              <ModuleTitle>
                Place of Entry Upon Return to the United States
              </ModuleTitle>

              <FormWrapper>
                <div>
                  <InputField
                    label="City or Town"
                    name="firstPlaceOfEntryCity"
                    placeholder=""
                  />
                </div>

                <div>
                  <SelectField
                    name={`firstPlaceOfEntryState`}
                    label="State"
                    placeholder="-Select-"
                    options={firstDepartureStatesData}
                    isLoading={firstDepartureStatesLoading}
                    isDisabled={!firstDepartureSelectedCountry}
                  />
                </div>
              </FormWrapper>

              <FormWrapper>
                <div>
                  <DatePickerField
                    label="Date You Left the United States (mm/dd/yyyy)"
                    name="secondDepartureDate"
                    disableFuture
                  />
                </div>
                <div>
                  <DatePickerField
                    label="Date You Returned to the United States (mm/dd/yyyy)"
                    name="secondReturnDate"
                    disableFuture
                  />
                </div>
              </FormWrapper>

              <ModuleTitle>
                Place of Entry Upon Return to the United States
              </ModuleTitle>

              <FormWrapper>
                <div>
                  <InputField
                    label="City or Town"
                    name="secondPlaceOfEntryCity"
                    placeholder=""
                  />
                </div>

                <div>
                  <SelectField
                    name={`secondPlaceOfEntryState`}
                    label="State"
                    placeholder="-Select-"
                    options={secondDepartureStatesData}
                    isLoading={secondDepartureStatesLoading}
                    isDisabled={!secondDepartureSelectedCountry}
                  />
                </div>
              </FormWrapper>

              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default InfoAboutYou4;

const Wrapper = styled.div``;
