import { FC } from "react";
import styled from "styled-components";
import Spinner from "../../../utils/Spinner";
import Colors from "../../../styles/Colors";

interface Props {
  name: string;
  icon?: JSX.Element;
  IsLoading?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
  type?: "reset" | "button" | "submit";
}

const CustomButton: FC<Props> = ({
  name,
  icon,
  IsLoading,
  isDisabled,
  onClick,
  type,
}) => {
  return (
    <Wrapper
      onClick={onClick}
      type={type ? type : "submit"}
      disabled={isDisabled}
    >
      {IsLoading && <Spinner color={Colors.WhiteF9} size={16} />}
      {name}
      {icon && icon}
    </Wrapper>
  );
};

export default CustomButton;

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${Colors.WhiteF9};
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.0625rem;
  border-radius: 8px;
  padding: 10px 24px;
  background-color: ${Colors.Blue00};
  text-transform: uppercase;
  border: 1px solid ${Colors.Blue00};

  transition: all 0.3s ease-out;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: ${Colors.Blue02};
    color: ${Colors.White};
  }
`;
