import React, { FC } from "react";
import {
  saveDS160SEVAdditionalContactObj,
  saveDS160SivesObj,
} from "../../../../../../api/ds160/types";
import ReviewBox, { ReviewData } from "../../../../components/Review/ReviewBox";
import { FormDS160Progress } from "../../types";
import { GenericDs160ReviewProps } from "./DownloadPage";
import PdfReviewBox from "../../../../components/Review/Pdf Review/PdfReviewBox";
import { ReviewSEVSectionA, ReviewSEVSectionB } from "./data";

interface Props extends GenericDs160ReviewProps {
  data?: {
    additionalContact: saveDS160SEVAdditionalContactObj;
    sevis: saveDS160SivesObj;
  };
}

const ReviewSEV: FC<Props> = ({ title, data, isDownload }) => {
  const sectionA = data ? ReviewSEVSectionA(data.additionalContact) : [];

  const sectionB = data ? ReviewSEVSectionB(data.sevis) : [];

  const section: ReviewData[] = [
    {
      section: {
        title: "Additional Contact",
        children: sectionA,
      },
    },
    {
      section: {
        title: "SEVIS",
        children: sectionB,
      },
    },
  ];

  const compProps = {
    title: title,
    data: section,
    editUrl: FormDS160Progress.studentExchangeVisaAdditional,
  };

  return isDownload ? (
    <PdfReviewBox {...compProps} />
  ) : (
    <ReviewBox {...compProps} />
  );
};

export default ReviewSEV;
