import { FieldArray, Form, Formik } from "formik";
import styled from "styled-components";
import {
  GenderDropDown,
  GenericYesNo,
  GenericYesNoOptionsValue,
  initDate,
  initInput,
  initSelect,
  SpecialSeparator,
  ToSaveDate,
} from "../../../components/data";
import FormSectionWrapper from "../../../components/FormSectionWrapper";
import { FormWrapper, ModuleTitle } from "../../../components/styles";
import RadioComp from "../../../components/RadioComp";
import FormBtn from "../../../components/FormBtn";
import InputField from "../../../components/formik fields/InputField";
import DatePickerField from "../../../components/formik fields/DatePickerField";
import { N600InfoAboutYouPart1Payload } from "../../../../../api/n600/types";
import { Modify, SingleOption } from "../../../../../types/types";
import { Dayjs } from "dayjs";
import GroupedSection from "../../../components/GroupedSection";
import AddAnotherBtn from "../../../components/AddAnotherBtn";
import { toast } from "react-toastify";
import Colors from "../../../../../styles/Colors";
import useCountries from "../../../components/useCountries";
import SelectField from "../../../components/formik fields/SelectField";
import {
  useGetN600InfoAboutYouPart1,
  useSaveN600InfoAboutYouPart1,
} from "../../../../../hooks/n600/useN600";
import useToNextSection from "../../../../../hooks/useToNextSection";
import { InfoAboutYouPart1Validation } from "../validation";

const initialOtherNamesArray: OtherNamesArrayProps = {
  otherFamilyName: "",
  otherGivenName: "",
  otherMiddleName: "",
};

interface OtherNamesArrayProps {
  otherFamilyName: string;
  otherGivenName: string;
  otherMiddleName: string;
}

export interface N600InfoAboutYouPart1Data
  extends Modify<
    Omit<
      N600InfoAboutYouPart1Payload,
      "otherFamilyName" | "otherGivenName" | "otherMiddleName"
    >,
    {
      otherNamesArray: OtherNamesArrayProps[];
      dateOfBirth: Dayjs | null;
      countryOfBirth: SingleOption | null;
      countryOfPriorCitizenship: SingleOption | null;
    }
  > {}

const InfoAboutYou1 = () => {
  const { data, isFetching, isError } = useGetN600InfoAboutYouPart1();
  const { mutate, isLoading: isSaving } = useSaveN600InfoAboutYouPart1();
  const { toNextSection } = useToNextSection();

  const {
    formattedCountryData: countryOfBirthData,
    countryLoading: countryOfBirthLoading,
  } = useCountries();

  const {
    formattedCountryData: countryOfCitizenshipData,
    countryLoading: countryOfCitizenshipLoading,
  } = useCountries();

  const savedOtherNamesArray = () => {
    if (
      data?.otherFamilyName ||
      data?.otherGivenName ||
      data?.otherMiddleName
    ) {
      const otherFamilyNameData = data?.otherFamilyName
        .split(SpecialSeparator)
        .map((ev: any) => ({ otherFamilyName: ev }));
      const otherGivenNameData = data?.otherGivenName
        .split(SpecialSeparator)
        .map((ev: any) => ({ otherGivenName: ev }));
      const otherMiddleNameData = data?.otherMiddleName
        .split(SpecialSeparator)
        .map((ev: any) => ({ otherMiddleName: ev }));

      return otherFamilyNameData.map((ev: any, i: any) => ({
        ...ev,
        ...otherGivenNameData[i],
        ...otherMiddleNameData[i],
      }));
    } else return [initialOtherNamesArray];
  };

  const onSubmit = (values: N600InfoAboutYouPart1Data) => {
    const {
      otherNamesArray,
      countryOfBirth,
      countryOfPriorCitizenship,
      dateOfBirth,
      ...rest
    } = values;

    const payload = {
      ...rest,
      otherFamilyName: otherNamesArray
        .map((ev) => ev.otherFamilyName)
        .join(SpecialSeparator),
      otherGivenName: otherNamesArray
        .map((ev) => ev.otherGivenName)
        .join(SpecialSeparator),
      otherMiddleName: otherNamesArray
        .map((ev) => ev.otherMiddleName)
        .join(SpecialSeparator),
      countryOfBirth: countryOfBirth?.value || "",
      countryOfPriorCitizenship: countryOfPriorCitizenship?.value || "",
      dateOfBirth: ToSaveDate(dateOfBirth),
    };

    mutate(payload, {
      onSuccess: toNextSection,
    });
  };

  const initialValues: N600InfoAboutYouPart1Data = {
    familyName: initInput(data?.familyName),
    givenName: initInput(data?.givenName),
    middleName: initInput(data?.middleName),
    hasNameChanged: initInput(data?.hasNameChanged),
    changedFamilyName: initInput(data?.changedFamilyName),
    changedGivenName: initInput(data?.changedGivenName),
    changedMiddleName: initInput(data?.changedMiddleName),
    otherNamesSinceBirth: initInput(data?.otherNamesSinceBirth),
    otherNamesArray: savedOtherNamesArray(),
    aNumber: initInput(data?.aNumber),
    ssn: initInput(data?.ssn),
    uscisOnlineAccountNumber: initInput(data?.uscisOnlineAccountNumber),
    dateOfBirth: initDate(data?.dateOfBirth),
    countryOfBirth: initSelect(countryOfBirthData, data?.countryOfBirth),
    countryOfPriorCitizenship: initSelect(
      countryOfCitizenshipData,
      data?.countryOfPriorCitizenship
    ),
    gender: initInput(data?.gender),
  };

  return (
    <FormSectionWrapper isLoading={isFetching} isError={isError}>
      <Wrapper>
        <Formik
          initialValues={initialValues}
          validationSchema={InfoAboutYouPart1Validation}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(formik) => (
            <Form>
              <ModuleTitle>
                Current Legal Name (do not provide a nickname)
              </ModuleTitle>

              <FormWrapper>
                <div>
                  <InputField
                    name="familyName"
                    label="Family Name"
                    placeholder=""
                    coloredLabel="(Last Name)"
                  />
                </div>

                <div>
                  <InputField
                    name="givenName"
                    label="Given Name"
                    placeholder=""
                    coloredLabel="(First Name)"
                  />
                </div>
              </FormWrapper>

              <FormWrapper>
                <div>
                  <InputField
                    name="middleName"
                    label="Middle Name"
                    placeholder=""
                  />
                </div>

                <div></div>
              </FormWrapper>

              <FormWrapper>
                <div>
                  <RadioComp
                    name="hasNameChanged"
                    title="Is the name on your Permanent Resident Card different from your full name above?"
                    options={GenericYesNo}
                    onChange={() => {
                      formik.setFieldValue(`changedFamilyName`, "");
                      formik.setFieldValue(`changedGivenName`, "");
                      formik.setFieldValue(`changedMiddleName`, "");
                    }}
                  />
                </div>

                <div></div>
              </FormWrapper>

              {formik.values.hasNameChanged ===
                GenericYesNoOptionsValue.yes && (
                <>
                  <ModuleTitle>
                    Your Name Exactly As It Appears on Your Permanent Resident
                    Card.
                  </ModuleTitle>

                  <FormWrapper>
                    <div>
                      <InputField
                        name="changedFamilyName"
                        label="Family Name"
                        placeholder=""
                        coloredLabel="(Last Name)"
                      />
                    </div>

                    <div>
                      <InputField
                        name="changedGivenName"
                        label="Given Name"
                        placeholder=""
                        coloredLabel="(First Name)"
                      />
                    </div>
                  </FormWrapper>
                  <FormWrapper>
                    <div>
                      <InputField
                        name="changedMiddleName"
                        label="Middle Name"
                        placeholder=""
                      />
                    </div>

                    <div></div>
                  </FormWrapper>
                </>
              )}

              <FormWrapper>
                <div>
                  <RadioComp
                    name="otherNamesSinceBirth"
                    title="Have you used any other names since birth?"
                    options={GenericYesNo}
                    onChange={() => {
                      formik.setFieldValue(`otherFamilyName`, "");
                      formik.setFieldValue(`otherGivenName`, "");
                      formik.setFieldValue(`otherMiddleName`, "");
                    }}
                  />
                </div>

                <div></div>
              </FormWrapper>

              {formik.values.otherNamesSinceBirth ===
                GenericYesNoOptionsValue.yes && (
                <>
                  <ModuleTitle>
                    Provide all other names you have ever used, include
                    nicknames, maiden name, and aliases.
                  </ModuleTitle>

                  <FieldArray
                    name="otherNamesArray"
                    render={(arrayHelpers) => (
                      <div className="px-2 py-3 mb-8 border rounded">
                        {formik.values.otherNamesArray.map((_, index) => (
                          <GroupedSection
                            key={index}
                            index={index}
                            onCancel={() => arrayHelpers.remove(index)}
                          >
                            <div className={`text-[${Colors.Blue00}]`}>
                              {index === 0
                                ? "Other names you've used since birth"
                                : `Entry${index + 1}:`}
                            </div>

                            <div className="mb-8">
                              <FormWrapper>
                                <div>
                                  <InputField
                                    name={`otherNamesArray[${index}].otherFamilyName`}
                                    label="Family Name"
                                    placeholder=""
                                    coloredLabel="(Last Name)"
                                  />
                                </div>

                                <div>
                                  <InputField
                                    name={`otherNamesArray[${index}].otherGivenName`}
                                    label="Given Name"
                                    placeholder=""
                                    coloredLabel="(First Name)"
                                  />
                                </div>
                              </FormWrapper>
                              <FormWrapper>
                                <div>
                                  <InputField
                                    name={`otherNamesArray[${index}].otherMiddleName`}
                                    label="Middle Name"
                                    placeholder=""
                                  />
                                </div>

                                <div></div>
                              </FormWrapper>
                            </div>
                          </GroupedSection>
                        ))}
                        <AddAnotherBtn
                          onClick={() => {
                            if (formik.values.otherNamesArray.length === 2) {
                              toast.error("Limit Reached");
                              return;
                            }
                            arrayHelpers.push(initialOtherNamesArray);
                          }}
                        />
                      </div>
                    )}
                  />
                </>
              )}

              <FormWrapper>
                <InputField
                  name="aNumber"
                  label="Alien Number"
                  placeholder=""
                />

                <InputField
                  name="ssn"
                  label="U.S. Social Security Number"
                  placeholder=""
                  coloredLabel="(if any)"
                />
              </FormWrapper>

              <FormWrapper>
                <div>
                  <InputField
                    name="uscisOnlineAccountNumber"
                    label="USCIS Online Account Number"
                    placeholder=""
                    coloredLabel="(if any)"
                  />
                </div>
              </FormWrapper>

              <FormWrapper>
                <div>
                  <DatePickerField
                    name="dateOfBirth"
                    label="Date of Birth (mm/dd/yyyy)"
                    disableFuture
                  />
                </div>

                <div>
                  <SelectField
                    name="countryOfBirth"
                    label="Country of Birth"
                    placeholder="-Select-"
                    options={countryOfBirthData}
                    isLoading={countryOfBirthLoading}
                    onChange={(value) => {
                      // console.log(value);
                      // console.log(formik.values.countryOfBirth?.value);
                    }}
                  />
                </div>
              </FormWrapper>

              <FormWrapper>
                <div>
                  <SelectField
                    name="countryOfPriorCitizenship"
                    label="Country of Prior Citizenship or Nationality"
                    placeholder="-Select-"
                    options={countryOfCitizenshipData}
                    isLoading={countryOfCitizenshipLoading}
                    onChange={(value) => {
                      // console.log(value);
                      // console.log(
                      //   formik.values.countryOfPriorCitizenship?.value
                      // );
                    }}
                  />
                </div>

                <div>
                  <RadioComp
                    name="gender"
                    title="Gender"
                    options={GenderDropDown}
                  />
                </div>
              </FormWrapper>

              <FormBtn
                isLoading={isSaving}
                addBackBtn
                isError={formik.dirty && !formik.isValid}
              />
            </Form>
          )}
        </Formik>
      </Wrapper>
    </FormSectionWrapper>
  );
};

export default InfoAboutYou1;

const Wrapper = styled.div``;
