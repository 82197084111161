import * as Yup from "yup";
import { SchemaObject } from "../../components/type";
import { BusinessProfileSettingsFormData } from "./settings/components/BusinessProfileSettings";

export const NewApplicationValidation = Yup.object().shape({
  newApp: Yup.object().nullable().required(" "),
});

export const ProfileSettingsValidation = Yup.object().shape({
  name: Yup.string().required("Required"),
  phoneNumber: Yup.string()
    .required("Required")
    .test("phoneNumber", "Invalid Phone number", function (value) {
      return value?.split(" ").length === 1 ? false : true;
    }),
  email: Yup.string(),
});

export const ProfilePasswordValidation = Yup.object().shape({
  newPassword: Yup.string().required("Required").label("new password"),
  confirmNewPassword: Yup.string()
    .required("Please confirm your password.")
    .oneOf([Yup.ref("newPassword")], "Your passwords do not match."),
  code: Yup.string().length(6).required("Required").trim(),
});

export const NewDocumentsValidation = Yup.object().shape({
  newDoc: Yup.object().nullable().required(" "),
});

export const BusinessProfileSettingsValidation = Yup.object().shape<
  SchemaObject<BusinessProfileSettingsFormData>
>({
  businessType: Yup.object().nullable().required("Required"),
  businessName: Yup.string().required("Required"),
  businessNumber: Yup.string()
    .required("Required")
    .test("phoneNumber", "Invalid Phone number", function (value) {
      return value?.split(" ").length === 1 ? false : true;
    }),
  businessEmail: Yup.string().trim().email().required("Required"),
  businessAddress: Yup.string().required("Required"),
});
