import { motion } from "framer-motion";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import { ImCancelCircle } from "react-icons/im";

import Overlay from "../../checkEligibilty/components/modals/Overlay";
import { ContainerVariants } from "../../checkEligibilty/components/modals/variant";
import Colors from "../../../../styles/Colors";
import { mediaObj } from "../../../../styles/Media";
import CongratsImage from "../../../../images/web/congrats.svg";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { WebPaths } from "../../../../routes/data";
import { BaseUrl } from "../../../../api/request";
import { SignUpType } from "./SignUp";
// import { useGetN400PaySuccess } from "../../../../hooks/n400/useN400";

interface Props {
  onClose: () => void;
  userID: string | null;
}

export interface VerificationResponse {
  hasError: boolean;
  code: number;
  verificationStatus: boolean;
}

const Verified: FC<Props> = ({ onClose, userID }) => {
  const [searchParams] = useSearchParams();
  const getType = searchParams.get("type") as SignUpType;
  const navigate = useNavigate();
  // for making the requests
  const [data, setData] = useState<VerificationResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<string | unknown | any>("");
  const isBusinessType = getType === SignUpType.business;
  // the function that makes the request to the backend
  const onVerify = async () => {
    // set the request to loading state
    setLoading(true);

    // make request to the verify endpoint
    try {
      // get the request response using the interface
      const response = isBusinessType
        ? await axios.post(`${BaseUrl}business/verify/${userID}`, {})
        : await axios.post(`${BaseUrl}user/verify/${userID}`, {});
      // setting the data to the response
      setData(response.data);
      // stop the request from loading to show the response
      setLoading(false);
    } catch (error: any) {
      // set error state to true
      setIsError(true);
      // stop the request from loading to show the error
      setLoading(false);
      // set the error message to the response error message
      setError(error.message);
    }
  };

  const onVerified = () => {
    navigate(
      `${WebPaths.Auth}${isBusinessType ? `?type=${SignUpType.business}` : ""}`
    );
  };

  const onRetry = () => {
    setLoading(true);
    onVerify();
  };

  useEffect(() => {
    onVerify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return createPortal(
      <Overlay onClose={() => {}}>
        <Wrapper
          onClick={(e) => e.stopPropagation()}
          variants={ContainerVariants}
        >
          <ContentWrapper>
            <TextBold>Verifying in process...</TextBold>
          </ContentWrapper>
        </Wrapper>
      </Overlay>,
      document.body
    );

  if (isError)
    return createPortal(
      <Overlay onClose={onClose}>
        <Wrapper
          onClick={(e) => e.stopPropagation()}
          variants={ContainerVariants}
        >
          <Cancel onClick={onClose}>
            <ImCancelCircle />
          </Cancel>
          <MainIcon>
            <img src={CongratsImage} alt="" />{" "}
          </MainIcon>
          <ContentWrapper>
            <TextBold className="text-[#ff0000]">
              ⚠️ Oops! we encountered a while verifying your account! ⚠️
            </TextBold>
            <Text className="font-bold text-red-400">{error}</Text>

            <Text>
              Click the button below to retry your account verification
            </Text>

            <CTA onClick={onRetry}>Retry Verification</CTA>
          </ContentWrapper>
        </Wrapper>
      </Overlay>,
      document.body
    );

  return createPortal(
    <Overlay onClose={onClose}>
      <Wrapper
        onClick={(e) => e.stopPropagation()}
        variants={ContainerVariants}
      >
        <Cancel onClick={onClose}>
          <ImCancelCircle />
        </Cancel>
        <MainIcon>
          <img src={CongratsImage} alt="" />
        </MainIcon>
        <ContentWrapper>
          <TextBold>
            {data?.verificationStatus
              ? "🎉 Your Account has been verified! 🎉"
              : "⚠️ Oops! we encountered an problem confirming your account verification status! ⚠️"}
          </TextBold>
          <Text>
            {data?.verificationStatus
              ? "Your account verification has been successful, you may proceed to login!"
              : "Something went wrong when checking your verification status try again later"}
          </Text>

          {data?.verificationStatus ? (
            <CTA onClick={onVerified}>Login to your account</CTA>
          ) : (
            <CTA onClick={onRetry}>Retry Verification</CTA>
          )}
        </ContentWrapper>
      </Wrapper>
    </Overlay>,
    document.body
  );
};

export default Verified;

const Wrapper = styled(motion.div)`
  width: 802px;
  max-height: 80vh;
  overflow-y: auto;
  background: #ffffff;
  border-radius: 12px;
  padding: 76px 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${mediaObj.smallDesktop} {
    margin: auto;
    width: 70vw;
    padding-left: 5%;
    padding-right: 5%;
  }

  ${mediaObj.tablet} {
    width: 80vw;
  }

  ${mediaObj.bigMobile} {
    width: 90vw;
  }
`;

const Cancel = styled.div`
  position: absolute;
  right: 40px;
  top: 48px;
  width: 24px;
  height: 24px;
  color: ${Colors.Black};
  cursor: pointer;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const MainIcon = styled.div`
  width: 108px;
  height: 108px;
  margin-bottom: 16px;

  & > svg,
  & > img {
    width: 100%;
    height: 100%;
    fill: ${Colors.RedC3};
  }
`;

const ContentWrapper = styled.span`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Text = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
`;

const TextBold = styled(Text)`
  font-weight: bold;
`;

const Btn = styled.button`
  width: max-content;
  height: 46px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 24px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 8px;
  white-space: nowrap;
  text-transform: uppercase;
  margin: auto;

  & > svg {
    width: 20px;
    height: 20px;
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

const CTA = styled(Btn)`
  background: ${Colors.Blue00};
  color: ${Colors.WhiteF9};
  padding: 18.5px 47px;

  &:hover:not(:disabled) {
    background: #022e58;
    color: ${Colors.WhiteF9};
    transition: 0.3s ease-in-out;
  }
`;
