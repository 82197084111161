import React, { useState } from "react";
import styled from "styled-components";
import { pricingPlans } from "./data";
import Constaint from "../../../layout/components/Constaint";
import { FaArrowRight } from "react-icons/fa6";
import { PopupModal } from "react-calendly";

const Pricing = () => {
  const [open, setOpen] = useState(false);
  return (
    <Wrapper>
      <Constaint>
        <Contents>
          <Header>
            <h2 className="text-base font-semibold leading-7 text-primaryColor">
              Pricing
            </h2>
            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Choose the right plan for&nbsp;you
            </p>

            <p className="lg:w-[60%] mx-auto mt-6 text-gray-600 sm:text-center">
              Choose the ideal plan for your business. Whether you're a startup
              or a large enterprise, SeamlessVisa offers flexible plans to fit
              your budget and business needs.
            </p>
          </Header>
          <PopupModal
            url="https://calendly.com/seamlessvisa-consultations/request-demo"
            // pageSettings={this.props.pageSettings}
            // utm={this.props.utm}
            // prefill={this.props.prefill}
            onModalClose={() => setOpen(false)}
            open={open}
            /*
             * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
             * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
             */
            rootElement={document.getElementById("root") as HTMLElement}
          />
          <PricingContainer>
            {pricingPlans.map((plan, index) => (
              <PricingCard
                key={index}
                borderColor={plan.borderColor}
                isHighlighted={plan.isHighlighted}
              >
                <PlanTitle>{plan.title}</PlanTitle>
                <FeatureList>
                  {plan.features.map((feature, i) => (
                    <li key={i}>{feature}</li>
                  ))}
                </FeatureList>
                <Button
                  color={plan.buttonColor}
                  isHighlighted={plan.isHighlighted}
                  onClick={() => setOpen(true)}
                >
                  {plan.buttonText}
                  <ArrowRight>
                    <FaArrowRight />
                  </ArrowRight>
                </Button>
              </PricingCard>
            ))}
          </PricingContainer>
        </Contents>
      </Constaint>
    </Wrapper>
  );
};

export default Pricing;

const Wrapper = styled.div``;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const Header = styled.div`
  text-align: center;
`;

// Styled components for the pricing cards
const PricingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;

  @media screen and (min-width: 950px) {
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 40px;
    justify-content: center;
    padding: 30px;
  }

  @media screen and (min-width: 1024px) {
  }
`;

const PricingCard = styled.div<{
  borderColor: string;
  isHighlighted?: boolean;
}>`
  border: 2px solid ${(props) => props.borderColor};
  border-radius: 12px;
  padding: 20px;
  background: ${(props) => (props.isHighlighted ? "#0073ff" : "#fff")};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  color: ${(props) => (props.isHighlighted ? "#fff" : "#000")};
  width: 100%;
  max-width: 400px;
  transition: transform 0.3s ease;

  @media screen and (min-width: 1024px) {
    width: 350px;
  }

  &:hover {
    transform: translateY(-10px);
  }
`;

const PlanTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
`;

const FeatureList = styled.ul`
  list-style: disc;
  padding: 0;
  margin-bottom: 20px;
  margin-left: 16px;

  &:last-child {
    list-style: none;
  }

  li {
    font-size: 16px;
    margin-bottom: 10px;
  }
`;

const Button = styled.button<{ color: string; isHighlighted?: boolean }>`
  background-color: ${(props) => props.color};
  color: ${(props) => (props.isHighlighted ? "#0073ff" : "#fff")};
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:hover {
    opacity: 0.9;
  }
`;

const ArrowRight = styled.span`
  font-size: 18px;
  margin-left: 10px;
`;
