import React from "react";
import { GlobalStyle } from "./styles/global-styles";
import AppRoutes from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "react-circular-progressbar/dist/styles.css";

function App() {
  return (
    <>
      <ToastContainer />
      <GlobalStyle />
      <AppRoutes />
    </>
  );
}

export default App;
