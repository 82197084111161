import { proxy } from "valtio";
import storage from "../utils/storage";

export const seamlessAccessToken = "seamlessAccessToken";
export const seamlessRefreshToken = "seamlessRefreshToken";

const accessToken = storage.get(seamlessAccessToken);
const refreshToken = storage.get(seamlessRefreshToken);

export const accessTokenStore = proxy<{
  accessToken: string | undefined;
  refreshToken: string | undefined;
}>({
  accessToken: accessToken,
  refreshToken: refreshToken,
});
